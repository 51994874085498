import React, { useContext, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import axios from "../../../api/axios";

const ADDTESTSERIES = "/api/test-series/create-testseries-submodule";

const initalValue = {
  name: "",
};
const studentSchema = Yup.object({
  name: Yup.string().min(2).max(100).required("Please enter your name"),
});
export default function AdminAddTest() {
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const navigate = useNavigate();
  const { errors, values, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initalValue,
    onSubmit: async (value) => {
      try {
        const response = await axios.post(
          ADDTESTSERIES,
          { testseries_submodule_name: value.name },
          config
        );
        // console.log(response);
        if (response) {
          setTimeout(() => {
            // navigate("/admin/test-series");
            navigate(-1);
          }, 1000);
          toast.success("Course Created");
        }
        // if (response) navigate("/admin/test-series");
      } catch (error) {
        console.log(error);
      }
      // console.log(value);
    },
    validationSchema: studentSchema,
  });

  return (
    <div className="main-wrapper">
      {/* <StudentHeader activeMenu={"Profile"} /> */}
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Test </h3>
                    <p>Add Test information.</p>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head">{/* <h4></h4> */}</div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">
                              Enter Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter your Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="update-profile">
                          <div
                            class="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <button type="submit" className="btn btn-primary">Submit</button>
                          </div>{" "}
                          <div
                            class="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <button type="button" onClick={()=>{navigate(-1)}} className="btn btn-primary cancel-btn ">
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
