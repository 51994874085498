import React, { useContext, useEffect, useState } from "react";
import { InstructorHeader } from "../../instructor/header";
import Footer from "../../footer";
import TextEditor from "../../pages/course/addCourse/editor";
import Select, { components } from "react-select";
import {
  InstructorProfileBg,
  ReferImage1,
  ReferImage2,
  ReferImage3,
  ReferImage4,
  ReferImage5,
  User15,
} from "../../imagepath";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import FeatherIcon from "feather-icons-react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";
import AuthContext from "../../../context/AuthProvider";
import axios from "../../../api/axios";
import { isArray } from "jquery";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import {   notification, Space  } from "antd";
import AdminHeader from "../../instructor/adminHeader";
const GETMODULE = "/api/recorded-video/link-video";
const SUBMODULE = "/api/recorded-video/get-submodule";
const LINKVIDEO = "/api/recorded-video/link-video";
import "../../instructor/adminTestSeries/index.css"
import "./index.css"

export default function AdminViewLink() {
  const [error, setError] = useState(null);
  const [error1, setError1] = useState(null);
  const [error2, setError2] = useState(null);
  const [subDis, setSubDis] = useState(null);
  const [title, setTitle] = useState(null);
  const [subModule, setSubModule] = useState(null);
  const [module, setModule] = useState(null);
  const [defaultModule, setDeafaultModule] = useState(null);
  const [subModuleOnly, setSubModuleOnly] = useState(null);
  const [subModuleOnlyDis, setSubModuleOnlyDis] = useState(null);
  const [loading, setLoading] = useState(false);
  const[submit,setSubmit]=useState(false)

  const { id } = useParams();
  let bytes = CryptoJS.AES.decrypt(id, "my-secret-key@admin8623009959");
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //  console.log(decryptedData);

  const { auth, setAuth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  //  console.log(token)

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // localStorage.setItem("lastVisitedRoute", window.location.pathname);

  const getModule = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${GETMODULE}/${decryptedData}`, config);
      // console.log(response.data);
      setTitle(response.data.res.title);
      setLoading(false);

      // this code is for get option in module select
      setModule(response?.data?.featureTypes[0]?.get_modules);

      // this code is for get deafault option in module select
      setDeafaultModule(response.data.modules);
      // console.log(response);
      // start
      // this code is for get deafault option in sub module select
      const module1 = response?.data?.modules;
      const subModule1 = response?.data?.subModules;
      // console.log('module1',module1);
      // console.log('subModule1',subModule1)
      let expectedReult = [];
      module1?.map((i) => {
        subModule1?.map((j) => {
          if (i.id == j.module_id) {
            return expectedReult.push(Object.assign({}, i, j));
          }
        });
      });
      // end
      // console.log(expectedReult);
      let subOnlyResult = [];
      response?.data?.featureTypes[0]?.only_sub_modules?.map((i) => {
        response?.data?.subModules?.map((j) => {
          if (i.id == j.id) {
            return subOnlyResult.push(Object.assign({}, i, j));
          }
        });
      });
      // this code get option in sub module
      const exe = module1?.map((v) => ({ ...v, isDisabled: true }));
      const merge = [...exe, ...expectedReult];
      // this is for set disable option
      setSubDis(merge);
      setSubModule(expectedReult);
      // sub module only
      // console.log(subOnlyResult);
      setSubModuleOnlyDis(subOnlyResult);
      setSubModuleOnly(response?.data?.featureTypes[0]?.only_sub_modules);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else{
      openNotificationWithIcon2("error")
      }

    }
  };
  useEffect(() => {
    getModule();
  }, []);

  const navigate = useNavigate();
  const handleSubModule = async (e) => {
    const res = e?.map((it) => it.value);
    // console.log('res',res);
    if (e.length === 0) {
      setError1("");
    } else setError1("");
    const response = await axios.post(SUBMODULE, { module_id: res }, config);
    // console.log(response.data);
    const test = response?.data?.test;
    setDeafaultModule(test);
    const newTest = test?.map((v) => ({ ...v, isDisabled: true }));
    const submodule1 = response?.data?.submodule;
    // this set sub module option
    setSubModule(submodule1);
    const merge = [...newTest, ...submodule1];
    // this set sub module value
    setSubDis(merge);
  };

  const handleSubModules = (e) => {
    // console.log('e',e);
    const res = e?.map(({ label, value }) => ({ name: label, id: value }));

    setSubModule(res);
  };
  const handleSubModulesOnly = (e) => {
    // console.log('e',e);
    if (e.length === 0) {
      // setError("Please select sub module only");
    } else setError("");
    const res = e.map(({ label, value }) => ({ name: label, id: value }));
    // console.log(res);
    setSubModuleOnlyDis(res);
  };
  const [api, contextHolder] = notification.useNotification();
  
  const[toast,setToast]=useState(false)
  
    const openNotificationWithIcon1 = (type) => {
      console.log("toast after click")
      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"#52c41a"}}>
           <strong>Link Created </strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        duration:1
      });
      };
      const openNotificationWithIconSesion = (type) => {
        notification[type]({
          message: (
            <div style={{ color: "red" }}>
              <strong>Session Expired Please Login </strong>
            </div>
          ),
          duration: 1,
        });
      };
      const openNotificationWithIcon2 = (type) => {
        console.log("toast after click")
        notification[type]
        (
          
          {
          message: 
          (
            <div style={{color:"red"}}>
             <strong>Internal Server Error </strong> 
            </div>
          ),
        
          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted
      
          // </div>)
          duration:1
        });
        };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (defaultModule.length === 0) {
      setError1("");
    } else setError1("");
    if (subModule.length === 0) setError2("");
    if (subModule.length !== 0) {
      setError2("");
    }
    if (subModuleOnly.length === 0) setError("");
    const modules = defaultModule.map((it) => it.id);
    const subModules = subModule.map((it) => it.id);
    const subModuleOnlys = subModuleOnlyDis.map((it) => it.id);
    // console.log('modules',modules);
    // console.log('subModules',subModules);
    // console.log('subModuleOnlys',subModuleOnlys);
    setSubmit(true)

    try {
      const result = await axios.post(
        LINKVIDEO,
        {
          modules: modules,
          sub_modules: subModules,
          only_sub_modules: subModuleOnlys,
          video_id: decryptedData,
        },
        config
      );
      if (result) {
        openNotificationWithIcon1("success")
        setTimeout(() => {
          navigate(-1, { replace: true });
        }, 1000);
        // toast.success("Link Created");
      }
    } catch (error) {
      console.log(error);
      setSubmit(false) 
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else{
      openNotificationWithIcon2("error")
      }

    }
  };
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };
  
  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  const SelectMenuButton = (props) => {
    return (
      <components.MenuList {...props}>
        <button
          type="button"
          style={{ width: "100%" }}
          onClick={() => navigate("/admin/recorded-videos/add")}
          className="btn btn-primary"
        >
          Create Module
        </button>
        {props.children}
      </components.MenuList>
    );
  };
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div className="container"
       style={{minWidth:"100%",marginTop:"-20px"}}

        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            {loading === true ? (
              <div
                className="d-flex justify-content-center"
                style={{ position: "absolute" }}
              >
                <div
                  className="spinner-border text-warning"
                  style={{
                    left: "50%",
                    marginLeft: "24em",
                    marginTop: "10em",
                  }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="col-xl-9 col-lg-8 col-md-12">
                <div className="tak-instruct-group">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="settings-widget">
                        <div className="settings-inner-blk p-0"> 
                        <div className="sell-course-head comman-space" >
                        <h3 style={{textTransform:"capitalize"}}>{title}</h3>
                        <nav
                                aria-label="breadcrumb"
                                style={{ marginTop: "0.5em",marginBottom:'-1.6em' }}
                              >
                                <ol className="breadcrumb">
                                  <li
                                    className="breadcrumb-item"
                                    aria-current="page"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      navigate("/admin/recorded-videos-list")
                                    }
                                  >
                                    Recorded Videos
                                  </li>
                                  <li
                                    className="breadcrumb-item active"
                                    aria-current="page"
                                    style={{
                                      cursor: "pointer",
                                      // marginLeft: "-.6em",
                                    }}
                                  >
                                    Link Videos
                                  </li>
                                </ol>
                              </nav>
                        </div>
                          <div className="checkout-form personal-address add-course-info ">
                            <div className="personal-info-head">
                              <h4>Link To Module And SubModule</h4>
                            </div>
                            <form
                              onSubmit={handleSubmit}
                              className=""
                              style={{
                                marginTop: "0.5em",
                                marginBottom: "1em",
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Select Modules
                                    </label>

                                    <Select
                                      components={{
                                        MenuList: SelectMenuButton,
                                      }}
                                      className="basic-single"
                                      classNamePrefix="select"
                                      name="modules"
                                      isMulti
                                      options={module?.map((ele) => {
                                        return {
                                          label: ele.name,
                                          value: ele.id,
                                        };
                                      })}
                                      defaultValue={defaultModule?.map(
                                        (ele) => {
                                          return {
                                            label: ele.name,
                                            value: ele.id,
                                          };
                                        }
                                      )}
                                      placeholder="Select Modules"
                                      onChange={(e) => handleSubModule(e)}
                                    ></Select>
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      {error1}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Select SubModules
                                    </label>

                                    <Select
                                     className="basic-single"
                                     classNamePrefix="select"
                                      name="subModule"
                                      options={subDis?.map((ele) => {
                                        return {
                                          label: ele.name,
                                          value: ele.id,
                                          isDisabled: ele.isDisabled,
                                        };
                                      })}
                                      value={subModule?.map((ele) => {
                                        return {
                                          label: ele.name,
                                          value: ele.id,
                                        };
                                      })}
                                      placeholder="Select SubModule"
                                      onChange={(e) => handleSubModules(e)}
                                      isMulti={true}
                                    ></Select>
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      {error2}
                                    </span>
                                  </div>
                                </div>

                                <div className="form-group mb-0">
                                  <div className="col-lg-12">
                                    <div className="form-group">
                                      <h4>Link To SubModule Only</h4>
                                      <div
                                        style={{
                                          marginTop: "1em",
                                          marginBottom: "2em",
                                        }}
                                      >
                                        <label className="form-label">
                                          Select SubModules Only
                                        </label>

                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          name="subModulesOnly"
                                          defaultValue={subModuleOnlyDis?.map(
                                            (ele) => {
                                              return {
                                                label: ele.name,
                                                value: ele.id,
                                              };
                                            }
                                          )}
                                          options={subModuleOnly?.map((ele) => {
                                            return {
                                              label: ele.name,
                                              value: ele.id,
                                            };
                                          })}
                                          placeholder="Select SubModules Only"
                                          onChange={(e) =>
                                            handleSubModulesOnly(e)
                                          }
                                          isMulti={true}
                                        ></Select>
                                      </div>

                                      <span style={{ color: "red" }}>
                                        {" "}
                                        {error}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                {/* <div>
                                  <button className="btn btn-primary"
                                  type="submit"
                                  disabled={submit}
                                  >
                                    Update
                                  </button>
                                  &nbsp;&nbsp;
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => navigate(-1)}
                                  >
                                    Cancel
                                  </button>
                                </div> */}
                                <div className="update-profile"
                                  id="submit-button"

                                >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            id="custom-submit"

                            aria-label="First group"
                          >
                            <button type="submit" className="btn btn-primary"
                            disabled={submit}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              id="cancel-button"
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <ToastContainer />

            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
