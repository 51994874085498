import React, { useEffect, useRef, useState, createContext, useContext, } from "react";
import { LogOut, Moon, Star, User, Delete ,Download,Upload,Book} from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import "./style.css";
import {
  Cart,
  Course14,
  Course15,
  Course4,
  ChahalLogo,
  logo,
  Messages,
  // Notification,
  User1,
  User11,
  User2,
  User3,
  Wish,
  userprofile,
} from "../../imagepath";
import { notification, Space } from "antd";

import axios from "../../../api/axios";
import axiosInstance from "../../../api/axios1";
import AuthContext from "../../../context/AuthProvider";
const LOGOUT = "/api/student/logout";

const EXPIRED = "api/student/get/expired_courses";
const COURSECATEGORY = "/api/admin/course/menu";
const UPLOADIMAGE = "/api/student/editImageUserProfile";

export default function StudentHeader({ activeMenu }) {
  const name = localStorage.getItem("nameuser");
  const navigate = useNavigate();
  const tokenStudent = localStorage.getItem("tokenStudent");
  // useEffect(() => {
  //   const tokenStudent = localStorage.getItem("tokenStudent");
  //   if (!tokenStudent) {
  //     navigate("/login");
  //   }
  // }, []);
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const { auth, courses, courseslist , expireddata, ExpiredCourse, setExpiredData} = useContext(AuthContext);
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  // const [courses, setCourses] = useState([]);
  // const[image,setImage]=useState("")

  const openNotificationWithIcon1 = (type) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Logout Successful</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const logOut = async () => {
    try {
      const lastVisiteStudentRoute = localStorage.getItem(
        "lastVisiteStudentRoute"
      );
      localStorage.removeItem("lastVisiteStudentRoute");
      const responce = await axiosInstance.post(LOGOUT, {}, config);
      if (responce) {
        openNotificationWithIcon1("success");

        setTimeout(() => {
          navigate("/login");
        }, 1000);
        const keysToRemove = Object.keys(localStorage).filter((key) =>
          key.startsWith("notificationClosed_")
        );
        keysToRemove.forEach((key) => localStorage.removeItem(key));
        // toast.success("Logout Successful");
        // console.log(tokenStudent,"token")
        localStorage.removeItem("tokenStudent");
        localStorage.removeItem("showNotification");
      }
    } catch (error) {
      console.error(error);
      if (error.response.status == 401) {
        localStorage.removeItem("tokenStudent");
        localStorage.removeItem("showNotification");

        // console.log(tokenStudent,"token")

        navigate("/login");
        const keysToRemove = Object.keys(localStorage).filter((key) =>
          key.startsWith("notificationClosed_")
        );
        keysToRemove.forEach((key) => localStorage.removeItem(key));
      } else {
        localStorage.removeItem("tokenStudent");
        localStorage.removeItem("showNotification");
        const keysToRemove = Object.keys(localStorage).filter((key) =>
          key.startsWith("notificationClosed_")
        );
        keysToRemove.forEach((key) => localStorage.removeItem(key));

        // console.log(tokenStudent,"token")

        navigate("/login");
      }
    }
  };
  const userid = localStorage.getItem("userid");

  const uploadprofileimage = async () => {
    try {
      const response = await axiosInstance.get(
        `${UPLOADIMAGE}/${userid}`,
        config
      );
      // console.log(response,"response in navbar")
      if (response) {
        // setImage(response?.data?.image)
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    // uploadprofileimage();
  }, []);
  const image = localStorage.getItem("profile-img");
  // console.log(image ,"in localstorage")
  const [navbar, setNavbar] = useState(false);
  // console.log(navbar,"navbar")

  const [showCart, setShowCart] = useState(false);
  const [showWish, setShowWish] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  // Mobile Menu toggle
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileSubMenu, setMobileSubMenu] = useState(false);
  const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
  const [mobileSubMenu22, setMobileSubMenu22] = useState(false);
  const [mobileSubMenu3, setMobileSubMenu3] = useState(false);
  const [mobileSubMenu32, setMobileSubMenu32] = useState(false);
  const [mobileSubMenu4, setMobileSubMenu4] = useState(false);
  const [mobileSubMenu42, setMobileSubMenu42] = useState(false);
  const [mobileSubMenu43, setMobileSubMenu43] = useState(false);
  const [mobileSubMenu5, setMobileSubMenu5] = useState(false);
  // const courseslist = async () => {
  //   try {
  //     const response = await axios.get(COURSECATEGORY, config);
  //     setCourses(response?.data?.message);
  //     // console.log(response, "response");
  //   } catch (error) {}
  // };
  // useEffect(() => {
  //   courseslist();
  // }, []);

  const openMobileMenu = (event) => {
    document.body.classList.add("menu-opened");
    setMobileMenu(true);
  };
  const hideMobileMenu = (event) => {
    document.body.classList.remove("menu-opened");
    setMobileMenu(false);
  };

  const openMobileSubMenu = (e) => {
    e.preventDefault();
    setMobileSubMenu(!mobileSubMenu);
  };
  const openMobileSubMenu2 = (e) => {
    e.preventDefault();
    setMobileSubMenu2(!mobileSubMenu2);
  };
  const openMobileSubMenu22 = (e) => {
    e.preventDefault();
    setMobileSubMenu22(!mobileSubMenu22);
  };
  const openMobileSubMenu3 = (e) => {
    e.preventDefault();
    setMobileSubMenu3(!mobileSubMenu3);
  };
  const openMobileSubMenu32 = (e) => {
    e.preventDefault();
    setMobileSubMenu32(!mobileSubMenu32);
  };
  const openMobileSubMenu4 = (e) => {
    e.preventDefault();
    setMobileSubMenu4(!mobileSubMenu4);
  };
  const openMobileSubMenu42 = (e) => {
    e.preventDefault();
    setMobileSubMenu42(!mobileSubMenu42);
  };
  const openMobileSubMenu43 = (e) => {
    e.preventDefault();
    setMobileSubMenu43(!mobileSubMenu43);
  };
  const openMobileSubMenu5 = (e) => {
    e.preventDefault();
    setMobileSubMenu5(!mobileSubMenu5);
  };
  // To close the modal, when clicked outside anywhere
  const cart = useRef();
  useOnClickOutside(cart, () => setShowCart(false));

  const wish = useRef();
  useOnClickOutside(wish, () => setShowWish(false));

  const notificationvalue = useRef();
  useOnClickOutside(notificationvalue, () => setShowNotification(false));

  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));

  // Cart Click
  const cartClick = (e) => {
    e.preventDefault();
    // if (showWish) {
    //   setShowWish(false);
    // }
    setShowCart(!showCart);
    // console.log(showCart);
  };

  const wishClick = (e) => {
    e.preventDefault();
    // if (showCart) {
    //   setShowCart(false);
    // }
    setShowWish(!showWish);
  };

  const notificationClick = (e) => {
    e.preventDefault();
    setShowNotification(!showNotification);
  };
  const profileClick = (e) => {
    e.preventDefault();
    setShowProfile(!showProfile);
  };

  const changeHeaderBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeHeaderBackground);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };
  const [showNotification1, setShowNotification1] = useState(true);
  const [loading, setLoading] = useState(false);
  // const [expireddata, setExpiredData] = useState([]);

  // const handleCloseNotification = () => {
  //   setShowNotification1(false);
  // }

  // const ExpiredCourse = async () => {
  //   try {
  //     setLoading(true);
  //     const resonse = await axiosInstance.get(EXPIRED, config);
  //     // console.log(resonse,"response")
  //     setExpiredData(resonse?.data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     if (error?.response?.status === 401) {
  //       localStorage.removeItem("token");
  //       localStorage.removeItem("showNotification");

  //       navigate("/login");
  //     }
  //     // openNotificationWithIcon2("error");
  //   }
  // };

  // useEffect(() => {
  //   ExpiredCourse();
  // }, []);
  // console.log(expireddata,"data")
  const [closedNotifications, setClosedNotifications] = useState([]);

  const handleCloseNotification = (index) => {
    if (expireddata?.length <= 1) {
      localStorage.setItem("showNotification", "false");
      setShowNotification1(false);
    }

    setExpiredData((prevData) => prevData.filter((_, i) => i !== index));

    // Update localStorage to indicate that this notification has been closed
    localStorage.setItem(`notificationClosed_${index}`, "true");
  };

  useEffect(() => {
    const shouldShowNotification =
      localStorage.getItem("showNotification") !== "false";
    setShowNotification1(shouldShowNotification);
  }, []);
 useEffect(() => {
  const handleResize = () => {
    if (window.innerWidth > 1440) {
      // If the window width is greater than 1440, open the mobile menu
      setNavbar(false);
      setMobileMenu(true);
    } else {
      // If the window width is less than or equal to 1440, close the mobile menu
      setNavbar(false);
      setMobileMenu(false);
    }
  };

  handleResize(); // Call the function initially
  window.addEventListener("resize", handleResize); // Add event listener for window resize

  return () => {
    window.removeEventListener("resize", handleResize); // Remove event listener on component unmount
  };
}, []);


  return (
    <>
      <header className="header header-page">
        <div className="header-fixed">
          <nav
            className={
              navbar
                ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
                : "navbar navbar-expand-lg header-nav scroll-sticky"
            }
          >
            <div className="container" style={{ minWidth: "100%" }}>
              <div className="navbar-header">
                <Link id="mobile_btn" to="#;" onClick={openMobileMenu}>
                  <span className="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </Link>
                <Link to="/" className="navbar-brand logo">
                  <img src={ChahalLogo} className="img-fluid" alt="Logo" />
                  
                </Link>
              </div>
              <div className="main-menu-wrapper"
              // style={{width:"800px"}}
              >
                <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img src={ChahalLogo} className="img-fluid" alt="Logo" />
                  </Link>
                  <Link
                    id="menu_close"
                    className="menu-close"
                    to="#;"
                    onClick={hideMobileMenu}
                  >
                    <i className="fas fa-times"></i>
                  </Link>
                </div>
                <ul className="main-nav">
                  <li className="has-submenu">
                    <Link to="/" className={isActive("/") ? "actives" : ""}>
                      Home{" "}
                      {/* <i
                      className="fas fa-chevron-down "
                      onClick={openMobileSubMenu2}
                    ></i> */}
                    </Link>
                    <ul
                    // className={
                    //   mobileSubMenu2 ? "submenu submenuShow" : "submenu"
                    // }
                    >
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                    </ul>
                  </li>

                  <li className="has-submenu">
                    <Link
                                        onClick={hideMobileMenu}

                      to="/dashboard"
                      className={isActive("/dashboard") ? "actives" : ""}
                    >
                      Dashboard{" "}
                    </Link>
                    <ul>
                      <li>
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                    </ul>
                  </li>
                  {courses?.map((item ,i) => {
                    
                    return (
                      <li className="has-submenu"
                      key={i}
                      >
                        
                        <Link
                                            onClick={hideMobileMenu}

                          to={`/student/courses/${item?.id}`}
                          className={
                            isActive(`/student/courses/${item?.id}`)
                              ? "actives"
                              : ""
                          }
                        >
                          {`${item?.name?.slice(
                                                          0,
                                                          10
                                                        )} ${
                                                          item?.name
                                                            ?.length > 10
                                                            ? "..."
                                                            : ""
                                                        }`}
                                                        {/* {item?.name} */}
                        </Link>
                        <ul>
                          <li>
                            <Link to={`/student/courses/${item?.id}`}>
                            {`${item?.name?.slice(
                                                          0,
                                                          10
                                                        )} ${
                                                          item?.name
                                                            ?.length > 10
                                                            ? "..."
                                                            : ""
                                                        }`}
                                                        
                            </Link>
                          </li>
                        </ul>
                      </li>
                    );
                  })}

                  {/* <li className="has-submenu">
                  <Link to="/student/courses">
                    Courses{" "}
                    * <i
                      className="fas fa-chevron-down "
                      onClick={openMobileSubMenu2}
                    ></i> *
                  </Link>
                  <ul
                  // className={
                  //   mobileSubMenu2 ? "submenu submenuShow" : "submenu"
                  // }
                  >
                    <li className="active">
                      <Link to="/student/my-subscriptions">Courses</Link>
                    </li>
                  </ul>
                </li> */}
                  <li className="has-submenu">
                    <Link
                                        onClick={hideMobileMenu}

                      to="/student/videos"
                      className={
                        activeMenu === "videos"
                          ? "actives"
                          : ""
                      }                    >
                      Videos{" "}
                    </Link>
                    <ul>
                      <li>
                        <Link to="/student/videos">Videos</Link>
                      </li>
                    </ul>
                  </li>

                  <li className="has-submenu">
                    <Link
                                        onClick={hideMobileMenu}

                      to="/student/test-series"
                      className={
                        activeMenu==="Test" ? "actives" : ""
                      }
                    >
                      Test Series{" "}
                    </Link>
                    <ul>
                      <li>
                        <Link to="/student/test-series">Test Series</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu">
                    <Link
                                        onClick={hideMobileMenu}

                      to="/student/std-live-class"
                      className={
                        isActive("/student/std-live-class") ? "actives" : ""
                      }
                    >
                      Live Classes{" "}
                    </Link>
                    <ul>
                      <li>
                        <Link to="/student/std-live-class">Live Classes</Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="has-submenu">
                    <Link
                                        onClick={hideMobileMenu}

                      to="/student/my-subscriptions"
                      className={
                        isActive("/student/my-subscriptions") ? "actives" : ""
                      }
                    >
                      My Subscriptions{" "}
                    </Link>
                    <ul>
                      <li>
                        <Link to="/student/my-subscriptions">
                          My Subscriptions
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  <li className="has-submenu">
                    <Link
                          onClick={hideMobileMenu}

                      to="/student/chat"
                      className={isActive("/student/chat") ? "actives" : ""}
                    >
                      Chat{" "}
                    </Link>
                    <ul>
                      <li>
                        <Link to="/student/chat">Chat</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <ul className="nav header-navbar-rht">
                <li className="nav-item user-nav">
                  <Link
                    to="#"
                    className={
                      showProfile ? "dropdown-toggle show" : "dropdown-toggle"
                    }
                    data-bs-toggle="dropdown"
                    onClick={profileClick}
                  >
                    <span className="user-img">
                      <img src={image ? image : userprofile} alt="" />
                      <span className="status online"></span>
                    </span>
                  </Link>
                  <div
                    ref={profile}
                    className={
                      showProfile
                        ? "users dropdown-menu dropdown-menu-right show modalPosition"
                        : "users dropdown-menu dropdown-menu-right"
                    }
                    data-popper-placement="bottom-end"
                  >
                    <div className="user-header">
                      <div className="avatar avatar-sm">
                        <img
                          src={image ? image : userprofile}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="user-text">
                        <h6 className="single-line">{name}</h6>
                        <p className="text-muted text mb-0">Student</p>
                      </div>
                    </div>

                    <Link
                      className="dropdown-item text"
                      to="/student/setting-edit-profile"
                    >
                      <User
                        size={14}
                        color={"#FF875A"}
                        className="headerIcon"
                      />{" "}
                      Edit Profile
                    </Link>
                    <Link
                      className="dropdown-item text"
                      to="/student/setting-student-security"
                    >
                      <Star
                        size={14}
                        color={"#FF875A"}
                        className="headerIcon"
                      />{" "}
                      Security
                    </Link>
                    <Link className="dropdown-item text"
                    to="/student/my-subscriptions"
                    >
                      <Book
                      size={14}
                     color={"#FF875A"}
                     className="headerIcon"
                   />{" "}
My SubScrptions
                    </Link>
                    <Link
                      className="dropdown-item text"
                      to="/student/expired-courses"
                    >
                      <Delete
                        size={14}
                        color={"#FF875A"}
                        className="headerIcon"
                      />{" "}
                      Inactive Courses
                    </Link>
                    <Link
                      className="dropdown-item text"
                      to="/student/invoicefee"
                    >
                      <Download
                        size={14}
                        color={"#FF875A"}
                        className="headerIcon"
                      />{" "}
                       Fees Invoice
                    </Link>
                    <Link
                      className="dropdown-item text"
                      to="/student/updates"
                    >
                      <Upload
                        size={14}
                        color={"#FF875A"}
                        className="headerIcon"
                      />{" "}
                      Updates
                    </Link>
                    <a
                      className="dropdown-item text "
                      style={{ cursor: "pointer" }}
                      onClick={logOut}
                    >
                      <LogOut
                        size={14}
                        color={"#FF875A"}
                        className="headerIcon "
                      />{" "}
                      Log Out
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>

          <div
            className={
              mobileMenu ? "sidebar-overlay opened" : "sidebar-overlay"
            }
          ></div>
          <ToastContainer />
        </div>
      </header>

      <div>
        {expireddata?.length > 0 && showNotification1 && (
          <>
            {expireddata?.length > 0
              ? expireddata?.map((item, index) => {
                  const dateParts = item?.expiry_date.split("-");
                  const formattedDate = dateParts.reverse().join("-");
                  const expirationDate = new Date(
                    dateParts[2],
                    dateParts[1] - 1,
                    dateParts[0]
                  );

                  const currentDate = new Date();
                  const timeDiff = expirationDate - currentDate;
                  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

                  let alertClass = "";
                  let notificationText = "";

                  if (diffDays > 4) {
                    alertClass = "alert-info";
                    notificationText = `Your ${item?.course[0]?.name} Course will expires on ${formattedDate}`;
                  } else if (diffDays === 4) {
                    alertClass = "alert-info";
                    notificationText = `Your ${item?.course[0]?.name} Course will expires  on ${formattedDate}`;
                  } else if (diffDays === 1) {
                    alertClass = "alert-warning";
                    notificationText = `Your ${item?.course[0]?.name} Course will expires  on tomorrow`;
                  } else if (diffDays > 0) {
                    alertClass = "alert-warning";
                    notificationText = `Your ${item?.course[0]?.name} Course will expires  on ${formattedDate}`;
                  } else if (diffDays === 0) {
                    alertClass = "alert-danger";
                    notificationText = `Your ${item?.course[0]?.name} Course will expires today  ${formattedDate}`;
                  } else {
                    alertClass = "alert-danger";
                    notificationText = `Your ${item?.course[0]?.name} Course has expired on ${formattedDate}`;
                  }

                  let closeButtonStyle = {
                    backgroundColor:
                      alertClass === "alert-danger"
                        ? "#f8d7da"
                        : alertClass === "alert-info"
                        ? "#cff4fc"
                        : alertClass === "alert-warning"
                        ? "#fff3cd"
                        : "",
                  };

                  const isNotificationClosed =
                    localStorage.getItem(`notificationClosed_${index}`) ===
                    "true";

                  if (isNotificationClosed) return null;

                  return (
                    <div
                      key={index}
                      className={`alert ${alertClass} alert-dismissible fade show d-flex`}
                      role="alert"
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                        textTransform: "capitalize",
                        padding: ".5rem",
                      }}
                    >
                      {notificationText}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => {
                          handleCloseNotification(index);
                        }}
                        style={Object.assign({}, closeButtonStyle, {
                          border: `1px solid ${closeButtonStyle.backgroundColor}`,
                        })}
                      >
                        <span
                          aria-hidden="true"
                          style={{ width: "20px", height: "20px" }}
                        >
                          &times;
                        </span>
                      </button>
                    </div>
                  );
                })
              : ""}
          </>
        )}
      </div>
    </>
  );
}
