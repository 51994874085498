import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Course10,
  Course11,
  Course12,
  Course13,
  Course14,
  Course15,
  Course16,
  Course17,
  Icon1,
  Icon2,
  User,
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
  Share,
} from "../imagepath";
import axios from "../../api/axios";
import { useState } from "react";
const GETCOURSE = "api/course-category-search";
const CONTACT="api/contact-details"
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { event } from "jquery";
// import "./gridinner.css";
import { notification, Space } from "antd";
// import PaginationStudent from "../addCourse/PaginationStudentSub";
// import axiosInstance from "../../../../api/axios1";
// import  WhatsApp  from "../../../../assets/img/logo/whatsapp.png";

const GridInnerPage = ({id,search}) => {
  // console.log(id,"id")

  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const [data, setData] = useState([]);
  const tokenStudent = localStorage.getItem("tokenStudent");
  const [list, setList] = useState(null);
  const [listdata, setlistdata] = useState(null);
  const [number, setNumber] = useState(null);

  const [loading, setLoading] = useState(false);
  const [all, setAll] = useState(false);
  const [free, setFree] = useState(false);
  const [searchvalue, setSearch] = useState("");
  const [paid, setPaid] = useState(false);
  const [freeCourses, setFreeCourses] = useState([]);
  const [PaidCourses, setPaidCourses] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [last_page, setLast_page] = useState("");
  const scrollRef = useRef(null);

  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const [coursesToShow, setCoursesToShow] = useState(4);

  const handleViewMore = () => {
    // Increase the number of courses to display
    setCoursesToShow(coursesToShow + 4);
  };
  
  const getCourse = async () => {
      try {
          setLoading(true);
          const response = await axios.post(GETCOURSE,{category:id==0?"":id,course:search=="No"?"":search}, config);
          const coursedata = response?.data[0]?.category;
           console.log(response?.data?.category,"response")
  
          // console.log(organizedCourses);
  
          setList(response?.data?.category);
          setData(response?.data?.course)
          setLoading(false)
      } catch (error) {
          console.log(error);
          // if (error.response && error?.response?.status === 401) {
          //     localStorage.removeItem("token");
          //     navigate("/login");
          // }
          openNotificationWithIcon2("error");
      } finally {
          setLoading(false);
      }
  };
  console.log(data,"list")
  const getCotact = async () => {
    try {
        setLoading(true);
        const response = await axios.get(CONTACT,);
        // console.log(response,"res")
        setNumber(response?.data?.detail)

        setLoading(false)
    } catch (error) {
        console.log(error);
        // if (error.response && error?.response?.status === 401) {
        //     localStorage.removeItem("token");
        //     navigate("/login");
        // }
        openNotificationWithIcon2("error");
    } finally {
        setLoading(false);
    }
};
  // Function to organize courses based on course_type.id
  const organizeCoursesByType = (coursedata) => {
    const organizedCourses = {};
  
    coursedata?.forEach((course) => {
      const courseType = course.course_type;
      const courseId = courseType?.id;
  
      if (courseId) {
        if (!organizedCourses[courseId]) {
          organizedCourses[courseId] = {
            name: courseType.name,
            course_type_id: courseId,
            courses: [],
          };
        }
  
        organizedCourses[courseId].courses.push(course);
      }
    });
  
    return Object.values(organizedCourses);
  };
  
  
  
  
  useEffect(() => {
    getCourse();
     getCotact()
  }, [id]);
  // console.log(currentPage,"pages",last_page)
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const visibleCourse = list?.slice(indexOfFirstCourse, indexOfLastCourse);

  const courseLength = data?.length ? data?.length : 0;
  const numOFTotalPage = Math.ceil(courseLength / 10);
  const pages = [...Array(numOFTotalPage + 1).keys()].slice(1);

  const navigate = useNavigate();


  useEffect(() => {
    if (data) {
      const filteredFreeCourses = list?.filter((item) => item?.amount == 0);
      setFreeCourses(filteredFreeCourses);
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      const filteredPaidCourses = list?.filter((item) => item?.amount != 0);
      setPaidCourses(filteredPaidCourses);
    }
  }, [data]);


  const Filter = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    // console.log(searchTerm, "searchterm"); 
    setSearch(searchTerm);

    if (!searchTerm) {
      getCourse();
      return;
    }
  
    const searchlist = data?.filter((t) =>
      t.name.toLowerCase().includes(searchTerm)
    );
    // console.log(searchlist,"serachlist")
    setData(searchlist);
  };

  const RadioCourses = (event) => {
    if (event?.name == "paid") {
      var paidcourse = list?.filter((item) => Number(item?.amount) > 0);
      
      if (event.checked) {
        setPaid(!paid);
        setFree(false);
        setAll(false);
      }

      setData(paidcourse);
    }
    if (event?.name == "free") {
      var freeCourses = list?.filter((item) => item.amount == 0);
      // console.log(freeCourses)
      if (event?.checked) {
        setFree(!free);
        setPaid(false);
        setAll(false);
      }
      setData(freeCourses);
    }
    if (event?.name == "all") {
      // var allCourse = data?.slice(index
      if (event?.checked) {
        setAll(!all);
        setPaid(false);
        setFree(false);
      }
      getCourse();
      return;
    }
  };
  // console.log(searchvalue,"serach")
  const ClearOptions = () => {
    // console.log(searchvalue,"serach in clear ")
    setSearch(" ");
    // console.log(searchvalue,"serach in after ")

    setAll(false);
    setPaid(false);
    setFree(false);
    // setData(data)
    getCourse();
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1023);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const itemsPerPage = 10;
  // const scrollToItem = (itemName) => {
  //   const element = document.getElementById(itemName);
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // };
  const scrollToItem = (itemName) => {
    const element = document.getElementById(itemName);
    if (element) {
      // Scroll to the target div
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  
      // Adjust the scroll position to ensure it stops at the top of the div
      const yOffset = -100; // Adjust this value as needed
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
  
  
  


  return (
    <>
      <div className="showing-list">
        <div className="row">
          <div className="">
            <div className="">
           
            </div>
          </div>
         
        </div>
      </div>
    
      <div className="row">
      
        <div
          className="col-lg-12 datavalue"
          
          // style={{ marginTop: "-35px" }}
        >
          <div className="row"
          
          >
            {loading === true ? (
              <div style={{ display: "block" }} id="cover-spin"></div>
            ) : 
            (data!=null?
              <>
              <div className="row">
                <div  >
                  <h3 style={{textAlign:"center"}}>No courses are found </h3>
                </div>
                <div className="">
                  <h3>Suggestions</h3>
                </div>
  
              </div>
              </>
              :<></>)
          }
          </div>
          
          <div className="row"
          
          >
            {loading === true ? (
              <div style={{ display: "block" }} id="cover-spin"></div>
            ) : list?.length > 0 ? (
              list?.map((item,index) => {
                return (
                  <div className="col-lg-3 col-md-6 d-flex mt-2"
                  key={item?.id}
                    >
                        <div
                          className="course-box course-design  "
                          style={{
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <div className="product ">
                            <div className="product-img">
                              <Link>
                                {item?.image === null ? (
                                  <img
                                    style={{ maxHeight:"220px",width:"100%" }}
                                    id="img-grid"
                                    className="img-fluid"
                                    alt=""
                                    src={Share}
                                  />
                                ) : (
                                  <img
                                    // style={{ width: "389px", height: "271px" }}
                                    className="img-fluid"
                                    id="img-grid"
                                    style={{maxHeight:"200px",width:"100%"}}
                                    alt=""
                                    src={`${item?.image}`}
                                  />
                                )}
                              </Link>
                              <div className="price" style={{ cursor: "pointer" }}>
                                <h3  style={{color:"#080F5B"}}>
                                  {+item.amount === 0 ? (
                                    <div>
                                    <h3 className="free-color"
                                    style={{color:"#080F5B"}}
                                    >FREE</h3>
                                    </div>
                                  ) : (
                                    <>
                                      ₹{" "}
                                      {parseInt(item.amount).toLocaleString(
                                        "en-IN",
                                        {
                                          maximumFractionDigits: 0,
                                        }
                                      )}
                                    </>
                                  )}
                                </h3>
                              </div>
                            </div>
                            <div className="product-content">
                              <div className="course-group d-flex"
                              style={{justifyContent:"space-between"}}
                              >
                                <div className="course-group-img d-flex"
                                style={{alignItems:"center"}}
                                >
                                 
                                  <div className="course-name">
                                    <h3
                                      className="mt-3"
                                      style={{
                                        marginLeft: "5px",
                                        textTransform: "capitalize",
                                        fontWeight:600,
                                        fontSize:"20px"
                                      }}
                                    >
                                      <Link>
                                        {item?.name.slice(0, 15)}
                                        {item?.name?.length > 15 ? "..." : ""}
                                      </Link>
                                      {/* <Link>{item?.name}</Link> */}
                                    </h3>
                                    {/* <p>Instructor</p> */}
                                  </div>
                                 
                                </div>
                                <div className="d-flex align-items-center"
                                style={{marginTop:"10px"}}
                                >
                                   <button
                                    onClick={() =>
                                      navigate(`/student/login/courses-slug/${item.slug}`)
                                    }
                                    className="btn btn-link"
                                    id=""
                                    style={{
                                    // padding:"20px"
                                  }}
                                  >
                                    Know More                              
          
                                  </button>
                                </div>
                                {/* <div className="course-share d-flex align-items-center justify-content-center">
                              <Link>
                                <i className="fa-regular fa-heart" />
                              </Link>
                            </div> */}
                              </div>
                              
                              {/* <div className="course-info d-flex align-items-center"
                              style={{marginLeft:"4px",paddingBottom:"0px"}}
                              >
                                <div className="rating-img d-flex align-items-center">
                                  <img src={Icon2} alt="" />
                                  <p>{item.duration} Days </p>
                                </div>
                                <div className="course-view d-flex align-items-center">
                            <img src={Icon1} alt="" />
                              <p>12 Test</p>
                              
                            </div>
                            <div className="course-view d-flex align-items-center">
                            <img src={Icon1} alt="" />
                              <p>12 videos</p>
                              
                            </div>
                            
                              </div> */}
                              {/* <div className="rating">
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star me-1" />
                            <span className="d-inline-block average-rating">
                              <span>4.0</span> (15)
                            </span>
                          </div> */}
                              <div className="all-btn all-category  align-items-center d-flex justify-content-between"
                              style={{border:"none"}}
                              >
                                <div className="d-flex"
                                // style={{justifyContent:"flex-end",borderRadius:"10px",border:"none"}}
    
                                >
                                  <a
                                    // onClick={() =>
                                    //   navigate(`/student/courses-slug/${item.slug}`)
                                    // }
                                    target="_blank"
                                    href={`https://wa.me/${number}?text=I%20need%20to%20buy%20this%20course%20${name}`}
    
                                    className="btn btn-danger whatsapp-button"
                                    id=""
                                    style={{
                                      // border:"1px solid #B4A7F5",
                                    // padding:"20px"
                                    // borderRadius:"20px"
                                  }}
                                  >
                               {/* <img style={{height:"40px"}} src={WhatsApp}/> */}
                               WhatsApp
                                  </a>
                                </div>
                                
                                {/* <div className="d-flex"
                                // style={{justifyContent:"flex-end",borderRadius:"10px",border:"none"}}
    
                                >
                                  <a
                                    // onClick={() =>
                                    //   navigate(`/student/courses-slug/${item.slug}`)
                                    // }
                                    className="btn btn-primary"
                                    id="know"
                                    style={{border:"1px solid #B4A7F5",
                                    // padding:"20px"
                                  }}
                                  >
                                    <p                                 
                                     style={{marginTop:"-4px",}}
    >                                Payments
    </p>
                                  </a>
                                </div> */}
                                <div className="d-flex">
      {/* New Payments button */}
      <button className="btn btn-primary payment-button"
      style={{borderRadius:"8px"}}
      >
        Buy know
      </button>
    </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                );
              })
            ) : (
              ""
            )}
          </div>
        </div>
        {/* {!isSmallScreen && (
          <div
            className="col-lg-3 col-item "
            id="filter"
            // style={{marginBottom:"30px"}}
            // style={{marginTop:"-38px"}}
          >
            <div
              className="col-lg-12"
              style={{ justifyContent: "space-between" }}
            >
              <div className="clear-filter d-flex align-items-center">
                <h4>
                  <FeatherIcon icon="filter" />
                  Filters
                </h4>
                <div className="clear-text">
                  <button onClick={ClearOptions} style={{ border: "none" }}>
                    CLEAR
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-12"
              // style={{border:"1px solid red"}}
            >
              <div className="show-filter add-course-info">
                <form action="#">
                  <div className="">
                    <div className="">
                      <div
                        className=" search-group"
                        style={{ marginTop: "-15px" }}
                      >
                        <i className="feather-search">
                          <FeatherIcon icon="search" />
                        </i>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search our Courses"
                          onChange={Filter}
                          value={searchvalue}

                          // onKeyDown={handleKeyPress}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-12" style={{ marginTop: "20px" }}>
              <div className="card search-filter ">
                <div className="card-body">
                  <div className="filter-widget mb-0">
                    <div className="categories-head d-flex align-items-center">
                      <h4>Price</h4>
                      <i className="fas fa-angle-down" />
                    </div>
                    <div>
                      <label className="custom_check custom_one">
                        <input
                          type="checkbox"
                          name="all"
                          checked={all}
                          onClick={(event) => {
                            RadioCourses(event.target), setAll(true);
                          }}
                        />
                        <span className="checkmark" /> All ({list?.length})
                      </label>
                    </div>
                    <div>
                      <label className="custom_check custom_one">
                        <input
                          type="checkbox"
                          name="free"
                          checked={free}
                          onClick={(event) => {
                            RadioCourses(event.target), setFree(true);
                          }}
                        />
                        <span className="checkmark" /> Free (
                        {freeCourses?.length})
                      </label>
                    </div>
                    <div>
                      <label className="custom_check custom_one">
                        <input
                          type="checkbox"
                          name="paid"
                          checked={paid}
                          onClick={(event) => {
                            RadioCourses(event.target), setPaid(true);
                          }}
                        />
                        <span className="checkmark" /> Paid Courses (
                        {PaidCourses?.length})
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </div>
      {/* {data?.length > 0 ? (
        <div className="fixtable">
          <PaginationStudent
            nPages={last_page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            link={GETCOURSE}
            setData={setData}
          />
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default GridInnerPage;
