import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Course10,
  Course11,
  Course12,
  Course13,
  Course14,
  Course15,
  Course16,
  Course17,
  Share,
  Icon2,
  User,
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
} from "../../../imagepath";
import axios from "../../../../api/axios";
const GETCOURSE = "/api/course";
import { notification, Space } from "antd";


const GridInnerPage = () => {
  const tokenStudent = localStorage.getItem("tokenStudent");
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const getCourse = async () => {
    setLoading(true);
    const reponse = await axios.get(GETCOURSE);
    // console.log(reponse.data);
    setList(reponse.data);

    setLoading(false);
  };
  useEffect(() => {
    getCourse();
  }, []);
  const navigate = useNavigate();
  const handleBuy=(id)=>{
    navigate(`/details/${id}`)
  }
  const [currentPage, setCurrentPage] = useState(1)
  const indexOfLastCourse = currentPage * 10
  const indexOfFirstCourse = indexOfLastCourse - 10
  const courseLength = list?.length ? list?.length : 0
  const numOFTotalPage = Math.ceil(courseLength / 10)
  const page = [...Array(numOFTotalPage + 1).keys()].slice(1)
  const prevPageHandler = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1)
  }
  const nextPageHandler = () => {
    if (currentPage !== numOFTotalPage) setCurrentPage(currentPage + 1)
  }
  return (
    <>
      <div className="row">
        {loading === true ? (
          <div
            class="d-flex justify-content-center"
            style={{ position: "absolute" }}
          >
            <div
              class="spinner-border text-warning"
              style={{
                left: "50%",
                marginRight: "24em",
                marginTop: "4em",
              }}
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          list?.map((item) => {
            console.log(item);
            return (
              <div className="col-lg-3 col-md-6 d-flex">
                <div className="course-box course-design d-flex ">
                  <div className="product">
                    <div className="product-img">
                      <Link>
                      {item?.image===null?     <img
                          className="img-fluid"
                          alt=""
                          src={Share}
                          style={{height:'180px'}}
                        />:        <img
                        className="img-fluid"
                        alt=""
                        src={`${item?.image}`}
                        style={{height:'180px'}}
                      />}
                
                      </Link>
                      <div className="price">
                        <h3 className={item.amount === '0.00'?"free-color":""}>
                         
                          {item.amount === '0.00'
                            ? "Free"
                            : ` ₹ ${ parseInt(item.amount).toLocaleString("en-IN", {
                                maximumFractionDigits: 0,
                              })}`
                              }
                        </h3>
                      </div>
                    </div>
                    <div className="product-content">
                      <div className="course-group d-flex">
                        <div className="course-group-img d-flex">
                          {/* <Link>
                        <img
                          src={User1}
                          alt=""
                          className="img-fluid"
                        />
                      </Link> */}
                          <div className="course-name">
                            <h4>
                              <Link>{item?.name}</Link>
                            </h4>
                            {/* <p>Instructor</p> */}
                          </div>
                        </div>
                        {/* <div className="course-share d-flex align-items-center justify-content-center">
                      <Link>
                        <i className="fa-regular fa-heart" />
                      </Link>
                    </div> */}
                      </div>
                      <h3 className="title">
                        {/* <Link>Information About UI/UX Design Degree</Link> */}
                      </h3>
                      <div className="course-info d-flex align-items-center">
                        {/* <div className="rating-img d-flex align-items-center">
                      <img src={Icon1} alt="" />
                      <p>12+ Lesson</p>
                    </div> */}
                    <div className="course-view d-flex align-items-center">
                      <img src={Icon2} alt="" />
                      <p>{item.duration} Days</p>
                    </div>
                      </div>
                      {/* <div className="rating">
                    <i className="fas fa-star filled me-1" />
                    <i className="fas fa-star filled me-1" />
                    <i className="fas fa-star filled me-1" />
                    <i className="fas fa-star filled me-1" />
                    <i className="fas fa-star me-1" />
                    <span className="d-inline-block average-rating">
                      <span>4.0</span> (15)
                    </span>
                  </div> */}
                      <div className="all-btn all-category d-flex align-items-center justify-content-between">
                        <a 
                         onClick={()=> handleBuy(item?.slug)} 
                         className="btn btn-primary">BUY NOW</a>
                        {/* <Link  className="btn btn-primary" style={{minWidth:'110px',padding:'5px 10px'}}>
                      KNOW MORE
                    </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}

        {/* <div className="col-lg-4 col-md-6 d-flex">
          <div className="course-box course-design d-flex ">
            <div className="product">
              <div className="product-img">
                <Link to="/course-details">
                  <img
                    className="img-fluid"
                    alt=""
                    src={Course12}
                  />
                </Link>
                <div className="price">
                  <h3 className="free-color">FREE</h3>
                </div>
              </div>
              <div className="product-content">
                <div className="course-group d-flex">
                  <div className="course-group-img d-flex">
                    <Link to="/instructor-profile">
                      <img
                        src={User3}
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                    <div className="course-name">
                      <h4>
                        <Link to="/instructor-profile">Jesse Stevens</Link>
                      </h4>
                      <p>Instructor</p>
                    </div>
                  </div>
                  <div className="course-share d-flex align-items-center justify-content-center">
                    <Link to="/#rate">
                      <i className="fa-regular fa-heart" />
                    </Link>
                  </div>
                </div>
                <h3 className="title">
                  <Link to="/course-details">
                    Sketch from A to Z (2022): Become an app designer
                  </Link>
                </h3>
                <div className="course-info d-flex align-items-center">
                  <div className="rating-img d-flex align-items-center">
                    <img src={Icon1} alt="" />
                    <p>12+ Lesson</p>
                  </div>
                  <div className="course-view d-flex align-items-center">
                    <img src={Icon2} alt="" />
                    <p>9hr 30min</p>
                  </div>
                </div>
                <div className="rating">
                  <i className="fas fa-star filled me-1" />
                  <i className="fas fa-star filled me-1" />
                  <i className="fas fa-star filled me-1" />
                  <i className="fas fa-star filled me-1" />
                  <i className="fas fa-star me-1" />
                  <span className="d-inline-block average-rating">
                    <span>4.0</span> (15)
                  </span>
                </div>
                <div className="all-btn all-category d-flex align-items-center">
                  <Link to="/checkout" className="btn btn-primary">
                    BUY NOW
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
 <div className="row">
        <div className="col-md-12">
          <ul className="pagination lms-page d-flex align-items-center justify-content-center">
            <li className="page-item prev" onClick={() => prevPageHandler()}>
              <Link className="page-link" to="#" tabIndex={-1}>
                <i className="fas fa-angle-left" />
              </Link>
            </li>
            {page.map((pages) => {
              return <li className={currentPage == pages ? "page-item first-page active" : "page-item first-page "}>
                <Link onClick={() => setCurrentPage(pages)} className="page-link" to="#">
                  {pages}
                </Link>
              </li>
            })}

            <li className="page-item next" onClick={() => nextPageHandler()}>
              <Link className="page-link" to="#">
                <i className="fas fa-angle-right" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      </div>
    </>
  );
};

export default GridInnerPage;
