import React, { useContext, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import {
  Bell,
  Book,
  DollarSign,
  Home,
  Lock,
  PieChart,
  Power,
  RefreshCw,
  Server,
  Settings,
  ShoppingBag,
  Star,
  Trash2,
  User,
  Users,
} from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import {
  InstructorProfileBg,
  User15,
  userprofile,
  gradientImage,
} from "../../imagepath";
import AuthContext from "../../../context/AuthProvider";
import axios from "../../../api/axios";
import { Button, Nav, NavDropdown } from "react-bootstrap";
import Select from "react-select";
import "./index.css";
import { notification, Modal } from "antd";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { Divider } from "@mui/material";

const LOGOUT = "/api/logout";
const COURSECATEGORY = "/api/admin/course/menu";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function AdminInstructorSidebar({ activeMenu }) {
  console.log(activeMenu)
  // console.log(activeMenu,"active")
  // const { auth, setAuth } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleShow = async (id) => {
    setShow(true);
    setIsModalVisible(true);
  };
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const token = localStorage.getItem("token");
  const name_admin = localStorage.getItem("nameuser");
  const [showCoursesDropdown, setShowCoursesDropdown] = useState(false);
  const [showCoursesDropdown1, setShowCoursesDropdown1] = useState(false);
  // const [courses, setCourses] = useState([]);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { auth, courses, courseslist } = useContext(AuthContext);

  const options = [
    { label: "Recorded Videos", value: "/admin/add-video" },
    { label: "Courses", value: "/admin/addcourse" },
    { label: "Students", value: "/admin/adduser" },
    { label: "Live Classes", value: "/admin/live-classes-create" },
  ];
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "#FFDEDA",
      border: state.isFocused ? 0 : 0,
      paddingLeft: "5px",
      paddingTop: "5px",
      paddingBottom: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        border: state.isFocused ? 0 : 0,
        color: "black",
      },
      borderRadius: "50px",
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "2px" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  const handleNew = (e) => {
    navigate(e.value);
    sessionStorage.setItem("default", JSON.stringify(e));
  };
  let retrievedObject = JSON.parse(sessionStorage.getItem("default"));
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Logout Succesful</strong>
        </div>
      ),
      duration: 1,

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      // </div>)

      // </div>)
    });
  };
  // const courseslist = async () => {
  //   try {
  //     const response = await axios.get(COURSECATEGORY, config);
  //     setCourses(response?.data?.message);
  //     // console.log(response, "response");
  //   } catch (error) {}
  // };
  // useEffect(() => {
  //   courseslist();
  // }, []);
  // console.log(courses,"courses")
  const logOut = async () => {
    try {
      const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
      // console.log(lastVisitedRoute, "lastVisitedRoute");
      localStorage.removeItem("lastVisitedRoute");
      // console.log(localStorage.removeItem("lastVisitedRoute"), "after lagout");
      localStorage.removeItem("token");

      const response = await axios.post(LOGOUT, {}, config);

      // console.log(response)

      if (response) {
        // toast.success("Logout Successful");
        openNotificationWithIcon("success");

        setTimeout(() => {
          navigate("/admin/login");
        }, 1000);
        setAuth({});
      }
      if (response == 401) {
        navigate("/admin/login");
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      openNotificationWithIcon2("error");
    }
  };
  const openNotificationWithIcon2 = (type) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  const handleClose = () => setShow(false);

  // const [selectedCourse, setSelectedCourse] = useState(null);

  // const handleCourseClick = (courseId) => {
  //   setSelectedCourse(courseId);
  // };
  const [openCourses, setOpenCourses] = useState({});

  const handleClick = (courseId, isDropdown) => {
    if (isDropdown) {
      setOpenCourses((prevOpenCourses) => ({
        ...prevOpenCourses,
        [courseId]: !prevOpenCourses[courseId],
      }));
    }
  };
  const handleClick1 = () => {
    setOpenCourses(!open);
  };

  const centername=sessionStorage.getItem("centername")
  return (
    <div className="col-xl-3 col-lg-3 col-md-12 theiaStickySidebar settings-widget11">
      <div className="">
        <Modal
          title="Create New"
          okButtonProps={{
            style: { backgroundColor: "#FF875A" },
            children: "Import",
          }}
          open={isModalVisible}
          okText="Create"
          onOk={() => {
            setIsModalVisible(false);
          }}
          onCancel={() => {
            setIsModalVisible(false);
          }}
        >
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {/* <Form.Label>Upload File</Form.Label> */}
              <Select
                // className="select2-container"
                options={options}
                defaultValue={retrievedObject}
                // defaultValue={value}
                placeholder="Create New"
                onChange={(e) => {
                  handleNew(e);
                }}
                styles={style}
              ></Select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <Form.Label>Example textarea</Form.Label>
                                  <Form.Control as="textarea" rows={3} /> */}
            </Form.Group>
          </Form>
        </Modal>

        {windowDimensions.width >= "991" ? (
          <>
            {/* <Divider /> */}
            <div
              className=" account-settings feather-icon"
              // style={{ backgroundColor: "#ffece6" }}
            >
              <List
                sx={{
                  width: "90%",
                  maxWidth: 370,
                  // bgcolor: "#ffece6",
                  borderRadius: "10px",
                  // padding:"10px"
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    className="settings-menu"
                    // style={{ backgroundColor: "#ffece6", borderRadius: "10px" }}
                  >
                    <h3 style={{textTransform:"capitalize"}}>{centername?`${centername} Center`:"Dashboard"}</h3>
                  </ListSubheader>
                }
              >
                <li className="nav-item">
                  <ListItemButton
                    className={
                      activeMenu === "Dashboard"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    // style={{padding:"10px"}}
                    component={Link}
                    to="/admin/dashboard"
                    // onClick={() => handleMenuClick("Dashboard")}
                  >
                    <ListItemIcon>
                      <Home size={20} style={{ color: "#ff875a" }} />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        fontSize: "18px",
                        fontWeight: "400",
                        color: "#392C7D",
                      }}
                      primary="Dashboard"
                    />
                  </ListItemButton>
                </li>

                <ListItemButton
                  className={
                    activeMenu === "Batches" ? "nav-item active" : "nav-item"
                  }
                  component={Link}
                  to="/admin/batchList"
                  // onClick={() => handleMenuClick("Batches")}
                >
                  <ListItemIcon>
                    <Server size={20} style={{ color: "#ff875a" }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Batches"
                  />
                </ListItemButton>

                <div key={"courses"}>
      <ListItemButton
        onClick={() => handleClick("courses", true)}
        className={
          activeMenu === "Courses" ? "nav-item active" : "nav-item"
        }
        component={Link}
        to="/admin/course/category"
      >
        <ListItemIcon>
          <i
            className="fas fa-book-reader"
            style={{ fontSize: "16px", color: "#ff875a" }}
          ></i>
        </ListItemIcon>
        <ListItemText primary="Courses" />
        {openCourses["courses"] ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        in={openCourses["courses"]}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {courses.map((mainCourse) => (
            <div key={mainCourse?.id}>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleClick(mainCourse?.id, false)}
                component={Link}
                to={`/admin/course-module/${mainCourse?.id}`}
                className={
                  activeMenu === `CoursesModule${mainCourse?.id}` ? `nav-item active${mainCourse?.id}` : "nav-item"
                }
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary={mainCourse.name} />
                {openCourses[mainCourse?.id] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItemButton>
            </div>
          ))}
        </List>
      </Collapse>
    </div>
                <ListItemButton
                  className={
                    activeMenu === "Videos" ? "nav-item active" : "nav-item"
                  }
                  component={Link}
                  to="/admin/recorded-videos-list"
                >
                  <ListItemIcon>
                    <i
                      className="fa-solid fa-video"
                      style={{ fontSize: "16px", color: "#ff875a" }}
                    ></i>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Videos"
                  />
                </ListItemButton>

                <ListItemButton
                  className={
                    activeMenu === "Test" ? "nav-item active" : "nav-item"
                  }
                  component={Link}
                  to="/admin/test-series"
                  // onClick={() => handleMenuClick("Test")}
                >
                  <ListItemIcon>
                    <i
                      className="fas fa-book-reader"
                      style={{ fontSize: "16px", color: "#ff875a" }}
                    ></i>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Test Series"
                  />
                </ListItemButton>

                <ListItemButton
                  className={
                    activeMenu === "LiveClasses"
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/live-classes"
                  // onClick={() => handleMenuClick("LiveClaasses")}
                >
                  <ListItemIcon>
                    <i
                      className="fa fa-television"
                      aria-hidden="true"
                      style={{ fontSize: "16px", color: "#ff875a" }}
                    ></i>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Live Classes"
                  />
                </ListItemButton>

                <ListItemButton
                  className={
                    activeMenu === ("StudentList"|| "studentGrid")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/student-list"
                  // onClick={() => handleMenuClick("StudentsList")}
                >
                    <ListItemIcon>
                      <Users size={20} style={{ color: "#ff875a" }} />
                    </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="All Students"
                  />
                </ListItemButton>

                <ListItemButton
                  className={
                    activeMenu === ("Teachers" || "StudentGrid")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/teachers"
                  // onClick={() => handleMenuClick("Teachers")}
                >
                  <ListItemIcon>
                    <ListItemIcon>
                      <Users size={20} style={{ color: "#ff875a" }} />
                    </ListItemIcon>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Teachers"
                  />
                </ListItemButton>
                <ListItemButton
                  className={
                    activeMenu === ("Enquiry" || "StudentGrid")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/enquiry-data"
                  // onClick={() => handleMenuClick("Enquiry")}
                >
                  <ListItemIcon>
                    <ListItemIcon>
                      <i
                        class="fa-solid fa-user-graduate"
                        style={{ fontSize: "16px", color: "#ff875a" }}
                      ></i>
                    </ListItemIcon>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Enquiry Data"
                  />
                </ListItemButton>
                <ListItemButton
                  className={
                    activeMenu === ("paid" || "StudentGrid")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/paid-student"
                  // onClick={() => handleMenuClick("paid")}
                >
                  <ListItemIcon>
                    <ListItemIcon>
                      <i
                        className="fa-solid fa-user-graduate"
                        style={{ fontSize: "16px", color: "#ff875a" }}
                      ></i>
                    </ListItemIcon>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Paid Students"
                  />
                </ListItemButton>

                <ListItemButton
                  className={
                    activeMenu === ("expiry" || "StudentGrid")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/expiry-student"
                  // onClick={() => handleMenuClick("expiry")}
                >
                  <ListItemIcon>
                    <ListItemIcon>
                      <i
                        className="fa fa-credit-card"
                        style={{ fontSize: "16px", color: "#ff875a" }}
                      ></i>
                    </ListItemIcon>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Installment"
                  />
                </ListItemButton>

                <ListItemButton
                  className={
                    activeMenu === ("exams" || "StudentGrid")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/exam-allerts"
                  // onClick={() => handleMenuClick("exams")}
                >
                  <ListItemIcon>
                    <ListItemIcon>
                      <i
                        className="fa fa-book-reader"
                        style={{ fontSize: "16px", color: "#ff875a" }}
                      ></i>
                    </ListItemIcon>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Exam Alerts"
                  />
                </ListItemButton>
                <ListItemButton
                  className={
                    activeMenu === ("centers" || "StudentGrid")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/centers"
                  // onClick={() => handleMenuClick("Batches")}
                >
                  <ListItemIcon>
                    <ListItemIcon>
                      <i
                        className="fas fa-file-invoice"
                        style={{ fontSize: "16px", color: "#ff875a" }}
                      ></i>
                    </ListItemIcon>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Academy Centers"
                  />
                </ListItemButton>
                <ListItemButton
                  className={
                    activeMenu === ("feeds" || "StudentGrid")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/feeds"
                  // onClick={() => handleMenuClick("Batches")}
                >
                  <ListItemIcon>
                    <ListItemIcon>
                      <i
                        className="fas fa-thumbs-up"
                        style={{ fontSize: "16px", color: "#ff875a" }}
                      ></i>
                    </ListItemIcon>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Feeds"
                  />
                </ListItemButton>
                <ListItemButton
                  className={
                    activeMenu === ("images" || "StudentGrid")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/images"
                  // onClick={() => handleMenuClick("Batches")}
                >
                  <ListItemIcon>
                    <ListItemIcon>
                      <i
                        className="fas fa-camera"
                        style={{ fontSize: "16px", color: "#ff875a" }}
                      ></i>
                    </ListItemIcon>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Professional Images"
                  />
                </ListItemButton>

                <ListItemButton
                  className={
                    activeMenu === ("invoice" || "StudentGrid")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/invoice"
                  // onClick={() => handleMenuClick("Batches")}
                >
                  <ListItemIcon>
                    <ListItemIcon>
                      <i
                        className="fas fa-file-invoice"
                        style={{ fontSize: "16px", color: "#ff875a" }}
                      ></i>
                    </ListItemIcon>
                  </ListItemIcon>
                  
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Fee Invoice"
                  />
                </ListItemButton>
                <ListItemButton
                  className={
                    activeMenu === "contact"
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/contacts"
                  // onClick={() => handleMenuClick("LiveClaasses")}
                >
                  <ListItemIcon>
                    <i
                      className="fa fa-address-book"
                      aria-hidden="true"
                      style={{ fontSize: "16px", color: "#ff875a" }}
                    ></i>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Contacts"
                  />
                </ListItemButton>
                <ListItemButton
                  className={
                    activeMenu === "transaction"
                      ? "nav-item active"
                      : "nav-item"
                  }
                  component={Link}
                  to="/admin/transaction"
                  // onClick={() => handleMenuClick("LiveClaasses")}
                >
                  <ListItemIcon>
                    <i
                      className="fa fa-credit-card"
                      aria-hidden="true"
                      style={{ fontSize: "16px", color: "#ff875a" }}
                    ></i>
                  </ListItemIcon>
                  <ListItemText
                    style={{ fontSize: "18px", fontWeight: "400" }}
                    primary="Transactions "
                  />
                </ListItemButton>
              </List>
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      <ToastContainer />
    </div>
  );
}
