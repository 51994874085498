import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../adminHeader";
import Footer from "../../footer";

import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import { Input,  Tooltip } from "antd";
import Button from "react-bootstrap/Button";

import axios from "../../../api/axios";
import CryptoJS from "crypto-js";

const COURSECATEGORYLIST = "/api/course-category/index";
const DELETECOURSECATEGORY="api/course-category/destroy";
const ADDCATEGORY = "/api/course-category/create";
const STATUSCHANGE="/api/course-category/status";
const COURSESEARCH="/api/course-category/category-search";

import "react-toastify/dist/ReactToastify.css";
import { notification, Modal } from "antd";
import Form from "react-bootstrap/Form";
import "../addAdminRecordedVideos/insex.css";
import moment from "moment";
const { confirm } = Modal;
import Switch from "react-switch";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";

export default function AdminFeedsCategory() {
  const { Search } = Input;
  const [install, setinstall] = useState(null);
  const [status, setSatus] = useState("");
  const[category,setCategory]=useState("")
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [last_page, setLast_page] = useState("");
  const [text, settext] = useState("");
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();


  // const [itemsPerPage, setitemsPerPage] = useState('')
  const [userid, setuserid] = useState("");
  const [batchid, setbatchid] = useState("");
  const [courseid, setcourseid] = useState("");
  const [paydata, setpaydata] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [message, setmessage] = useState("No Data");
  const [total_amount, settotal_amount] = useState("");
  const [remaing, setRemaing] = useState("");
  const [sum, setsum] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(new Date());

  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const fetchCourseCategory = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(COURSECATEGORYLIST, config);
    console.log(resonse?.data?.data,"resonse")
    
      // setitemsPerPage(batch_detail_relation.length)
      setList(resonse?.data?.data);
      setData(resonse?.data?.data);
      setLast_page(resonse?.data?.last_page)
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };

  useEffect(() => {
    fetchCourseCategory();
  }, []);
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.put(
      STATUSCHANGE,
      { id: id, status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1("success","Status Updated", "#52c41a");
    }
    if (!response) {
      openNotificationWithIcon2("error","Internal Server","red");
    }
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
  };
  

  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon1 = (type, text, colors) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type, text, colors) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const visiblerecordsData = list?.slice(indexOfFirstCourse, indexOfLastCourse);
  const recordsDataLength = list?.length ? list?.length : 0;
  const numOFTotalPage = Math.ceil(recordsDataLength / 10);
  const pages = [...Array(numOFTotalPage + 1).keys()].slice(1);
 
  const handleAddUser = async () => {
    try {
      const formData = new FormData();
      formData.append("status", status);
      formData.append("name", category);
      formData.append("image", file);
      const response = await axios.post(
        
        ADDCATEGORY,
        formData,
        config
      );
      if (response) {
        setIsModalVisibleForm(false);
        openNotificationWithIcon1("success", "Category Created", "#52c41a");
        setSatus("");
        setCategory("")
        setFile(null)
        setFilePre("")
        fetchCourseCategory();
       
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status == 403) {
        openNotificationWithIcon1("error", "403", "red");
      } else {
        openNotificationWithIcon1("error", error.response.data.message, "red");
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  console.log(status,"status",file)

  
  const handleCreate = () => {
  
    handleShowForm();
  };
  const handleModalClose = () => {
    setIsModalVisibleForm(false);
    setCategory('');
    setSatus(''); // Resetting status
    setFile(null); // Resetting file
    setFilePre(''); // Resetting filePre
  };

  const Filter = async (e) => {
    const searchValue = e.target.value;
    settext(searchValue);
    const res = await axios.post(COURSESEARCH, { name: searchValue }, config);
    if (res.data.data.length > 0) {
      setList(res.data.data);
      setCurrentPage(res.data.current_page);
      setLast_page(res.data.last_page);
      setmessage('No Data')
    }
    if (res.data.data.length === 0) {
      setList(res?.data?.data)
      setmessage('No Data Found')
    }
    if(searchValue.length=== 0){
      const res = await axios.get(`${COURSECATEGORYLIST}?page=${currentPage}`, config);
      setList(data);
      setCurrentPage(1);
      setLast_page(res.data.last_page);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  let itemsPerPage = 10;
 
  const handleClose = () => setShow(false);

  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this Course Category?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.delete(`${DELETECOURSECATEGORY}/${id}`, config);
          // console.log("resonse", resonse);
          openNotificationWithIcon1("success","Category Deleted","#52c41a");

          setLoading(false);
          fetchCourseCategory();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          if (error?.response?.status === 422) {
            openNotificationWithIcon2(error?.response?.data?.message,"error");

          }
          openNotificationWithIcon2(error?.response?.data?.message,"error");
        }
      },
      onCancel() {},
    });
  }
 
  console.log(list,"list")
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Courses"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget ttt">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <div>
                          <h3 style={{ textTransform: "capitalize" }}>
                            Course Category
                          </h3>
                          <nav
                            aria-label="breadcrumb"
                            style={{ marginBottom: "-1.6em" }}
                          >
                            <ol class="breadcrumb">
                              <li
                                class="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate("/admin/course")
                                }
                              >
                                Courses
                              </li>
                              <li
                                class="breadcrumb-item active"
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                // onClick={() =>
                                //   navigate("/admin/recorded-videos")
                                // }
                              >
                                {" "}
                                Course Category
                              </li>
                            </ol>
                          </nav>
                          </div>
                         
                        </div>
                        <div className="comman-space pb-0">
                          <div
                            // className="settings-referral-blk table-responsive"
                            // id="table-value"
                          >
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row gx-2 align-items-center">
                                    <div className="col-md-6 col-item">
                                      <div className=" search-group">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Name"
                                          allowClear
                                          onChange={Filter}
                                          onKeyPress={handleKeyPress}
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-item">
                                      <div
                                        className="form-group select-form mb-0 d-flex justify-content-end"
                                        // style={{  marginBottom:"-5px"}}
                                      >
                                        <Button
                                          onClick={() =>
                                            handleCreate()
                                          }
                                          style={
                                            {
                                              // marginLeft: "5.2em",
                                              // width:"100%"
                                            }
                                          }
                                          className="btn btn-primary"
                                          id="custom-button"
                                        >
                                          {" "}
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Create New
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                            <Modal
                              title="Add Course Category"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              visible={isModalVisibleForm}
                              okText="Create"
                              onOk={() => {
                                handleAddUser();
                              }}
                              onCancel={handleModalClose}
                            >
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Category Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    placeholder="enter Category Name"
                                  />
                                </Form.Group>
                                <div className="col-lg-6" >
                          <div className="form-group">
                            <label>Status :</label>
                            <br />
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="status"
                                id="inlineRadios1"
                              onClick={()=>setSatus("0")}
                                value="0"
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadios1"
                              >
                                Inactive
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="status"
                                id="inlineRadios2"
                                onClick={()=>setSatus("1")}
                                value="1"
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadios2"
                              >
                                Active
                              </label>

                            </div>
                            {/* <div>
                              {errors?.status && touched?.status ? (
                                <span style={{ color: "red" }}>
                                  {errors?.status}
                                </span>
                              ) : null}
                            </div> */}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">
                              Cover image
                            </label>
                            <input
                              type="file"
                              name="image"
                              id="image"
                              onChange={(e) => {
                                setFile(e.target.files[0]),
                                  setFilePre(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                              }}
                              className="form-control"
                              placeholder="Cover Image"
                            />
                            {/* {errors.image && touched.image ? (
                              <span style={{ color: "red" }}>
                                {errors.image}
                              </span>
                            ) : null} */}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div
                              className="add-image-box"
                              style={{ minHeight: "0", height: "200px" }}
                            >
                              {!filePre && (
                                <Link to="#" style={{ fontSize: "200px" }}>
                                  <i className="far fa-image" />
                                </Link>
                              )}
                              {filePre && (
                                <img
                                  src={filePre}
                                  style={{
                                    height: "200px",
                                    width: "100%",
                                    borderRadius: "10px",
                                  }}
                                ></img>
                              )}
                            </div>
                          </div>
                        </div>
                              </Form>
                            </Modal>
                           
                           
                            <table className="table table-nowrap mt-2">
                              <thead>
                                <tr className="text-center">
                                  <th>SR.NO</th>
                                  <th>Name</th>
                                  <th>Image</th>
                                  <th>Status</th>
                                 
                                  <th>ACTION</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div
                                          class="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span class="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </div>
                              ) : list?.length > 0 ? (
                                list?.map((item, i) => {
                                  const serialNumber =
                                    (currentPage - 1) * itemsPerPage + i + 1;
                                  return (
                                    <tbody
                                      key={i}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <tr className="text-center">
                                        <td>{serialNumber}</td>
                                        <td>
                                          <Link
                                            // to="student-profile"
                                            className="refer-avatar-blk align-items-center"
                                          >
                                            <p>{item?.name}</p>
                                          </Link>
                                        </td>
                                       <td>
                                       <div
                                              style={{
                                                justifyContent:"center"
                                              }}
                                              className="sell-table-group d-flex align-items-center"
                                              
                                            >
                                                <div
                                                  className="sell-group-img"
                                                  style={{ height: "none" }}
                                                >
                                                  <Link>
                                                    <img
                                                      src={item?.image}
                                                      style={{
                                                        borderRadius: "15px",
                                                        width: "90%",
                                                        height: "90%",
                                                        objectFit: "cover",
                                                      }}
                                                      className="img-fluid "
                                                      alt=""
                                                    />
                                                  </Link>
                                                </div>
                                              
                                            </div>
                                       </td>
                                        <td>
                                          <Switch
                                            onChange={(e) => {
                                              handleChange(
                                                item?.id,
                                                item?.status
                                              );
                                            }}
                                            checked={
                                              item?.status
                                              === "1"
                                                ? true
                                                : false
                                            }
                                            id={item?.id}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={38}
                                            className="react-switch"
                                          />
                                        </td>                                        
                                        <td>
                                          {/* <Tooltip title="Edit">
                                            <Button
                                              onClick={() =>
                                                handleEdit(
                                                  item.batch_id,
                                                  item.expiry_date,
                                                  item.user_id,
                                                  item.course_id
                                                )
                                              }
                                              type="button"
                                              class="btn btn-secondary"
                                            >
                                              <i class="fa fa-credit-card"></i>
                                            </Button>{" "}
                                          </Tooltip>
                                          <Tooltip title="View">
                                            <button
                                              onClick={() =>
                                                handleView(
                                                  item.user_id,
                                                  item.course_id
                                                )
                                              }
                                              // type="button"
                                              class="btn btn-info"
                                            >
                                              <i
                                                style={{ color: "white" }}
                                                class="fa fa-eye"
                                                aria-hidden="true"
                                              ></i>
                                            </button>{" "}
                                          </Tooltip> */}
                                          <Tooltip title="Delete">
                                              {" "}
                                              <Button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                  showConfirm(item?.id)
                                                }
                                              >
                                                <i className="fa-regular fa-trash-can"></i>
                                              </Button>
                                            </Tooltip>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              ) : (
                                <div
                                  // class="spinner-border text-warning"
                                  // style={{
                                  //   left: "50%",
                                  //   marginLeft: "24em",
                                  //   // marginTop: "10em",
                                  // }}
                                  role="status"
                                >
                                  <span class="sr-only">
                                    "No Data"
                                  </span>
                                </div>
                              )}
                            </table>
                            </div>
                            {list?.length <= 0 ? (
                              <h5 class="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {list ?.length <= 0  ? (
                              ""
                            ) : (
                              <PaginationVideo
                                nPages={last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={COURSECATEGORYLIST}
                                setData={setList}
                                text={text}
                                setLast_page={setLast_page}
                                linkSearch={COURSESEARCH}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
}
