import React from "react";
import { Link } from "react-router-dom";
import { Qute, Qute01, User1, User2, User3 } from "../../imagepath";
// import OwlCarousel from "react-owl-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial = () => {
  return (
    <section className="testimonial-four"
     style={{marginTop:"70px"}}
    >
      <div className="review"
       style={{bottom:"0px",position:"unset"}}
      >
        <div className="container">
          <div className="testi-quotes">
            <img src={Qute} alt="" />
          </div>
          <div
            className="mentor-testimonial lazy slider aos"
            data-aos="fade-up"
            data-sizes="50vw"
            
          >
            <Slider>
              <Card />
              <Card2 />
              <Card3 />
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};
const Card = () => {
  return (
    <div className="d-flex justify-content-center"
    style={{ backgroundImage:"linear-gradient(#57504d, #f2f3f6)"}}
    >
      <div className="testimonial-all d-flex justify-content-center">
        <div className="testimonial-two-head text-center align-items-center d-flex">
          <div className="testimonial-four-saying ">
            <div className="testi-right">
              <img src={Qute01} alt="" />
            </div>

            <p style={{color:"black"}}>
              Ishita Kishore, a Delhi University graduate and the daughter of an
              Air Force officer, secured AIR 1 in the UPSC Civil Services
              Examination (CSE), 2022. The 26-year-old woman passed the
              prestigious exam on her third attempt. She failed her preliminary
              exam on each of her first two attempts.
            </p>

            <div className="four-testimonial-founder">
              <div className="fount-about-img">
                <Link to="">
                  <img
                    src={"https://chahalacademy.com/images/air-1-2022.webp"}
                    alt=""
                    style={{ width: "50%", height: "50%" }}
                    className="img-fluid"
                  />
                </Link>
              </div>

              {/* <h3>
                <Link to=""></Link>
              </h3>
              <span>Founder of Awesomeux Technology</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Card2 = () => {
  return (
    <div className="d-flex justify-content-center"
    style={{ backgroundImage:"linear-gradient(#57504d, #f2f3f6)"}}

    >
      <div className="testimonial-all d-flex justify-content-center">
        <div className="testimonial-two-head text-center align-items-center d-flex">
          <div className="testimonial-four-saying ">
            <div className="testi-right">
              <img src={Qute01} alt="" />
            </div>
            <p>
              Shruti Sharma is an alumnus of St. Stephens College, Delhi, and
              hails from Bijnor, Uttar Pradesh. She pursued her Postgraduation
              from Delhi School of Economics. She had always wanted to serve
              society in one way or another and saw IAS services with the most
              scope of serving the underprivileged and therefore decided to
              pursue civil services.
            </p>
            <div className="four-testimonial-founder">
              <div className="fount-about-img">
                <Link to="">
                  <img
                    src={"https://chahalacademy.com/images/air-1-2021.webp"}
                    alt=""
                    style={{ width: "50%", height: "50%" }}
                    className="img-fluid"
                  />
                </Link>
              </div>
              {/* <h3>
                <Link to="">john smith</Link>
              </h3>
              <span>Founder of Awesomeux Technology</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Card3 = () => {
  return (
    <div className="d-flex justify-content-center"
    style={{ backgroundImage:"linear-gradient(#57504d, #f2f3f6)"}}

    >
      <div className="testimonial-all d-flex justify-content-center">
        <div className="testimonial-two-head text-center align-items-center d-flex">
          <div className="testimonial-four-saying ">
            <div className="testi-right">
              <img src={Qute01} alt="" />
            </div>
            <p>
              Shubham started his preparation in 2017 and secured AIR-1 in his
              3rd attempt. He believes in saying ''Where there is a will, there
              is the way". His father and his commitment to serve people were
              the source of his motivation.
            </p>
            <div className="four-testimonial-founder">
              <div className="fount-about-img">
                <Link to="">
                  <img
                    src={"https://chahalacademy.com/images/air-1-2020.webp"}
                    style={{ width: "50%", height: "50%" }}
                    alt=""
                    className="img-fluid"
                  />
                </Link>
              </div>
              {/* <h3>
                <Link to="">David Lee</Link>
              </h3>
              <span>Founder of Awesomeux Technology</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// {
//   <section className="testimonial-four">
//       <div className="review">
//         <div className="container">
//           <div className="testi-quotes">
//             <img src={Qute} alt="" />
//           </div>
//           <div
//             className="mentor-testimonial lazy slider aos"
//             data-aos="fade-up"
//             data-sizes="50vw"
//           >
//             <div className="d-flex justify-content-center">
//               <div className="testimonial-all d-flex justify-content-center">
//                 <div className="testimonial-two-head text-center align-items-center d-flex">
//                   <div className="testimonial-four-saying ">
//                     <div className="testi-right">
//                       <img src={Qute01} alt="" />
//                     </div>
//                     <p>
//                       Lorem Ipsum is simply dummy text of the printing and
//                       typesetting industry. Lorem Ipsum has been the industry's
//                       standard dummy text ever since the 1500s, when an unknown
//                       printer took a galley of type and scrambled it to make a
//                       type specimen book.
//                     </p>
//                     <div className="four-testimonial-founder">
//                       <div className="fount-about-img">
//                         <Link to="instructor-profile">
//                           <img src={User1} alt="" className="img-fluid" />
//                         </Link>
//                       </div>
//                       <h3>
//                         <Link to="instructor-profile">Daziy Millar</Link>
//                       </h3>
//                       <span>Founder of Awesomeux Technology</span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="d-flex justify-content-center">
//               <div className="testimonial-all d-flex justify-content-center">
//                 <div className="testimonial-two-head text-center align-items-center d-flex">
//                   <div className="testimonial-four-saying ">
//                     <div className="testi-right">
//                       <img src={Qute01} alt="" />
//                     </div>
//                     <p>
//                       Lorem Ipsum is simply dummy text of the printing and
//                       typesetting industry. Lorem Ipsum has been the industry's
//                       standard dummy text ever since the 1500s, when an unknown
//                       printer took a galley of type and scrambled it to make a
//                       type specimen book.
//                     </p>
//                     <div className="four-testimonial-founder">
//                       <div className="fount-about-img">
//                         <Link to="instructor-profile">
//                           <img src={User3} alt="" className="img-fluid" />
//                         </Link>
//                       </div>
//                       <h3>
//                         <Link to="instructor-profile">john smith</Link>
//                       </h3>
//                       <span>Founder of Awesomeux Technology</span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="d-flex justify-content-center">
//               <div className="testimonial-all d-flex justify-content-center">
//                 <div className="testimonial-two-head text-center align-items-center d-flex">
//                   <div className="testimonial-four-saying ">
//                     <div className="testi-right">
//                       <img src={Qute01} alt="" />
//                     </div>
//                     <p>
//                       Lorem Ipsum is simply dummy text of the printing and
//                       typesetting industry. Lorem Ipsum has been the industry's
//                       standard dummy text ever since the 1500s, when an unknown
//                       printer took a galley of type and scrambled it to make a
//                       type specimen book.
//                     </p>
//                     <div className="four-testimonial-founder">
//                       <div className="fount-about-img">
//                         <Link to="instructor-profile">
//                           <img src={User2} alt="" className="img-fluid" />
//                         </Link>
//                       </div>
//                       <h3>
//                         <Link to="instructor-profile">David Lee</Link>
//                       </h3>
//                       <span>Founder of Awesomeux Technology</span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
// }

export default Testimonial;
