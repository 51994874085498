import React from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../../api/axios";
import { useState } from "react";
// import {ZoomMtg} from '@zoomus/websdk'
const host ='https://lms.stellarflux.in'
const local ='http://127.0.0.1:3000' 
const JoinMeeting = () => {
  const navigate= useNavigate()
  useEffect(() => {    
   window.addEventListener("popstate", () => {
  window.location.reload()
});
 }, []);
  const details = JSON.parse(localStorage.getItem("details"));
  const name = localStorage.getItem("nameuser");
  let payload = {
    meetingNumber: details?.meeting_id,
    role: 0,
    sdkKey: "60SaBHXGQheVtxBp1_0Xzw",
    sdkSecret: "DVBOoLB5zxrBKSj5CR2xG7QgO5yVToRZ",
    passWord: details?.password,
    userName: name,
    userEmail: "",
    leaveUrl: `${host}/student/std-live-class`,
  };
  const fetchZoom = async () => {
    const { ZoomMtg } = await import("@zoomus/websdk");
    ZoomMtg.setZoomJSLib("https://source.zoom.us/2.13.0/lib", "/av");

    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    // loads language files, also passes any error messages to the ui
    ZoomMtg.i18n.load("en-US");
    ZoomMtg.i18n.reload("en-US");
    ZoomMtg.generateSDKSignature({
      meetingNumber: payload.meetingNumber,
      role: payload.role,
      sdkKey: payload.sdkKey,
      sdkSecret: payload.sdkSecret,
      success: function (signature) {
        ZoomMtg.init({
          debug: true,
          leaveUrl: payload.leaveUrl,
          isSupportAV: true,
          disableInvite: true,
          // isSupportAV: true, meetingInfo: [ 'topic', 'host', ] ,
          success: function (data) {
            ZoomMtg.join({
              meetingNumber: payload.meetingNumber,
              signature: signature.result,
              sdkKey: payload.sdkKey,
              userName: payload.userName,
              userEmail: payload.userEmail,
              passWord: payload.passWord,
              tk: "",
              success: function () {
                // console.log("-----------joined--------");
              },
              error: function (error) {
                console.log(error);
              },
            });
          },
          error: function (error) {
            console.log(error);
          },
        });
      },
      error: function (error) {
        console.log(error);
      },
    });
  };
  useEffect(() => {
    fetchZoom();
  });
  return <></>;
};

export default JoinMeeting;
