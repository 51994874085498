import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthProvider";

const ProtectedStudent = ({ Component }) => {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  useEffect(() => {
    const tokenStudent =localStorage.getItem('tokenStudent')
    // const token = auth.tokenStudent;
    if (!tokenStudent) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <Component />
    </>
  );
};

export default ProtectedStudent;
