import React, { useContext, useEffect, useState } from "react";
import { InstructorHeader } from "../../instructor/header";
import AdminHeader from "../adminHeader";
import Footer from "../../footer";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import Button from "react-bootstrap/Button";
import Switch from "react-switch";
import AuthContext from "../../../context/AuthProvider";
import axios from "../../../api/axios";
import CryptoJS from "crypto-js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip, Input } from "antd";
import { notification, Space } from "antd";
import { Spin } from "antd";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";
import Courses from "../../../assets/img/Dashboard/admincourse.png";

const FEEDLIST = "/api/feeds/index";
const DELETEFEEDS = "/api/feeds/destroy";
const FEEDSTATUS = "/api/feeds/statusUpdate";
const FEEDSEARCH = "/api/feeds/search";
const COURSEVIDEO = "/api/admin/course/active";
const COURSEINDEX = "/api/course/course-order";

import { Modal } from "antd";

const { confirm } = Modal;

export default function AdminCourseIndex() {
  const { Search } = Input;
  const [show, setShow] = useState(false);
  const [last_page, setLast_page] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(null);
  const [text, settext] = useState("");
  const [list, setList] = useState(null);
  const [index, setIndex] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const token = localStorage.getItem("token");
  const [message, setmessage] = useState("No Data");
  const [draggedItemId, setDraggedItemId] = useState(null);
  const [targetItems, setTargetItems] = useState([]);
  const [droppedItems, setDroppedItems] = useState([]);
  const [draggedItemIndex, setDraggedItemIndex] = useState(null);
  const { auth } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  localStorage.setItem("lastVisitedRoute", window.location.pathname);
  const [droppedItemId, setDroppedItemId] = useState(null);
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      `${FEEDSTATUS}/${id}`,
      { status: result },
      config
    );

    if (response) {
      openNotificationWithIcon1("success");
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
  };

  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const fecthCoureList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(COURSEVIDEO, config);
      setList(resonse?.data);
      setData(resonse?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      openNotificationWithIcon2("error");
    }
  };

  const CourseIndex = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(COURSEINDEX, config);
      setDroppedItems(resonse?.data?.message);
      setData(resonse?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      openNotificationWithIcon2("error");
    }
  };

  useEffect(() => {
    fecthCoureList();
    CourseIndex();
  }, []);

  const handleClose = () => setShow(false);
  const [api, contextHolder] = notification.useNotification();
  const [toast, setToast] = useState(false);

  const openNotificationWithIcon = (type, text, color) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };

  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Course Added </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const handleDragStart = (id) => {
    setDraggedItemId(id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (side, id) => {
    setDroppedItemId(id);
  
    if (draggedItemId !== null) {
      if (side === 'left') {
        // If you have a different variable for the source list, replace 'list' with that variable
        const draggedItem = list.find(item => item.id === draggedItemId);
        if (draggedItem) {
          setDroppedItems([...droppedItems, draggedItem]);
          setList(list.filter(item => item.id !== draggedItemId));
        }
      } else if (side === 'right') {
        const updatedTargetItems = [...droppedItems];
        const draggedItemIndex = updatedTargetItems.findIndex(item => item.id === draggedItemId);
        const droppedItemIndex = updatedTargetItems.findIndex(item => item.id === droppedItemId);
  
        if (draggedItemIndex !== -1 && droppedItemIndex !== -1) {
          [updatedTargetItems[draggedItemIndex], updatedTargetItems[droppedItemIndex]] =
            [updatedTargetItems[droppedItemIndex], updatedTargetItems[draggedItemIndex]];
          setDroppedItems(updatedTargetItems);
        }
      }
    }
  };
  
console.log(droppedItems,"droppedItems")  
  
  const sendIdToApi = (id) => {
    console.log("ID to send to API:", id);
  };

  const removeDroppedItem = (id) => {
    const updatedDroppedItems = droppedItems.filter((item) => item.id !== id);
    setDroppedItems(updatedDroppedItems);

    const correspondingTargetItem = list?.find((item) => item.id === id);
    setTargetItems([...targetItems, correspondingTargetItem]);
  };

  const getNameById = (id) => {
    const item = list?.find((item) => item.id === id);
    return item ? item.name : "";
  };

  const isItemDropped =  (id) => {
    return droppedItems.some((item) => item.id === id);
  };

  const handleSubmit = async () => {
    const submittedItems = droppedItems?.map((item, index) => ({
      fk_course_id: item.id,
      order: index + 1,
    }));  

    try {
      setLoading(true);
      const resonse = await axios.post(COURSEINDEX,{ data: submittedItems }, config);
      openNotificationWithIcon1("success")
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      openNotificationWithIcon2("error");
    }
  };

  const itemsPerPage = 10;

  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div className="container" style={{ minWidth: "100%", marginTop: "-15px" }}>
          <div className="row">
            <AdminInstructorSidebar activeMenu={"feeds"} />
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space d-flex" style={{ justifyContent: "space-between" }}>
                          <h3>Course Index</h3>
                          <button className="btn btn-primary" onClick={() => { handleSubmit() }}>Submit</button>
                        </div>
                        <div className="comman-space pb-0">
                          <div className="instruct-search-blk">
                            <div className="show-filter choose-search-blk">
                              <form action="#">
                                <div className="row gx-2 align-items-center">
                                  <div className="col-md-12 col-lg-12 col-item">
                                    <div className="form-group select-form mb-0 d-flex justify-content-end"></div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="App">
                            <div className="data-container row">
                              <div className="source-container col-5" style={{ paddingBottom: "20px", height: "250px", overflow: "auto", border:"1px solid #c4bcbc", borderRadius:"8px" }}>
                                <div style={{padding:"10px"}}>
                                  <h5>Courses List</h5>
                                </div>
                                {list?.map((item) => (
                                  <div
                                    className="box source col-8 d-flex"
                                    style={{ margin: "auto", marginTop: "10px" }}
                                    key={item.id}
                                    draggable
                                    onDragStart={() => handleDragStart(item.id)}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <img src={Courses} style={{ width: "70px", height: "70px" }} alt="" />
                                    </div>
                                    <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                                      <h6 style={{ textTransform: "capitalize", marginLeft:"10px" }}>{item?.name}</h6>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className="drop-container col-5" style={{ margin:"auto", border:"1px solid #c4bcbc", borderRadius:"8px", backgroundColor:"#f2f2f3"}}>
                                <div style={{padding:"10px"}}>
                                  <h5>Course Index List</h5>
                                </div>
                                <div
                                  className="box1 target"
                                  onDragOver={handleDragOver}
                                  onDrop={() => handleDrop('left', index)}
                                  style={{ height: "250px", minWidth: "100%", margin: "auto" }}
                                  placeholder="You can drag courses here"
                                >
                                  {droppedItems?.map((item) => (
                                    <div
                                      key={item.id}
                                      className="dropped-item col-9"
                                      draggable
                                      onDragStart={() => handleDragStart(item.id)}
                                      style={{
                                        border: "1px solid rgb(163 192 220)",
                                        padding: "10px",
                                        borderRadius: "8px",
                                        margin: "auto",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img src={Courses} alt="" style={{width:"70px", height:"70px"}} />
                                        <div style={{ alignSelf: "center" }}>
                                          <h6 style={{ textTransform: "capitalize", marginLeft:"10px" }}>{item?.name}</h6>
                                        </div>
                                      </div>
                                      <button
                                        style={{
                                          borderRadius: "50%",
                                          color: "red",
                                          border: "none",
                                          marginRight:"10px"
                                        }}
                                        onClick={() => removeDroppedItem(item.id)}
                                      >
                                        &#x274c;
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          {list?.length <= 0 ? (
                            <h5 className="text-center">{message}</h5>
                          ) : (
                            ""
                          )}
                          {list === null ? (
                            <h5 className="text-center">{message}</h5>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
}
