import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Course10,
  Course11,
  Course12,
  Course13,
  Course14,
  Course15,
  Course16,
  Course17,
  Share,
  Icon2,
  User,
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
} from "../../../imagepath";
import axios from "../../../../api/axios";
// import "../../../../components/student/studentLive/index.css"
import "./gridinner.css";
import { notification, Space } from "antd";
import { BookOpen, Calendar, CreditCard } from "react-feather";

import { useState } from "react";
import { Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import axiosInstance from "../../../../api/axios1";
import StudentSideBar from "../../../student/sidebar";
import { Chip } from "@mui/material";
const GETCOURSE = "/api/student/my-subscriptions";
const GridInnerPage = () => {
  const [data, setData] = useState([]);
  const tokenStudent = localStorage.getItem("tokenStudent");
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };

  const [all, setAll] = useState(false);
  const [free, setFree] = useState(false);
  const [search, setSearch] = useState("");
  const [paid, setPaid] = useState(false);
  const [freeCourses, setFreeCourses] = useState([]);
  const [PaidCourses, setPaidCourses] = useState([]);
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const getCourse = async () => {
    try {
      setLoading(true);
      const reponse = await axiosInstance.get(GETCOURSE, config);
      setList(reponse?.data);
      setData(reponse?.data);
      //  console.log(reponse.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIcon3("error", error?.response?.data?.message,);
        setTimeout(() => {
          navigate("/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon3("error",error?.response?.data?.message);

      }
      setLoading(false);
    }
  };
  useEffect(() => {
    getCourse();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  var visibleCourse = list?.slice(indexOfFirstCourse, indexOfLastCourse);

  const courseLength = list?.length ? list?.length : 0;
  const numOFTotalPage = Math.ceil(courseLength / 10);
  const pages = [...Array(numOFTotalPage + 1).keys()].slice(1);
  const [datavalue, setDataValue] = useState([]);
  // useEffect(() => {
  //   if (visibleCourse) {
  //     setDataValue(list?.slice(indexOfFirstCourse, indexOfLastCourse));
  //   }
  // }, []);

  // const render=()=>{
  //   setDataValue(visibleCourse)
  // }
  // useEffect(()=>{
  //   render()
  // },[])
  //  console.log(datavalue,"value")
  const navigate = useNavigate();
  const RadioCourses = (event) => {
    if (event?.name == "paid") {
      var paidcourse = visibleCourse
        ?.filter((item) => Number(item?.amount) > 0)
        .slice(indexOfFirstCourse, indexOfLastCourse);
      if (event.checked) {
        setPaid(!paid);
        setFree(false);
        setAll(false);
      }

      setData(paidcourse);
      // visibleCourse=paidcourse
    }
    if (event?.name == "free") {
      var freeCourses = visibleCourse
        ?.filter((item) => item?.amount == 0)
        .slice(indexOfFirstCourse, indexOfLastCourse);
      if (event?.checked) {
        setFree(!free);
        setPaid(false);
        setAll(false);
      }

      setData(freeCourses);
      // visibleCourse=freeCourses
    }
    if (event?.name == "all") {
      var allCourse = visibleCourse?.slice(
        indexOfFirstCourse,
        indexOfLastCourse
      );
      if (event?.checked) {
        setAll(!all);
        setPaid(false);
        setFree(false);
      }

      setData(allCourse);
      // visibleCourse=allCourse
    }
  };
  const ClearOptions = () => {
    setSearch("");
    setAll(false);
    setPaid(false);
    setFree(false);
    setData(list);
  };
  useEffect(() => {
    if (list) {
      const filteredFreeCourses = list.filter((item) => item?.amount == 0);
      setFreeCourses(filteredFreeCourses);
    }
  }, [list]);
  useEffect(() => {
    if (list) {
      const filteredPaidCourses = list.filter((item) => item?.amount != 0);
      setPaidCourses(filteredPaidCourses);
    }
  }, [list]);
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const itemsPerPage = 10;

  const renderPagination = () => {
    const pagesToShow = 6;
    const totalPageCount = Math.ceil(courseLength / itemsPerPage);
    // console.log(totalPageCount,"page count")

    if (totalPageCount <= pagesToShow) {
      return [...Array(totalPageCount).keys()].map((pageNum) => (
        <li
          key={pageNum}
          className={
            currentPage === pageNum + 1 ? "page-item active" : "page-item"
          }
        >
          <Link
            className="page-link"
            to="#"
            onClick={() => setCurrentPage(pageNum + 1)}
          >
            {pageNum + 1}
          </Link>
        </li>
      ));
    } else {
      if (currentPage <= 3) {
        for (let i = 0; i < 5; i++) {
          pages.push(
            <li
              key={i}
              className={
                currentPage === i + 1 ? "page-item active" : "page-item"
              }
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li key={totalPageCount - 1} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      } else if (currentPage >= totalPageCount - 2) {
        pages.push(
          <li key={0} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        for (let i = totalPageCount - 5; i < totalPageCount; i++) {
          pages.push(
            <li
              key={i}
              className={
                currentPage === i + 1 ? "page-item active" : "page-item"
              }
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
      } else {
        pages.push(
          <li key={0} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i)}
              >
                {i}
              </Link>
            </li>
          );
        }
        pages.push(
          <li key="ellipsis2" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li key={totalPageCount - 1} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      }
      return pages;
    }
  };
  const prevPageHandler = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const nextPageHandler = () => {
    if (currentPage !== numOFTotalPage) setCurrentPage(currentPage + 1);
  };
  // console.log(list,"list")
  // console.log(visibleCourse,"visible")
  return (
    <>
      <div className="row">
        <StudentSideBar activeMenu={"Subscription"} />
        <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9">
          <div className="col-lg-6">
            <h3 className="mb-3 mb-md-4 mb-lg-5 animate__animated animate__fadeIn live-classes-heading">
              My Subscriptions
            </h3>
          </div>
          { list?.length>0?list?.map((item,index) => {
            var inputDate = item?.expiry_date;
            var dateObject = new Date(inputDate);
            // console.log(dateObject, "date object");
            var day = dateObject.getDate();
            var month = dateObject.getMonth() + 1;
            var year = dateObject.getFullYear();
            var formattedDate = day + "-" + month + "-" + year;
            var inputDate1 = item?.start_date;
            var dateObject1 = new Date(inputDate1);
            var day1 = dateObject1.getDate();
            var month1 = dateObject1.getMonth() + 1;
            var year1 = dateObject1.getFullYear();
            var formattedDate1 = day1 + "-" + month1 + "-" + year1;
            const currentDate = new Date();
            // console.log(currentDate, "currentdata");
            // console.log("date", dateObject < currentDate);
            return (
              <div key={item?.id}>
                <div
                  className="monthly-subscribtion comman-space border-line"
                  style={{ borderBottom: "3px solid rgb(199 199 199 / 25%)" }}
                  key={item?.id}
                >
                  <div
                    className="monthly-group d-flex align-items-center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="subscribtion-active">
                      <div className="active-btns">
                        {dateObject < currentDate ? (
                          <button type="button" className="btn btn-danger">
                            Expired
                          </button>
                        ) : (
                          <button type="button" className="btn btn-success">
                            Active
                          </button>
                        )}
                      </div>
                      <h5 style={{ fontSize: "20px" }}>
                        {item?.course[0]?.name} course
                      </h5>
                      {/* <p>Subscription ID: #100010002</p> */}
                    </div>
                    <div className="active-change-plan grad-border hvr-sweep-to-right">
                      <Chip
                        label={item?.course[0]?.amount > 0 ? "Paid" : "Free"}
                        style={{
                          backgroundColor:
                            item?.course[0]?.amount > 0 ? "#159F46" : "#FF875A",
                          fontSize: "16px",
                          color: "white",
                          padding: "5px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="month-plan-detail">
                    <div className="row">
                      <div className="col-xl-3 col-md-9 d-flex"
                      style={{margin:"auto"}}
                      >
                        <div
                          className=""
                          style={{ margin: "auto", width: "70%" }}
                        >
                          <div
                            className=""
                            style={{ width: "90%", margin: "auto" }}
                          >
                            <img
                              style={{
                                width: "80%",
                                margin: "auto",
                                height:"60%",
                                borderRadius: "4px",
                              }}
                              src={item?.course[0]?.image}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-4 d-flex"
                      style={{marginTop:"20px"}}
                      >
                        <div className="bill-widget d-flex align-items-center">
                          <div className="plan-icons">
                            <Calendar size={20} />
                          </div>
                          <div className="bill-name-group">
                            <h5>Started On</h5>
                            <p>{formattedDate1}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-4 d-flex"
                                            style={{marginTop:"20px"}}

                      >
                        <div className="bill-widget d-flex align-items-center">
                          <div className="plan-icons">
                            <CreditCard size={20} />
                          </div>
                          <div className="bill-name-group">
                            <h5>Price</h5>
                            <p>{item?.course[0]?.amount}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-4 d-flex"
                                            style={{marginTop:"20px"}}

                      >
                        <div className="bill-widget d-flex align-items-center">
                          <div className="plan-icons">
                            <Calendar size={20} />
                          </div>
                          <div className="bill-name-group">
                            <h5>Expires On</h5>
                            <p>{formattedDate}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }):<div style={{margin:"auto",padding:"20px"}}><h4 style={{textAlign:"center"}}>No Subscriptions</h4></div>}
        </div>
      </div>
    </>
  );
};

export default GridInnerPage;
