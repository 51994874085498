import React, { useContext, useEffect, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { LoginImg, logo, NetIcon1, NetIcon2 ,ChahalLogo} from "../../imagepath";
import FeatherIcon from "feather-icons-react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthProvider";
import { useState } from "react";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Modal, notification, Space } from "antd";

const LOGIN_URL = "/api/student-login";
const CENTERSLIST="/api/centers-login"


const initalValue = {
  email: "",
  password: "",
};
const loginSchema = Yup.object({
  email: Yup.string().test('is-email-or-phone', 'Invalid email or phone number', function (value) {
    const containsAtSymbol = value.includes('@');
    
    // const isEmailValid = Yup.string().email().isValidSync(value);
    const isEmailValid = containsAtSymbol && Yup.string().email().isValidSync(value);
    const isPhoneValid = /^\d{10}$/.test(value);

    return isEmailValid || isPhoneValid;
  }).required("Please enter your email or phone number"),
  password: Yup.string().required("Please enter Your password"),
});
const StudentLogin = () => {
  const navigate =useNavigate();
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const[centersvalue,SetCentersValue]=useState([])

  const lastVisiteStudentRoute = localStorage.getItem("lastVisiteStudentRoute");
  // useEffect(()=>{
  //   if(lastVisiteStudentRoute){
  //     navigate("/dashboard")
  //   }
  // })
  const tokenStudent=localStorage?.getItem("tokenStudent")
  // useEffect(()=>{
  //   if(tokenStudent)
  //   {
  //     navigate("/dashboard")
  //   }
  //   else{
  //     navigate("/login")
  //   }
  // })
const loginfunction=()=>{
   if(tokenStudent)
   {
     navigate("/dashboard")
   }
   else{
     navigate("/login")
   }

}
useEffect(()=>{
  loginfunction()
},[])
  const { auth, setAuth } = useContext(AuthContext);
  const [passwordType, setPasswordType] = useState("password");
  const [error, setError] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [userid, setUserid] = useState("");
  const [toast, setToast] = useState(false);
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const[value,setvalue]=useState(false)


  const openNotificationWithIcon1 = (type, text) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>{text} </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type, text) => {
    console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type, text) => {
    console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "blue" }}>
          <strong>{text}</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 2,
    });
  };
  // console.log(userid);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initalValue,
      onSubmit: async (val) => {
        // console.log(val);
        try {
          setvalue(true)

          setLoading(true);
          const response = await axios.post(LOGIN_URL, values, {
            // headers: { "Content-Type": "application/json",
            // 'Db': localStorage.getItem('db') ? localStorage.getItem('db') : 0, // Default accept type

            //  },
            withCredentials: true,
          });
          setRes(true);
          setUserid(response?.data?.user?.id);
          localStorage.setItem("userid", response?.data?.user?.id);
          localStorage.setItem("profile-img", response?.data?.profile_img);

          const tokenStudent = response?.data?.token;
          localStorage.setItem("tokenStudent", tokenStudent);
          const nameuser = response?.data?.user?.name;
          localStorage.setItem("nameuser", nameuser);
          localStorage.setItem("phoneno", response?.data?.user?.phone);

          localStorage.setItem("password-rest", response?.data?.password_reset);
          // console.log(response?.data);
          if (response?.data?.password_reset == "0") {
            openNotificationWithIcon3("info", "Change Password");
            // const handleChangepassword=(userid)=>{
            //   setTimeout(()=>{
            //     navigate(`/changepassword/${userid}`)

            //   },1000)

            // handleChangepassword(userid);
            setTimeout(() => {
              navigate("/changepassword");
            }, 1000);

          } 
          else {
            // localStorage.removeItem("token");

            // setAuth({ tokenStudent });
            navigate("/dashboard")
            // if (tokenStudent) {
            //   setLoading(false);

            //   setRes(false);

            //   // setTimeout(() => {
            //   //   navigate("/dashboard");
            //   // }, 1000);
            //   // toast.success("Login Successful");
            //   if (lastVisiteStudentRoute === "/login") {
            //     // console.log(lastVisiteStudentRoute,)
            //     navigate("/dashboard");
            //   } else if (lastVisiteStudentRoute) {
            //     navigate(lastVisiteStudentRoute);
            //   } else if (!lastVisiteStudentRoute) {
            //     setTimeout(() => {
            //       navigate("/dashboard");
            //     }, 1000);
            //   }
            //   openNotificationWithIcon1("success", "Login Successful");
            // }
          }
          setvalue(false)

        } catch (err) {
          console.log(err);
          openNotificationWithIcon2("error", err.response.data.message);
          setRes(false);
          setvalue(false)

          setLoading(false);

          if (!err?.response) {
            setError("No Server Response");
          } else if (err.response?.status === 400) {
            setError("Missing Email or Password");
          } else if (err.response?.status === 401) {
            setError("Unauthorized");
          }
          else if(err?.response?.status===405){
            navigate("")
          }
          else {
            setError("Login Failed");
          }
        }
      },
      validationSchema: loginSchema,
    });
  const handleChangepassword = (userid) => {
    setTimeout(() => {
      navigate(`/changepassword/${userid}`);
    }, 1000);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };
  const Centers = async () => {
    try {
      const resonse = await axios.get(CENTERSLIST);
      // console.log(resonse,"response")
       SetCentersValue(resonse?.data?.message)
        // setOptions(resonse?.data);
        // setSelectedOption(resonse?.data[0]);
      //  setTotalCourse(resonse?.data?.totalCourse)

    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      // openNotificationWithIcon2("error");
    }
  };
  useEffect(()=>{
    Centers()
  },[])
  // console.log(centersvalue,"centers")
  const Remove=()=>{
    sessionStorage.removeItem("db");
    sessionStorage.removeItem("centername")
    navigate("/")
  }
  const CentersSend = async (name,fk_login_db) => {

    sessionStorage.setItem("db",fk_login_db)
    sessionStorage.setItem("centername",name)
    openNotificationWithIcon1("success",`${name} Center is Selected`,"#52c41a")
    handleModalDeleteClose()
  };
  const handleShowForm1 = () => {
    setIsModalVisibleForm(true);
  };

  const handleModalDeleteClose = () => {
    setIsModalVisibleForm(false);
  };
  const namevalue=sessionStorage.getItem("centername")
  const dbvalue=sessionStorage.getItem("db")
  
  return (
    <>
      <div className="main-wrapper log-wrap">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
            <OwlCarousel
              {...settings}
              className="owl-carousel login-slide owl-theme"
            >
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                     Chahal Academy
                  </h2>
                  {/* <p>
                     Chahal Academy.: IT & Consulting 1) Business Analysis &
                    Modeling 2) Certified Google SEO 3) Web Development 4) App
                    Development 5) Software Development 6) Marketing Solutions
                    7) HR Solutions 8) Finance Solutions
                  </p> */}
                </div>
              </div>
              {/* <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    Stellarflux 
                  </h2>
                  <p>
                    StellarFlux: IT & Consulting 1) Business Analysis &
                    Modeling 2) Certified Google SEO 3) Web Development 4) App
                    Development 5) Software Development 6) Marketing Solutions
                    7) HR Solutions 8) Finance Solutions
                  </p>
                </div>
              </div>
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    Chahal Academy.
                  </h2>
                  <p>
                  StellarFlux: IT & Consulting 1) Business Analysis &
                    Modeling 2) Certified Google SEO 3) Web Development 4) App
                    Development 5) Software Development 6) Marketing Solutions
                    7) HR Solutions 8) Finance Solutions
                  </p>
                </div>
              </div> */}
            </OwlCarousel>
          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="w-100">
                  <div className="img-logo">
                    <img src={ChahalLogo} className="img-fluid" alt="Logo" />
                    <div className="back-home">
                      <Link to="/">Back to Home</Link>
                    </div>
                  </div>
                  <div className="row"style={{justifyContent:"center",display:"flex",paddingBottom:"20px"}}>
                    {/* <div className="col-4"><button type="button" className="btn btn-primary">Admin</button></div> */}
                    <div className="col-4"><button type="button" className="btn btn-primary" 
                    onClick={()=>{
                      navigate("/login")
                    }}>Student Login</button></div>
                     <div className="col-4"><button type="button" className="btn btn-danger" 
                    onClick={()=>{
                      navigate("/admin/login")
                    }}>Admin Login</button></div>
                    <div className="col-4">
                      <button type="button" className="btn btn-success"
                        onClick={()=>{
                          navigate("/teacher/login")
                        }}
                      >
                        Teacher Login</button>
                        </div>

                    </div>
                  <h1>Sign into Your Student Account</h1>
                  <div className="d-flex" style={{justifyContent:"space-between"}}>
                  <h5>{namevalue && dbvalue ?`${namevalue} Center is Selected`:
                  <button className="btn btn-primary" 
                  onClick={()=>{
                    navigate("/")
                  }}>Please Select Center</button>
                  
                  }</h5>
                  {namevalue && dbvalue?
                  <div > <button className="btn btn-danger" onClick={()=>{
                    handleShowForm1()
                  }}>Change Center</button></div>:<></>}
                  </div>
                  <Modal
                                title="Select Center"
                                okButtonProps={{
                                  style: { backgroundColor: "#FF875A" },
                                  children: "Import",
                                }}
                                open={isModalVisibleForm}
                                okText="Update"
                                onOk={() => {
                                  handeleDeleteCategory();
                                }}
                                onCancel={() => {
                                  setIsModalVisibleForm(false);
                                }}
                                // onCancel={handleModalDeleteClose}
                              >
                                <div className="row">
        {centersvalue?.map((item)=>{
          return(
<div className="col-lg-6 col-md-12 col-sm-12"
style={{ cursor: 'pointer' }}
 onClick={() => CentersSend(item?.name, item?.fk_login_db)}
>
<div>
                  <div className="course"
                  style={{color:"black"}}
                  >
                    
                    <div
                      className="blur-border course-radius align-items-center aos"
                      // data-aos="fade-up"
                    >
                      <div className="online-course  align-items-center">
                        <div className="course-img d-flex"
                        >
                 
 {item?.img ? (
        <img  style={{margin:"auto"}} src={item?.img} alt="Center Name" />
      ) : (
        <div style={{margin:"auto"}}>
        <i className="fa-solid fa-star"></i>
        </div>
      )}

                        </div>
                        <div className="course-inner-content mt-4"
                                                  

                        >
                       
                          <h5 style={{textAlign:"center"}}>{item?.name}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

</div>
          )
        })}
        
      </div>
                              </Modal>
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="form-group"
                    style={{marginTop:"15px"}}
                    >
                      <label className="form-control-label">
                        Email or Phone Number
                      </label>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        autoComplete="off"
                        value={values.email}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your email address or Phone Number"
                        required
                      />
                      {errors.email && touched.email ? (
                        <span style={{ color: "red" }}>{errors.email}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">Password</label>
                      <div className="pass-group">
                        <div className="password-field-wrapper">
                          <input
                            type={passwordType}
                            onChange={handleChange}
                            value={values.password}
                            required
                            autoComplete="off"
                            name="password"
                            id="password"
                            className="form-control password-field"
                            placeholder="Password"
                          />

                          <span
                            className="toggle-password feather-eye"
                            onClick={togglePassword}
                          >
                            {passwordType === "password" ? (
                              <FeatherIcon icon="eye" />
                            ) : (
                              <FeatherIcon icon="eye-off" />
                            )}
                          </span>
                        </div>
                        {/* {errors.password && touched.password ? (
                          <span style={{ color: "red" }}>
                            {errors.password}
                          </span>
                        ) : null} */}
                        {error ? (
                          <span style={{ color: "red" }}>
                            {errors.password}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="forgot">
                      <span>
                        <Link className="forgot-link" to="/forgot-password">
                          Forgot Password ?
                        </Link>
                      </span>
                    </div>
                    <div className="remember-me">
                      <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                        {" "}
                        Remember me
                        <input type="checkbox" name="radio" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="d-grid">
                      <button
                        className="btn btn-start"
                        // onClick={handleChangepassword(userid)}
                        disabled={value}
type="submit"
                      >
                        Sign In
                      </button>
                    </div>
                    {error ? (
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        {error}
                      </div>
                    ) : null}
                  </form>
                </div>
              </div>
              <div className="google-bg text-center">
                {/* <span>
                  <Link to="#">Or sign in with</Link>
                </span> */}
                {/* <div className="sign-google">
                  <ul>
                    <li>
                      <Link to="#">
                        <img src={NetIcon1} className="img-fluid" alt="Logo" />{" "}
                        Sign In using Google
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={NetIcon2} className="img-fluid" alt="Logo" />
                        Sign In using Facebook
                      </Link>
                    </li>
                  </ul>
                </div> */}
                <p className="mb-0">
                  New User ? <Link to="/register">Create an Account</Link>
                </p>
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default StudentLogin;
