import React, { useContext, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import StudentHeader from "../header";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import * as Yup from "yup";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {   notification, Space  } from "antd";
import AdminHeader from "../../instructor/adminHeader";
const UPDATETESTSERIES = "/api/recorded-video/update-module";
const EDITTESTSERIES = "/api/recorded-video/edit-module";

export default function AdminEditVideoModule() {
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const[submit,setSubmit]=useState(false)

  const { id } = useParams();
  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  const fetchTestEdit = async () => {
    try{
      setLoading(true);
      const response = await axios.get(
        `${EDITTESTSERIES}/${decryptedData}`,
        config
      );
      // console.log(response);
      setData(response.data.res);
      setLoading(false);
    }
    catch(err){
      console.log(err)
      if (err.response.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else{
        openNotificationWithIcon2("Internal Server Error","error")
      }
    }
   
  };
  useEffect(() => {
    fetchTestEdit();
  }, []);
  const initalValue = {
    name: data?data?.name:"",
    status: data?.status,
  };
  const studentSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter your name"),
    status: Yup.string().required("Please enter your status"),
  });
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  
  const[toast,setToast]=useState(false)
  const openNotificationWithIcon = (type) => {

    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"#52c41a"}}>
         <strong>Module Updated</strong> 
        </div>
      ),
      duration:1
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      
    });
    };
    const openNotificationWithIcon2 = (text,type) => {

      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"red"}}>
           <strong>{text}</strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        duration:1
      });
      };
      const openNotificationWithIconSesion = (type) => {
        notification[type]({
          message: (
            <div style={{ color: "red" }}>
              <strong>Session Expired Please Login </strong>
            </div>
          ),
          duration: 1,
        });
      };
  
  const { errors, values, touched, handleChange, handleSubmit, getFieldProps } =
    useFormik({
      initialValues: initalValue,
      onSubmit: async (value) => {
        setSubmit(true)
        try {
          const response = await axios.post(
            UPDATETESTSERIES,
            {
              "recordedvideos-name": value.name,
              status: value.status,
              "recordedvideos-id": decryptedData,
            },
            config
          );
          // console.log(response);
          if (response) {
            openNotificationWithIcon("success")
            setTimeout(() => {
              navigate("/admin/recorded-videos");
            }, 800);
            // toast.info("Module Updated");
          }
        } catch (error) {
          console.log(error?.response?.data?.message);
          setSubmit(false)
          if (error.response.status == 422) {
            openNotificationWithIcon2(error?.response?.data?.message,"error")
  
          }
         else if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
            localStorage.removeItem('token')
            navigate('/admin/login')
          }
        
        else{
          openNotificationWithIcon2("Internal Server Error","error")

        }
      }
        // console.log(value);
      },
      enableReinitialize: true,
      validationSchema: studentSchema,
    });
    useEffect(() => {
      const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
      if(lastVisitedRoute){
        localStorage.removeItem("lastVisitedRoute")
      }
      else{
        localStorage.setItem("lastVisitedRoute", window.location.pathname);
  
      }
    }, []);
    // const windowTop = (arg1, arg2) => {
    //   window.scrollTo(arg1, arg2);
    // };
    
    //   useEffect(() => {
    //     windowTop(0, 0);
    //   }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader/>
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
        style={{maxWidth:"100%",}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Module</h3>
                    {/* <p>Edit Test Series information.</p> */}
                    <nav aria-label="breadcrumb" style={{ marginTop: "0.5em" }}>
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li
                          className="breadcrumb-item "
                          aria-current="page"
                          style={{ cursor: "pointer",fontWeight:'normal' }}
                          onClick={() =>
                            navigate("/admin/recorded-videos-list")
                          }
                        >
                          {" "}
                          Recorded Videos
                        </li>
                        <li
                          className="breadcrumb-item "
                          aria-current="page"
                          style={{ cursor: "pointer" ,fontWeight:'normal'}}
                          onClick={() => navigate("/admin/recorded-videos")}
                        >
                          {" "}
                          Recorded Module & SubModule
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                          style={{
                            cursor: "pointer",marginLeft:'-1em'
                          }}
                        >
                          Edit Module 
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      {loading === true ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Enter Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={values.name}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Your Name"
                              />
                              {errors.name && touched.name ? (
                                <span style={{ color: "red" }}>
                                  {errors.name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label
                                className="form-control-label"
                                style={{
                                  marginRight: "10px",
                                  marginTop: "5px",
                                }}
                              >
                                Status :
                              </label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("status").onChange}
                                  value={"0" || values?.status}
                                  checked={values?.status === "0"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio2"
                                  onChange={getFieldProps("status").onChange}
                                  value={"1" || values?.status}
                                  checked={values?.status === "1"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Active
                                </label>
                              </div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="update-profile"
                           id="submit-button"
                          >
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"

                            >
                              <button type="submit" className="btn btn-primary"
                               disabled={submit}
                              >
                                Update
                              </button>
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"

                            >
                              <button
                                type="button"
                                onClick={() => {
                                  // windowTop(0,0)
                                  navigate("/admin/recorded-videos");
                                }}
                                className="btn btn-primary cancel-btn "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
