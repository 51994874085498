import React, { useState, useContext, useEffect, useRef } from "react";

import AdminHeader from "../../instructor/adminHeader";
import Footer from "../../footer";
import { AudioOutlined } from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import { Video2 } from "../../imagepath";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";
import CryptoJS from "crypto-js";
import axios from "../../../api/axios";
// import "./style.css";
import { notification, Space } from "antd";

const RECORDEDVIDEO = "/api/recorded-video/pdf-list";
const DELETEPDF = "/api/recorded-video/pdf/delete";
const RECORDEDSTATUS = "/api/recorded-video/status";
const RECORDEDSEARCH = "/api/recorded-video/recordedvideo-search";
const IMPORT = "/api/recorded-video/pdf-file-upload";

import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import FeatherIcon from "feather-icons-react";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "react-switch";
import { Modal } from "antd";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { event } from "jquery";
import Form from "react-bootstrap/Form";

const { confirm } = Modal;

export default function VideoUploadPdf() {
  const { Search } = Input;
  const [list, setList] = useState(null);
  const inputFile = useRef(null);
  const [last_page, setLast_page] = useState("");
  const [file, setFile] = useState(null);
  const [records1, setRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [text, settext] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [error, seterror] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  //   const [id, setId] = useState("");
  const [messages, setmessage] = useState("No Data");
  const [uid, setuid] = useState("");
  const[name,setName]=useState("")

  const handleClose = () => setShow(false);

  const navigate = useNavigate();

  const { id } = useParams();
  // function showAlert() {
  //   navigate("/admin/recorded-videos");
  // }

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const fecthRecordedList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(`${RECORDEDVIDEO}/${id}`, config);
      // console.log(resonse?.data, "response");
      setList(resonse?.data);
      setRecords(resonse?.data);
      setLoading(false);
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    } catch (error) {
      console.log(error);
      if (error) {
        localStorage.removeItem("token");
        openNotificationWithIconSesion("error")
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon2("error");
      }
    }
  };
  useEffect(() => {
    fecthRecordedList();
  }, []);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Pdf Deleted</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text} </strong>
        </div>
      ),
      duartion: 1,
    });
  };

  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this Pdf?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        setLoading(true);
        try {
          const response = await axios.delete(`${DELETEPDF}/${id}`, config);
          setLoading(false);
          openNotificationWithIcon("success");
          fecthRecordedList();
          setShow(false);
          response;
          // toast.success("Item deleted");
          // Show success toast notification
        } catch (error) {
          console.error("Error deleting student:", error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else{
          openNotificationWithIcon2("error");
          }
          fecthRecordedList()
        }
      },
      onCancel() {},
    });
  }
  const handleDeleteStudent = async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await axios.delete(`${DELETEVIDEO}/${id}`, config);
        setLoading(false);
        fecthRecordedList();
        setShow(false);
        response;
        // toast.warning("Video Deleted");
        // toast.success("Item deleted");
        // Show success toast notification
      } catch (error) {
        console.error("Error deleting student:", error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      }
    }
  };

  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      RECORDEDSTATUS,
      { id: id, status: result },
      config
    );

    if (response) {
      openNotificationWithIcon1("success", "Status updated", "#52c41a");
      const updatedList = list?.map((item) =>
        item.id === id ? { ...item, status: result } : item
      );
      setRecords(updatedList);
    } else {
      openNotificationWithIcon2("error");
    }
  };

  const handlePdf = (id,event) => {
    event.preventDefault()
    setIsModalVisible(true);
    setuid(id);
  };
  const props = {
    accept: ".pdf",
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} is not a pdf file`);
      }
      return file.type === "application/pdf" ? true : Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      let files = info.file.originFileObj;
      delete files.uid;
      if (file) {
        info.fileList.splice(0);
      } else {
        setFile(files);
      }
    },
    // showUploadList: file ? false : true,
  };
  const handleImoprt = async (event) => {
    event.preventDefault()
    seterror("");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("video_id", uid);
    formData.append("name",name)
    // event.preventDefault()
    console.log("krishna before response")


    try {
      const response = await axios.post(IMPORT, formData, config);
      console.log("krishna in response")
      fecthRecordedList();
      openNotificationWithIcon1("success", response.data.message, "#52c41a");
      if (response) {
        setIsModalVisible(false);
        if (inputFile.current) {
          inputFile.current.value = "";
          inputFile.current.type = "text";
          inputFile.current.type = "file";
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      if (error?.response?.status === 422) {
        openNotificationWithIcon1("error", error.response.data.message, "red");
      } 
      seterror(error.response.data.message);
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }
      else {
        openNotificationWithIcon1("error", "Internal Server Error", "red");
      }
     
    }
  };
  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      settext(searchValue);
      const res = await axios.post(
        RECORDEDSEARCH,
        { name: searchValue },
        config
      );
      if (res.data.data.length > 0) {
        setRecords(res.data.data);
        setLast_page(res.data.last_page);
        setmessage("No Data");
      }
      if (res.data.data.length === 0) {
        setRecords(res.data.data);
        setmessage("No Data Found");
      }
      if (searchValue.length === 0) {
        const res = await axios.get(
          `${RECORDEDVIDEO}?page=${currentPage}`,
          config
        );
        setRecords(list);
        setCurrentPage(1);
        setLast_page(res.data.last_page);
      }
    } catch (error) {
      console.error("Error deleting student:", error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon2("error")
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };

  let data = [];

  // if (Array.isArray(records1)) {
  //   // Only sort if records1 is an array
  //   data = records1.sort((a, b) => {
  //     if (sortColumn !== "") {
  //       if (sortDirection === "asc") {
  //         if (a[sortColumn] < b[sortColumn]) return -1;
  //         if (a[sortColumn] > b[sortColumn]) return 1;
  //         return 0;
  //       } else {
  //         if (a[sortColumn] < b[sortColumn]) return 1;
  //         if (a[sortColumn] > b[sortColumn]) return -1;
  //         return 0;
  //       }
  //     } else {
  //       return a.id - b.id;
  //     }
  //   });
  // }
 
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const itemsPerPage = 10;
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  // useEffect(() => {
  //   windowTop(0, 0);
  // }, []);
  return (
    <div className="main-wrapper ">
      <AdminHeader />
      <div className="page-content ">
        <div
          className="container"
          style={{ maxWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <div className="filter-grp ticket-grp d-flex align-items-center justify-content-between">
                            <h3>Uplaod Pdf</h3>
                          </div>
                        </div>

                        <div className="comman-space pb-0">
                          <div
                          // className="settings-referral-blk table-responsive"
                          // id="table-value"
                          >
                            {/* Instructor Users */}

                            {/* <form>
                              <div className="student-search-form d-flex m-0">
                                {/* <div className="form-group mb-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Twitter Profile Name"
                              />
                            </div> *
                                <div className="export-cvv-btn">
                                  <Link
                                    to="/admin/add-video"
                                    className="btn btn-primary"
                                    style={{color:'white'}}
                                  >
                                   Create New Video
                                  </Link>
                                  {/* Export CSV *
                                </div>
                                <div className="profile-share d-flex align-items-center justify-content-center">
                                  <Search
                                    className="custom-search-box"
                                    placeholder="input search text"
                                    allowClear
                                    size="large"
                                    onChange={Filter}
                                  />
                                </div>
                              </div>
                            </form> */}
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row  gx-2 align-items-center">
                                    {/* <div className="col-md-4 col-item">
                                      <div className=" search-group justify-content-start">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search"
                                          allowClear
                                          onChange={Filter}
                                          style={{ width: "100%" }}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </div> */}

                                    <div className="col-md-12 col-lg-12 col-item">
                                      <div
                                        className="button-container justify-content-end"
                                        style={{ gap: "5px" }}
                                      >
                                        <button
                                          onClick={(event) => handlePdf(id,event)}
                                          className="btn btn-primary"
                                          // style={{width:"100%"}}
                                          id="custom-button"
                                        >
                                          <i
                                            className="fa fa-download"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Upload Pdf
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                            
                              <Modal
                                title="Upload Pdf"
                                okButtonProps={{
                                  style: { backgroundColor: "#FF875A" },
                                  children: "Import",
                                }}
                                open={isModalVisible}
                                okText="ok"
                                onOk={(event) => {
                                  handleImoprt(event);
                                  // setIsModalVisible(false);
                                }}
                                onCancel={() => {
                                  setIsModalVisible(false);
                                  seterror("");
                                }}
                              >
                                {/* <Upload
                                  {...props}
                                >
                                  <Button icon={<UploadOutlined />}
                                                                    onChange={(e) => {setFile(e.target.files[0]),e.preventDefault()}}
                                                                    accept=".doc,.docx,application/pdf"
                                  
                                  >
                                    Upload pdf only
                                  </Button>
                                </Upload> */}
                                                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={name}
                                   onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter Pdf Name"
                                  />
                                </Form.Group>
                                <div className="mb-3">
                                <label htmlFor="formFile" className="form-label">
                                  only support Pdf file
                                </label>
                                <input
                                  className="form-control"
                                  autoFocus
                                  onChange={(e) => setFile(e.target.files[0])}
                                  ref={inputFile}
                                  type="file"
                                  id="formFile"
                                />
                                {error && (
                                  <span style={{ color: "red" }}>{error}</span>
                                )}
                              </div>
                              </Form>
                              </Modal>
                              <table className="table table-nowrap ">
                                <thead>
                                  <tr className="text-center">
                                    <th>SR NO</th>
                                    <th>Name</th>
                                    <th> Downlaod Pdf </th>
                                   
                                    <th>ACTION</th>
                                  </tr>
                                </thead>
                                {loading === true ? (
                                 
                                    <tbody className="d-flex justify-content-center align-items-center"
                                    style={{
                                      position: "fixed",
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.6)",
                                    }}>
                                      <tr>
                                        <td>
                                          <div
                                            className="spinner-border text-warning"
                                            role="status"
                                          >
                                            <span className="sr-only">
                                              Loading...
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                ) : (
                                  <tbody
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {records1
                                      // .sort((a, b) => b.id - a.id)
                                      ?.map((item, i) => {
                                        const sNo =
                                          (currentPage - 1) * itemsPerPage +
                                          i +
                                          1;
                                        return (
                                          <tr key={item.id}>
                                            <td className="text-center">
                                              {sNo}
                                            </td>
                                            <td className="text-center">
                                              {item?.name}
                                            </td>
                                            <td className="text-center"><a href={item?.url}
                                            target="_blank"
                                            className="btn btn-primary  text-white"
                                            >
                                            <i
                                            className="fa fa-download"
                                            aria-hidden="true"
                                            // style={{color:"#FF875A"}}
                                          ></i>{" "}
                                                </a></td>

                                            <td className="text-center">
                                              <Tooltip title="Delete">
                                                <button
                                                  onClick={() =>
                                                    showConfirm(item.id)
                                                  }
                                                  type="button"
                                                  className="btn btn-danger"
                                                >
                                                  <i
                                                    className="fa fa-trash-o"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                              </Tooltip>{" "}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                )}
                              </table>
                            </div>

                            {records1?.length <= 0 ? (
                              <h5 className="text-center">{messages}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          {/* <div className="fixtable">
                            {records1?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationVideo
                                nPages={last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={RECORDEDVIDEO}
                                setData={setRecords}
                                text={text}
                                setLast_page={setLast_page}
                                linkSearch={RECORDEDSEARCH}
                              />
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
}
