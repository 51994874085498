import React, { useEffect, useState } from "react";
import CourseHeader from "../header";
import GridInnerPage from "./gridInnerPage";
import FeatherIcon from "feather-icons-react";
import { Blog1, Blog2, Blog3 } from "../../../imagepath";
import Select from "react-select";
import { Link } from "react-router-dom";
import Footer from "../../../footer";
import StudentHeader from "../../../student/studentHeader";

const CourseSubcription = () => {
  localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);

  const tokenStudent = localStorage.getItem("tokenStudent");
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#FFDEDA" : "white",
      };
    },
    dropdownIndicator: (base, state) => ({
      ...base,
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "300ms",
    }),
  };

  const option = [
    { label: "Newly published", value: "Newly published" },
    { label: "published 1", value: "published 1" },
    { label: "published 2", value: "published 2" },
    { label: "published 3", value: "published 3" },
  ];

  const [input, setInput] = useState(null);
  useEffect(() => {
    const lastVisiteStudentRoute=localStorage.getItem("lastVisiteStudentRoute")
    if(lastVisiteStudentRoute){
      localStorage.removeItem("lastVisiteStudentRoute")
    }
    else{
      localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);

    }
  }, []);
  useEffect(()=>{
    const lastVisiteStudentTest=  localStorage.getItem("lastVisiteStudentTest")
  if(lastVisiteStudentTest!==window.location.pathname){
    localStorage.removeItem("startTime");
    localStorage.removeItem("expirationTime");
   
  }
},[]);


  return (
    <>
      <div className="main-wrapper">
        <StudentHeader activeMenu={"CourseGrid"} />

        <div className="breadcrumb-bar">
          <div className="container"
          style={{minWidth:"98%",margin:"auto"}}

          >
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      {/* <li className="breadcrumb-item" aria-current="page">
                        Courses
                      </li> */}
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <Link to="/student/my-subscriptions">
                          {" "}
                          My Subscriptions
                        </Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="course-content">
          <div className="container"
            style={{minWidth:"98%",margin:"auto"}}

          >
            <div className="row">
              <div className="col-lg-12">
               

                <GridInnerPage />

               
              </div>
              
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default CourseSubcription;
