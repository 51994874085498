import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import { Link, useNavigate, useParams } from "react-router-dom";
import StudentSideBar from "../sidebar";
import Select from "react-select";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import AuthContext from "../../../context/AuthProvider";
import { useFormik } from "formik";
import CryptoJS from "crypto-js";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../api/axios";
import {   notification, Space  } from "antd";

import AdminHeader from "../../instructor/adminHeader";
const STUDENTEDIT ="/api/student/edit";
const CENTERSLIST = "/api/public/academy_centers";


const STUDENTUUDATE = "/api/student/update";
export default function AddUserEdit() {
  const [country, setCountry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const[submit,setSubmit]=useState(false)
  const [centers, setCenters] = useState([]);
  const [centersId, setCentersId] = useState("");

  const { stdid } = useParams();
  const navigate = useNavigate(); 
  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(stdid),
    "my-secret-key@admin8623009959"
  );

  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const openNotificationWithIcon2 = (type,text,col) => {
    // console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:col}}>
         <strong>{text} </strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };
    const openNotificationWithIconSesion = (type) => {
      notification[type]({
        message: (
          <div style={{ color: "red" }}>
            <strong>Session Expired Please Login </strong>
          </div>
        ),
        duration: 1,
      });
    };
    useEffect(() => {
      const CentersList = async () => {
        try {
          const response = await axios.get(CENTERSLIST);
          setCenters(response?.data?.message);
        } catch (error) {
          console.log(error);
        }
      };
  
      CentersList();
    }, []);
  const fetchUser = async () => {
    try{
      setLoading(true);
      if (decryptedData) {
        const response = await axios(`${STUDENTEDIT}/${encodeURIComponent(decryptedData)}`, config);
        // console.log(response, "test");
        setData(response?.data);
        setCentersId(response?.data?.fk_academy_center_id)
      }
      setLoading(false);
    }
    catch(error){
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon2("error", error?.response?.data?.message,"red");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error", "Internal Server Error", "red");

      }
    }
  
  };
  useEffect(() => {
    fetchUser();
  }, []);
  // console.log(centersId,"centersid")
  const handelcenterchange=(value)=>{
    // console.log(value)
  setCentersId(value?.value)
  }
  // console.log(data);
  const initalValue = {
    name: data?.name || "",
    email: data?.email || "",
    password: data?.password || "",
    phone: data?.phone || "",
    dob: data?.dob || "",
    gender: data?.gender || "",
    status: data?.status || "",
    course:data?.course || "",
    refrence:data?.refrence||"",
    remark:data?.remark ||"",
  };
  const studentSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter your name"),
    email: Yup.string().email().required("Please enter your email"),
    // password: Yup.string()
    //   .required("Please enter a password")
    //   .min(6, "Password must have at least  characters"),
    phone: Yup.string().max(10).required("Please enter your phone number"),
    dob: Yup.date().min("1969-11-13", "Date is too early").max('2023-11-13', "Date is too further").required("Please Enter date of birth"),
        gender: Yup.string().required("Select your gender"),
    status: Yup.string().required("Please enter your status"),
  });

  const options = [
    // { label: "Select Status", value: "Status" },
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ];
  const defaultVal = [
    {
      label: data?.status === "1" ? "Active" : "Inactive",
      value: data?.status,
    },
  ];
  const[toast,setToast]=useState(false)
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type) => {
    // console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"#52c41a"}}>
         <strong>Student Updated</strong> 
        </div>
      ),
      duration:1
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Created
  
      // </div>)
      
    });
  
    
  
       
     
     
    };
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    field,
  } = useFormik({
    initialValues: initalValue,
    enableReinitialize: true,
    onSubmit: async (value) => {
      setSubmit(true)
      const dataToSend = { ...value, fk_center_id: centersId };

      try {
        const response = await axios.post(
          `${STUDENTUUDATE}/${decryptedData}`,
          dataToSend,
          config
        );
        // console.log(response);
        if (response) {
          openNotificationWithIcon("success")

          setTimeout(() => {
            // navigate("/admin/student-list");
            navigate(-1)
          }, 1000);
          // toast.info("Student Updated");
        }
      } catch (error) {
        console.log(error);
        setSubmit(false)
        // if (error.response.data.errors.email) {
        //   setFieldError("email", error.response.data.errors.email.toString());
        // }
        // if (error.response.data.errors.phone) {
        //   setFieldError("phone", error.response.data.errors.phone.toString());
        // }
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem('token')
          navigate('/admin/login')
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon2("error", error.response.data.message, "red");
        } 
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIcon2("error", error?.response?.data?.message,"red");
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
        else {
          openNotificationWithIcon2("error", "Internal Server Error", "red");
        }
       
      }
    },
    validationSchema: studentSchema,
  });


  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  // console.log("data?.status", data?.status === "1" ? "Active" : "Inactive");

  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };
  
  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  const today = new Date();
  today.setFullYear(today.getFullYear() - 12);

  const maxDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"StudentList"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Students</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{marginBottom:0.5}}>
                        <li
                          onClick={() => navigate("/admin/student-list")}
                          className="breadcrumb-item"
                          role="button"
                        >
                          <a>All Students</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Edit Student
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      {loading === true ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Enter Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Name"
                                id="name"
                                name="name"
                                onChange={handleChange}
                                value={values.name}
                              />
                              {errors.name && touched.name ? (
                                <span style={{ color: "red" }}>
                                  {errors.name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Email Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Email Address"
                                id="email"
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                              />
                              {errors.email && touched.email ? (
                                <span style={{ color: "red" }}>
                                  {errors.email}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Phone
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Phone"
                                id="phone"
                                name="phone"
                                onChange={handleChange}
                                value={values.phone}
                              />
                              {errors.phone && touched.phone ? (
                                <span style={{ color: "red" }}>
                                  {errors.phone}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Select Center</label>
                            <Select
  options={centers?.map(center => ({ value: center.id, label: center.name }))}
  placeholder="Select Center"
  value={{
    label: centers?.find(
      (option) => option.id == centersId
    )?.name,
    value: centersId,
  }}  onChange={handelcenterchange}
/>


                            {errors.fk_center_id && touched.fk_center_id ? <span style={{ color: "red" }}>{errors.fk_center_id}</span> : null}
                          </div>
                        </div>
                          <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Intrested Course</label>
                            <input
                        type="course"
                        name="course"
                        id="course"
                        autoCorrect="off"
                        autoComplete="off"
                        value={values.course}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="intrested course"
                        required
                      />
                      {errors.course && touched.course ? (
                        <span style={{ color: "red" }}>{errors.course}</span>
                      ) : null}
                           </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Date of Birth
                              </label>
                              <input
                                type="date"
                                name="dob"
                                id="dob"
                                autoCorrect="off"
                                autoComplete="off"
                                value={values.dob}
                                onChange={handleChange}
                                max={maxDate}
                                className="form-control"
                                placeholder="Enter your date of birth"
                                
                              />
                              {errors.dob && touched.dob ? (
                                <span style={{ color: "red" }}>
                                  {errors.dob}
                                </span>
                              ) : null}
                            </div>
                          </div>
                              <div className="col-lg-6">
                              <div className="form-group">
                      <label className="form-control-label">Refrence</label>
                      <input
                        type="text"
                        name="refrence"
                        id="refrence"
                        autoComplete="off"
                        autoCorrect="off"
                        value={values.refrence}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="refrence"
                        required
                      />
                      {errors.refrence && touched.refrence ? (
                        <span style={{ color: "red" }}>{errors.refrence}</span>
                      ) : null}
                    </div>
                              </div>
                              <div className="col-lg-6">
                                
                    <div className="form-group">
                      <label className="form-control-label">Remark</label>
                      <input
                        type="text"
                        name="remark"
                        id="remark"
                        autoComplete="off"
                        autoCorrect="off"
                        value={values.remark}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="remark"
                        required
                      />
                      {errors.remark && touched.remark ? (
                        <span style={{ color: "red" }}>{errors.remark}</span>
                      ) : null}
                    </div>
                              </div>
                              <div className="col-lg-6">
                            <div
                              className="form-group"
                              // style={{ marginTop: "10px" }}
                            >
                              <div className="row">
                                <div className="col">
                                  <label className="text-center">Gender</label>
                                </div>
                              </div>
                                <div style={{marginTop:'.5em'}}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("gender").onChange}
                                  value={"0" || values?.gender}
                                  checked={values?.gender === "0"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="inlineRadio2"
                                  checked={values?.gender === "1"}
                                  onChange={getFieldProps("gender").onChange}
                                  value={"1" || values?.gender}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="inlineRadio3"
                                  value={"2" || values?.gender}
                                  onChange={getFieldProps("gender").onChange}
                                  checked={values?.gender === "2"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio3"
                                >
                                  Other
                                </label>
                              </div>
                              </div>
                              {errors.gender && touched.gender ? (
                                <span style={{ color: "red" }}>
                                  {errors.gender}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        <div
                            className="col-lg-6"
                          >
                            <div className="form-group">
                              <label className="form-label">Status</label>
                              <br />
                              <div style={{marginTop:'.5em'}}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios1"
                                  onChange={getFieldProps("status").onChange}
                                  value={"0" || values?.status}
                                  checked={values?.status === "0"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios2"
                                  checked={values?.status === "1"}
                                  onChange={getFieldProps("status").onChange}
                                  value={"1" || values?.status}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios2"
                                >
                                  Active
                                </label>
                              </div>
                              </div>
                        
                            </div>
                          </div>

                          <div className="update-profile"
                                                    id="submit-button"

                          >
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
   
                            >
      

      <button type="submit" className="btn btn-primary"
                              disabled={submit}
                             
                              >
                                Update
                              </button>
        
      
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"

                            >
                              <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="btn btn-primary cancel-btn "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      <ToastContainer autoClose={3000} hideProgressBar={true} />
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
