import React from "react";
import axios from "../../../../api/axios";

const PaginationQuestion = ({ nPages, currentPage, setCurrentPage, link, setData,user,subuser }) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const handleSetData = async (pgNumber) => {
    const res = await axios.get(`${link}/${user}/${subuser}?page=${pgNumber}`, config);
    setCurrentPage(pgNumber);
    setData(res.data.allQuestion.data);
  };

  const nextPage = async () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
      const res = await axios.get(`${link}/${user}/${subuser}?page=${currentPage + 1}`, config);
      setData(res.data.allQuestion.data);
    }
  };
  const prevPage =async () => {
    if (currentPage !== 1) {
        setCurrentPage(currentPage - 1)
        const res = await axios.get(`${link}/${user}/${subuser}?page=${currentPage - 1}`, config);
        setData(res.data.allQuestion.data);
    };
  };
  const renderPageNumbers = () => {
    let pages = [];

    if (nPages <= 1) {
      pages = [1, ];
    } else if (nPages <= 6) {
      pages = [...Array(nPages).keys()].map(i => i + 1);
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, 4, 5, null, nPages];
      } else if (currentPage >= nPages - 2) {
        pages = [1, null, nPages - 4, nPages - 3, nPages - 2, nPages - 1, nPages];
      } else {
        pages = [1, null, currentPage - 1, currentPage, currentPage + 1, null, nPages];
      }
    }

    return pages.map((pgNumber, index) => (
      <li
        key={index}
        className={`${
          currentPage === pgNumber ? "page-item first-page active" : "page-item first-page "
        } `}
      >
        {pgNumber !== null ? (
          <a onClick={() => handleSetData(pgNumber)} className="page-link" href="#">
            {pgNumber}
          </a>
        ) : (
          <span> <a  className="page-link" href="#">
          ....
        </a></span>
        )}
      </li>
    ));
  };
  return (
    <nav>
      <ul className="pagination lms-page d-flex align-items-center justify-content-center">
        <li className="page-item prev">
          <a className="page-link" onClick={prevPage} href="#">
          <i className="fas fa-angle-left" />
          </a>
        </li>
                  {renderPageNumbers()}

        <li className="page-item next">
          <a className="page-link" onClick={nextPage} href="#">
          <i className="fas fa-angle-right" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default PaginationQuestion;
