import {
  CategoryIcon,
  CategoryIcon1,
  CategoryIcon2,
  CategoryIcon3,
  CategoryIcon4,
  CategoryIcon5,
} from "../../imagepath";
import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import { useState } from "react";
const COURSECATEGORYLIST="/api/app/home/courses"

const TopCategory = () => {
  const navigate=useNavigate()
 
  const [list,setList]=useState([])
  const fetchCourseCategory = async () => {
    try {
      // setLoading(true);
      const resonse = await axios.get(COURSECATEGORYLIST);
     console.log(resonse,"resonse category")
    
      // setitemsPerPage(batch_detail_relation.length)
       setList(resonse?.data);
      // setData(resonse?.data?.data);
      // setLast_page(resonse?.data?.last_page)
      // setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  useEffect(()=>{
fetchCourseCategory()
  },[])
   console.log(list,"list")
   var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    gap:10,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: list?.length,
        margin:25
      },
      768: {
        items: list?.length,
        margin:25
      },
      1170: {
        items: list?.length,
        margin:25
      },
    },
  };
  return (
    <section className="section how-it-works">
      <div className="container">
        <div className="section-header aos" data-aos="fade-up">
          <div className="section-sub-head">
            {/* <span>Favourite Course</span> */}
            <h2> Course Category</h2>
          </div>
          <div className="all-btn all-category d-flex align-items-center">
            <Link to="" className="btn btn-primary">
              All Categories
            </Link>
          </div>
        </div>
        {/* <div className="section-text aos" data-aos="fade-up">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean
            accumsan bibendum gravida maecenas augue elementum et neque.
            Suspendisse imperdiet.
          </p>
        </div> */}
        <OwlCarousel
          {...settings}
          className="owl-carousel mentoring-course owl-theme aos"
          data-aos="fade-up"
          loop
          margin={10}
          nav
        >
          {list?.map((item,index)=>{
          return(
            <div className="feature-box text-center "
                 key={index}
            >
            <div className="feature-bg"
            onClick={()=>{
              navigate(`student/courses-list/${item?.id}`)
            }}
            >
              <div className="feature-header">
                <div className="feature-icon">
                  <img src={item?.image} alt="krishna" />
                </div>
                <div className="feature-cont">
                  <div className="feature-text">{item?.name}</div>
                </div>
              </div>
              {/* <p>40 Instructors</p> */}
            </div>
          </div>
          )
          })}
         
          

        </OwlCarousel>
      </div>
    </section>
  );
};

export default TopCategory;
