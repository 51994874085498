import React, { useContext, useEffect, useState } from "react";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import AuthContext from "../../../context/AuthProvider";
import CryptoJS from "crypto-js";
import axios from "../../../api/axios";
import AdminHeader from "../../instructor/adminHeader";
import {   notification, Space  } from "antd";

const ADDPAYMENRTERM = "/api/course-payment/create-course-payment";

export default function AddPaymentTerm() {
  const [data, setData] = useState([{ id: 1, amount: null, duration: null }]);
  const token = localStorage.getItem("token");
  const { auth } = useContext(AuthContext);
  const { payid, name } = useParams();
  const[submit,setSubmit]=useState(false)

  var bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(payid),
    "my-secret-key@admin8623009959"
  );
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const navigate = useNavigate();
  const handleAdd = () => {
    const addData = [...data];
    const result = [
      ...addData,
      { id: data.length + 1, amount: null, duration: null },
    ];
    setData(result);
  };
  // console.log(data);
  const handleEdit = (e, i) => {
    const { name, value } = e.target;
    const editData = [...data];
    const result = editData.find((item) => item.id === i);
    result[name] = value;
    setData(editData);
  };
  const handleDelete = (id) => {
    const deleteData = [...data];
    const result = deleteData.filter((item) => item.id !== id);
    setData(result);
  };
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const arrayObject_to_array = (input, type) => {
    const temArr = [];
    for (let key in input) {
      temArr.push(input[key][type]);
    }
    return temArr;
  };
  const [api, contextHolder] = notification.useNotification();
  
  const[toast,setToast]=useState(false)
 
    const openNotificationWithIcon = (type) => {
      console.log("toast after click")
      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"#52c41a"}}>
           <strong>Installment Created</strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        
      });
      };
      const openNotificationWithIcon2 = (type) => {
        console.log("toast after click")
        notification[type]
        (
          
          {
          message: 
          (
            <div style={{color:"red"}}>
             <strong>Internal Server Error </strong> 
            </div>
          ),
        
          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted
      
          // </div>)
          duration:1
        });
        };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const amount = arrayObject_to_array(data, "amount");
    const duration = arrayObject_to_array(data, "duration");
    // console.log(amount,duration);
    setSubmit(true)
    try {
      if (amount && duration) {
        const response = await axios.post(
          ADDPAYMENRTERM,
          {
            amount: amount,
            duration: duration,
            course_id: decryptedData,
            total_inst_count: data.length,
          },
          config
        );
        // console.log(response)
        if (response.data.success) {
          openNotificationWithIcon("success")
          setTimeout(() => {
            navigate(-1);
          }, 1000);
          // toast.success("Installment Created");
        }
      }
      if (data.length === 0) {
        toast.error("Add Amount and Duration");
      }
    } catch (error) {
      console.log(error);
      setSubmit(false)
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      openNotificationWithIcon2("error")
    }
  };
  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Courses"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>{name}</h3>
                    {/* <p>{name}</p> */}
                    <nav aria-label="breadcrumb" style={{ marginTop: "0.5em" }}>
                      <ol class="breadcrumb" style={{marginBottom:0.5}}>
                        <li
                          class="breadcrumb-item"
                          style={{ cursor: "pointer" }}
                        >
                          <a onClick={() => navigate("/admin/course")}>
                            Courses
                          </a>
                        </li>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          onClick={()=>navigate(-1)}
                          style={{ cursor: "pointer",fontWeight:'normal' }}
                        >
                          Payment Terms
                        </li>
                        <li
                          class="breadcrumb-item active" style={{marginLeft:'-1em'}}
                          aria-current="page"
                          role="button"
                        >
                        Add Installment
                        </li>
                      </ol>
                    </nav>
                    {/* <div className="row">
                      <div className="col-sm-6 col-lg-5"></div>
                      <div className="col-sm-6 col-lg-7">
                        <div className="text-end">
                          <Link onClick={handleAdd} className="btn-mark">
                            Add More
                          </Link>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    {/* <div className="personal-info-head">
                      <h4>Edit Courses</h4>
                      <p>Edit Courses information.</p>
                    </div> */}
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        {data?.map((item) => {
                          return (
                            <>
                              <div key={item.id} className="col-lg-5">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Installment {item.id} Amount (₹)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Amount"
                                    name="amount"
                                    value={item.amount}
                                    onChange={(e) => handleEdit(e, item.id)}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-5">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Installment {item.id} Duration (Days)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Duration(in days)"
                                    name="duration"
                                    value={item.duration}
                                    onChange={(e) => handleEdit(e, item.id)}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-1">
                                <div className="form-group">
                                  <button
                                    onClick={() => handleAdd()}
                                    type="button"
                                    style={{
                                      color: "whitesmoke",
                                      marginTop: "2em",
                                    }}
                                    className="btn btn-primary"
                                  >
                                    <i
                                      class="fa fa-plus"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                              <div className="col-lg-1">
                                <div className="form-group">
                                  <button
                                    onClick={() => handleDelete(item.id)}
                                    type="button"
                                    style={{
                                      color: "whitesmoke",
                                      marginTop: "2em",
                                      // marginLeft: "-1em",
                                    }}
                                    className="btn btn-info"
                                  >
                                    <i className="fa-regular fa-trash-can"></i>
                                  </button>
                                </div>
                              </div>
                            </>
                          );
                        })}

                        <div className="update-profile"
                           id="submit-button"

                        >
                          <div
                            class="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button type="submit" className="btn btn-primary"
                            disabled={submit}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            class="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
