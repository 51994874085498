import React, { useState, useContext, useEffect } from "react";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../context/AuthProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../../api/axios";
// import JoditEditor from "jodit-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import TextEditor from "../../pages/course/addCourse/textEditor";
import Select from "react-select";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import AdminHeader from "../../instructor/adminHeader";
import { notification, Space } from "antd";
import "./index.css";
const RECORDEDEDIT = "/api/recorded-video/edit";
const RECORDEDUPDATE = "/api/recorded-video/update";

export default function AddRecordEdit() {
  const [descriptionValue, setDescriptionValue] = useState("");
  const [errorI, setErrorI] = useState("");
  const [country, setCountry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { stdid } = useParams();
  const [submit, setSubmit] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null); // State to hold the uploaded image


  var bytes = CryptoJS.AES.decrypt(stdid, "my-secret-key@admin8623009959");
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // console.log('decryptedData',decryptedData);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  // console.log(stdid);
  const options = [
    { label: "YouTube", value: "0" },
    { label: "Vimeo", value: "1" },
    { label: "Vdocipher", value: "2" },
  ];
  const defaultValue = options.find((option) => option.label === "YouTube");
  const [file, setFile] = useState(null);
  const { auth } = useContext(AuthContext);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const fetchUser = async () => {
    try{
    setLoading(true);
    const response = await axios(`${RECORDEDEDIT}/${decryptedData}`, config);
    // console.log(response.data, "test24");
    setData(response?.data);
    setLoading(false);
    }
    catch(error){
      // setSubmit(false) 
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon2( error?.response?.data?.message,"error");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
      openNotificationWithIcon2("Internal Server","error")
      }
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);

  const initalValue = {
    title: data?.title?data?.title:"",
    link: data?.link?data?.link:"",
    vid_source: data?.vid_source?data?.vid_source:"",
    description: data?data?.description:"",
    thumbnail: data?.thumbnail,
    status: data?.status,
    video_length: data?data?.video_length:"",
  };
  function checkUrl(test_url) {
    var testLoc = document.createElement("a");
    testLoc.href = test_url.toLowerCase();
    let url = testLoc.hostname;
    var what;
    if (url.indexOf("youtube.com") !== -1) {
      what = "Youtube";
    } else if (url.indexOf("vimeo.com") !== -1) {
      what = "Vimeo";
    } else {
      what = "None";
    }
    return what;
  }
  const RecordedSchema = Yup.object({
    title: Yup.string().min(2).required("Please enter your title"),
    link: Yup.string().required("Please enter your video link"),
    link: Yup.string()
      .test(
        "isLarger",
        "link type and video link does not match",
        (value, testContext) => {
          let a = checkUrl(value);
          if (testContext.parent.vid_source == 0 && a == "Youtube") {
            return true;
          } else if (testContext.parent.vid_source == 1 && a == "Vimeo") {
            return true;
          } else {
            return false;
          }
        }
      )
      .required("Please enter a video link"),
    vid_source: Yup.string().required("Please enter a video source"),
    // description: Yup.string().required("Please enter your video description"),
    // thumbnail_path: Yup.string().required("Enter your thumbnail_path"),
    // thumbnail: Yup.string().required("Enter your thumbnail"),
    status: Yup.string().required("Please enter your status"),
    video_length: Yup.string().required("Please enter your Video Time"),
  });

  const defaultVal = [
    {
      label:
        data?.vid_source === "0"
          ? "YouTube"
          : data?.vid_source === "2"
          ? "Vdocipher"
          : "Vimeo",
      value: data?.vid_source,
    },
  ];
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    setFile(newFile); // Update state with selected file
    setUploadedImage(URL.createObjectURL(newFile)); // Update state with uploaded image URL
  };

  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Video updated </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (text, type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    field,
  } = useFormik({
    initialValues: initalValue,
    enableReinitialize: true,
    onSubmit: async (value) => {
      setErrorI("");
      setSubmit(true);

      // console.log(value);
      // {title:value.title,link_type:value.vid_source,video_link:value.link,desc:value.description,thumbnail:null,status:value.status},

      const formData = new FormData();
      if (file !== null) {
        formData.append("thumbnail", file);
      }
      formData.append("desc", values.description);
      formData.append("title", values.title);
      formData.append("link_type", value.vid_source);
      formData.append("status", values.status);
      formData.append("video_link", values.link);
      formData.append("time", values.video_length);

      // console.log(formData);

      try {
        const response = await axios.post(
          `${RECORDEDUPDATE}/${decryptedData}`,
          formData,
          config
        );
        // console.log(response, "updaterecorded");
        if (response) {
          openNotificationWithIcon1("success");
          setTimeout(() => {
            navigate("/admin/recorded-videos-list");
          }, 800);
          // toast.info("Video Updated");
        }
      } catch (error) {
        console.log(error?.response?.data?.message);
        setSubmit(false);
        if (error?.response?.data?.errors?.thumbnail) {
          toast.warning(error?.response?.data?.message);
          setErrorI(error?.response?.data?.errors?.thumbnail?.toString());
        }
        else if (error?.response?.status == 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      else if (error.response.status == 422) {
          openNotificationWithIcon2(error?.response?.data?.message, "error");
        }

        else{
          openNotificationWithIcon2(error?.response?.data?.message, "error");

        }
      }
    },
    validationSchema: RecordedSchema,
  });
  // console.log(values.thumbnail);

  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);

  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* <StudentHeader activeMenu={"Profile"} /> */}
      {/* Student Dashboard */}
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Video</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li
                          className="breadcrumb-item"
                          aria-current="page"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate("/admin/recorded-videos-list")
                          }
                        >
                          Recorded Videos
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                          role="button"
                        >
                          Edit Video
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    {/* <div className="personal-info-head">
                      <h5>Edit Video</h5>
                    </div> */}
                    <form onSubmit={handleSubmit}>
                      {loading === true ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Title
                              </label>
                              <input
                                type="title"
                                id="title"
                                name="title"
                                value={values.title}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Title"
                              />
                            </div>
                            {errors.title && touched.title ? (
                              <span style={{ color: "red" }}>
                                {errors.title}
                              </span>
                            ) : null}
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Select Link Type
                              </label>
                              <Select
                                className=" select country-select"
                                name="vid_source"
                                id="vid_source"
                                options={options}
                                defaultValue={defaultVal[0]}
                                placeholder="Select Link Type"
                                onChange={(option) =>
                                  setFieldValue("vid_source", option.value)
                                }
                                styles={style}
                                value={
                                  options
                                    ? options.find(
                                        (option) =>
                                          option?.value === field?.value
                                      )
                                    : ""
                                }
                              ></Select>
                              {errors.vid_source && touched.vid_source ? (
                                <span style={{ color: "red" }}>
                                  {errors.vid_source}
                                </span>
                              ) : null}
                              {/* <input
                                type="link"
                                id="link"
                                name="link"
                                value={values.link}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter link"
                              /> */}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Video Link (For Vdocipher: paste video id only)
                              </label>
                              <input
                                id="link"
                                name="link"
                                value={values.link}
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                placeholder="Enter Video Link"
                              />
                            </div>
                            {errors.link && touched.link ? (
                              <span style={{ color: "red" }}>
                                {errors.link}
                              </span>
                            ) : null}
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Video Time
                              </label>
                              <input
                                type="number"
                                name="video_length"
                                id="video_length"
                                value={values?.video_length}
                                onChange={handleChange}
                                // style={{minHeight:"50px"}}

                                className="form-control"
                                placeholder="Enter Video Time"
                              />
                              {errors.time && touched.time ? (
                                <span style={{ color: "red" }}>
                                  {errors.time}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group mb-0">
                            <label className="form-control-label">
                              Video Description
                            </label>
                            <div id="editor">
                              <TextEditor
                                type="description"
                                id="description"
                                name="description"
                                value={
                                  values?.description == null
                                    ? ""
                                    : values?.description
                                }
                                setFieldValue={setFieldValue}
                              />
                              {errors.description && touched.description ? (
                                <span style={{ color: "red" }}>
                                  {errors.description}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div
                            className="col-lg-12"
                            style={{ marginTop: "1em" }}
                          >
                            <div className="form-group">
                              <label className="form-control-label">
                                Thumbnail
                              </label>
                              <input
                                type="file"
                                name="Thumbnail"
                                id="Thumbnail"
                                // onChange={(e) => setFile(e.target.files[0])}
                                onChange={handleFileChange} 
                                className="form-control"
                                placeholder="Cover Image"
                              />
                              {errorI ? (
                                <span style={{ color: "red" }}>{errorI}</span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <div
                                className="add-image-box"
                                style={{ minHeight: "0", height: "200px" }}
                              >
                               {uploadedImage ? ( // Check if uploadedImage state is not null
            <img
              src={uploadedImage} // Display the uploaded image
              style={{
                height: "200px",
                width: "100%",
                borderRadius: "10px",
              }}
              alt="Thumbnail Preview"
            />
          ) : (
            values.thumbnail && ( // If uploadedImage state is null, check if values.thumbnail exists
              <img
                src={`${data?.thumbnail_path}/${values.thumbnail}`} // Display the previously uploaded image
                style={{
                  height: "200px",
                  width: "100%",
                  borderRadius: "10px",
                }}
                alt="Thumbnail Preview"
              />
            )
          )}
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-lg-12"
                            style={{ marginTop: "-8px" }}
                          >
                            <div className="form-group">
                              <label className="form-control-label">
                                Status
                              </label>
                              <br />
                              <div className="col-lg-6 ">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    id="inlineRadio1"
                                    checked={values?.status === "0"}
                                    onChange={getFieldProps("status").onChange}
                                    value={"0" || values?.status}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio1"
                                  >
                                    InActive
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    id="inlineRadio2"
                                    checked={values?.status === "1"}
                                    onChange={getFieldProps("status").onChange}
                                    value={"1" || values?.status}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio2"
                                  >
                                    Active
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div>
                            <button className="btn btn-primary"
                            type="submit"
                            disabled={submit}
                            >Update</button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              onClick={() => navigate(-1)}
                              className="btn btn-danger"
                            >
                              Cancel
                            </button>
                          </div> */}
                          <div className="update-profile" id="submit-button">
                            <div
                              className="btn-group mr-2"
                              id="custom-submit"
                              role="group"
                              aria-label="First group"
                            >
                              <button
                                type="submit"
                                className="btn btn-primary responsive-buttons"
                                disabled={submit}
                              >
                                Update
                              </button>
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
                            >
                              <button
                                type="button"
                                onClick={() => {
                                  navigate("/admin/recorded-videos-list");
                                  // windowTop(0,0)
                                }}
                                className="btn btn-primary cancel-btn responsive-buttons "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer autoClose={1000} hideProgressBar={true} />
      <Footer />
    </div>
  );
}
