import React, { useState } from "react";
import { InstructorHeader } from "../../instructor/header";
import { AudioOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { Link, useNavigate } from "react-router-dom";

import Footer from "../../footer";
import FeatherIcon from "feather-icons-react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";

export default function RecordedVideoModuleAndSubModule() {
  const { Search } = Input;

  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleAddShow = () => setShowAdd(true);
  const handleCloseModule = () => setShowAdd(false);
  const handleCloseModuleEdit = () => setShowEdit(false);
  const handleEditShow = () => setShowEdit(true);
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate("/admin/editvideo");
  };
  const handleView = () => {
    navigate("/admin/view-video");
  };
  const handleLink = () => {
    navigate("/admin/link-video");
  };

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );
  const onSearch = (value) => console.log(value);

  return (
    <div className="main-wrapper">
      {/* <InstructorHeader /> */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head student-meet-head comman-space border-0">
                          <div className="tak-head d-flex align-items-center">
                            <div>
                              <h3>Recorded Module & SubModule</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="comman-space pb-0">
                          <div className="settings-referral-blk table-responsive">
                            {/* Instructor Users */}
                            <Modal show={showAdd} onHide={handleCloseModule}>
                              <Modal.Header closeButton>
                                <Modal.Title>Add Module</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter name"
                                    />
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Delete Course</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Are You Sure Want Delete This Course?
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <Modal
                              show={showEdit}
                              onHide={handleCloseModuleEdit}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Edit Recorded Videos</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter name"
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Status
                                    </label>
                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          name="gender"
                                          value="male"
                                        />{" "}
                                        Active
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          name="gender"
                                          value="female"
                                        />{" "}
                                        Inactive
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>

                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <form>
                              <div className="student-search-form d-flex m-0">
                                {/* <div className="form-group mb-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Twitter Profile Name"
                              />
                            </div> */}
                                <div className="export-cvv-btn">
                                  <h4 className="text text-secondary">
                                    Modules1
                                  </h4>

                                  {/* Export CSV */}
                                </div>
                                <div className="profile-share d-flex align-items-center justify-content-center">
                                  <Link
                                    onClick={handleAddShow}
                                    className="btn btn-primary"
                                  >
                                    Add New
                                  </Link>
                                </div>
                              </div>
                            </form>
                            <table
                              className="table table-nowrap"
                              style={{ marginTop: "15px" }}
                            >
                              <thead>
                                <tr>
                                  <th>SR. NO.</th>
                                  <th>NAME</th>

                                  <th>STATUS</th>
                                  <th>ACTIONS</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>
                                    <Link
                                      to="/admin/courses/demo"
                                      className="refer-avatar-blk d-flex align-items-center"
                                    >
                                      <p>GS</p>
                                    </Link>
                                  </td>

                                  <td>
                                    <Switch
                                      onChange={handleChange}
                                      checked={checked}
                                      className="react-switch"
                                    />
                                  </td>
                                  <td>
                                    <button
                                      onClick={handleEditShow}
                                      type="button"
                                      class="btn btn-warning"
                                    >
                                      <i className="fa-regular fa-pen-to-square"></i>
                                    </button>{" "}
                                    <button
                                      onClick={handleShow}
                                      type="button"
                                      class="btn btn-danger"
                                    >
                                      <i
                                        class="fa fa-trash-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>{" "}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/* Instructor List */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="comman-space pb-0">
                          <div className="settings-referral-blk table-responsive">
                            {/* Instructor Users */}
                            <Modal show={showAdd} onHide={handleCloseModule}>
                              <Modal.Header closeButton>
                                <Modal.Title>Add Sub Module</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter name"
                                    />
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Delete Course</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Are You Sure Want Delete This Course?
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <Modal
                              show={showEdit}
                              onHide={handleCloseModuleEdit}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Edit Recorded Videos</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter name"
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Status
                                    </label>
                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          name="gender"
                                          value="male"
                                        />{" "}
                                        Active
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          name="gender"
                                          value="female"
                                        />{" "}
                                        Inactive
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>

                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <form>
                              <div className="student-search-form d-flex m-0">
                                {/* <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Twitter Profile Name"
                            />
                          </div> */}
                                <div className="export-cvv-btn">
                                  <h4 className="text text-secondary">
                                    Sub Modules
                                  </h4>

                                  {/* Export CSV */}
                                </div>
                                <div className="profile-share d-flex align-items-center justify-content-center">
                                  <Link
                                    onClick={handleAddShow}
                                    className="btn btn-primary"
                                  >
                                    Add New
                                  </Link>
                                </div>
                              </div>
                            </form>
                            <table
                              className="table table-nowrap"
                              style={{ marginTop: "15px" }}
                            >
                              <thead>
                                <tr>
                                  <th>SR. NO.</th>
                                  <th>NAME</th>

                                  <th>STATUS</th>
                                  <th>ACTIONS</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>
                                    <Link
                                      to="/admin/courses/demo"
                                      className="refer-avatar-blk d-flex align-items-center"
                                    >
                                      <p>GS</p>
                                    </Link>
                                  </td>

                                  <td>
                                    <Switch
                                      onChange={handleChange}
                                      checked={checked}
                                      className="react-switch"
                                    />
                                  </td>
                                  <td>
                                    <button
                                      onClick={handleEditShow}
                                      type="button"
                                      class="btn btn-warning"
                                    >
                                      <i className="fa-regular fa-pen-to-square"></i>
                                    </button>{" "}
                                    <button
                                      onClick={handleShow}
                                      type="button"
                                      class="btn btn-danger"
                                    >
                                      <i
                                        class="fa fa-trash-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>{" "}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/* Instructor List */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
