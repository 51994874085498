import React, { useState } from "react";
import Header from "../headerStudent";
import {
  bannerimg,
  Become1,
  Become2,
  CertificateIcon,
  Course1,
  Course2,
  Course3,
  Course4,
  Course5,
  Course6,
  CourseArrowBg,
  CourseIcon,
  GratuateIcon,
  Icon01,
  Icon02,
  Icon03,
  Icon04,
  Icon1,
  Icon10,
  Icon12,
  Icon13,
  Icon14,
  Icon15,
  Icon16,
  Icon17,
  Icon18,
  Icon19,
  Icon2,
  Icon20,
  Icon21,
  Icon7,
  Icon8,
  Icon9,
  Join,
  logo,
  PencilIcon,
  ChahalLogo,
  Share,
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
  Seminar,
} from "../imagepath";
import TopCategory from "./slider/topCategory";
import Loginbg from "../../assets/img/banner.png";
import CourseBg from "../../assets/img/course-bg.png";
import TrendingCourse from "./slider/trendingCourse";
import Companies from "./slider/companies";
import BgBanner1 from "../../assets/img/bg-banner-01.png";
import BgBanner2 from "../../assets/img/bg-banner-02.png";
import UserLove from "../../assets/img/user-love.jpg";
import Blog from "./slider/blog";
import Footer from "../footer";
import Testimonial from "./slider/testimonial";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useSpring, animated } from "react-spring";
import FooterStudent from "../fotterstudent";
import axios from "../../api/axios";
import axioscenter from "../../api/axios";

import DemoHeader from "../pages/course/demoHeader";
import ProfessionImages from "./slider/ScrolImages";
import DarkVariantExample from "./slider/Scrool";
const CATEGORYLIST="api/course-category"
const CENTERSLIST="/api/centers-login"
import { notification, Space, Modal } from "antd";



// const options = [
//   { label: "Category", value: "Category" },
//   { label: "Angular", value: "Angular" },
//   { label: "Node Js", value: "Node Js" },
//   { label: "React", value: "React" },
//   { label: "Python", value: "Python" },
// ];

export const Home = () => {
  const [value, setValue] = useState(null);
  const COURSEPAID = "/api/student/all-paidcourse-count";
  const navigate=useNavigate()
  const token = localStorage.getItem("tokenStudent");
  const[options,setOptions]=useState([])
  const[centersvalue,SetCentersValue]=useState([])


  const [selectedOption, setSelectedOption] = useState(null);
  // console.log(selectedOption)
  const dbvalue=sessionStorage.getItem("db");

 const removevalue= sessionStorage.getItem("db",)
 const removename= sessionStorage.getItem("centername",)
 useEffect(()=>{
  

 },[removevalue,removename])

  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "#FFDEDA",
      border: state.isFocused ? 0 : 0,
      paddingLeft: "5px",
      paddingTop:"5px",
      paddingBottom:"5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        border: state.isFocused ? 0 : 0,
        color: "black",
      },
      borderRadius: "50px",
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "2px" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  const Course = async () => {
    try {
      const resonse = await axios.get(CATEGORYLIST);
      // console.log(resonse,"response")
        setOptions(resonse?.data);
        // setSelectedOption(resonse?.data[0]);
      //  setTotalCourse(resonse?.data?.totalCourse)

    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIcon1("error",response?.data?.message,"red");

        navigate("/login");
      }
      else{
      openNotificationWithIcon1("error",response?.data?.message,"red");
      }
    }
  };
  const Centers = async () => {
    try {
      const resonse = await axios.get(CENTERSLIST);
      // console.log(resonse,"response")
       SetCentersValue(resonse?.data?.message)
        // setOptions(resonse?.data);
        // setSelectedOption(resonse?.data[0]);
      //  setTotalCourse(resonse?.data?.totalCourse)

    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      // openNotificationWithIcon2("error");
    }
  };
  const openNotificationWithIcon1 = (type,text,color) => {
    notification[type]({
      message: (
        <div style={{ color: color }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const CentersSend = async (name,fk_login_db) => {
    // try {
    //   const resonse = await axios.post(CENTERSLIST,{"name":name,"center":fk_login_db});
    //   //  console.log(resonse?.data?.message,"response")
       
    //     // setOptions(resonse?.data);
    //     // setSelectedOption(resonse?.data[0]);
    //   //  setTotalCourse(resonse?.data?.totalCourse)

    // } catch (error) {
    //   console.log(error);
    //   if (error?.response?.status === 401) {
    //     localStorage.removeItem("tokenStudent");
    //     navigate("/login");
    //     openNotificationWithIcon1("error","Sesion Expired","red")

    //   }
    //   else{
    //     openNotificationWithIcon1("error",error?.response?.data?.message,"red")

    //   }
    //   // openNotificationWithIcon2("error");
    // }
    sessionStorage.setItem("db",fk_login_db)
    sessionStorage.setItem("centername",name)
    
    setTimeout(()=>{
      window.location.reload()

    },200)
    openNotificationWithIcon1("success",`${name} Center is Selected`,"#52c41a")

    setTimeout(()=>{
      navigate(-1)

    },100)

  };
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
    Course();
    Centers()
  }, []);
  // console.log(centersvalue,"centers")
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const handleSearch = (e) => {
    setValue(e);
  };
  


  function Number({ n }) {
    const { number } = useSpring({
      from: { number: 0 },
      number: n,
      delay: 200,
      config: { mass: 1, tension: 20, friction: 10 },
    });
    return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
  }
//  console.log(value,"options")
 useEffect(()=>{

 },[dbvalue])
  return (
    <>
      <div className="main-wrapper">
        {token?<Header />:<DemoHeader/>}
        
        {/* banner */}
{dbvalue>0?
        <section
          className="home-slide d-flex align-items-center"
          style={{ backgroundImage: "url(" + Loginbg + ")" }}
        >
          <div className="container">
            <div className="row ">
              <div className="col-md-7">
                <div className="home-slide-face aos" data-aos="fade-up">
                  <div className="home-slide-text ">
                    <h5>The Leader in Online Learning</h5>
                    <h1>Engaging &amp; Accessible Online Courses For All</h1>
                    <p>Own your future learning new skills online</p>
                  </div>
                  <div className="banner-content">
                    <form className="form" >
                      <div className="form-inner">
                        <div className="input-group homeSearch">
                          <i className="fa-solid fa-magnifying-glass search-icon" />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search School, Online eductional centers, etc"
                            value={value}
                            onChange={(e)=>{
                              setValue(e.target.value)
                            }}
                          />
                          <span className="drop-detail">
                            <Select
                            // className="select2-container"
                              options={options?.map((ele)=>{
                                return{
                                  label:ele?.name,
                                  value:ele?.id
                                }
                              })}
                              
                               value={selectedOption}
                               defaultValue={options[0]}
                              placeholder="Category"
                              onChange={handleSelectChange}
                              styles={style}
                            ></Select>
                          </span>
                          <button
                            className="btn sub-btn"
                            type="submit"
                            onClick={()=>{
                              navigate(`/student/courses-list/${selectedOption?selectedOption?.value:0}`)
                            }}
                          >
                            <i className="fas fa-arrow-right" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="trust-user">
                    <p>
                      Trusted by over 15K Users <br />
                      worldwide since 2022
                    </p>
                    <div className="trust-rating d-flex align-items-center">
                      <div className="rate-head">
                        <h2>
                        <span className="d-flex"> 
                            <Number n={1000} />+
                         </span>
                        </h2>
                      </div>
                      <div className="rating d-flex align-items-center">
                        <h2 className="d-inline-block average-rating">4.4</h2>
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 d-flex align-items-center">
                <div className="girl-slide-img aos" data-aos="fade-up">
                  <img src={bannerimg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>:<></>}
        {/* /banner */}

        {/* Home banner bottom */}
        {dbvalue>0?
        <section className="section student-course">
          <div className="container">
            <div className="course-widget">
              <div className="row">
              <div className="col-lg-3 col-md-6 d-flex">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius aos"
                      data-aos="fade-up"
                    >
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src={GratuateIcon} alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                          <span className="d-flex"> 
                            <Number n={2000} /> +
                         </span>
                          </h4>
                          <p>Passed Students</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius aos"
                      data-aos="fade-up"
                    >
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src={CourseIcon} alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                          <span className="d-flex"> 
                            <Number n={20} />+
                         </span>
                          </h4>
                          <p>Centers </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius aos"
                      data-aos="fade-up"
                    >
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src={Seminar} style={{width:"81px",height:"81px"}} alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                          <span className="d-flex"> 
                            <Number n={500} />+
                         </span>
                          </h4>
                          <p>Free Seminars</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
                <div className="col-lg-3 col-md-6">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius align-items-center aos"
                      data-aos="fade-up"
                    >
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src={CertificateIcon} alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            {/* <span>10</span>K */}
                            <span className="d-flex"> 
                            <Number n={100} />+
                         </span>
                          </h4>
                          <p>Award Winning</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>:<></>}
      
        <section className="section how-it-works">
    <div className="container">
      <div className="section-header aos" data-aos="fade-up">
        <div className="section-sub-head">
          {/* <span>Images</span> */}
          <h2>Select Center</h2>
        </div>
       
      </div>
      <div className="row">
        {centersvalue?.map((item)=>{
          return(
<div className="col-lg-3 col-md-6 col-sm-12"
style={{ cursor: 'pointer' }}
 onClick={() => CentersSend(item?.name, item?.fk_login_db)}
>
<div>
                  <div className="course">
                    <div
                      className="blur-border course-radius align-items-center aos"
                      data-aos="fade-up"
                    >
                      <div className="online-course  align-items-center">
                        <div className="course-img d-flex"
                        >
                          {/* <img src={item?.img?item?.img:""} alt="Center Name"                         
                          style={{margin:"auto"}}
 /> */}
 {item?.img ? (
        <img  style={{margin:"auto"}} src={item.img} alt="Center Name" />
      ) : (
        <div style={{margin:"auto"}}>
        <i className="fa-solid fa-star"></i>
        </div>
      )}

                        </div>
                        <div className="course-inner-content mt-4"
                                                  

                        >
                       
                          <h5 style={{textAlign:"center"}}>{item?.name}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

</div>
          )
        })}
        
      </div>
     
        
     
    </div>
  </section>
        {/* Home banner bottom */}
{/* Images scrool bar */}
        {/* <ProfessionImages/> */}
        {dbvalue>0?
        <DarkVariantExample/>:<></>}
        {/* Top Category with Owl Carousel */}
        {dbvalue>0?
        <TopCategory />:<></>}
        {/* Top Category with Owl Carousel */}

        {/* What's new Featured Course */}
        {/* <section
          className="section new-course"
        >
          <div className="container">
            <div className="section-header aos" data-aos="fade-up">
              <div className="section-sub-head">
                <span>What’s New</span>
                <h2>Featured Courses</h2>
              </div>
              <div className="all-btn all-category d-flex align-items-center">
                <Link to="" className="btn btn-primary">
                  All Courses
                </Link>
              </div>
            </div>
            <div className="section-text aos" data-aos="fade-up">
              <p className="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                aenean accumsan bibendum gravida maecenas augue elementum et
                neque. Suspendisse imperdiet.
              </p>
            </div>
            <div className="course-feature">
              <div className="row">
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="course-box d-flex aos" data-aos="fade-up">
                    <div className="product">
                      <div className="product-img">
                        <Link to="">
                          <img className="img-fluid" alt="" src={Course1} />
                        </Link>
                        <div className="price">
                          <h3>
                            $300 <span>$99.00</span>
                          </h3>
                        </div>
                      </div>
                      <div className="product-content">
                        <div className="course-group d-flex">
                          <div className="course-group-img d-flex">
                            <Link to="">
                              <img src={User1} alt="" className="img-fluid" />
                            </Link>
                            <div className="course-name">
                              <h4>
                                <Link to="">
                                  Nicole Brown
                                </Link>
                              </h4>
                              <p>Instructor</p>
                            </div>
                          </div>
                          <div className="course-share d-flex align-items-center justify-content-center">
                            <Link to="#">
                              <i className="fa-regular fa-heart" />
                            </Link>
                          </div>
                        </div>
                        <h3 className="title instructor-text">
                          <Link to="">
                            Information About UI/UX Design Degree
                          </Link>
                        </h3>
                        <div className="course-info d-flex align-items-center">
                          <div className="rating-img d-flex align-items-center">
                            <img src={Icon1} alt="" />
                            <p>12+ Lesson</p>
                          </div>
                          <div className="course-view d-flex align-items-center">
                            <img src={Icon2} alt="" />
                            <p>9hr 30min</p>
                          </div>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star" />
                          <span className="d-inline-block average-rating">
                            <span>4.0</span> (15)
                          </span>
                        </div>
                        <div className="all-btn all-category d-flex align-items-center">
                          <Link to="" className="btn btn-primary">
                            BUY NOW
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="course-box d-flex aos" data-aos="fade-up">
                    <div className="product">
                      <div className="product-img">
                        <Link to="">
                          <img className="img-fluid" alt="" src={Course2} />
                        </Link>
                        <div className="price">
                          <h3>
                            $400 <span>$99.00</span>
                          </h3>
                        </div>
                      </div>
                      <div className="product-content">
                        <div className="course-group d-flex">
                          <div className="course-group-img d-flex">
                            <Link to="">
                              <img src={User2} alt="" className="img-fluid" />
                            </Link>
                            <div className="course-name">
                              <h4>
                                <Link to="">
                                  Jenis R.
                                </Link>
                              </h4>
                              <p>Instructor</p>
                            </div>
                          </div>
                          <div className="course-share d-flex align-items-center justify-content-center">
                            <Link to="#">
                              <i className="fa-regular fa-heart" />
                            </Link>
                          </div>
                        </div>
                        <h3 className="title instructor-text">
                          <Link to="">
                            Wordpress for Beginners - Master Wordpress Quickly
                          </Link>
                        </h3>
                        <div className="course-info d-flex align-items-center">
                          <div className="rating-img d-flex align-items-center">
                            <img src={Icon1} alt="" />
                            <p>11+ Lesson</p>
                          </div>
                          <div className="course-view d-flex align-items-center">
                            <img src={Icon2} alt="" />
                            <p>6hr 30min</p>
                          </div>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star" />
                          <span className="d-inline-block average-rating">
                            <span>4.3</span> (15)
                          </span>
                        </div>
                        <div className="all-btn all-category d-flex align-items-center">
                          <Link to="" className="btn btn-primary">
                            BUY NOW
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="course-box d-flex aos" data-aos="fade-up">
                    <div className="product">
                      <div className="product-img">
                        <Link to="">
                          <img className="img-fluid" alt="" src={Course3} />
                        </Link>
                        <div className="price combo">
                          <h3>FREE</h3>
                        </div>
                      </div>
                      <div className="product-content">
                        <div className="course-group d-flex">
                          <div className="course-group-img d-flex">
                            <Link to="">
                              <img src={User5} alt="" className="img-fluid" />
                            </Link>
                            <div className="course-name">
                              <h4>
                                <Link to="">
                                  Jesse Stevens
                                </Link>
                              </h4>
                              <p>Instructor</p>
                            </div>
                          </div>
                          <div className="course-share d-flex align-items-center justify-content-center">
                            <Link to="#">
                              <i className="fa-regular fa-heart" />
                            </Link>
                          </div>
                        </div>
                        <h3 className="title instructor-text">
                          <Link to="">
                            Sketch from A to Z (2022): Become an app designer
                          </Link>
                        </h3>
                        <div className="course-info d-flex align-items-center">
                          <div className="rating-img d-flex align-items-center">
                            <img src={Icon1} alt="" />
                            <p>16+ Lesson</p>
                          </div>
                          <div className="course-view d-flex align-items-center">
                            <img src={Icon2} alt="" />
                            <p>12hr 30min</p>
                          </div>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star" />
                          <span className="d-inline-block average-rating">
                            <span>4.5</span> (15)
                          </span>
                        </div>
                        <div className="all-btn all-category d-flex align-items-center">
                          <Link to="" className="btn btn-primary">
                            BUY NOW
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="course-box d-flex aos" data-aos="fade-up">
                    <div className="product">
                      <div className="product-img">
                        <Link to="">
                          <img className="img-fluid" alt="" src={Course4} />
                        </Link>
                        <div className="price">
                          <h3>
                            $500 <span>$99.00</span>
                          </h3>
                        </div>
                      </div>
                      <div className="product-content">
                        <div className="course-group d-flex">
                          <div className="course-group-img d-flex">
                            <Link to="">
                              <img src={User4} alt="" className="img-fluid" />
                            </Link>
                            <div className="course-name">
                              <h4>
                                <Link to="">
                                  Nicole Brown
                                </Link>
                              </h4>
                              <p>Instructor</p>
                            </div>
                          </div>
                          <div className="course-share d-flex align-items-center justify-content-center">
                            <Link to="#">
                              <i className="fa-regular fa-heart" />
                            </Link>
                          </div>
                        </div>
                        <h3 className="title instructor-text">
                          <Link to="">
                            Learn Angular Fundamentals From beginning to advance
                            lavel
                          </Link>
                        </h3>
                        <div className="course-info d-flex align-items-center">
                          <div className="rating-img d-flex align-items-center">
                            <img src={Icon1} alt="" />
                            <p>10+ Lesson</p>
                          </div>
                          <div className="course-view d-flex align-items-center">
                            <img src={Icon2} alt="" />
                            <p>8hr 30min</p>
                          </div>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star" />
                          <span className="d-inline-block average-rating">
                            <span>4.2</span> (15)
                          </span>
                        </div>
                        <div className="all-btn all-category d-flex align-items-center">
                          <Link to="" className="btn btn-primary">
                            BUY NOW
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="course-box d-flex aos" data-aos="fade-up">
                    <div className="product">
                      <div className="product-img">
                        <Link to="">
                          <img className="img-fluid" alt="" src={Course5} />
                        </Link>
                        <div className="price">
                          <h3>
                            $300 <span>$99.00</span>
                          </h3>
                        </div>
                      </div>
                      <div className="product-content">
                        <div className="course-group d-flex">
                          <div className="course-group-img d-flex">
                            <Link to="">
                              <img src={User3} alt="" className="img-fluid" />
                            </Link>
                            <div className="course-name">
                              <h4>
                                <Link to="">
                                  John Smith
                                </Link>
                              </h4>
                              <p>Instructor</p>
                            </div>
                          </div>
                          <div className="course-share d-flex align-items-center justify-content-center">
                            <Link to="#">
                              <i className="fa-regular fa-heart" />
                            </Link>
                          </div>
                        </div>
                        <h3 className="title instructor-text">
                          <Link to="">
                            Build Responsive Real World Websites with HTML5 and
                            CSS3
                          </Link>
                        </h3>
                        <div className="course-info d-flex align-items-center">
                          <div className="rating-img d-flex align-items-center">
                            <img src={Icon1} alt="" />
                            <p>13+ Lesson</p>
                          </div>
                          <div className="course-view d-flex align-items-center">
                            <img src={Icon2} alt="" />
                            <p>10hr 30min</p>
                          </div>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star" />
                          <span className="d-inline-block average-rating">
                            <span>4.0</span> (15)
                          </span>
                        </div>
                        <div className="all-btn all-category d-flex align-items-center">
                          <Link to="" className="btn btn-primary">
                            BUY NOW
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex">
                  <div className="course-box d-flex aos" data-aos="fade-up">
                    <div className="product">
                      <div className="product-img">
                        <Link to="">
                          <img className="img-fluid" alt="" src={Course6} />
                        </Link>
                        <div className="price combo">
                          <h3>FREE</h3>
                        </div>
                      </div>
                      <div className="product-content">
                        <div className="course-group d-flex">
                          <div className="course-group-img d-flex">
                            <Link to="">
                              <img src={User6} alt="" className="img-fluid" />
                            </Link>
                            <div className="course-name">
                              <h4>
                                <Link to="">
                                  Stella Johnson
                                </Link>
                              </h4>
                              <p>Instructor</p>
                            </div>
                          </div>
                          <div className="course-share d-flex align-items-center justify-content-center">
                            <Link to="#">
                              <i className="fa-regular fa-heart" />
                            </Link>
                          </div>
                        </div>
                        <h3 className="title instructor-text">
                          <Link to="">
                            C# Developers Double Your Coding Speed with Visual
                            Studio
                          </Link>
                        </h3>
                        <div className="course-info d-flex align-items-center">
                          <div className="rating-img d-flex align-items-center">
                            <img src={Icon1} alt="" />
                            <p>7+ Lesson</p>
                          </div>
                          <div className="course-view d-flex align-items-center">
                            <img src={Icon2} alt="" />
                            <p>7hr 30min</p>
                          </div>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star" />
                          <span className="d-inline-block average-rating">
                            <span>4.6</span> (15)
                          </span>
                        </div>
                        <div className="all-btn all-category d-flex align-items-center">
                          <Link to="" className="btn btn-primary">
                            BUY NOW
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* What's new Featured Course */}

        {/* Master Skills */}
        {dbvalue>0?
        <section className="section master-skill p-0"
        
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="section-header aos" data-aos="fade-up">
                  <div className="section-sub-head">
                    <span>What’s New</span>
                    <h2>Master the skills to drive your career</h2>
                  </div>
                </div>
                <div className="section-text aos" data-aos="fade-up">
                  <p>
                    Get certified, master modern tech skills, and level up your
                    career — whether you’re starting out or a seasoned pro. 95%
                    of eLearning learners report our hands-on content directly
                    helped their careers.
                  </p>
                </div>
                <div className="career-group aos" data-aos="fade-up">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img src={Icon01} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <p>Stay motivated with engaging instructors</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img src={Icon02} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <p>Keep up with in the latest in cloud</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img src={Icon03} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <p>Get certified with 100+ certification courses</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img src={Icon04} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <p>Build skills your way, from labs to courses</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 d-flex align-items-end">
                <div className="career-img aos" data-aos="fade-up">
                  <img src={Join} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>:<></>}
        {/* /Master Skills */}

        {/* Trending Course */}
        {/* <TrendingCourse /> */}
        {/* Trending Course */}

        {/* Companies */}
        {/* <section className="section lead-companies">
          <div className="container">
            <div className="section-header aos" data-aos="fade-up">
              <div className="section-sub-head feature-head text-center">
                <span>Trusted By</span>
                <h2>500+ Leading Universities And Companies</h2>
              </div>
            </div>
            <div className="lead-group aos" data-aos="fade-up">
              <Companies />
            </div>
          </div>
        </section> */}
        {/* Companies */}

        {/* Share knowledge */}
        {/* <section
          className="section share-knowledge"
          style={{ backgroundImage: "url(" + BgBanner1 + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="knowledge-img aos" data-aos="fade-up">
                  <img src={Share} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="join-mentor aos" data-aos="fade-up">
                  <h2>Want to share your knowledge? Join us a Mentor</h2>
                  <p>
                    High-definition video is video of higher resolution and
                    quality than standard-definition. While there is no
                    standardized meaning for high-definition, generally any
                    video.
                  </p>
                  <ul className="course-list">
                    <li>
                      <i className="fa-solid fa-circle-check" />
                      Best Courses
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check" />
                      Top rated Instructors
                    </li>
                  </ul>
                  <div className="all-btn all-category d-flex align-items-center">
                    <Link to="" className="btn btn-primary">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* /Share knowledge */}

     
        {/* Testimonial Carousel */}
        {/* <Testimonial /> */}
        {dbvalue>0?
        <Testimonial /> 
        :<></>}

        {/* Testimonial Carousel */}

        {/* Become a instructor */}
        {/* <section className="section become-instructors aos" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 d-flex">
                <div className="student-mentor cube-instuctor ">
                  <h4>Become An Instructor</h4>
                  <div className="row">
                    <div className="col-lg-7 col-md-12">
                      <div className="top-instructors">
                        <p>
                          Top instructors from around the world teach millions
                          of students on Mentoring.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <div className="mentor-img">
                        <img className="img-fluid" alt="" src={Become2} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 d-flex">
                <div className="student-mentor yellow-mentor">
                  <h4>Transform Access To Education</h4>
                  <div className="row">
                    <div className="col-lg-8 col-md-12">
                      <div className="top-instructors">
                        <p>
                          Create an account to receive our newsletter, course
                          recommendations and promotions.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="mentor-img">
                        <img className="img-fluid" alt="" src={Become1} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* /Become a instructor */}

        {/* Blog */}
        {/* <section
          className="section latest-blog"
          style={{ backgroundImage: "url(" + BgBanner2 + ")" }}
        >
          <div className="container">
            <div className="section-header aos" data-aos="fade-up">
              <div className="section-sub-head feature-head text-center mb-0">
                <h2>Latest Blogs</h2>
                <div className="section-text aos" data-aos="fade-up">
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Eget aenean accumsan bibendum gravida maecenas augue
                    elementum et neque. Suspendisse imperdiet.
                  </p>
                </div>
              </div>
            </div>
            <Blog />
            <div className="enroll-group aos" data-aos="fade-up">
              <div className="row ">
                <div className="col-lg-4 col-md-6">
                  <div className="total-course d-flex align-items-center">
                    <div className="blur-border">
                      <div className="enroll-img ">
                        <img src={Icon7} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="course-count">
                      <h3>
                        <span className="counterUp">253,085</span>
                      </h3>
                      <p>STUDENTS ENROLLED</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="total-course d-flex align-items-center">
                    <div className="blur-border">
                      <div className="enroll-img ">
                        <img src={Icon8} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="course-count">
                      <h3>
                        <span className="counterUp">1,205</span>
                      </h3>
                      <p>TOTAL COURSES</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="total-course d-flex align-items-center">
                    <div className="blur-border">
                      <div className="enroll-img ">
                        <img src={Icon9} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="course-count">
                      <h3>
                        <span className="counterUp">127</span>
                      </h3>
                      <p>COUNTRIES</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lab-course">
              <div className="section-header aos" data-aos="fade-up">
                <div className="section-sub-head feature-head text-center">
                  <h2>
                    Unlimited access to 360+ courses <br />
                    and 1,600+ hands-on labs
                  </h2>
                </div>
              </div>
              <div className="icon-group aos" data-aos="fade-up">
                <div className="offset-lg-1 col-lg-12">
                  <div className="row">
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon9} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon10} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon16} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon12} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon13} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon14} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon15} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon16} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon17} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon18} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* /Blog */}

        {/* Footer */}
        <div className=""
        style={{marginTop:"50px"}}
        >
        <FooterStudent />

        </div>
        {/* /Footer */}
      </div>
    </>
  );
};

export default Home;
