import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import { Link, useNavigate } from "react-router-dom";
import StudentSideBar from "../sidebar";
import Select from "react-select";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextEditor from "../../pages/course/addCourse/textEditor";
import { useFormik } from "formik";
import axios from "../../../api/axios";
import * as Yup from "yup";
import AuthContext from "../../../context/AuthProvider";
import AdminHeader from "../../instructor/adminHeader";
import { async } from "q";
import { notification, Space } from "antd";

const CREATEFEEDS = "/api/feeds/create";
const ADDBATCH = "/api/course/add-batch";
const FEDDMASTER="/api/feed-master/show-active"
const COURSETIME="/api/course-type/show-active"
const UPLOADIMAGE="/api/uploads";

const initalValue = {
  name: "",
  status: "",
  description: "",
  fk_feed_master_id:"",
};
const courseSchema = Yup.object({
  name: Yup.string().min(2).required("Please enter your name"),
  status: Yup.string().required("Please select your status"),
//   fk_feed_master_id:Yup.string().required("Please select your Feed Master"),
  // batch: Yup.array()
  //   .min(1, "Pick at least one items")
  //   .of(
  //     Yup.object().shape({
  //       label: Yup.string().required(),
  //       value: Yup.string().required(),
  //     })
  //   ),
});

export default function AddAdminFeedlist() {
  const [country, setCountry] = useState(null);
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();
  const [radio, setRadio] = useState(null);
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [api, contextHolder] = notification.useNotification();
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedCoursetime, setSelectedCoursetime] = useState([]);
  const [selectedCoursevalue, setSelectedCoursevalue] = useState("");
  const [selectedCoursetimevalue, setSelectedCoursetimevalue] = useState("");
const [imageerror,setImageError]=useState("")
  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon2 = (type, text) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIconerror = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>course is already exists</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    
    onSubmit: async (values, { setFieldError }) => {
        // console.log("krishna before try");

      setSubmit(true);
      try {

        // console.log("krishna");
        const formData = new FormData();
      
        formData.append("name", values.name);
        formData.append("content", values.description);
        formData.append("status", values.status);
        formData.append("file",filePre)
        formData.append("fk_feed_master_id",selectedCoursevalue)

        const response = await axios.post(CREATEFEEDS, formData, config);
        // console.log(response);
        if (response) {
          openNotificationWithIcon("success", "Feed Created", "#52c41a");
          setTimeout(() => {
            navigate("/admin/feeds");
          }, 800);
          // toast.success("Course Created");
        }
      } catch (error) {
        setSubmit(false);
        console.log(error);
        if (error?.response?.status === 422) {
          openNotificationWithIcon("error", error.response.data.message, "red");
        } 
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIcon("error", error?.response?.data?.message,"red");
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
       
        // if (error.response.data.errors.image) {
        //   setFieldError("image", error.response.data.errors.image.toString());
        // }
       else if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
        else {
          openNotificationWithIcon("error", "Internal Server Error", "red");
        }
      }
    },
    validationSchema: courseSchema,
  });
  const handleRadio = (e) => {
    if (e === "option2") {
      setFieldValue("amount", 0);
    }
    setRadio(e);
  };
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  
 

const UploadImage = async (e) => {
    try {
        const formData=new FormData()
        // if (file === null) {
        //     formData.append("file", "");
        //   } else if (file !== null) {
        //     formData.append("file", file);
        //   } else {
        //     formData.append("file", file);
        //   }
        formData.append("file",e?.target?.files[0])
          formData.append("type","image")
        const res = await axios.post(UPLOADIMAGE, formData,config)
// console.log(res,"res")
        setFilePre(res?.data?.message)
    } catch (error) {
        console.log(error?.response?.data?.message);
        setImageError()
        if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
        }
        openNotificationWithIcon("error",error?.response?.data?.message,"red")

    }
}
const fetchCourseCategory = async () => {
    try {
        const res = await axios.get(FEDDMASTER, config)
// console.log(res,"res")
        setSelectedCourse(res?.data?.message)
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
        }

    }
}
// console.log(filePre,"file")
useEffect(() => {
  fetchCourseCategory()
}, []);
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // console.log(selectedCourse,"selected course")
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"feeds"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Feeds</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li className="breadcrumb-item" role="button">
                          <a onClick={() => navigate("/admin/feeds")}>
                            Feeds
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Add Feeds
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Feed Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Name"
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Feed Master</label>
                            <select
                              className="form-control"
                              id="fk_feed_master_id"
                              name="fk_feed_master_id"
                              value={selectedCoursevalue}
                              onChange={(e) =>
                                setSelectedCoursevalue(e.target.value)
                              }
                            >
                              <option value="">Select a Feed Master</option>
                              {selectedCourse?.map((course) => (
                                <option key={course?.id} value={course?.id}>
                                  {course?.name}
                                </option>
                              ))}
                            </select>
                  
                            {!selectedCourse && (
                              <div className="text-danger">
                                Please select a Feed Master.
                              </div>
                            )}
                          </div>

                          {/* </div> */}
                          {/* <h3>{userChoice?.label}</h3> */}
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">
                              Cover image
                            </label>
                            <input
                              type="file"
                              name="file"
                              id="file"
                              onChange={(e) => {
                                setFile(e.target.files[0]),
                                //   setFilePre(
                                //     URL.createObjectURL(e.target.files[0])
                                //   );
                                UploadImage(e);

                              }}
                              className="form-control"
                              placeholder="Cover Image"
                            />
                            {/* {errors.image && touched.image ? (
                              <span style={{ color: "red" }}>
                                {errors.image}
                              </span>
                            ) : null} */}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div
                              className="add-image-box"
                              style={{ minHeight: "0", height: "200px" }}
                            >
                              {!filePre && (
                                <Link to="#" style={{ fontSize: "200px" }}>
                                  <i className="far fa-image" />
                                </Link>
                              )}
                              {filePre && (
                                <img
                                  src={filePre}
                                  style={{
                                    height: "200px",
                                    width: "100%",
                                    borderRadius: "10px",
                                  }}
                                ></img>
                              )}
                            </div>
                          </div>
                        </div>
                       
                        <div className="form-group mb-0">
                          <label className="form-control-label">
                            Content
                          </label>
                          <div id="editor">
                            {/* <TextEditor
                              id="description"
                              name="description"
                              // onBlur={handleBlur}
                              value={values.description}
                              onChange={handleChange}
                            /> */}
                            <TextEditor
                              type="description"
                              id="description"
                              name="description"
                              value={values.description}
                              setFieldValue={setFieldValue}
                            />
                            {/* <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              name="description"
                              // onBlur={handleBlur}
                              value={values.description}
                              onChange={handleChange}
                              rows="3"
                            ></textarea> */}
                            {errors.description && touched.description ? (
                              <span style={{ color: "red" }}>
                                {errors.description}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Status :</label>
                            <br />
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="inlineRadios1"
                                onChange={getFieldProps("status").onChange}
                                value="0"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadios1"
                              >
                                InActive
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="inlineRadios2"
                                onChange={getFieldProps("status").onChange}
                                value="1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadios2"
                              >
                                Active
                              </label>
                            </div>
                            <div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        
                       
                       
                        <div className="update-profile" id="submit-button"
                        style={{marginTop:"20px"}}
                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={submit}
                            //   value={console.log("krishna in submit")}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
