import { Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "../../../api/axios";
import Wave from "../../../assets/img/TestResult/wavy.png";
const QUESTIONSRESULT = "api/student/test-result-analytics";
import ApexCharts from "react-apexcharts";
import Chart from "react-apexcharts";
import { schemeCategory10 } from "d3-scale-chromatic";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import wrongimg from "../../../assets/img/Dashboard/wrongQuestions.png"
import VideoJs from "../../pages/course/addCourse/videoJs";

// import MyChart from "./chart.jsx";
// import BarChart from "./Barchrat";
// import StackedBarChart from "./StackedBar";
// import SubjectWiseGraph from "./SubjectWiseGraph";
const Subjectwise = ({ result,}) => {
  //   const [wrongAccordionOpen, setWrongAccordionOpen] = useState(false);
  //   const [leaveAccordionOpen, setLeaveAccordionOpen] = useState(false);
  const [openAccordions, setOpenAccordions] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [videoid,setvideoid]=useState([])
const[you,setyou]=useState([])
  const handelvimeovideo=(link)=>{
    setvideoid(link)
    handleShowForm();
  }
  const handelyoutubevideo=(url1)=>{
    setyou(url1)
    handleShowForm()
  }
  const YouTubeGetID = (url) => {
    if (typeof url !== "string") {
      return null;
    }
  
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
  
    return match && match[2].length === 11 ? match[2] : null;
  };
  const ytcode = YouTubeGetID(you);
  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };

  return (
    <div className="" style={{ marginTop: "20px" }}>
     <div className="row"
 style={{justifyContent:"center"}}
 >
  {result?.wrongQuestions?.length>0? 
  <div className="col-md-10">
  <div className="settings-widget"
                    style={{border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}

  >
                    <div className="settings-inner-blk p-10">
                    <div className="col-12"
                    style={{padding:"10px",paddingBottom:"20px"}}
                    >
                 <h3 style={{color:"red",marginLeft:"20px"}}>Wrong Questions Analysis</h3> 
                {result?.wrongQuestions?.map((item, index) => {
                  // console.log(item,"single subject")
                   const parser = new DOMParser();
                   const doc = parser.parseFromString(item.description, 'text/html');
                   const doc1 = parser.parseFromString(item?.question, 'text/html');
                   const questionContent = doc1.body.textContent;
                   // Get the text content
                   const textContent = doc.body.textContent;
                 
                   // Find the image element and get its source
                   const imageElement = doc.body.querySelector('img');
                   const imageUrl = imageElement ? imageElement.src : null;
                  //  console.log(imageUrl,"image")
                  const videoUrl = "https://vimeo.com/252443587"
const videoId = videoUrl.split("v=")[1]; // Extract video ID

const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div style={{marginTop:"15px"}} key={index}>
    <div className="accordion" id={`wrongQuestionsAccordion${index}`} 
    style={{marginTop:"5px"}}
    >
      <div className="accordion-item"
                // style={{background:"#fff5e6"}}

      >
        <h2 className="accordion-header d-flex">
          <button
className="accordion-button collapsed"
type="button"
data-bs-toggle="collapse"
data-bs-target={`#collapseWrongOne${index}`}
aria-expanded="false"
aria-controls={`collapseWrongOne${index}`}
    style={{backgroundColor:"#e6e6e6",color:"black"}}
          >
           Q{index+1}{")"}{" "}{questionContent}
            {/* <span style={{ textAlign: "center" }}>Physiscs</span> */}
          </button>
        </h2>
        <div
          id={`collapseWrongOne${index}`}
          className="accordion-collapse collapse"
          data-bs-parent="#wrongQuestionsAccordion"
        >
          <div className="accordion-body">
          <div className="settings-widget profile-details"
                    style={{border:"none"}}

          >
                <div className="settings-menu p-0">
                  
                  <div className="address-check-widget comman-space">
                    <div className="check-bill-address">
                     
                      <div className="edit-new-address wallet-method wallet-radio-blk d-flex align-items-center"
                      style={{marginBottom:"5px"}}
                      >
                        <label className=""><h6>Wrong Answer</h6>
                       
                                               <CloseCircleOutlined twoToneColor="#FF0000"
                        style={{fontSize:"18px",color:"red"}} />

                          <span className="checkmark"
                          style={{fontSize:"20px",marginLeft:"7px",}}
                          >{item?.your_answer}</span> 
                        </label>
     
                      </div>
                      <div className="edit-new-address wallet-method wallet-radio-blk d-flex align-items-center mb-0"
                                            style={{marginBottom:"5px"}}

                      >
                      <label className="">
                        <h6>Correct Answer</h6>
                      <CheckCircleTwoTone twoToneColor="#52c41a"
                        style={{fontSize:"18px"}}
                        />

                      
                          <span className="checkmark"
                          style={{fontSize:"20px",marginLeft:"7px"}}
                          >{item?.answer}</span> 
                        </label>
                       
                      </div>
                    {item?.description?<div className="add-new-address grad-border hvr-sweep-to-right"
                      style={{marginTop:"5px"}}
                      >
                        <h4                      
                        style={{color:"black",textTransform:"capitalize"}}
>
                        Descrption {" : "}

                        </h4>
                        <p style={{color:"rgb(134, 134, 134)",fontSize:"24px"}}>{textContent}</p>
      {imageUrl && <img src={imageUrl} alt="image" />}

                      </div>:""}
                      {item?.video?
                      <div className="add-new-address grad-border hvr-sweep-to-right"
                      style={{marginTop:"5px"}}
                      >
                        <button className="btn btn-secondary"
                        onClick={()=>{
                          let embedUrl;

    // Check if it's a YouTube link
    if (item?.video?.includes('youtube.com')) {
      const videoId = item.video.split('v=')[1];
      embedUrl = `https://www.youtube.com/embed/${videoId}`;
    }
    // Check if it's a Vimeo link
    else if (item?.video?.includes('vimeo.com')) {
      const videoId = item.video.split('/').pop();
      embedUrl = `https://player.vimeo.com/video/${videoId}`;
    }
    // For any other link
    else {
      embedUrl = item?.video; // Assuming it's a valid video URL
    }
    handelvimeovideo(embedUrl)
                        }}
                        >Watch Video</button>
                  
                      </div>:""}
                      {item?.explaination?
                      <div className="add-new-address grad-border hvr-sweep-to-right"
                      style={{marginTop:"5px",color:"black"}}
                      >
                        <h6>Solution : </h6>
                       <div style={{color:"black"}} dangerouslySetInnerHTML={{ __html: item?.explaination }} />
                  
                      </div>:""}
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
})}

         
                </div>
                      </div>
                      </div>
  </div>:""}
 

     </div>
     <div>

     </div>
                          <Modal
                            title="Video"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                                height:"450px"
                              }}
                              open={isModalVisibleForm}
                            okText="Ok"
                              onOk={() => {
                                // handleAddNotification();
                                setIsModalVisibleForm(false);

                              }}
                              onCancel={() => {
                                setIsModalVisibleForm(false);
                              }}
                            >
             
<div>
 <div style={{height:"400px",width:"100%"}}>
 
      <iframe
        title="Video"
        src={videoid}
        width="80%"
        // height="400"
        frameBorder="0"
        allowFullScreen
        style={{marginTop:"-40px",height:"400px"}}
      ></iframe>
       {/* <VideoJs
                                      src={`https://www.youtube.com/embed/${ytcode}`}
                                      type="video/youtube"
                                    /> */}
    
    
  </div>
</div>
                            </Modal>
{result?.leaveQuestions?.length>0? <div className="row"
 style={{justifyContent:"center"}}
 >
  <div className="col-md-10">
  <div className="settings-widget">
                    <div className="settings-inner-blk p-10">
                    <div className="col-12"
                    style={{padding:"10px",paddingBottom:"20px"}}
                    >
                 <h3>Unattempted Questions Analysis</h3> 
                {result?.leaveQuestions?.map((item, index1) => {
                  // console.log(item,"single subject")
                   const parser = new DOMParser();
                   const doc = parser.parseFromString(item.description, 'text/html');
                   const doc1 = parser.parseFromString(item?.question, 'text/html');
                   const questionContent = doc1.body.textContent;

                   // Get the text content
                   const textContent = doc.body.textContent;

                   // Find the image element and get its source
                   const imageElement = doc.body.querySelector('img');
                   const imageUrl = imageElement ? imageElement.src : null;
                  //  console.log(imageUrl,"image")

  return (
    <div style={{marginTop:"15px"}} key={index1}>
    <div className="accordion" id={`unattemptedAccordionExample${index1}`} 
    style={{marginTop:"5px"}}
    >
      <div className="accordion-item">
        <h2 className="accordion-header d-flex">
          <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapseUnattemptedOne${index1}`}
          aria-expanded="false"
          aria-controls={`collapseUnattemptedOne${index1}`}
    style={{backgroundColor:"#e6e6e6",color:"black"}}
          >
           Q{index1+1}{")"}{" "}{questionContent}
            {/* <span style={{ textAlign: "center" }}>Physiscs</span> */}
          </button>
        </h2>
        <div
 id={`collapseUnattemptedOne${index1}`}
 className="accordion-collapse collapse"
 data-bs-parent="#unattemptedAccordion"
        >
          <div className="accordion-body">
          <div className="settings-widget profile-details"
          style={{border:"none"}}
          >
                <div className="settings-menu p-0">
                  
                  <div className="address-check-widget comman-space">
                    <div className="check-bill-address">
                      <div className="edit-new-address wallet-method wallet-radio-blk d-flex align-items-center mb-0"
                                            style={{marginBottom:"5px"}}

                      >
                      <label className="">

                        <h6>Correct Answer</h6>
                      <CheckCircleTwoTone twoToneColor="#52c41a"
                        style={{fontSize:"18px"}}
                        />

                      
                          <span className="checkmark"
                          style={{fontSize:"20px",marginLeft:"7px"}}
                          >{item?.answer}</span> 
                        </label>
                       
                      </div>
                    {item?.description?<div className="add-new-address grad-border hvr-sweep-to-right"
                      style={{marginTop:"5px"}}
                      >
                        <h4                      
                        style={{color:"black",textTransform:"capitalize"}}
>
                        Descrption {" : "}

                        </h4>
                        <p style={{color:"rgb(134, 134, 134)",fontSize:"24px"}}>{textContent}</p>
      {imageUrl && <img src={imageUrl} alt="image" />}

                      </div>:""}
                      {item?.video?
                      <div className="add-new-address grad-border hvr-sweep-to-right"
                      
                      >
                        <button className="btn btn-secondary"
                        
                        onClick={()=>{
                          let embedUrl;

    // Check if it's a YouTube link
    if (item?.video?.includes('youtube.com')) {
      const videoId = item.video.split('v=')[1];
      embedUrl = `https://www.youtube.com/embed/${videoId}`;
    }
    // Check if it's a Vimeo link
    else if (item?.video?.includes('vimeo.com')) {
      const videoId = item.video.split('/').pop();
      embedUrl = `https://player.vimeo.com/video/${videoId}`;
    }
    // For any other link
    else {
      embedUrl = item?.video; // Assuming it's a valid video URL
    }
    handelvimeovideo(embedUrl)
                        }}
                        >Watch Video</button>
                  
                      </div>:""}
                      {item?.explaination?
                      <div className="add-new-address grad-border hvr-sweep-to-right"
                     
                      >
                        <h6>Solution : </h6>
                       <div style={{color:"black"}} dangerouslySetInnerHTML={{ __html: item?.explaination }} />
                  
                      </div>:""}
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
})}

         
                </div>
                      </div>
                      </div>
  </div>

 </div>  :""}
      
    </div>
  );
};

export default Subjectwise;
