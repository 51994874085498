import React from 'react'
import { useState } from 'react';
import { Scrollbar } from "react-scrollbars-custom";
import axiosInstance from '../../../api/axios1';
import { useEffect } from 'react';
import StudentHeader from '../studentHeader';
import Footer from '../../footer';
import { Icon1 } from '../../imagepath';
const INVOICE="api/get-student-InvoiceList"
import { LogOut, Moon, Star, User, Delete ,Download} from "react-feather";
import { Link, useNavigate } from "react-router-dom";


const InvoiceDownload = () => {
  
  const [loading, setLoading] = useState(false);
  const [invoicedata,setInvoiceData]=useState([])
  const tokenStudent = localStorage.getItem("tokenStudent");

  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const InvoiceDownload = async () => {
    try {
      setLoading(true);
      const resonse = await axiosInstance.get(INVOICE, config);
      //  console.log(resonse?.data,"response")
      setInvoiceData(resonse?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("tokenStudent");
        localStorage.removeItem("showNotification")

        navigate("/login");

      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIcon3("error", error?.response?.data?.message,);
        setTimeout(() => {
          navigate("/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon3("error",error?.response?.data?.message)
      }
      // openNotificationWithIcon2("error");
    }
  };

  useEffect(() => {
    InvoiceDownload();
    
  }, []);
  // console.log(invoicedata,"expired")
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  return (
    <div className="main-wrapper">
    <StudentHeader />
    {/* Student Dashboard */}
    <div className="page-content">
      <div className="container">
        <div className="row">
          <div className='col-6'
          style={{margin:"auto"}}
          >
          {invoicedata?.length > 0 ? (
                          <Scrollbar style={{ height: 300 }}>
                            <table className="table table-nowrap">
                              <thead style={{textAlign:"center"}}>
                                <tr >
                                  <th>Course Name</th>
                                  <th >Invoice No</th>
                                  <th >Invoice Date</th>
                                  <th>Amount</th>
                                  <th >Downlaod Pdf</th>
                                  
                                </tr>
                              </thead>
                              <tbody>
                                {invoicedata?.length > 0 &&
                                  invoicedata?.map((item, i) => {
                                    // console.log(item,"item")
                                    // const dateParts = item?.expiry_date.split('-');
                                    // const formattedDate = ""
                                    return (
                                      <tr key={i}
                                      style={{textAlign:"center"}}
                                      >
                                        <td>
                                          <p>
                                            {/* <img
                                              src={Icon1}
                                              alt=""
                                              className="me-2"
                                            /> */}
                                            {item?.courses?.name}
                                          </p>
                                        </td>
                                        <td> {item?.inv_number}</td>
                                        <td> {item?.inv_date}</td>
                                        <td> {item?.total_amount}</td>

                                        <td >
                                        <Link
                      className="dropdown-item text"
                    //   to="/student/invoicefee"
                    onClick={()=>{
                        window.open(item?.pdf_url, "_blank");

                    }}
                    >
                      <Download
                        size={14}
                        color={"#FF875A"}
                        className="headerIcon"
                      />{" "}
                      
                    </Link>
                                        </td>

                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </Scrollbar>
                        ) : (
                          <h4 style={{ padding: "20px",textAlign:"center" }}>
                            No Invoices
                          </h4>
                        )}
          </div>
        
        </div>
      </div>
    </div>
    <Footer />
  </div>
   
  )
}

export default InvoiceDownload