import React, { useState,useEffect } from "react";
import CourseHeader from "../header";
import GridInnerPage from "./gridInnerPage";
import FeatherIcon from "feather-icons-react";
import { Blog1, Blog2, Blog3 } from "../../../imagepath";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../footer";
import StudentHeader from "../../../student/studentHeader";
import FooterStudent from "../../../fotterstudent";

const StudentCourse = () => {
  const {id}=useParams()
  localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);

    const tokenStudent =localStorage.getItem('tokenStudent')
    const navigate=useNavigate()
    useEffect(() => {
      const tokenStudent =localStorage.getItem('tokenStudent')
      if (!tokenStudent) {
        navigate("/login");
      }
    }, []);
 
  useEffect(() => {
    const lastVisiteStudentRoute=localStorage.getItem("lastVisiteStudentRoute")
    if(lastVisiteStudentRoute){
      localStorage.removeItem("lastVisiteStudentRoute")
    }
    else{
      localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);

    }
  }, []);
 useEffect(()=>{
      const lastVisiteStudentTest=  localStorage.getItem("lastVisiteStudentTest")
    if(lastVisiteStudentTest!==window.location.pathname){
      localStorage.removeItem("startTime");
      localStorage.removeItem("expirationTime");
     
    }
  },[]);

  
  return (
    <>
      <div className="main-wrapper">
        <StudentHeader activeMenu={`/student/courses/${id}`} />

        <div className="breadcrumb-bar">
          <div className="container"
          style={{minWidth:"98%",margin:"auto"}}
          >
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        <Link to={`/student/courses/${id}`}>Courses</Link>                    
                      </li>
                      {/* <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        All Courses
                      </li> */}
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="course-content">
          <div className="container "
          style={{minWidth:"98%",margin:"auto"}}
          >
            <div className="row ">
              <div className="col-lg-12">
                {/* Filter */}
                {/* <div className="showing-list">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="d-flex align-items-center">
                        <div className="view-icons">
                          <Link to="/course-grid" className="grid-view active">
                      
                            <FeatherIcon icon="grid" />
                          </Link>
                          <Link to="/course-list" className="list-view">
                         
                            <FeatherIcon icon="list" />
                          </Link>
                        </div>
                        <div className="show-result">
                          <h4>Showing 1-9 of 50 results</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="show-filter add-course-info">
                        <form action="#">
                          <div className="row gx-2 align-items-center">
                            <div className="col-md-6 col-item">
                              <div className=" search-group">
                                <i className="feather-search">
                                  <FeatherIcon icon="search" />
                                </i>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search our courses"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-item">
                              <div className="form-group select-form mb-0">
                                <Select
                                  options={option}
                                  defaultValue={input}
                                  onChange={setInput}
                                  placeholder="Newly Published"
                                  styles={customStyles}
                                ></Select>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* /Filter */}

                <GridInnerPage id={id} />

               
              </div>
              <div className="col-lg-3 theiaStickySidebar ">
                <div className="stickysidebar ">
                
                   
           
                </div>
              </div>
            </div>
          </div>
        </section>

        <FooterStudent />
      </div>
    </>
  );
};

export default StudentCourse;
