import React, { useContext, useEffect, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { LoginImg, logo, NetIcon1, NetIcon2,ChahalLogo } from "../../imagepath";
import FeatherIcon from "feather-icons-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../context/AuthProvider";
import { useState } from "react";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { notification, Space } from "antd";
import axiosInstance from "../../../api/axios1";
import axiosTeacher from "../../../api/axiosTeacher";

const CHANGEPASSWORD = "/api/teacher/reset-password";

const initalValue = {
  password: "",
  new_password: "",
};
const loginSchema = Yup.object({
  password: Yup.string()
    .required("Please enter a password")
    .min(8, "Password must have at least  characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character, and must be at least 8 characters long"
    ),

  new_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password Must Match"),
});
const TeacherChangePassword = () => {
  const id = useParams();

  const lastVisiteStudentRoute = localStorage.getItem("lastVisiteStudentRoute");
  const Teacheruserid = localStorage.getItem("Teacheruserid");
  console.log(Teacheruserid,"Teacheruserid")

  const { auth, setAuth } = useContext(AuthContext);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");

  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [password, setpassword] = useState("");
  const [changepassword, setChangepas] = useState("");

  const [toast, setToast] = useState(false);
  const token = localStorage.getItem("tokenTeacher");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const openNotificationWithIcon1 = (type, text) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>{text} </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type, text) => {
    console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      duration: 2,
    });
  };
  const openNotificationWithIcon3 = (type, text) => {
    console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      duration: 2,
    });
  };
  const openNotificationWithIcon4 = (type, text) => {
    console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      duration: 2,
    });
  };
  useEffect(() => {
    const tokenTeacher =localStorage.getItem('tokenTeacher')
    if (!tokenTeacher) {
      navigate("/teacher/login");
    }
  }, []);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initalValue,
      onSubmit: async (val) => {
        if (val?.password !== val?.new_password) {
          openNotificationWithIcon2("error", "Password Mismatch");
        }

        // console.log(val);
        try {
          const response = await axiosTeacher.post(
            `${CHANGEPASSWORD}/${Teacheruserid}`,
            values,
            {
              config,
            }
          );
          openNotificationWithIcon1("success", "Password Changed");
          // console.log(response?.data);
          localStorage.removeItem("tokenTeacher")
          navigate("/teacher/login");
          localStorage.removeItem("userid");
        } catch (err) {
          console.log(err);
          if (err.response?.status == 403) {
            // console.log("krishna",err.response?.data?.message)
            openNotificationWithIcon4("error", err.response?.data?.message);
          }
        }
      },
      validationSchema: loginSchema,
    });
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };
  const hadlesubmitData = () => {};
  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className="main-wrapper log-wrap">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
            <OwlCarousel
              {...settings}
              className="owl-carousel login-slide owl-theme"
            >
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    Chahal Academy 
                  </h2>
                  {/* <p>
                    Stellarflux: IT & Business Solutions 1) Business Analysis &
                    Modeling 2) Certified Google SEO 3) Web Development 4) App
                    Development 5) Software Development 6) Marketing Solutions
                    7) HR Solutions 8) Finance Solutions
                  </p> */}
                </div>
              </div>
              {/* <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    Stellarflux .
                  </h2>
                  <p>
                    Stellarflux: IT & Business Solutions 1) Business Analysis &
                    Modeling 2) Certified Google SEO 3) Web Development 4) App
                    Development 5) Software Development 6) Marketing Solutions
                    7) HR Solutions 8) Finance Solutions
                  </p>
                </div>
              </div>
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    Stellarflux.
                  </h2>
                  <p>
                    Stellarflux: IT & Business Solutions 1) Business Analysis &
                    Modeling 2) Certified Google SEO 3) Web Development 4) App
                    Development 5) Software Development 6) Marketing Solutions
                    7) HR Solutions 8) Finance Solutions
                  </p>
                </div>
              </div> */}
            </OwlCarousel>
          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="w-100">
                  <div className="img-logo">
                    <img src={ChahalLogo} className="img-fluid" alt="Logo" />
                    {/* <div className="back-home">
                      <Link to="/">Back to Home</Link>
                    </div> */}
                  </div>
                  <h1>Change password</h1>
                  <form novalidate onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label className="form-control-label">
                        Change Password
                      </label>
                      <div className="pass-group">
                        <div className="password-field-wrapper">
                          <input
                            type={passwordType}
                            onChange={handleChange}
                            value={values.password}
                            required
                            autoComplete="off"
                            name="password"
                            id="password"
                            className="form-control password-field"
                            placeholder="New Password"
                          />

                          <span
                            className="toggle-password feather-eye"
                            onClick={togglePassword}
                          >
                            {passwordType === "password" ? (
                              <FeatherIcon icon="eye" />
                            ) : (
                              <FeatherIcon icon="eye-off" />
                            )}
                          </span>
                        </div>
                        {errors ? (
                          <div style={{ color: "red" }}>{errors.password}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">
                        {" "}
                        New Password
                      </label>
                      <div className="pass-group">
                        <div className="password-field-wrapper">
                          <input
                            type={passwordType1}
                            onChange={handleChange}
                            value={values.new_password}
                            required
                            autoComplete="off"
                            name="new_password"
                            id="new_password"
                            className="form-control password-field"
                            placeholder="New Password"
                          />

                          <span
                            className="toggle-password feather-eye"
                            onClick={togglePassword1}
                          >
                            {passwordType1 === "password" ? (
                              <FeatherIcon icon="eye" />
                            ) : (
                              <FeatherIcon icon="eye-off" />
                            )}
                          </span>
                        </div>

                        {errors.new_password && touched.new_password ? (
                          <span style={{ color: "red" }}>
                            {errors.new_password}
                          </span>
                        ) : null}
                        {error ? (
                          <span style={{ color: "red" }}>
                            {errors.password}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="d-grid">
                      <button className="btn btn-start">Change Password</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default TeacherChangePassword;
