import React, { useState } from "react";
import { LoginImg, logo ,ChahalLogo} from "../../imagepath";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
import axios from "../../../api/axios";
let yup = require("yup");
const SENDEMAIL = "/api/student/forgot_password";
import "./index.css";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import * as Yup from "yup";
import { useFormik } from "formik";
const initalValue = {
  otp: "",
  password: "",
  confirm_password: "",
};
const loginSchema = Yup.object({
  otp: Yup.string().required("Please enter a otp"),
  password: Yup.string()
    .required("Please enter a password")
    .min(8, "Password must have at least  characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character, and must be at least 8 characters long"
    ),

  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password Must Match"),
});

const ForgotPassword = () => {
  const [disabled, setDisabled] = useState(false);
  const [emails, setemail] = useState("");
  const [animation, setAnimation] = useState("open");
  const [display, setDisplay] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const navigate = useNavigate();
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };
  let schema = yup.object().shape({
    email: yup.string().email().required("enter the email"),
  });
  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: [
      '<i className="fas fa-arrow-left"></i>',
      '<i className="fas fa-arrow-right"></i>',
    ],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };
  const hide = async (ms) => {
    setAnimation("close");

    await new Promise((r) => setTimeout(r, ms));

    setDisplay(false);
  };
  const openNotificationWithIcon = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const sendEmail = async (e) => {
    e.preventDefault();
    schema
      .isValid({
        email: emails,
      })
      .then(async (valid) => {
        // valid; // => true
        if (valid == false) {
          openNotificationWithIcon("error", "Please enter vaild email", "red");
        } else {
          try {
            const response = await axios.post(
              SENDEMAIL,
              { email: emails },
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            );
            if (response.status == 200) {
              openNotificationWithIcon(
                "success",
                response.data.message,
                "#52c41a"
              );
              hide(1000);
            }
          } catch (error) {
            console.log(error);
            if (error.response.status == 403) {
              openNotificationWithIcon("error", error.response.data, "red");
            }
          }
        }
      });

    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 10000);
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initalValue,
      onSubmit: async (val) => {
        try {
          const response = await axios.put(`${SENDEMAIL}`, val, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          });
          openNotificationWithIcon("success", "Password Changed", "#52c41a");
          navigate("/login");
        } catch (err) {
          console.log(err);
          if (err.response?.status == 403) {
            openNotificationWithIcon(
              "error",
              err.response?.data?.errors,
              "red"
            );
          }
        }
      },
      validationSchema: loginSchema,
    });
  return (
    <>
      <div className="main-wrapper">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
            <OwlCarousel
              {...settings}
              className="owl-carousel login-slide owl-theme"
              data-aos="fade-up"
            >
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    DreamsLMS Courses.
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.
                  </p>
                </div>
              </div>
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    DreamsLMS Courses.
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.
                  </p>
                </div>
              </div>
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Welcome to <br />
                    DreamsLMS Courses.
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="img-logo">
                  <img src={ChahalLogo} className="img-fluid" alt="Logo" />
                  <div className="back-home">
                    <Link to="/">Back to Home</Link>
                  </div>
                </div>

                {display ? (
                  <div className="Modal open">
                    {" "}
                    <h1>Forgot Password ?</h1>
                    <div className="reset-password">
                      <p>Enter your email to reset your password.</p>
                    </div>
                    <form>
                      <div className="form-group">
                        <label className="form-control-label">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your email address"
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </div>
                      <div className="d-grid">
                        <button
                          className="btn btn-start"
                          onClick={(e) => {
                            sendEmail(e);
                          }}
                          disabled={disabled}
                          // type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className={`Modal ${animation}`}>
                    <h1>Change password</h1>
                    <form novalidate onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label className="form-control-label">Otp</label>
                        <div className="pass-group">
                          <div className="password-field-wrapper">
                            <input
                              type="text"
                              onChange={handleChange}
                              value={values.otp}
                              required
                              autoComplete="off"
                              name="otp"
                              id="password"
                              className="form-control password-field"
                              placeholder="otp"
                            />
                          </div>
                          {errors.otp && touched.otp ? (
                            <span style={{ color: "red" }}>{errors.otp}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-control-label">
                          New Password
                        </label>
                        <div className="pass-group">
                          <div className="password-field-wrapper">
                            <input
                              type={passwordType}
                              onChange={handleChange}
                              value={values.password}
                              required
                              autoComplete="off"
                              name="password"
                              id="password"
                              className="form-control password-field"
                              placeholder="New Password"
                            />

                            <span
                              className="toggle-password feather-eye"
                              onClick={togglePassword}
                            >
                              {passwordType === "password" ? (
                                <FeatherIcon icon="eye" />
                              ) : (
                                <FeatherIcon icon="eye-off" />
                              )}
                            </span>
                          </div>
                          {errors.password && touched.password ? (
                            <span style={{ color: "red" }}>
                              {errors.password}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-control-label">
                          {" "}
                          Confirm Password
                        </label>
                        <div className="pass-group">
                          <div className="password-field-wrapper">
                            <input
                              type={passwordType1}
                              onChange={handleChange}
                              value={values.confirm_password}
                              required
                              autoComplete="off"
                              name="confirm_password"
                              id="confirm_password"
                              className="form-control password-field"
                              placeholder="New Password"
                            />

                            <span
                              className="toggle-password feather-eye"
                              onClick={togglePassword1}
                            >
                              {passwordType1 === "password" ? (
                                <FeatherIcon icon="eye" />
                              ) : (
                                <FeatherIcon icon="eye-off" />
                              )}
                            </span>
                          </div>
                          {errors.confirm_password &&
                          touched.confirm_password ? (
                            <span style={{ color: "red" }}>
                              {errors.confirm_password}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="d-grid">
                        <button className="btn btn-start">
                          Change Password
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
