import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import CryptoJS from "crypto-js";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import * as Yup from "yup";
import Select from "react-select";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {   notification, Space  } from "antd";
import AdminHeader from "../../instructor/adminHeader";
const EDITTESTSERIES = "/api/recorded-video/edit-submodule";
const UPDATETESTSERIES = "/api/recorded-video/update-submodule";

export default function AdAdminSubEdit() {
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();
  const[submit,setSubmit]=useState(false)

  var bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchEdit = async () => {
    try {
      setLoading(true);
      const respone = await axios.get(
        `${EDITTESTSERIES}/${decryptedData}`,
        config
      );
      // console.log(respone);
      setData(respone.data.res);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
    }
  };
  useEffect(() => {
    fetchEdit();
  }, []);
  const navigate = useNavigate();
  const initalValue = {
    name: data?data?.name:"",
    status: data?.status,
  };
  const studentSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter your name"),
    status: Yup.string().required("Please enter your status"),
  });
  const [api, contextHolder] = notification.useNotification();
  
  const[toast,setToast]=useState(false)
  const openNotificationWithIcon = (type) => {
    // console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"#52c41a"}}>
         <strong>SubModule Updated</strong> 
        </div>
      ),
      duration:1
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      
    });
    };
    const openNotificationWithIcon2 = (text,type) => {
      console.log("toast after click")
      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"red"}}>
           <strong>{text}</strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        duration:1
      });
      };
      const openNotificationWithIconSesion = (type) => {
        notification[type]({
          message: (
            <div style={{ color: "red" }}>
              <strong>Session Expired Please Login </strong>
            </div>
          ),
          duration: 1,
        });
      };

  const { errors, values, touched, handleChange, handleSubmit, getFieldProps } =
    useFormik({
      initialValues: initalValue,
      onSubmit: async (value) => {
        setSubmit(true)
        try {
          const response = await axios.post(
            UPDATETESTSERIES,
            {
              recordedvideos_submodule_name: value.name,
              status: value.status,
              moduleid: 0,
              "recordedvideos-submodule-id": data?.id,
            },
            config
          );
          if (response) {
            openNotificationWithIcon("success")
            setTimeout(() => {
              navigate(-1);
            }, 800);

            // toast.info("SubModule Updated");
          }
        } catch (error) {
          console.log(error);
          setSubmit(false)

          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem('token')
            navigate('/admin/login')
          }
          if (error?.response?.status === 422) {
            openNotificationWithIcon2(error?.response?.data?.message,"error")
  
          }
          else if (error?.response?.status === 403) {
            localStorage.removeItem("token");
            openNotificationWithIcon2( error?.response?.data?.message,"error");
            setTimeout(() => {
              navigate("/admin/login");
            }, 1000); // Delay the navigation to show the notification
          }
          else{
            openNotificationWithIcon2(error?.response?.data?.message,"error")

          }
        }
        // console.log(value);
      },
      validationSchema: studentSchema,
      enableReinitialize: true,
    });
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  const [country, setCountry] = useState(null);

  const options = [
    { label: "Select Status", value: "Status" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];
  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };
  
  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container" 
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit SubModule</h3>
                    {/* <p>Edit Sub Module information.</p> */}
                    <nav aria-label="breadcrumb" style={{ marginTop: "0.5em" }}>
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li
                          className="breadcrumb-item "
                          aria-current="page"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate("/admin/recorded-videos-list")
                          }
                        >
                          {" "}
                          Recorded Videos
                        </li>
                        <li
                          className="breadcrumb-item"
                          aria-current="page"
                          style={{ cursor: "pointer", fontWeight: "normal" }}
                          onClick={() => navigate("/admin/recorded-videos")}
                        >
                          {" "}
                          Recorded Module & SubModule
                        </li>
                        <li
                          className="breadcrumb-item"
                          aria-current="page"
                          onClick={() => navigate(-1)}
                          style={{
                            marginLeft: "-1em",
                            cursor: "pointer",
                            fontWeight: "normal",
                          }}
                        >
                          SubModule
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                          style={{
                            cursor: "pointer",
                            marginLeft: "-1em",
                          }}
                          onClick={() => navigate("")}
                        >
                          Edit SubModule
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">
                              Enter Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Your Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Select Exam</label>

                            <Select
                              className=" select country-select"
                              name="sellist1"
                              options={options}
                              defaultValue={options[0]}
                              placeholder="Select "
                              onChange={setCountry}
                              styles={style}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Select Sub-Exam</label>

                            <Select
                              className=" select country-select"
                              name="sellist1"
                              options={options}
                              defaultValue={options[0]}
                              placeholder="Select "
                              onChange={setCountry}
                              styles={style}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Select Batch</label>

                            <Select
                              className=" select country-select"
                              name="sellist1"
                              options={options}
                              defaultValue={options[0]}
                              placeholder="Select "
                              onChange={setCountry}
                              styles={style}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Select Syllabus</label>

                            <Select
                              className=" select country-select"
                              name="sellist1"
                              options={options}
                              defaultValue={options[0]}
                              placeholder="Select "
                              onChange={setCountry}
                              styles={style}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Select Subject</label>

                            <Select
                              className=" select country-select"
                              name="sellist1"
                              options={options}
                              defaultValue={options[0]}
                              placeholder="Select "
                              onChange={setCountry}
                              styles={style}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Select Faculty</label>

                            <Select
                              className=" select country-select"
                              name="sellist1"
                              options={options}
                              defaultValue={options[0]}
                              placeholder="Select "
                              onChange={setCountry}
                              styles={style}
                            ></Select>
                          </div>
                        </div> */}
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              style={{ marginRight: "10px", marginTop: "5px" }}
                            >
                              Status :
                            </label>
                            <br />
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="inlineRadio1"
                                onChange={getFieldProps("status").onChange}
                                value={"0" || values?.status}
                                checked={values?.status === "0"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                InActive
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="inlineRadio2"
                                onChange={getFieldProps("status").onChange}
                                value={"1" || values?.status}
                                checked={values?.status === "1"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio2"
                              >
                                Active
                              </label>
                            </div>
                            {errors.status && touched.status ? (
                              <span style={{ color: "red" }}>
                                {errors.status}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="update-profile"
                         id="submit-button"

                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button type="submit" className="btn btn-primary"
                             disabled={submit}
                            >
                              Update
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button
                              type="button"
                              onClick={() => {
                                // windowTop(0,0);

                                navigate("/admin/recorded-videos");
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      <ToastContainer />
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
