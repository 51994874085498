import React, { useEffect, useRef, useState } from "react";
import "./ChatApp.css"; // Import your custom styles
import "bootstrap/dist/css/bootstrap.min.css";
import { Tooltip } from "antd";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import axios from "axios";
import axiosInstance from "../../../api/axios1";
import axiosTeacher from "../../../api/axiosTeacher";
import StudentHeader from "../studentHeader";
import FooterStudent from "../../fotterstudent";
import { Link } from "react-router-dom";
const GETMESSAGES = "/api/student/get/chat";
const SENDMESSAGE = "/api/teacher/send/chat";
const LASTSTUDENTS = "/api/student/teacher/list";
import { useInView } from "react-intersection-observer";
import { userprofile } from "../../imagepath";
import { notification, Space } from "antd";
import chahalpic from "../../../assets/img/chahal/chahalpic.jpg";
import chahalpicblur from "../../../assets/img/chahal/chahalblur.jpeg";
import chahalpng from "../../../assets/img/chahal/chahalpic copy.png"
import textterd from "../../../assets/img/chahal/Pic1.jpg"


const StudentChatApp = () => {
  const token = localStorage.getItem("tokenStudent");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentname, setStudentsName] = useState("");
  const [teacherid, setTeacherid] = useState("");
  const [id, setid] = useState([]);
  const chatContainerRef = useRef(null);
  const Userid = localStorage.getItem("userid");
  const [currentPage, setCurrentPage] = useState(1);
  const lastMessageRef = useRef(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
    root: chatContainerRef.current, // Use the chat container as the root
    rootMargin: "0px 0px 100% 0px", // Adjust the root margin based on your needs
  });
  const [isAtTop, setIsAtTop] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  // const [shouldScroll, setShouldScroll] = useState(true);
  const [scrollAtBottom, setScrollAtBottom] = useState(true);
  const [pagevalue, setPageValue] = useState(1);

  // Function to scroll to the bottom of the container
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  // useEffect(() => {
  //   if (shouldScroll) {
  //     scrollToBottom();
  //     setShouldScroll(false); // Reset shouldScroll after scrolling
  //   }
  // }, [messages, shouldScroll]);
  const handleStudentlist = async () => {
    try {
      // setLoading(true);
      const responce = await axiosInstance.get(LASTSTUDENTS, config);
      // console.log(responce?.data,"responce")

      setStudents(responce?.data);
      // setSelectedStudent(responce?.data[0])
      // setStudentsName(responce?.data[0]?.name)
      // setTeacherid(+responce?.data[0]?.id)   // take the id of the student
      // setid(responce?.data[0]?.id)
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      // openNotificationWithIcon2("error");
      // setLoading(false);
    }
  };
  const handleList = async () => {
    try {
      // setLoading(true);
      const responce = await axiosInstance.get(LASTSTUDENTS, config);
      // console.log(responce?.data,"responce")
      // setStudents(responce?.data)
      setSelectedStudent(responce?.data[0]);
      setStudentsName(responce?.data[0]?.name);
      setTeacherid(+responce?.data[0]?.id); // take the id of the student
      setid(responce?.data[0]?.id);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      // openNotificationWithIcon2("error");
      setLoading(false);
    }
  };
  useEffect(() => {
    handleStudentlist();
    // handleList();
  }, []);
  const handleSelectStudent = (student) => {
    // console.log(student,"in student function")
    setSelectedStudent(student);
    setStudentsName(student?.name);
    setid(student?.id);
    // setTeacherid(+student?.id)   // take the id of the student
    setScrollAtBottom(true);
  };
  // console.log(selectedStudent, "selected stdent");
  useEffect(() => {
    axiosInstance
      .get(`${GETMESSAGES}/${id ? id : ""}?page=${currentPage}`, config)
      .then((response) => {
        // console.log(response.data, "response in useEffect");
        // setMessages(response.data.data);
        const reversermessage = response?.data?.data.reverse();
        setMessages(reversermessage);
        setLastPage(response?.data ? response?.data?.last_page : "");
        setPageValue(response?.data?.current_page);
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });

    // Enable logging for Laravel Echo connection
    Pusher.logToConsole = true;

    const echo = new Echo({
      broadcaster: "pusher",
      key: "a54af538516d5957021d",
      cluster: "ap2",
      encrypted: true,
    });

    let isMounted = true;
    // console.log("Laravel Echo connected");

    echo.channel("user-send").listen("UserMessageSent", (event) => {
      // console.log(event, "event");
      try {
        if (event) {
          if (
            event?.data?.sender_id == Userid ||
            event?.data?.receiver_id == Userid
          ) {
            setMessages((prevMessages) => [...prevMessages, event.data]);
            // getstudentmessages(currentPage);
            handleStudentlist();
          }
        }
      } catch (error) {
        console.error("Error processing event:", error);
      }
    });

    return () => {
      isMounted = false;
      echo.disconnect();
    };
  }, [id, currentPage]);
  const getstudentmessages = async (page) => {
    try {
      // setLoading(true);
      const responce = await axiosInstance.get(
        `${GETMESSAGES}/${id ? id : ""}?page=${page}`,
        config
      );
      //  console.log(responce,"responce")
      const reversedMessages = responce?.data?.data?.reverse();
      setMessages((prevMessages) => [...reversedMessages, ...prevMessages]);
      setLastPage(responce?.data ? responce?.data?.last_page : "");
      setPageValue(responce?.data?.current_page);

      // setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      // openNotificationWithIcon2("error");
      setLoading(false);
    }
  };
  useEffect(() => {
    //  getstudentmessages(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (inView && currentPage > 1) {
      // console.log('Reached top of the scroll. Fetching the previous page.');
      getstudentmessages(currentPage - 1);
    }
  }, [inView, currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const scrollTop = chatContainerRef.current.scrollTop;
        const threshold = 50;

        setIsAtTop(scrollTop < threshold);
      }
    };

    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      const chatContainer = chatContainerRef.current;
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isAtTop && currentPage < lastPage) {
      // console.log('Reached the top of the scroll. Fetching the previous page.');
      getstudentmessages(currentPage + 1);
    }
  }, [isAtTop, currentPage, lastPage]);

  // console.log(messages,"messages outside",teacherid)

  const handleSendMessage = async () => {
    try {
      if (newMessage.trim() !== "" && selectedStudent) {
        // Send the message to the server
        const response = await axiosInstance.post(
          SENDMESSAGE,
          {
            receiver_id: id,
            message: newMessage,
          },
          config
        );

        // Assuming the server returns the sent message data, update the UI
        const sentMessage = response?.data;
        // console.log(sentMessage,"messages")
        if (sentMessage?.sent_time && sentMessage?.sent_date) {
          sentMessage.sent_time = formatSentTime(sentMessage.sent_time);
          setMessages((prevMessages) => [...prevMessages, sentMessage]);
        } else {
          console.error("Invalid date or time in sent message:", sentMessage);
        }
        // getstudentmessages(currentPage)
        // handleStudentlist();
        // Clear the message input
        setNewMessage("");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      openNotificationWithIcon2("error", error?.response?.data?.message);

      // Handle error if needed
    }
  };

  const openNotificationWithIcon2 = (type, text) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };

  function groupedMessages() {
    // Group messages by date
    const grouped = messages.reduce((acc, message) => {
      const date = message.sent_date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});

    // Convert the grouped object into an array, sorted by date
    const sortedGroups = Object.keys(grouped)
      .sort()
      .map((date) => ({
        date,
        messages: grouped[date],
      }));

    return sortedGroups;
  }

  // Function to format sent time
  function formatSentTime(sentTime) {
    const [hours, minutes] = sentTime ? sentTime?.split(":") : "";
    const dummyDate = new Date(2000, 0, 1, hours, minutes);

    if (isNaN(dummyDate.getTime())) {
      // console.error("Invalid time string:", sentTime);
      return "Invalid Time";
    }

    const formattedTime = dummyDate?.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    // console.log(formattedTime);
    return formattedTime;
  }

  // Function to format date
  function formatDate(sentDate) {
    const messageDate = new Date(sentDate);
    const today = new Date();

    if (isSameDay(messageDate, today)) {
      return "Today";
    } else if (isSameDay(messageDate, new Date(today.getTime() - 86400000))) {
      return "Yesterday";
    } else {
      return messageDate?.toLocaleDateString();
    }
  }

  // Function to check if two dates are the same day
  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
  useEffect(() => {
    if (scrollAtBottom) {
      scrollToBottom();
      setScrollAtBottom(false);
    }
  }, [messages]);
  useEffect(() => {
    // console.log(pagevalue,"current page in useEffect")
    if (pagevalue == 1) {
      scrollToBottom();
    }
  }, [messages]);
  // console.log(students,"students in the array")
  const containerStyle = {
    minHeight: selectedStudent ? "420px" : "500px",
    overflow: "auto",
    // backgroundImage: `url(${selectedStudent ? chahalpicblur : chahalpic})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    objectFit: "contain",
    backgroundRepeat: "no-repeat",
    background: "rgb(111, 202, 199)",
    background: "linear-gradient(90deg, rgba(111,202,199,1) 0%, rgba(255,255,255,1) 100%)"
  };
  
  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"chat"} />
      {/* <div className="breadcrumb-bar">
        <div className="container" style={{ minWidth: "98%", margin: "auto" }}>
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                 
                    <li className="breadcrumb-item active" aria-current="page">
                      <Link to="/student/chat"> Chat</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div
        className="page-content instructor-page-content"
        style={{ padding: "10px" ,
        // border:"3px solid #ddd6d6",
        marginTop:"-17px",
        borderRadius:"8px",}}
      >
        <div className="container-fluid"
        style={{padding:"10px"}}
        >
          <div className="row"
          style={{padding:"0px"}}
          >
            <div className="col-lg-3"
            style={{border:"3px solid #d5cdcd",
            minHeight:'500px',
            // backgroundColor:"#c6c6cd" ,
            background: "#f1f1f1",
            // background: "linear-gradient(342deg, rgba(2,110,107,1) 0%, rgba(255,255,255,1) 96%)",      
          
          
          }}
            >
              <div className="student-sidebar" 
              style={{ 
                padding: "15px",
            // background: "#c1dad9",
            // background: "linear-gradient(342deg, rgba(2,110,107,1) 0%, rgba(255,255,255,1) 96%)",      
            
            }}>
                <div style={{ padding: "5px",display:"flex" }}>
                <i className='far fa-comment' style={{fontSize:"22px",marginTop:"2px"}}></i>
                  <h5 className="ms-2">Messages</h5>
                </div>
                <div
                  className=""
                  style={{ maxHeight: "500px", overflow: "auto" }}
                >
                  {students?.map((student, index) => (
                    <div
                      key={index}
                      className="row"
                      onClick={() => handleSelectStudent(student)}
                      style={{
                       marginTop: "10px",
                        //   boxShadow: student?.students[0]?.id === id ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" : "",
                        margin: "auto",
                        padding: "8px",
                        borderRadius: "12px",
                        // border:"1px solid red",
                        //   backgroundColor:student?.students[0]?.id === id ? "white" : ""
                        boxShadow:
                          student?.id === id
                            ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                            : "",
                        backgroundColor: student?.id === id ? "white" : "",
                        // border:"1px solid red"
                      }}
                    >
                      <div className="student-image col-3">
                        <div
                          style={{
                            // display: "flex",
                            alignSelf: "center",
                            justifyContent: "center",
                            marginTop: "5px",
                            width: "60px",
                            height: "60px",
                          }}
                        >
                          <img
                            style={{
                              borderRadius: "50%",
                              width: "78%",
                              height: "78%",
                              objectFit: "cover",
                            }}
                            src={
                              student?.profile ? student?.profile : userprofile
                            }
                            alt={student?.name}
                          />
                        </div>
                      </div>
                      <div
                        className="col-9"
                        style={{ alignSelf: "center", justifyContent: "" }}
                      >
                        <div className="row">
                          <div className="col-7"
                          >
                            <h4 style={{ fontSize: "14px", fontWeight: "600" }}>
                              <Tooltip title={student ? student?.name : ""}>
                                {`${student?.name.slice(0, 10)} ${
                                  student?.name.length > 10 ? "..." : ""
                                }`}
                              </Tooltip>
                            </h4>

                            <h6 style={{ fontSize: "12px" }}>
                              <Tooltip title={student?.chat?.message}>
                                {student?.chat?.message
                                  ? `${student?.chat?.message.slice(0, 10)} ${
                                      student?.chat?.message.length > 10
                                        ? "..."
                                        : ""
                                    }`
                                  : ""}
                              </Tooltip>
                            </h6>
                          </div>
                          <div
                            className="col-5"
                            style={{
                              alignSelf: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <h6
                              style={{ alignSelf: "center", fontSize: "12px" }}
                            >
                              {student?.chat?.sent_time
                                ? formatSentTime(student?.chat?.sent_time)
                                : ""}
                            </h6>
                          </div>
                        </div>
                        {/* {student.isActive && <span className="active-symbol">&#8226;</span>} */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-lg-9 texterd"
            style={{backgroundColor:"white",
           borderRight:"3px solid #ddd6d6",
           borderTop:"3px solid #ddd6d6",
           borderBottom:"3px solid #ddd6d6",

          //  background: "rgb(111,202,199)",
          //  background: "linear-gradient(90deg, rgba(111,202,199,1) 0%, rgba(255,255,255,1) 100%)"// background: linear-gradient(90deg, rgba(111,202,199,1) 0%, rgba(255,255,255,1) 100%).
          
          //  borderTopLeftRadius:"10px",


      }}
            >
              <div className="row ">
                <div className="col-lg-12"
                style={{position:"relative",
              
                background: "#f1f1f1",
                // background: "linear-gradient(90deg, rgba(111,202,199,1) 0%, rgba(255,255,255,1) 100%)"// background: linear-gradient(90deg, rgba(111,202,199,1) 0%, rgba(255,255,255,1) 100%).
     
              }}
                // style={{ borderBottom: '1px solid transparent', backgroundImage: 'linear-gradient(to right, #000 50%, transparent 50%)', backgroundSize: '10px 1px', backgroundRepeat: 'repeat-x', paddingBottom: '10px' }}
                >
               <div className="d-flex">
               <div
                          style={{
                             display: "flex",
                            alignSelf: "center",
                            justifyContent: "center",
                            //  marginTop: "10px",
                             width: "70px",
                             height: "70px",
                            marginLeft:"10px"

                          }}
                          
                        >
                          <img
                            style={{
                              borderRadius: "50%",
                              width: "75%",
                              height: "75%",
                              objectFit: "cover",
                              alignSelf:"cenetr",
                              marginTop:"10px"
                              
                            }}
                            src={
                              selectedStudent?.profile ? selectedStudent?.profile : userprofile
                            }
                            alt={selectedStudent?.name}
                          />
                         
                        </div>
                        <div className="align-self-center"
                        style={{marginTop:"5px"}}
                        >
                        <h4 style={{ color: "green" }}>
                  {selectedStudent
                    ? `${studentname}`
                    : "Select a Teacher"}
                </h4>
                        </div>
                        <div style={{ position: 'absolute', bottom: '-1px', left: 0, width: '100%',
                         borderBottom: '3px dotted rgb(197 192 192)' }}></div>
               </div>
                         
                        
                </div>
               <div
                style={{  
                  // minHeight: selectedStudent?"420px":"500px",
                  // minHeight:"500px",
                overflow: "auto",
                 backgroundImage: selectedStudent?`url(${textterd})`
                 :`url(${chahalpic})`,
                //  backgroundSize: "cover", // Optional: Adjust to your needs
                backgroundPosition: "center", // Optional: Adjust to your needs
                objectFit:"contain",
               backgroundRepeat: selectedStudent?"":"no-repeat",
            //  background: selectedStudent?"":"linear-gradient(90deg, rgba(111,202,199,1) 0%, rgba(255,255,255,1) 100%)"// background: linear-gradient(90deg, rgba(111,202,199,1) 0%, rgba(255,255,255,1) 100%).

                // backgroundColor:"white",
     
                // marginTop:"5px"

                // opacity: selectedStudent ? "1" : "1", // Adjust opacity conditionally
                // opacity: selectedStudent ? "0.5" : "1", // Adjust opacity conditionally
              }}
               >
                <div
                  className="message-container col-12"
                  ref={chatContainerRef}
                  style={{  
                    minHeight: selectedStudent?"420px":"500px",
                    // minHeight:"500px",
                  overflow: "auto",
                   backgroundImage: selectedStudent?`url(${textterd})`
                   :`url(${chahalpic})`,
                  //  backgroundSize: "cover", // Optional: Adjust to your needs
                  backgroundPosition: "center", // Optional: Adjust to your needs
                  objectFit:"contain",
                  backgroundRepeat: selectedStudent?"":"no-repeat",
                  // backgroundColor:"white",

       
                  // marginTop:"5px"

                  // opacity: selectedStudent ? "1" : "1", // Adjust opacity conditionally
                  // opacity: selectedStudent ? "0.5" : "1", // Adjust opacity conditionally
                }}
                // style={containerStyle}
                >
                  {groupedMessages()?.map((group, groupIndex) => (
                    <>
                      {selectedStudent ? (
                        <div key={groupIndex}>
                          <div
                            className="date-header"
                            style={{
                            
                              textAlign: "center",
                              marginTop: "5px",
                              fontSize: "14px",
                            }}
                          >
                            <span style={{ backgroundColor: "white",
                             padding: "5px 10px",
                             borderRadius:"5px", 
                             color: "black",
                            //  marginTop:"15px",

                             }}>
    {formatDate(group.date)}
  </span>
                          </div>
                          {group?.messages
                            // ?.slice()  // Create a shallow copy of the array
                            // .reverse() // Reverse the order of the copied array
                            ?.map((message, index) => (
                              <div
                                key={index}
                                className={
                                  message?.sender_id == Userid
                                    ? "user-message-student"
                                    : "student-message-student"
                                }
                                ref={
                                  index === group.messages.length - 1
                                    ? lastMessageRef
                                    : null
                                }
                              >
                                {message?.sender_id == Userid ? (
                                  <>
                                    <div className="receiver-message-student">
                                      {/* Receiver's message */}
                                      {message?.message}
                                    </div>
                                    <div
                                      style={{
                                        alignSelf: "center",
                                        marginLeft: "5px",
                                        color: "black",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {formatSentTime(message?.sent_time)}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="sender-message-student">
                                      {/* Sender's message */}
                                      {message?.message}
                                    </div>
                                    <div
                                      style={{
                                        alignSelf: "center",
                                        marginLeft: "5px",
                                        fontSize: "12px",
                                        color: "black",
                                      }}
                                    >
                                      {formatSentTime(message?.sent_time)}
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </div>
           

                {selectedStudent && (
                  <div className="message-input"
                  style={{paddingBottom:"5px"}}
                  >
                    <textarea
                      placeholder="Type your message..."
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={handleSendMessage}
                    >
                      Send
                    </button>
                  </div>
                )}
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FooterStudent /> */}
    </div>
  );
};

export default StudentChatApp;
