import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../footer";
import StudentHeader from "../studentHeader";
import CryptoJS from "crypto-js";
import axios from "../../../api/axios";
import Result from "../../../assets/img/TestResult/result.jpg";
import Wave from "../../../assets/img/TestResult/wavy.png";
const SUBMIT = "/api/student/submit-result";
import "./index.css";

export default function studentTestResult() {
  const [subTotalMarks, setSubTotalMarks] = useState("");
  const [subtotalWrongQuestion, setSubtotalWrongQuestion] = useState("");
  const [totalCorrectQuestion, setTotalCorrectQuestion] = useState("");
  const [totalNumberQuestion, setTotalNumberQuestion] = useState("");
  const [notAttemptedQuestion, setNotAttemptedQuestion] = useState("");
  const tokenStudent = localStorage.getItem("tokenStudent");
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };

  const { subid } = useParams();
  // console.log(subid,"subid")
  const location = useLocation();
  // console.log(location,"location")

  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(subid),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // console.log(subid);
  const apiSubmit = async () => {
    try {
      setNotAttemptedQuestion(location.state.notAttemptedQuestion);
      setSubTotalMarks(location.state.subTotalMarks);
      setSubtotalWrongQuestion(location.state.subtotalWrongQuestion);
      setTotalCorrectQuestion(location.state.totalCorrectQuestion);
      setTotalNumberQuestion(location.state.totalNumberQuestion);
      history.pushState(null, document.title, location.href);
      window.addEventListener("popstate", function (event) {
        history.pushState(null, document.title, location.href);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    apiSubmit();
  }, []);
  useEffect(() => {
    const lastVisiteStudentTest = localStorage.getItem("lastVisiteStudentTest");
    if (lastVisiteStudentTest !== window.location.pathname) {
      localStorage.removeItem("startTime");
      localStorage.removeItem("expirationTime");
    }
  }, []);
  return (
    <>
      <div className="main-wrapper">
        <StudentHeader activeMenu={"Grid2"} />
        {/* BreadCrumb */}
        <div className="breadcrumb-bar p-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/student/test-series">Test Series</Link>
                      </li>
                      <li className="breadcrumb-item">
                        {" "}
                        <Link>Test Result</Link>
                      </li>
                      {/* <li className="breadcrumb-item">Students Videos</li> */}
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BreadCrumb */}
        {/* Page Wrapper */}
        <div className="page-content" style={{ padding: "0px" }}>
          <div className="container" style={{ width: "70%" }}>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card">
                  <div>
                    <img
                      style={{ objectFit: "contain", width: "100%" }}
                      src={Wave}
                    />
                  </div>
                  <div
                    className="card-header text-center"
                    style={{ padding: "0px" }}
                  >
                    <h2 style={{ color: "green" }}>Test Result</h2>
                  </div>
                  <div
                    className="card-body row p-0"
                    style={{ justifyContent: "space-evenly" }}
                    // style={{
                    //   backgroundImage: `url(${Result})`,
                    //   backgroundSize: 'contain',
                    //   backgroundRepeat: 'no-repeat',
                    //   backgroundPosition: 'center',
                    //   objectFit:"cover"
                    // }}
                  >
                    <div className="col-lg-6" id="resultbox" style={{}}>
                      <div className="row">
                        <div
                          className="col-lg-12"
                          style={{ textAlign: "center" }}
                        >
                          <h6 style={{ fontWeight: "700" }}>No of Questions</h6>
                        </div>
                        <div className="col-lg-12">
                          <h4
                            style={{
                              fontWeight: "700",
                              textAlign: "center",
                              padding: "5px",
                              marginTop: "15px",
                            }}
                          >
                            {" "}
                            {totalNumberQuestion}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6" id="resultbox">
                      <div className="row">
                        <div
                          className="col-lg-12"
                          style={{ textAlign: "center" }}
                        >
                          <h6 style={{ fontWeight: "700" }}>No of Correct</h6>
                        </div>
                        <div className="col-lg-12">
                          <h4
                            style={{
                              fontWeight: "700",
                              textAlign: "center",
                              padding: "5px",
                              color: "green",
                              marginTop: "15px",
                            }}
                          >
                            {" "}
                            {totalCorrectQuestion}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6" id="resultbox">
                      <div className="row">
                        <div
                          className="col-lg-12"
                          style={{ textAlign: "center" }}
                        >
                          <h6 style={{ fontWeight: "700" }}>Wrong Questions</h6>
                        </div>
                        <div className="col-lg-12">
                          <h4
                            style={{
                              fontWeight: "700",
                              textAlign: "center",
                              padding: "5px",
                              fontSize: "22px",
                              color: "red",
                              fontSize: "28px",
                            }}
                          >
                            {" "}
                            {subtotalWrongQuestion}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6" id="resultbox">
                      <div className="row">
                        <div
                          className="col-lg-12"
                          style={{ textAlign: "center" }}
                        >
                          <h6 style={{ fontWeight: "700" }}>Not Attempted </h6>
                        </div>
                        <div className="col-lg-12">
                          <h4
                            style={{
                              fontWeight: "700",
                              textAlign: "center",
                              padding: "5px",
                              color: "#ffbc34",
                              fontSize: "28px",
                            }}
                          >
                            {" "}
                            {notAttemptedQuestion}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-body row"
                    style={{
                      justifyContent: "space-evenly",
                      padding: "0px  0px 15px 0px",
                    }}
                    // style={{
                    //   backgroundImage: `url(${Result})`,
                    //   backgroundSize: 'contain',
                    //   backgroundRepeat: 'no-repeat',
                    //   backgroundPosition: 'center',
                    //   objectFit:"cover"
                    // }}
                  >
                    <div className="col-lg-6" id="resultbox">
                      <div className="row">
                        <div
                          className="col-lg-12"
                          style={{ textAlign: "center" }}
                        >
                          <h6 style={{ fontWeight: "700" }}>Total Marks</h6>
                        </div>
                        <div className="col-lg-12">
                          <h4
                            style={{
                              fontWeight: "700",
                              textAlign: "center",
                              padding: "5px",
                              fontSize: "28px",
                              color: "blue",
                            }}
                          >
                            {/* {Number.isInteger(subTotalMarks) === true && Number(subTotalMarks > 0) ? subTotalMarks : 0} */}
                            {subTotalMarks > 0
                              ? subTotalMarks
                              : "0"}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Page Wrapper */}
        {/* <Footer /> */}
      </div>
    </>
  );
}
