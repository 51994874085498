import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import { Link, useNavigate, useParams } from "react-router-dom";
import StudentSideBar from "../sidebar";
import Select from "react-select";
import AdminInstructorSidebar from "../adminSidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextEditor from "../../pages/course/addCourse/textEditor";
import { useFormik } from "formik";
import axios from "../../../api/axios";
import * as Yup from "yup";
import AuthContext from "../../../context/AuthProvider";
import AdminHeader from "../adminHeader";
import { notification, Space } from "antd";

const CREATEPAYMENT = "/api/Payment-mode/create";
const initalValue = {
  name: "",
  status: "",

};
const courseSchema = Yup.object({
  name: Yup.string().min(2).required("Please enter your Batch Name"),
  status: Yup.string().required("Enter your  Status"),
});

export default function AdminAddPayment() {
  const navigate = useNavigate();

  const [submit, setSubmit] = useState(false)

  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false)
  const openNotificationWithIcon = (type, text, col) => {
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: col }}>
                <strong>{text}</strong>
              </div>
            ),
          duration: 1
        });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    onSubmit: async (values, { setFieldError }) => {
      setSubmit(true)
      try {
        // console.log(value);
        const formData = new FormData();

        formData.append("name", values.name);

        formData.append("status", values.status);
        const response = await axios.post(CREATEPAYMENT, formData, config);
        // console.log(response);
        if (response) {
          setToast(true)
          openNotificationWithIcon("success", 'Payment Method Created', "#52c41a")
          setTimeout(() => {
            navigate("/admin/Payment-methods");
          }, 1000);
          // toast.success("Batch Created");
        }
      } catch (error) {
        console.log(error);
        setSubmit(false)
        setToast(false)
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem('token')
          navigate('/admin/login')
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon("error", error.response.data.message, "red")
        }
        else {
          openNotificationWithIcon("error", 'Internal Server Error', "red")
        }
      

      }
    },
    validationSchema: courseSchema,
  });


  const handleRadio = (e) => {
    if (e === "option2") {
      setFieldValue("amount", 0);
    }
    setRadio(e);
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute")
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute")
    }
    else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
          style={{ marginTop: "-15px", minWidth: "100%" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"invoice"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-9 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Payment Methods</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li className="breadcrumb-item" role="button">
                          <a onClick={() => navigate('/admin/Payment-methods')}>Payment Methods</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Add Payment Methods
                        </li> 
                      </ol>
                    </nav>
                  </div>
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">
                              Payment Methods
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your  Payment Methods"
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>


                        <div className="col-lg-6" >
                          <div className="form-group">
                            <label>Status :</label>
                            <br />
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="inlineRadios1"
                                onChange={getFieldProps("status").onChange}
                                value="0"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadios1"
                              >
                                Inactive
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="inlineRadios2"
                                onChange={getFieldProps("status").onChange}
                                value="1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadios2"
                              >
                                Active
                              </label>

                            </div>
                            <div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="update-profile"
                          id="submit-button"

                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >


                            <button type="submit" className="btn btn-primary"
                              disabled={submit}

                            >
                              Submit
                            </button>



                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      {/* <ToastContainer /> */}
      <Footer />
    </div>
  );
}
