import React, { useState,useEffect, useContext } from "react";
import CourseHeader from "../header";
import GridInnerPage from "./gridInnerPage";
import FeatherIcon from "feather-icons-react";
import { Blog1, Blog2, Blog3 } from "../../../imagepath";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../footer";
import StudentHeader from "../../../student/studentHeader";
import FooterStudent from "../../../fotterstudent";
import DemoHeader from "../demoHeader";
import AuthContext from "../../../../context/AuthProvider";

const StudentLogiCourses = () => {
  const {id}=useParams()
 

  const { courses } = useContext(AuthContext);
  // console.log(courses,"courses")
  const courseName=courses?.find((item)=>{
    return(item?.id==id)
  })
  console.log(courseName,"courseName")

  return (
    
    <>
    <div className="main-wrapper">
      <DemoHeader />
      <div className="breadcrumb-bar">
          <div className="container"
          style={{minWidth:"98%",margin:"auto"}}
          >
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to="/">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        <Link
                          to={`/student/courses/${id}`}
                         >{courseName?.name}</Link>                    
                      </li>
                      {/* <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        All Courses
                      </li> */}
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="course-content">
          <div className="container "
          style={{minWidth:"98%",margin:"auto"}}
          >
            <div className="row ">
              <div className="col-lg-12">
             
                <GridInnerPage id={id} />

                {/* /pagination */}
                {/* <div className="row">
                  <div className="col-md-12">
                    <ul className="pagination lms-page">
                      <li className="page-item prev">
                        <Link className="page-link" to="#" tabIndex={-1}>
                          <i className="fas fa-angle-left" />
                        </Link>
                      </li>
                      <li className="page-item first-page active">
                        <Link className="page-link" to="#">
                          1
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          2
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          3
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          4
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          5
                        </Link>
                      </li>
                      <li className="page-item next">
                        <Link className="page-link" to="#">
                          <i className="fas fa-angle-right" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* /pagination */}
              </div>
             
            </div>
          </div>
        </section>
     
    

      <FooterStudent />
    </div>
  </>
    
  );
};

export default StudentLogiCourses;
