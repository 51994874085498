import React, { useEffect, useState } from "react";
import Footer from "../../footer";
import "./index.css";
import Select from "react-select";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import AdminHeader from "../../instructor/adminHeader";
import { Tabs } from "antd";
import axios from "../../../api/axios";
import Moduleform from "./Moduleform";
import Submoduleform from "./Submoduleform";

export default function AdminRestrictedVideo() {
  const onChange = (key) => {
    // console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Module",
      children: (
       <Moduleform/>
      ),
    },
    {
      key: "2",
      label: "Sub Module",
      children: (
        <Submoduleform/>
      ),
    },
  ];
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };
  
  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Profile Privacy */}
            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="checkout-form personal-address add-course-info">
                    <div className="personal-info-head">
                      <h4>Add Restricted Time</h4>
                    </div>
                    <Tabs
                      defaultActiveKey="1"
                      items={items}
                      onChange={onChange}
                      // indicatorSize={(origin) => origin - 16}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Privacy */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
