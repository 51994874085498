// import React, { useState } from "react";
// import StudentHeader from "../header";

// import Footer from "../footer";
// import { User11 } from "../imagepath";
// import { Link } from "react-router-dom";

// import StudentSideBar from "../student/sidebar";
// import Select from "react-select";

// import AdminInstructorSidebar from "../instructor/adminSidebar";
// import TextEditor from "../pages/course/addCourse/editor";

// export default function AddAdminFeed() {
//   const [country, setCountry] = useState(null);
//   const [radio, setRadio] = useState(null);
//   const options = [
//     { label: "Select Status", value: "Status" },
//     { label: "Draft", value: "Draft" },
//     { label: "Published", value: "Published" },
//   ];
//   const style = {
//     control: (baseStyles, state) => ({
//       ...baseStyles,
//       backgroundColor: "white",
//       width: "100%",
//       height: "40px",
//       color: "black",
//       minHeight: "40px",
//       border: "1px solid #e9ecef",
//       paddingLeft: "5px",
//       // This line disable the blue border
//       boxShadow: state.isFocused ? 0 : 0,
//       borderRadius: state.isSelected ? "0" : "10px",
//       fontSize: "14px",
//       "&:hover": {
//         cursor: "pointer",
//       },
//       outline: "none",
//     }),
//     menu: (base) => ({ ...base, marginTop: "0px" }),
//     menuList: (base) => ({ ...base, padding: "0" }),
//     option: (provided, state) => ({
//       ...provided,
//       backgroundColor: state.isSelected ? "#FFDEDA" : "white",
//       color: "black",
//       fontSize: "14px",
//       "&:hover": {
//         backgroundColor: "#FFDEDA",
//         // #dddddd
//       },
//     }),
//     indicatorSeparator: (base) => ({
//       ...base,
//       display: "none",
//     }),
//     dropdownIndicator: (base, state) => ({
//       ...base,
//       color: "black",
//       transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
//       transition: "250ms",
//     }),
//   };
//   return (
//     <div className="main-wrapper">
//       {/* <StudentHeader activeMenu={"Profile"} /> */}
//       {/* Student Dashboard */}
//       <div className="page-content">
//         <div className="container">
//           <div className="row">
//             {/* Sidebar */}
//             <AdminInstructorSidebar activeMenu={"Courses1"} />
//             {/* Sidebar */}

//             {/* Profile Details */}
//             <div className="col-xl-9 col-md-8">
//               <div className="settings-widget profile-details">
//                 <div className="settings-menu p-0">
//                   <div className="profile-heading">
//                     <h3>Profile Details123</h3>
//                     <p>
//                       You have full control to manage your own account setting.
//                     </p>
//                   </div>
//                   {/* <div className="course-group mb-0 d-flex">
//                     <div className="course-group-img d-flex align-items-center">
//                       <Link to="student-profile">
//                         <img src={User11} alt="" className="img-fluid" />
//                       </Link>
//                       <div className="course-name">
//                         <h4>
//                           <Link to="student-profile">Your avatar</Link>
//                         </h4>
//                         <p>PNG or JPG no bigger than 800px wide and tall.</p>
//                       </div>
//                     </div>
//                     <div className="profile-share d-flex align-items-center justify-content-center">
//                       <Link to="#;" className="btn btn-success">
//                         Update
//                       </Link>
//                       <Link to="#;" className="btn btn-danger">
//                         Delete
//                       </Link>
//                     </div>
//                   </div> */}
//                   <div className="checkout-form personal-address add-course-info ">
//                     <div className="personal-info-head">
//                       <h4>Add Courses</h4>
//                       <p>Add Courses information.</p>
//                     </div>
//                     <form action="#">
//                       <div className="row">
//                         <div className="col-lg-6">
//                           <div className="form-group">
//                             <label className="form-control-label">
//                               Course Name
//                             </label>
//                             <input
//                               type="text"
//                               className="form-control"
//                               placeholder="Enter your Name"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-lg-6">
//                           <div className="form-group">
//                             <label className="form-control-label">
//                               Course Duration
//                             </label>
//                             <input
//                               type="text"
//                               className="form-control"
//                               placeholder="Enter your Email Address"
//                             />
//                           </div>
//                         </div>
//                         {radio === "option1" && (
//                           <div className="col-lg-6">
//                             <div className="form-group">
//                               <label className="form-control-label">
//                                 Amount
//                               </label>
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 placeholder="Enter your Amount"
//                               />
//                             </div>
//                           </div>
//                         )}

//                         <div className="col-lg-6">
//                           <div
//                             class="form-check form-check-inline"
//                             style={{ marginTop: "30px" }}
//                           >
//                             <input
//                               class="form-check-input"
//                               type="radio"
//                               name="inlineRadioOptions"
//                               id="inlineRadio1"
//                               value="option1"
//                               onClick={(e) => {
//                                 setRadio(e.target.value);
//                               }}
//                             />
//                             <label class="form-check-label" for="inlineRadio1">
//                               Paid Course
//                             </label>
//                           </div>
//                           <div class="form-check form-check-inline">
//                             <input
//                               class="form-check-input"
//                               type="radio"
//                               name="inlineRadioOptions"
//                               id="inlineRadio2"
//                               value="option2"
//                               onClick={(e) => {
//                                 setRadio(e.target.value);
//                               }}
//                             />
//                             <label class="form-check-label" for="inlineRadio2">
//                               Free
//                             </label>
//                           </div>
//                           {/* <div class="form-check form-check-inline">
//                             <input
//                               class="form-check-input"
//                               type="radio"
//                               name="inlineRadioOptions"
//                               id="inlineRadio3"
//                               value="option3"
//                             />
//                             <label class="form-check-label" for="inlineRadio3">
//                               Other
//                             </label>
//                           </div> */}
//                         </div>
//                         <div className="col-lg-6">
//                           <div className="form-group">
//                             <label className="form-control-label">
//                               Cover image
//                             </label>
//                             <input
//                               type="file"
//                               className="form-control"
//                               placeholder="Cover Image"
//                             />
//                           </div>
//                         </div>
//                         <div className="form-group mb-0">
//                           <label className="add-course-label">
//                             Course Description
//                           </label>
//                           <div id="editor">
//                             <TextEditor />
//                           </div>
//                         </div>
//                         <div className="col-lg-6">
//                           <div className="form-group">
//                             <label
//                               className="form-control-label"
//                               style={{ marginRight: "10px", marginTop: "30px" }}
//                             >
//                               Status :
//                             </label>
//                             <div class="form-check form-check-inline">
//                               <input
//                                 class="form-check-input"
//                                 type="radio"
//                                 name="inlineRadioOptions"
//                                 id="inlineRadio1"
//                                 value="option1"
//                               />
//                               <label
//                                 class="form-check-label"
//                                 for="inlineRadio1"
//                               >
//                                 Draft
//                               </label>
//                             </div>
//                             <div class="form-check form-check-inline">
//                               <input
//                                 class="form-check-input"
//                                 type="radio"
//                                 name="inlineRadioOptions"
//                                 id="inlineRadio2"
//                                 value="option2"
//                               />
//                               <label
//                                 class="form-check-label"
//                                 for="inlineRadio2"
//                               >
//                                 Published
//                               </label>
//                             </div>
//                           </div>
//                         </div>

//                         <div className="update-profile">
//                           <button type="button" className="btn btn-primary">
//                             Add Course
//                           </button>
//                         </div>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* Profile Details */}
//           </div>
//         </div>
//       </div>
//       {/* Student Dashboard */}
//       <Footer />
//     </div>
//   );
// }

import React, { useState } from "react";
import { InstructorHeader } from "../instructor/header";

import Footer from "../footer";

import {
  InstructorProfileBg,
  ReferImage1,
  ReferImage2,
  ReferImage3,
  ReferImage4,
  ReferImage5,
  User15,
} from ".././imagepath";
import { Link, useNavigate } from "react-router-dom";

import AdminInstructorSidebar from "../instructor/adminSidebar";
import FeatherIcon from "feather-icons-react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";
import { AudioOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";

export default function AddAdminFeed() {
  const { Search } = Input;
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const handlePayment = () => {
    navigate("/admin/show-course-payment");
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate("/admin/editfeed");
  };

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );

  const onSearch = (value) => console.log(value);

  return (
    <div className="main-wrapper">
      {/* <InstructorHeader /> */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Courses1"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div
                          style={{ height: "80px" }}
                          className="sell-course-head student-meet-head comman-space border-0"
                        >
                          <div className="tak-head d-flex align-items-center">
                            <div>
                              <h3>Feed</h3>
                            </div>
                          </div>
                          <form>
                            <div className="student-search-form d-flex ">
                              {/* <div className="form-group mb-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Twitter Profile Name"
                                />
                              </div> */}

                              {/* <div className="profile-share d-flex align-items-center justify-content-center">
                                <Link to="#;" className="btn btn-success">
                                  Export Excel
                                </Link>
                                <Link to="#;" className="btn btn-danger">
                                  Import Excel
                                </Link>
                              </div> */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="comman-space pb-0 p-0">
                          <div className="settings-referral-blk table-responsive">
                            {/* Instructor Users */}
                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Delete Course</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Are You Sure Want Delete This Course?
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal>

                            {/* <div className="form-group mb-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Twitter Profile Name"
                              />
                            </div> */}

                            <form>
                              <div
                                className="student-search-form d-flex m-0"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  padding: "13px",
                                }}
                              >
                                {/* <div className="form-group mb-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Twitter Profile Name"
                              />
                            </div> */}
                                <div className="export-cvv-btn">
                                  <h4>Feed List</h4>
                                  {/* Export CSV */}
                                </div>
                                <div className="profile-share d-flex align-items-center justify-content-center">
                                  <Search
                                    placeholder="input search text"
                                    allowClear
                                    enterButton="Search"
                                    size="large"
                                    onSearch={onSearch}
                                  />
                                  <Link
                                    to="/admin/addfeed"
                                    className="btn btn-primary"
                                  >
                                    Add Student
                                  </Link>
                                </div>
                              </div>
                            </form>

                            <table className="table table-nowrap">
                              <thead>
                                <tr>
                                  <th>SR. NO.</th>
                                  <th>TITLE</th>
                                  <th>SUBTITLE</th>
                                  <th>DESCRIPTION</th>
                                  <th>STATUS</th>
                                  <th>ACTIONS</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>
                                    <Link
                                      to="/admin/courses/demo"
                                      className="refer-avatar-blk d-flex align-items-center"
                                    >
                                      <p>UPSC CSE Demo Course</p>
                                    </Link>
                                  </td>
                                  <td>30 Days</td>
                                  <td>0</td>
                                  <td>
                                    <h5 style={{ color: "green" }}>Active</h5>
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => handleEdit()}
                                      type="button"
                                      class="btn btn-secondary"
                                    >
                                      Edit
                                    </button>{" "}
                                    <Button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={handleShow}
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>
                                    <Link
                                      to="#"
                                      className="refer-avatar-blk d-flex align-items-center"
                                    >
                                      <p>User Smiley</p>
                                    </Link>
                                  </td>
                                  <td>20 Days</td>
                                  <td>2$</td>
                                  <td>
                                    <h5 style={{ color: "green" }}>Active</h5>
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      class="btn btn-secondary"
                                    >
                                      Edit
                                    </button>{" "}
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/* Instructor List */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
