import React, { useEffect, useState } from "react";
import StudentHeader from "../../student/studentHeader";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

 import "./graphsAnsMulti.css";
import { Checkbox, ConfigProvider, Tabs, Tooltip } from "antd";
// import Result from "./Result";
import { useParams } from "react-router";
import axios from "../../../api/axios";
import { LogOut, Moon, Star, User, Delete, Download } from "react-feather";
import { notification, Modal } from "antd";
const RESULTPDF = "/api/question/list";
import scorecard from "../../../assets/img/Dashboard/Scorecard.png";
import Accuracyimg from "../../../assets/img/Dashboard/Accuracy.png";
import Chart from "react-apexcharts";
import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import Weightage from "../../../assets/img/Dashboard/weightage.png";
const RESULTPDF1 = "/api/student/test-result-analytics_single_byuser";

const StudentMultiAnswerSheet = () => {
  // console.log(userid,"userid",attempt)
  const navigate = useNavigate();

  const { id, userid, attempt } = useParams();
  // const [attempt, setattempt] = useState("");
  const token = localStorage.getItem("tokenStudent");
  // const userid = localStorage.getItem("userid");
  const [result, setResult] = useState([]);
  const [subjectdata, setSubjectData] = useState([]);
  const [attemptedques, setAttemptedques] = useState();
  const [statusresult, setStatusresult] = useState();
  const [openAccordions, setOpenAccordions] = useState({});
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [videoid, setvideoid] = useState([]);
  const[result1,setResult1]=useState([]);

  const [isModalVisibleForm1, setIsModalVisibleForm1] = useState(false);
  const [cheked, setChecked] = useState([]);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [selectedButton, setSelectedButton] = useState(0);
  const handleClick = (index) => {
    // console.log(index, "index");
    setSelectedButton(index);
  };
  const handleModalDeleteClose = () => {
    setIsModalVisibleForm1(false);
  };
  const handleShowForm1 = () => {
    setIsModalVisibleForm1(true);
  };
  const handleCreate = () => {
    handleShowForm1();
  };
  const onChange = (e) => {
    setChecked(e.target.checked);
    // console.log(`checked = ${e.target.checked}`);
  };
  const ResultDownlaod1 = async () => {
    try {
      //   setLoading(true);
      const resonse = await axios.get(`${RESULTPDF1}/${userid}/${id}/${attempt}`);
      console.log(resonse?.data, "response result");
      //   setInvoiceData(resonse?.data);
       setResult1(resonse?.data);
      //   setLoading(false);
    } catch (error) {
      console.log(error);
      //   if (error?.response?.status === 401) {
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("showNotification")

      //     navigate("/login");
      //   }
      // openNotificationWithIcon2("error");
    }
  };
  useEffect(() => {
    ResultDownlaod1();
  }, []);
  const ResultDownlaod = async () => {
    try {
      //   setLoading(true);
      const responce = await axios.get(`${RESULTPDF}/${id}`);
      console.log(responce?.data, "response");
      //   setInvoiceData(resonse?.data);
      setResult(responce?.data);

      //   setLoading(false);
    } catch (error) {
      console.log(error);
      //   if (error?.response?.status === 401) {
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("showNotification")

      //     navigate("/login");
      //   }
      // openNotificationWithIcon2("error");
    }
  };
  useEffect(() => {
    ResultDownlaod();
  }, []);
  console.log(result, "subject");

  const [accordionOpen, setAccordionOpen] = useState(true);

  useEffect(() => {
    // This effect runs once when the component mounts
    // Set accordionOpen to true to ensure all accordion items are open by default
    setAccordionOpen(true);
  }, []); // Empty dependency array ensures the effect runs only once
  const chartOptions = {
    series: [result1?.totalAttempt, result1?.leaveQuestion],

    chart: {
      width: "100%",
      type: "donut",
      // height:"100%"
    },
    labels: ["Attempted", "Unattempted"],
    colors: ["#6fdc6f", "#66b0e5"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'dark',
    //     type: 'vertical',
    //     shadeIntensity: 0.5,
    //     inverseColors: false,
    //     opacityFrom: 1,
    //     opacityTo: 0.8,
    //     stops: [0, 100]
    //   }
    // }
  };
  const chartOptions1 = {
    series: [result1?.correctAnswer, result1?.wrongAnswer],
    chart: {
      width: "100%",
      type: "pie",
      //  height:"100%"
    },
    labels: ["Correct Answers", "Wrong Answers"],
    colors: ["#6fdc6f", "#f15b5b"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'dark',
    //     type: 'vertical',
    //     shadeIntensity: 0.5,
    //     inverseColors: false,
    //     opacityFrom: 1,
    //     opacityTo: 0.8,
    //     stops: [0, 100]
    //   }
    // }
  };
  return (
    <>
      <div className="main-wrapper">
        {/* BreadCrumb */}
        <div className="breadcrumb-bar p-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="container" style={{ marginTop: "20px" }}>
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="clo-12">
              <h3 style={{ textAlign: "center", textTransform: "capitalize" }}>
                {result[0]?.name} Answer Sheet
              </h3>
            </div>
            <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-md-6 d-flex">
              <div
                className="card instructor-card w-100"
                style={{
                  border: "none",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className="card-body d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="instructor-inner">
                    <h6 style={{ color: "black", fontWeight: 700 }}>
                      Scored Marks
                    </h6>
                    <h4
                      className="instructor-text-success"
                      style={{ fontWeight: 800 }}
                    >
                      {console.log(result1?.totalMarks)}
                      {result1?.totalMarks >= 0
                        ? (result1?.totalMarks).toFixed(2)
                        : result1?.totalMarks?.toFixed(2)}
                    </h4>
                  </div>
                  <div className="instructo-inner">
                    <img src={scorecard} alt="image" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div
                className="card instructor-card w-100"
                style={{
                  border: "none",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className="card-body d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="instructor-inner">
                    <h6 style={{ color: "black", fontWeight: 700 }}>
                      Accuracy
                    </h6>
                    <h4
                      className="instructor-text-info"
                      style={{ color: "#f00e21", fontWeight: 800 }}
                    >
                      {(
                        (result1?.correctAnswer / result1?.totalAttempt) *
                        100
                      ).toFixed(2) >= 0
                        ? (
                            (result1?.correctAnswer / result1?.totalAttempt) *
                            100
                          ).toFixed(2)
                        : 0}
                      %
                    </h4>
                  </div>
                  <div className="instrucor-inner">
                    <img src={Accuracyimg} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-md-6">
              <div
                className="settings-widget krishna1"
                style={{
                  border: "none",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className="settings-inner-blk"
                  style={{ borderBottom: "none" }}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h4
                      style={{
                        marginLeft: "20px",
                        marginTop: "15px",
                        color: " #392C7D",
                        fontSize: "19px",
                      }}
                    >
                      Total quesions : {result1?.totalQuestion}
                    </h4>
                    <h4
                      style={{
                        marginLeft: "20px",
                        marginTop: "15px",
                        color: " #392C7D",
                        marginRight: "30px",
                        fontSize: "19px",
                      }}
                    >
                      Total Marks :
                      {result1?.totalQuestion * result1?.eachQuestionMark}
                    </h4>
                  </div>

                  <div className="sell-course-head comman-space graph2-1-ans ">
                    <div className="chart">
                      <Chart
                        options={chartOptions}
                        series={chartOptions.series}
                        type={chartOptions.chart.type}
                        width={chartOptions.chart.width}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="settings-widget "
                style={{
                  border: "none",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className="settings-inner-blk p-0"
                  style={{ borderBottom: "none" }}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h4
                      style={{
                        marginLeft: "20px",
                        marginTop: "15px",
                        color: "#392C7D",
                        fontSize: "19px",
                      }}
                    >
                      Positive Marks :{" "}
                      {(
                        result1?.eachQuestionMark * result1?.correctAnswer
                      ).toFixed(2)}
                    </h4>
                    <h4
                      style={{
                        marginTop: "15px",
                        color: "#392C7D",
                        marginRight: "30px",
                        fontSize: "19px",

                        marginRight: "20px",
                      }}
                    >
                      Negative Marks :{" "}
                      {(result1?.negativeMark * result1?.wrongAnswer).toFixed(2)}
                    </h4>
                  </div>

                  <div className="sell-course-head comman-space graph2-ans">
                    <div className="chart">
                      <Chart
                        options={chartOptions1}
                        series={chartOptions1.series}
                        type={chartOptions1.chart.type}
                        width={chartOptions1.chart.width}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            {result?.length > 0
              ? result[1]?.map((item, index) => {
                  return (
                    <div className="col-12 d-flex mt-4">
                      <div
                        className="card instructor-card w-100"
                        style={{
                          border: "none",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                      >
                        <div
                          className="card-body d-flex"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div className="instructor-inner">
                            <h6
                              style={{
                                color: "black",
                                fontWeight: 700,
                                textTransform: "capitalize",
                                display:'flex'
                              }}
                            >
                              <div>
                              Q{index + 1}){" "}
                              </div>
                              <div className="ms-2">
                              { <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.name,
                                  }}
                                />}
                              </div>
                             
                               
                              
                            </h6>

                            <h4
                              className="instructor-text-success"
                              style={{ fontWeight: 600 }}
                            >
                              <div className="d-flex">
                                <div
                                  style={{
                                    display: "flex",
                                    alignSelf: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontSize: "18px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Answer :{" "}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignSelf: "center",
                                    fontSize: "22px",
                                    marginBottom: "4px",
                                    marginLeft: "4px",
                                  }}
                                >
                                  {item?.answer}
                                </div>
                              </div>
                            </h4>
                           
                                                    {/* {value?.video ? (
                                                      <div
                                                        className="add-new-address grad-border hvr-sweep-to-right"
                                                        style={{
                                                          marginTop: "5px",
                                                        }}
                                                      >
                                                        <button className="btn btn-secondary"
                                                         onClick={()=>{
                                                          let embedUrl;
                                
                                    // Check if it's a YouTube link
                                    if (value?.video?.includes('youtube.com')) {
                                      const videoId = value.video.split('v=')[1];
                                      embedUrl = `https://www.youtube.com/embed/${videoId}`;
                                    }
                                    // Check if it's a Vimeo link
                                    else if (value?.video?.includes('vimeo.com')) {
                                      const videoId = value.video.split('/').pop();
                                      embedUrl = `https://player.vimeo.com/video/${videoId}`;
                                    }
                                    // For any other link
                                    else {
                                      embedUrl = value?.video; // Assuming it's a valid video URL
                                    }
                                    handelvimeovideo(embedUrl)
                                                        }}
                                                        
                                                        >
                                                          Watch Video
                                                        </button>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )} */}
                                                    {item?.explaination ? (
                                                      <div
                                                        className="add-new-address grad-border hvr-sweep-to-right d-flex"
                                                        style={{
                                                          marginTop: "5px",
                                                        }}
                                                      >
                        <div style={{alignSelf:'center'}}>
                                                        <h6>Solution : </h6>

                                                        </div>                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item?.explaination,
                                                          }}
                                                           style={{marginLeft:"5px",marginTop:'-2px'}}
                                                        />
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentMultiAnswerSheet;
