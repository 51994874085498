import React, { useContext, useEffect, useState } from "react";
import { InstructorHeader } from "../../instructor/header";
import AdminHeader from "../adminHeader";
import Footer from "../../footer";
// import "./index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import Button from "react-bootstrap/Button";

import Switch from "react-switch";
import AuthContext from "../../../context/AuthProvider";
import axios from "../../../api/axios";
import CryptoJS from "crypto-js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip, Input } from "antd";
import { notification, Space } from "antd";
import { Spin } from "antd";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";
import CoursesImage from "../../../assets/img/Dashboard/admincourse.png";

const COURSELIST = "/api/course-category/course";
const DELETECOURSE = "/api/course/delete";
const COURSESTATUS = "/api/course/status";
const COURSESEARCH = "/api/course/course-search";
import { Modal } from "antd";
import PaginationCourseType from "./Pagination";
import MoveIcon from "../../../assets/img/user/Move.png"
const COURSECATEGORY = "/api/admin/course/menu";
const COURSEINDEX="/api/admin/courses/get/category"
const UPDATECOURSEINDEXLIST="/api/admin/courses/orders"
const { confirm } = Modal;
// const windowTop1 = (arg1, arg2) => {
//   window.scrollTo(arg1, arg2);
// };
export default function CourseModule() {
  const { id } = useParams();
  // console.log(id, "moduleid");
  const { Search } = Input;
  const [show, setShow] = useState(false);
  const [last_page, setLast_page] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(null);
  const [text, settext] = useState("");
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState([]);
  const [moduleid,setId]=useState(id)
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const[course,setCourse]=useState([])

  //   const [id, setId] = useState("");
  const token = localStorage.getItem("token");
  const [message, setmessage] = useState("No Data");
  const { auth } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      COURSESTATUS,
      { id: id, status: result },
      config
    );
    response;
    // toast.success("Status Change");
    // console.log(response);
    if (response) {
      openNotificationWithIcon1("success");
      fecthCoureList()
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
    // console.log(list);
  };
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconLogin = (type,text) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const [courses, setCourses] = useState([]);

  const courseslist = async () => {
    try {
      const response = await axios.get(COURSECATEGORY, config);
      const data =response?.data?.message.filter((item)=>
        item?.id==moduleid
      )
      setCourses(data);
    } catch (error) {}
  };
  // console.log(courses, "response of the id");

  useEffect(() => {
    courseslist();
  }, [moduleid]);

  const fecthCoureList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${COURSELIST}/${id}`, config);
      //  console.log(response?.data?.message, "data");
      setList(response.data?.message?.data);
      setName(response?.data?.message);
      setLast_page(response.data?.message?.last_page);
      //   setData(resonse.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIconLogin("error",error?.response?.data?.message,);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      
      else{
      openNotificationWithIcon2("error");
      }
    }
  };
  useEffect(() => {
    fecthCoureList();
  }, []);
  // console.log(list);
  const coursesIndexList= async () => {
    try {
      const response = await axios.get(`${COURSEINDEX}/${id}`, config);
      // console.log(response.data,"response")
      setCourse(response?.data)
    } catch (error) {
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
      openNotificationWithIcon2("error");
      }
    }
  };
  
  const handlePayment = (payid) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(payid),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/show-course-payment/${ciphertexts}`);
  };
  const handleClose = () => setShow(false);
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Course Delted</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const handleDeleteCourse = async () => {
    if (id) {
      try {
        setLoading(true);
        const resonse = await axios.delete(`${DELETECOURSE}/${id}`, config);
        openNotificationWithIcon("success");

        setLoading(false);
        fecthCoureList();
        setShow(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      }
    }
  };
  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this course?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.delete(`${DELETECOURSE}/${id}`, config);
          // console.log("resonse", resonse);
          openNotificationWithIcon("success");

          setLoading(false);
          fecthCoureList();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          if (error?.response?.status === 422) {
            openNotificationWithIcon2(error?.response?.data?.message, "error");
          }
          else{
          openNotificationWithIcon2(error?.response?.data?.message, "error");
        }
      }
      },
      onCancel() {},
    });
  }
  const handleCreate = () => {
  
    handleShowForm();
    coursesIndexList()
  };
  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };

  const handleModalClose = () => {
    setIsModalVisibleForm(false);
  };
  const handleView=(id)=>{
    navigate(`/admin/courses/videolist/${id}`)
  }

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);
  const handleEdit = (id,moduleid) => {
    // console.log(id,"id in module",id)
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/editcourse/${ciphertexts}/${moduleid}`);
  };
  const handleLink = (slug,name) => {
    // console.log(id,"id in module",id)
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(name),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/courses/${slug}/${ciphertexts}`);
  };
  const Filter = async (e) => {
    const searchValue = e.target.value;
    settext(searchValue);
    const res = await axios.post(COURSESEARCH, { name: searchValue }, config);
    // console.log(res?.data?.message,"accept")
    if (res.data.message?.data?.length > 0) {
      setList(res.data.message?.data);
      setCurrentPage(res.data.current_page);
      setLast_page(res.data.last_page);
      setmessage("No Data");
    }
    if (res.data.message?.data?.length === 0) {
      setList(res?.data?.message?.data);
      setmessage("No Data Found");
    }
    if (searchValue.length === 0) {
      const res = await axios.get(`${COURSELIST}?page=${currentPage}`, config);
      setList(data);
      setCurrentPage(1);
      setLast_page(res.data.last_page);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  // console.log(list, "list");
  // useEffect(() => {
  //   windowTop1(0, 0);
  // }, []);
  const itemsPerPage = 10;
  const handleDragStart = (id) => (e) => {
    e.dataTransfer.setData('text/plain', id);
  };

  const handleDrop = (id) => (e) => {
    e.preventDefault();
    const draggedId = e.dataTransfer.getData('text/plain');
    const updatedCourses = [...course];
    const draggedItem = updatedCourses.find((item) => item.id == draggedId);

    const targetIndex = updatedCourses.findIndex((item) => item.id == id);

    updatedCourses[updatedCourses.findIndex((item) => item.id == draggedId)] =
      updatedCourses[targetIndex];
    updatedCourses[targetIndex] = draggedItem;
    

    setCourse(updatedCourses);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  
  const UpdateCourseIndex = async () => {
    const datavalue = course?.map((item, index) => ({
      id: item?.id,
      order: index,
    }));
    // const value=[]
    // value.push(datavalue)
    try {
      const response = await axios.post(UPDATECOURSEINDEXLIST,{data:datavalue} ,config);
      if(response){
        handleModalClose()
        openNotificationWithIcon1("success")
      }
      // console.log(response?.data?.message, "data");
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      openNotificationWithIcon2("error");
    }
  };
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={`CoursesModule${id}`} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div
                          className="sell-course-head comman-space button-container"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div>
                          <h3>{courses[0]?.name}</h3>

                          <nav
                            aria-label="breadcrumb"
                            // style={{ marginBottom: "-1.6em" }}
                          >
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate("/admin/course/category")
                                }
                              >
                                Courses Category

                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                // onClick={() =>
                                //   navigate("/admin/recorded-videos")
                                // }
                              >
                                {" "}
                                {courses?courses[0]?.name:""}
                              </li>
                            </ol>
                          </nav>
                          </div>
                          {/* <button className="btn btn-primary">Course Category</button> */}
                          <div className="d-flex">
                            {/* <div
                              className="ticket-btn-primary"
                              style={{
                                color: "white",
                                marginRight: "0.35em",
                                backgroundColor: "rgb(255, 135, 90)",
                                padding: "15px",
                                height: "45px",
                                marginTop: "-10px",
                                textAlign: "center",
                                display: "flex",
                                borderRadius: "8px",
                              }}
                            >
                              <Link
                                to={"/admin/course/index"}
                                style={{ alignSelf: "center", color: "white" }}
                              >
                                Course Index
                              </Link>
                            </div> */}
                            <div
                              className="ticket-btn-grp"
                              style={{ marginRight: ".35em" }}
                            >
                              <Link to={`/admin/course-type/${id}`}>
                                Course SubCategory
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="comman-space pb-0">
                          <div
                          // className="instruct-search-blk"
                          // id="table-value"
                          >
                            {/* Instructor Users */}

                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row gx-2 align-items-center">
                                    <div className="col-md-6 col-item">
                                      <div className=" search-group">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Course Name"
                                          // allowClear
                                          //  onChange={Filter}
                                          onKeyDown={handleKeyPress}
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-item">
                                      <div
                                        className="button-conatiner d-flex justify-content-end"
                                        // style={{  marginBottom:"-5px"}}
                                      >
                                        <Button
                                          onClick={() =>
                                            handleCreate()

                                          }
                                          
                                          className="btn btn-danger"
                                          id="custom-button"
                                        >
                                          Course Index
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            navigate(`/admin/addcourse/${id}`)
                                          }
                                          style={
                                            {
                                              // marginLeft: "5.2em",
                                              // width:"100%"
                                            }
                                          }
                                          className="btn btn-primary"
                                          id="custom-button"
                                        >
                                          {" "}
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Create New
                                        </Button>
                                        
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                            <Modal
                              title="Add Course Index"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              open={isModalVisibleForm}
                              okText="Save"
                              onOk={() => {
                                UpdateCourseIndex()
                              }}
                              onCancel={handleModalClose}
                            >
                              
                              <div style={{maxHeight:"420px",overflow:"auto"}}>
                           {course?.length>0?course?.map((item)=>{
                            return(
 <div
 className="box source col-8 d-flex"
 style={{ margin: "auto", marginTop: "10px" ,boxShadow:"none" }}
  key={item.id}
 draggable
 // onDragStart={() => handleDragStart(item.id)}
 onDragStart={handleDragStart(item?.id)}
 onDragOver={handleDragOver}
 onDrop={handleDrop(item?.id)}
>
 <div style={{ display: "flex" }}>
   <img src={item?.image?item?.image:CoursesImage} style={{ width: "70px", height: "70px" ,borderRadius:"50px" }} alt="" />
 </div>
 <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
   <h6 style={{ textTransform: "capitalize", marginLeft:"10px" }}>{item?.name}</h6>
   <h6 style={{ textTransform: "capitalize", marginLeft:"10px",fontSize:"12px" }}>SubCategory {" "}:{" "}{item?.course_type?.name}</h6>

 </div>
 {/* <img src={MoveIcon} alt="move" style={{width:"25px",height:"25px",marginLeft:"25px"}}/> */}
</div>
                            )
                           }):"No Data"}     
                             

                            </div>
                              
                            </Modal>
                              <table className="table table-nowrap">
                                <thead>
                                  <tr className="text-center">
                                    <th>Sr. No.</th>
                                    {/* <th>Catgeory NAME</th> */}
                                    <th>Course Name</th>
                                    <th>Course Type</th>
                                    <th>Duration</th>
                                    <th>Amount</th>
                                     <th>Status</th>
                                  <th>Actions</th> 
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                {loading === true ? (
                                 
                                    <tbody   className="d-flex justify-content-center align-items-center"
                                    style={{
                                      position: "fixed",
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.6)",
                                    }}>
                                      <tr>
                                        <td>
                                          <div
                                            className="spinner-border text-warning"
                                            role="status"
                                          >
                                            <span className="sr-only">
                                              Loading...
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                ) : (
                                  list?.map((item, i) => {
                                    const sNo =
                                        (currentPage - 1) * itemsPerPage +
                                        i +
                                        1;
                                      return (
                                        <tbody
                                          key={item.id}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <tr className="text-center">
                                            <td className="text-center">
                                              {sNo}
                                            </td>
                                            {/* <td className="text-center">
                                              <Tooltip title={item1?.name}>
                                                {`${item?.name?.slice(
                                                  0,
                                                  20
                                                )} ${
                                                  item1?.name?.length > 20
                                                    ? "..."
                                                    : ""
                                                }`}
                                              </Tooltip>
                                            </td> */}
                                            <td className="text-center">
                                              <Tooltip title={item?.name}>
                                                {`${item?.name?.slice(0, 25)} ${
                                                  item?.name?.length > 25
                                                    ? "..."
                                                    : ""
                                                }`}
                                              </Tooltip>
                                            </td>
                                            <td>{item?.course_type?.name}</td>

                                            <td>{item.duration} Days</td>
                                            {/* <td>₹ {Math.round(item.amount).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</td> */}
                                            <td>
                                              ₹{" "}
                                              {parseInt(
                                                item.amount
                                              ).toLocaleString("en-IN", {
                                                maximumFractionDigits: 0,
                                              })}
                                            </td>
                                            <td>
                                              
                                          <Switch
                                            onChange={(e) => {
                                              handleChange(
                                                item.id,
                                                item.status
                                              );
                                            }}
                                            checked={
                                              item.status === "1" ? true : false
                                            }
                                            // id={item.id}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={38}
                                            className="react-switch"
                                          />
                                        </td>
                                           
                                            <td className="text-center">
                                        
                                            <Tooltip title="Link">
                                            <button
                                              onClick={() =>
                                                // navigate(
                                                //   `/admin/courses/${item.slug}/${item.name}`
                                                // )
                                                handleLink(item?.slug,item?.name)
                                              }
                                              type="button"
                                              className="btn btn-success text-white"
                                            >
                                              <i
                                                className="fa fa-link"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </Tooltip>{" "}
                                          <Tooltip title="Edit">
                                            {" "}
                                            <button
                                              onClick={() =>
                                                handleEdit(item?.id,moduleid)
                                              }
                                              type="button"
                                              className="btn btn-secondary"
                                            >
                                              <i className="fa-regular fa-pen-to-square"></i>
                                            </button>{" "}
                                          </Tooltip>
                                          <Tooltip title="View Videos">
                                              <a
                                                onClick={() =>
                                                  handleView(item?.id)
                                                }
                                                className="btn btn-info text-white"
                                              >
                                                <i className="fa-regular fa-eye"></i>
                                              </a>
                                            </Tooltip>{" "}
                                          <Tooltip title="Delete">
                                            <Button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() =>
                                                showConfirm(item?.id)
                                              }
                                            >
                                              <i className="fa-regular fa-trash-can"></i>
                                            </Button>
                                          </Tooltip>
                                        </td>
                                          </tr>
                                        </tbody>
                                      );

                                    return "";
                                  })
                                )}
                              </table>
                            </div>

                            {list?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {list === null ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {list ?.length <= 0  ? (
                              ""
                            ) : (
                              <PaginationCourseType
                                nPages={last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={COURSELIST}
                                id={id}
                                setData={setList}
                                text={text}
                                setLast_page={setLast_page}
                                linkSearch={COURSESEARCH}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
}
