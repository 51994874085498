import React, { useContext, useEffect, useState } from "react";
import { InstructorHeader } from "../header";
import Footer from "../../footer";
import {
  InstructorProfileBg,
  ReferImage1,
  ReferImage2,
  ReferImage3,
  ReferImage4,
  ReferImage5,
  User15,
} from "../../imagepath";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import FeatherIcon from "feather-icons-react";
import AdminHeader from "../adminHeader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CryptoJS from "crypto-js";
import Switch from "react-switch";
import AuthContext from "../../../context/AuthProvider";
import { Search } from "react-feather";
import "../adminTestSeries/index.css"
import * as Yup from "yup";
import axios from "../../../api/axios";
import { AddAdminRecordedVideos } from '../addAdminRecordedVideos/index';
import { Tooltip } from "antd";
import TextEditor from "../../pages/course/addCourse/editor";
import { event } from "jquery";
import { useFormik } from "formik";
import { notification, Space  } from "antd";

const EDITCOURSECATEGORY="/api/course-category/show"
const UPDATECATEGORY="/api/course-category/update"
export default function AdminCategoryEdit() {
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();
  const[submit,setSubmit]=useState(false)
  const [errorI, setErrorI] = useState("");
  const [file, setFile] = useState(null);


//   let bytes = CryptoJS.AES.decrypt(
//     decodeURIComponent(id),
//     "my-secret-key@admin8623009959"
//   );
//   let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const fetchEditCourseCategory = async () => {
    try{
      setLoading(true);
      const response = await axios.get(
        `${EDITCOURSECATEGORY}/${id}`,
        config
      );
      // console.log(response,"response")
      setData(response.data);
      setLoading(false);
    }
    catch(error){
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon2(error?.response?.data?.message,"error");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
    }
   
  };
  useEffect(() => {
    fetchEditCourseCategory();
  }, []);
  const initalValue = {
    name: data?.name?data?.name:"",
    status: data?.status,
    image:data?.image,
  };
  const studentSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter your Batch Name"),
   
    status: Yup.string().required("Please enter your Status"),
  });
  const navigate = useNavigate();
  const[toast,setToast]=useState(false)
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type) => {
    // console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"#52c41a"}}>
         <strong>Category Updated</strong> 
        </div>
      ),
      duration:1

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Created
  
      // </div>)
      
    });
  
    
  
       
     
     
    };
  const { errors, values, touched, handleChange, handleSubmit, getFieldProps,setFieldValue } =
    useFormik({
      initialValues: initalValue,
      onSubmit: async (value) => {
        setSubmit(true)
        try {
            const formData = new FormData();
            if (file !== null) {
              const image = new Image();
              image.src = URL.createObjectURL(file);
              await new Promise(resolve => { image.onload = resolve });
              if (image.width > 630 || image.height > 420) {
                openNotificationWithIcon("error", "Image dimensions should be 630px width and 430px height.", "red");
                setSubmit(false);
                return;
              }
                formData.append("file", file);
              }
            formData.append("name", value?.name);
            formData.append("status", value?.status);
    
          const response = await axios.post(
            `${UPDATECATEGORY}/${id}`,
            // {
            //   "name": value?.name,
            //   "status": value?.status,
            //   "file":value?.file,

            
            // },
            formData,
            config
          );
          if (response) {
            openNotificationWithIcon("success")

            setTimeout(() => {
              // navigate("/admin/test-series");
              setToast(true)

              navigate(-1);
            }, 1000);
            // toast.info("Catgeory Updated");
          }
        } catch (error) {
          console.log(error?.response?.data?.message);
          setSubmit(false)
          setToast(false)
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem('token')
            navigate('/admin/login')
          }
          else if (error?.response?.status === 403) {
            localStorage.removeItem("token");
            openNotificationWithIcon2(error?.response?.data?.message,"error");
            setTimeout(() => {
              navigate("/admin/login");
            }, 1000); // Delay the navigation to show the notification
          }
          else{
          openNotificationWithIcon2(error?.response?.data?.message,"error")
        }
      }
      },
      enableReinitialize: true,
      validationSchema: studentSchema,
    });
    useEffect(() => {
      const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
      if(lastVisitedRoute){
        localStorage.removeItem("lastVisitedRoute")
      }
      else{
        localStorage.setItem("lastVisitedRoute", window.location.pathname);
  
      }
    }, []);
    const openNotificationWithIcon2 = (text,type) => {
      // console.log("toast after click")
      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"red"}}>
           <strong>{text}</strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        duration:1
      });
      };
      const openNotificationWithIconSesion = (type) => {
        notification[type]({
          message: (
            <div style={{ color: "red" }}>
              <strong>Session Expired Please Login </strong>
            </div>
          ),
          duration: 1,
        });
      };
      // const windowTop = (arg1, arg2) => {
      //   window.scrollTo(arg1, arg2);
      // };
      
      //   useEffect(() => {
      //     windowTop(0, 0);
      //   }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader/>
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Courses"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Category</h3>
                    {/* <p>Edit Test Series information.</p> */}
                    <nav aria-label="breadcrumb" style={{marginTop:'0.5em'}} >
                      <ol className="breadcrumb" style={{marginBottom:0.5,cursor:"pointer"}}>
                        <li className="breadcrumb-item" onClick={()=>navigate('/admin/course/category')} aria-current="page">
                         Caategory
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                      Edit Category
                        </li>
                      </ol>
                    </nav>
                  </div>
<div >
<div className="checkout-form personal-address add-course-info "
                  style={{backgroundColor:"white",padding:"10px"}}
                  >
                    <div className="personal-info-head"
                    style={{padding:"20px"}}
                    >
                    <form onSubmit={handleSubmit}>
                      {loading === true ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row" >
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Enter Category Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={values.name}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter your Catgeory Name"
                              />
                              {errors.name && touched.name ? (
                                <span style={{ color: "red" }}>
                                  {errors.name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Cover Image Size(630px*420px)
                              </label>
                              <input
  type="file"
  id="image"
  name="image"
  className="form-control"
  placeholder="Cover Image"
  onChange={(e) => {
    setFile(e.target.files[0]); // Update the state with the new file
    setFieldValue("image", URL.createObjectURL(e.target.files[0])); // Update the form field value with the new image URL
  }}
/>

                              {/* {values.image && (
                                <img
                                  src={`https://lmscode.stellarflux.in/${values.image}`}
                                  alt="no image"
                                  height={50}
                                  width={90}
                                />
                              )} */}
                              {errorI ? (
                                <span style={{ color: "red" }}>{errorI}</span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <div
                                className="add-image-box"
                                style={{ minHeight: "0", height: "200px" }}
                              >
                                {values.image === null ? (
                                  <img
                                    src={Share}
                                    style={{
                                      height: "200px",
                                      width: "100%",
                                      borderRadius: "10px",
                                    }}
                                  ></img>
                                ) : (
                                  <img
                                    src={`${values.image}`}
                                    style={{
                                      height: "200px",
                                      width: "100%",
                                      borderRadius: "10px",
                                    }}
                                  ></img>
                                )}
                              </div>
                            </div>
                          </div>
                        
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label
                                className="radio control-label"
                                style={
                                  {
                                    // marginRight: "10px",
                                    // marginTop: "-30px",
                                  }
                                }
                              >
                                Status :
                              </label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("status").onChange}
                                  value={"0" || values?.status}
                                  checked={values?.status === "0"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio2"
                                  onChange={getFieldProps("status").onChange}
                                  value={"1" || values?.status}
                                  checked={values?.status === "1"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Active
                                </label>
                              </div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="update-profile"
                              id="submit-button"

                          >
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"

                            >

      <button type="submit" className="btn btn-primary"
                              disabled={submit}
                             
                              >
                                Update
                              </button>
        
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"

                            >
                              <button
                                type="button"
                                onClick={() => {
                                  navigate(-1);
                                }}
                                className="btn btn-primary cancel-btn "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    </form>
                    </div>
                  </div>
</div>
                 
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      {/* <ToastContainer /> */}
      <Footer />
    </div>
  );
}
