import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { notification } from "antd";

const initalValue = {
  timex: "",
  module: "",
};

const moduleSchema = Yup.object({
  timex: Yup.string().required("Please enter your time"),
  module: Yup.string().required("Please select your module"),
});

const Moduleform = () => {
  const token = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [module, setModule] = useState(null);
  const getModule = async () => {
    const responce = await axios.get("/api/admin/module/get/2", config);
    setModule(
      responce?.data?.map(({ id, name }) => ({ label: name, value: id }))
    );
  };
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      // height: "40px",
      color: "black",
      minHeight: "40px",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  useEffect(() => {
    getModule();
  }, []);

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  const options = [
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "5", value: "5" },
  ];
  const navigate = useNavigate();
  
  const openNotificationWithIcon = (type,text,col) => {
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: col }}>
                <strong>{text}</strong>
              </div>
            ),
        });

  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const {
    errors,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    onSubmit: async (value, { setFieldError }) => {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const response = await axios.put(
          `api/admin/recordedvideo/limit/module/${value.module}`,
          { timex: value.timex },
          config
        );
        if (response.status==200){   
             openNotificationWithIcon("success",response.data.message,"#52c41a")

          setTimeout(() => {
            navigate(-1);
          }, 800)}
       

      } catch (error) {
        console.log(error.response);
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
        if (error.response.status === 500){
            openNotificationWithIcon("error",'Internal Server Error',"red")
        }
        else{
          openNotificationWithIcon("error",'Internal Server Error',"red")

        }
      }
    },
    validationSchema: moduleSchema,
  });
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };
  
  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  return (
    <div>
      <div className="checkout-form personal-address add-course-info ">
        <div className="personal-info-head">
          <h4>Restricted Time</h4>
          <p>Select your option</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="new-address">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mb-0">
                  <label className="form-label">Select Module</label>
                  <Select
                    className=" select"
                    name="module"
                    options={module}
                    placeholder="Choose"
                    styles={style}
                    id="module"
                    onChange={(option) => setFieldValue("module", option.value)}
                  ></Select>
                  {errors.module && touched.module ? (
                    <span style={{ color: "red" }}>{errors.module}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-0">
                  <label className="form-label">Time</label>
                  <Select
                    className=" select"
                    name="timex"
                    options={options}
                    placeholder="Choose"
                    styles={style}
                    id="timex"
                    onChange={(option) => setFieldValue("timex", option.value)}
                  ></Select>
                  {errors.timex && touched.timex ? (
                    <span style={{ color: "red" }}>{errors.timex}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className="update-profile"
              id="submit-button"
              style={{ marginTop: "2rem" }}
            >
              <div
                className="btn-group mr-2"
                role="group"
                aria-label="First group"
                id="custom-submit"
              >
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>{" "}
              <div
                className="btn-group mr-2"
                role="group"
                aria-label="First group"
                id="custom-submit"
              >
                <button
                  type="button"
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="btn btn-primary cancel-btn"
                >
                  Cancel
                </button>
              </div>{" "}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Moduleform;
