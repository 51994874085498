import React, { useContext, useState } from "react";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";

import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../api/axios";
import AdminHeader from "../../instructor/adminHeader";
import GridInnerPage from "./gridInnerPage";


export default function AdminGridCourse() {
  const { id } = useParams();
  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id), 
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const navigate = useNavigate();


  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"paid"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Course List</h3>
                    <nav aria-label="breadcrumb" >
                      <ol class="breadcrumb" style={{marginBottom:0.5}}>
                        <li
                          class="breadcrumb-item "
                          onClick={() => navigate(-1)}
                          // aria-current="page"
                          style={{ cursor: "pointer" }}
                        >
                         Paid Students
                        </li>
                        <li
                          class="breadcrumb-item active"
                          aria-current="page"
                          style={{
                            cursor: "pointer",
                            // marginLeft: "-.6em",
                            fontWeight:'normal'
                          }}
                        >
                          Course List
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="checkout-form personal-address add-course-info "
                  //  style={{background:'#E5E4E2'}}
                   >
                    <div className="personal-info-head"></div>
                    <form>
                      <div className="row">
                        <div className="col-lg-12">
                         <GridInnerPage uid={decryptedData}/>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
