import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon2, Share } from "../../imagepath";
import axios from "../../../api/axios";
import CryptoJS from "crypto-js";
import { Button } from "react-bootstrap";
import "./gridinner.css"


const GETCOURSE = "/api/user-subscription-course";

const GridInnerPage = ({ uid }) => {
    const token = localStorage.getItem("token");
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [payTypeDetails, setpayTypeDetails] = useState(null)
    const [CourseTypeDetails, setCourseTypeDetails] = useState(null)

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const getCourse = async () => {
        try{
            setLoading(true);
            const reponse = await axios.get(`${GETCOURSE}/${uid}`, config);
            setpayTypeDetails(reponse.data.payTypeDetails)
            setCourseTypeDetails(reponse.data.CourseTypeDetails)
            setList(reponse.data.courses);
            setLoading(false);
        }
        catch(error){
            if (error?.response?.status === 401) {
                localStorage.removeItem('token')
                navigate('/admin/login')
              }
        }
       
    };
    useEffect(() => {
        getCourse();
    }, []);
    const navigate = useNavigate();
    const handleBuy = (id) => {
          // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(id),
        "my-secret-key@admin8623009959"
      ).toString();
    let userid =CryptoJS.AES.encrypt(
        JSON.stringify(uid),   "my-secret-key@admin8623009959"
    ).toString()  
      let ciphertexts = encodeURIComponent(ciphertext);
      let userids =encodeURIComponent(userid)
        navigate(`/admin/course-list/emi/${ciphertexts}/${userids}`)
    }
    return (
        <>
            <div className="row">
                {loading === true ? (
                    <div
                        class="d-flex justify-content-center"
                        style={{ position: "absolute" }}
                    >
                        <div
                            class="spinner-border text-warning"
                            style={{
                                left: "50%",
                                marginRight: "24em",
                                marginTop: "4em",
                            }}
                            role="status"
                        >
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    list?.map((item) => {
                        return (
                            <div className="col-lg-4 col-md-6 d-flex">
                                <div className="course-box course-design d-flex "
                                 style={{
                                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                                  }}
                                >
                                    <div className="product">
                                        <div className="product-img">
                                            <Link>
                                                {item?.cors?.image === null ?
                                                    <img
                                                        className="img-fluid"
                                                        alt=""
                                                        src={Share}
                                                        style={{ height: '180px' }}
                                                    /> : <img
                                                        className="img-fluid"
                                                        alt=""
                                                        src={`${item?.cors.image}`}
                                                        style={{ height: '180px' }}
                                                    />}

                                            </Link>
                                            <div className="price"
                                            style={{minWidth:"100px",
                                        height:"40px",
                                        textAlign:"center"
                                    }}
                                            >
                                                <h3 className={item.cors.amount === '0.00' ? "free-color" : ""}
                                                style={{margin:"0px"}}
                                                >

                                                    {item.cors.amount === '0.00'
                                                        ? "Free"
                                                        : ` ₹ ${parseInt(item.cors.amount).toLocaleString("en-IN", {
                                                            maximumFractionDigits: 0,
                                                        })}`
                                                    }
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="product-content">
                                            <div className="course-group d-flex">
                                                <div className="course-group-img d-flex">
                                                    <div className="course-name">
                                                        <h4>
                                                            <Link>{item?.cors.name}</Link>
                                                        </h4>
                                                        {/* <p>Instructor</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <h3 className="title">
                                            </h3>
                                            <div className="course-info d-flex align-items-center">
                                                <div className="course-view d-flex align-items-center">
                                                    <img src={Icon2} alt="" />
                                                    <p>{item.cors.duration} Days</p>
                                                </div>
                                            </div>
                                            {item?.inst_paid_count == '0' ? <div className="all-btn all-category d-flex align-items-center justify-content-between"
                                                         id="button-btn"

                                            >
                                                <Button 
                                                id="custom-button"
                                                className="btn btn-primary">Full Payment</Button>
                                            </div> : ''}
{/*           
                                          {  payTypeDetails.map((it)=>it.course_id==item.course_id? CourseTypeDetails?.map((is)=>is?.cpt_id==it?.cpt_id?(is.total_inst_count==item.inst_paid_count?
                                          <div className="all-btn all-category d-flex align-items-center justify-content-between">
                                            <a className="btn btn-primary">Full Payment</a>
                                        </div>:<div className="all-btn all-category d-flex align-items-center justify-content-between">
                                            <a onClick={()=>{handleBuy(is.cpt_id)}} className="btn btn-primary">Pay Emi</a>
                                        </div>):""):"")} */}
                                        {payTypeDetails.map((it)=> it.course_id==item.course_id? item.inst_paid_count==it.total_inst_count?<div className="all-btn all-category d-flex align-items-center justify-content-between"
                                        id="button-btn"
                                        >
                                            <Button className="btn btn-primary"
                                             id="custom-button"
                                            >Full Payment</Button>
                                        </div>:<div className="all-btn all-category d-flex align-items-center justify-content-between">
                                            <Button onClick={()=>{handleBuy(it.cpt_id,item.user_id)}}
                                             className="btn btn-primary"
                                             id="custom-button"
                                             >Pay Emi</Button>
                                        </div>:"")}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </>
    );
};

export default GridInnerPage;
