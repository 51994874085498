import Collapse from "react-bootstrap/Collapse";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Share,
  Chart,
  Cloud,
  Icon1,
  Icon2,
  Import,
  Key,
  Mobile,
  People,
  Play,
  Teacher,
  Timer2,
  User1,
  Users,
  Video,
  Video2,
  Chapter,
} from "../../../imagepath";
import { Scrollbar } from "react-scrollbars-custom";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import "./detailsContent.css";
import axios from "../../../../api/axios";
import axiosInstance from "../../../../api/axios1";
const DETAILSDATA = "/api/student/course/detail";
const COURSESUBSCRPTION="/api/free-course-subcription"
import { notification, Space } from "antd";

const DetailsContent = ({
  amount,
  image,
  slug,
  description,
  name,
  id,
  setvideos,
  settestseries,
  duration,
  subscrption
}) => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [flag, setFlag] = useState(true);
  const [flag1, setFlag1] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subModule, setSubModule] = useState([]);
  const [test, setTest] = useState([]);
  const [video, setVideo] = useState([]);
  console.log(subscrption,"sub")

  // const [loading,setLoading] =useState(false)
  const formattedAmount = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
  }).format(amount || 0);
  // const DescriptionWithReadMore = ({ description, wordLimit }) => {
  //   const [showFullText, setShowFullText] = useState(false);

  //   if (!description) {
  //     return null;
  //   }

  //   const words = description.split(" ");
  //   const displayedWords = showFullText ? words : words.slice(0, wordLimit);
  //   const displayedText = displayedWords.join(" ");
  //   const isExceedsLimit = words.length > wordLimit;

  //   const handleClick = () => {
  //     setShowFullText(!showFullText);
  //   };
  //   const removePTags = (text) => {
  //     return text.replace(/<p>/g, "").replace(/<\/p>/g, "");
  //   };

  //   return (
  //     <div>
  //       {removePTags(displayedText)}
  //       {isExceedsLimit && !showFullText && "..."}
  //       {isExceedsLimit && (
  //         <button className="btn btn-link" onClick={handleClick}>
  //           <span className="fs-6">
  //             {showFullText ? "Read Less" : "Read More"}
  //           </span>
  //         </button>
  //       )}
  //     </div>
  //   );
  // };
  const tokenStudent = localStorage.getItem("tokenStudent");
 
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const getDetail1 = async () => {
    try {
      setLoading(true);
      const responce = await axiosInstance.get(`${DETAILSDATA}/${id}`, config);
      console.log(responce,"responce")
      setSubModule(responce?.data[0]?.relation?.sub_module);

      if (responce?.data[0]?.relation?.sub_module) {
        const testModules = responce?.data[0]?.relation?.sub_module?.filter(
          (module) => module?.type === "Test Series"
        );
        const videoModules = responce?.data[0]?.relation?.sub_module?.filter(
          (module) => module?.type === "Recorded Videos"
        );

        setTest(testModules);
       
        // setvideos(videoModules?.length);
        settestseries(testModules?.length);
        setVideo(videoModules);
        if (responce?.data[0]?.relation?.sub_module) {
          const finalvalue = [];
          const uniqueIds = new Set();
    
          responce?.data[0]?.relation?.sub_module
            .filter((module) => module?.type === "Recorded Videos")
            .forEach((module) => {
              const moduleId = module.id;
    
              module?.video.forEach((video) => {
                const videoId = video.id;
                const combinedId = `${moduleId}-${videoId}`;
    
                if (!uniqueIds.has(combinedId)) {
                  finalvalue.push({
                    id: videoId,
                    module_id: moduleId,
                    name: video.name,
                    thumbnail: video.thumbnail,
                    thumbnail_path: video.thumbnail_path,
                    description: video.description,
                  });
    
                  uniqueIds.add(combinedId);
                }
              });
            });
    
          // console.log(finalvalue, "finalValue");
          setvideos(finalvalue.length);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const videodata = () => {
    let result = [];
    for (const item of video) {
      // console.log(item);
      const submodule = [];

      submodule.push({ name: item.name, id: item.id });
      let obj = {};
      let videos = [];
      for (const video of item.video) {
        for (const Videos of video.videos) {
          videos.push(Videos);
        }
      }
      // console.log(videos, "videos");
      // const unique = [];
      // videos.map(x => unique.filter(a => a.thumbnail == x.thumbnail && a.title == x.title).length > 0 ? null : unique.push(x));
      // delete item.videorelation;
      // obj["videos"] = unique;
      // obj["submodule"] = item;

      // result.push(obj);
    }
  };
// console.log(video,"video")
  const finalData = [];

  video.forEach((item) => {
    const videoIds = new Set(); // To keep track of seen video ids

    item.video.forEach((video) => {
      if (!videoIds.has(video.id)) {
        finalData.push({
          name: item?.name,
          id: video?.id,
          nameofvideo: video?.name,
          thumbnail: video?.thumbnail,
          thumbnail_path: video?.thumbnail_path,
          description: video?.description,
        });

        videoIds.add(video.id);
      }
    });
  });
  // console.log(finalData,"finaldata")
  // useEffect(()=>{
  //  setvideos(finalData?.length) 
  // },[])
 

  // console.log(finalData);

  // console.log(subModule?subModule:"empty","submodule")
  // console.log("module data", finalData);
  useEffect(() => {
    getDetail1();
  }, []);
  useEffect(() => {}, [flag, flag1, flag2]);
  useEffect(() => {}, [subModule, test, video]);

  const navigate = useNavigate();
  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}:${m}`;
  };
  const userid=localStorage?.getItem("userid")
  console.log(id,"in courses")
  const handelfreeCourse=async(userid,id,duration)=>{
      try{
        const response=await axiosInstance.post(COURSESUBSCRPTION,{
          "user_id":userid,
          "course_id":id,
          "expiry_date":duration
        }, config);
        if(response){
          openNotificationWithIcon1("success");
          getDetail1()
          navigate(-1)

        }
        
        
        } 
        catch(error){
          console.log(error,"error")
        
          if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else{
        openNotificationWithIcon("error",error?.response?.data?.message,"red")
          }
        }

  }

  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Course Subscriped Sucessfully</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIcon = (type, text, color) => {
    console.log("krishna")
    notification[type]({
      message: (
        <div style={{ color: color }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  return (
    <>
      <section className="page-content course-sec">
        {loading === true ? (
          <div style={{ display: "block" }} id="cover-spin"></div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                {/* Overview */}
                <div className="card overview-sec">
                  <div className="card-body ">
                    <div className=" d-flex " style={{ gap: "60px" }}>
                      <div
                        onClick={() => {
                          setFlag(true);
                          setFlag1(false);
                          setFlag2(false);
                          // setFlag(true);
                        }}
                        style={{
                          paddingBottom: "3px",
                          borderBottom: flag ? " 2.3px solid #FF8D00" : "none",
                          // textDecoration: flag ? "underline #FF8D00" : "none",
                        }}
                      >
                        <button
                          className=""
                          style={{ border: "none", backgroundColor: "white" }}
                        >
                          <h5
                            className="subs-title"
                            style={{ marginBottom: ".5em" }}
                          >
                            Overview
                          </h5>
                        </button>
                      </div>
                    </div>
                    {flag ? (
                      <div style={{ cursor: "pointer", paddingTop: "20px" }}>
                        <h6>Course Description</h6>
                        <div
                          style={{ textTransform: "capitalize" }}
                          dangerouslySetInnerHTML={{
                            __html: description,
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* wordLimit={40} */}
                    {/* <p>
                    It was popularised in the 1960s with the release of Letraset
                    sheets containing Lorem Ipsum passages, and more recently
                    with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.
                  </p> */}
                    {/* <h6>What you'll learn</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Become a UX designer.</li>
                        <li>You will be able to add UX designer to your CV</li>
                        <li>Become a UI designer.</li>
                        <li>Build &amp; test a full website design.</li>
                        <li>Build &amp; test a full mobile app.</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>
                          Learn to design websites &amp; mobile phone apps.
                        </li>
                        <li>You'll learn how to choose colors.</li>
                        <li>Prototype your designs with interactions.</li>
                        <li>Export production ready assets.</li>
                        <li>All the techniques used by UX professionals</li>
                      </ul>
                    </div>
                  </div>
                  <h6>Requirements</h6>
                  <ul className="mb-0">
                    <li>
                      You will need a copy of Adobe XD 2019 or above. A free
                      trial can be downloaded from Adobe.
                    </li>
                    <li>No previous design experience is needed.</li>
                    <li className="mb-0">
                      No previous Adobe XD skills are needed.
                    </li>
                  </ul> */}
                  </div>
                </div>

                {/* /Overview */}
                {/* Course Content */}

                <div className="card content-sec">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <h5 className="subs-title">Course Content</h5>
                        <div
                          className=""
                          style={{
                            borderBottom: "2.3px solid #FF8D00",
                            width: "150px",
                            marginTop: "-8px",
                            marginLeft: "-4px",
                          }}
                        ></div>
                        <p style={{ marginTop: "10px" }}>Course Details</p>
                      </div>
                      <div className="col-sm-6 text-sm-end">
                        {/* <h6>92 Lectures 10:56:11</h6> */}
                      </div>
                    </div>

                    <div className="course-card">
                      <h6 className="cou-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#collapseOne"
                          aria-expanded={open}
                          onClick={() => setOpen(!open)}
                          aria-controls="example-collapse-text"
                        >
                          Test Series
                        </Link>
                      </h6>
                      <div id="collapseOne" className="card-collapse collapse">
                        {test?.length > 0 ? (
                          <Scrollbar style={{ height: 250 }}>
                            <table class="table table-borderless">
                              <thead>
                                <tr>
                                  <th scope="col">Title</th>
                                  <th scope="col">Questions</th>
                                  <th scope="col">Total Marks</th>
                                  <th scope="col">Duration</th>
                                </tr>
                              </thead>
                              <tbody>
                                {test?.length > 0 &&
                                  test?.map((item, i) => {
                                    return (
                                      <tr key={i} style={{}}>
                                        <td>
                                          <p>
                                            <img
                                              src={Icon1}
                                              alt=""
                                              className="me-2"
                                            />
                                            {item?.name}
                                          </p>
                                        </td>
                                        <td> {item?.totalQuestion}</td>
                                        <td> {item?.total_marks}</td>
                                        <td>
                                          {" "}
                                          {convertMinsToHrsMins(item?.duration)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </Scrollbar>
                        ) : (
                          <h6 style={{ padding: "20px" }}>
                            No Test Series for this Course
                          </h6>
                        )}
                        {/* <ul style={{marginRight:'1em'}}>
                            {test?.length > 0 &&
                              test?.map((item) => {
                                return (
                                  <li
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    <p
                                      style={{
                                        wordWrap: " break-word",
                                        width: "33.33%",
                                      }}
                                    >
                                      <img
                                        src={Icon1}
                                        alt=""
                                        className="me-2"
                                      />
                                      {item?.name}
                                    </p>
                                    <p
                                      style={{
                                        wordWrap: " break-word",
                                        width: "33.33%",
                                      }}
                                    >
                                      <span style={{ fontWeight: "bold" }}>
                                        Questions
                                      </span>{" "}
                                      {item?.totalQuestion}
                                    </p>
                                    <p
                                      style={{
                                        wordWrap: " break-word",
                                        width: "33.33%",
                                      }}
                                    >
                                      <span style={{ fontWeight: "bold" }}>
                                        Total Marks
                                      </span>{" "}
                                      {item?.total_marks}
                                    </p>
                                    <div>
                                      <span>
                                        {" "}
                                        {convertMinsToHrsMins(item?.duration)}
                                      </span>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul> */}
                      </div>
                    </div>

                    <h6 className="cou-title">
                      <Link
                        className="collapsed"
                        data-bs-toggle="collapse"
                        to="#course2"
                        aria-expanded={open2}
                        onClick={() => setOpen2(!open2)}
                        aria-controls="example-collapse-text"
                      >
                        Videos
                      </Link>
                    </h6>
                    <div id="course2" className="card-collapse collapse">
                      {" "}
                      {finalData?.length > 0 ? (
                        <Scrollbar style={{ height: 250 }}>
                          <div className="course-card">
                            <ul style={{ marginRight: "1em" }}>
                              {finalData.length > 0 &&
                                finalData.map((item) => {
                                  return (
                                    <li
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <p style={{ wordWrap: " break-word" }}>
                                        <img
                                          src={Play}
                                          alt=""
                                          className="me-2"
                                        />
                                        {item?.nameofvideo}
                                      </p>
                                      <div>
                                        {/* <Link>Preview</Link> */}
                                        <span> 13:55</span>
                                      </div>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </Scrollbar>
                      ) : (
                        <h6 style={{ padding: "20px" }}>
                          No Video for this Course
                        </h6>
                      )}
                    </div>
                  </div>
                </div>
                {/* /Course Content */}
                {/* Instructor */}
                {/* <div className="card instructor-sec">
                <div className="card-body">
                  <h5 className="subs-title">About the instructor</h5>
                  <div className="instructor-wrap">
                    <div className="about-instructor">
                      <div className="abt-instructor-img">
                        <Link to="/instructor-profile">
                          <img
                            src={User1}
                            alt="img"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="instructor-detail">
                        <h5>
                          <Link to="/instructor-profile">Nicole Brown</Link>
                        </h5>
                        <p>UX/UI Designer</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star me-1" />
                      <span className="d-inline-block average-rating">
                        4.5 Instructor Rating
                      </span>
                    </div>
                  </div>
                  <div className="course-info d-flex align-items-center">
                    <div className="cou-info">
                      <img src={Play} alt="" />
                      <p>5 Courses</p>
                    </div>
                    <div className="cou-info">
                      <img src={Icon1} alt="" />
                      <p>12+ Lesson</p>
                    </div>
                    <div className="cou-info">
                      <img src={Icon2} alt="" />
                      <p>9hr 30min</p>
                    </div>
                    <div className="cou-info">
                      <img src={People} alt="" />
                      <p>270,866 students enrolled</p>
                    </div>
                  </div>
                  <p>
                    UI/UX Designer, with 7+ Years Experience. Guarantee of High
                    Quality Work.
                  </p>
                  <p>
                    Skills: Web Design, UI Design, UX/UI Design, Mobile Design,
                    User Interface Design, Sketch, Photoshop, GUI, Html, Css,
                    Grid Systems, Typography, Minimal, Template, English,
                    Bootstrap, Responsive Web Design, Pixel Perfect, Graphic
                    Design, Corporate, Creative, Flat, Luxury and much more.
                  </p>
                  <p>Available for:</p>
                  <ul>
                    <li>1. Full Time Office Work</li>
                    <li>2. Remote Work</li>
                    <li>3. Freelance</li>
                    <li>4. Contract</li>
                    <li>5. Worldwide</li>
                  </ul>
                </div>
              </div> */}
                {/* /Instructor */}
                {/* Reviews */}
                {/* <div className="card review-sec">
                <div className="card-body">
                  <h5 className="subs-title">Reviews</h5>
                  <div className="instructor-wrap">
                    <div className="about-instructor">
                      <div className="abt-instructor-img">
                        <Link to="instructor-profile">
                          <img
                            src={User1}
                            alt="img"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="instructor-detail">
                        <h5>
                          <Link to="/instructor-profile">Nicole Brown</Link>
                        </h5>
                        <p>UX/UI Designer</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star me-1" />
                      <span className="d-inline-block average-rating">
                        4.5 Instructor Rating
                      </span>
                    </div>
                  </div>
                  <p className="rev-info">
                    “ This is the second Photoshop course I have completed with
                    Cristian. Worth every penny and recommend it highly. To get
                    the most out of this course, its best to to take the
                    Beginner to Advanced course first. The sound and video
                    quality is of a good standard. Thank you Cristian. “
                  </p>
                  <Link to="#" className=" btn-reply">
              
                    <FeatherIcon icon="corner-up-left"/>
                     Reply
                  </Link>
                </div>
              </div> */}
                {/* /Reviews */}
                {/* Comment */}
                {/* <div className="card comment-sec">
                <div className="card-body">
                  <h5 className="subs-title">Post A comment</h5>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Subject"
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        rows={4}
                        className="form-control"
                        placeholder="Your Comments"
                        defaultValue={""}
                      />
                    </div>
                    <div className="submit-section">
                      <button className=" submit-btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div> */}
                {/* /Comment */}
              </div>
              <div className="col-lg-4">
                <div className="sidebar-sec">
                  {/* Video */}
                  <div
                    className="video-sec vid-bg "
                    style={{
                      marginTop: "4rem",
                      // marginLeft: "1rem"
                    }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <Link
                        // to="https://www.youtube.com/embed/1trvO6dqQUI"
                        // className="video-thumbnail"
                        // data-fancybox=""
                        >
                          {/* <div className="play-icon">
                          <i className="fa-solid fa-play" />
                        </div> */}
                          {image === null ? (
                            <img
                              className="img-fluid"
                              id="img-grid"
                              // style={{ width: "330px", height: "200px" }}
                              src={Share}
                              alt=""
                            />
                          ) : (
                            <img
                              className="img-fluid"
                              id="img-grid"
                              //  style={{ width: "330px", height: "200px" }}
                              src={`${image}`}
                              alt=""
                            />
                          )}
                        </Link>
                        <div className="video-details">
                          {formattedAmount == "₹0"  ? (
                            <div className="plan-change"
                            >
                            <button
                              // target="_blank"
                              // href={`https://wa.me/9640671866?text=I%20need%20to%20buy%20this%20course%20${name}`}
                              onClick={() =>
                                // navigate(`/student/buy-course/${slug}`)
                               openNotificationWithIcon("info","Please Login","blue")
                              }
                              className="btn btn-enroll w-100 "

                              // style={{ height: "50px" }}
                            >
                              <span
                                className="button-text"
                                style={{ fontSize: "18px" }}
                              >
                                Buy Subscrption
                              </span>
                            </button>
                          </div>
                          ) : (
                            <div className="course-fee">
                              <div className="basic-plan">
                                <h2>{formattedAmount}</h2>
                              </div>
                            </div>
                          )}

                          <div className="row gx-2">
                            <div className="col-md-6 addHeart">
                              {/* <Link
                              to="/course-wishlist"
                              className=" btn btn-wish w-100"
                            > */}
                              {/* <i className="feather-heart" />  */}
                              {/* <FeatherIcon icon="heart"/>
                              Add to Wishlist
                            </Link> */}
                            </div>
                            {/* <div className="col-md-6 addHeart">
                            <Link
                              to="#"
                              className="btn btn-wish w-100"
                            > */}
                            {/* <i className="feather-share-2" />  */}
                            {/* <FeatherIcon icon="share-2"/>
                              
                              Share
                            </Link>
                          </div> */}
                          </div>
                          {/* <Link
                          to="checkout"
                          className="btn btn-enroll w-100"
                        >
                          Buy Now
                        </Link> */}
                          {formattedAmount == "₹0" ? (
                            ""
                          ) : (
                            <div className="plan-change">
                              <button  className="btn btn-enroll w-100 "
                              // disabled={subscrption!==null}
                              onClick={() =>
                                // navigate(`/student/buy-course/${slug}`)
                               openNotificationWithIcon("info","Please Login","blue")
                              }
                              >
                              {/* <a
                                target="_blank"
                                href={`https://wa.me/9640671866?text=I%20need%20to%20buy%20this%20course%20${name}`}
                                // onClick={() =>
                                //   navigate(`/student/buy-course/${slug}`)
                                // }
                                

                               
                                // style={{ height: "50px" }}
                              > */}
                                <span
                                  className="button-text"
                                  style={{ fontSize: "18px",color:"white" }}
                                >
                                  {"Buy Now"}
                                </span>
                              {/* </a> */}
                              </button>
                            
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Video */}
                  {/* Include */}
                  {/* <div className="card include-sec">
                  <div className="card-body">
                    <div className="cat-title">
                      <h4>Includes</h4>
                    </div>
                    <ul>
                      <li>
                        <img src={Import} className="me-2" alt="" /> 11 hours
                        on-demand video
                      </li>
                      <li>
                        <img src={Play} className="me-2" alt="" /> 69
                        downloadable resources
                      </li>
                      <li>
                        <img src={Key} className="me-2" alt="" /> Full lifetime
                        access
                      </li>
                      <li>
                        <img src={Mobile} className="me-2" alt="" /> Access on
                        mobile and TV
                      </li>
                      <li>
                        <img src={Cloud} className="me-2" alt="" /> Assignments
                      </li>
                      <li>
                        <img src={Teacher} className="me-2" alt="" />{" "}
                        Certificate of Completion
                      </li>
                    </ul>
                  </div>
                </div> */}
                  {/* /Include */}
                  {/* Features */}
                  <div className="card feature-sec">
                    <div className="card-body">
                      <div className="cat-title">
                        <h4>Includes</h4>
                      </div>
                      <ul>
                        {/* <li>
                        <img src={Users} className="me-2" alt="" /> Enrolled:{" "}
                        <span>32 students</span>
                      </li>
                      <li>
                        <img src={Timer2} className="me-2" alt="" /> Duration:{" "}
                        <span>20 hours</span>
                      </li> */}
                        <li>
                          <img src={Chapter} className="me-2" alt="" /> Test:{" "}
                          <span>{test?.length > 0 ? test?.length : "0"}</span>
                        </li>
                        <li>
                          <img src={Video2} className="me-2" alt="" /> Video:
                          <span>
                            {" "}
                            {finalData?.length > 0 ? finalData.length : "0"}
                          </span>
                        </li>
                        {/* <li>
                        <img src={Chart} className="me-2" alt="" /> Level:{" "}
                        <span>Beginner</span>
                      </li> */}
                      </ul>
                    </div>
                  </div>
                  {/* /Features */}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default DetailsContent;
