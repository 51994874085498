import React, { useEffect, useState } from "react";
import StudentHeader from "../../student/studentHeader";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

// import "./index.css";
import { Checkbox, ConfigProvider, Tabs, Tooltip } from "antd";
// import Result from "./Result";
import { useParams } from "react-router";
import axios from "../../../api/axios";
import { LogOut, Moon, Star, User, Delete, Download } from "react-feather";
import { notification, Modal } from "antd";
const RESULTPDF = "/api/student/test-result-analytics/byuser";
import scorecard from "../../../assets/img/Dashboard/Scorecard.png";
import Accuracyimg from "../../../assets/img/Dashboard/Accuracy.png";
import Chart from "react-apexcharts";
import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import Weightage from "../../../assets/img/Dashboard/weightage.png";

const StudentTestMultiResultDownload = () => {
  // console.log(userid,"userid",attempt)
  const navigate = useNavigate();

  const { id, userid, attempt } = useParams();
  // const [attempt, setattempt] = useState("");
  const token = localStorage.getItem("tokenStudent");
  // const userid = localStorage.getItem("userid");
  const [result, setResult] = useState([]);
  const [subjectdata, setSubjectData] = useState([]);
  const [attemptedques, setAttemptedques] = useState();
  const [statusresult, setStatusresult] = useState();
  const [openAccordions, setOpenAccordions] = useState({});
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [videoid, setvideoid] = useState([]);
  const handelvimeovideo = (link) => {
    setvideoid(link);
    handleShowForm();
  };

  const [isModalVisibleForm1, setIsModalVisibleForm1] = useState(false);
  const [cheked, setChecked] = useState([]);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [selectedButton, setSelectedButton] = useState(0);
  const handleClick = (index) => {
    // console.log(index, "index");
    setSelectedButton(index);
  };
  const handleModalDeleteClose = () => {
    setIsModalVisibleForm1(false);
  };
  const handleShowForm1 = () => {
    setIsModalVisibleForm1(true);
  };
  const handleCreate = () => {
    handleShowForm1();
  };
  const onChange = (e) => {
    setChecked(e.target.checked);
    // console.log(`checked = ${e.target.checked}`);
  };
  const ResultDownlaod = async () => {
    try {
      //   setLoading(true);
      const responce = await axios.get(
        `${RESULTPDF}/${userid}/${id}/${attempt}`
      );
      //   console.log(responce?.data, "response");
      //   setInvoiceData(resonse?.data);
      setResult(responce?.data);
      setStatusresult(responce?.status);
      // setAttemptedques(responce?.data?.totalQuestion
      //   -(responce?.data?.correct+responce?.data?.wrong))
      setAttemptedques(
        responce?.data?.totalQuestion - responce?.data?.leaveQuestion
      );
      const subjectWiseData = responce?.data?.subject?.map((subject) => {
        const subjectResult = responce?.data?.subjectResult?.find(
          (result) => result.name === subject
        );
        const wrongQuestions = responce?.data?.wrongQuestions?.filter(
          (question) => question.subject === subject
        );
        const unattemptedQuestios = responce?.data?.leaveQuestions?.filter(
          (question) => question?.subject === subject
        );
        const uniqueID = Math.random().toString(36).substr(2, 9);

        return {
          subjectResult: {
            ...subjectResult,
            attemptedquestions: subjectResult?.correct + subjectResult?.wrong,
            unattemptedQuestios1:
              subjectResult?.count -
              (subjectResult?.correct + subjectResult?.wrong),
            id: uniqueID,
            wrongansquestions: wrongQuestions,
            unattemptedQuestios: unattemptedQuestios,
            negativeMark: responce?.data?.negativeMark,
          },
        };
      });

      const finalData = {
        subjectWiseData,
      };
      setSubjectData(finalData);
      //   setLoading(false);
    } catch (error) {
      console.log(error);
      //   if (error?.response?.status === 401) {
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("showNotification")

      //     navigate("/login");
      //   }
      // openNotificationWithIcon2("error");
    }
  };
  useEffect(() => {
    ResultDownlaod();
  }, []);
  console.log(subjectdata, "subject");
  const chartOptions = {
    series: [result?.totalAttempt, result?.leaveQuestion],

    chart: {
      width: "100%",
      type: "donut",
      // height:"100%"
    },
    labels: ["Attempted", "Unattempted"],
    colors: ["#6fdc6f", "#66b0e5"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'dark',
    //     type: 'vertical',
    //     shadeIntensity: 0.5,
    //     inverseColors: false,
    //     opacityFrom: 1,
    //     opacityTo: 0.8,
    //     stops: [0, 100]
    //   }
    // }
  };
  const chartOptions1 = {
    series: [result?.correctAnswer, result?.wrongAnswer],
    chart: {
      width: "100%",
      type: "pie",
      //  height:"100%"
    },
    labels: ["Correct Answers", "Wrong Answers"],
    colors: ["#6fdc6f", "#f15b5b"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'dark',
    //     type: 'vertical',
    //     shadeIntensity: 0.5,
    //     inverseColors: false,
    //     opacityFrom: 1,
    //     opacityTo: 0.8,
    //     stops: [0, 100]
    //   }
    // }
  };
  const [accordionOpen, setAccordionOpen] = useState(true);

  useEffect(() => {
    // This effect runs once when the component mounts
    // Set accordionOpen to true to ensure all accordion items are open by default
    setAccordionOpen(true);
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <>
      <div className="main-wrapper">
        {/* BreadCrumb */}
        <div className="breadcrumb-bar p-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="container" style={{ marginTop: "20px" }}>
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-md-5 d-flex">
              <div
                className="card instructor-card w-100"
                style={{
                  border: "none",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className="card-body d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="instructor-inner">
                    <h6 style={{ color: "black", fontWeight: 700 }}>
                      Scored Marks
                    </h6>
                    <h4
                      className="instructor-text-success"
                      style={{ fontWeight: 800 }}
                    >
                      {result?.totalMarks >= 0
                        ? (result?.totalMarks).toFixed(2)
                        : result?.totalMarks}
                    </h4>
                  </div>
                  <div className="instructo-inner">
                    <img src={scorecard} alt="image" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 d-flex">
              <div
                className="card instructor-card w-100"
                style={{
                  border: "none",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className="card-body d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="instructor-inner">
                    <h6 style={{ color: "black", fontWeight: 700 }}>
                      Accuracy
                    </h6>
                    <h4
                      className="instructor-text-info"
                      style={{ color: "#f00e21", fontWeight: 800 }}
                    >
                      {(
                        (result?.correctAnswer / result?.totalAttempt) *
                        100
                      ).toFixed(2) >= 0
                        ? (
                            (result?.correctAnswer / result?.totalAttempt) *
                            100
                          ).toFixed(2)
                        : 0}
                      %
                    </h4>
                  </div>
                  <div className="instructo-inner">
                    <img src={Accuracyimg} alt="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-md-5">
              <div
                className="settings-widget krishna1"
                style={{
                  border: "none",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className="settings-inner-blk"
                  //  style={{borderBottom:"none",backgroundColor:"#cbd9e6"}}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h4
                      style={{
                        marginLeft: "20px",
                        marginTop: "15px",
                        color: " #392C7D",
                        fontSize: "19px",
                      }}
                    >
                      Total quesions : {result?.totalQuestion}
                    </h4>
                    <h4
                      style={{
                        marginLeft: "20px",
                        marginTop: "15px",
                        color: " #392C7D",
                        marginRight: "30px",
                        fontSize: "19px",
                      }}
                    >
                      Total Marks :
                      {result?.totalQuestion * result?.eachQuestionMark}
                    </h4>
                  </div>

                  <div className="sell-course-head comman-space graph2">
                    <div className="chart">
                      <Chart
                        options={chartOptions}
                        series={chartOptions.series}
                        type={chartOptions?.chart?.type}
                        width={chartOptions.chart.width}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div
                className="settings-widget krishna2"
                style={{
                  border: "none",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className="settings-inner-blk p-0"
                  //  style={{borderBottom:"none",backgroundColor:"rgb(197 236 190 / 39%)"}}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h4
                      style={{
                        marginLeft: "20px",
                        marginTop: "15px",
                        color: "#392C7D",
                        fontSize: "19px",
                      }}
                    >
                      Positive Marks :
                      {(
                        result?.eachQuestionMark * result?.correctAnswer
                      ).toFixed(2)}
                    </h4>
                    <h4
                      style={{
                        marginLeft: "20px",
                        marginTop: "15px",
                        color: "#392C7D",
                        marginRight: "30px",
                        fontSize: "19px",
                      }}
                    >
                      Negative Marks :{" "}
                      {(result?.negativeMark * result?.wrongAnswer).toFixed(2)}
                    </h4>
                  </div>

                  <div className="sell-course-head comman-space graph2">
                    <div className="chart">
                      <Chart
                        options={chartOptions1}
                        series={chartOptions1.series}
                        type={chartOptions1?.chart?.type}
                        width={chartOptions1.chart.width}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12" style={{ justifyContent: "center" }}>
              <h3 style={{ marginLeft: "120px" }}>SubjectWise Analysis</h3>
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-lg-10">
                  {subjectdata?.subjectWiseData?.length > 0
                    ? subjectdata?.subjectWiseData?.map((item, index) => {
                        //   console.log(item,"item")

                        const seriesData = [
                          item?.subjectResult?.attemptedquestions,
                          item?.subjectResult?.unattemptedQuestios1,
                        ];
                        const seriesData1 = [
                          item?.subjectResult?.correct,
                          item?.subjectResult?.wrong,
                        ];

                        const parser = new DOMParser();
                        const doc = parser.parseFromString(
                          item?.description,
                          "text/html"
                        );

                        // Get the text content
                        const textContent = doc.body.textContent;

                        // Find the image element and get its source
                        const imageElement = doc.body.querySelector("img");
                        const imageUrl = imageElement ? imageElement.src : "";
                        const wrongOpenAccordion =
                          openAccordions[`wrongAccordion${index}`] || null;
                        const unattemptedOpenAccordion =
                          openAccordions[`unattemptedAccordion${index}`] ||
                          null;
                        const neg = item?.wrong * item?.negativeMark;
                        // console.log(neg,"nge")
                        return (
                          <>
                            <div
                              className=""
                              style={{
                                background: " #FFFFFF",
                                borderRadius: "10px",
                                padding: "20px",
                                marginBottom: "25px",
                                border: "1px solid #e9ecef",
                              }}
                            >
                              <h4
                                style={{
                                  color: "rgb(57, 44, 125)",
                                  textTransform: "capitalize",
                                }}
                              >
                                Subject : {item?.subjectResult?.name}
                              </h4>
                              <div
                                className="row d-flex"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-md-4 ">
                                  <div>
                                    <div
                                      className="card instructor-card w-100"
                                      style={{
                                        border: "none",
                                        boxShadow:
                                          "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                      }}
                                    >
                                      <div
                                        className="card-body d-flex"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="instructor-inner">
                                          <h6
                                            style={{
                                              color: "black",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Score
                                          </h6>
                                          <h4
                                            className="instructor-text-info"
                                            style={{
                                              color: "red",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {(
                                              item?.subjectResult?.correct *
                                              result?.eachQuestionMark
                                            ).toFixed(2) -
                                              item?.subjectResult?.wrong *
                                                item?.subjectResult
                                                  ?.negativeMark}
                                          </h4>
                                        </div>
                                        <div className="instructor-inner">
                                          <img src={scorecard} alt="image" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 ">
                                  <div>
                                    <div
                                      className="card instructor-card w-100"
                                      style={{
                                        border: "none",
                                        boxShadow:
                                          "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                      }}
                                    >
                                      <div
                                        className="card-body d-flex"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="instructor-inner">
                                          <h6
                                            style={{
                                              color: "black",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Accuracy
                                          </h6>
                                          <h4
                                            className="instructor-text-success"
                                            style={{
                                              color: "red",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {(
                                              (item?.subjectResult?.correct /
                                                item?.subjectResult
                                                  ?.attemptedquestions) *
                                              100
                                            ).toFixed(2) >= 0
                                              ? (
                                                  (item?.subjectResult
                                                    ?.correct /
                                                    item?.subjectResult
                                                      ?.attemptedquestions) *
                                                  100
                                                ).toFixed(2)
                                              : 0}
                                            %
                                          </h4>
                                        </div>
                                        <div className="instructor-inner">
                                          <img src={Accuracyimg} alt="image" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 ">
                                  <div>
                                    <div
                                      className="card instructor-card w-100"
                                      style={{
                                        border: "none",
                                        boxShadow:
                                          "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                      }}
                                    >
                                      <div
                                        className="card-body d-flex"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="instructor-inner">
                                          <h6
                                            style={{
                                              color: "black",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Weightage
                                          </h6>
                                          <h4
                                            className="instructor-text-warning"
                                            style={{
                                              color: "red",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {(
                                              (item?.subjectResult?.count /
                                                result?.totalQuestion) *
                                              100
                                            ).toFixed(2) >= 0
                                              ? (
                                                  (item?.subjectResult?.count /
                                                    result?.totalQuestion) *
                                                  100
                                                ).toFixed(2)
                                              : 0}
                                            %
                                          </h4>
                                        </div>
                                        <div className="instructor-inner">
                                          <img src={Weightage} alt="image" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="clo-lg-10">
                                  <div
                                    className=""
                                    style={{ paddingBottom: "20px" }}
                                  >
                                    <div className="settings-inner-blk ">
                                      <div
                                        className="row"
                                        style={{
                                          justifyContent: "center",
                                          padding: "10px",
                                          //   border:"1px solid red"
                                        }}
                                      >
                                        <div className="col-lg-6">
                                          <div
                                            className="settings-widget "
                                            style={{
                                              border: "none",
                                              boxShadow:
                                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                            }}
                                          >
                                            <div
                                              className="settings-inner-blk p-0"
                                              // style={{
                                              //   borderBottom: "none",
                                              //   backgroundColor: "#cbd9e6",
                                              // }}
                                            >
                                              <div
                                                className="d-flex"
                                                style={{
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <h4
                                                  style={{
                                                    marginLeft: "20px",
                                                    marginTop: "15px",
                                                    color: "#392C7D",
                                                    fontSize: "19px",
                                                  }}
                                                >
                                                  Total Questions :{" "}
                                                  {item?.subjectResult?.count}
                                                </h4>
                                                <h4
                                                  style={{
                                                    marginLeft: "20px",
                                                    marginTop: "15px",
                                                    color: "#392C7D",
                                                    marginRight: "30px",
                                                    fontSize: "19px",
                                                  }}
                                                >
                                                  Total Marks :{" "}
                                                  {(
                                                    result?.eachQuestionMark *
                                                    item?.subjectResult?.count
                                                  ).toFixed(2)}
                                                </h4>
                                              </div>

                                              <div
                                                className="sell-course-head comman-space graph1"
                                                //   style={{ height: "265px" }}
                                              >
                                                <div className="chart">
                                                  <Chart
                                                    options={chartOptions}
                                                    series={seriesData}
                                                    type={
                                                      chartOptions?.chart?.type
                                                    }
                                                    width={
                                                      chartOptions.chart.width
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div
                                            className="settings-widget"
                                            style={{
                                              border: "none",
                                              boxShadow:
                                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                            }}
                                          >
                                            <div
                                              className="settings-inner-blk p-0"
                                              style={{
                                                borderBottom: "none",
                                                // backgroundColor: "rgb(240 222 229)",
                                              }}
                                            >
                                              <div
                                                className="d-flex"
                                                style={{
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <h4
                                                  style={{
                                                    marginLeft: "20px",
                                                    marginTop: "15px",
                                                    color: "#392C7D",
                                                    paddingLeft: "5px",
                                                    fontSize: "19px",
                                                  }}
                                                >
                                                  Positive Marks :{" "}
                                                  {(
                                                    result?.eachQuestionMark *
                                                    item?.subjectResult?.correct
                                                  ).toFixed(2)}
                                                </h4>
                                                <h4
                                                  style={{
                                                    // marginLeft: "20px",
                                                    marginTop: "15px",
                                                    color: "#392C7D",
                                                    paddingRight: "5px",
                                                    fontSize: "19px",

                                                    marginRight: "20px",
                                                  }}
                                                >
                                                  Negative Marks :{" "}
                                                  {(
                                                    result?.negativeMark *
                                                    item?.subjectResult?.wrong
                                                  ).toFixed(2)}
                                                </h4>
                                              </div>

                                              <div
                                                className="sell-course-head comman-space graph1 "
                                                // style={{ height: "265px" }}
                                              >
                                                <div className="chart">
                                                  <Chart
                                                    options={chartOptions1}
                                                    series={seriesData1}
                                                    type={
                                                      chartOptions1?.chart?.type
                                                    }
                                                    width={
                                                      chartOptions1?.chart
                                                        ?.width
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div></div>
                                    </div>
                                  </div>
                                </div>
                                {item?.subjectResult?.wrongansquestions
                                  ?.length > 0 ? (
                                  <div
                                    className="clo-lg-12"
                                    style={{ marginTop: "-10px" }}
                                  >
                                    <h4
                                      style={{ padding: "10px", color: "red" }}
                                    >
                                      Wrong Question Analysis
                                    </h4>
                                    {item?.subjectResult?.wrongansquestions.map(
                                      (value, index1) => {
                                        const parser = new DOMParser();
                                        const doc = parser.parseFromString(
                                          value.description,
                                          "text/html"
                                        );
                                        const doc1 = parser.parseFromString(
                                          value?.question,
                                          "text/html"
                                        );
                                        const questionContent =
                                          doc1.body.textContent;

                                        // Get the text content
                                        const textContent =
                                          doc.body.textContent;

                                        // Find the image element and get its source
                                        const imageElement =
                                          doc.body.querySelector("img");
                                        const imageUrl = imageElement
                                          ? imageElement.src
                                          : "";
                                        console.log(imageUrl, "url");
                                        const isOpen =
                                          wrongOpenAccordion === index1;
                                        return (
                                          <>
                                            <div style={{ marginTop: "20px" }}>
                                              <div
                                                className="accordion"
                                                id={`wrongAccordionExample${index}-${index1}`}
                                                key={index1}
                                                style={{ padding: "0px 20px" }}
                                              >
                                                <div className="accordion-item"
                                                style={{border:'none'}}
                                                >
                                                  <h2 className="accordion-header d-flex">
                                                    <button
                                                      className={`accordion-button collapsed ${
                                                        isOpen ? "show" : ""
                                                      }`}
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target={`#wrongCollapseOne${index}-${index1}`}
                                                      aria-expanded="true"
                                                      aria-controls={`wrongCollapseOne${index}-${index1}`}
                                                      style={{
                                                        backgroundColor:
                                                          "#e6e6e6",
                                                        color: "black",
                                                      }}
                                                      // onClick={() => {
                                                      //   setOpenAccordions({
                                                      //     ...openAccordions,
                                                      //     [`wrongAccordion${index}`]:
                                                      //       index1,
                                                      //     [`unattemptedAccordion${index}`]:
                                                      //       null, // Close unattempted accordion
                                                      //   });
                                                      // }}
                                                      onClick={() =>
                                                        setAccordionOpen(
                                                          !accordionOpen
                                                        )
                                                      }
                                                    >
                                                      {/* Q: <b>{index+1}</b>{")"}  */}
                                                      Q{index + 1}
                                    
                                    {")"}{" "}   {questionContent}
                                                      {/* <span style={{ textAlign: "center" }}>Physiscs</span> */}
                                                    </button>
                                                  </h2>
                                                  <div
                                                    id={`wrongCollapseOne${index}-${index1}`}
                                                    className={`accordion-collapse collapse ${
                                                      accordionOpen
                                                        ? "show"
                                                        : ""
                                                    }`}
                                                    data-bs-parent={`#wrongAccordionExample${index}-${index1}`}
                                                  >
                                                    <div className="accordion-body">
                                                      <div
                                                        className="settings-widget profile-details"
                                                        style={{
                                                          border: "none",
                                                        }}
                                                      >
                                                        <div className="settings-menu p-0">
                                                          <div className="profile-heading">
                                                            <p
                                                              style={{
                                                                color: "black",
                                                                fontSize:
                                                                  "24px",
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              Subject :{" "}
                                                              {value?.subject}
                                                            </p>
                                                          </div>
                                                          <div
                                                            className="address-check-widget comman-space"
                                                            style={{
                                                              padding:
                                                                "10px 25px",
                                                            }}
                                                          >
                                                            <div className="check-bill-address">
                                                              <div
                                                                className="edit-new-address wallet-method wallet-radio-blk d-flex align-items-center"
                                                                style={{
                                                                  marginBottom:
                                                                    "5px",
                                                                }}
                                                              >
                                                                <label className="">
                                                                  <h6>
                                                                    Wrong Answer
                                                                    :
                                                                  </h6>
                                                                  <CloseCircleOutlined
                                                                    twoToneColor="#FF0000"
                                                                    style={{
                                                                      fontSize:
                                                                        "20px",
                                                                      color:
                                                                        "red",
                                                                    }}
                                                                  />

                                                                  <span
                                                                    className="checkmark"
                                                                    style={{
                                                                      fontSize:
                                                                        "18px",
                                                                      marginLeft:
                                                                        "7px",
                                                                      marginTop:
                                                                        "2px",
                                                                    }}
                                                                  >
                                                                    {
                                                                      value?.your_answer
                                                                    }
                                                                  </span>
                                                                </label>
                                                              </div>
                                                              <div
                                                                className="edit-new-address wallet-method wallet-radio-blk d-flex align-items-center mb-0"
                                                                style={{
                                                                  marginBottom:
                                                                    "5px",
                                                                }}
                                                              >
                                                                <label className="">
                                                                  <h6>
                                                                    Correct
                                                                    Answer :
                                                                  </h6>
                                                                  <CheckCircleTwoTone
                                                                    twoToneColor="#52c41a"
                                                                    style={{
                                                                      fontSize:
                                                                        "20px",
                                                                    }}
                                                                  />

                                                                  <span
                                                                    className="checkmark"
                                                                    style={{
                                                                      fontSize:
                                                                        "18px",
                                                                      marginLeft:
                                                                        "7px",
                                                                      marginTop:
                                                                        "2px",
                                                                    }}
                                                                  >
                                                                    {
                                                                      value?.answer
                                                                    }
                                                                  </span>
                                                                </label>
                                                              </div>
                                                              {value?.description ? (
                                                                <div
                                                                  className="add-new-address grad-border hvr-sweep-to-right"
                                                                  style={{
                                                                    marginTop:
                                                                      "5px",
                                                                  }}
                                                                >
                                                                  <h4
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                      textTransform:
                                                                        "capitalize",
                                                                      fontSize:
                                                                        "20px",
                                                                    }}
                                                                  >
                                                                    Descrption{" "}
                                                                    {" : "}
                                                                  </h4>

                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "rgb(134, 134, 134)",
                                                                      fontSize:
                                                                        "24px",
                                                                    }}
                                                                  >
                                                                    {
                                                                      textContent
                                                                    }
                                                                  </p>
                                                                  {imageUrl && (
                                                                    <img
                                                                      src={
                                                                        imageUrl
                                                                      }
                                                                      style={{width:"70%"}}
                                                                      alt="image"
                                                                    />
                                                                  )}
                                                                </div>
                                                              ) : (
                                                                ""
                                                              )}

                                                              {value?.explaination ? (
                                                                <div
                                                                  className="add-new-address grad-border hvr-sweep-to-right"
                                                                  style={{
                                                                    marginTop:
                                                                      "5px",
                                                                  }}
                                                                >
                                                                  <h6>
                                                                    Solution :{" "}
                                                                  </h6>
                                                                  <div
                                                                    dangerouslySetInnerHTML={{
                                                                      __html:
                                                                        value?.explaination,
                                                                    }}
                                                                  />
                                                                </div>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                {item?.subjectResult?.unattemptedQuestios
                                  ?.length > 0 ? (
                                  <div className="col-lg-12">
                                    <h4
                                      style={{
                                        padding: "10px",
                                        color: "#8c8892",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Unattempted Question Analysis
                                    </h4>
                                    {item?.subjectResult?.unattemptedQuestios.map(
                                      (value, index1) => {
                                        const parser = new DOMParser();
                                        const doc = parser.parseFromString(
                                          value.description,
                                          "text/html"
                                        );
                                        const doc1 = parser.parseFromString(
                                          value?.question,
                                          "text/html"
                                        );
                                        const questionContent =
                                          doc1.body.textContent;
                                        // Get the text content
                                        const textContent =
                                          doc.body.textContent;

                                        // Find the image element and get its source
                                        const imageElement =
                                          doc.body.querySelector("img");
                                        const imageUrl = imageElement
                                          ? imageElement.src
                                          : "";
                                        console.log(imageUrl, "url");

                                        const isOpen =
                                          unattemptedOpenAccordion === index1;
                                        return (
                                          <>
                                            <div style={{ marginTop: "20px" }}>
                                              <div
                                                className="accordion"
                                                id={`unattemptedAccordionExample${index}-${index1}`}
                                                key={index1}
                                                style={{ padding: "0px 20px" }}
                                              >
                                                <div className="accordion-item"
                                                 style={{border:'none'}}
                                                >
                                                  <h2 className="accordion-header d-flex">
                                                    <button
                                                      className={`accordion-button collapsed ${
                                                        isOpen ? "show" : ""
                                                      }`}
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target={`#unattemptedCollapseOne${index}-${index1}`}
                                                      aria-expanded="true"
                                                      aria-controls={`unattemptedCollapseOne${index}-${index1}`}
                                                      style={{
                                                        backgroundColor:
                                                          "#e6e6e6",
                                                        color: "black",
                                                      }}
                                                      // onClick={() => {
                                                      //   setOpenAccordions({
                                                      //     ...openAccordions,
                                                      //     [`unattemptedAccordion${index}`]:
                                                      //       index1,
                                                      //     [`wrongAccordion${index}`]:
                                                      //       null, // Close wrong accordion
                                                      //   });
                                                      // }}
                                                      onClick={() =>
                                                        setAccordionOpen(
                                                          !accordionOpen
                                                        )
                                                      }
                                                    >  Q{index + 1}
                                    
                                                    {")"}{" "}
                                                      {questionContent}
                                                      {/* <span style={{ textAlign: "center" }}>Physiscs</span> */}
                                                    </button>
                                                  </h2>
                                                  <div
                                                    id={`unattemptedCollapseOne${index}-${index1}`}
                                                    className={`accordion-collapse collapse ${
                                                      accordionOpen
                                                        ? "show"
                                                        : ""
                                                    }`}
                                                    data-bs-parent={`#unattemptedAccordionExample${index}-${index1}`}
                                                  >
                                                    <div className="accordion-body">
                                                      <div className="settings-widget profile-details"
                                                       style={{border:'none'}}
                                                      >
                                                        <div className="settings-menu p-0"
                                                         style={{border:'none'}}
                                                        >
                                                          <div className="profile-heading"
                                                           style={{border:'none'}}
                                                          >
                                                            <p
                                                              style={{
                                                                color: "black",
                                                                fontSize:
                                                                  "24px",
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              Subject :{" "}
                                                              {value?.subject}
                                                            </p>
                                                          </div>
                                                          <div
                                                            className="address-check-widget comman-space"
                                                            style={{
                                                              padding:
                                                                "10px 25px",
                                                            }}
                                                          >
                                                            <div className="check-bill-address">
                                                              <div
                                                                className="edit-new-address wallet-method wallet-radio-blk d-flex align-items-center mb-0"
                                                                style={{
                                                                  marginBottom:
                                                                    "5px",
                                                                }}
                                                              >
                                                                <label className="">
                                                                  <h6>
                                                                    Coreect
                                                                    Answer{" "}
                                                                  </h6>
                                                                  <CheckCircleTwoTone
                                                                    twoToneColor="#52c41a"
                                                                    style={{
                                                                      fontSize:
                                                                        "20px",
                                                                    }}
                                                                  />

                                                                  <span
                                                                    className="checkmark"
                                                                    style={{
                                                                      fontSize:
                                                                        "18px",
                                                                      marginLeft:
                                                                        "7px",
                                                                      marginTop:
                                                                        "2px",
                                                                    }}
                                                                  >
                                                                    {
                                                                      value?.answer
                                                                    }
                                                                  </span>
                                                                </label>
                                                              </div>
                                                              {value?.description ? (
                                                                <div
                                                                  className="add-new-address grad-border hvr-sweep-to-right"
                                                                  style={{
                                                                    marginTop:
                                                                      "5px",
                                                                  }}
                                                                >
                                                                  <h4
                                                                    style={{
                                                                      color:
                                                                        "black",
                                                                      textTransform:
                                                                        "capitalize",
                                                                      fontSize:
                                                                        "20px",
                                                                    }}
                                                                  >
                                                                    Descrption{" "}
                                                                    {" : "}
                                                                  </h4>
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "rgb(134, 134, 134)",
                                                                      fontSize:
                                                                        "24px",
                                                                    }}
                                                                  >
                                                                    {
                                                                      textContent
                                                                    }
                                                                  </p>
                                                                  {imageUrl && (
                                                                    <img
                                                                      src={
                                                                        imageUrl
                                                                      }
                                                                      style={{width:"70%"}}
                                                                      alt="image"
                                                                    />
                                                                  )}
                                                                </div>
                                                              ) : (
                                                                ""
                                                              )}

                                                              {value?.explaination ? (
                                                                <div
                                                                  className="add-new-address grad-border hvr-sweep-to-right"
                                                                  style={{
                                                                    marginTop:
                                                                      "5px",
                                                                  }}
                                                                >
                                                                  <h6>
                                                                    Solution :{" "}
                                                                  </h6>
                                                                  <div
                                                                    dangerouslySetInnerHTML={{
                                                                      __html:
                                                                        value?.explaination,
                                                                    }}
                                                                  />
                                                                </div>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentTestMultiResultDownload;
