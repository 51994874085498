// import React, { useContext, useEffect, useState } from "react";

// import Footer from "../../footer";
// import { InstructorHeader } from "../../instructor/header";
// import {
//   InstructorProfileBg,
//   ReferImage1,
//   ReferImage2,
//   ReferImage3,
//   ReferImage4,
//   ReferImage5,
//   User15,
// } from "../../imagepath";
// import { Link, useNavigate } from "react-router-dom";
// import AdminInstructorSidebar from "../../instructor/adminSidebar";
// import FeatherIcon from "feather-icons-react";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import Switch from "react-switch";
// import axios from "../../../api/axios";
// import AuthContext from "../../../context/AuthProvider";
// const STUDENTLIST = "/api/view-student";
// export default function AdminInstructorFeedList() {
//   const [show, setShow] = useState(false);
//   const [list, setList] = useState(null);
//   const [checked, setChecked] = useState(false);
//   const { auth } = useContext(AuthContext);
//   const config = {
//     headers: { Authorization: `Bearer ${auth.token}` },
//   };
//   const handleChange = (nextChecked) => {
//     setChecked(nextChecked);
//   };

//   useEffect(() => {
//     const fecthSudentList = async () => {
//       const resonse = await axios.get(STUDENTLIST, config);
//       console.log(resonse);
//       setList(resonse.data);
//     };
//     fecthSudentList();
//   }, []);
//   console.log(list);
//   // fecthSudentList()
//   console.log("token", auth.token);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const navigate = useNavigate();
//   const handleEdit = () => {
//     navigate("/admin/edituser");
//   };
//   return (
//     <div className="main-wrapper">
//       {/* <InstructorHeader /> */}
//       <div className="page-content">
//         <div className="container">
//           <div className="row">
//             {/* Sidebar */}
//             <AdminInstructorSidebar activeMenu={"StudentList"} />
//             {/* Sidebar */}

//             {/* Instructor Dashboard */}
//             <div className="col-xl-9 col-lg-8 col-md-12">
//               <div className="tak-instruct-group">
//                 <div className="row">
//                   <div className="col-md-12">
//                     <div className="settings-widget">
//                       <div className="settings-inner-blk p-0">
//                         <div className="sell-course-head student-meet-head comman-space border-0">
//                           <div className="tak-head d-flex align-items-center">
//                             <div>
//                               <h3>Students</h3>
//                               <p>Meet people taking your course.</p>
//                             </div>
//                             <div className="view-icons">
//                               <Link
//                                 to="/admin/instructor-student-grid"
//                                 className="grid-view"
//                               >
//                                 {/* <i className="feather-grid"></i> */}
//                                 <FeatherIcon icon="grid" />
//                               </Link>
//                               <Link
//                                 to="/admin/instructor-student-list"
//                                 className="list-view active"
//                               >
//                                 <FeatherIcon icon="list" />
//                               </Link>
//                             </div>
//                           </div>
//                           <form>
//                             <div className="student-search-form d-flex ">
//                               {/* <div className="form-group mb-0">
//                                 <input
//                                   type="text"
//                                   className="form-control"
//                                   placeholder="Twitter Profile Name"
//                                 />
//                               </div> */}
//                               <div className="export-cvv-btn">
//                                 <Link
//                                   to="/admin/adduser"
//                                   className="btn btn-primary"
//                                 >
//                                   Add Student
//                                 </Link>
//                                 {/* Export CSV */}
//                               </div>
//                               <div className="profile-share d-flex align-items-center justify-content-center">
//                                 <Link to="#;" className="btn btn-success">
//                                   Export Excel
//                                 </Link>
//                                 <Link to="#;" className="btn btn-danger">
//                                   Import Excel
//                                 </Link>
//                               </div>
//                             </div>
//                           </form>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="settings-widget">
//                       <div className="settings-inner-blk p-0">
//                         <div className="comman-space pb-0">
//                           <div className="settings-referral-blk table-responsive">
//                             {/* Instructor Users */}
//                             <Modal show={show} onHide={handleClose}>
//                               <Modal.Header closeButton>
//                                 <Modal.Title>Delete Student</Modal.Title>
//                               </Modal.Header>
//                               <Modal.Body>
//                                 Are You Sure Want Delete This Student?
//                               </Modal.Body>
//                               <Modal.Footer>
//                                 <Button
//                                   variant="secondary"
//                                   onClick={handleClose}
//                                 >
//                                   Close
//                                 </Button>
//                                 <Button variant="primary" onClick={handleClose}>
//                                   Delete
//                                 </Button>
//                               </Modal.Footer>
//                             </Modal>
//                             <table className="table table-nowrap">
//                               <thead>
//                                 <tr>
//                                   {/* <th>
//                                     <div className="form-check instruct-check-list">
//                                       <input
//                                         className="form-check-input"
//                                         type="checkbox"
//                                         name="option1"
//                                       />
//                                     </div>
//                                   </th> */}
//                                   <th>SR. NO.</th>
//                                   <th>NAME</th>
//                                   <th>EMAIL</th>
//                                   <th>CONTACT NO.</th>
//                                   <th>GENDER</th>
//                                   <th>STATUS</th>
//                                   <th>ACTIONS</th>
//                                   <th>&nbsp;</th>
//                                 </tr>
//                               </thead>
//                               {list?.map((item) => {
//                                 return (
//                                   <tbody>
//                                     <tr>
//                                       <td>{item.id}</td>
//                                       <td>
//                                         <Link
//                                           to="student-profile"
//                                           className="refer-avatar-blk d-flex align-items-center"
//                                         >
//                                           <p>{item.name}</p>
//                                         </Link>
//                                       </td>
//                                       <td>{item.email}</td>
//                                       <td>{item.phone}</td>
//                                       <td>{item.gender ? "Male" : "Female"}</td>
//                                       <td>
//                                         <Switch
//                                           onChange={handleChange}
//                                           checked={item.status}
//                                           className="react-switch"
//                                         />
//                                       </td>
//                                       <td>
//                                         <button
//                                           onClick={() => handleEdit()}
//                                           type="button"
//                                           class="btn btn-secondary"
//                                         >
//                                           Edit
//                                         </button>{" "}
//                                         <Button
//                                           type="button"
//                                           className="btn btn-danger"
//                                           onClick={handleShow}
//                                         >
//                                           Delete
//                                         </Button>
//                                       </td>
//                                     </tr>
//                                   </tbody>
//                                 );
//                               })}
//                             </table>
//                             {/* Instructor List */}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* Instructor Dashboard */}
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }

import React, { useState } from "react";
import StudentHeader from "../../student/header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import { Link } from "react-router-dom";

import StudentSideBar from "../../student/sidebar";
import Select from "react-select";

import AdminInstructorSidebar from "../../instructor/adminSidebar";
import TextEditor from "../../pages/course/addCourse/editor";

export default function AddAdminFeed1() {
  const [country, setCountry] = useState(null);
  const [radio, setRadio] = useState(null);
  const options = [
    { label: "Select Status", value: "Status" },
    { label: "Draft", value: "Draft" },
    { label: "Published", value: "Published" },
  ];
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  return (
    <div className="main-wrapper">
      {/* <StudentHeader activeMenu={"Profile"} /> */}
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Courses1"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Feed</h3>
                  </div>

                  {/* <div className="course-group mb-0 d-flex">
                    <div className="course-group-img d-flex align-items-center">
                      <Link to="student-profile">
                        <img src={User11} alt="" className="img-fluid" />
                      </Link>
                      <div className="course-name">
                        <h4>
                          <Link to="student-profile">Your avatar</Link>
                        </h4>
                        <p>PNG or JPG no bigger than 800px wide and tall.</p>
                      </div>
                    </div>
                    <div className="profile-share d-flex align-items-center justify-content-center">
                      <Link to="#;" className="btn btn-success">
                        Update
                      </Link>
                      <Link to="#;" className="btn btn-danger">
                        Delete
                      </Link>
                    </div>
                  </div> */}
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head">
                      <h4>Feed</h4>
                    </div>
                    <form action="#">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Title</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter title"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Subtitle
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter subtitle"
                            />
                          </div>
                        </div>

                        <div className="form-group mb-0">
                          <label className="add-course-label">
                            Course Description
                          </label>
                          <div id="editor">
                            <TextEditor />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              style={{ marginRight: "10px", marginTop: "30px" }}
                            >
                              Status :
                            </label>
                            <br />
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="option1"
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >
                                Draft
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="option2"
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >
                                Published
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button type="button" className="btn btn-primary">
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button type="button" className="btn btn-danger">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
