import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../footer";
import AdminInstructorSidebar from "../adminSidebar";
import AuthContext from "../../../context/AuthProvider";
import axios from "axios";


export const AdminZoom = () => {
  const { auth } = useContext(AuthContext);
  
  const client_id = "hoDZIPSTS9GurMyVUc15CQ";
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const CLIENT_SECRET = "sDJMMJeQ9BH6MTR66FMrQvesJQWRLVQW";

  const REDIRECT_URI = "https://lmscode.stellarflux.in/api/live-classes/add-token";
  // $url = "https://zoom.us/oauth/authorize?response_type=code&client_id=".$client_id."&redirect_uri=".$REDIRECT_URI;

  const url = `https://zoom.us/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${REDIRECT_URI}`;
  // const fetchUrl = async () => {
  //   const response = await axios.get(
  //     url,
  //     { "content-type": "application/json" },
  //     (req, res) => {
  //       navigate(`/admin/zoom/${res}`);
  //     }
  //   );
  //   console.log(response);
  //   setData(response.data);
  // };
  // useEffect(() => {
  //   fetchUrl();
  // }, []);
  // console.log(data);
  return (
    <div className="main-wrapper">
      {/* <InstructorHeader activeMenu={"Dashboard"} /> */}
      <div className="page-content instructor-page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Zoom"} />
      
            {/* Sidebar */}
            {/* Zoom Token */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="page-content">
                <div className="container">
                  <div className="row">
                    {/* Profile Details */}
                    <div className="col-md-12">
                      <div className="settings-widget">
                        <div className="settings-inner-blk add-course-info new-ticket-blk p-0">
                          <div className="comman-space">
                            {/* <h4>Create New </h4> */}
                            <div className="filter-grp ticket-grp tiket-suport d-flex align-items-center justify-content-between">
                              <div>
                                <h3>ZOOM INTEGRATION</h3>
                          
                              </div>
                              <div className="ticket-btn-grp">
                                <Link to={url}>Zoom Link</Link>

                              </div>
                            </div>
                            <div>
                              <div className="form-group">
                                <label className="form-control-label">
                                  Access Token
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group">
                                <label className="form-control-label">
                                  Refresh Token
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group">
                                <label className="form-control-label">
                                  Token valid Till
                                </label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="submit-ticket">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                >
                                  Submit
                                </button>
                                <button type="button" className="btn btn-dark">
                                  Back
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Profile Details */}
                  </div>
                </div>
              </div>
            </div>
            {/* Zoom Token */}

            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
