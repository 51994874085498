import Collapse from "react-bootstrap/Collapse";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Share,
  Chart,
  Cloud,
  Icon1,
  Icon2,
  Import,
  Key,
  Mobile,
  People,
  Play,
  Teacher,
  Timer2,
  User1,
  Users,
  Video,
  Video2,
  Chapter,
} from "../imagepath";
import { Scrollbar } from "react-scrollbars-custom";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import "./detailsContent.css";
import axios from "../../api/axios";
import axiosInstance from "../../api/axios1";
const DETAILSDATA = "/api/student/course-detail/detail";
const COURSESUBSCRPTION="/api/free-course-subcription"
import { notification, Space } from "antd";
import { teal } from "@mui/material/colors";

const DetailsContent = ({
  amount,
  image,
  slug,
  description,
  name,
  id,
  setvideos,
  settestseries,
  duration,
  subscrption,
  contact,
}) => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [flag, setFlag] = useState(true);
  const [flag1, setFlag1] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subModule, setSubModule] = useState([]);
  const [test, setTest] = useState([]);
  const [video, setVideo] = useState([]);
  const[testserieslength,setTestseriesLength]=useState([])
  const [videoslength,setVideosLength]=useState([])
    // console.log(videoslength,"videoslength")

  // const [loading,setLoading] =useState(false)
  const formattedAmount = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
  }).format(amount || 0);

  const tokenStudent = localStorage.getItem("tokenStudent");
 
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const getDetail1 = async () => {
    try {
      setLoading(true);
      const responce = await axiosInstance.get(`${DETAILSDATA}/${id}`,);
      // console.log(responce,"responce")
      setSubModule(responce?.data[0]?.relation?.sub_module);

      if (responce?.data[0]?.relation?.sub_module) {

        //  test-series
        const testModules = responce?.data[0]?.relation?.module?.filter(
          (module) => module?.type === "Test Series"
        );
        const testsubModules = responce?.data[0]?.relation?.sub_module?.filter(
          (module) => module?.type === "Test Series"
        );
// Array to store the desired objects
const result = [];

// Iterate over each module in testModules
testModules.forEach((module) => {
  // Find all submodules whose ID matches the current module's ID
  const matchingSubmodules = testsubModules.filter(
    (subModule) => +subModule.module_id === module.id
  );
  // Create the object for the current module
  const moduleObject = {
    name: module.name,
    id: module.id,
    submodules: matchingSubmodules,
  };

  // Add the object to the result array
  result.push(moduleObject);
});
setTest(result);
const valuetestlength=[]
const valuelength=result?.map((item)=>{
  item?.submodules?.map((item1)=>{
    valuetestlength?.push(item1)

  })
})
settestseries(testModules?.length);

setTestseriesLength(valuetestlength)
//  console.log("Result:", valuetestlength);

        //  console.log(testModules,"test-modules")
        // console.log(testsubModules,"test-submodules")
        const videoMainmodule=responce?.data[0]?.relation?.module?.filter(
          (module)=>module?.type==="Recorded Videos"
        )
        // console.log(videoMainmodule,"videomain")
        const videoModules = responce?.data[0]?.relation?.sub_module?.filter(
          (module) => module?.type === "Recorded Videos"
        );
       setVideosLength(videoModules)
        // console.log(videoModules,"videomain")
        const resultvideo = [];

        videoMainmodule.forEach((module) => {
  // Find all submodules whose ID matches the current module's ID
  const matchingSubmodules = videoModules.filter(
    (subModule) => (+subModule.module_id == module?.id ||subModule?.module_id==0 )
  );
  // Create the object for the current module
  const moduleObject = {
    name: module.name,
    id: module.id,
    submodules: matchingSubmodules,
  };

  // Add the object to the result array
  resultvideo.push(moduleObject);
});
// console.log(resultvideo,"resultvideo")

       
        // setvideos(videoModules?.length);
        setVideo(resultvideo);
        if (responce?.data[0]?.relation?.sub_module) {
          const finalvalue = [];
          const uniqueIds = new Set();
    
          responce?.data[0]?.relation?.sub_module
            .filter((module) => module?.type === "Recorded Videos")
            .forEach((module) => {
              const moduleId = module.id;
    
              module?.video.forEach((video) => {
                const videoId = video.id;
                const combinedId = `${moduleId}-${videoId}`;
    
                if (!uniqueIds.has(combinedId)) {
                  finalvalue.push({
                    id: videoId,
                    module_id: moduleId,
                    name: video.name,
                    thumbnail: video.thumbnail,
                    thumbnail_path: video.thumbnail_path,
                    description: video.description,
                  });
    
                  uniqueIds.add(combinedId);
                }
              });
            });
    
          // console.log(finalvalue, "finalValue");
          setvideos(finalvalue.length);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const videodata = () => {
    let result = [];
    for (const item of video) {
      // console.log(item);
      const submodule = [];

      submodule.push({ name: item.name, id: item.id });
      let obj = {};
      let videos = [];
      for (const video of item.video) {
        for (const Videos of video.videos) {
          videos.push(Videos);
        }
      }
      // console.log(videos, "videos");
      // const unique = [];
      // videos.map(x => unique.filter(a => a.thumbnail == x.thumbnail && a.title == x.title).length > 0 ? null : unique.push(x));
      // delete item.videorelation;
      // obj["videos"] = unique;
      // obj["submodule"] = item;

      // result.push(obj);
    }
  };
// console.log(video,"video")
  const finalData = [];

  // video.forEach((item) => {
  //   const videoIds = new Set(); // To keep track of seen video ids

  //   item.video.forEach((video) => {
  //     if (!videoIds.has(video.id)) {
  //       finalData.push({
  //         name: item?.name,
  //         id: video?.id,
  //         nameofvideo: video?.name,
  //         thumbnail: video?.thumbnail,
  //         thumbnail_path: video?.thumbnail_path,
  //         description: video?.description,
  //         videolength:video?.videolength
  //       });

  //       videoIds.add(video.id);
  //     }
  //   });
  // });
  const submoduleData = {}; // Object to store data organized by submodule ID
  const finalData1 = [];
  finalData1.push(video)

  finalData1?.forEach((item) => {
    item?.forEach((item1) => {
      item1?.submodules?.forEach((item2) => {
        const submoduleid = item2?.id;
        const submodulename = item2?.name;
  
        // Check if submodule ID exists in submoduleData object, if not, initialize it with an empty array
        if (!submoduleData[submoduleid]) {
          submoduleData[submoduleid] = [];
        }
  
        // Iterate over the videos in the submodule and add relevant data to the corresponding submodule ID key
        const videoIds = new Set(); // To keep track of seen video ids
        item2?.video?.forEach((video) => {
           if (!videoIds.has(video.id) ) {
            submoduleData[submoduleid].push({
              name: submodulename,
              submoduleid: submoduleid,
              id: video?.id,
              nameofvideo: video?.name,
              thumbnail: video?.thumbnail,
              thumbnail_path: video?.thumbnail_path,
              description: video?.description,
              duration:video?.videolength,
              
            });
            videoIds.add(video.id);
           }
        });
      });
    });
  });
  
  // Convert submoduleData object into an array of arrays
  const organizedData = Object.values(submoduleData).map((videos) => {
    return {submodulename:videos[0]?.name,submoduleid:videos[0]?.submoduleid, data: videos };
  });


// Convert submoduleData object into an array of arrays


//  console.log(organizedData);

    // console.log(finalData,"finaldata")
  // useEffect(()=>{
  //  setvideos(finalData?.length) 
  // },[])
 

  // console.log(finalData);

  // console.log(subModule?subModule:"empty","submodule")
  // console.log("module data", finalData);
  useEffect(() => {
    getDetail1();
  }, []);
  useEffect(() => {}, [flag, flag1, flag2]);
  useEffect(() => {}, [subModule, test, video]);

  const navigate = useNavigate();
  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}:${m}`;
  };
  const formatVideoLength = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };
  const userid=localStorage?.getItem("userid")
  // console.log(id,"in courses")
  // const handelfreeCourse=async(userid,id,duration)=>{
  //     try{
  //       const response=await axiosInstance.post(COURSESUBSCRPTION,{
  //         "user_id":userid,
  //         "course_id":id,
  //         "expiry_date":duration
  //       }, config);
  //       if(response){
  //         openNotificationWithIcon1("success");
  //         getDetail1()
  //         navigate(-1)

  //       }
        
        
  //       } 
  //       catch(error){
  //         console.log(error,"error")
        
  //         if (error?.response?.status === 401) {
  //           localStorage.removeItem("token");
  //           navigate("/admin/login");
  //         }
  //         else{
  //       openNotificationWithIcon("error",error?.response?.data?.message,"red")
  //         }
  //       }

  // }

  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Course Subscriped Sucessfully</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIcon = (type, text, color) => {
    notification[type]({
      message: (
        <div style={{ color: color }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const videofinallength=[]
  videoslength?.forEach((item) => {
    const videoIds = new Set(); // To keep track of seen video ids

    item.video.forEach((video) => {
      // console.log(video,"video")
      if (!videoIds.has(video.id)) {
        videofinallength.push({
          name: item?.name,
          id: video?.id,
          nameofvideo: video?.name,
          thumbnail: video?.thumbnail,
          thumbnail_path: video?.thumbnail_path,
          description: video?.description,
        });

        videoIds.add(video.id);
      }
    });
  });

  //  console.log(videofinallength,"test state")
  return (
    <>
      <section className="page-content course-sec">
        {loading === true ? (
          <div style={{ display: "block" }} id="cover-spin"></div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                {/* Overview */}
                <div className="card overview-sec">
                  <div className="card-body ">
                    <div className=" d-flex " style={{ gap: "60px" }}>
                      <div
                        onClick={() => {
                          setFlag(true);
                          setFlag1(false);
                          setFlag2(false);
                          // setFlag(true);
                        }}
                        style={{
                          paddingBottom: "3px",
                          borderBottom: flag ? " 2.3px solid #FF8D00" : "none",
                          // textDecoration: flag ? "underline #FF8D00" : "none",
                        }}
                      >
                        <button
                          className=""
                          style={{ border: "none", backgroundColor: "white" }}
                        >
                          <h5
                            className="subs-title"
                            style={{ marginBottom: ".5em" }}
                          >
                            Overview
                          </h5>
                        </button>
                      </div>
                    </div>
                    {flag ? (
                      <div style={{ cursor: "pointer", paddingTop: "20px" }}>
                        <h6>Course Description</h6>
                        <div
                          style={{ textTransform: "capitalize" }}
                          dangerouslySetInnerHTML={{
                            __html: description,
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* wordLimit={40} */}
                    {/* <p>
                    It was popularised in the 1960s with the release of Letraset
                    sheets containing Lorem Ipsum passages, and more recently
                    with desktop publishing software like Aldus PageMaker
                    including versions of Lorem Ipsum.
                  </p> */}
                    {/* <h6>What you'll learn</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Become a UX designer.</li>
                        <li>You will be able to add UX designer to your CV</li>
                        <li>Become a UI designer.</li>
                        <li>Build &amp; test a full website design.</li>
                        <li>Build &amp; test a full mobile app.</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>
                          Learn to design websites &amp; mobile phone apps.
                        </li>
                        <li>You'll learn how to choose colors.</li>
                        <li>Prototype your designs with interactions.</li>
                        <li>Export production ready assets.</li>
                        <li>All the techniques used by UX professionals</li>
                      </ul>
                    </div>
                  </div>
                  <h6>Requirements</h6>
                  <ul className="mb-0">
                    <li>
                      You will need a copy of Adobe XD 2019 or above. A free
                      trial can be downloaded from Adobe.
                    </li>
                    <li>No previous design experience is needed.</li>
                    <li className="mb-0">
                      No previous Adobe XD skills are needed.
                    </li>
                  </ul> */}
                  </div>
                </div>

                {/* /Overview */}
                {/* Course Content */}

                <div className="card content-sec">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <h5 className="subs-title">Course Content</h5>
                        <div
                          className=""
                          style={{
                            borderBottom: "2.3px solid #FF8D00",
                            width: "150px",
                            marginTop: "-8px",
                            marginLeft: "-4px",
                          }}
                        ></div>
                        <p style={{ marginTop: "10px" }}>Course Details</p>
                      </div>
                      <div className="col-sm-6 text-sm-end">
                        {/* <h6>92 Lectures 10:56:11</h6> */}
                      </div>
                    </div>

                    <div className="course-card">
  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
    <h6 className="">
      <Link
        className=""
        data-bs-toggle=""
        to=""
        aria-expanded={open}
        // onClick={() => setOpen(!open)}
        aria-controls=""
      >
        Test Series
      </Link>
    </h6>

    {test?.length > 0 &&
      test?.map((item, index) => {
        const collapseId = `collapse_${item?.id}`; // Unique ID for each collapse

        return (
          <div
            className="accordion"
            id="accordionExample"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            key={item?.id}
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id={`heading_${index}`}>
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#${collapseId}`} // Set the collapse target dynamically
                  aria-expanded="false"
                  aria-controls={collapseId}
                  style={{textTransform:"capitalize"}}
                >
                  {item?.name}
                </button>
              </h2>
              <div
                id={collapseId}
                className="accordion-collapse collapse"
                aria-labelledby={`heading_${index}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <Scrollbar style={{ height: 180 }}>
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Title</th>
                          <th scope="col">Questions</th>
                          <th scope="col">Total Marks</th>
                          <th scope="col">Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        {test?.length > 0 &&
                          item?.submodules?.map((item1, index1) => {
                            //  console.log(item1, "item1");
                            return (
                              <tr key={item1?.id} style={{}}>
                                <td>
                                  <p>
                                    <img
                                      src={Icon1}
                                      alt=""
                                      className="me-2"
                                    />
                                    {item1?.name}
                                  </p>
                                </td>
                                <td>{item1?.totalQuestion}</td>
                                <td>{item1?.total_marks}</td>
                                <td>
                                  {convertMinsToHrsMins(item1?.duration)}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </Scrollbar>
                </div>
              </div>
            </div>
          </div>
        );
      })}
  </div>
</div>


<div className="course-card">
  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
    <h6 className="">
      <Link
        className=""
        data-bs-toggle=""
        to=""
        aria-expanded={open}
        // onClick={() => setOpen(!open)}
        aria-controls=""
      >
        Videos
      </Link>
    </h6>

    {organizedData?.length > 0 && 
      organizedData?.map((item, index) => {
        // console.log(item,"videos")
        const collapseId = `collapse_${index}`; // Unique ID for each collapse

        return (
          <>
          {item?.data?.length>0?
           <div
            className="accordion"
            id="accordionExample"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            key={index}
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id={`heading_${index}`}>
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#${collapseId}`} // Set the collapse target dynamically
                  aria-expanded="false"
                  aria-controls={collapseId}
                  style={{textTransform:"capitalize"}}
                >
                  {item?.submodulename}
                </button>
              </h2>
              <div
                id={collapseId}
                className="accordion-collapse collapse"
                aria-labelledby={`heading_${index}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
               
                   <Scrollbar style={{ height: 180 }}>
                          <div className="course-card">
                            <ul style={{ marginRight: "1em" }}>
                              {item?.data?.length > 0 &&
                                item?.data?.map((item1,index1) => {
                                  // console.log(item1,"item1")
                                  return (
                                    <div key={item?.id}>
                                    <li
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                      
                                      
                                    >
                                      <p style={{ wordWrap: " break-word" }}>
                                        <img
                                          src={Play}
                                          alt=""
                                          className="me-2"
                                        />
                                        {item1?.nameofvideo}
                                      </p>
                                      <div>
                                        {/* <Link>Preview</Link> */}
                                        <span>
                                        {convertMinsToHrsMins(item1?.duration)}

                                        </span>
                                      </div>
                                    </li>
                                    </div>
                                  );
                                })}
                            </ul>
                          </div>
                        </Scrollbar>
                </div>
              </div>
            </div>
          </div>:""}</>
         
        );
      })}
  </div>
</div>
                  </div>
                </div>
                
              </div>
              <div className="col-lg-4">
                <div className="sidebar-sec">
                  {/* Video */}
                  <div
                    className="video-sec vid-bg "
                    style={{
                      marginTop: "4rem",
                      // marginLeft: "1rem"
                    }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <Link
                        // to="https://www.youtube.com/embed/1trvO6dqQUI"
                        // className="video-thumbnail"
                        // data-fancybox=""
                        >
                          {/* <div className="play-icon">
                          <i className="fa-solid fa-play" />
                        </div> */}
                          {image === null ? (
                            <img
                              className="img-fluid"
                              id="img-grid"
                              // style={{ width: "330px", height: "200px" }}
                              src={Share}
                              alt=""
                            />
                          ) : (
                            <img
                              className="img-fluid"
                              id="img-grid"
                              //  style={{ width: "330px", height: "200px" }}
                              src={`${image}`}
                              alt=""
                            />
                          )}
                        </Link>
                        <div className="video-details">
                          {formattedAmount == "₹0"  ? (
                            <div className="plan-change"
                            >
                            <button
                              // target="_blank"
                              // href={`https://wa.me/9640671866?text=I%20need%20to%20buy%20this%20course%20${name}`}
                              onClick={() =>
                                // navigate(`/student/buy-course/${slug}`)
                                // handelfreeCourse(userid,id,duration)
                                openNotificationWithIcon("error","Please Login","red")
                              }
                              className="btn btn-enroll w-100 "
                              // disabled={subscrption!==null}

                              // style={{ height: "50px" }}
                            >
                              <span
                                className="button-text"
                                style={{ fontSize: "18px" }}
                              >
                                {subscrption ?"Subscribed":"Buy Subscrption"}
                              </span>
                            </button>
                          </div>
                          ) : (
                            <div className="course-fee">
                              <div className="basic-plan">
                                <h2>{formattedAmount}</h2>
                              </div>
                            </div>
                          )}

                          <div className="row gx-2">
                            <div className="col-md-6 addHeart">
                              {/* <Link
                              to="/course-wishlist"
                              className=" btn btn-wish w-100"
                            > */}
                              {/* <i className="feather-heart" />  */}
                              {/* <FeatherIcon icon="heart"/>
                              Add to Wishlist
                            </Link> */}
                            </div>
                            {/* <div className="col-md-6 addHeart">
                            <Link
                              to="#"
                              className="btn btn-wish w-100"
                            > */}
                            {/* <i className="feather-share-2" />  */}
                            {/* <FeatherIcon icon="share-2"/>
                              
                              Share
                            </Link>
                          </div> */}
                          </div>
                          {/* <Link
                          to="checkout"
                          className="btn btn-enroll w-100"
                        >
                          Buy Now
                        </Link> */}
                          {formattedAmount == "₹0" ? (
                            ""
                          ) : (
                            <div className="plan-change">
                              <button  className="btn btn-enroll w-100 "
                              // disabled={subscrption!==null}
                              onClick={()=>{
                                openNotificationWithIcon("error","Please Login","red")

                              }}
                              >
                              <span
                                  className="button-text"
                                  style={{ fontSize: "18px",color:"white" }}
                                >
                                  {subscrption ?"Subscribed":"Buy Now"}
                                </span>
                              </button>
                            
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Video */}
                  {/* Include */}
                  {/* <div className="card include-sec">
                  <div className="card-body">
                    <div className="cat-title">
                      <h4>Includes</h4>
                    </div>
                    <ul>
                      <li>
                        <img src={Import} className="me-2" alt="" /> 11 hours
                        on-demand video
                      </li>
                      <li>
                        <img src={Play} className="me-2" alt="" /> 69
                        downloadable resources
                      </li>
                      <li>
                        <img src={Key} className="me-2" alt="" /> Full lifetime
                        access
                      </li>
                      <li>
                        <img src={Mobile} className="me-2" alt="" /> Access on
                        mobile and TV
                      </li>
                      <li>
                        <img src={Cloud} className="me-2" alt="" /> Assignments
                      </li>
                      <li>
                        <img src={Teacher} className="me-2" alt="" />{" "}
                        Certificate of Completion
                      </li>
                    </ul>
                  </div>
                </div> */}
                  {/* /Include */}
                  {/* Features */}
                  <div className="card feature-sec">
                    <div className="card-body">
                      <div className="cat-title">
                        <h4>Includes</h4>
                      </div>
                      <ul>
                        {/* <li>
                        <img src={Users} className="me-2" alt="" /> Enrolled:{" "}
                        <span>32 students</span>
                      </li>
                      <li>
                        <img src={Timer2} className="me-2" alt="" /> Duration:{" "}
                        <span>20 hours</span>
                      </li> */}
                        <li>
                          <img src={Chapter} className="me-2" alt="" /> Test:{" "}
                          <span>{test?.length > 0 ? testserieslength?.length : "0"}</span>
                        </li>
                        <li>
                          <img src={Video2} className="me-2" alt="" /> Video:
                          <span>
                            {" "}
                            {videofinallength?.length > 0 ? videofinallength?.length : "0"}
                          </span>
                        </li>
                        {/* <li>
                        <img src={Chart} className="me-2" alt="" /> Level:{" "}
                        <span>Beginner</span>
                      </li> */}
                      </ul>
                    </div>
                  </div>
                  {/* /Features */}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default DetailsContent;
