import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import { Link, useNavigate } from "react-router-dom";
import StudentSideBar from "../sidebar";
import { Select } from 'antd';
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextEditor from "../../pages/course/addCourse/textEditor";
import { useFormik } from "formik";
import axios from "../../../api/axios";
import * as Yup from "yup";
import AuthContext from "../../../context/AuthProvider";
import AdminHeader from "../../instructor/adminHeader";
import { async } from "q";
import { notification, Space } from "antd";
// import "./index.css"

const CREATECENTER = "/api/admin/academy_centers";
const CENTERLIST="/api/public/location/states"
const CITYLIST="api/public/location/cities"
const { Option } = Select;

const initalValue = {
  name: "",
};
const courseSchema = Yup.object({
  name: Yup.string().min(2).required("Enter Your Name"),
});

export default function AddAdminCenterList() {
  const [country, setCountry] = useState(null);
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();
  const [radio, setRadio] = useState(null);
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [api, contextHolder] = notification.useNotification();
  const [selectedCenterId, setSelectedCenterId] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [selectedCity,setSelectedCity]=useState(null)
  const [selectedCityId,setSelectedCityId]=useState(null)

  const openNotificationWithIcon = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon2 = (type, text) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconerror = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>course is already exists</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    
    onSubmit: async (values, { setFieldError }) => {
        // console.log("krishna before try");

      setSubmit(true);
      try {

        // console.log("krishna");
        const formData = new FormData();
        formData.append("state_id", selectedCenterId);
        formData.append("city_id", selectedCityId);
        formData.append("name", values.name);
        const response = await axios.post(CREATECENTER, formData, config);
        // console.log(response);
        if (response) {
          openNotificationWithIcon("success", "Academy Center Created", "#52c41a");
          setTimeout(() => {
            navigate("/admin/centers");
          }, 800);
          
          // toast.success("Course Created");
        }
      } catch (error) {
        setSubmit(false);
        console.log(error);
        if (error?.response?.status === 422) {
          openNotificationWithIcon("error", error?.response?.data?.message, "red");
        } 
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIcon("error", error?.response?.data?.message,"red");
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
        else {
          openNotificationWithIcon("error", "Internal Server Error", "red");
        }
        // if (error.response.data.errors.image) {
        //   setFieldError("image", error.response.data.errors.image.toString());
        // }
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
        else{
          openNotificationWithIcon2("error",error?.response?.data?.message)
        }
      }
    },
     validationSchema: courseSchema,
  });
  
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  
const handleCourseChange = (value) => {
      // console.log(value,"value course")
      setSelectedCenterId(value);
  };
  const handleStudentChange = (value) => {
    //  console.log(value,"value student")
    setSelectedCityId(value);
  };
 
const InstituteList = async () => {
  try {
      const res = await axios.get(CENTERLIST, config)
//  console.log(res,"data")
     setSelectedCenter(res?.data?.message)
  } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
          localStorage.removeItem('token')
          navigate('/admin/login')
      }

  }
}
const CityList = async () => {
    try {
        const res = await axios.get(`${CITYLIST}/${selectedCenterId}`, config)
    // console.log(res,"data city")
    setSelectedCity(res?.data?.message)
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
        }
  
    }
  }
useEffect(() => {
  InstituteList()
  CityList()
}, [selectedCenterId]);
// console.log(selectedCenter,"selected center")
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  

  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"centers"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Academy Centers</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li className="breadcrumb-item" role="button">
                          <a onClick={() => navigate("/admin/centers")}>
                            Academy Centers
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Add Academy Centers
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                      <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                               Name 
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your Name"
                              id="name"
                              name="name"
                              style={{height:"20px"}}
                              value={values?.name}
                              onChange={handleChange}
                            />
                            {errors?.name && touched?.name ? (
                              <span style={{ color: "red" }}>
                                {errors?.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
    <div className="col-lg-6">
      <div className="form-group d-flex"
      style={{flexDirection:"column"}}
      >
        <label className="form-control-label"> Select State</label>
        <Select
          showSearch
          style={{ width: "100%",height:"100%" }}
          placeholder="Select an option"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
         onChange={handleCourseChange}
        >
          {selectedCenter?.map((option) => (
            
            <Option key={option?.id} value={option?.id} >
              {option?.name}
            </Option>
          ))}
        </Select>
      </div>
    </div>
    <div className="col-lg-6">
      <div className="form-group d-flex"
      style={{flexDirection:"column"}}
      >
        <label className="form-control-label">Select City</label>
        <Select
          showSearch
          style={{ width: "100%",}}
          placeholder="Select an option"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleStudentChange}

        >
          {selectedCity?.map((option) => (
            <Option key={option.id} value={option.id}>
              {option?.name}
            </Option>
          ))}
        </Select>
        {/* {errors.name && touched.name ? (
          <span style={{ color: 'red' }}>{errors.name}</span>
        ) : null} */}
      </div>
    </div>
    

                       
                        <div className="update-profile" id="submit-button"
                        style={{marginTop:"20px"}}
                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={submit}
                            //   value={console.log("krishna in submit")}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
