import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import { Link, useNavigate, useParams } from "react-router-dom";
import StudentSideBar from "../sidebar";
import Select from "react-select";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextEditor from "../../pages/course/addCourse/textEditor";
import { useFormik ,useFormikContext} from "formik";
import axios from "../../../api/axios";
import * as Yup from "yup";
import AuthContext from "../../../context/AuthProvider";
import AdminHeader from "../../instructor/adminHeader";
import { notification, Space } from "antd";


const CREATEEXAMALLERTPPDF = "/api/student/examAlerat/create/pdf";
const initalValue = {
  name: "",
  status: "",
  date: "",
};
const courseSchema = Yup.object({
  name: Yup.string().min(2).required("Please enter your Pdf Name"),
  // pdf:Yup.string().required("please Upload pdf only")
});
const CreateExamAllertsPdf = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();
  const [touched1, setTouched1] = useState({});
  const [errors1, setErrors1] = useState({});
  const {id}=useParams()

  const [submit, setSubmit] = useState(false);

// const { setTouched } = useFormikContext();

  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  
  useEffect(()=>{
// console.log(file,"file")
  },[file])
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    onSubmit: async (values, { setFieldError }) => {
      setSubmit(true);
      try {
        // console.log(value);
        const formData = new FormData();

        formData.append("name", values.name);

        formData.append("examalert_id", id);
         formData.append("file",file)
        const response = await axios.post(CREATEEXAMALLERTPPDF, formData, config);
        // console.log(response);
        if (response) {
          setToast(true);
          openNotificationWithIcon("success", "Exam Alerts Pdf Created", "#52c41a");
          setTimeout(() => {
            navigate(`/admin/exam-allerts/pdf/${id}`)
          }, 1000);
          // toast.success("Batch Created");
        }
      } catch (error) {
        console.log(error);
        setSubmit(false);
        setToast(false);
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
       else if (error?.response?.status === 422) {
          openNotificationWithIcon("error", error.response.data.message, "red");
        } else {
          openNotificationWithIcon("error", "Internal Server Error", "red");
        }
       
      }
    },
    validationSchema: courseSchema,
  });

  const handleRadio = (e) => {
    if (e === "option2") {
      setFieldValue("amount", 0);
    }
    setRadio(e);
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // const minDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD

  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div
          className="container"
          style={{ marginTop: "-15px", minWidth: "100%" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"exams"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Exam Alerts Pdf</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li className="breadcrumb-item" role="button">
                          <a onClick={() => navigate("/admin/exam-allerts")}>
                            Exam Alerts
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                            <a onClick={() => navigate(`/admin/exam-allerts/pdf/${id}`)}>
                            Exam Alert Pdf
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Add Exam Alerts Pdf
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Pdf Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your Pdf Name"
                              id="name"
                              name="name"
                              value={values?.name}
                              onChange={handleChange}
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Upload Pdf
                            </label>
                            <input
                              type="file"
                              name="pdf" // Use a more descriptive name like "pdf"
                              id="pdf"
                              accept=".pdf" // Restrict to PDF files only
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file.type === "application/pdf") {
                                  // Validate file type
                                  setFile(file);
                                  setFilePre(URL.createObjectURL(file));
                                  setErrors1({ pdf: "" }); 
                                } else {
                                  // Handle invalid file type, e.g., display an error message
                                  setErrors1({
                                    pdf: "Invalid file type. Please upload a PDF file.",
                                  });
                                  setTouched1({ pdf: true }); // Mark the field as touched for error display

                                }
                              }}
                              className="form-control"
                              placeholder="Upload PDF"
                            />
                            { errors1.pdf && touched1.pdf  ? (
                              <span style={{ color: "red" }}>{errors1.pdf}</span>
                            ) : null}
                          </div>
                        </div>

                    
                    

                        <div className="update-profile" id="submit-button">
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={submit}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      {/* <ToastContainer /> */}
      <Footer />
    </div>
  );
};

export default CreateExamAllertsPdf;
