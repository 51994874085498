import axios from "axios";

const BASEURL ='https://aspirantcode.chahalacademy.com'

const axiosTeacher = axios.create({
  baseURL: BASEURL,
  headers: {
    // Default content type
   'Db': sessionStorage.getItem('db') ? sessionStorage.getItem('db') : 0, // Default accept type
}
});

// Add a request interceptor
axiosTeacher.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("tokenTeacher");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosTeacher.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
    localStorage.removeItem("tokenTeacher");
      localStorage.removeItem("showNotification")
      window.location.href = "/teacher/login"; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default axiosTeacher;
