import React, { useContext, useEffect, useState } from "react";
import Footer from "../../footer";

import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import AdminHeader from "../adminHeader";
import Button from "react-bootstrap/Button";
import CryptoJS from "crypto-js";
import Switch from "react-switch";
import AuthContext from "../../../context/AuthProvider";
import { Search } from "react-feather";
import "../adminTestSeries/index.css";
import "./index.css";
import axios from "../../../api/axios";
import { Tooltip } from "antd";

import { notification, Space, Modal } from "antd";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";
const DELETEBATCH = "/api/del-courses-batch";
const SHOWBATCH = "/api/courses-batch";
const STATUSCHANGE = "/api/status-courses-batch";
const BATCHSEARCH = "/api/search-courses-batch";
const { confirm } = Modal;

export default function AdminBatchList() {
  const [show, setShow] = useState(false);
  const [showView, setShowView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [iddata, setIdData] = useState("");
  const [message,setmessage]=useState('No Data')
  const [idvalue, setIdValue] = useState("");
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      STATUSCHANGE,
      { id: id, status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1("success");
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
  };

  const [last_page, setLast_page] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const [text, settext] = useState("");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleClose = () => setShow(false);
  const handleCloseView = () => setShowView(false);
  const handleShow = (id) => {
    setShow(true);
    // setIdValue(id)
  };

  const navigate = useNavigate();
  const handleView = () => {};
  const handleEdit = (id) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/batchList-edit/${ciphertexts}`);
  };
  const handelAddCourse = (slug) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(slug),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/batchListStudents/${ciphertexts}`);
  };
  const fetchBatchList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${SHOWBATCH}`, config);
      setList(response?.data?.data);
      setData(response?.data?.data);
      setIdData(response?.data?.CourseId); // using this setIdData i am sending courseId to create page
      setLast_page(response.data?.last_page);
      setCurrentPage(response?.data?.current_page);

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon3("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error")
        // openNotificationWithIcon3("error","Please Reload the Page")

      }
       
    }
  };

  useEffect(() => {
    fetchBatchList();
  }, [token]);
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Batch Deleted</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const handleDeleteStudent = async () => {
    setLoading(true);
    const resonse = await axios.get(`${DELETEBATCH}/${idvalue}`, config);
    setLoading(false);
    if (resonse) {
      openNotificationWithIcon("info");

      fetchBatchList();
    }

    setShow(false);
    // toast.warning("Batch Deleted");
    return resonse;
  };
  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this batch?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETEBATCH}/${id}`, config);
          setLoading(false);
          if (resonse) {
            openNotificationWithIcon("success");
            fetchBatchList();
          }
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else if (error?.response?.status === 403) {
            localStorage.removeItem("token");
            openNotificationWithIcon3("error", error?.response?.data?.message);
            setTimeout(() => {
              navigate("/admin/login");
            }, 1000); // Delay the navigation to show the notification
          }
          else{
          openNotificationWithIcon2("error");
          }
        }
      },
      onCancel() {},
    });
  }
  const Filter = async (e) => {
    const searchValue = e.target.value;
    settext(searchValue);
    const res = await axios.post(BATCHSEARCH, { name: searchValue }, config);
    if (res?.data?.data?.length > 0) {
      setList(res?.data?.data);
      setCurrentPage(res.data.current_page);
      setLast_page(res.data.last_page);
      setmessage('No Data')
    }
    if (res.data.data.length === 0) {
      setmessage('No Data Found')
      setList(res?.data?.data);
    }
    if (searchValue.length == 0) {
      const response = await axios.get(
        `${SHOWBATCH}?page=${currentPage}`,
        config
      );
      // setList(response?.data?.data);
      setList(data);
      setCurrentPage(1);
      setLast_page(response.data.last_page);
    }
  };

  const itemsPerPage = 10;
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };

  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  // useEffect(() => {
  //   windowTop(0, 0);
  // }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Batches"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3>Batches</h3>
                        </div>
                        <div className="comman-space pb-0">
                          <div className="instruct-search-blk">
                            <div className="show-filter choose-search-blk">
                              {/* <h4 className="text text-secondary">{name.slice(0,60)}</h4> */}
                              <form action="#">
                                {/* <p>Zoom App Details</p> */}
                                <div className="row gx-2 align-items-center">
                                  <div className="col-md-6 col-item">
                                    <div className=" search-group">
                                      <Search
                                        size={16}
                                        style={{
                                          position: "absolute",
                                          left: "7px",
                                          color: "#F66962",
                                        }}
                                      />
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Name"
                                        onChange={Filter}
                                      />
                                      <h4 className="text text-secondary">
                                        {/* {name.slice(0, 60)} */}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-6 col-item">
                                    <div
                                      className="form-group select-form mb-0 d-flex justify-content-end"
                                      style={{ marginBottom: "-5px" }}
                                    >
                                      <Button
                                        onClick={() =>
                                          navigate(`/admin/create-new-batch`)
                                        }
                                        style={
                                          {
                                            //  marginLeft: "5.2em",
                                            // width:"100%"
                                          }
                                        }
                                        className="btn btn-primary"
                                        id="custom-button"
                                      >
                                        {" "}
                                        <i className="fa-solid fa-plus"></i>{" "}
                                        Create New
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="settings-referral-blk table-responsive">
                            {/* Instructor Users */}
                            {/* <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Delete Batch</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Are You Sure Want Delete This Batch?
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                {contextHolder}
                                <Space>
                                  <Button
                                    variant="primary"
                                    onClick={handleDeleteStudent}
                                  >
                                    Delete
                                  </Button>
                                </Space>
                              </Modal.Footer>
                            </Modal> */}
                            {/* View Payment */}
                            {/* <Modal show={showView} onHide={handleCloseView}>
                              <Modal.Header closeButton>
                                <Modal.Title>View Payment</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <table className="table table-nowrap mb-0">
                                  <thead>
                                    <tr>
                                      <th>Inst.No</th>
                                      <th>Amount</th>
                                      <th>Duration</th>
                                    </tr>
                                  </thead>
                                  {loadingTable === true ? (
                                    <div
                                      className="d-flex justify-content-center"
                                      style={{ position: "absolute" }}
                                    >
                                      <div
                                        class="spinner-border text-warning"
                                        style={{
                                          left: "50%",
                                          marginLeft: "24em",
                                          marginTop: "10em",
                                        }}
                                        role="status"
                                      >
                                        <span class="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    data?.map((item, i) => {
                                      return (
                                        <tbody
                                          key={item.id}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                            ₹ {Math.round(item.inst_amount).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                            </td>
                                            <td>
                                              ₹{" "}
                                              {parseInt(
                                                item.inst_amount
                                              ).toLocaleString("en-IN", {
                                                maximumFractionDigits: 0,
                                              })}
                                            </td>
                                            <td>{item.inst_duration} Days</td>
                                          </tr>
                                        </tbody>
                                      );
                                    })
                                  )}
                                </table>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal> */}
                            <table className="table table-nowrap mt-2">
                              <thead>
                                <tr className="text-center">
                               
                                  <th>SR. NO.</th>
                                  <th>Name</th>
                                  <th>STATUS </th>
                                  <th>ACTIONS</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                               
                                  <tbody   className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}>
                                    <tr>
                                      <td>
                                        <>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                        </>
                                      </td>
                                    </tr>
                                  </tbody>
                              ) : (
                                list?.map((item, i) => {
                                  const sNo =
                                    (currentPage - 1) * itemsPerPage + i + 1;

                                  return (
                                    <tbody
                                      key={item.id}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <tr className="text-center">
                                        <td>{sNo}</td>
                                        <td>{item?.name}</td>
                                        {/* <td>₹ {Math.round(item.amount).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} </td> */}

                                        <td>
                                          <Switch
                                            onChange={(e) => {
                                              handleChange(
                                                item?.id,
                                                item?.status
                                              );
                                            }}
                                            checked={
                                              item?.status === "1"
                                                ? true
                                                : false
                                            }
                                            // id={item?.id}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={38}
                                            className="react-switch"
                                          />
                                        </td>
                                        <td
                                          className="text-center "
                                          style={{ width: "100px" }}
                                        >
                                          <Tooltip title="Add Students">
                                            <Button
                                              type="button"
                                              className="btn btn-warning text-white"
                                              style={{ marginLeft: "5px" }}
                                              onClick={() => {
                                                var ciphertext =
                                                  CryptoJS.AES.encrypt(
                                                    JSON.stringify(item?.id),
                                                    "my-secret-key@admin8623009959"
                                                  ).toString();
                                                let ciphertexts =
                                                  encodeURIComponent(
                                                    ciphertext
                                                  );
                                                navigate(
                                                  `/admin/batcheslink/${item?.slug}/${ciphertexts}`
                                                );
                                              }}
                                            >
                                              {" "}
                                              <i
                                                className="fa fa-user-plus"
                                                aria-hidden="true"
                                              ></i>
                                            </Button>
                                          </Tooltip>
                                          <Tooltip title="Show Students">
                                            <Button
                                              type="button"
                                              className="btn btn-info text-white"
                                              style={{ marginLeft: "5px" }}
                                              // onClick={() =>
                                              //   handelAddCourse(item?.slug)
                                              // }
                                              onClick={() => {
                                                var ciphertext =
                                                  CryptoJS.AES.encrypt(
                                                    JSON.stringify(item?.id),
                                                    "my-secret-key@admin8623009959"
                                                  ).toString();
                                                let ciphertexts =
                                                  encodeURIComponent(
                                                    ciphertext
                                                  );
                                                navigate(
                                                  `/admin/batchListStudents/${item?.slug}/${ciphertexts}`
                                                );
                                              }}
                                            >
                                              Show Students
                                            </Button>
                                          </Tooltip>
                                          <Tooltip title="Edit">
                                            {" "}
                                            <button
                                              onClick={() => {
                                                handleEdit(item?.id);
                                                // setIdData(item?.id)
                                              }}
                                              type="button"
                                              className="btn btn-secondary"
                                            >
                                              <i className="fa-regular fa-pen-to-square"></i>
                                            </button>{" "}
                                          </Tooltip>
                                          <Tooltip title="Delete">
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() => {
                                                setIdValue(item?.id),
                                                  showConfirm(item?.id);
                                              }}
                                            >
                                              <i className="fa-regular fa-trash-can"></i>
                                            </button>
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              )}                            
                            </table>
                            {list?.length <= 0 ? (
                                <h5 className="text-center">{message}</h5>
                              ) : (
                                ""
                              )}
                            {/* Instructor List */}
                          </div>
                          <div style={{ position: "relative", top: "-.7em" }}>
                             {list?.length <= 0 ? (
                                ''
                              ) : (
                                <PaginationVideo
                              nPages={last_page}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              setLast_page={setLast_page}
                              link={SHOWBATCH}
                              text={text}
                              setData={setList}
                              linkSearch={BATCHSEARCH}
                            />
                              )}
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
        {/* <ToastContainer /> */}
      </div>
      <Footer />
    </div>
  );
}
