import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "../../../api/axios";
import Wave from "../../../assets/img/TestResult/wavy.png";
const QUESTIONSRESULT="api/student/test-result-analytics"
import ApexCharts from 'react-apexcharts';
import Chart from "react-apexcharts";
import { schemeCategory10 } from 'd3-scale-chromatic';
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import{CheckCircleTwoTone, CloseCircleOutlined}from'@ant-design/icons';
import MyChart from "./chart.jsx"
import BarChart from "./Barchrat";
import StackedBarChart from "./StackedBar";
import Subjectwise from "./Subjectwise";
import "./graphs.css"
import axiosInstance from "../../../api/axios1";
import scorecard from "../../../assets/img/Dashboard/Scorecard.png"
import Accuracyimg from "../../../assets/img/Dashboard/Accuracy.png"

const Result = ({attempt}) => {
  // console.log(attempt,"attempt")
  const id = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [result, setResult] = useState([]);
  const token = localStorage.getItem("tokenStudent");
  const[subjectdata,setSubjectData]=useState([])
  const [attemptedques,setAttemptedques]=useState()
  const [statusresult,setStatusresult]=useState()

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong> Your exam has been Sumited</strong>
        </div>
      ),
      description:
        "You had closed the tab or changed the URL so the Exam has been submited. So Please attempt the Test Again",
      duration: 5,
    });
  };
  const fecthquestionResult = async () => {
    try {
      setLoading(true);
      const responce = await axiosInstance.get(`${QUESTIONSRESULT}/${id?.id}/${attempt}`, config);
      //  console.log(responce,"response")
      setResult(responce?.data);
      setStatusresult(responce?.status)
      // setAttemptedques(responce?.data?.totalQuestion
      //   -(responce?.data?.correct+responce?.data?.wrong))
      setAttemptedques(responce?.data?.totalQuestion-responce?.data?.leaveQuestion)
      const subjectWiseData = responce?.data?.subject?.map(subject => {
        const subjectResult = responce?.data?.subjectResult?.find(result => result.name === subject);
        const wrongQuestions = responce?.data?.wrongQuestions?.filter(question => question.subject === subject);
      const unattemptedQuestios=responce?.data?.leaveQuestions?.filter(question=>question?.subject===subject)
      const uniqueID = Math.random().toString(36).substr(2, 9);

        return {
          subjectResult: {
            ...subjectResult,
            attemptedquestions: subjectResult?.correct + subjectResult?.wrong,
            unattemptedQuestios1:subjectResult?.count-(subjectResult?.correct + subjectResult?.wrong),
            id:uniqueID,
            wrongansquestions: wrongQuestions,
            unattemptedQuestios:unattemptedQuestios,
            negativeMark:responce?.data?.negativeMark
          },
         
        };
      });
      
      const finalData = {
        subjectWiseData
      };
      setSubjectData(finalData)
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      }
      if (error?.response?.status === 403) {
        openNotificationWithIcon("error");
        // setTimeout(() => {
        //   navigate("/student/test-series");
        // }, 6000);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    fecthquestionResult();
  }, [attempt]);
  // console.log(attemptedques,"attemots")
  const chartOptions = {
    series: [attemptedques?attemptedques:0,result?.leaveQuestion?result?.leaveQuestion:0],
    chart: {
      width: "100%",
      type: "donut",
      // height:"100%"
    },
    labels: ["Attempted","Unattempted"],
    colors:[   "#6fdc6f","#66b0e5"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
            
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'dark',
    //     type: 'vertical',
    //     shadeIntensity: 0.5,
    //     inverseColors: false,
    //     opacityFrom: 1,
    //     opacityTo: 0.8,
    //     stops: [0, 100]
    //   }
    // }
  };
  const capitalizedLabels = result?.subject?.map(subject => {
    return subject?.charAt(0).toUpperCase() + subject.slice(1);
  });
  let counts = [];

  if (result?.subjectQuestionCount) {
    result?.subjectQuestionCount?.forEach(obj => {
      const count = Object.values(obj)[0];
      if (count !== undefined) {
        counts.push(count*result?.eachQuestionMark);
      }
    });
  }
  const colorPalette = schemeCategory10.slice(0, capitalizedLabels?.length);
  const chartOptions1 = {
    series:[result?.correctAnswer?result?.correctAnswer:0,result?.wrongAnswer?result?.wrongAnswer:0],
    chart: {
      width: "100%",
      type: "pie",
      //  height:"100%"

    },
    labels: ["Correct Answers","Wrong Answers"],
    colors:["#6fdc6f", "#f15b5b"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'dark',
    //     type: 'vertical',
    //     shadeIntensity: 0.5,
    //     inverseColors: false,
    //     opacityFrom: 1,
    //     opacityTo: 0.8,
    //     stops: [0, 100]
    //   }
    // }
  };
  // console.log(result,"result")
  // console.log(statusresult,"subjectdata")
  return (
    <>
     {loading === true ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        >
          <div className="spinner-border text-warning" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>):(
          <>
         <div className="page-content"
         style={{
          background: "#d08e8924",
      // padding:"10px"
      }}
         >{statusresult!==210?
         <div className="container"
         style={{padding:"10px"}}
         >
         <div className="row"
         style={{justifyContent:"center"}}
         >
                 <div className="col-md-5 d-flex">
                   <div className="card instructor-card w-100"
                  style={{border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                  >
                     <div className="card-body d-flex"
                     style={{justifyContent:"space-between"}}
                     >
                       <div className="instructor-inner">
                         <h6 style={{color:"black",fontWeight:700}}>Scored Marks</h6>
                         <h4 className="instructor-text-success"
                         style={{fontWeight:800}}
                         >
                          {(result?.totalMarks)>=0?(result?.totalMarks).toFixed(2):result?.totalMarks}
                         
                          </h4>
                       </div>
                       <div className="instructo-inner">
                        <img src={scorecard} alt="image"/>
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="col-md-5 d-flex">
                   <div className="card instructor-card w-100"
                  style={{border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}

                   >
                     <div className="card-body d-flex"
                     style={{justifyContent:"space-between"}}

                     >
                       <div className="instructor-inner">
                         <h6 style={{color:"black",fontWeight:700}}>Accuracy</h6>
                         <h4 className="instructor-text-info"
                         style={{color:"#f00e21",fontWeight:800}}
                         >{((result?.correctAnswer / result?.totalAttempt) * 100).toFixed(2)>=0?((result?.correctAnswer / result?.totalAttempt) * 100).toFixed(2):0}%</h4>
                       </div>
                       <div className="instructo-inner">
                        <img src={Accuracyimg} alt="image"/>
                       </div>
                     </div>
                   </div>
                 </div>
                
               </div>
 
               <div className="row"
               style={{justifyContent:"center"}}
               >
                 <div className="col-md-5"
                 >
                   <div className="settings-widget krishna1"
                  style={{border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                  >
                     <div className="settings-inner-blk"
                                            //  style={{borderBottom:"none",backgroundColor:"#cbd9e6"}}

                     >
                       <div
                         className="d-flex"
                         style={{ justifyContent: "space-between" }}
                       >
                         <h4
                           style={{
                             marginLeft: "20px",
                             marginTop: "15px",
                             color: " #392C7D",
                             fontSize:"19px"
                           }}
                         >
                           Total quesions : {result?.totalQuestion}
                         </h4>
                         <h4
                           style={{
                             marginLeft: "20px",
                             marginTop: "15px",
                             color: " #392C7D",
                             marginRight: "30px",
                             fontSize:"19px"
                           }}
                         >
                           Total Marks :{result?.totalQuestion*result?.eachQuestionMark}
                         </h4>
                       </div>
 
                       <div className="sell-course-head comman-space graph2">
                         <div className="chart">
                           <Chart
                             options={chartOptions}
                             series={chartOptions.series}
                             type={chartOptions?.chart?.type}
                             width={chartOptions.chart.width}
                             
                           />
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="col-md-5"
                 >
                   <div className="settings-widget krishna2"
                              style={{border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}


                   >
                     <div
                       className="settings-inner-blk p-0"
                      //  style={{borderBottom:"none",backgroundColor:"rgb(197 236 190 / 39%)"}}
                     >
                       <div
                         className="d-flex"
                         style={{ justifyContent: "space-between" }}
                       >
                         <h4
                           style={{
                             marginLeft: "20px",
                             marginTop: "15px",
                             color: "#392C7D",
                             fontSize:"19px"
                           }}
                         >
                           Positive Marks :{(result?.eachQuestionMark*result?.correctAnswer).toFixed(2)}
                         </h4>
                         <h4
                           style={{
                             marginLeft: "20px",
                             marginTop: "15px",
                             color: "#392C7D",
                             marginRight: "30px",
                             fontSize:"19px"
                           }}
                         >
                           Negative Marks : {(result?.negativeMark*result?.wrongAnswer).toFixed(2)}
                         </h4>
                       </div>
 
                       <div className="sell-course-head comman-space graph2"
                       
                       >
                         <div className="chart">
                           <Chart
                             options={chartOptions1}
                             series={chartOptions1.series}
                             type={chartOptions1?.chart?.type}
                             width={chartOptions1.chart.width}
                             
                           />
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
              <div className="row">
               <div className="col-lg-12"
               style={{justifyContent:"center"}}
               >
               <h3 style={{marginLeft:"120px"}}>SubjectWise Analysis</h3>
               <Subjectwise result={result} data={subjectdata?.subjectWiseData} data1={subjectdata}/>

               </div>
              </div>
 
               
               
               
 
         </div>: <div className="col-md-5 d-flex"
         style={{margin:"auto"}}
         >
                   <div className="card instructor-card w-100"
                  style={{border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                  >
                    <div className="card-body d-flex"
                     style={{justifyContent:"space-between"}}
                     >
                       <div className="instructor-inner">
                         <h4 style={{color:"black",fontWeight:700}}>No Result Found</h4>
                         <h6 className="instructor-text-success"
                         style={{fontWeight:400,color:"#7f8280",textTransform:"capitalize"}}
                         >
                          you had not submited the test Properly
                         
                          </h6>
                       </div>
                       <div className="instructo-inner">
                        <img src={scorecard} alt="image"/>
                       </div>
                     </div>
                   </div>
                 </div>}
       </div>
       </>
          
        )}
      
    </>
  );
};

export default Result;
