import React from 'react'
import TeacherSideBar from '../TeacherNavbar'
import FooterStudent from '../../fotterstudent'
import ChatApp from '../TeacherChat'

const TeacherDashboard = () => {
  return (
    <div className="main-wrapper">
    < TeacherSideBar activeMenu={"/teacher/dashboard"} />
    <div className="page-content instructor-page-content"
    style={{
      // background: "#d08e8924",
  padding:"10px",
  marginTop:"-10px",
  borderRadius:"8px",
  }}
    >
      
        <ChatApp/>
    </div>
    {/* <FooterStudent /> */}
  </div>
  )
}

export default TeacherDashboard