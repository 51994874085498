import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../footer";
import "./buy.css";
import { CheckCircle } from "../../../imagepath";
import StudentHeader from "../../../student/studentHeader";
import { useState } from "react";
import axios from "../../../../api/axios";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notification, Space } from "antd";

const BUYCOURSE = "/api/student/buy-course";
const SUBCRIPTION = "/api/student/course-subscription";
const EMI = "/api/student/course-emi-subscription";
import { Collapse, Table } from "react-bootstrap";

const BuyCourse = () => {
  const [description, setDescription] = useState("");
  const [courseCount,setcourseCount]=useState('')
  const [installmentCount,setinstallmentCount]=useState('')
  const [paymentType,setpaymentType]=useState('')
  const [paymentOptions,setpaymentOptions]=useState('')
  const navigate = useNavigate();
  const tokenStudent = localStorage.getItem("tokenStudent");
  const [data, setData] = useState(null);
  const { slug } = useParams();
  const [full, setFull] = useState(null);
  const [emi, setEmi] = useState(null);
  const [getId, setGetId] = useState("");
  const [loading, setLoading] = useState(false);
  const [emiOptionPrice, setEmiOptionPrice] = useState(null);
  const [emiOptionNumber, setEmiOptionNumber] = useState("");
  const [paymentOptionCptId, setPaymentOptionCptId] = useState("");
  const [paymentOptionpayment_type, setPaymentOptionpayment_type] =
    useState("");
  const [cpt,setcpt]=useState ('')

  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };

  const getBuyCourse = async () => {
    try{
    setLoading(true);
    const responce = await axios.get(`${BUYCOURSE}/${slug}`, config);
    setFull(responce?.data?.fullPayment);
    setGetId(responce.data.fullPayment.cpt_id);
    setData(responce.data.data);
    setEmi(responce.data.emiOptions);
    setpaymentOptions(responce?.data?.paymentOptions)
    setEmiOptionPrice(responce.data.emiOption);
    setDescription(responce.data.data?.description);
    setPaymentOptionpayment_type(responce.data.paymentOptionpayment_type);
    setcourseCount(responce?.data?.courseCount)
    setpaymentType(responce?.data?.paymentType)
    setinstallmentCount(responce?.data?.installmentCount)
    setEmiOptionNumber(responce.data.emiOptionNumber);
    setPaymentOptionCptId(responce.data.paymentOptionCptId);
    responce.data.emiOptions.map((it) => {
      if (it.cpt_id === responce.data.paymentOptionCptId) {
        setGetId(it.cpt_id);
      }
    });
    responce?.data?.paymentOptions?.map((e)=>{
      setcpt(e.cpt_id)
    })
    setLoading(false);
  }
  catch(error){
    if (error?.response?.status === 401) {
      localStorage.removeItem('token')
      navigate('/login')
    }
    openNotificationWithIcon3("error")
  }
  };

  useEffect(() => {
    getBuyCourse();
  }, []);

  // const handleSubmmit = async () => {
  //   console.log(getId);
  //   console.log(slug);
  //   try{

    
  //   setLoading(true);
  //   const responce = await axios.post(
  //     SUBCRIPTION,
  //     { paymentOption: getId },
  //     config
  //   );
  //   if (responce) {
  //     setTimeout(() => {
  //       navigate(`/student/my-subscriptions`);
  //     }, 1000);
  //     openNotificationWithIcon1("success")
  //     // toast.success("Course Is Buyed");
  //   }
  //   setLoading(false);
  // }
  // catch(error){
  //   if (error?.response?.status === 401) {
  //     localStorage.removeItem('token')
  //     navigate('/login')
  //   }
  //   openNotificationWithIcon3("error")
  // }
  // };
  const handleSubmmitEmi = async () => {
    try{
    setLoading(true);
    const responce = await axios.post(EMI, { paymentOption: cpt }, config);
    if (responce) {
      setTimeout(() => {
        navigate(`/student/my-subscriptions`);
      }, 1000);
      openNotificationWithIcon2("success")
      // toast.success("Course Emi Payed Successsfuly");
    }
    setLoading(false);
  }
  catch(error){
    if (error?.response?.status === 401) {
      localStorage.removeItem('token')
      navigate('/login')
    }
    openNotificationWithIcon3("error")
    setLoading(false)

  }
  };

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    const tokenStudent = localStorage.getItem("tokenStudent");
    if (!tokenStudent) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    const lastVisiteStudentRoute=localStorage.getItem("lastVisiteStudentRoute")
    if(lastVisiteStudentRoute){
      localStorage.removeItem("lastVisiteStudentRoute")
    }
    else{
      localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);

    }
  }, []);
  const [selectedPlan, setSelectedPlan] = useState(full?.cpt_id);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const openNotificationWithIcon1 = (type) => {
    // console.log("toast after click")
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "#52c41a" }}>
                <strong>Course Is Buyed </strong>
              </div>
            ),

          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted

          // </div>)
          duration: 1
        });
  };
  const openNotificationWithIcon2 = (type) => {
    // console.log("toast after click")
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "#52c41a" }}>
                <strong>Course Emi Payed Successsfuly</strong>
              </div>
            ),

          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted

          // </div>)
          duration: 1
        });
  };
  const openNotificationWithIcon3= (type) => {
    // console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"red"}}>
         <strong>Internal Server Error </strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };  
  return (
    <>
      <div className="main-wrapper">
        <StudentHeader activeMenu={"PricingPlan"} />

        <div className="breadcrumb-bar">
          <div className="container"
                    style={{minWidth:"98%",margin:"auto"}}

          >
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        <Link to="/student/courses">Courses</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        <Link> Buy Course</Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loading === true ? (
         <div
         style={{ display: 'block' }}
         id="cover-spin"
       >
       </div>
        ) : (
          <section className="course-content checkout-widget">
            <div className="container "
              style={{minWidth:"98%",margin:"auto"}}

            >
              <div className="row">
                <div className="col-lg-8">
                  {/* Billing Address */}

                  <div className="cart-head"
                  style={{padding:"20px 0px"}}
                  >
                    <h4>Select Payment Type</h4>
                  </div>

                  {full ? (
                    <>
                      {courseCount===0 ?<>
                        <div
                            className="plan-box shadow-sm rounded p-4"
                            style={{ transition: "box-shadow 0.3s ease" }}
                          >
                            <div>
                              <h4>Full Payment</h4>
                              <p>
                                Pay ₹{" "}
                                {data?.amount === 0
                                  ? "Free"
                                  : parseInt(data?.amount).toLocaleString(
                                      "en-IN",
                                      {
                                        maximumFractionDigits: 0,
                                      }
                                    )}{" "}
                                at once and avail complete course benefits.
                              </p>
                            </div>
                            <h3>
                              <input
                                name="plan"
                                className="form-check-input"
                                value={full?.cpt_id}
                                type="radio"
                                onChange={(e) => {
                                  setSelectedPlan(full?.cpt_id);
                                  setGetId(full?.cpt_id);
                                  setSelectedPayment(null); // Set selected payment as null for full payment
                                }}
                                checked={
                                  selectedPlan === full?.cpt_id || !selectedPlan
                                }
                              />
                            </h3>
                          </div>
                          {emi?.map((item, i) => {
                            let installmentNumber = 1;
                            return (
                              <>
                                <div
                                  key={i}
                                  className="plan-box shadow-sm rounded p-4"
                                  style={{
                                    transition: "box-shadow 0.3s ease",
                                  }}
                                >
                                  <div className="container">
                                    <h4>Installment {i + 1}</h4>
                                    <div>        
                                      <h6 className="cou-title">
                                        <Link
                                          className="collapsed"
                                          onClick={() => handleToggle(i)}
                                          aria-controls="example-collapse-text"
                                        >
                                          View Price Breakup
                                        </Link>
                                      </h6>

                                      <Collapse
                                        in={openIndex === i}
                                        id="collapseOne"
                                        className="card-collapse collapse"
                                      >
                                        <table className="table text-center table-borderless table-nowrap mb-0">
                                          <thead>
                                            <tr>
                                              <th>Inst.No</th>
                                              <th>Amount</th>
                                              <th>Duration</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {emiOptionPrice?.map(
                                              (setEmiOptionPrice, index) => {
                                                if (
                                                  item.cpt_id ===
                                                  setEmiOptionPrice.cpt_id
                                                ) {
                                                  const currentInstallmentNumber =
                                                    installmentNumber;
                                                  installmentNumber++;
                                                  return (
                                                    <tr data-index={index}>
                                                      <td>
                                                        {
                                                          currentInstallmentNumber
                                                        }
                                                      </td>
                                                      <td>
                                                        ₹{" "}
                                                        {parseInt(
                                                          setEmiOptionPrice.inst_amount
                                                        ).toLocaleString(
                                                          "en-IN",
                                                          {
                                                            maximumFractionDigits: 0,
                                                          }
                                                        )}
                                                      </td>
                                                      <td>
                                                        {
                                                          setEmiOptionPrice.inst_duration
                                                        }{" "}
                                                        Days
                                                      </td>
                                                    </tr>
                                                  );
                                                } else {
                                                  return null;
                                                }
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      </Collapse>
                                    </div>
                                  </div>
                                  <h3>
                                    <input
                                      name="plan"
                                      className="form-check-input"
                                      type="radio"
                                      value={item.cpt_id}
                                      onChange={(e) => {
                                        setSelectedPlan(item.cpt_id);
                                        setGetId(item.cpt_id);
                                        // setSelectedPayment(item.inst_amount);
                                      }}
                                    />
                                  </h3>
                                </div>
                              </>
                            );
                          })}
                      </>:courseCount===1 && paymentType==='1' && installmentCount===0?<>
                      {paymentOptions?.map((item, i) => {
                            let installmentNumber = 1;
                            return (
                              <>
                                <div
                                  key={i}
                                  className="plan-box shadow-sm rounded p-4"
                                  style={{
                                    transition: "box-shadow 0.3s ease",
                                  }}
                                >
                                  <div className="container">
                                    <h4>Installment {i + 1}</h4>
                                    <div>        
                                      <h6 className="cou-title">
                                        <Link
                                          className="collapsed"
                                          onClick={() => handleToggle(i)}
                                          aria-controls="example-collapse-text"
                                        >
                                          View Price Breakup
                                        </Link>
                                      </h6>

                                      <Collapse
                                        in={openIndex === i}
                                        id="collapseOne"
                                        className="card-collapse collapse"
                                      >
                                        <table className="table text-center table-borderless table-nowrap mb-0">
                                          <thead>
                                            <tr>
                                              <th>Inst.No</th>
                                              <th>Amount</th>
                                              <th>Duration</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {emiOptionPrice?.map(
                                              (setEmiOptionPrice, index) => {
                                                if (
                                                  item.cpt_id ===
                                                  setEmiOptionPrice.cpt_id
                                                ) {
                                                  const currentInstallmentNumber =
                                                    installmentNumber;
                                                  installmentNumber++;
                                                  return (
                                                    <tr data-index={index}>
                                                      <td>
                                                        {
                                                          currentInstallmentNumber
                                                        }
                                                      </td>
                                                      <td>
                                                        ₹{" "}
                                                        {parseInt(
                                                          setEmiOptionPrice.inst_amount
                                                        ).toLocaleString(
                                                          "en-IN",
                                                          {
                                                            maximumFractionDigits: 0,
                                                          }
                                                        )}
                                                      </td>
                                                      <td>
                                                        {
                                                          setEmiOptionPrice.inst_duration
                                                        }{" "}
                                                        Days
                                                      </td>
                                                    </tr>
                                                  );
                                                } else {
                                                  return null;
                                                }
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      </Collapse>
                                    </div>
                                  </div>
                                  <h3>
                                    <input
                                      name="plan"
                                      className="form-check-input"
                                      type="radio"
                                      value={item.cpt_id}
                                      onChange={(e) => {
                                        setSelectedPlan(item.cpt_id);
                                        setcpt(item.cpt_id);
                                        // setSelectedPayment(item.inst_amount);
                                      }}
                                      checked={true}
                                    />
                                  </h3>
                                </div>
                              </>
                            );
                          })}
                      </>:  <div className="plan-box shadow-sm rounded p-4 d-flex align-content-center justify-content-center">
                          <h3>Course Already Purchased</h3>
                        </div>}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-lg-4 theiaStickySidebar">
                  <div className="stickysidebar">
                    <div
                      className="student-widget select-plan-group mt-2 shadow-sm rounded p-4"
                      style={{ transition: "box-shadow 0.3s ease" }}
                    >
                      <div className="student-widget-group">
                        <div className="plan-header">
                          <h4>Selected Course</h4>
                        </div>
                        <div className="basic-plan">
                          <h3>{data?.name}</h3>
                          <h2>
                            <span>₹</span>
                             {selectedPayment && selectedPayment.amount !== 0
                              ? parseInt(selectedPayment.amount).toLocaleString(
                                  "en-IN",
                                  {
                                    maximumFractionDigits: 0,
                                  }
                                )
                              : selectedPayment === null 
                              ? parseInt(data?.amount).toLocaleString(
                                  "en-IN",
                                  {
                                    maximumFractionDigits: 0,
                                  }
                                )
                              : data?.amount === 0
                              ? "Free"
                              : +parseInt(data?.amount).toLocaleString(
                                  "en-IN",
                                  {
                                    maximumFractionDigits: 0,
                                  }
                                )}
                          </h2>
                        </div>
                        <div className="plan-change">
                          {courseCount===0?    <a
                          target="_blank"
                          href={`https://wa.me/9640671866?text=I%20need%20to%20buy%20this%20course%20${data.name}`}
                              // onClick={() => {
                              //   handleSubmmit();
                                
                              // }}
                              className="btn btn-secondary w-100 mt-3 bg-primary"
                              style={{ height: "50px" }}
                            >
                              <span className="button-text">Buy Now</span>
                            </a>:''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <ToastContainer />
        <Footer />
      </div>
    </>
  );
};

export default BuyCourse;

{
  /* {loading === true ? (
          <div
            class="d-flex justify-content-center"
            style={{ position: "absolute" }}
          >
            <div
              class="spinner-border text-warning"
              style={{
                left: "50%",
                marginLeft: "24em",
                marginTop: "10em",
              }}
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <section className="course-content">
            <div className="container">
              <h4 className="mr-8">Select Payment Type</h4>
              {/* Plan Type *
              <div className="row">
                <div className="col-lg-4">
                  {/* Image *
                  <div>
                    <img
                      className="img-fluid"
                      src={`https://lmscode.stellarflux.com/${data?.image}`}
                      alt=""
                      style={{
                        width: "100%",
                        height: "150px",
                        marginTop: "8px",
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-4 d-flex justify-content-between" >
                  {full ? (
                    <div className="plan-box">
                      <div>
                        <h4>Full Payment</h4>
                        <p>
                          Pay ₹ {data.amount} at once and avail complete course
                          benefits.
                        </p>
                      </div>
                      <h3>
                        <input
                          name="plan"
                          className="form-check-input"
                          value={full.cpt_id}
                          type="radio"
                          onChange={(e) => setGetId(full.cpt_id)}
                        />
                      </h3>
                    </div>
                  ) : (
                    ""
                  )}

                  {emi?.map((item, i) => {
                    return (
                      <div key={i} className="plan-box">
                        <div>
                          <h4>Installment {i + 1}</h4>
                          <p>
                            Pay ₹ {item.inst_amount} with{" "}
                            {item.total_inst_count} Installment
                          </p>
                        </div>
                        <h3>
                          <input
                            name="plan"
                            className="form-check-input"
                            type="radio"
                            value={item.cpt_id}
                            onChange={(e) => setGetId(item.cpt_id)}
                          />
                        </h3>
                      </div>
                    );
                  })}
                </div>

                <div className="col-lg-4">
                  {/* Buy Now button *
                  <div className="benefit-box">
                    <h6 className="text-center">Buy Course</h6>
                    <button
                      onClick={() => {
                        handleSubmmit();
                      }}
                      className="btn btn-secondary w-100"
                      style={{ height: "40px" }}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  {/* Description box *
                  <div className="card complete-sec">
                    <div className="card-body">
                      <h2 className="text-center">{data?.name}</h2>
                      <div className="com-info">
                        <DescriptionWithReadMore
                          contentEditable="true"
                          description={description}
                          wordLimit={40}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {loading === true ? (
          <div
            class="d-flex justify-content-center"
            style={{ position: "absolute" }}
          >
            <div
              class="spinner-border text-warning"
              style={{
                left: "50%",
                marginLeft: "24em",
                marginTop: "10em",
              }}
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="container"></div>
        )} */
}
