import React, { useContext, useEffect, useState } from "react";

import AdminHeader from "../adminHeader";
import Footer from "../../footer";
import Form from "react-bootstrap/Form";
import moment from "moment";

import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import { Input, Tooltip } from "antd";
import Button from "react-bootstrap/Button";
import Select, { components } from "react-select";

import Switch from "react-switch";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import AuthContext from "../../../context/AuthProvider";
import axios from "../../../api/axios";
import CryptoJS from "crypto-js";
const STUDENTLIST = "/api/student/paid-students";
const DELETESTUDENT = "/api/student/delete";
const STUDENTSTATUS = "/api/student/status";
const COURSEDETAILS = "api/viewInstallment/all-course";
const STUDENTSERCH = "api/student/paid-students-search";

import "react-toastify/dist/ReactToastify.css";
import { notification, Modal } from "antd";

import "../addAdminRecordedVideos/insex.css";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";
const { confirm } = Modal;
const PAIDEXCEl = "/api/student/export-paiduser";
const FREESTUDENTS="/api/admin/paidStudent";
const CENTERSLIST = "/api/public/academy_centers";


export default function AdminInstructorStudentGrid() {
  const { Search } = Input;
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [itemsPerPage, setitemsPerPage] = useState("");
  const [last_page, setLast_page] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [install, setinstall] = useState(null);
  const [admissionData, setadmissionData] = useState(null);
  const [id, setId] = useState("");
  const [message, setmessage] = useState("No Data");
  const [courselist, setcourselist] = useState(null);
  const [showImport, setShowImport] = useState(false);
  const [text, settext] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [total_amount, settotal_amount] = useState("");
  const [remaing, setRemaing] = useState("");
  const [sum, setsum] = useState("");
  const [uid, setuid] = useState("");
  const [centers, setCenters] = useState([]);
  const [centersId, setCentersId] = useState("");

  // console.log(uid,"id")
const[excel,setExcel]=useState([])
  const [total, setTotal] = useState("");
  const[freecount,setFreeCount]=useState("")

  const navigate = useNavigate();
  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);
  // console.log(token)
  const { auth } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const courseDetails = async () => {
    try {
      const response = await axios.get(`${COURSEDETAILS}/${uid}`, config);
      // console.log(response.data);
      let arr = [];
      for (let x of response.data) {
        for (let y of x.course) {
          arr.push(y);
        }
      }
      const key = "id";

      const arrayUniqueByKey = [
        ...new Map(arr.map((item) => [item[key], item])).values(),
      ];

      setcourselist(arrayUniqueByKey);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  useEffect(() => {
    // courseDetails();
  }, []);
  const fecthSudentList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(STUDENTLIST, config);
      setLast_page(resonse.data.last_page);
       setList(resonse?.data?.data);
      setitemsPerPage(resonse?.data?.data.length);
      setTotal(resonse?.data?.total);
      setData(resonse.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon1("error", error?.response?.data?.message,"red");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error")
      }
    }
  };
  const freeStudnetsList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(FREESTUDENTS, config);
      // console.log(resonse, "test");
      setFreeCount(resonse?.data)
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };

  useEffect(() => {
    fecthSudentList();
    freeStudnetsList()
  }, []);
  useEffect(() => {
    const CentersList = async () => {
      try {
        const response = await axios.get(CENTERSLIST);
        setCenters(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    };

    CentersList();
  }, []);
  const handleShowImport = () => {
    setShowImport(true);
  };

  const handleClose = () => setShow(false);
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Student Deleted</strong>
        </div>
      ),
      duration: 1,
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon1 = (type, text, col) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const handleDeleteStudent = async () => {
    if (id) {
      setLoading(true);
      const resonse = await axios.get(`${DELETESTUDENT}/${id}`, config);
      setLoading(false);
      openNotificationWithIcon("info");
      fecthSudentList();
      setShow(false);
      // toast.warning("Student Deleted");
      return resonse;
      // console.log("resonse", resonse);
    }
  };
  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this student?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETESTUDENT}/${id}`, config);
          setLoading(false);
          openNotificationWithIcon("success");
          fecthSudentList();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else if (error?.response?.status === 403) {
            localStorage.removeItem("token");
            openNotificationWithIcon1("error", error?.response?.data?.message,"red");
            setTimeout(() => {
              navigate("/admin/login");
            }, 1000); // Delay the navigation to show the notification
          }
          else{
          openNotificationWithIcon2("error");
          }
        }
      },
      onCancel() {},
    });
  }
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      STUDENTSTATUS,
      { id: id, status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1("success", "Status updated", "#52c41a");
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    fecthSudentList();
    // toast.success("Status Change");
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
    // console.log(list);
  };
  const handleShow = async (id) => {
    setShow(true);
    setId(id);
  };
  const handleEdit = (id) => {
    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/edituser/${ciphertexts}`);
  };
  const handleCourse = (id) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/course-list/${ciphertexts}`);
  };
  const Filter = async (e) => {
    const searchValue = e.target.value;
    settext(searchValue);
    const res = await axios.post(STUDENTSERCH, { name: searchValue }, config);
    if (res.data.data.length > 0) {
      setList(res.data.data);
      setLast_page(res.data.last_page);
      setmessage("No Data");
    }
    if (res.data.data.length == 0) {
      setList(res.data.data);
      setmessage("No Data Found");
    }
    if (searchValue.length === 0) {
      // setList(data)
      const res = await axios.get(`${STUDENTLIST}?page=${currentPage}`, config);
      setList(res.data.data);
      setCurrentPage(1);
      setLast_page(res.data.last_page);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const handleView = async (id) => {
    setIsModalVisible(true);
    setLoadingTable(false);
    setuid(id);
    try {
      const response = await axios.get(`${COURSEDETAILS}/${id}`, config);
      // console.log(response.data);
      let arr = [];
      for (let x of response.data) {
        for (let y of x.course) {
          arr.push(y);
        }
      }
      const key = "id";

      const arrayUniqueByKey = [
        ...new Map(arr.map((item) => [item[key], item])).values(),
      ];

      setcourselist(arrayUniqueByKey);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon1("error", error?.response?.data?.message,"red");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
    }
  };
  const handleUser = async (e) => {
    try {
      setLoadingTable(true);
      const res = await axios.get(
        `api/viewInstallment/${uid}/${e.value}`,
        config
      );
      setinstall(res.data.viewInstallment);
      setadmissionData(res.data.date.created_at)
      let total_amount = [
        ...new Set(res.data.viewInstallment.map((e) => e.total_amount)),
      ];
      settotal_amount(Number(total_amount[0]));
      let inst_amount = res.data.viewInstallment.map((e) =>
        Number(e.inst_amount)
      );
      let sum = inst_amount.reduce((partialSum, a) => partialSum + a, 0);
      let amount = Number(total_amount[0]);
      setsum(sum);
      let rem = amount - sum;
      setRemaing(rem);
      setLoadingTable(false);
      setIsModalVisible(true);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{

      openNotificationWithIcon1("error", error?.response?.data?.message, "red");
    }
  }
  };
  let ids = 1;
  const config1 = {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "blob" },
    responseType: "arraybuffer",
  };
  const exportCsv = async (excel) => {
    try {
      const response = await axios.get(PAIDEXCEl, config1);
      setExcel(response.data);
      const fileExtension = ".xlsx";
      const data = new Blob([response.data], {
        type: "application/vnd.ms-excel",
      });
      FileSaver.saveAs(data, "Student" + fileExtension);
    } catch (error) {
      console.log(error);
      
      setWord(false);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{

      openNotificationWithIcon1("error", error.response.data.message, "red");
    }
    }
  };
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"paid"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget ttt">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3 style={{ textTransform: "capitalize" }}>
                            Paid Students
                          </h3>
                        </div>
                        <div className="comman-space pb-0">
                          <div
                          // className="settings-referral-blk table-responsive"
                          // id="table-value"
                          >
                            {/* Instructor Users */}

                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div
                                    className="row gx-2 align-items-center"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    <div className="col-md-6 col-item">
                                      <div className=" search-group">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Name"
                                          // allowClear
                                          onChange={Filter}
                                          onKeyPress={handleKeyPress}
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="col-md-6 col-lg-6 col-item"
                                      style={{
                                        justifyContent: "flex-end",
                                        gap: "5px",
                                      }}
                                    >
                                      <div
                                        className="button-container"
                                        style={{
                                          justifyContent: "flex-end",
                                          gap: "10px",
                                        }}
                                      >
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            exportCsv(excel);
                                          }}
                                          className=" btn custom-button-excel"
                                        >
                                          {" "}
                                          Download Excel{" "}
                                        </button>
                                        <button
                                          onClick={(e) => e.preventDefault()}
                                          className=" btn custom-button-total"
                                        >
                                          Total Students{":"}
                                          {freecount}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                             
                              <Modal
                                title="View Installment"
                                okButtonProps={{
                                  style: { backgroundColor: "#FF875A" },
                                  children: "Import",
                                }}
                                open={isModalVisible}
                                okText="ok"
                                onOk={() => {
                                  setIsModalVisible(false);
                                  setinstall(null);
                                  settotal_amount("");
                                  setsum("");
                                  setRemaing("");
                                }}
                                onCancel={() => {
                                  setIsModalVisible(false);
                                  setinstall(null);
                                  settotal_amount("");
                                  setsum("");
                                  setRemaing("");
                                }}
                              >
                                <Form>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Select Course</Form.Label>
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      name="modules"
                                      options={courselist?.map((ele) => {
                                        return {
                                          label: ele.name,
                                          value: ele.id,
                                        };
                                      })}
                                      placeholder="Select Course"
                                      onChange={(e) => handleUser(e)}
                                    ></Select>
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <table className="table table-bordered">
                                      <thead>
                                        <tr>
                                        <th scope="col">Admission Date</th>
                                          <th scope="col">Total Amount</th>
                                          <th scope="col">Paid Amount</th>
                                          <th scope="col">Remaing Amount</th>
                                          
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{moment(admissionData).format("DD-MM-YYYY")}</td>
                                          <td>{total_amount || 0}</td>
                                          <td>{sum || 0}</td>
                                          <td>
                                            {remaing < 0 ? 0 : remaing || 0}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    {" "}
                                    <table className="table table-borderless mb-0">
                                      <thead>
                                        <tr className="text-center">
                                          <th>Inst.No</th>
                                          <th>Installment Amount</th>
                                          <th>Payment Date</th>
                                        </tr>
                                      </thead>
                                      {loadingTable === true ? (
                                    <tbody  
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                      position: "fixed",
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.6)",
                                    }}>
                                      <tr>
                                        <td>
                                          <div
                                            className="spinner-border text-warning"
                                            role="status"
                                          >
                                            <span className="sr-only">
                                              Loading...
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                      ) : (
                                        install?.map((item, i) => {
                                          return (
                                            <tbody key={item?.id}>
                                              <tr className="text-center">
                                                <td>{i + 1}</td>
                                                <td>
                                                  ₹{" "}
                                                  {parseInt(
                                                    item?.inst_amount
                                                  ).toLocaleString("en-IN", {
                                                    maximumFractionDigits: 0,
                                                  })}
                                                </td>
                                                <td>
                                                  {item?.payment_date == null
                                                    ? moment(admissionData).format("DD-MM-YYYY")
                                                    : moment(
                                                        item?.payment_date
                                                      ).format("DD-MM-YYYY")}
                                                </td>
                                              </tr>
                                            </tbody>
                                          );
                                        })
                                      )}
                                    </table>
                                  </Form.Group>
                                </Form>
                              </Modal>
                              <table className="table table-nowrap mt-2">
                                <thead>
                                  <tr className="text-center">
                                    <th>SR. NO.</th>
                                    <th>NAME</th>
                                    <th>PHONE</th>
                                    <th>TYPE</th>
                                    <th className="text-center">CENTER NAME</th>

                                    <th>STATUS</th>

                                    {/* <th>MANAGE STUDENT</th> */}
                                    <th>ACTIONS</th>
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                {loading === true ? (
                                  
                                    <tbody  className="d-flex justify-content-center align-items-center"
                                    style={{
                                      position: "fixed",
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.6)",
                                    }}>
                                      <tr>
                                        <td>
                                          <div
                                            className="spinner-border text-warning"
                                            role="status"
                                          >
                                            <span className="sr-only">
                                              Loading...
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                ) : list?.length > 0 ? (
                                  list?.map((item, i) => {
                                    const serialNumber =
                                      (currentPage - 1) * itemsPerPage + i + 1;
                                      const centerObj = centers.find((center) => center?.id == item?.fk_academy_center_id);  
                                      // console.log(centerObj,"obj")
   
                                    return (
                                      <tbody
                                        key={item?.id}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        <tr style={{textAlign:"center"}}>
                                          <td>{serialNumber}</td>
                                          <td>
                                            <Link
                                              // to="student-profile"
                                              className="refer-avatar-blk align-items-center"
                                            >
                                              <p>{item?.name}</p>
                                            </Link>
                                          </td>
                                          {/* <td>{item?.email}</td> */}
                                          {/* <td>{item?.phone}</td> */}
                                          <td>{item?.phone}</td>
                                          <td>
                                            <a className="badge badge-success text-white">
                                              Paid User
                                            </a>
                                          </td>
                                          <td>{item?.center?.name}</td>

                                          <td>
                                            <div
                                              className="form-check form-switch"
                                              style={{ paddingLeft: "0px" }}
                                            >
                                              <Switch
                                                onChange={(e) => {
                                                  handleChange(
                                                    item.id,
                                                    item.status
                                                  );
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={20}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={15}
                                                width={38}
                                                className="react-switch"
                                                id="flexSwitchCheckChecked"
                                                checked={
                                                  item.status === "1"
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                          </td>
                                          {/* <td>
                                        <Button variant='info' className="text-white" onClick={()=>{handleCourse(item.id)}} >Subscription 
                                        </Button> 
                                        </td> */}
                                          <td>
                                            <Tooltip title="Edit">
                                              <Button
                                                onClick={() =>
                                                  handleEdit(item.id)
                                                }
                                                type="button"
                                                className="btn btn-secondary"
                                              >
                                                <i className="fa-regular fa-pen-to-square"></i>
                                              </Button>{" "}
                                            </Tooltip>
                                            {/* <Tooltip title="Subscription" >
                                            <Button
                                              onClick={() => { handleCourse(item.id) }}
                                              className="btn-success text-white"
                                              style={{
                                                backgroundColor: "#00e65b",
                                                border: "#00e65b"
                                              }}
                                            >
                                              ₹
                                            </Button>{" "}
                                          </Tooltip> */}
                                            <Tooltip title="View">
                                              <button
                                                onClick={() =>
                                                  handleView(item.id)
                                                }
                                                // type="button"
                                                className="btn btn-info"
                                              >
                                                <i
                                                  style={{ color: "white" }}
                                                  className="fa fa-eye"
                                                  aria-hidden="true"
                                                ></i>
                                              </button>{" "}
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                              <Button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                  showConfirm(item.id)
                                                }
                                              >
                                                <i className="fa-regular fa-trash-can"></i>
                                              </Button>
                                            </Tooltip>
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })
                                ) : (
                                 
                                    <>
                                    </>
                                )}
                              </table>
                            </div>

                            {list?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {list?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationVideo
                                nPages={last_page}
                                currentPage={currentPage}
                                text={text}
                                setCurrentPage={setCurrentPage}
                                linkSearch={STUDENTSERCH}
                                link={STUDENTLIST}
                                setData={setList}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
}
