import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import   "./textEditor.css";

const TextEditor = ({handleChange,value,setFieldValue,name}) => {
  return (
    <CKEditor
      editor={ClassicEditor}
    //   onReady={(editor) => {
    //     // You can store the "editor" and use when it is needed.
       
    //   }}
    config={{
      // Set the height of the CKEditor main container
      ckfinder: {
        options: {
          container: ".ck ck-editor__main",
          height: 400 // Adjust the height as needed
        }
      }
    }}
    data={value}
      onChange={(event, editor) => {
        const data = editor.getData();
        // setText(data)
        // handleChange(event)
        setFieldValue(name,data)
        // console.log({ event, editor, data });
      }}
    //   onBlur={(event, editor) => {
    //     console.log("Blur.", editor);
    //   }}
    //   onFocus={(event, editor) => {
    //     console.log("Focus.", editor);
    //   }}
    />
  );
};

export default TextEditor;
