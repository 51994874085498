import React, { useState, useContext, useEffect } from "react";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../context/AuthProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Select } from "antd";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import AdminHeader from "../../instructor/adminHeader";
import { notification } from "antd";

const CENTEREDIT = "/api/admin/academy_centers";
const CENTERLIST = "/api/public/location/states";
const CITYLIST = "api/public/location/cities";
const UPDATECENTERLIST = "/api/admin/academy_centers";

export default function AddEditCenterList() {
  const [selectedCenterId, setSelectedCenterId] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [submit, setSubmit] = useState(false);
  const [data, setData] = useState([]);
  const [name, setName] = useState("");

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const { auth } = useContext(AuthContext);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const fetchUser = async () => {
    setLoading(true);
    const response = await axios(`${CENTEREDIT}/${id}`, config);
    setData(response?.data?.message);
    setName(response?.data?.message?.name)
    setSelectedCenterId(response?.data?.message?.state?.id);
    setSelectedCityId(response?.data?.message?.city?.id);

    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const initalValue = {
    name: data?.name ? data?.name : "",
  };

  const RecordedSchema = Yup.object({
    name: Yup.string().min(2).required("Please enter the Center Name"),
  });

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const openNotificationWithIcon = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const handleCourseChange = (value) => {
    setSelectedCenterId(value);
  };

  const handleStudentChange = (value) => {
    setSelectedCityId(value);
  };

  const InstituteList = async () => {
    try {
      const res = await axios.get(CENTERLIST, config);
      setSelectedCenter(res?.data?.message);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon("error", error?.response?.data?.message,"red");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
    }
  };

  const CityList = async () => {
    try {
      const res = await axios.get(`${CITYLIST}/${selectedCenterId}`, config);
      setSelectedCity(res?.data?.message);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };

  useEffect(() => {
    InstituteList();
    CityList();
  }, [selectedCenterId]);
  const handleNameChange=(event)=>{
setName(event?.target?.value)
  }

//   const {
//     errors,
//     values,
//     touched,
//     handleBlur,
//     handleChange,
//     handleSubmit,
//     getFieldProps,
//     setFieldValue,
//     setFieldTouched,
//     field,
//   } = useFormik({
//     initialValues: initalValue,
//     enableReinitialize: true,
//     onSubmit: async (values) => {
//     //   setErrorI("");
//       setSubmit(true);

//       // console.log(value);
//       // {name:value.name,link_type:value.vid_source,video_link:value.link,desc:value.description,thumbnail:null,status:value.status},

//     //    const formData = new FormData();

//     //    formData.append("name", values.name);
//     //    formData.append("state_id", selectedCenterId);
//     //   formData.append("city_id", selectedCityId);
//     //    console.log(formData)
//     const requestBody = {
//         name: values.name, // Name from form values
//         state_id: selectedCenterId, // State ID from selectedCenterId
//         city_id: selectedCityId, // City ID from selectedCityId
//       };

//       try {
//         const response = await axios.put(
//           `${UPDATECENTERLIST}/${id}`,
//         //   {"name":values.name,
//         //   "state_id": selectedCenterId,
//         //   "city_id": selectedCityId
//         // }, 
//         requestBody,
        
//           config
//         );
//         // console.log(response, "updaterecorded");
//         if (response) {
//             openNotificationWithIcon("success","Academy Center Updated","#52c41a");
//           setTimeout(() => {
//             navigate("/admin/centers");
//           }, 800);
//           // toast.info("Video Updated");
//         }
//       } catch (error) {
//         console.log(error?.response?.data?.message);
//         setSubmit(false);
//           if (error?.response?.status == 401) {
//           localStorage.removeItem("token");
//           navigate("/admin/login");
//         } else if (error.response.status == 422) {
//           openNotificationWithIcon("error",error?.response?.data?.message, "red");
//         } else {
//           openNotificationWithIcon("error",error?.response?.data?.message, "red");
//         }
//       }
//     },
//     validationSchema: RecordedSchema,
//   });
const handelUpdate = async () => {
    setSubmit(true);
    if (!name) {
        // Display error message for empty name field
        openNotificationWithIcon("error", "Please enter the Center Name", "red");
        return; // Stop further execution
      }
      if (!selectedCenterId) {
        // Display error message for empty name field
        openNotificationWithIcon("error", "Please Select your State", "red");
        return; // Stop further execution
      }
      if (!selectedCityId) {
        // Display error message for empty name field
        openNotificationWithIcon("error", "Please Select Your City", "red");
        return; // Stop further execution
      }
    const requestBody = {
      name: name,
      state_id: selectedCenterId,
      city_id: selectedCityId,
    };
  
    try {
      const response = await axios.put(
        `${UPDATECENTERLIST}/${id}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response) {
        openNotificationWithIcon("success", "Academy Center Updated", "#52c41a");
        setTimeout(() => {
          navigate("/admin/centers");
        }, 800);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setSubmit(false);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      } else if (error?.response?.status === 422) {
        openNotificationWithIcon("error", error?.response?.data?.message, "red");
      } 
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon("error", error?.response?.data?.message,"red");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else {
        openNotificationWithIcon("error", error?.response?.data?.message, "red");
      }
    }
  };
  
  // console.log(selectedCenterId, "cenetr", selectedCityId);

  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            <AdminInstructorSidebar activeMenu={"centers"} />
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Academy Center</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li
                          className="breadcrumb-item"
                          aria-current="page"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/admin/centers")}
                        >
                          Academy Centers
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                          role="button"
                        >
                          Edit Academy Centers
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="checkout-form personal-address add-course-info">
                    {/* <form onSubmit={handleSubmit}> */}
                      {loading === true ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">Name</label>
                              <input
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                onChange={(event)=>{
                                    handleNameChange(event)
                                }}
                                className="form-control"
                                placeholder="Enter name"
                              />
                              {/* {errors.name && touched.name ? (
                                <span style={{ color: "red" }}>
                                  {errors.name}
                                </span>
                              ) : null} */}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="form-group d-flex"
                              style={{ flexDirection: "column" }}
                            >
                              <label className="form-control-label">
                                Select State
                              </label>
                              <Select
                                showSearch
                                style={{ width: "100%", height: "100%" }}
                                placeholder="Select an option"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleCourseChange}
                                value={{
                                  label: selectedCenter?.find(
                                    (option) => option.id === selectedCenterId
                                  )?.name,
                                  value: selectedCenterId,
                                }}
                              >
                                {selectedCenter?.map((option) => (
                                  <Select.Option
                                    key={option?.id}
                                    value={option?.id}
                                  >
                                    {option?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div
                              className="form-group d-flex"
                              style={{ flexDirection: "column" }}
                            >
                              <label className="form-control-label">
                                Select City
                              </label>
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Select an option"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleStudentChange}
                                value={{
                                  label: selectedCity?.find(
                                    (option) => option.id === selectedCityId
                                  )?.name,
                                  value: selectedCityId,
                                }}
                              >
                                {selectedCity?.map((option) => (
                                  <Select.Option
                                    key={option.id}
                                    value={option.id}
                                  >
                                    {option?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="update-profile" id="submit-button">
                            <div
                              className="btn-group mr-2"
                              id="custom-submit"
                              role="group"
                              aria-label="First group"
                            >
                              <button
                                type="submit"
                                className="btn btn-primary responsive-buttons"
                                disabled={submit}
                                onClick={()=>{
                                    handelUpdate()
                                }}
                              >
                                Update
                              </button>
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
                            >
                              <button
                                type="button"
                                onClick={() =>
                                  navigate("/admin/centers")
                                }
                                className="btn btn-primary cancel-btn responsive-buttons "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={1000} hideProgressBar={true} />
      <Footer />
    </div>
  );
}
