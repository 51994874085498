import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import { Link, useNavigate } from "react-router-dom";
import StudentSideBar from "../sidebar";
import Select from "react-select";
import axios from "../../../api/axios";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import FeatherIcon from "feather-icons-react";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "antd";
import moment from "moment";
import AdminHeader from "../../instructor/adminHeader";
// import "./index.css";
import { notification, Space } from "antd";

const ADDSTUDENT = "/api/teacher/create";


const initalValue = {
  name: "",
  email: "",
  password: "",
  phone: "",
  dob: "",
  gender: "",
  status: "",
  username:"",
  join_date:"",
  type:""
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const studentSchema = Yup.object({
  name: Yup.string().min(2).max(100).required("Please enter your name"),
  email: Yup.string().email().required("Please enter your email"),
  username:Yup.string().min(2).max(100).required("Please enter your name"),
  // password: Yup.string()
  //   .required("Please enter a password")
  //   .min(6, "Password must have at least  characters"),
  phone: Yup.string()
    .max(10)
    .required("Please enter your phone number")
    .matches(phoneRegExp, "Phone number is not valid"),
  dob: Yup.date()
    .min("1969-11-13", "Date is too early")
    .max("2023-11-13", "Date is too further")
    .required("Please Enter date of birth"),
    join_date:Yup.date().required("Please Enter date of birth"),

  status: Yup.string().required("Please select your status"),
  gender: Yup.string().required("Please select your gender"),
  status: Yup.string().required("Please select your status"),
});
const AddTeacher=()=> {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [country, setCountry] = useState(null);
  const [submit, setSubmit] = useState(false);

  const [isPasswordGenerated, setIsPasswordGenerated] = useState(false); // New state variable

  const [api, contextHolder] = notification.useNotification();
  const [selectedOption, setSelectedOption] = useState('');
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const type = [
    { label: "Select Option", value: "Option" },
    { label: "Free", value:0 },
    { label: "Paid", value:1 },
    { label: "Both", value: 2 },
  ];

  const openNotificationWithIcon = (type, text,) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>{text}</strong>
        </div>
      ),
      duartion: 1,

      // description:
      // (<div style={{color:"green"}}>
      // Your Student has been Sucessfully Created

      // </div>)
    });
  };
  // console.log(selectedOption,"selectedOption")
  const openNotificationWithIcon1 = (type, text,color) => {
    notification[type]({
      message: (
        <div style={{ color: color }}>
          <strong>{text}</strong>
        </div>
      ),
      duartion: 1,

      // description:
      // (<div style={{color:"green"}}>
      // Your Student has been Sucessfully Created

      // </div>)
    });
  };
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldError,
    setFieldValue,
    field,
  } = useFormik({
    initialValues: initalValue,
    onSubmit: async (value, { setFieldError }) => {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      setSubmit(true);
      try {
        const response = await axios.post(ADDSTUDENT, {
          name:values?.name,
          email:values?.email,
          password:values?.password,
          phone:values?.phone,
          dob:values?.dob,
          gender:values?.gender,
          username:values?.name,
          join_date:values?.join_date,
          type:selectedOption,
          status:values?.status

        }, config);
        if (response) openNotificationWithIcon("success", "Teacher Created");

        setTimeout(() => {
          navigate("/admin/teachers");
        }, 800);
      } catch (error) {
        console.log(error.response);
        setSubmit(false);
        // openNotificationWithIcon(
        //   "error",
        //   error.response.data.errors.email.toString()
        // );
        if (error?.response?.data?.errors?.email) {
          setFieldError("email", error?.response?.data?.errors?.email.toString());
        }
        if (error?.response?.data?.errors?.phone) {
          setFieldError("phone", error.response.data.errors.phone.toString());
        }
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
        if(error?.response?.status==422){
            openNotificationWithIcon1("error", error?.response?.data?.message,"red");

        }
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIcon1("error", error?.response?.data?.message,"red");
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
        else{
            openNotificationWithIcon1("error", error?.response?.data?.message,"red");

        }
      }
      // console.log(value);
    },
    validationSchema: studentSchema,
  });
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handleGen = (e) => {
    const check = e.target.checked;
    const name = values.name;
    const dob = values.dob;
    const month = moment(dob).format("MM");
    const day = moment(dob).format("DD");
    const cutName = name.slice(0, 4);

    if (check === true) {
      if (values.name && values.dob) {
        const pass = `${cutName}${day}${month}`;
        setFieldValue("password", pass);
        setIsPasswordGenerated(true);
      } else if (values.name.length === 0) {
        setFieldError("name", "Please enter your name");
        openNotificationWithIcon("error", "Please enter your name");
      } else if (values.dob.length === 0) {
        setFieldError("dob", "Enter your date of birth");
        openNotificationWithIcon("error", "Enter your date of birth");
      }
    }
  };
  // const options = [
  //   { label: "Select Status", value: "Status" },
  //   { label: "Active", value: "Active" },
  //   { label: "Inactive", value: "Inactive" },
  // ];
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  const today = new Date();
  today.setFullYear(today.getFullYear() - 12);

  const maxDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
// console.log(type)
const openNotificationWithIconSesion = (type) => {
  notification[type]({
    message: (
      <div style={{ color: "red" }}>
        <strong>Session Expired Please Login </strong>
      </div>
    ),
    duration: 1,
  });
};
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Teachers"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Teacher</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li
                          onClick={() => navigate("/admin/teachers")}
                          className="breadcrumb-item"
                          role="button"
                        >
                          <a>Teachers</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Add Teachers
                        </li>
                      </ol>
                    </nav>
                  </div>
                  {/* <div className="course-group mb-0 d-flex">
                    <div className="course-group-img d-flex align-items-center">
                      <Link to="student-profile">
                        <img src={User11} alt="" className="img-fluid" />
                      </Link>
                      <div className="course-name">
                        <h4>
                          <Link to="student-profile">Your avatar</Link>
                        </h4>
                        <p>PNG or JPG no bigger than 800px wide and tall.</p>
                      </div>
                    </div>
                    <div className="profile-share d-flex align-items-center justify-content-center">
                      <Link to="#;" className="btn btn-success">
                        Update
                      </Link>
                      <Link to="#;" className="btn btn-danger">
                        Delete
                      </Link>
                    </div>
                  </div> */}
                  <div className="checkout-form personal-address add-course-info">
                    <div className="personal-info-head">
                      {/* <h4>Add Student/User</h4>
                       <p>Add Student/User information.</p> */}
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Enter Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Your Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Enter UserName
                            </label>
                            <input
                              type="text"
                              name="username"
                              id="username"
                              value={values.username}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Your User Name"
                            />
                            {errors.username && touched.username ? (
                              <span style={{ color: "red" }}>
                                {errors.username}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Email Address
                            </label>
                            <input
                              type="text"
                              name="email"
                              id="email"
                              value={values.email}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="Enter Your Email Address"
                            />
                            {errors.email && touched.email ? (
                              <span style={{ color: "red" }}>
                                {errors.email}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Phone</label>
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              onChange={handleChange}
                              value={values.phone}
                              className="form-control"
                              placeholder="Enter Your Phone"
                            />
                            {errors.phone && touched.phone ? (
                              <span style={{ color: "red" }}>
                                {errors.phone}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-label">
                                Teacher Type
                              </label>

                              <Select
                                className=" select country-select"
                                name="type"
                                options={type}
          placeholder="Select Type"
          value={type.find((option) => option.value === selectedOption)}
          onChange={(selected) => setSelectedOption(selected?.value)}
          styles={style}
                              ></Select>
                              {errors.correctAnswer && touched.correctAnswer ? (
                                <span style={{ color: "red" }}>
                                  {errors.correctAnswer}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        <div className="col-lg-6">
                          <div
                            className="form-group"
                            // style={{ marginTop: "-8px" }}
                          >
                            <label className="form-control-label">
                              Date of Birth
                            </label>
                            {/* <input
                              type="date"
                              className="form-control"
                              id="dob"
                              value={values.dob}
                              onChange={handleChange}
                              name="dob"
                              placeholder="Birth of Date"
                            /> */}
                            <input
                              type="date"
                              name="dob"
                              id="dob"
                              autoCorrect="off"
                              autoComplete="off"
                              value={values.dob}
                              onChange={handleChange}
                              max={maxDate}
                              className="form-control"
                              placeholder="Enter your date of birth"
                              
                            />

                            {errors.dob && touched.dob ? (
                              <span style={{ color: "red" }}>{errors.dob}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div
                            className="form-group"
                            // style={{ marginTop: "-8px" }}
                          >
                            <label className="form-control-label">
                              Joining Date
                            </label>
                            {/* <input
                              type="date"
                              className="form-control"
                              id="dob"
                              value={values.dob}
                              onChange={handleChange}
                              name="dob"
                              placeholder="Birth of Date"
                            /> */}
                            <input
                              type="date"
                              name="join_date"
                              id="join_date"
                              autoCorrect="off"
                              autoComplete="off"
                              value={values.join_date}
                              onChange={handleChange}
                            // max={new Date ()}
                              className="form-control"
                              placeholder="Enter your date of birth"
                              
                            />

                            {errors.join_date && touched.join_date ? (
                              <span style={{ color: "red" }}>{errors.join_date}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <label className="text-center">Gender</label>
                              </div>
                            </div>
                            <div style={{ marginTop: "0.5em" }}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("gender").onChange}
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="inlineRadio2"
                                  onChange={getFieldProps("gender").onChange}
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  onChange={getFieldProps("gender").onChange}
                                  id="inlineRadio3"
                                  value="2"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio3"
                                >
                                  Other
                                </label>
                              </div>
                            </div>
                            <div>
                              {errors.gender && touched.gender ? (
                                <span style={{ color: "red" }}>
                                  {errors.gender}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Password
                            </label>
                            <div className="password-field">
                              <input
                                type={passwordType}
                                className="form-control"
                                name="password"
                                id="password"
                                placeholder="Password"
                                onChange={handleChange}
                                value={values.password}
                                readOnly={isPasswordGenerated}
                              />

                              <span
                                className=""
                                style={{
                                  position: "relative",
                                  top: "-2em",
                                  left: "26em",
                                }}
                                onClick={togglePassword}
                              >
                                {passwordType === "password" ? (
                                  <FeatherIcon icon="eye" />
                                ) : (
                                  <FeatherIcon icon="eye-off" />
                                )}
                              </span>
                              <div style={{ marginTop: "-1.5em" }}>
                                {errors.password && touched.password ? (
                                  <span style={{ color: "red" }}>
                                    {errors.password}
                                  </span>
                                ) : null}
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div
                            className="form-check"
                            style={{ marginTop: "40px" }}
                          >
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={(e) => handleGen(e)}
                              />
                              Generate password
                            </label>
                            <span>
                              {" "}
                              <Tooltip title="Auto Generated password will be combination of first four letters of your name, date and month of DOB. Example">
                                {" "}
                                <i
                                  className="fa fa-question-circle"
                                  aria-hidden="true"
                                ></i>
                              </Tooltip>
                            </span>
                          </div>
                        </div> */}
                        <div className="col-lg-6">
                          <div
                            className="form-group"
                            // style={{ marginTop: "10px" }}
                          >
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <label className="text-center">Status</label>
                              </div>
                            </div>
                            <div style={{ marginTop: "0.5em" }}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios1"
                                  onChange={getFieldProps("status").onChange}
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios2"
                                  onChange={getFieldProps("status").onChange}
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios2"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                            <div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <div
                            className="form-group"
                          >
                            <label className="form-label">Status</label>

                            <Select
                              className="select country-select"
                              name="status"
                              options={options}
                              defaultValue={options[0]}
                              onChange={(option) =>
                                setFieldValue("status", option.value)
                              }
                              placeholder="Select Status"
                              styles={style}
                            ></Select>
                            {errors.status && touched.status ? (
                              <span style={{ color: "red" }}>
                                {errors.status}
                              </span>
                            ) : null}
                          </div>
                        </div> */}
                        {/* 
                        <div className="col-lg-6">
                          <div
                            className="form-check"
                            style={{ marginTop: "30px" }}
                          >
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                              />
                              Mail credentials to user
                            </label>
                          </div>
                        </div> */}

                        <div className="update-profile" id="submit-button">
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={submit}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate("/admin/teachers");
                                // windowTop(0,0)
                              }}
                              className="btn btn-primary cancel-btn"
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      {/* <ToastContainer autoClose={3000} hideProgressBar={true} /> */}
      <Footer />
    </div>
  );
}
export default AddTeacher
