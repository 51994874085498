import React, { useState } from "react";
import { InstructorHeader } from "../../../instructor/header";
import Footer from "../../../footer";
import TextEditor from "../../../pages/course/addCourse/editor";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import CryptoJS from "crypto-js";

import * as Yup from "yup";
import { useFormik } from "formik";
import AdminInstructorSidebar from "../../../instructor/adminSidebar";
import axios from "../../../../api/axios";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { notification, Space } from "antd";

import AdminHeader from "../../../instructor/adminHeader";
const CLASSMODULE = "/api/live-classes/add";
const ADDCLASS = "/api/live-classes/create";
const EDITCLASS = "/api/live-classes/edit";
const UDATECLASS = "/api/live-classes/update";

export default function EditLiveClasses() {
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [data, setData] = useState(null);
  const [live, setlive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState(null);
  const [subModules, setsubModules] = useState(null)
  const [resultsub, setresultsub] = useState(null)
  const { id } = useParams();
  const [submit, setSubmit] = useState(false)

  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const fetchModule = async () => {
    const response = await axios.get(CLASSMODULE, config);
    // setOption(response?.data);
  };
  useEffect(() => {
    // fetchModule();
  }, []);
  const fetchClass = async () => {
    try {
      setLoading(true);
      if (decryptedData) {
        const response = await axios(`${EDITCLASS}/${decryptedData}`, config);
        // console.log(response,"liveclass")
        setOption(response?.data?.batch)
        setData(response?.data?.liveclasses);
        setlive(response?.data?.LiveRelation);
        setsubModules(response?.data?.subModules)
        const result = []
        response?.data?.LiveSubmoduleRelations.map((submob) => {
          response?.data?.subModules.map((subModule) => {
            if (submob.sub_module_id == subModule.id) {
              result.push(subModule);
            }
          })
        })
        setresultsub(result)
      }
      setLoading(false);
    }
    catch (error) {
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon2("error", error?.response?.data?.message,"red");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error","Internal Server Error","red")
      }
    }

  };
  useEffect(() => {
    fetchClass();
  }, []);
//  console.log(data,"data");
  const result = [];
  option?.map((it) => {
    live?.map((its) => {
      if (it?.id == its?.batch_id) {
        return result.push(Object.assign({}, it, its));
      }
    });
  });
  // console.log(result);

  const initalValue = {
    title: data?.title?data?.title:"",
    name: data?.name?data?.name:"",
    email: data?.email?data?.email:"",
    date: data?.start?data?.start:"",
    duration: data?.duration?data?.duration:"",
    password: data?.password?data?.password:"",
    status: data?.status,
    // batch: result?.map((ele) => {
    //   return {
    //     label: ele.name,
    //     value: Number(ele.batch_id),
    //   };
    // }),
    submodule: resultsub?.map((ele) => {
      return {
        label: ele.name,
        value: ele.id,
      };
    }),
  };
  const liveSchema = Yup.object({
    title: Yup.string().required("Please enter your title"),
    name: Yup.string().min(2).max(100).required("Please enter your name"),
    email: Yup.string().required("Please enter your email"),
    date: Yup.string().required("Please enter your date"),
    duration: Yup.string().required("Please enter your duration"),
    password: Yup.string().required("Please enter your meeting password"),
    submodule: Yup.array()
      .min(1, "Pick at least one items")
      .of(
        Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
  });
  const [checked, setChecked] = useState(false);
  const handleChangew = (nextChecked) => {
    setChecked(nextChecked);
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false)
  const openNotificationWithIcon = (type) => {
    // console.log("toast after click")
    api[type]
      (

        {
          message:
            (
              <div style={{ color: "#52c41a" }}>
                <strong>Live Class Updated</strong>
              </div>
            ),

          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Created

          // </div>)

        });






  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldError,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    enableReinitialize: true,
    onSubmit: async (value, { setFieldError }) => {
      // console.log(value);
      setSubmit(true)
      try {
        const response = await axios.post(
          UDATECLASS,
          {
            title: value.title,
            name: value.name,
            email: value.email,
            duration: value.duration,
            date: value.date,
            password: value.password,
            "live-class-id": decryptedData,
            // batch: value.batch.map((e) => e.value),
            submodule: value.submodule.map((e) => e.value),
            meeting_id: data?.meeting_id,
            status: value.status
          },
          config
        );
        // console.log(response);
        if (response)
          openNotificationWithIcon("success")
        setTimeout(() => {
          navigate(-1);
        }, 1000);
        // toast.info("Live Class Updated");
      } catch (error) {
        console.log(error);
        setSubmit(false)
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem('token')
          navigate('/admin/login')
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon2("error", error.response.data.message, "red");
        } 
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIcon2("error", error?.response?.data?.message,"red");
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
        else {
          openNotificationWithIcon2("error", "Internal Server Error", "red");
        }
        console.log(error);
      
      }
    },
    validationSchema: liveSchema,
  });

  const navigate = useNavigate();
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      // height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
  };
  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  const openNotificationWithIcon2 = (type,text,col) => {
    console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:col}}>
         <strong> {text} </strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };
    // const windowTop = (arg1, arg2) => {
    //   window.scrollTo(arg1, arg2);
    // };
    
    //   useEffect(() => {
    //     windowTop(0, 0);
    //   }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div className="container"
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"LiveClasses"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-8 main-page">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3>Edit Live Class</h3>
                          <nav aria-label="breadcrumb" style={{ marginBottom: '-1em', marginTop: '0.5em' }}>
                            <ol className="breadcrumb">
                              <li className="breadcrumb-item" onClick={() => navigate('/admin/live-classes')} role="button">
                                <a>Live Classes</a>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                role="button"
                                aria-current="page"
                              >
                                Edit Live Class
                              </li>
                            </ol>
                          </nav>
                        </div>
                        <div className="tak-head d-flex align-items-center">
                          <div>
                            <div className="checkout-form personal-address add-course-info ">
                              <form noValidate onSubmit={handleSubmit}>
                                {loading === true ? (
                                  <div
                                    className="d-flex justify-content-center"
                                    style={{ position: "absolute" }}
                                  >
                                    <div
                                      className="spinner-border text-warning"
                                      style={{
                                        left: "50%",
                                        marginLeft: "24em",
                                        marginTop: "10em",
                                      }}
                                      role="status"
                                    >
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Title
                                        </label>
                                        <input
                                          type="text"
                                          name="title"
                                          onChange={handleChange}
                                          className="form-control"
                                          value={values.title}
                                          placeholder="Enter Title"
                                        />
                                        {errors.title && touched.title ? (
                                          <span style={{ color: "red" }}>
                                            {errors.title}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Name
                                        </label>
                                        <input
                                          name="name"
                                          type="text"
                                          value={values.name}
                                          className="form-control"
                                          onChange={handleChange}
                                          placeholder="Enter Name"
                                        />
                                        {errors.name && touched.name ? (
                                          <span style={{ color: "red" }}>
                                            {errors.name}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Email
                                        </label>
                                        <input
                                          type="text"
                                          onChange={handleChange}
                                          name="email"
                                          value={values.email}
                                          className="form-control"
                                          placeholder="Enter Email"
                                          disabled
                                        />
                                        {errors.email && touched.email ? (
                                          <span style={{ color: "red" }}>
                                            {errors.email}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Date Time
                                        </label>
                                        <input
                                          type="datetime-local"
                                          onChange={handleChange}
                                          className="form-control"
                                          name="date"
                                          formNoValidate
                                          // defaultValue={moment
                                          //   .utc(values.date)
                                          //   .local()
                                          //   .format("YYYY-MM-DD[T]HH:mm:ss")}
                                          value={moment(values.date).format("YYYY-MM-DD[T]HH:mm:ss")}
                                        />
                                        {errors.date && touched.date ? (
                                          <span style={{ color: "red" }}>
                                            {errors.date}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Duration
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          onChange={handleChange}
                                          placeholder="Enter Duration"
                                          name="duration"
                                          value={values.duration}
                                        />
                                        {errors.duration && touched.duration ? (
                                          <span style={{ color: "red" }}>
                                            {errors.duration}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-control-label">
                                          Meeting Password
                                        </label>
                                        <input
                                          type="password"
                                          onChange={handleChange}
                                          className="form-control"
                                          placeholder="Enter Meeting Password"
                                          name="password"
                                          value={values.password}
                                        />
                                        {errors.password && touched.password ? (
                                          <span style={{ color: "red" }}>
                                            {errors.password}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    {/* <div className="col-lg-12">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Select Batch
                                        </label>

                                        <Select
                                          className=" select country-select"
                                          name="batch"
                                          isMulti
                                          options={option?.map((ele) => {
                                            return {
                                              label: ele.name,
                                              value: ele.id,
                                            };
                                          })}
                                          // defaultValue={}
                                          value={values.batch}
                                          // defaultValue={options[0]}
                                          placeholder="Select Batch"
                                          onChange={(assignedUser) =>
                                            setFieldValue(
                                              "batch",
                                              assignedUser
                                            )
                                          }
                                          // onChange={setCountry}
                                          styles={style}
                                        ></Select>
                                        {errors.batch &&
                                          touched.batch ? (
                                          <span style={{ color: "red" }}>
                                            {errors.batch}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div> */}
                                    <div className="col-lg-12">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Select SubModule
                                        </label>

                                        <Select
                                          className=" select country-select"
                                          name="submodule"
                                          isMulti
                                          options={subModules?.map((ele) => {
                                            return {
                                              label: ele.name,
                                              value: ele.id,
                                            };
                                          })}
                                          // defaultValue={}
                                          value={values.submodule}
                                          // defaultValue={options[0]}
                                          placeholder="Select SubModule"
                                          onChange={(assignedUser) =>
                                            setFieldValue(
                                              "submodule",
                                              assignedUser
                                            )
                                          }
                                          // onChange={setCountry}
                                          styles={style}
                                        ></Select>
                                        {errors.submodule &&
                                          touched.submodule ? (
                                          <span style={{ color: "red" }}>
                                            {errors.submodule}
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Status
                                        </label>
                                        <br />
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="status"
                                            id="inlineRadio1"
                                            onChange={
                                              getFieldProps("status").onChange
                                            }
                                            value={"0" || values?.status}
                                            checked={values?.status === "0"}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="inlineRadio1"
                                          >
                                            Inactive
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="status"
                                            id="inlineRadio2"
                                            checked={values?.status === "1"}
                                            onChange={
                                              getFieldProps("status").onChange
                                            }
                                            value={"1" || values?.status}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="inlineRadio2"
                                          >
                                            Active
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{ marginTop: "1em" }}>
                                      {contextHolder}
                                      <div className="update-profile"
                                      id="submit-button"
                                      >
                                        <div
                                          className="btn-group mr-2"
                                          role="group"
                                          aria-label="First group"
                                          id="custom-submit"

                                        >
                                          <button type="submit" className="btn btn-primary"
                                            disabled={submit}
                                          >
                                            Update
                                          </button>
                                        </div>{" "}
                                        <div
                                          className="btn-group mr-2"
                                          role="group"
                                          aria-label="First group"
                                          id="custom-submit"

                                        >
                                          <button
                                            type="button"
                                            onClick={() => {
                                              navigate("/admin/live-classes");
                                              // windowTop(0,0)
                                            }}
                                            className="btn btn-primary cancel-btn "
                                          >
                                            Cancel
                                          </button>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <ToastContainer autoClose={3000} hideProgressBar={true} /> */}
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
