import { createContext, useEffect, useState } from "react";
import axios from "../api/axios";
import axiosInstance from "../api/axios1";
const COURSECATEGORY = "/api/admin/course/menu";
const EXPIRED = "/api/student/get/expired_courses";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [courses, setCourses] = useState([]);
  const [expireddata, setExpiredData] = useState([]);
  const tokenStudent = localStorage.getItem("tokenStudent");

  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const courseslist = async () => {
    try {
      const response = await axios.get(COURSECATEGORY);
      setCourses(response?.data?.message);
    } catch (error) {
      // console.error(error);
      if (error?.resonse?.satus == 401) {
        localStorage.removeItem("token");
        navigate("/admin/login")
  
      }
      else if(error?.response?.satus===403){
        localStorage.removeItem("token");
  
        // openNotificationWithIcon2("error",err?.response?.data?.message)
        navigate("/admin/login")
  
    }
  }
  };
  useEffect(() => {
    courseslist();
  }, []);
  const ExpiredCourse = async () => {
    try {
      // setLoading(true);
      const resonse = await axiosInstance.get(EXPIRED, config);
      //  console.log(resonse,"response in expired courses")
      setExpiredData(resonse?.data);
      // setLoading(false);
    } catch (error) {
      // console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenStudent");
        localStorage.removeItem("showNotification");

        // navigate("/login");
      }
      // openNotificationWithIcon2("error");
    }
  };

  useEffect(() => {
    if(tokenStudent==localStorage.getItem("token")){
      // ExpiredCourse();

    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth ,courses, courseslist ,expireddata,ExpiredCourse,setExpiredData}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
