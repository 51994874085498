import React, { useState } from "react";
import * as Yup from "yup";
import Footer from "../../footer";
import StudentHeader from "../studentHeader";
import axios from "../../../api/axios";
import { useFormik } from "formik";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { notification } from "antd";
import axiosInstance from "../../../api/axios1";
const CHANGEPASSWORD = "/api/student/change-password-save";

export default function StudentSecuritys() {
  const initalValue = {
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  };
  const loginSchema = Yup.object({
    current_password: Yup.string().required(
      "Please enter your current password"
    ),
    new_password: Yup.string()
      .required("Please enter a password")
      .min(8, "Password must have at least  characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character, and must be at least 8 characters long"
      ),
    new_password_confirmation: Yup.string()
      .required()
      .oneOf([Yup.ref("new_password"), null], "Password Must Match"),
  });
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };
  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };
  const openNotificationWithIcon2 = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),

      duration: 2,
    });
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initalValue,
    onSubmit: async (val) => {
      try {
        const token = localStorage.getItem("tokenStudent");
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const response = await axiosInstance.post(CHANGEPASSWORD, values, config);
        // console.log(response);
        if (response) {
          openNotificationWithIcon2("success", "Password Changed", "#52c41a");
        }
      } catch (err) {
        console.log(err);
        if (err.response?.status == 403) {
          openNotificationWithIcon2(
            "error",
            err.response?.data[1],
            "red"
          );
        }
        if (err.response.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("tokenStudent");
          navigate("/login");
        }
        else if (err?.response?.status === 403) {
          localStorage.removeItem("tokenStudent");
          openNotificationWithIcon3("error", err?.response?.data?.message,);
          setTimeout(() => {
            navigate("/login");
          }, 1000); // Delay the navigation to show the notification
        }
        else{
          openNotificationWithIcon3("error",err?.response?.data?.message)
        }
      }
    },
    validationSchema: loginSchema,
  });
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  return (
    <div className="main-wrapper">
      <StudentHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            {/* <StudentSideBar activeMenu={"Security"} /> */}
            {/* Sidebar */}

            {/* Student Security */}
            <div className="col-xl-12 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Security</h3>
                    <p>
                      Edit your account settings and change your password here.
                    </p>
                  </div>
                  {/* <div className="checkout-form personal-address border-line">
                    <div className="personal-info-head">
                      <h4>Email Address</h4>
                      <p>
                        Your current email address is{" "}
                        <span>maxwell@example.com</span>
                      </p>
                    </div>
                    <form action="#">
                      <div className="new-address">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                New email address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your New email address"
                              />
                            </div>
                          </div>
                          <div className="profile-share d-flex ">
                            <button type="button" className="btn btn-success">
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div> */}
                  <div className="checkout-form personal-address">
                    <div className="personal-info-head">
                      <h4>Change Password</h4>
                      <p>
                        We will email you a confirmation when changing your
                        password, so please expect that email after submitting.
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label className="form-control-label">
                              Current password
                            </label>
                            <div className="pass-group">
                              <div className="password-field-wrapper">
                                <input
                                  type={passwordType}
                                  onChange={handleChange}
                                  value={values.current_password}
                                  required
                                  autoComplete="off"
                                  name=""
                                  id="current_password"
                                  placeholder="Current Password"
                                  className="form-control"
                                />
                                <span
                                  className="toggle-password feather-eye"
                                  onClick={togglePassword}
                                >
                                  {passwordType === "password" ? (
                                    <FeatherIcon icon="eye" />
                                  ) : (
                                    <FeatherIcon icon="eye-off" />
                                  )}
                                </span>
                              </div>
                              {errors.current_password &&
                              touched.current_password ? (
                                <span style={{ color: "red" }}>
                                  {errors.current_password}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="form-control-label">
                             New Password
                            </label>
                            <div className="pass-group" id="passwordInput">
                              <div className="password-field-wrapper">
                                <input
                                  type={passwordType1}
                                  className="form-control password-field"
                                  placeholder="Enter your New password"
                                  onChange={handleChange}
                                  value={values.new_password}
                                  required
                                  autoComplete="off"
                                  name="new_password"
                                  id="new_password"
                                />
                                <span
                                  className="toggle-password feather-eye"
                                  onClick={togglePassword1}
                                >
                                  {passwordType1 === "password" ? (
                                    <FeatherIcon icon="eye" />
                                  ) : (
                                    <FeatherIcon icon="eye-off" />
                                  )}
                                </span>
                              </div>
                              {errors.new_password && touched.new_password ? (
                                <span style={{ color: "red" }}>
                                  {errors.new_password}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="form-control-label">
                              Confirm New Password
                            </label>
                            <div className="pass-group">
                              <div className="password-field-wrapper">
                                <input
                                  type={passwordType2}
                                  onChange={handleChange}
                                  value={values.new_password_confirmation}
                                  required
                                  autoComplete="off"
                                  name="new_password_confirmation"
                                  id="new_password_confirmation"
                                  className="form-control password-field"
                                  placeholder="Enter your Confirm New password"
                                />
                                <span
                                  className="toggle-password feather-eye"
                                  onClick={togglePassword2}
                                >
                                  {passwordType2 === "password" ? (
                                    <FeatherIcon icon="eye" />
                                  ) : (
                                    <FeatherIcon icon="eye-off" />
                                  )}
                                </span>
                              </div>
                              {errors.new_password_confirmation &&
                              touched.new_password_confirmation ? (
                                <span style={{ color: "red" }}>
                                  {errors.new_password_confirmation}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="update-profile save-password">
                            <button className="btn btn-primary">
                              Save Password
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Student Security */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
