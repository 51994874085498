import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import AdminHeader from "../../instructor/adminHeader";
import { Input, Space } from "antd";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../../footer";
import { Tooltip } from "antd";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";
import moment from "moment";
import axios from "../../../api/axios";
import Form from "react-bootstrap/Form";
import "./index.css";
import Select from "react-select";
import PaginationLive from "../../pages/course/addCourse/PaginationLive";

const VIEW = "/api/live-classes/view";
const STATIS = "/api/live-classes/status";
const DELETESTUDENT = "/api/live-classes/delete";
const ADDMEET = "/api/live-classes/add-token";
const LIVECLASSESAPI = "/api/live-classes/liveclasses-search";
import { notification, Modal } from "antd";

const { confirm } = Modal;

export default function AdminLiveClasses() {
  const { Search } = Input;
  const [last_page, setLast_page] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState("");
  const [zoomCount2, setzoomCount2] = useState("");
  const [zoomAppDetail, setZoomAppDetail] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [zoomToken, setZoomToken] = useState(null);
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [zoomAble, setZoomAble] = useState(null);
  const [zoomDis, setZoomDis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showMeet, setShowMeet] = useState(false);
  const [id, setId] = useState("");
  const [text, settext] = useState("");
  const [message, setmessage] = useState("No Data");
  const [joinFrom, setJoinFrom] = useState()
  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  const handleCloseMeet = () => setShowMeet(false);

  const handleClose = () => setShow(false);

  const handleShow = async (id) => {
    setShow(true);
    setId(id);
  };

  const handleShowMeet = async (id) => {
    setIsModalVisible(true);
    setShowMeet(true);
  };

  const navigate = useNavigate();

  // const REDIRECT_URI =
  //   "https://lmscode.stellarflux.com/api/live-classes/add-token";
  // const client_id = "hoDZIPSTS9GurMyVUc15CQ";

  // const url = `https://zoom.us/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${REDIRECT_URI}`;
  const handleNewMeet = async (emailId) => {
    try {
      const response = await axios.post(ADDMEET, { emailId: emailId }, config);
      const url = `https://zoom.us/oauth/authorize?response_type=code&client_id=${response.data.clientid}&redirect_uri=${response.data.REDIRECT_URI}`;
      location.replace(url);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const handleEdit = (id) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/edit-liveclass/${ciphertexts}`);
  };
  const handleMeeting = (id, meeting_url) => {
    // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(id), 'my-secret-key@admin8623009959').toString();
    // let ciphertexts =encodeURIComponent(ciphertext)
    // navigate(`/admin/live-class-join/${ciphertexts}`);
    window.open(meeting_url, "_blank");
  };

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);
  const config = {
    headers: { Authorization: `Bearer ${token}`,
  
  },
  };
  const fetchJoin = async(val)=>{ 
      try{
        setLoading(true);
        const cr = await axios.post('/api/admin/update-joinclass',{
          join:val
        },
      
        config
      )
      if(cr.data[0] === "success"){
        toast.success(cr.data[1]);
        if(val === "zoom"){
          setJoinFrom({label:"Zoom App",value:"zoom"})
        }else{
          setJoinFrom( {label:"LMS",value:"lms"})
        }
      }else{
        toast.error(cr.data[1]);
      }
      setLoading(false);
      }catch(e){
        openNotificationWithIcon2("error")
        setLoading(false);

      }
  }
  const fetchApis = async () => {
    try {
      setLoading(true);
      const response = await axios.get(VIEW, config);
      // console.log(response.data);
      if(response?.data?.joinFrom == 'zoom'){
        setJoinFrom({label:"Zoom App",value:"zoom"})
      }else{
        setJoinFrom( {label:"LMS",value:"lms"})
      }
      setLast_page(response?.data?.liveVideo?.last_page);
      setList(response?.data?.liveVideo?.data);
      setData(response?.data?.liveVideo?.data);
      setPage(response?.data?.zoomCount);
      setzoomCount2(response?.data?.zoomCount2);
      setZoomToken(response?.data?.zoomToken);
      // setCurrentPage(page);
      const res = response?.data?.zoomAppDetail
        ?.filter((it) => !!it)
        .map(({ email }) => ({ label: email, value: email }));
      setZoomAppDetail(res);
      const email = response?.data?.zoomToken?.map((it) => it.email);

      let arrfilter = response?.data?.zoomAppDetail?.filter(function (item) {
        return email.indexOf(item.email) === -1;
      });
      setZoomAble(arrfilter);
      let arrfilter1 = response?.data?.zoomAppDetail?.filter(function (item) {
        return email.indexOf(item.email) !== -1;
      });
      setZoomDis(arrfilter1);
      setLoading(false);
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon3("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error")
      }
    }
  };
  useEffect(() => {
    fetchApis();
  }, []);
  const openNotificationWithIcon = (type) => {
    // console.log("toast after click")
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Live Class Deleted</strong>
        </div>
      ),
      duration: 1,

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type) => {
    // console.log("toast after click")
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const handleDeleteStudent = async () => {
    if (id) {
      setLoading(true);
      const resonse = await axios.get(`${DELETESTUDENT}/${id}`, config);
      // console.log(resonse.data.message);
      setLoading(false);
      fetchApis();
      setShow(false);
      if (resonse.data.message) {
        openNotificationWithIcon("info");
        // toast.warning("Live Class Deleted");
      }
    }
  };
  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this live class?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETESTUDENT}/${id}`, config);
          // console.log(resonse.data.message);
          setLoading(false);
          fetchApis();
          setShow(false);
          if (resonse.data.message) {
            openNotificationWithIcon("success");
            // toast.warning("Live Class Deleted");
          }
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }

          else{
          openNotificationWithIcon2("error");
          }
        }
      },
      onCancel() {},
    });
  }
  const handleChanges = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      STATIS,
      { liveclassid: id, status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1("success");
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    // toast.success("Status Change");
    // console.log(response);
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
    // console.log(list);
  };
  const onSearch = (value) => console.log(value);
  const handleUrl = () => {
    window.opener = null;
    window.open(url, "_blank");
    window.close();
  };
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
  };
  const handleKoinChange = (e)=>{
    fetchJoin(e.value)
  }
  const Filter = async (e) => {
    const searchValue = e.target.value;
    settext(searchValue);
    const res = await axios.post(LIVECLASSESAPI, { name: searchValue }, config);
    if (res.data.liveVideo.data.length > 0) {
      setList(res.data.liveVideo.data);
      setCurrentPage(res.data.liveVideo.current_page);
      setLast_page(res.data.liveVideo.last_page);
    }
    if (res.data.liveVideo.data.length == 0){
      setList(res.data.liveVideo.data);
      setmessage('No Data Found')
    }
    if (searchValue.length === 0) {
      const res = await axios.get(`${VIEW}?page=${currentPage}`, config);
      setList(res.data.liveVideo.data);
      setCurrentPage(1);
      setLast_page(res.data.liveVideo.last_page);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const openNotificationWithIcon2 = (type) => {
    // console.log("toast after click")
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type,text) => {
    // console.log("toast after click")
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text} </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const itemsPerPage = 10;
  // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  // useEffect(() => {
  //   windowTop(0, 0);
  // }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-10px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"LiveClasses"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <div className="row">
                            <div className="col-12 col-md-8">
                              <h3 style={{ textTransform: "capitalize" }}>
                              Live Classes
                            </h3>
                            </div>
                            <div className="col-12 col-md-4">
                            <div className="form-group">
                                        <label className="form-label">
                                          Join From 
                                        </label>
                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          name="submodule"
                                          value={joinFrom}
                                          options={[
                                            {label:"LMS",value:"lms"},
                                            {label:"Zoom App",value:"zoom"}
                                          ]}
                                          // defaultValue={options[0]}
                                          placeholder="Select SubModule"
                                          onChange={handleKoinChange}
                                        ></Select>
                                      </div>
                            </div>
                          </div>
                        </div>
                        <div className="comman-space pb-0">
                          <div
                            // className="settings-referral-blk table-responsive"
                            // id="table-value"
                          >
                            {/* Instructor Users */}
                           
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div
                                    className="row align-items-center"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    <div className="col-md-3 col-lg-3 col-item">
                                      <div className=" search-group justify-content-start">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Name"
                                          // allowClear
                                          onChange={Filter}
                                          onKeyDown={handleKeyPress}
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-9 col-lg-9 justify-content-flex-end">
                                      <div
                                        className="button-container"
                                        style={{ justifyContent: "flex-end" ,gap:"5px"}}
                                      >
                                        <Button
                                          id="custom-button"
                                          onClick={() =>
                                            navigate(
                                              "/admin/live-class-batches"
                                            )
                                          }
                                          style={{
                                            background: "#FF5364",
                                            border: "#FF5364",
                                          }}
                                          className="btn  "
                                        >
                                          Live Class SubModule
                                        </Button>

                                        <Button
                                          style={{
                                            color: "white",

                                            // marginRight: "1em",

                                            background: "#392c7d",
                                            border: "#392c7d",
                                          }}
                                          id="custom-button"
                                          onClick={() =>
                                            navigate("/admin/manage-details")
                                          }
                                          className="btn  "
                                        >
                                          Manage Accounts
                                        </Button>

                                        <Button
                                          onClick={() => handleShowMeet()}
                                          style={{
                                            color: "white",

                                            // marginRight: "1em",

                                            textAlign: "center",

                                            background: "#009efb",

                                            border: "#009efb",
                                          }}
                                          id="custom-button"
                                          className="btn  "
                                        >
                                          Generate Token
                                        </Button>

                                        <Button
                                          onClick={() =>
                                            navigate(
                                              "/admin/live-classes-create"
                                            )
                                          }
                                          id="custom-button"
                                          style={{}}
                                          className="btn btn-primary  "
                                        >
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Create New
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
<div className="settings-referral-blk table-responsive">
       
                            {/* genrate token */}
                            <Modal
                              title="Generate Token "
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              open={isModalVisible}
                              onOk={() => {
                                setIsModalVisible(false);
                              }}
                              onCancel={() => {
                                setIsModalVisible(false);
                              }}
                            >
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  {zoomAble?.map((is,index) => {
                                    return (
                                      <Button
                                        onClick={() => handleNewMeet(is?.email)}
                                        className="btn btn-info  btn-block text-white p-1"
                                        style={{ marginBottom: "0.5em" }}
                                        key={index}
                                      >
                                        {is.email}
                                      </Button>
                                    );
                                  })}
                                  {zoomDis?.map((is,index) => {
                                    return (
                                      <Button
                                        onClick={() => handleNewMeet(is?.email)}
                                        disabled={true}
                                        className="btn btn-info  btn-block text-white"
                                        style={{ marginBottom: "0.5em" }}
                                        key={index}
                                      >
                                        {is.email}
                                      </Button>
                                    );
                                  })}
                                </Form.Group>
                              </Form>
                            </Modal>
                          
                            <table
                              className="table table-nowrap mt-2"
                              // style={{ marginTop: "15px" }}
                            >
                              <thead>
                                <tr className="text-center">
                                  <th>SR. NO.</th>
                                  <th>TITLE</th>
                                  <th>START </th>
                                  <th>END</th>

                                  <th>STATUS</th>
                                  <th>ACTIONS</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                               
                                  <tbody  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}>
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                              
                              ) : (
                                list?.map((item, i) => {
                                  const sNo =
                                    (currentPage - 1) * itemsPerPage + i + 1;

                                  const currentDate = moment();

                                  const startDate = moment(item.start);

                                  const endDate = moment(item.end);

                                  let formattedStartDate;

                                  let formattedEndDate;

                                  if (startDate.isSame(currentDate, "day")) {
                                    formattedStartDate = "Today";
                                  } else if (
                                    startDate.isSame(
                                      currentDate.clone().add(1, "day"),

                                      "day"
                                    )
                                  ) {
                                    formattedStartDate = "Tomorrow";
                                  } else {
                                    formattedStartDate = `${startDate.format(
                                      "DD MMMM YYYY"
                                    )}`;
                                  }

                                  if (endDate.isSame(currentDate, "day")) {
                                    formattedEndDate = "Today";
                                  } else if (
                                    endDate.isSame(
                                      currentDate.clone().add(1, "day"),

                                      "day"
                                    )
                                  ) {
                                    formattedEndDate = "Tomorrow";
                                  } else {
                                    formattedEndDate = `${endDate.format(
                                      "DD MMMM YYYY"
                                    )}`;
                                  }
                                  return (
                                    <tbody
                                     
                                      style={{ textTransform: "capitalize" }}
                                      key={sNo}
                                    >
                                      <tr className="text-center">
                                        <td>{sNo}</td>
                                        <td>
                                          <Tooltip title={item.title}>
                                            {`${item.title.slice(0, 10)} ${
                                              item.title.length > 10
                                                ? "..."
                                                : ""
                                            }`}
                                          </Tooltip>
                                        </td>
                                        <td>
                                          <span>{`${formattedStartDate} ${moment(
                                            item.start
                                          ).format("hh:mm A")}`}</span>
                                        </td>
                                        <td>
                                          {" "}
                                          &nbsp; &nbsp;&nbsp;
                                          {/* <span>{`${moment(item.end).format(
                                            "DD"
                                          )} ${moment(item.end).format(
                                            "MMMM"
                                          )} ${moment(item.end).format(
                                            "YYYY"
                                          )} ${moment(item.end).format(
                                            "hh:mm A"
                                          )}`}</span> */}
                                          <span>{`${formattedEndDate} ${moment(
                                            item.end
                                          ).format("hh:mm A")}`}</span>
                                        </td>
                                        <td>
                                          {" "}
                                          <Switch
                                            onChange={(e) => {
                                              handleChanges(
                                                item.id,
                                                item.status
                                              );
                                            }}
                                            checked={
                                              item.status === "1" ? true : false
                                            }
                                            // id={item.id}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={38}
                                            className="react-switch"
                                          />
                                        </td>
                                        <td>
                                          <Tooltip title="Start Class">
                                            {" "}
                                            <button
                                              type="button"
                                              className="btn btn-success text-white"
                                              style={{ marginRight: ".3em" }}
                                              onClick={() =>
                                                handleMeeting(
                                                  item.id,
                                                  item.meeting_url
                                                )
                                              }
                                            >
                                              Start Class
                                            </button>
                                          </Tooltip>{" "}
                                          <Tooltip title="Edit">
                                            <button
                                              onClick={() =>
                                                handleEdit(item.id)
                                              }
                                              type="button"
                                              className="btn btn-secondary"
                                              style={{ marginRight: ".3em" }}
                                            >
                                              <i className="fa-regular fa-pen-to-square"></i>
                                            </button>{" "}
                                          </Tooltip>
                                          <Tooltip title="Delete">
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() =>
                                                showConfirm(item.id)
                                              }
                                              style={{ marginRight: ".4em" }}
                                            >
                                              <i className="fa-regular fa-trash-can"></i>
                                            </button>
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              )}
                            </table>

</div>
                       
                            {list?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {list?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationLive
                                nPages={last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={VIEW}
                                text={text}
                                setData={setList}
                                linkSearch={LIVECLASSESAPI}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
      <Footer />
    </div>
  );
}
