import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import * as Yup from "yup";
import Select from "react-select";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../instructor/adminHeader";
import { notification, Space } from "antd";

const EDITTESTSERIES = "/api/test-series/edit-testseries-submodule";
const UPDATETESTSERIES = "/api/test-series/update-testseries-submodule";
const CATEGORY="/api/test-series/category"

export default function AdminEditTestSeriesUser() {
  const [data, setData] = useState(null);
  const { modid, user, id, type } = useParams();
  const [submit, setSubmit] = useState(false);
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();
  const [errorI, setErrorI] = useState("");
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [selectedCenterId, setSelectedCenterId] = useState(null);

  // console.log(modid,user);
  const [loading, setLoading] = useState(false); 
  // let bytes = CryptoJS.AES.decrypt(
  //   decodeURIComponent(modid),
  //   "my-secret-key@admin8623009959"
  // );
  // let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchEdit = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${EDITTESTSERIES}/${modid}`,
        config
      );
      // console.log(response);
      setData(response.data.res);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchEdit();
  }, []);
  const InstituteList = async () => {
    try {
        const res = await axios.get(CATEGORY, config)
    // console.log(res,"data")
       setSelectedCenter(res?.data?.message)
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
        }
  
    }
  }
  useEffect(()=>{
    InstituteList()
  },[])
  // console.log(selectedCenter,"selected")
  const initalValue = {
    name: data?.name?data?.name:"",
    status: data?.status,
    image:data?.image?data?.image:"",
    attempts:data?.attempts?data?.attempts:""

  };
  // console.log(initalValue,"instialvalue")
  const studentSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter your name"),
    status: Yup.string().required("Please enter your status"),
  });
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon1 = (type) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Test updated </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type,text,col) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text} </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const { errors, values, touched, handleChange, handleSubmit, getFieldProps,setFieldValue } =
    useFormik({
      initialValues: initalValue,
      enableReinitialize: true,
      onSubmit: async (value) => {
        setSubmit(true);
        setErrorI("");

        const formData = new FormData();
        if (file === null) {
          formData.append("image", "");
        } else if (file !== null) {
          const image = new Image();
          image.src = URL.createObjectURL(file);
          await new Promise(resolve => { image.onload = resolve });
          if (image.width > 630 || image.height > 420) {
            openNotificationWithIcon2("error", "Image dimensions should be 630px width and 430px height.", "red");
            setSubmit(false);
            return;
          }
          formData.append("image", file);
        } else {
          formData.append("image", file);
        }
        formData.append("testseries_submodule_name", values.name);
        formData.append("testseries-submodule-id", data?.id);
        formData.append("moduleid", data?.module_id);
        formData.append("status", value?.status);
        formData.append("attempts", value?.attempts);


        try {
          const response = await axios.post(
            UPDATETESTSERIES,
            formData,
            config
          );
          // console.log(response);
          if (response) {
            openNotificationWithIcon1("success");
            navigate(-1);
          }
        } catch (error) {
          setSubmit(false);
          if (error?.response?.status === 422) {
            openNotificationWithIcon2("error", error.response.data.message, "red");
          } else {
            openNotificationWithIcon2("error", "Internal Server Error", "red");
          }

        }
        // console.log(value);
      },
      validationSchema: studentSchema,
    });
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  const [country, setCountry] = useState(null);

  const options = [
    { label: "Select Status", value: "Status" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  // useEffect(() => {
  //   windowTop(0, 0);
  // }, []);
  const categoryName=selectedCenter?.find((item)=>{
    return item?.id==data?.fk_test_structure_id
  })
  // console.log(categoryName,"name")
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div
          className="container"
          style={{ marginTop: "-15px", minWidth: "100%" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Test</h3>
                    {type == 2 ? (
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                          <li
                            className="breadcrumb-item "
                            onClick={() => navigate("/admin/test-series")}
                            // aria-current="page"
                            style={{ cursor: "pointer" }}
                          >
                            Test Series
                          </li>
                          <li
                            className="breadcrumb-item"
                            aria-current="page"
                            style={{
                              cursor: "pointer",
                              // marginLeft: "-.6em",
                              fontWeight: "normal",
                            }}
                            onClick={() => navigate(-1)}
                          >
                            Test
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                            style={{
                              cursor: "pointer",
                              marginLeft: "-.7em",
                            }}
                          >
                            Edit Test
                          </li>
                        </ol>
                      </nav>
                    ) : (
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                          <li
                            className="breadcrumb-item "
                            onClick={() => navigate("/admin/test-series")}
                            // aria-current="page"
                            style={{ cursor: "pointer" }}
                          >
                            Test Series
                          </li>
                          <li
                            className="breadcrumb-item"
                            aria-current="page"
                            style={{
                              cursor: "pointer",
                              // marginLeft: "-.6em",
                              fontWeight: "normal",
                            }}
                            onClick={() => navigate(
                              `/admin/test-series/${user}/${encodeURIComponent(id)}`
                            )}
                            
                          >
                            Test
                          </li>
                          <li
                            className="breadcrumb-item"
                            aria-current="page"
                            style={{
                              cursor: "pointer",
                              marginLeft: "-.6em",
                              fontWeight: "normal",
                            }}
                            onClick={() => navigate(-1)}
                          >
                            Show Group Testseries
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                            style={{
                              cursor: "pointer",
                              marginLeft: "-.7em",
                            }}
                          >
                            Edit Test
                          </li>
                        </ol>
                      </nav>
                    )}
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      {loading === true ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Enter Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={values.name}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Your Name"
                              />
                              {errors.name && touched.name ? (
                                <span style={{ color: "red" }}>
                                  {errors.name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Attempts
                            </label>
                            <input
                              type="number"
                              name="attempts"
                              id="attempts"
                              value={values.attempts}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Attempts"
                            />
                            {errors.attempts && touched.attempts ? (
                              <span style={{ color: "red" }}>
                                {errors.attempts}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Selcted Category
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={categoryName?.name}
                                className="form-control"
                                // onChange={handleChange}
                                placeholder="Enter Your Categeory Name"
                              />
                             
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                              Cover Image Size(630px*420px)
                              </label>
                              <input
                                type="file"
                                id="image"
                                name="image"
                                // value={values.image}
                                className="form-control"
                                placeholder="Cover Image"
                                onChange={(e) => {
                                  setFile(e.target.files[0]); // Update the state with the new file
                                  setFieldValue("image", URL.createObjectURL(e.target.files[0])); // Update the form field value with the new image URL
                                }}                              />
                              {/* {values.image && (
                                <img
                                  src={`https://lmscode.stellarflux.in/${values.image}`}
                                  alt="no image"
                                  height={50}
                                  width={90}
                                />
                              )} */}
                              {errorI ? (
                                <span style={{ color: "red" }}>{errorI}</span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <div
                                className="add-image-box"
                                style={{ minHeight: "0", height: "200px" }}
                              >
                                {values.image === null ? (
                                  <img
                                    src={Share}
                                    style={{
                                      height: "200px",
                                      width: "100%",
                                      borderRadius: "10px",
                                    }}
                                  ></img>
                                ) : (
                                  <img
                                    src={`${values.image}`}
                                    style={{
                                      height: "200px",
                                      width: "100%",
                                      borderRadius: "10px",
                                    }}
                                  ></img>
                                )}
                              </div>
                            </div>
                          </div>
                       
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Status :
                              </label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("status").onChange}
                                  value={"0" || values?.status}
                                  checked={values?.status === "0"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio2"
                                  onChange={getFieldProps("status").onChange}
                                  value={"1" || values?.status}
                                  checked={values?.status === "1"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                            {errors.status && touched.status ? (
                              <span style={{ color: "red" }}>
                                {errors.status}
                              </span>
                            ) : null}
                          </div>
                          <div className="update-profile" id="submit-button">
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
                            >
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={submit}
                              >
                                Update
                              </button>
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
                            >
                              <button
                                type="button"
                                onClick={() => {
                                  navigate(-1);
                                  // windowTop(0, 0);
                                }}
                                className="btn btn-primary cancel-btn "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
