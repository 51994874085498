import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider"; 
import {toast,ToastContainer} from 'react-toastify';
import CryptoJS  from 'crypto-js' 
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from "yup";
import Select from "react-select";
import axios from "../../../api/axios";

const EDITTESTSERIES = "/api/test-series/edit-testseries-submodule";
const UPDATETESTSERIES = "/api/test-series/update-testseries-submodule";

export default function AdminEditTest() {
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();
  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchEdit = async () => {
    try {
      setLoading(true);
      const respone = await axios.get(`${EDITTESTSERIES}/${decryptedData}`, config);
      // console.log(respone);
      setData(respone.data.res);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchEdit();
  }, []);
  const navigate = useNavigate();
  const initalValue = {
    name: data?.name,
    status: data?.status,
  };
  const studentSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter your name"),
    status: Yup.string().required("Please enter your status"),
  });
  const { errors, values, touched, handleChange, handleSubmit, getFieldProps } =
    useFormik({
      initialValues: initalValue,
      onSubmit: async (value) => {
        try {
          const response = await axios.post(
            UPDATETESTSERIES,
            {
              testseries_submodule_name: value.name,
              status: value.status,
              moduleid: data?.module_id,
              "testseries-submodule-id": data?.id,
            },
            config
          );
          // console.log(response);
          if (response){     setTimeout(() => {
            // navigate("/admin/test-series");
            navigate(-1);
          }, 1000); toast.info("Test Updated"); }
          // if (response) navigate("/admin/test-series");
        } catch (error) {
          console.log(error);
        }
        // console.log(value);
      },
      validationSchema: studentSchema,
      enableReinitialize: true,
    });
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  const [country, setCountry] = useState(null);

  const options = [
    { label: "Select Status", value: "Status" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  return (
    <div className="main-wrapper">
      {/* <StudentHeader activeMenu={"Profile"} /> */}
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                  <h3>Edit Test Series</h3>
                      <p>Edit Test Series information.</p>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head">
                 
                    </div>
                    {loading === true ? (
                      <div
                        class="d-flex justify-content-center"
                        style={{ position: "absolute" }}
                      >
                        <div
                          class="spinner-border text-warning"
                          style={{
                            left: "50%",
                            marginLeft: "24em",
                            marginTop: "10em",
                          }}
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Enter Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={values.name}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter your Name"
                              />
                              {errors.name && touched.name ? (
                                <span style={{ color: "red" }}>
                                  {errors.name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label
                                className="form-control-label"
                                style={{
                                  marginRight: "10px",
                                  // marginTop: "10px",
                                }}
                              >
                                Status :
                              </label>
                              <br />
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("status").onChange}
                                  value={"0" || values?.status}
                                  checked={values?.status === "0"}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineRadio1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio2"
                                  onChange={getFieldProps("status").onChange}
                                  value={"1" || values?.status}
                                  checked={values?.status === "1"}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineRadio2"
                                >
                                  Active
                                </label>
                              </div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="update-profile">
                          <div
                            class="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <button type="submit" className="btn btn-primary">Update</button>
                          </div>{" "}
                          <div
                            class="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <button type="button" onClick={()=>{navigate(-1)}} className="btn btn-primary cancel-btn ">
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer/>
      <Footer />
    </div>
  );
}
