import React, { useState } from "react";
import StudentHeader from "../../student/header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import TextEditor from "../../pages/course/addCourse/editor";
import { Link } from "react-router-dom";

import StudentSideBar from "../../student/sidebar";
import Select from "react-select";
import AdminInstructorSidebar from "../../instructor/adminSidebar";

export default function AddFeedEdit() {
  const [country, setCountry] = useState(null);
  const options = [
    { label: "Select Status", value: "Status" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  return (
    <div className="main-wrapper">
      {/* <StudentHeader activeMenu={"Profile"} /> */}
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Courses1"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Feed</h3>
                  </div>

                  {/* <div className="course-group mb-0 d-flex">
                  <div className="course-group-img d-flex align-items-center">
                    <Link to="student-profile">
                      <img src={User11} alt="" className="img-fluid" />
                    </Link>
                    <div className="course-name">
                      <h4>
                        <Link to="student-profile">Your avatar</Link>
                      </h4>
                      <p>PNG or JPG no bigger than 800px wide and tall.</p>
                    </div>
                  </div>
                  <div className="profile-share d-flex align-items-center justify-content-center">
                    <Link to="#;" className="btn btn-success">
                      Update
                    </Link>
                    <Link to="#;" className="btn btn-danger">
                      Delete
                    </Link>
                  </div>
                </div> */}
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head">
                      <h4>Feed</h4>
                    </div>
                    <form action="#">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Title</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter title"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Subtitle
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter subtitle"
                            />
                          </div>
                        </div>

                        <div className="form-group mb-0">
                          <label className="add-course-label">
                            Course Description
                          </label>
                          <div id="editor">
                            <TextEditor />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              style={{ marginRight: "10px", marginTop: "30px" }}
                            >
                              Status :
                            </label>
                            <br />
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="option1"
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >
                                Draft
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="option2"
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >
                                Published
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button type="button" className="btn btn-primary">
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button type="button" className="btn btn-danger">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
