import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import { Link, useNavigate, useParams } from "react-router-dom";
import StudentSideBar from "../sidebar";
import Select from "react-select";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextEditor from "../../pages/course/addCourse/textEditor";
import { useFormik ,useFormikContext} from "formik";
import axios from "../../../api/axios";
import * as Yup from "yup";
import AuthContext from "../../../context/AuthProvider";
import AdminHeader from "../../instructor/adminHeader";
import { notification, Space } from "antd";


const CREATEEXAMALLERT = "/api/student/create/exam-alert";
const initalValue = {
  name: "",
  status: "",
  date: "",
};
const courseSchema = Yup.object({
  name: Yup.string().min(100).required("Please enter your Exam Name"),
  status: Yup.string().required("Enter your  Status"),
  date: Yup.date().required("Enter your Exam Date"),
  // pdf:Yup.string().required("please Upload pdf only")
});
const CreateExamAllerts = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();
  const [touched1, setTouched1] = useState({});
  const [errors1, setErrors1] = useState({});
  
  const [submit, setSubmit] = useState(false);

// const { setTouched } = useFormikContext();

  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  
  useEffect(()=>{
// console.log(file,"file")
  },[file])

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    onSubmit: async (values, { setFieldError }) => {
      setSubmit(true);
      try {
        // console.log(value);
        const formData = new FormData();

        formData.append("name", values.name);

        formData.append("status", values.status);
        formData.append("date", values.date);
        // formData.append("file",file)
        const response = await axios.post(CREATEEXAMALLERT, formData, config);
        // console.log(response);
        if (response) {
          setToast(true);
          openNotificationWithIcon("success", "Exam Alerts Created", "#52c41a");
          setTimeout(() => {
            navigate("/admin/exam-allerts");
          }, 1000);
          // toast.success("Batch Created");
        }
      } catch (error) {
        console.log(error);
        setSubmit(false);
        setToast(false);
        if (error?.response?.status === 422) {
          openNotificationWithIcon("error", error.response.data.message, "red");
        } 
        
       else if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIcon("error", error?.response?.data?.message,"red");
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
        else {
          openNotificationWithIcon("error", "Internal Server Error", "red");
        }
      }
    },
    validationSchema: courseSchema,
  });

  const handleRadio = (e) => {
    if (e === "option2") {
      setFieldValue("amount", 0);
    }
    setRadio(e);
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // const minDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD

  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div
          className="container"
          style={{ marginTop: "-15px", minWidth: "100%" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"exams"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Exam Alerts</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li className="breadcrumb-item" role="button">
                          <a onClick={() => navigate("/admin/exam-allerts")}>
                            Exam Alerts
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Add Exam Alerts
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Exam Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your  Exam Alerts Name"
                              id="name"
                              name="name"
                              value={values?.name}
                              onChange={handleChange}
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        {/* <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Upload Pdf
                            </label>
                            <input
                              type="file"
                              name="pdf" // Use a more descriptive name like "pdf"
                              id="pdf"
                              accept=".pdf" // Restrict to PDF files only
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file.type === "application/pdf") {
                                  // Validate file type
                                  setFile(file);
                                  setFilePre(URL.createObjectURL(file));
                                  setErrors1({ pdf: "" }); 
                                } else {
                                  // Handle invalid file type, e.g., display an error message
                                  setErrors1({
                                    pdf: "Invalid file type. Please upload a PDF file.",
                                  });
                                  setTouched1({ pdf: true }); // Mark the field as touched for error display

                                }
                              }}
                              className="form-control"
                              placeholder="Upload PDF"
                            />
                            { errors1.pdf && touched1.pdf  ? (
                              <span style={{ color: "red" }}>{errors1.pdf}</span>
                            ) : null}
                          </div>
                        </div> */}

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Status :</label>
                            <br />
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="inlineRadios1"
                                onChange={getFieldProps("status").onChange}
                                value="0"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadios1"
                              >
                                Inactive
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="inlineRadios2"
                                onChange={getFieldProps("status").onChange}
                                value="1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadios2"
                              >
                                Active
                              </label>
                            </div>
                            <div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div
                            className="form-group"
                            // style={{ marginTop: "-8px" }}
                          >
                            <label className="form-control-label">
                              Exam Date
                            </label>
                            {/* <input
                              type="date"
                              className="form-control"
                              id="dob"
                              value={values.dob}
                              onChange={handleChange}
                              name="dob"
                              placeholder="Birth of Date"
                            /> */}
                            <input
                              type="date"
                              name="date"
                              id="date"
                              autoCorrect="off"
                              autoComplete="off"
                              value={values?.date}
                              onChange={handleChange}
                              min={new Date().toISOString().split("T")[0]}
                              //   max={maxDate}
                              className="form-control"
                              placeholder="Enter your date of birth"
                            />

                            {errors.date && touched.date ? (
                              <span style={{ color: "red" }}>{errors.dob}</span>
                            ) : null}
                          </div>
                        </div>

                        <div className="update-profile" id="submit-button">
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={submit}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      {/* <ToastContainer /> */}
      <Footer />
    </div>
  );
};

export default CreateExamAllerts;
