import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "../../footer";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import AuthContext from "../../../context/AuthProvider";
import VideoJs from "../../pages/course/addCourse/videoJs";
import VideoPlayer from "../../pages/course/addCourse/VideoPlayer";
import axios from "../../../api/axios";
const VIEWVIDEO = "/api/recorded-video/single-view";
const STARTTIME = "/api/student/videos/user_watch_time";
import "../../instructor/adminTestSeries/index.css";
import VimeoPlayer from "../../pages/course/addCourse/VimeoPlayer";
import VimeoStudent from "../../pages/course/addCourse/Vimeo";
import VimeoAdmin from "../../pages/course/addCourse/videoAdmin";
import { notification } from "antd";

export default function AdminViewVideo() {
  const { viewvid } = useParams();
  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(viewvid),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const token = localStorage.getItem("token");
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [playbackInfo, setPlaybackInfo] = useState("");
  const [watchtime, setWatchTime] = useState("");
  const [linktype, setlinktype] = useState("");
  const [res, setRes] = useState(null);
  const navigate = useNavigate();

  function checkUrl(test_url) {
    let testLoc = document.createElement("a");
    testLoc.href = test_url.toLowerCase();
    let url = testLoc.hostname;
    let what;
    if (url.indexOf("youtube.com") !== -1) {
      what = "Youtube";
    } else if (url.indexOf("vimeo.com") !== -1) {
      what = "Vimeo";
    } else {
      what = "None";
    }
    return what;
  }

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // console.log(decryptedData);
  const videoRef = useRef(null);
  const viewVidos = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${VIEWVIDEO}/${decryptedData}`, config);
      setlinktype(checkUrl(response.data.res.link));
      setRes(response.data.res);
      setOtp(response.data.otp);
      setPlaybackInfo(response.data.playbackInfo);
      setLoading(false);
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
openNotificationWithIcon2("error","Internal Server Error")
      }
    }
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
const openNotificationWithIcon2 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  useEffect(() => {
    viewVidos();
  }, []);
  const YouTubeGetID = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };
  const ytcode = YouTubeGetID(res?.link);
  let vimeo_Reg = /(videos|video|channels|\.com)\/([\d]+)/;
  const style = {
    videoWrapper: {
      position: "relative",
      paddingBottom: "56.25%",
      paddingTop: "25px",
      height: 0,
    },
    iframe: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    video: {
      objectFit: "cover",
      width: "100vw",
      height: "100vh",
      position: "fixed",
      top: "0",
      left: "0",
    },
  };

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  // const sendTime=async()=>{
  //   try {
  //     setLoading(true)
  //     const response = await axios.get(`${STARTTIME}/${decryptedData}`, config);
  //     // setRes(response.data.res);
  //     // setOtp(rrusponse.data.otp);
  //     // setPlaybackInfo(response.data.playbackInfo);
  //     setWatchTime(response?.data?.watchTime)

  //     // console.log(response)
  //     setLoading(false)
  //   } catch (error) {
  //     console.error(error);
  //     if (error?.response?.status === 401) {
  //       localStorage.removeItem('token')
  //       navigate('/admin/login')
  //     }
  //     setLoading(false)
  //   }
  // }
  // useEffect(()=>{
  //   sendTime();
  // })
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  return (
    <div className="main-wrapper">
      {/* <h2>{viewvid}</h2> */}
      {/* <InstructorHeader /> */}
      <div className="page-content">
        <div className="container" style={{ minWidth: "100%" }}>
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            {loading === true ? (
              <div
                className="d-flex justify-content-center"
                style={{ position: "absolute" }}
              >
                <div
                  className="spinner-border text-warning"
                  style={{
                    left: "50%",
                    marginLeft: "24em",
                    marginTop: "10em",
                  }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="col-xl-9 col-lg-8 col-md-12">
                <div className="tak-instruct-group">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="settings-widget">
                        <div className="settings-inner-blk p-0">
                          <div className="sell-course-head comman-space">
                            <h3 style={{ textTransform: "capitalize" }}>
                              {res?.title}
                            </h3>
                            <nav
                              aria-label="breadcrumb"
                              style={{
                                marginTop: "0.5em",
                                marginBottom: "-1.6em",
                              }}
                            >
                              <ol className="breadcrumb">
                                <li
                                  className="breadcrumb-item"
                                  aria-current="page"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate("/admin/recorded-videos-list")
                                  }
                                >
                                  Recorded Videos
                                </li>
                                <li
                                  className="breadcrumb-item active"
                                  aria-current="page"
                                  style={{ cursor: "pointer" }}
                                  // onClick={() =>
                                  //   navigate("/admin/recorded-videos-list")
                                  // }
                                >
                                  View Video 
                                </li>
                              </ol>
                            </nav>
                          </div>
                          {res?.vid_source == "0" ? (
                            <div
                              className="comman-space pb-0"
                              style={{ height: "90vh" }}
                            >
                              <div className="settings-referral-blk table-responsive">
                                {/* Instructor Users */}
                                {/* {res?.vid_source == '0'?} */}
                                <div
                                  style={style.videoWrapper}
                                  onContextMenu={(e) => e.preventDefault()}
                                >
                                  {linktype == "Youtube" ? (
                                    <VideoJs
                                      src={`https://www.youtube.com/embed/${ytcode}`}
                                      type="video/youtube"
                                    />
                                  ) : (
                                    <div className="d-flex align-items-center justify-content-center" style={{height:'550px'}}>
                                      <h2>Added wrong link</h2>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : res?.vid_source === "1" ? (
                            <div
                              className="comman-space pb-0"
                              // style={{ height: "90vh" }}
                            >
                              <div className="settings-referral-blk table-responsive">
                                {/* Instructor Users */}
                                {/* {res?.vid_source == '0'?} */}
                                <div
                                // style={{ width: "100%", maxWidth: "85em", margin: "0 auto" }}
                                >
                                  {linktype == "Vimeo" ? (
                                    <VimeoAdmin
                                      id={res?.link.match(vimeo_Reg)[2]}
                                      viewvid={decryptedData}
                                      watchTime={watchtime}
                                      //  type= "video/vimeo"
                                      // id={res?.link.match(vimeo_Reg)[2]}
                                    />
                                  ) : (
                                    <div className="d-flex align-items-center justify-content-center" style={{height:'550px'}}>
                                      <h2>Added wrong link</h2>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="comman-space pb-0"
                              style={{ height: "70vh" }}
                            >
                              {}
                              <div className="settings-referral-blk table-responsive">
                                {/* Instructor Users */}
                                {/* {res?.vid_source == '0'?} */}
                                <div style={style.videoWrapper}>
                                  <iframe
                                    src={`https://player.vdocipher.com/v2/?otp=${otp}&playbackInfo=${playbackInfo}`}
                                    width="860"
                                    height="315"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allowFullScreen={true}
                                    allow="encrypted-media"
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
