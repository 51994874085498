import React from "react";
import axios from "../../../../api/axios";

const PaginationLive = ({
  nPages,
  currentPage,
  setCurrentPage,
  link,
  setData,
  linkSearch,
  text,
}) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const handleSetData = async (pgNumber) => {
    if(text.length>0){
      try {
        const res = await axios.post(
          `${linkSearch}?page=${pgNumber}`,
          { name: text },
          config
        );
        if (res.data.liveVideo.data.length > 0) {
          setCurrentPage(pgNumber);
          setData(res.data.liveVideo.data);
        }
      } catch (error) {
        console.log(error);
      }
    }else{ const res = await axios.get(`${link}?page=${pgNumber}`, config);
    setCurrentPage(pgNumber);
    setData(res.data.liveVideo.data);}
   
  };

  const nextPage = async () => {
    if (currentPage !== nPages) {
      if (text.length > 0) {
        try {
          const res = await axios.post(
            `${linkSearch}?page=${currentPage + 1}`,
            { name: text },
            config
          );
          if (res.data.liveVideo.data.length > 0) {
            setCurrentPage(currentPage + 1);
            setData(res.data.liveVideo.data);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setCurrentPage(currentPage + 1);
        const res = await axios.get(`${link}?page=${currentPage + 1}`, config);
        setData(res.data.liveVideo.data);
      }
    }
  };
  const prevPage = async () => {
    if (currentPage !== 1) {
      if (text.length > 0) {
        try {
          const res = await axios.post(
            `${linkSearch}?page=${currentPage - 1}`,
            { name: text },
            config
          );
          if (res.data.liveVideo.data.length > 0) {
            setCurrentPage(currentPage - 1);
            setData(res.data.liveVideo.data);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setCurrentPage(currentPage - 1);
        const res = await axios.get(`${link}?page=${currentPage - 1}`, config);
        setData(res.data.liveVideo.data);
      }
    }
  };
  return (
    <nav>
      <ul className="pagination lms-page d-flex align-items-center justify-content-center">
        <li className="page-item prev">
          <a className="page-link" onClick={prevPage} href="#">
            <i className="fas fa-angle-left" />
          </a>
        </li>
        {pageNumbers.map((pgNumber) => (
          <li
            key={pgNumber}
            className={`${
              currentPage == pgNumber
                ? "page-item first-page active"
                : "page-item first-page"
            } `}
          >
            <a
              onClick={() => handleSetData(pgNumber)}
              className="page-link"
              href="#"
            >
              {pgNumber}
            </a>
          </li>
        ))}
        <li className="page-item next">
          <a className="page-link" onClick={nextPage} href="#">
            <i className="fas fa-angle-right" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default PaginationLive;
