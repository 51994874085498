import axios from "axios";
// const BASEURL ='https://lmscode.stellarflux.in/'
    const BASEURL ='https://aspirantcode.chahalacademy.com'
    // const BASEURL ='https://lmscode.chahalacademy.com'
// const BASEURL ='https://aspirantcode.chahalacademy.com'
// console.log(localStorage.getItem("db"))


export default axios.create({
    baseURL:BASEURL,
    headers: {
        // Default content type
       'Db': sessionStorage.getItem('db') ? sessionStorage.getItem('db') : 0, // Default accept type
   }
    
})

