import React, { useEffect, useRef, useState } from 'react';
// import Vimeo from '@vimeo/player';
// import './video.css'; commited for iframe 
import axios from '../../../../api/axios';
const STARTTIME="/api/student/videos/user_watch_time"
import {   notification, Space  } from "antd";
import { useNavigate } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';

const VimeoAdmin = ({ id,viewvid }) => {
//   console.log(viewvid,"id")
//   const playerRef = useRef(null);
  const token = localStorage.getItem("tokenStudent");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
 
  const handlePlay = () => {
    console.log('Video playback initiated.');
    // Add your custom logic here
  };

  const handlePlaying = () => {
    console.log('Video started playing.');
    // Add your custom logic here
  };

  const handlePause = () => {
    console.log('Video paused.');
    // Add your custom logic here
  };
  
  return (
    <>
   <iframe
      id="vimeo-iframe"
      title="vimeo-video"
      src={`https://player.vimeo.com/video/${id}`}
      width="100%"
      height="250px"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
     


      </>
  );
}

export default  VimeoAdmin;
//  1st final ooutput with start and pause time

