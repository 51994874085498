

import { useLocation } from 'react-router-dom'

const Scroll = () =>{
    const {pathname} = useLocation()
  
        if(pathname){
            window.scrollTo(0,0)
        }
  return null
}

export default Scroll