import React, { useContext, useEffect, useState } from "react";
import { InstructorHeader } from "../../instructor/header";
import AdminHeader from "../adminHeader";
import Footer from "../../footer";
// import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import Button from "react-bootstrap/Button";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import Switch from "react-switch";
import AuthContext from "../../../context/AuthProvider";
import axios from "../../../api/axios";
import CryptoJS from "crypto-js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip, Input } from "antd";
import { notification, Space } from "antd";
import { Spin } from "antd";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";
const INVOICELIST = "/api/invoice/list";
const DELETEINVOICE = "/api/invoice/delete";
const FEEDSTATUS = "/api/feeds/statusUpdate";
const SEARCH = "/api/search/course/invoice";
import { Modal } from "antd";

const { confirm } = Modal;
// const windowTop1 = (arg1, arg2) => {
//   window.scrollTo(arg1, arg2);
// };
export default function AdminInvoiceList() {
  const { Search } = Input;
  const [show, setShow] = useState(false);
  const [last_page, setLast_page] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(null);
  const [text, settext] = useState("");
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const token = localStorage.getItem("token");
  const [message, setmessage] = useState("No Data");
  const [excel, setExcel] = useState(null);
  const [file, setFile] = useState(null);

  const { auth } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Invoice Deleted Successfully </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const fetchInvoiceList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(INVOICELIST, config);
      // console.log(resonse,"resonse")
      setList(resonse?.data?.data);
      setLast_page(resonse.data.last_page);
      setData(resonse.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIconLogin("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
      openNotificationWithIcon2("error");
      }
    }
  };
  useEffect(() => {
    fetchInvoiceList();
  }, []);
  // console.log(list);
  const handlePayment = (payid) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(payid),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/show-course-payment/${ciphertexts}`);
  };
  const handleClose = () => setShow(false);
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#1677ff" }}>
          <strong>Feed Delted</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconLogin = (type,text) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  
  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this Invoice?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.delete(`${DELETEINVOICE}/${id}`,
         config);
          // console.log("resonse", resonse);
          openNotificationWithIcon3("success");

          setLoading(false);
          fetchInvoiceList();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          if (error?.response?.status === 422) {
            openNotificationWithIcon2(error?.response?.data?.message,"error");

          }
          else{
          openNotificationWithIcon2(error?.response?.data?.message,"error");
        }
      }
      },
      onCancel() {
        fetchInvoiceList()
      },
    });
  }

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const Filter = async (e) => {
    const searchValue = e.target.value;
    settext(searchValue);
    const res = await axios.post(SEARCH, { name: searchValue }, config);
    console.log(res,"res")
    if (res.data.data.length > 0) {
      setList(res.data.data);
      setCurrentPage(res.data.current_page);
      setLast_page(res.data.last_page);
      setmessage('No Data')
    }
    if (res.data.data.length === 0) {
      setList(res?.data?.data)
      setmessage('No Data Found')
    }
    if(searchValue.length=== 0){
      const res = await axios.get(`${INVOICELIST}?page=${currentPage}`, config);
      setList(data);
      setCurrentPage(1);
      setLast_page(res.data.last_page);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  const config1 = {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "blob" },
    responseType: "arraybuffer",
  };
  const exportCsv = async (excel,id) => {
    try {
      const response = await axios.get(`/api/invoice/pdf/${id}`, config);
      console.log(response,"res")
      // const fileExtension = ".pdf"; // Change the file extension to ".pdf"
      // const data = new Blob([response?.data?.message], {
      //   type: "application/pdf", // Set the content type to "application/pdf"
      // });
      // FileSaver.saveAs(data, "Invoice" + fileExtension);
       window.open(response?.data?.message, '_blank');

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   windowTop1(0, 0);
  // }, []);
  const itemsPerPage = 10;
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"invoice"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space d-flex"
                        style={{justifyContent:"space-between"}}
                        >
                          <h3> Fee Invoice </h3>
                          {/* <button className="btn btn-primary">Course Category</button> */}
                       
                        </div>
                        
                        <div className="comman-space pb-0">
                          <div
                            // className="instruct-search-blk"
                            // id="table-value"
                          >
                            {/* Instructor Users */}
                            
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row gx-2 align-items-center">
                                    <div className="col-md-6 col-item">
                                      <div className=" search-group">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search  Name"
                                          // allowClear
                                          onChange={Filter}
                                          onKeyDown={handleKeyPress}
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-item">
                                      <div
                                        className="admin-container d-flex justify-content-end"
                                        // style={{  marginBottom:"-5px"}}
                                      >
                                         <Button
                                          onClick={() =>
                                            navigate("/admin/institute")
                                          }
                                          style={
                                            {
                                              color:"white"
                                            }
                                          }
                                          className="btn btn-info"
                                          id="custom-button"
                                        >
                                          {" "}
                                          Institute center List
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            navigate("/admin/Payment-methods")
                                          }
                                          style={
                                            {
                                              // marginLeft: "5.2em",
                                              // width:"100%"
                                            }
                                          }
                                          className="btn btn-danger"
                                          id="custom-button"
                                        >
                                          {" "}
                                          Payment Modes
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            navigate("/admin/create/invoice")
                                          }
                                          style={
                                            {
                                              // marginLeft: "5.2em",
                                              // width:"100%"
                                            }
                                          }
                                          className="btn btn-primary"
                                          id="custom-button"
                                        >
                                          {" "}
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Create New
                                        </Button>
                                       
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                            
                            <table className="table table-nowrap">
                              <thead>
                                <tr className="text-center">
                                  <th>SR. NO.</th>
                                  <th>NAME</th>
                                  <th>Course Name</th>
                                  <th>Invoice No</th>
                                  <th>Invoice Date </th>
                                  <th>Amount </th>
                                  <th>ACTIONS</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                                
                                  <tbody 
                                   className="d-flex justify-content-center align-items-center"
                                   style={{
                                     position: "fixed",
                                     top: 0,
                                     right: 0,
                                     bottom: 0,
                                     left: 0,
                                     backgroundColor: "rgba(255, 255, 255, 0.6)",
                                   }}
                                  >
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                              ) : (
                                list?.map((item, i) => {
                                  const sNo =
                                    (currentPage - 1) * itemsPerPage + i + 1;
                                  return (
                                    <tbody
                                      key={item.id}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <tr className="text-center">
                                        <td className="text-center">{sNo}</td>
                                        <td className="text-center">
                                         
                                                 
                                         <Tooltip title={item?.user?.name}>
                                           {`${item?.user?.name?.slice(0, 20)} ${
                                             item?.user?.name?.length > 20
                                               ? "..."
                                               : ""
                                           }`}
                                              </Tooltip>
                                           
                                        </td>
                                        <td className="text-center">
                                         
                                         <Tooltip title={item?.courses?.name}>
                                           {`${item?.courses?.name?.slice(0, 20)} ${
                                             item?.courses?.name?.length > 20
                                               ? "..."
                                               : ""
                                           }`}
                                         </Tooltip>
                                      
                                   </td>
                                   <td className="text-center">
                                         
                                         {item?.inv_number}
                                      
                                   </td>
                                   <td className="text-center">
                                         
                                         {item?.inv_date}
                                      
                                   </td>
                                   <td className="text-center">
                                         
                                         {item?.total_amount}
                                      
                                   </td>

                                       
                                   
                                        <td className="text-center">
                                         
                                        
                                        <Tooltip title="Download Pdf">
                                            <Button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={(e) => {
                                                exportCsv(excel,item?.id),
                                                  e.preventDefault();
                                                  
                                              }}
                                            >
                                               <i
                                                  className="fa fa-download"
                                                  aria-hidden="true"
                                                ></i>
                                            </Button>
                                          </Tooltip>
                                          <Tooltip title="Delete">
                                            <Button
                                              type="button"
                                              className="btn btn-danger m-2"
                                              onClick={() =>
                                                showConfirm(
                                                  item?.id,
                                                  )
                                              }
                                            >
                                              <i className="fa-regular fa-trash-can"></i>
                                            </Button>
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              )}
                            </table>
                            </div>
                            
                            {list?.length <= 0 ? (
                             <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {list === null ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {list ?.length <= 0  ? (
                              ""
                            ) : (
                              <PaginationVideo
                                nPages={last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={INVOICELIST}
                                setData={setList}
                                text={text}
                                setLast_page={setLast_page}
                                linkSearch={SEARCH}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
}
