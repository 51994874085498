import React, { useContext, useEffect, useState } from "react";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";

import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../api/axios";
import AdminHeader from "../../instructor/adminHeader";
import Select from "react-select";
const BUYCOURSE = '/api/emi-subscription-course';
const SUBCRIPTION = '/api/pay-emi'
import {   notification, Space  } from "antd";


export default function AdminCourseEmi() {
    const { cid, id } = useParams();
    const [cptid, setcptid] = useState(null)
    const [payTypeDetails, setpayTypeDetails] = useState(null)
    const [CourseTypeDetails, setCourseTypeDetails] = useState(null)
    const [cou, setCou] = useState(null)
    const [emicou, setEmiCou] = useState(null)
    const [disemi, setdisemi] = useState(null)
    const [fileInput, setFileInput] = useState(null);
    const [referalId,setReferalId]=useState("")
    const[method,setMethod]=useState("")
    const[transaction,setTransaction]=useState("")
    const[file,setFile]=useState("")
    const[paymentvalue,setPaymentValue]=useState("")
    let bytes = CryptoJS.AES.decrypt(
        decodeURIComponent(cid),
        "my-secret-key@admin8623009959"
    );
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    let userid = CryptoJS.AES.decrypt(
        decodeURIComponent(id),
        "my-secret-key@admin8623009959"
    )
    let decryptedUserid = JSON.parse(userid.toString(CryptoJS.enc.Utf8));
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const style = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "white",
            width: "100%",
            height: "40px",
            color: "black",
            minHeight: "40px",
            border: "1px solid #e9ecef",
            paddingLeft: "5px",
            // This line disable the blue border
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: state.isSelected ? "0" : "10px",
            fontSize: "14px",
            "&:hover": {
                cursor: "pointer",
            },
            outline: "none",
        }),
        menu: (base) => ({ ...base, marginTop: "0px" }),
        menuList: (base) => ({ ...base, padding: "0" }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#FFDEDA" : "white",
            color: "black",
            fontSize: "14px",
            "&:hover": {
                backgroundColor: "#FFDEDA",
                // #dddddd
            },
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            color: "black",
            transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
            transition: "250ms",
        }),
    };
    const fetchBuyCourse = async () => {
        try {
            const res = await axios.get(`${BUYCOURSE}/${decryptedData}/${decryptedUserid}`, config)
            setCourseTypeDetails(res?.data?.CourseTypeDetails);
            setpayTypeDetails(res?.data?.payTypeDetails)
            const payment=[]
            const paymentdetail =res?.data?.payTypeDetails.map((pay)=>{

                        setcptid(pay.cpt_id);
                        payment.push(pay)
                    
             
            })
            const arr = []
            const arr1 = []
            let numofinstall=''
            const paymentrefine =[...new Set(payment)]
            paymentrefine.map((emi,i,row)=>{
                    // if (emi.inst_number == res.data.countInstallment) {
                    //  arr.push(emi)
                        
                    // }
                    if (Number(res.data.countInstallment)+1  ==  emi.inst_number) {
                        numofinstall=emi.inst_number
                        arr1.push(emi);
                    }
        
            })
            const course =  res.data.payTypeDetails.filter((e)=>e.inst_number <=res.data.countInstallment)
            setCou(course);
            setEmiCou([...new Set(arr1)]);
            const data = Array.apply(null, Array(Number(numofinstall))).map(function (x, i) { return i + 1; })
            const payTypeDetailsFilter= res?.data?.payTypeDetails.filter((e)=> e.inst_number <=numofinstall)
              let  result = res?.data?.payTypeDetails.filter(element => !payTypeDetailsFilter.find(e => e.inst_number === element.inst_number ));
            setdisemi(result);
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 401) {
                localStorage.removeItem('token')
                navigate('/admin/login')
              }
        }

    }
    useEffect(() => {
        fetchBuyCourse()
    }, [])
    const PaymentSelect = () => {
        const paymentoptions = [{ name: "Cash" }, { name: "Online" }];
        const optionsvalue = paymentoptions.map((item) => ({
          value: item.name,
          label: item.name,
        }));
      
        return optionsvalue;
      };
    //   const optionsData=(event)=>{
    //     setPaymentValue()

    //   }
      const optionsvalue = PaymentSelect();
      const referaiIdvalue=(event)=>{
        setReferalId(event.target.value)
      }
      const handleFileInputChange = (event) => {
        setFileInput(event.target.files[0]);
      };
      console.log(fileInput,"fileinput")
      const [api, contextHolder] = notification.useNotification();
  
      const[toast,setToast]=useState(false)
     
        const openNotificationWithIcon = (type) => {
          console.log("toast after click")
          notification[type]
          (
            
            {
            message: 
            (
              <div style={{color:"#52c41a"}}>
               <strong>Emi Payed Successsfuly </strong> 
              </div>
            ),
          
            // description:
            // (<div style={{color:"green"}}>
            // Your Batch has Sucessfully Deleted
        
            // </div>)
            duration:1,
          });
          };
    const handleSubmmit = async (event) => {
        event.preventDefault();
        let pay_mode = "";
        if (paymentvalue === "Cash") {
            pay_mode = "0"; 
          } else if (paymentvalue === "Online") {
            pay_mode = "1"; 
          }
          if(paymentvalue==""){
          return  setMethod("Select Payment Option")
        }
        if(referalId=="" && paymentvalue=="Online"){
          return  setTransaction("Select Transaction No")
            
        }
        // if(fileInput==null ){
        //     setFile("Select File")
            
        // }
        const formData = new FormData();
        if (fileInput === null) {
          formData.append("pay_proof", '');
        } else if (fileInput !== null) {
          formData.append("pay_proof", fileInput);
        } else { formData.append("pay_proof", fileInput); }
        formData.append("paymentOption", cptid);
        formData.append("pay_mode",pay_mode );
        formData.append("ref_no", referalId);
        formData.append("userid",decryptedUserid );
        const responce = await axios.post(
            SUBCRIPTION,
            formData,
            config
        );
        if (responce) {
            openNotificationWithIcon("success")
            setTimeout(() => {
                navigate(`/admin/paid-student`);
            }, 800);
            // toast.success("Emi Payed Successsfuly");
        }
        if (responce.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
          }
    };
    return (
        <div className="main-wrapper">
            <AdminHeader />
            {/* Student Dashboard */}
            <div className="page-content">
                <div className="container"
                style={{minWidth:"100%",marginTop:"-15px"}}
                >
                    <div className="row">
                        {/* Sidebar */}
                        <AdminInstructorSidebar activeMenu={"paid"} />
                        {/* Sidebar */}

                        {/* Profile Details */}
                        <div className="col-xl-9 col-md-8 main-page">
                            <div className="settings-widget profile-details">
                                <div className="settings-menu p-0">
                                    <div className="profile-heading">
                                        <h3>Course Emi</h3>
                                        <nav aria-label="breadcrumb" >
                                            <ol class="breadcrumb" style={{ marginBottom: 0.5 }}>
                                                <li
                                                    class="breadcrumb-item "
                                                    onClick={() => navigate(-1)}
                                                    // aria-current="page"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Paid Students
                                                </li>
                                                <li
                                                    class="breadcrumb-item active"
                                                    aria-current="page"
                                                    style={{
                                                        cursor: "pointer",
                                                        // marginLeft: "-.6em",
                                                        fontWeight: 'normal'
                                                    }}
                                                >
                                                    Course Emi
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>

                                    <div className="checkout-form personal-address add-course-info ">
                                        <div className="personal-info-head"></div>
                                        <form>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        {/* {payTypeDetails?.map((pay,i,row) =>
                                                            CourseTypeDetails?.map((course) =>  pay?.course_id ==  course.course_id ?
                                                          (  pay?.inst_number ==  course.inst_number?<div
                                                            className="plan-box shadow-sm rounded p-4"
                                                            style={{ transition: "box-shadow 0.3s ease" }}
                                                        >
                                                            <div>
                                                                <h4>{course?.inst_number} Installment</h4>
                                                                <p>
                                                                    Pay ₹ {course?.inst_amount}
                                                                </p>
                                                            </div>
                                                        </div>: row.length+1 ==course.inst_number?<><div
                                                            className="plan-box shadow-sm rounded p-4"
                                                            style={{ transition: "box-shadow 0.3s ease" }}
                                                        >
                                                            <div>
                                                                <h4>{course?.inst_number} Installment</h4>
                                                                <p> 
                                                                    Pay ₹ {course?.inst_amount}
                                                                </p>
                                                            </div>
                                                            <input type="radio" value={course.cpt_id} onClick={()=>console.log(course.cpt_id)} checked={Number (pay?.inst_number)+1 ==course.inst_number}  style={{width:'35px',height:'35px'}}  />
                                                        </div></>:<div
                                                            className="plan-box shadow-sm rounded p-4"
                                                            style={{ transition: "box-shadow 0.3s ease" }}
                                                        >
                                                            <div>
                                                                <h4>{course?.inst_number} Installment</h4>
                                                                <p>
                                                                    Pay ₹ {course?.inst_amount}
                                                                </p>
                                                            </div>
                                                    
                                                                <input type="radio" disabled={true} style={{width:'35px',height:'35px'}}  />
                                                          
                                                        </div>)      : "")
                                                        )} */}
                                                        {cou?.map((cos) => {
                                                            return <div
                                                                className="plan-box shadow-sm rounded p-4"
                                                                style={{ transition: "box-shadow 0.3s ease" }}
                                                            >
                                                                <div>
                                                                    <h4>{cos?.inst_number} Installment</h4>
                                                                    <p>
                                                                        Pay ₹ {cos?.inst_amount}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        })}
                                                        {emicou?.map((cou) => {
                                                            return <div
                                                                className="plan-box shadow-sm rounded p-4"
                                                                style={{ transition: "box-shadow 0.3s ease" }}
                                                            >
                                                                <div>
                                                                    <h4>{cou?.inst_number} Installment</h4>
                                                                    <p>
                                                                        Pay ₹ {cou?.inst_amount}
                                                                    </p>
                                                                </div>
                                                                <input type="radio" value={cou.cpt_id} checked={true} style={{ width: '35px', height: '35px' }} />
                                                            </div>
                                                        })}
                                                        {disemi?.map((cou) => {
                                                            return <div
                                                                className="plan-box shadow-sm rounded p-4"
                                                                style={{ transition: "box-shadow 0.3s ease" }}
                                                            >
                                                                <div>
                                                                    <h4>{cou?.inst_number} Installment</h4>
                                                                    <p>
                                                                        Pay ₹ {cou?.inst_amount}
                                                                    </p>
                                                                </div>

                                                                <input type="radio" disabled={true} style={{ width: '35px', height: '35px' }} />

                                                            </div>
                                                            
                                                        })}
                                                          <div className="row  gx-2 align-items-center"> 
                                                            <div className="col-md-4 col-item">
                                                            <label className="form-label">
                                                            Select the Payment Options
                                                        </label>
                                                              
                                                <Select
                                                            className=" select country-select"
                                                            name="questionType"
                                                            id="questionType"
                                                            options={optionsvalue}
                                                            placeholder="Select your Payment Options"
                                                            onChange={(option) => {
                                                                setPaymentValue(option?.label)
                                                            }
                                                            }
                                                             styles={style}
                                                        ></Select>
                                                           {paymentvalue==""?<span style={{color:'red'}}>{method}</span>:""}

                                                </div>
                                                <div className="col-md-4 col-item">
                                                <label className="form-label"
                                                style={{marginBottom:"5px"}}
                                                >Enter your Transaction No</label>

                                                <input
                                               type="text"
                                               name="Referal"
                                               id="referalNo"
                                               value={referalId}
                                               onChange={referaiIdvalue}
                                               disabled={paymentvalue=="Cash"}
                                               className="form-control"
                                               placeholder="Enter Your Transaction Number"
                                                                                />
                               {referalId=="" && paymentvalue=="Online" ? <span style={{color:'red'}}>{transaction}</span>: ""}

                                                </div>
                                                <div className="col-md-4 col-item">
                                                <label className="form-label">Upload Image</label>

                                                <input type="file" name="fileInput" accept=".pdf, .jpg, .jpeg, .png"
                                                                                               disabled={paymentvalue=="Cash"}

                                                 onChange={handleFileInputChange}
                                                />
                                              {/* {file && <span style={{color:'red'}}>{file}</span>} */}
 
                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className="update-profile"
                                                  id="submit-button"

                                                >
                                                    <div
                                                        class="btn-group mr-2"
                                                        role="group"
                                                        aria-label="First group"
                                                        id="custom-submit"

                                                    >
                                                        <button onClick={(e) => handleSubmmit(e)} type="submit" className="btn btn-primary">
                                                            Submit
                                                        </button>
                                                    </div>{" "}
                                                    <div
                                                        class="btn-group mr-2"
                                                        role="group"
                                                        aria-label="First group"
                                                        id="custom-submit"

                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                navigate(-1);
                                                            }}
                                                            className="btn btn-primary cancel-btn "
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>{" "}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Profile Details */}
                    </div>
                </div>
            </div>
            {/* Student Dashboard */}
            <ToastContainer />
            <Footer />
        </div>
    );
}
