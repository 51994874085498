import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import Footer from "../../footer";
import StudentHeader from "../../student/studentHeader";
import axios from "../../../api/axios";
import male from "../../../assets/img/slider/home-slider-2.png"
import { Course26, StudentIcon, User1 } from "../../imagepath";
const TEST = "/api/student/test-series/courses";
import { notification, Space, Tooltip } from "antd";
import "./style.css"
import FooterStudent from "../../fotterstudent";
import axiosInstance from "../../../api/axios1";
import StudentSideBar from "../../student/sidebar";
const QUESTIONSTATUS = "/api/student/test-attempt"
import {
  Course10,
  Course11,
  Course12,
  Course13,
  Course14,
  Icon1,
  InstructorProfileBg,
  TimerStart,
  User15,
} from "../../imagepath";
export default function AddStudentTest() {
  localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState(null);
  const navigate = useNavigate();
  const tokenStudent = localStorage.getItem("tokenStudent");
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const [data, setData] = useState(null)
  const[responsettempt,setResponseattempt]=useState(false)
  const getVideo = async () => {
    try {
      setLoading(true);
      const responce = await axiosInstance.get(TEST, config);
      // console.log(responce)
  
      setLoading(false);

      setList(responce?.data);



    }
    catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('token')
        navigate('/login')
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIcon2("error", error?.response?.data?.message,);
        setTimeout(() => {
          navigate("/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error",error?.response?.data?.message)

      }
      setLoading(false)

    }

    // console.log([ ...submodulenew.flat()]);
  };
  useEffect(() => {
    getVideo();
  }, []);
// console.log(list,"length")
const openNotificationWithIconSesion = (type) => {
  notification[type]({
    message: (
      <div style={{ color: "red" }}>
        <strong>Session Expired Please Login </strong>
      </div>
    ),
    duration: 1,
  });
};
const openNotificationWithIcon3 = (type,message) => {
  notification[type]({
    message: (
      <div style={{ color: "red" }}>
        <strong>{message} </strong>
      </div>
    ),
    duration: 1,
  });
};

const [attemptid,setAttemptId]=useState()
  const [currentPage, setCurrentPage] = useState(1)
  const indexOfLastCourse = currentPage * 10
  const indexOfFirstCourse = indexOfLastCourse - 10
  const visibleCourse = list?.slice(indexOfFirstCourse,indexOfLastCourse)
  const courseLength = list?.length ? list?.length : 0
  const numOFTotalPage = Math?.ceil(courseLength / 10)
  // console.log(numOFTotalPage)
   const pages = [...Array(numOFTotalPage + 1).keys()].slice(1)
//  console.log(pages,"paages")

  const handleTest = async(id,) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    // let date = new Date();
    // let current = moment(date).format("YYYY-MM-DD HH:mm:ss").valueOf()
    // let expiry_dates = moment(expiry_date).format("YYYY-MM-DD HH:mm:ss").valueOf()
    // console.log(`${current}     ${expiry_dates}`);
    // if (current > expiry_dates) {
    //   toast.success("Course is expired ");
    // } else {
      try {
        setLoading(true);
        const responce = await axiosInstance.get(`${QUESTIONSTATUS}/${id}`, config);
        if(responce){
          setResponseattempt(true)
          navigate(`/student/test-series/question/${ciphertexts}`);

        }
  
        setLoading(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
openNotificationWithIconSesion("error")
          localStorage.removeItem('tokenStudent')
          navigate('/login')
        }
        else{
        openNotificationWithIcon3("error",error?.response?.data?.message)
      }
    }

    // }
  };
  
  const handleTestResult = (id,type) => {
if(type==1){
  navigate(`/student/test-series-result/${id}`);

}
else{
  navigate(`/student/test-series-result-single/${id}`)
}
  };
  useEffect(() => {
    const lastVisiteStudentRoute = localStorage.getItem("lastVisiteStudentRoute")
    if (lastVisiteStudentRoute) {
      localStorage.removeItem("lastVisiteStudentRoute")
    }
    else {
      localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);

    }
  }, []);
  const openNotificationWithIcon2 = (type) => {
   
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "red" }}>
                <strong>Internal Server Error </strong>
              </div>
            ),

          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted

          // </div>)
          duration: 1
        });
  };
  useEffect(() => {
    const lastVisiteStudentTest = localStorage.getItem("lastVisiteStudentTest")
    if (lastVisiteStudentTest !== window.location.pathname) {
      localStorage.removeItem("startTime");
      localStorage.removeItem("expirationTime");

    }
    else {
      localStorage.removeItem("startTime");
      localStorage.removeItem("expirationTime");
    }
  })
  const itemsPerPage = 10;
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };
  
  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);

  const renderPagination = () => {
    const pagesToShow = 6;
    const totalPageCount = Math.ceil(courseLength / itemsPerPage);

    if (totalPageCount <= pagesToShow) {
      return [...Array(totalPageCount).keys()].map(pageNum => (
        <li
          key={pageNum}
          className={currentPage === pageNum + 1 ? "page-item active" : "page-item"}
        >
          <Link
            className="page-link"
            to="#"
            onClick={() => setCurrentPage(pageNum + 1)}
          >
            {pageNum + 1}
          </Link>
        </li>
      ));
    } else {
      if (currentPage <= 3) {
        for (let i = 0; i < 5; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i + 1 ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
        pages.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
        pages.push(
          <li
            key={totalPageCount - 1}
            className="page-item"
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      } else if (currentPage >= totalPageCount - 2) {
        pages.push(
          <li
            key={0}
            className="page-item"
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
        for (let i = totalPageCount - 5; i < totalPageCount; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i + 1 ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
      } else {
        pages.push(
          <li
            key={0}
            className="page-item"
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i)}
              >
                {i}
              </Link>
            </li>
          );
        }
        pages.push(<li key="ellipsis2" className="page-item disabled"><span className="page-link">...</span></li>);
        pages.push(
          <li
            key={totalPageCount - 1}
            className="page-item"
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      }
      return pages;
    }
  };
  const prevPageHandler = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1)
  }
  const nextPageHandler = () => {
    if (currentPage !== numOFTotalPage) setCurrentPage(currentPage + 1)
  }
  const fecthquestionStatus = async () => {
    try {
      setLoading(true);
      const responce = await axiosInstance.get(`${QUESTIONSTATUS}/${attemptid}`, config);
      if(responce){
        setResponseattempt(true)
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/login')
      }
      openNotificationWithIcon2("error")
    }
  }
  useEffect(()=>{
  },[])
   const handelTestSeries=(id)=>{
    navigate(`/student/test-series/${id}`)
   }
  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"Test"} />
      {/* BreadCrumb */}
      <div className="breadcrumb-bar">
        <div className="container"
          style={{ minWidth: "98%", margin: "auto" }}

        >
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="">Test Series</Link>
                    </li>
                    {/* <li className="breadcrumb-item">Students Videos</li> */}
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* BreadCrumb */}
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container"
          style={{ minWidth: "98%", margin: "auto" }}

        >
          <div className="row">
            <div className="all-course ">
              {/* <h3 class="mb-3 mb-md-4 mb-lg-5 ">Test Series</h3> */}
              {/* animate__animated animate__fadeIn live-classes-heading */}
              <div className="row">
                {loading === true ? (
                  <div
                    style={{ display: 'block' }}
                    id="cover-spin"
                  >
                  </div>
                ) : (
                  <div className="row">
                  <StudentSideBar activeMenu={"videos"} />
                  <div className="col-xl-9 col-lg-9 col-md-12">
                  <div className="col-lg-12">
        <h3 className="mb-3 mb-md-4 mb-lg-5 animate__animated animate__fadeIn live-classes-heading">
          Test Series
        </h3>
      </div>
      <div className="settings-tickets-blk course-instruct-blk ">
                      {/* Referred Users */}
                     
                          {list?.length>0?list?.map((item,index)=>{
                            
                            return(
                            <div key={index}>
                           
                                <div
                            className="row d-flex"
                            style={{
                              marginTop: "20px",
                              borderBottom:
                                "2px solid rgba(199, 199, 199, 0.25)",
                              paddingBottom: "10px",
                            }}
                            key={item?.id}
                          >
                            <div className="col-lg-3 col-sm-12"
                            style={{alignSelf:"center"}}
                            key={item?.id}
                            >
                              <div
                                className="videoimg-div"
                                style={{
                                  justifyContent: "center",
                                  padding: "5px",
                                  width: "100%",
                                  height: "100%",
                                  margin:"auto",
                                  // border:"1px solid red"
                                }}
                              >
                                  <img
                                    src={item?.image}
                                    className="videoimg"
                                    alt=""
                                    
                                  />
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12"
                            style={{alignSelf:"center",justifyContent:"center",}}
                            >
                              <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12"
                                style={{padding:"5px",margin:"auto"}}
                                >
                                  <div
                                    className="sell-tabel-info"
                                    style={{ marginLeft: "0",maxWidth:"100%", padding:"5px"}}
                                  >
                                    <p style={{ textTransform: "capitalize" }}>
                                      <Link to="">
                                        {item?.courseName} course
                                      </Link>
                                    </p>
                                    <div className="course-info d-flex align-items-center border-bottom-0 pb-0">
                                      <div className="rating-img d-flex align-items-center">
                                        <img src={Icon1} alt="" />
                                        <p>
                                          {item?.count?.testSeries} {""}Tests
                                        </p>
                                      </div>
                                    
                                    </div>
                                    <Tooltip title={ `${
                                            ((item?.count?.attempt || 0) /
                                              (item?.count?.testSeries || 1)) *
                                            100
                                          }%`}>
                                    <div className="course-stip progress-stip">
                                      
                                      <div
                                        className="progress-bar bg-success progress-bar-striped active-stip"
                                        style={{
                                          width: `${
                                            ((item?.count?.attempt || 0) /
                                              (item?.count?.testSeries || 1)) *
                                            100
                                          }%`,
                                        }}
                                      >
                                        
                                      </div>
                                    </div>
                                    </Tooltip>
                                  </div>
                                </div>
                                <div
                                  className="col-lg-4 col-sm-12"
                                  style={{
                                    justifyContent: "center",
                                    margin: "auto",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignSelf: "center",
                                    }}
                                  >
                                    <button
                                      className="btn btn-primary"
                                      style={{ marginTop: "20px" }}
                                      onClick={()=>{
                                        handelTestSeries(item?.id)
                                      }}
                                    >
                                      Show TestSeries
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            </div>
                            )
                          }):<div style={{margin:"auto",padding:"20px"}}><h4 style={{textAlign:"center"}}>No Test series</h4></div>}
                        
                              
                              
                         
                      
                      {/* Referred Users */}
                    </div>
       
      </div>
        </div>
                    )}
              </div>
            </div>
          </div>
        </div>
        {/* {visibleCourse?.length>0?<div className="row"
        style={{marginTop:"20px"}}
        >
          <div className="col-md-12">
                 <div className="fixtable">
                            <ul className="pagination lms-page d-flex align-items-center justify-content-center" style={{ marginLeft: '1.5em' }}>
                              <li className="page-item prev" onClick={() => prevPageHandler()}>
                                <Link className="page-link" to="#" tabIndex={-1}>
                                  <i className="fas fa-angle-left" />
                                </Link>
                              </li>
                              {renderPagination()}

                              <li className="page-item next" onClick={() => nextPageHandler()}>
                                <Link className="page-link" to="#">
                                  <i className="fas fa-angle-right" />
                                </Link>
                              </li>
                            </ul>
                          </div>
          </div>
        </div>:""} */}
        
      </div>

      <ToastContainer />
      {/* Page Wrapper */}
      <FooterStudent />
    </div>
  );
}
