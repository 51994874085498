import React, { useEffect, useRef, useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../footer";
import {
  Course10,
  Course11,
  Course12,
  InstructorProfileBg,
  User15,
} from "../../imagepath";
import { InstructorHeader } from "../../instructor/header";
import StudentHeader from "../../student/studentHeader";
import InstructorSidebar from "../sidebar";
import StudentInstructorSidebar from "../studentSidebar";
import Chart from "react-apexcharts";
import axios from "../../../api/axios";
import AuthContext from "../../../context/AuthProvider";
import { notification, Space, Modal, Tooltip } from "antd";
import "./index.css";
import FooterStudent from "../../fotterstudent";
import axiosInstance from "../../../api/axios1";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Form from "react-bootstrap/Form";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import paidcourseimg from "../../../assets/img/Dashboard/asigncourse.png";
import freecourseimg from "../../../assets/img/Dashboard/freecourses.png";
import totalcouresimg from "../../../assets/img/Dashboard/totalcourses.png";
import examallertimg from "../../../assets/img/Dashboard/examalert.png";
import coursespic from "../../../assets/img/Dashboard/icons8-test-50.png";
import videopic from "../../../assets/img/Dashboard/icons8-video-64.png";
import moment from "moment";

import CryptoJS from "crypto-js";

import StudentSideBar from "../../student/sidebar";
const COURSEPAID = "/api/student/all-paidcourse-count";
const COURSEFREE = "/api/student/all-freecourse-count";
const WATCHEDVIDEO = "/api/student/all-watchedVideo-count";
const VIDEOCOUNT = "/api/student/all-video-count";
const TESTSERIES = "/api/student/all-test_series-count";
const ATTEMPTTEST = "/api/student/attempt_test-count";
const NOTIFACTIONSLIST = "/api/student/notification/exam-alert";
const CREATE = "/api/student/notification/exam-alert/create";
const COURSESLIST = "/api/student/list-student/exam-alert";
const STATUSSEEN = "/api/feeds/students";
const IMAGECOUNT = "/api/feedWatched/create";
const COURSESLISTDATA = "/api/student/courses_list";
const COURSEIDLIST = "/api/student/courses_list/data";
const RESETEXAMDATE = "/api/student/notification/exam-alert/reset";
const LIVECLASSDATE = "/api/student-latest-liveclass";
const PDFDOWNLAOD = "/api/student/exam-alert-student/pdf";
const DASHBOARDCOUNT="/api/dashboard"

export const Dashboard = () => {
  const name = localStorage.getItem("nameuser");
  localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);
  const chartRef = useRef(null);
  const [data, setdata] = useState(20);
  const [data1, setdata1] = useState(28);
  const [hoveredSeries, setHoveredSeries] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("tokenStudent");
  const { auth } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [paidcourse, setpaidCourse] = useState(null);
  const [freecourse, setfreeCourse] = useState(null);
  const [totalcourse, setTotalCourse] = useState(null);

  const [watchedVideo, setwatchedVideo] = useState(null);
  const [Video, setVideo] = useState(null);
  const [testseries, setTestseries] = useState(null);
  const [attemptseries, setAtemptseries] = useState(null);
  const [notficationsdata, setNotifactionsData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [courseid, setCourseid] = useState("");
  const [courseslist, setcourseslist] = useState([]);
  const [usernotification, setUserNotification] = useState([]);
  const [showDateInput, setShowDateInput] = useState(false);
  const [date, setDate] = useState(null);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [isModalVisibleForm1, setIsModalVisibleForm1] = useState(false);
  const [isModalVisibleForm2, setIsModalVisibleForm2] = useState(false);

  const [feeds, setFeeds] = useState([]);
  const [imagefeed, setImagefeed] = useState("");
  const [imageid, setimageid] = useState("");
  const [textcontent1, setTextContent] = useState("");
  const [feedsdata, setFeedsData] = useState(null);
  const [testlist, setTestLits] = useState(null);
  const [videolist, setVideoList] = useState(null);
  const [coursevalue, setCourseValue] = useState("");
  const [buttonvalue, setbutton] = useState(false);
  const [courseslistdata, setCoursesListdata] = useState([]);
  const [courseidlist, setCourseidList] = useState([]);
  const [examDate, setExamDate] = useState("");
  const [liveclass, setLiveclass] = useState(null);
  const[pdflist,setPdfList]=useState([])
  const navigate = useNavigate();
  useEffect(() => {
    const lastVisiteStudentRoute = localStorage.getItem(
      "lastVisiteStudentRoute"
    );
    if (lastVisiteStudentRoute) {
      localStorage.removeItem("lastVisiteStudentRoute");
    } else {
      localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);
    }
  }, []);
  const PaidCourse = async () => {
    try {
      setLoading(true);
      const resonse = await axiosInstance.get(DASHBOARDCOUNT, config);
      // console.log(resonse,"response")
       setpaidCourse(resonse?.data?.paidCourse);
       setfreeCourse(resonse?.data?.freeCourse);
       setTotalCourse(resonse?.data?.totalCourse)

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")

        localStorage.removeItem("tokenStudent");

        navigate("/login");

      }
       else if (error?.response?.status === 403) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIcon1("error", error?.response?.data?.message,);
        setTimeout(() => {
          navigate("/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error");

      }
    }
  };
 

 
  const Notifications = async () => {
    try {
      setLoading(true);
      const resonse = await axiosInstance.get(NOTIFACTIONSLIST, config);
      setNotifactionsData(resonse?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")

        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
       else if (error?.response?.status === 403) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIcon1("error", error?.response?.data?.message,);
        setTimeout(() => {
          navigate("/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
      openNotificationWithIcon2("error");
      }
    }
  };
  const coursesdata = async () => {
    try {
      setLoading(true);
      const resonse = await axiosInstance.get(COURSESLIST, config);
      setcourseslist(resonse?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")

        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else if (err?.response?.status === 403) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIcon1("error", err?.response?.data?.message,"red");
        setTimeout(() => {
          navigate("/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
      openNotificationWithIcon2("error");
      }
    }
  };
  const coursesList = async () => {
    try {
      setLoading(true);
      const resonse = await axiosInstance.get(COURSESLISTDATA, config);
      setCoursesListdata(resonse?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")

        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else{
      openNotificationWithIcon2("error");
      }
    }
  };
  const coursesIdListTest = async (id) => {
    // setCourseValue(id);
    coursesIdList(id);
  };
  const ExamResetDate = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const resonse = await axiosInstance.put(RESETEXAMDATE, config);
      if (resonse) {
        setIsModalVisibleForm(false);
        Notifications();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")

        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else{
      openNotificationWithIcon2("error");
      }
    }
  };
  const LiveClassDates = async () => {
    try {
      setLoading(true);
      const resonse = await axiosInstance.get(LIVECLASSDATE, config);
      // console.log(resonse, "resonse");

      if (resonse) {
        setLiveclass(resonse?.data.slice(0, 3));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")

        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else{
      openNotificationWithIcon2("error");
      }
    }
  };
  const handleMeeting = (id, meeting_url, expiry_date) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    let date = new Date();
    let current = moment(date).format("YYYY-MM-DD HH:mm:ss").valueOf();
    let expiry_dates = moment(expiry_date)
      .format("YYYY-MM-DD HH:mm:ss")
      .valueOf();
    // console.log(`${current}     ${expiry_dates}`);
    if (current > expiry_dates) {
      toast.info("Course is Expired ");
    } else {
      navigate(`/student/std-live-class-join/${ciphertexts}`);
    }
    // window.open(meeting_url, '_blank')
  };
  useEffect(() => {
    PaidCourse();
    Notifications();
    coursesdata();
    coursesList();
    LiveClassDates();
  }, []);

  const coursesIdList = async (id) => {
    try {
      setLoading(true);
      const resonse = await axiosInstance.get(`${COURSEIDLIST}/${id}`, config);
      // console.log(resonse.data?.videos, "data");
      setCourseidList(resonse?.data);
      setTestLits(resonse?.data?.testseries);
      setVideoList(resonse?.data?.videos);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("errro")
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else{      
      openNotificationWithIcon2("error");
      }
    }
  };
  useEffect(() => {}, [coursevalue]);
  const handleAddNotification = async () => {
    try {
      const response = await axiosInstance.post(
        CREATE,
        {
          courseId: courseid,
          date: date,
        },
        config
      );
      if (response) {
        setIsModalVisibleForm(false);
        openNotificationWithIcon1("success", "Notifcation Updated", "#52c41a");
        setDate("");

        Notifications();
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 403) {
        openNotificationWithIcon1("error", "403", "red");
      } 
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")

        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else {
        openNotificationWithIcon1("error", error?.response?.data?.message, "red");
      }
      
    }
  };
  const handleShowForm = () => {
    console.log("krishna in showform");
    setIsModalVisibleForm(true);
  };
  const handleShowForm1 = () => {
    console.log("krishna in showform");
    setIsModalVisibleForm2(true);
  };
  const handleEdit = () => {
    console.log("krishna");
    handleShowForm();
  };
  const handleShowFormStatus = () => {
    setIsModalVisibleForm1(true);
  };
  const handleStatus = (file) => {
    // setcourseid(course_id);
    setImagefeed(file);
    // setimageid(id)

    handleShowFormStatus();
  };

  const handelstatuschange = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(STATUSSEEN, config);
      // console.log(response,"response")
      setFeeds(response?.data?.message);
      setFeedsData(response?.data?.message);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIconSesion("error")
        navigate("/login");
      }
      else{
        openNotificationWithIcon2("error");

      }
    }
  };
  const handelimagecount = async (id) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        IMAGECOUNT,
        { fk_feeds_id: id },
        config
      );
      // setImagefeed(response?.data?.message);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else{
      openNotificationWithIcon2("error");
      }
    }
  };
  const handelContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");

    // Get the text content
    const textContentvalue = doc.body.textContent;

    setTextContent(textContentvalue);
  };
  useEffect(() => {
    handelstatuschange();
  }, []);
  const openNotificationWithIcon1 = (type, text, colors) => {
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const unwatchedvideos = Video - watchedVideo;
  const unattemptedTest = testseries - attemptseries;
  const chartOptions = {
    series: [67],
    chart: {
      width: "100%",
      type: "radialBar",
    },
    labels: ["Video Watched "],
    colors: ["#f15b5b"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const chartOptions1 = {
    series: [unattemptedTest, attemptseries],
    chart: {
      width: "106.9%",
      type: "pie",
    },
    labels: [" UnattemptedTest Series", "Attempted Test Series"],
    colors: ["#ffc14d", "#6fdc6f"],
    // theme: {
    //   monochrome: {
    //     enabled: true,
    //     color: "#e5ba73",
    //     shadeTo: "dark",
    //     shadeIntensity: 0.4,
    //   },
    // },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const minuteSeconds = 60;
  const hourSeconds = 3600;
  const daySeconds = 86400;

  const timerProps = {
    isPlaying: true,
    size: 120,
    strokeWidth: 6,
  };

  const renderTime = (dimension, time) => {
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };

  const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
  const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
  const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
  const getTimeDays = (time) => Math.max(0, (time / daySeconds) | 0);
  // const getTimeDays = (time) =>(time / daySeconds) | 0;
  // console.log(notficationsdata?.length, "notficationsdata");
  const startDate = new Date(
    notficationsdata?.userDate?.exam_date !== null
      ? notficationsdata?.userDate?.created_at
      : notficationsdata?.examDate?.exam_date
  );
  const endDate = new Date(
    notficationsdata?.userDate?.exam_date !== null
      ? notficationsdata?.userDate?.exam_date
      : notficationsdata?.examDate?.date
  );

  const differenceInMilliseconds = endDate - startDate;
  const differenceInSeconds = differenceInMilliseconds / 1000;
  const stratTime = startDate / 1000;
  const endTime = endDate / 1000;
  const newdate = Date.now() / 1000;
  const remainingTime = endTime - stratTime;
  const remaingvalue = endTime - newdate;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  const toggleDateInput = (e) => {
    e.preventDefault();
    setShowDateInput((prevState) => !prevState);
  };
  const handleDateChange = (e) => {
    setDate(e.target.value);
  };
  const handleCourseSelect = (selectedCourseId) => {
    // Find the selected course in the courseslist
    const selectedCourse = courseslist?.find(
      (course) => course.id == selectedCourseId
    );
    // console.log(selectedCourse, "selected course");
    //  const selectedDate=courseslist?.find((course)=>course?.data===selectedCourseId)
    // Set the exam date in the state
    // setExamDate("");
    setExamDate(selectedCourse?.date);

    // Set the selected course
    setCourseid(selectedCourseId);
  };

  function isToday(date, date1) {
    // console.log(date, "date in tody");

    try {
      const today = new Date();
      const dateToCheck = new Date(date1);
      // console.log(dateToCheck, "kdkkdkdk");

      return (
        today.getFullYear() === dateToCheck.getFullYear() &&
        today.getMonth() === dateToCheck.getMonth() &&
        today.getDate() === dateToCheck.getDate()
      );
    } catch (error) {
      console.error("Invalid date provided:", date);
      return false; // Or handle the error differently
    }
  }
  function isTomorrow(date, date1) {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const dateToCheck = new Date(date1);

    return (
      tomorrow.getFullYear() === dateToCheck.getFullYear() &&
      tomorrow.getMonth() === dateToCheck.getMonth() &&
      tomorrow.getDate() === dateToCheck.getDate()
    );
  }
  // console.log(liveclass)
  const handleDownload = async (id) => {
    try {
      setLoading(true);

      const response = await axios.get(`${PDFDOWNLAOD}/${id}`, config);
      handleShowForm1()
      console.log(response,"res")
      setPdfList(response?.data)
      setLoading(false);


      // window.open(response?.data?.message, "_blank");
    } catch (error) {
      console.log(error);
      if (error.response && error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"Dashboard"} />
      <div
        className="page-content instructor-page-content"
        style={{
          background: "#d08e8924",
          // padding:"10px"
        }}
      >
        <div className="container" style={{ minWidth: "100%" }}>
          <div className="row">
            {/* Sidebar */}
            <StudentSideBar activeMenu={"Dashboard"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div
              className="col-xl-9 col-lg-9 col-md-12"
              // style={{margin:"auto"}}
            >
              {feeds?.length > 0 ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div
                          className="sell-course-head comman-space"
                          style={{
                            border: "none",
                            display: "flex",
                            gap: "20px",
                            padding: "15px",
                            // flexWrap: "wrap",
                            overflowX:"scroll",
                            WebkitOverflowScrolling: "touch", /* Enable momentum scrolling on iOS */
    scrollbarWidth: "thin", /* "auto" or "thin" for Firefox */
    scrollbarColor: "#888 transparent", /* Color of the scrollbar track and thumb */
    height: "calc(100% - 8px)", /* Adjust this height to accommodate the scrollbar */
                          }}
                        >
                          {feeds?.map((item, index) => {
                            let borderStyle;
                            if (item?.feeduser_watch_count === "0") {
                              borderStyle = "4px solid #5fee5f";
                            } else {
                              borderStyle = "4px solid #b5b6b8";
                            }
                            return (
                              <div key={index}>
                                <div
                                  style={{
                                    border: borderStyle,
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50%",
                                    padding: "4px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  key={item?.id}
                                >
                                  {feedsdata !== null ? (
                                    <div
                                      className="d-flex"
                                      style={{
                                        // border: "3px solid red",
                                        border: "1px solid #ccc",
                                        width: "92%",
                                        height: "92%",
                                        borderRadius: "50%",
                                        justifyContent: "center",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={() => {
                                        handleStatus(item?.file);
                                        handelimagecount(item?.id);
                                        handelContent(item?.content);
                                      }}
                                    >
                                      {feedsdata !== null ? (
                                        <img
                                          src={item?.file}
                                          alt="image"
                                          className="rounded-circle"
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      ) : (
                                        <div style={{ width: "70px" }}>
                                          <Skeleton width="70" />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <Skeleton />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <Modal
                title="Feeds"
                okButtonProps={{
                  style: { backgroundColor: "#FF875A" },
                  children: "Import",
                }}
                open={isModalVisibleForm1}
                okText="Ok"
                cancelText="Close"
                onOk={() => {
                  // handleAddNotification();
                  setIsModalVisibleForm1(false);
                  setbutton(false);
                  handelstatuschange();
                }}
                onCancel={() => {
                  setIsModalVisibleForm1(false);
                  setbutton(false);
                  handelstatuschange();
                }}
              >
                <div className="row">
                  <div className="col-12">
                    <div>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={imagefeed}
                      />
                      <div>
                        {textcontent1 !== null ? (
                          <div className="" style={{ padding: "5px" }}>
                            {textcontent1 !== "null" ? (
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-link"
                                  onClick={() => setbutton(true)}
                                >
                                  Read More
                                </button>
                              </div>
                            ) : (
                              ""
                            )}

                            {buttonvalue ? (
                              <div>
                                {textcontent1 === "null" ? "" : textcontent1}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              <div className="row">
                {/* {paidcourse !== null ?  */}
                {!notficationsdata?.examDate ? (
                  <div className="col-md-6 col-lg-3 col-sm-12 d-flex">
                    <div className="card instructor-card w-100">
                      <div className="card-body">
                        {notficationsdata?.length <= 0 ||
                        notficationsdata?.examDate == null ? (
                          <div
                            className="form-group select-form mb-0 d-flex"
                            style={{
                              marginBottom: "-5px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <button
                              onClick={() => handleEdit()}
                              style={
                                {
                                  //  marginLeft: "5.2em",
                                  // width:"100%"
                                }
                              }
                              className="btn btn-primary"
                              id="custom-button"
                            >
                              {" "}
                              <i className="fa-solid fa-plus"></i> Exam Alerts
                            </button>
                          </div>
                        ) : (
                          ""
                        )}

                        <div></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {notficationsdata?.examDate ? (
                  <div className="col-md-6 col-lg-3 col-sm-12 d-flex">
                    <div
                      className="card instructor-card w-100"
                      style={{
                        border: "none",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      }}
                    >
                      <div className="card-body">
                        <div
                          className="instructor-inner d-flex"
                          style={{
                            justifyContent: "space-between",
                            overflowWrap: "break-word",
                          }}
                        >
                          <h6>
                            {notficationsdata?.examDate != null ? (
                              notficationsdata?.examDate?.name + " Exam Alert"
                            ) : (
                              <Skeleton />
                            )}
                          </h6>
                          {notficationsdata ? (
                            <Tooltip title="Upadte Exam Alert">
                              <div
                                style={{
                                  alignSelf: "self-start",
                                  marginTop: "-6px",
                                }}
                                className="instructor-inner"
                                onClick={() => {
                                  handleEdit();
                                }}
                              >
                                <i
                                  className="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </Tooltip>
                          ) : (
                            <Skeleton />
                          )}
                        </div>
                        {notficationsdata ? (
                          <div
                            className=" row"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div
                              className="countdown-timer col-8"
                              style={{ margin: "auto" }}
                            >
                              {/* <CountdownCircleTimer
        {...timerProps}
        colors="#004777"
                      // colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
        duration={daysDuration}
        initialRemainingTime={remaingvalue}
        size={90}
        strokeWidth={5}

      >
        {({ elapsedTime, color }) => (
          <>
          <div style={{ color , textAlign:"center"}}>
            {renderTime(getTimeDays(remaingvalue))}
            <div style={{marginLeft:"4px",textAlign:"center",color:"red"}}>Days left</div>
          </div>
         
        </>
        )}
        
      </CountdownCircleTimer> */}

                              <h4
                                className="instructor-text-warning d-flex"
                                style={{ marginTop: "10px" }}
                              >
                                {" "}
                                {renderTime(getTimeDays(remaingvalue))}{" "}
                                <span
                                  style={{
                                    fontSize: "14px",
                                    marginTop: "5px",
                                    marginLeft: "3px",
                                  }}
                                >
                                  Days Left
                                </span>
                              </h4>
                            </div>
                            <div
                              className="countdown-timer col-4"
                              style={{
                                justifyContent: "end",
                                alignSelf: "center",
                              }}
                            >
                              {notficationsdata?.examDate != null ? (
                                <img
                                  src={examallertimg}
                                  alt="#"
                                  style={{ width: "48px", height: "48px" }}
                                />
                              ) : (
                                <Skeleton />
                              )}
                            </div>
                            {/* <div    className="col-6"                   
    style={{justifyContent:"flex-start"}}
>
    <h4 className="">

{notficationsdata !== null ? (notficationsdata?.name)  : <Skeleton variant="text" width={100} />}
  </h4>
    </div> */}
                            <div className="d-flex">
                              <Tooltip title="Downlaod Pdf">
                                <button
                                  type="button"
                                  className="btn-primary"
                                  onClick={() => {
                                    if (notficationsdata?.examDate?.id) {
                                      handleDownload(
                                        notficationsdata?.examDate?.id
                                      );
                                    } else {
                                      handleDownload(
                                        notficationsdata?.userDate
                                          ?.fk_examAlert_id
                                      );
                                    }
                                  }}
                                  // onClick={() => {
                                  //   // Open the URL in a new window to trigger the download
                                  //   window.open(item?.url, '_blank');
                                  // }}

                                  style={{
                                    // minWidth: "20px",
                                    margin: "auto",
                                    borderRadius: "4px",
                                    padding:"5px"
                                  }}
                                >
                                  <i
                                    className="fa fa-download"
                                    style={{
                                      color: "white",
                                      marginTop: "-60px",
                                    }}
                                  ></i> Downlaod Pdf
                                </button>
                              </Tooltip>
                            </div>
                          </div>
                        ) : (
                          <Skeleton
                          //  circle
                          //  height="100%"
                          //  width="100%"
                          //  containerClassName="avatar-skeleton"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                   <div className="col-md-6 col-lg-3 col-sm-12 d-flex">
                  <div
                    className="card instructor-card w-100"
                    style={{
                      border: "none",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  >
                    <div className="card-body row">
                      <div className="instructor-inner col-md-7">
                        <h6>
                          {" "}
                          {paidcourse !== null && freecourse !== null ? (
                            "Total Courses"
                          ) : (
                            <Skeleton />
                          )}
                        </h6>
                        <h4
                          className="instructor-text-warning"
                          style={{ marginTop: "25px" }}
                        >
                          {paidcourse !== null && freecourse !== null ? (
                            totalcourse < 10 ? (
                              `0${totalcourse}`
                            ) : (
                              totalcourse 
                            )
                          ) : (
                            <Skeleton />
                          )}
                        </h4>
                      </div>
                      <div
                        className="instructor-inner col-md-5"
                        style={{
                          justifyContent: "end",
                          alignSelf: "center",
                          marginTop: "10px",
                        }}
                      >
                        {paidcourse != null ? (
                          <img
                            src={totalcouresimg}
                            style={{ width: "48px", height: "48px" }}
                            alt="index"
                          />
                        ) : (
                          <Skeleton />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-12 d-flex">
                  <div
                    className="card instructor-card w-100"
                    style={{
                      border: "none",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  >
                    <div
                      className="card-body d-flex row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="instructor-inner col-md-7">
                        <h6>
                          {paidcourse !== null ? "Paid Courses" : <Skeleton />}
                        </h6>
                        <h4
                          className="instructor-text-success"
                          style={{ marginTop: "25px" }}
                        >
                          {paidcourse != null ? (
                            paidcourse < 10 ? (
                              `0${paidcourse}`
                            ) : (
                              paidcourse
                            )
                          ) : (
                            <Skeleton />
                          )}
                        </h4>
                      </div>
                      <div
                        className="col-md-5"
                        style={{
                          justifyContent: "end",
                          alignSelf: "center",
                          marginTop: "10px",
                        }}
                      >
                        {paidcourse != null ? (
                          <img
                            src={paidcourseimg}
                            style={{ width: "48px", height: "48px" }}
                            alt="index"
                          />
                        ) : (
                          <Skeleton />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3 col-sm-12 d-flex">
                  <div
                    className="card instructor-card w-100"
                    style={{
                      border: "none",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  >
                    <div className="card-body row">
                      <div className="instructor-inner col-md-7">
                        <h6>
                          {freecourse !== null ? (
                            "Free Courses"
                          ) : (
                            <Skeleton width="70" />
                          )}
                        </h6>

                        <h4
                          className="instructor-text-info"
                          style={{ marginTop: "25px" }}
                        >
                          {freecourse != null ? (
                            freecourse < 10 ? (
                              `0${freecourse}`
                            ) : (
                              freecourse
                            )
                          ) : (
                            <Skeleton />
                          )}
                        </h4>
                      </div>
                      <div
                        className="instructor-inner col-md-5"
                        style={{
                          justifyContent: "end",
                          alignSelf: "center",
                          marginTop: "10px",
                        }}
                      >
                        {freecourse != null ? (
                          <img
                            src={freecourseimg}
                            style={{ width: "48px", height: "48px" }}
                            alt="index"
                          />
                        ) : (
                          <Skeleton />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
             
                
              </div>
              {liveclass?.length > 0 ? (
                <>
                  <div>
                    <div className="d-flex justify-content-between">
                      <h4> UpComming Live Class</h4>
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          navigate("/student/std-live-class");
                        }}
                      >
                        View More
                      </button>
                    </div>
                    <div className="row">
                      {/* {paidcourse !== null ?  */}
                      {liveclass?.length > 0
                        ? liveclass?.map((item,i) => {
                            const dateObject = new Date(item.start);
                            const formattedDate =
                              moment(dateObject).format("DD-MM-YYYY, h.mma");
                            const timeString = formattedDate.slice(-7);
                            let displayDate;
                            if (isToday(formattedDate, item?.start)) {
                              displayDate = "Today";
                              // console.log("k1");
                            } else if (isTomorrow(formattedDate, item?.start)) {
                              // console.log("k22");

                              displayDate = "Tomorrow";
                            } else {
                              // Use formattedDate with desired time format
                              displayDate =
                                moment(dateObject).format("DD-MM-YYYY");
                            }
                            const currentTimeEpoch = Math.floor(
                              Date.now() / 1000
                            );
                            const startTimeEpoch = item?.start
                              ? Math.floor(new Date(item.start) / 1000)
                              : undefined;

                            // Handle potential errors:
                            if (!startTimeEpoch) {
                              console.error(
                                "Invalid start time format for meeting:",
                                item?.start
                              );
                              return true; // Disable button on error
                            }
                            const timeDifference =
                              startTimeEpoch - currentTimeEpoch;
                            const remainingMinutes = Math.floor(
                              timeDifference / 60
                            );
                            // console.log(remainingMinutes, "mins");
                            return (
                              <div className="col-md-4 d-flex"
                              key={i}
                              >
                                <div
                                  className="card instructor-card w-100"
                                  style={{
                                    border: "none",
                                    boxShadow:
                                      "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                    minWidth: "100px",
                                  }}
                                >
                                  <div
                                    className="card-body d-flex row"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    <div className="instructor-inner col-md-7">
                                      <h6>
                                        {liveclass !== null ? (
                                          item?.title
                                        ) : (
                                          <Skeleton />
                                        )}
                                      </h6>
                                      <h4
                                        className="instructor-text-success"
                                        style={{
                                          marginTop: "20px",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {liveclass != null ? (
                                          `${displayDate} ,${timeString}`
                                        ) : (
                                          <Skeleton />
                                        )}
                                      </h4>
                                    </div>
                                    <div
                                      className="col-md-5"
                                      style={{
                                        justifyContent: "end",
                                        alignSelf: "center",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {paidcourse != null ? (
                                        <div>
                                          <button
                                            className="btn-primary"
                                            style={{
                                              width: "100px",
                                              borderRadius: "8px",
                                              padding: "5px",
                                            }}
                                            onClick={() => {
                                              handleMeeting(
                                                item.id,
                                                item.meeting_url,
                                                item.expiry_date
                                              );
                                            }}
                                            disabled={
                                              remainingMinutes <= 5
                                                ? false
                                                : true
                                            }
                                          >
                                            Join
                                          </button>
                                        </div>
                                      ) : (
                                        <Skeleton />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div>
                <Modal
                  title="Add Exam Alert"
                  okButtonProps={{
                    style: { backgroundColor: "#FF875A" },
                    children: "Import",
                  }}
                  open={isModalVisibleForm}
                  okText="Create"
                  onOk={() => {
                    handleAddNotification();
                  }}
                  onCancel={() => {
                    setIsModalVisibleForm(false);
                    setDate("");
                  }}
                >
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Select Course</Form.Label>
                      <Form.Select
                        value={courseid} // Set the selected value here
                        onChange={(e) => handleCourseSelect(e.target.value)} // Handle the change event
                        placeholder="Select a course"
                      >
                        <option value="">Select...</option>
                        {courseslist?.map((option) => (
                          <option key={option?.id} value={option?.id}>
                            {`${option?.name}-${option?.year}`}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                      <button
                        className="btn btn-primary"
                        onClick={toggleDateInput}
                        disabled={courseid == ""}
                      >
                        Customize Date
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={(event) => {
                          ExamResetDate(event);
                        }}
                        // disabled={courseid==""}
                      >
                        Rest Exam Date
                      </button>
                    </div>
                    {showDateInput && (
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Label>Select Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={date}
                          min={Date.now()}
                          max={examDate}
                          onChange={handleDateChange}
                        />
                      </Form.Group>
                    )}
                  </Form>
                </Modal>
                <Modal
                  title="Downlaod Pdf"
                  okButtonProps={{
                    style: { backgroundColor: "#FF875A" },
                    children: "Import",
                  }}
                  open={isModalVisibleForm2}
                  okText="Downlaod Pdf"
                  // onOk={() => {
                  //   handleAddNotification();
                  // }}
                  bodyStyle={{ maxHeight: '300px', overflowY: 'auto' }}
                  onCancel={() => {
                    setIsModalVisibleForm2(false);
                    // setDate("");
                  }}
                >
                   <Form>
                                <Form.Group className="mb-3">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr className="text-center">
                                        <th scope="col">Pdf Name</th>
                                        <th scope="col">Downlaod Pdf</th>
                                      </tr>
                                    </thead>
                                    {loading === true ? (
                                       <tbody  className="d-flex justify-content-center align-items-center"
                                       style={{
                                         position: "fixed",
                                         top: 0,
                                         right: 0,
                                         bottom: 0,
                                         left: 0,
                                         backgroundColor: "rgba(255, 255, 255, 0.6)",
                                       }}>
                                         <tr>
                                           <td className="spinner-border text-warning"
                                               role="status">
                                            
                                             <span className="sr-only">
                                                 Loading...
                                               </span>
                                           </td>
                                         </tr>
                                       </tbody>
                                    ) : (
                                      pdflist?.map((item, i) => {
                                        return (
                                          <tbody key={i}>
                                            <tr className="text-center">
                                              <td>{item?.name}</td>
                                              <td>
                                              <Tooltip title="Download pdf">
                                            <button
                                              type="button"
                                              className="btn btn-info"
                                            //   onClick={() => {
                                            //     handleDownload(item?.id);
                                                
                                            //   }}
                                              onClick={() => {
                                                // Open the URL in a new window to trigger the download
                                                window.open(item?.file, '_blank');
                                              }}

                                              style={{ marginLeft: ".5em" }}
                                            >
                                              <i
                                                className="fa fa-download"
                                                style={{ color: "white" }}
                                              ></i>
                                            </button>
                                          </Tooltip>
                                              </td>
                                              
                                             
                                            </tr>
                                          </tbody>
                                        );
                                      })
                                    )}
                                  </table>
                                </Form.Group>
                              
                              </Form>
                
                </Modal>
              </div>

              {/* <div className="row"
              
              >
                {Video!=null && Video!=0? <div className="col-md-6"
                // style={{background:"white",borderRadius:"10px",}}
                >
                  <div className="settings-widget"
                  style={{height:"100%",border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",backgroundColor:"white"}}

                  >
                    <div className="settings-inner-blk p-0"
                     style={{border:"none"}}
                    >
                      <div
                        className="d-flex flex-column flex-md-row justify-content-between"
                        style={{ justifyContent: "space-between" }}
                      >
                        <h4
                          style={{
                            marginLeft: "20px",
                            marginTop: "15px",
                            color: " #392C7D",
                          }}
                        >
                          Videos 
                        </h4>
                        <h4
                          style={{
                            marginLeft: "20px",
                            marginTop: "15px",
                            color: " #392C7D",
                            marginRight: "30px",
                          }}
                        >
                          Total Videos : {" "}{Video}
                        </h4>
                      </div>

                      <div className="sell-course-head comman-space"
                       style={{border:"none"}}
                      >
                        <div className="chart">
                          <Chart
                            options={chartOptions}
                            series={chartOptions.series}
                            type={chartOptions.chart.type}
                            width={chartOptions.chart.width}
                          />
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>:<div className="col-md-6 d-flex">
                <Skeleton variant="rect" width={"100%"} height={"100%"} />
              </div>}
               {testseries!=null && testseries!=0? <div className="col-md-6 testvalue"
                              //  style={{background:"white",borderRadius:"10px"}}

               >
                  <div className="settings-widget"
                   style={{height:"100%",border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",backgroundColor:"white"}}
                  >
                    <div
                      className="settings-inner-blk p-0"
                      style={{border:"none",height:"95%"}}
                    >
                      <div
                        className="d-flex flex-column flex-md-row justify-content-between"
                        style={{ justifyContent: "space-between" }}
                      >
                        <h4
                          style={{
                            marginLeft: "20px",
                            marginTop: "15px",
                            color: "#392C7D",
                          }}
                        >
                          Test Series 
                        </h4>
                        <h4
                          style={{
                            marginLeft: "20px",
                            marginTop: "15px",
                            color: "#392C7D",
                            marginRight: "30px",
                          }}
                        >
                          Total TestSeries : {testseries}
                        </h4>
                      </div>

                      <div className="sell-course-head comman-space "
                      style={{border:"none"}}
                      >
                        <div className="chart">
                          <Chart
                            options={chartOptions1}
                            series={chartOptions1.series}
                            type={chartOptions1.chart.type}
                            width={chartOptions1.chart.width}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>:<div className="col-md-6 d-flex">
                <Skeleton variant="rect" width={"100%"} height={"100%"} />
              </div>}
               
              </div> */}
              <div style={{ marginTop: "20px" }}>
                <div
                  className="accordion"
                  id="accordionExample"
                  style={{ marginTop: "20px", borderRadius: "8px" }}
                >
                  {courseslistdata
                    ? courseslistdata.map((item, index) => {
                        return (
                          <div
                            className="accordion-item"
                            key={index}
                            style={{ marginTop: "20px" }}
                          >
                            <h2
                              className="accordion-header"
                              id={`heading${index}`}
                            >
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-expanded="false"
                                aria-controls={`collapse${index}`}
                                style={{ backgroundColor: "white" }}
                                onClick={() =>
                                  coursesIdListTest(item?.course_id)
                                }
                              >
                                {item?.course[0]?.name} course
                              </button>
                            </h2>

                            <div
                              id={`collapse${index}`}
                              className="accordion-collapse collapse"
                              aria-labelledby={`heading${index}`}
                              data-bs-parent="#accordionExample"
                            >
                              <div>
                                <div className="accordion-body row">
                                  {loading === true ? (
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{
                                        position: "fixed",
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                        backgroundColor:
                                          "rgba(255, 255, 255, 0.6)",
                                      }}
                                    >
                                      <div
                                        className="spinner-border text-warning"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {testlist?.length > 0 ? (
                                        <div
                                          className="col-lg-12 col-sm-12"
                                          // style={{border:"1px solid red"}}
                                        >
                                          <h5 style={{ marginLeft: "13px" }}>
                                            Test Series
                                          </h5>
                                          <div className="row">
                                            {testlist
                                              ? testlist?.map((item, index) => {
                                                  return (
                                                    <div
                                                      className="col-lg-6"
                                                      style={{
                                                        padding: "20px",
                                                      }}
                                                      key={index}
                                                    >
                                                      <div
                                                        className="card instructor-card w-100"
                                                        style={{
                                                          border: "none",
                                                          boxShadow:
                                                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                                        }}
                                                      >
                                                        <div
                                                          className="card-body row"
                                                          style={{
                                                            padding: "15px",
                                                          }}
                                                        >
                                                          <div className="instructor-inner col-md-7">
                                                            <h6>
                                                              {" "}
                                                              {item?.name}
                                                            </h6>
                                                            <h4
                                                              className="instructor-text-warning"
                                                              style={{
                                                                marginTop:
                                                                  "20px",
                                                              }}
                                                            >
                                                              {item?.attempt}/
                                                              {item?.total}
                                                              {/* {(paidcourse !== null && freecourse !== null) ?((paidcourse+freecourse)<10?`0${paidcourse+freecourse}`:paidcourse+freecourse) : <Skeleton />} */}
                                                            </h4>
                                                          </div>
                                                          <div
                                                            className="instructor-inner col-md-5  d-flex"
                                                            style={{
                                                              justifyContent:
                                                                "space-evenly",
                                                              alignSelf:
                                                                "center",
                                                              marginTop: "10px",
                                                            }}
                                                          >
                                                            <div className="">
                                                              {paidcourse !=
                                                              null ? (
                                                                <img
                                                                  src={
                                                                    coursespic
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "64px",
                                                                    height:
                                                                      "64px",
                                                                  }}
                                                                  alt="index"
                                                                />
                                                              ) : (
                                                                <Skeleton />
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                justifyContent:
                                                                  "space-between",
                                                                alignSelf:
                                                                  "center",
                                                                marginTop:
                                                                  "12px",
                                                              }}
                                                            >
                                                              <h4 className="instructor-text-warning">
                                                                {" "}
                                                                {(
                                                                  ((item?.attempt ||
                                                                    0) /
                                                                    (item?.total ||
                                                                      1)) *
                                                                  100
                                                                ).toFixed(2)}
                                                                %
                                                              </h4>
                                                            </div>
                                                          </div>

                                                          <div
                                                            className="course-stip progress-stip"
                                                            style={{
                                                              padding: "0px",
                                                            }}
                                                          >
                                                            <Tooltip
                                                              title={
                                                                ((item?.attempt ||
                                                                  0) /
                                                                  (item?.total ||
                                                                    1)) *
                                                                100
                                                              }
                                                            >
                                                              <div
                                                                className="progress-bar bg-success progress-bar-striped active-stip"
                                                                style={{
                                                                  width: `${
                                                                    ((item?.attempt ||
                                                                      0) /
                                                                      (item?.total ||
                                                                        1)) *
                                                                    100
                                                                  }%`,
                                                                }}
                                                              ></div>
                                                            </Tooltip>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                })
                                              : ""}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                  {loading === true ? (
                                    ""
                                  ) : (
                                    <>
                                      {videolist?.length > 0 ? (
                                        <div className="col-lg-12 col-sm-12">
                                          <h5 style={{ marginLeft: "13px" }}>
                                            Videos
                                          </h5>
                                          <div className="row">
                                            {videolist
                                              ? videolist?.map(
                                                  (item, index) => {
                                                    return (
                                                      <div
                                                        className="col-lg-6"
                                                        style={{
                                                          padding: "10px",
                                                          display: "flex",
                                                        }}
                                                        key={index}
                                                      >
                                                        <div
                                                          className="card instructor-card w-100"
                                                          style={{
                                                            border: "none",
                                                            boxShadow:
                                                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                                          }}
                                                        >
                                                          <div
                                                            className="card-body row"
                                                            style={{
                                                              padding: "15px",
                                                            }}
                                                          >
                                                            <div className="instructor-inner col-md-7">
                                                              <h6>
                                                                {" "}
                                                                {item?.name}
                                                              </h6>
                                                              <h4
                                                                className="instructor-text-warning"
                                                                style={{
                                                                  marginTop:
                                                                    "20px",
                                                                }}
                                                              >
                                                                {item?.watched}/
                                                                {item?.total}
                                                                {/* {(paidcourse !== null && freecourse !== null) ?((paidcourse+freecourse)<10?`0${paidcourse+freecourse}`:paidcourse+freecourse) : <Skeleton />} */}
                                                              </h4>
                                                            </div>
                                                            <div
                                                              className="instructor-inner col-md-5 d-flex"
                                                              style={{
                                                                justifyContent:
                                                                  "space-evenly",
                                                                alignSelf:
                                                                  "center",
                                                                marginTop:
                                                                  "10px",
                                                              }}
                                                            >
                                                              <div
                                                                className=""
                                                                // style={{marginLeft:"20px"}}
                                                              >
                                                                {paidcourse !=
                                                                null ? (
                                                                  <img
                                                                    src={
                                                                      videopic
                                                                    }
                                                                    style={{
                                                                      width:
                                                                        "64px",
                                                                      height:
                                                                        "64px",
                                                                    }}
                                                                    alt="index"
                                                                  />
                                                                ) : (
                                                                  <Skeleton />
                                                                )}
                                                              </div>

                                                              <div
                                                                style={{
                                                                  justifyContent:
                                                                    "space-between",
                                                                  alignSelf:
                                                                    "center",
                                                                  marginTop:
                                                                    "12px",
                                                                }}
                                                              >
                                                                <h4 className="instructor-text-warning">
                                                                  {" "}
                                                                  {(
                                                                    ((item?.watched ||
                                                                      0) /
                                                                      (item?.total ||
                                                                        1)) *
                                                                    100
                                                                  ).toFixed(2)}
                                                                  %
                                                                </h4>
                                                              </div>
                                                            </div>
                                                            <div
                                                              className="course-stip progress-stip"
                                                              style={{
                                                                padding: "0px",
                                                                width: "95%",
                                                                margin: "auto",
                                                              }}
                                                            >
                                                              <Tooltip
                                                                title={(
                                                                  ((item?.watched ||
                                                                    0) /
                                                                    (item?.total ||
                                                                      1)) *
                                                                  100
                                                                ).toFixed(2)}
                                                              >
                                                                <div
                                                                  className="progress-bar bg-success progress-bar-striped active-stip"
                                                                  style={{
                                                                    width: `${
                                                                      ((item?.watched ||
                                                                        0) /
                                                                        (item?.total ||
                                                                          1)) *
                                                                      100
                                                                    }%`,
                                                                  }}
                                                                ></div>
                                                              </Tooltip>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <FooterStudent />
    </div>
  );
};
