import React, { useContext, useEffect, useState } from "react";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {toast,ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import CryptoJS  from 'crypto-js'
import AuthContext from "../../../context/AuthProvider";
import axios from "../../../api/axios";
import AdminHeader from "../../instructor/adminHeader";
import {   notification, Space  } from "antd";

const EDITPAYMENRTERM = "api/course-payment/edit-course-payment";
const UPDATEPAYMENTTERM = "/api/course-payment/update-course-payment";

export default function EditPaymentTearms() {
  const [data, setData] = useState(null);
  const { auth } = useContext(AuthContext);
  const { payid, cptid,name } = useParams();
  const[submit,setSubmit]=useState(false)

  let bytes1 = CryptoJS.AES.decrypt(decodeURIComponent(payid), 'my-secret-key@admin8623009959');
  let decryptedDataPay = JSON.parse(bytes1.toString(CryptoJS.enc.Utf8));
  let bytes2 = CryptoJS.AES.decrypt(decodeURIComponent(cptid), 'my-secret-key@admin8623009959');
  let decryptedDataCpt = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const navigate =useNavigate()
  const handleAdd = () => {
    const addData = [...data];
    const result = [
      ...addData,
      { id: data.length + 1, inst_amount: null, inst_duration: null },
    ];
    setData(result);
  };
  // console.log(data);
  const handleEdit = (e, i) => {
    const { name, value } = e.target;
    const editData = [...data];
    const result = editData.find((item) => item.id === i);
    result[name] = value;
    setData(editData);
  };
  const handleDelete = (id) => {
    const deleteData = [...data];
    const result = deleteData.filter((item) => item.id !== id);
    setData(result);
  };
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchPaymentInst = async () => {
    try{
      setLoading(true);
      const response = await axios.get(`${EDITPAYMENRTERM}/${decryptedDataCpt}`, config);
      setData(response.data.PaymentTerm);
      setLoading(false);
    }
   catch(err){ 
     if (err.response.status === 401) {
    localStorage.removeItem('token')
    navigate('/admin/login')
  }

   }
    // console.log(response);
  };
  useEffect(() => {
    fetchPaymentInst();
  }, []);
  const arrayObject_to_array = (input, type) => {
    const temArr = [];
    for (let key in input) {
      temArr.push(input[key][type]);
    }
    return temArr;
  };
  const [api, contextHolder] = notification.useNotification();
  
  const[toast,setToast]=useState(false)

    const openNotificationWithIcon1 = (type) => {
      console.log("toast after click")
      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"#52c41a"}}>
           <strong>Installment Updated </strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        
      });
      };
      const openNotificationWithIcon2 = (type) => {
        console.log("toast after click")
        notification[type]
        (
          
          {
          message: 
          (
            <div style={{color:"red"}}>
             <strong>Internal Server Error </strong> 
            </div>
          ),
        
          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted
      
          // </div>)
          duration:1
        });
        };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const inst_amount = arrayObject_to_array(data, "inst_amount");
    const inst_duration = arrayObject_to_array(data, "inst_duration");
    const inst = data?.length;
    setSubmit(true)
    try {
      if (inst_amount && inst_duration) {
        const response = await axios.post(
          `${UPDATEPAYMENTTERM}/${decryptedDataCpt}`,
       {
            amount: inst_amount,
            duration: inst_duration,
            course_id: Number (decryptedDataPay),
            total_inst_count: inst,
          },
          config
        );
        // console.log(response);
        if(response.data.success){
          openNotificationWithIcon1("success")
          setTimeout(() => {
            navigate(-1)
          }, 800); 
            // toast.info('Installment Updated')
        } 
      }
      if(data.length===0){
        toast.error('Add Amount and Duration')
      }
    } catch (error) {
      console.log(error);
      setSubmit(false)
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      openNotificationWithIcon2("error")
    }
  };
  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  return (
    <div className="main-wrapper">
      {/* <StudentHeader activeMenu={"Profile"} /> */}
      {/* Student Dashboard */}
      <AdminHeader />
      <div className="page-content">
        <div className="container"
        style={{maxWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Courses"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>{name}</h3>
                    {/* <p>{name}</p> */}
                    <nav aria-label="breadcrumb" style={{ marginTop: "0.5em" }}>
                      <ol class="breadcrumb" style={{marginBottom:0.5}}>
                        <li
                          class="breadcrumb-item"
                          style={{ cursor: "pointer" }}
                        >
                          <a onClick={() => navigate("/admin/course")}>
                            Courses
                          </a>
                        </li>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          onClick={()=>navigate(-1)}
                          style={{ cursor: "pointer",fontWeight:'normal' }}
                        >
                          Payment Terms
                        </li>
                        <li
                          class="breadcrumb-item active" style={{marginLeft:'-1em'}}
                          aria-current="page"
                          role="button"
                        >
                        Edit Installment
                        </li>
                      </ol>
                    </nav>
                    {/* <div className="row">
                      <div className="col-sm-6 col-lg-5"></div>
                      <div className="col-sm-6 col-lg-7">
                        <div className="text-end">
                          <Link onClick={handleAdd} className="btn-mark">
                            Add More
                          </Link>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    {/* <div className="personal-info-head">
                      <h4>Edit Courses</h4>
                      <p>Edit Courses information.</p>
                    </div> */}
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        {loading === true ? (
                          <div
                            class="d-flex justify-content-center"
                            style={{ position: "absolute" }}
                          >
                            <div
                              class="spinner-border text-warning"
                              style={{
                                left: "50%",
                                marginLeft: "24em",
                                marginTop: "10em",
                              }}
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          data?.map((item, i) => {
                            return (
                              <>
                                <div key={item.id} className="col-lg-5">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Installment {i + 1} Amount (₹)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Your Amount"
                                      name="inst_amount"
                                      value={item.inst_amount}
                                      onChange={(e) => handleEdit(e, item.id)}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-5">
                                  <div className="form-group">
                                    <label className="form-control-label">
                                      Installment {i + 1} Duration (Days)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Duration(in days)"
                                      name="inst_duration"
                                      value={item.inst_duration}
                                      onChange={(e) => handleEdit(e, item.id)}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-1">
                                <div className="form-group">
                                  <button
                                    onClick={() => handleAdd()}
                                    type="button"
                                    style={{ color: "whitesmoke" ,marginTop:'2em'}}
                                    className="btn btn-primary"
                                  >
                                   <i class="fa fa-plus" aria-hidden="true"></i>
                                  </button>
                                </div>
                              </div>
                                <div className="col-lg-1">
                                  <div className="form-group">
                                    <button
                                      onClick={() => handleDelete(item.id)}
                                      type="button"
                                      style={{ color: "whitesmoke" ,marginTop:'2em', }}
                                      className="btn btn-info"
                                    >
                                      <i className="fa-regular fa-trash-can"></i>
                                    </button>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        )}

                        <div className="update-profile"
                          id="submit-button"

                        >
                          <div
                            class="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button className="btn btn-primary"
                            type="submit"
                            disabled={submit}
                            >Update</button>
                          </div>{" "}
                          <div
                            class="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button type="button" onClick={()=>navigate(-1)} className="btn btn-primary cancel-btn ">
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer/>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
