import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import * as Yup from "yup";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../instructor/adminHeader"; 
import {   notification, Space  } from "antd";
import "./index.css"
const UPDATEZOOM = "/api/zoom-app-details/update";
const EDITZOOM = "/api/zoom-app-details/edit";

export default function AdminEditZoom() {
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();
  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const fetchZoomEdit = async () => {
    try{
      setLoading(true);
      const response = await axios.get(
        `${EDITZOOM}/${decryptedData}`,
        config
      );
      setData(response.data);
      setLoading(false);
    }
    catch(error){
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
    }
  
  };
  useEffect(() => {
    fetchZoomEdit();
  }, []);
  const initalValue = {
    clientid: data?.client_id?data?.client_id:"",
    clentsecret: data?.client_secrect?data?.client_secrect:"",
    redirecturl: data?.redirect_url?data?.redirect_url:"",
    email: data?.email?data?.email:"",
  };
  const studentSchema = Yup.object({
    clientid: Yup.string().min(1).required("Please enter client id"),
    clentsecret: Yup.string().min(1).required("Please enter your clent secret"),
    redirecturl: Yup.string().min(1).required("Please enter your redirect url"),
    email: Yup.string().email().required("Please enter your email"),
  });
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  
  const[toast,setToast]=useState(false)
  const openNotificationWithIcon = (type) => {
    // console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"#52c41a"}}>
         <strong>Account Updated</strong> 
        </div>
      ),
      duration:1
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Created
  
      // </div>)
      
    });
  
    
  
       
     
     
    };
    const openNotificationWithIcon2 = (type) => {
      // console.log("toast after click")
      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"red"}}>
           <strong>Internal Server Error </strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        duration:1
      });
      };
      useEffect(() => {
        const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
        if(lastVisitedRoute){
          localStorage.removeItem("lastVisitedRoute")
        }
        else{
          localStorage.setItem("lastVisitedRoute", window.location.pathname);
    
        }
      }, []);
  const { errors, values, touched, handleChange, handleSubmit, getFieldProps } =
    useFormik({
      initialValues: initalValue,
      onSubmit: async (value) => {
        try {
          const response = await axios.post(
            `${UPDATEZOOM}/${decryptedData}`,
            {
                client_id: value.clientid,
                client_secrect: value.clentsecret,
                redirect_url: value.redirecturl,
                email: value.email,
            },
            config
          );
          if (response) {
            openNotificationWithIcon("success")
            setTimeout(() => {
              // navigate("/admin/test-series");
              navigate(-1);
            }, 1000);
            // toast.info("Account Updated");
          }
        } catch (error) {
          console.log(error);
          openNotificationWithIcon2("error")
        }
      },
      enableReinitialize: true,
      validationSchema: studentSchema,
    });
    // const windowTop = (arg1, arg2) => {
    //   window.scrollTo(arg1, arg2);
    // };
    
    //   useEffect(() => {
    //     windowTop(0, 0);
    //   }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader/>
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"LiveClasses"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Manage Account</h3>
                    {/* <p>Edit Test Series information.</p> */}
                    <nav aria-label="breadcrumb" style={{marginTop:'0.5em'}} >
                      <ol className="breadcrumb" style={{marginBottom:0.5}}>
                      <li
                          className="breadcrumb-item "
                          aria-current="page"
                          role="button"
                          onClick={() => navigate("/admin/live-classes")}
                        >
                          Live Classes
                        </li>
                        <li className="breadcrumb-item" style={{fontWeight:'normal'}} role="button" onClick={()=>navigate('/admin/manage-details')} aria-current="page">
                        Manage Accounts
                        </li>
                        <li className="breadcrumb-item active" style={{marginLeft:'-1em'}} role="button" aria-current="page">
                        Edit Manage Account
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      {loading === true ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                              <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Client Id
                            </label>
                            <input
                              type="text"
                              name="clientid"
                              id="clientid"
                              value={values.clientid}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Client Id"
                            />
                            {errors.clientid && touched.clientid ? (
                              <span style={{ color: "red" }}>
                                {errors.clientid}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Client Secret
                            </label>
                            <input
                              type="text"
                              name="clentsecret"
                              id="clentsecret"
                              value={values.clentsecret}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Client Secret"
                            />
                            {errors.clentsecret && touched.clentsecret ? (
                              <span style={{ color: "red" }}>
                                {errors.clentsecret}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Redirect Url
                            </label>
                            <input
                              type="text"
                              name="redirecturl"
                              id="redirecturl"
                              value={values.redirecturl}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Redirect Url"
                            />
                            {errors.redirecturl && touched.redirecturl ? (
                              <span style={{ color: "red" }}>
                                {errors.redirecturl}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Email</label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              step="0.01"
                              value={values.email}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Email"
                            />
                            {errors.email && touched.email ? (
                              <span style={{ color: "red" }}>
                                {errors.email}
                              </span>
                            ) : null}
                          </div>
                        </div>
                          <div className="update-profile"
                             id="submit-button"

                          >
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"

                            >
                              <button type="submit" className="btn btn-primary">
                                Update
                              </button>
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"

                            >
                              <button
                                type="button"
                                onClick={() => {
                                  navigate("/admin/manage-details");
                                  // windowTop(0,0)
                                }}
                                className="btn btn-primary cancel-btn "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
