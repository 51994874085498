import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../footer";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import Store from "../../../Redux/Store"
 import {addItem} from "../../../Redux/Actions/itemActions"

import AuthContext from "../../../context/AuthProvider";
import { Button } from "react-bootstrap";
import { Tooltip } from "antd";
import Switch from "react-switch";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import AdminHeader from "../../instructor/adminHeader";
import CryptoJS from "crypto-js";
import PaginationSub from "../../pages/course/addCourse/PaginationSub";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import Folder  from "../../../assets/img/Folder/folder (2).png"
import Document  from "../../../assets/img/Folder/document.png"

const SHOWSUDMODULE = "/api/test-series/submodules";
const SHOWSUDMODULE1 = "/api/test-series/show-testseries-submodule";

const DELETEMODULE = "/api/test-series/delete-testseries-submodule";
const MODULESTATUS = "/api/test-series/status-testseries-submodule";
const SEARCHSUBMODULEAPI = "/api/test-series/submodules/search";
import { Modal } from "antd";
import { notification, Space } from "antd";
import PaginationNModule from "./PaginationNModule";
//  import { useSelector, useDispatch } from 'react-redux';


const { confirm } = Modal;

export const AdminAddTestSeriesSubModuleFolder = () => {
  const location = useLocation();
  // const dispatch = useDispatch();
  // const items = useSelector(state => state.items);
    // console.log(items,"items")
    const pagevalue=localStorage.getItem("pagination")

    const [list, setList] = useState(null);
    const [text, settext] = useState("");
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [last_page, setLast_page] = useState("");
    const [loading, setLoading] = useState(false);
    const [subModule, setSubModule] = useState(false);
    const [ids, setIds] = useState("");
    const [message, setmessage] = useState("No Data");
    const[modulename,setModuleName]=useState("")
    const[bredcumdata,setBredcumData]=useState([])
    const[bredcumdata1,setBredcumData1]=useState([])
    const [previouspage,setPreviousPage]=useState([])


    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const  {subid,user,mainid,pagenumber}=useParams()
     console.log(pagenumber,"subid",)
     const urlParams = new URLSearchParams(window.location.search);
const pageValue1 = urlParams.get('page');
    const handleEdit = (modid, submodid) => {
        // let ciphertext = CryptoJS.AES.encrypt(
        //   JSON.stringify(modid),
        //   "my-secret-key@admin8623009959"
        // ).toString();
        // let ciphertexts = encodeURIComponent(ciphertext);
         navigate(`/admin/edit/test-series/${modid}/${user}/${mainid}/${2}`);
      };
    const handleChange = async (id, status) => {
        let bool = eval(status);
        let result = Number(!bool) + "";
        const response = await axios.post(
          MODULESTATUS,
          { id: id, status: result },
          config
        );
        if (response) {
          openNotificationWithIcon1("success",response?.data?.message);
        }
        if (!response) {
          openNotificationWithIcon2("error");
        }
        // toast.success("Status Change");
        const editCheck = [...list];
        const res = editCheck.find((it) => it.id === id);
        res.status = Number(!bool) + "";
        setList(editCheck);
        // console.log(list);
      };
      const handleRoute = async (slug, folderid, id, item) => {
        if (folderid == 1) {
            // Check if the item's ID already exists in local storage
            // const existingItems = JSON.parse(localStorage.getItem('items')) || [];
            // const isItemExists = existingItems.some(existingItem => existingItem.id === item.id);
    
            // if (!isItemExists) {
            //     // Add the new item to the array if it doesn't already exist
            //     existingItems.push(item);
            //     localStorage.setItem('items', JSON.stringify(existingItems));
            // }
    
            // Continue with navigation logic
            ShowSudModule(id);
            navigate(`/admin/test-series-module/${id}/${user}/${mainid}`);
        } else {
          
            navigate(`/admin/testseries/${user}/${slug}`);
        }
    };
    // const handleRoute = async (slug, folderid, id, item) => {
    //   if (folderid == 1) {
    //     const isItemExists = items?.some(existingItem => existingItem.id === item.id);
    //             if (!isItemExists) {
    //       Store.dispatch(addItem(item));
    //     }
    //     ShowSudModule(id);
    //     navigate(`/admin/test-series-module/${id}/${user}/${mainid}`);
    //   } else {
    //     navigate(`/admin/testseries/${user}/${slug}`);
    //   }
    // };
    const ShowSudModule = async (id) => {
        // console.log(id)
        try {
          setLoading(true);
          // const response = await axios.get(`${SHOWSUDMODULE}/${subid?subid:id}`, config); previous data
          const response = await axios.get(`${SHOWSUDMODULE}/${id?id:subid}?page=${pageValue1}`, config);
          //  console.log(response,"response")
          setList(response?.data?.message?.data);
          setData(response?.data?.message?.data);
          setLast_page(response?.data?.message?.last_page);
          //  setModuleName(response?.data?.message?.data[0]?.test_sub_module);
             setCurrentPage(response?.data?.message?.current_page);
        //   const subModuleData = response.data.subModule.data;
        //   const singleValue = subModuleData.find((item) => item.id === item.id);
        //   setSubModule(singleValue?.module_id);
    
          setLoading(false);


        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");

          }
          else if (error?.response?.status === 403) {
            localStorage.removeItem("token");
            openNotificationWithIconLogin("error", error?.response?.data?.message);
            setTimeout(() => {
              navigate("/admin/login");
            }, 1000); // Delay the navigation to show the notification
          }
          else{
          openNotificationWithIcon2("error");
          }
        }
      };
    //   console.log(modulename,"name")
      function showConfirm(id) {
        confirm({
          title: "Do you want to delete this test?",
          content:
            "When clicked the OK button, this dialog will be closed after 1 second",
          okButtonProps: { style: { backgroundColor: "#ff0100" } },
          async onOk() {
            try {
              setLoading(true);
              const resonse = await axios.get(`${DELETEMODULE}/${id}`, config);
              //  console.log("resonse", resonse);
              // toast.warning("Test Deleted");
              openNotificationWithIcon("success","TestSerires Submodule Deleted");
              setLoading(false);
              ShowSudModule();
            //   setShow(false);
            } catch (error) {
              console.log(error);
              if (error?.response?.status === 401) {
                openNotificationWithIconSesion("error")
                localStorage.removeItem("token");
                navigate("/admin/login");
              }
              else if (error?.response?.status === 403) {
                localStorage.removeItem("token");
                openNotificationWithIconLogin("error", error?.response?.data?.message);
                setTimeout(() => {
                  navigate("/admin/login");
                }, 1000); // Delay the navigation to show the notification
              }
              else{

              openNotificationWithIcon2("error");
              }
            }
          },
          onCancel() {},
        });
      }
      useEffect(() => {
        ShowSudModule();
      }, [subid,pageValue1]);
      const openNotificationWithIcon = (type,message) => {

        notification[type]({
          message: (
            <div style={{ color: "#52c41a" }}>
              <strong>{message}</strong>
            </div>
          ),
          duration: 1,
    
          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted
    
          // </div>)
        });
      };
      const openNotificationWithIconLogin = (type,message) => {

        notification[type]({
          message: (
            <div style={{ color: "red" }}>
              <strong>{message}</strong>
            </div>
          ),
          duration: 1,
    
          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted
    
          // </div>)
        });
      };
      const openNotificationWithIcon1 = (type,) => {
      
        notification[type]({
          message: (
            <div style={{ color: "#52c41a" }}>
              <strong>Status updated </strong>
            </div>
          ),
    
          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted
    
          // </div>)
          duration: 1,
        });
      };
      const openNotificationWithIcon2 = (type) => {
    
        notification[type]({
          message: (
            <div style={{ color: "red" }}>
              <strong>Internal Server Error </strong>
            </div>
          ),
    
          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted
    
          // </div>)
          duration: 1,
        });
      };
      const openNotificationWithIconSesion = (type) => {
        notification[type]({
          message: (
            <div style={{ color: "red" }}>
              <strong>Session Expired Please Login </strong>
            </div>
          ),
          duration: 1,
        });
      };
      const Filter = async (e) => {
        try {
          const searchValue = e.target.value;
          settext(searchValue);
          const res = await axios.post(
            `${SEARCHSUBMODULEAPI}/${subid}`,
            { name: searchValue,  },
            config
          );
          // console.log(res,"res")
          if (res?.data?.message?.data.length > 0) {
            setList(res?.data?.message?.data);
            setCurrentPage(res?.data?.message?.current_page);
            setLast_page(res?.data?.message?.last_page);
            setmessage("No Data");
          }
          if (res?.data?.message?.data.length == 0) {
            setList(res?.data?.message?.data);
            setmessage("No Data Found");
          }
          if (searchValue.length === 0) {
            const res = await axios.get(
              `${SHOWSUDMODULE}/${subid}?page=${currentPage}`,
              config
            );
            setList(res?.data?.message?.data);
            setCurrentPage(1);
            setLast_page(res?.data?.message?.last_page);
          }
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else if (error?.response?.status === 403) {
            localStorage.removeItem("token");
            openNotificationWithIconLogin("error", error?.response?.data?.message);
            setTimeout(() => {
              navigate("/admin/login");
            }, 1000); // Delay the navigation to show the notification
          }
        }
      };
      const handleKeyPress = (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          Filter();
        }
      };
      const itemsPerPage = 10;

      useEffect(() => {
        const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
        if (lastVisitedRoute) {
    
          localStorage.removeItem("lastVisitedRoute");
        } else {
      
          localStorage.setItem("lastVisitedRoute", window.location.pathname);
        }
      }, []);
      const slug=localStorage.getItem("slug")

      const ShowSudModuleBredcum1 = async (id) => {
        // console.log(id)
        try {
          setLoading(true);
          
          const response = await axios.get(`${SHOWSUDMODULE1}/${slug}`, config);
            //  console.log(response,"response 500")
            setBredcumData(response?.data?.module?.name)
            // localStorage.setItem("getSlug",response?.data?.getSlug)
          // setList(response?.data?.message?.data);
          // setData(response?.data?.message?.data);
          // setLast_page(response?.data?.message?.last_page);
          //  setModuleName(response?.data?.message?.data[0]?.test_sub_module);
          //  setCurrentPage(page);
        //   const subModuleData = response.data.subModule.data;
        //   const singleValue = subModuleData.find((item) => item.id === item.id);
        //   setSubModule(singleValue?.module_id);
    
          setLoading(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");

          }
          else{
          openNotificationWithIcon2("error");
          }
        }
      };
      // const ShowSudModuleBredcum = async (id) => {
      //   // console.log(id)
      //   try {
      //     setLoading(true);
      //     const response = await axios.get(`${SHOWSUDMODULE}/${subid}`, config);
      //       console.log(response,"response")
      //     // setList(response?.data?.message?.data);
      //     // setData(response?.data?.message?.data);
      //     // setLast_page(response?.data?.message?.last_page);
      //     //  setModuleName(response?.data?.message?.data[0]?.test_sub_module);
      //     //  setCurrentPage(page);
      //   //   const subModuleData = response.data.subModule.data;
      //   //   const singleValue = subModuleData.find((item) => item.id === item.id);
      //   //   setSubModule(singleValue?.module_id);
    
      //     setLoading(false);
      //   } catch (error) {
      //     console.log(error);
      //     if (error?.response?.status === 401) {
      //       openNotificationWithIconSesion("error")
      //       localStorage.removeItem("token");
      //       navigate("/admin/login");

      //     }
      //     else{
      //     openNotificationWithIcon2("error");
      //     }
      //   }
      // };
      useEffect(()=>{
          ShowSudModuleBredcum1()
        //  ShowSudModuleBredcum()

      },[])

      const bredcrum=JSON.parse(localStorage.getItem('items'));
      // console.log( bredcrum ,"items ")


      useEffect(() => {
        const handleLocationChange = () => {
          // console.log("krishna")
            const currentPathId = getCurrentPathId(location.pathname);
            const breadcrumb = JSON.parse(localStorage.getItem('items')) || [];
    
            // Check if the current path ID exists in the breadcrumb trail
            const updatedBreadcrumb = breadcrumb.filter(item => item.id !== currentPathId);
    
            // Update the breadcrumb trail in the local storage
            localStorage.setItem('items', JSON.stringify(updatedBreadcrumb));
        };
    
        window.addEventListener("popstate", handleLocationChange ());
    
        return () => {
            window.removeEventListener("popstate", handleLocationChange ());
        };
    }, [location.pathname]);
    
    

  const getCurrentPathId = (pathname) => {
    // Extract the ID from the current pathname (e.g., "/admin/test-series-module/68/gs-rzn/8")
    const parts = pathname.split("/");
    return parseInt(parts[3]); // Assuming the ID is at the 3rd index
  };

      const handleBreadcrumbClick = (id) => {
        const breadcrumb = JSON.parse(localStorage.getItem('items')) || [];
      
        // Find the index of the clicked ID in the breadcrumb array
        const index = breadcrumb.findIndex(item => item.id === id);
      
        // Check if the index is valid
        if (index !== -1) {
          // Remove all items from the breadcrumb array starting from the found index
          const updatedBreadcrumb = breadcrumb.slice(0, index);
          localStorage.setItem('items', JSON.stringify(updatedBreadcrumb));
        }
      
        // Continue with navigation logic
        // ...
      };
      
      const handelTestPage=(currentPage)=>{
        setPreviousPage(currentPage)
        console.log(currentPage,'page')
      }
      console.log(previouspage,"previouspage")
      return(
        <div className="main-wrapper">
        <AdminHeader activeMenu={"Test"} />
        <div className="page-content instructor-page-content">
          <div
            className="container"
            style={{ minWidth: "100%", marginTop: "-15px" }}
          >
            <div className="row">
              {/* Sidebar */}
              <AdminInstructorSidebar activeMenu={"Test"} />
              {/* Sidebar */}
  
              {/* Instructor Dashboard */}
              <div className="col-xl-9 col-lg-9 col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget" style={{ border: "none" }}>
                      <div
                        className="settings-inner-blk p-0"
                        style={{ border: "1px solid #e9ecef" }}
                      >
                        <div className="sell-course-head comman-space">
                          <div className="filter-grp ticket-grp d-flex align-items-center justify-content-between flex-wrap">
                            <div>
                              <h3 style={{ textTransform: "capitalize" }}>
                                 {list?list[0]?.test_sub_module?.name:""}
                              </h3>
                              <nav
                                aria-label="breadcrumb"
                                style={{
                                  marginTop: "0.5em",
                                  marginBottom: "-1.6em",
                                  // border:"1px solid red",
                                  width:"700px",
                                  display:"flex",
                                  flexWrap:"wrap"
                                }}
                              >
                                <ol className="breadcrumb">
                                  {/* <li
                                    className="breadcrumb-item"
                                    aria-current="page"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => navigate("/admin/test-series")}
                                  >
                                    Test Series
                                  </li> */}
                                  <li
                                    className="breadcrumb-item"
                                    aria-current="page"
                                    style={{ cursor: "pointer" ,paddingRight:"0px"}}
                                    onClick={() => navigate(`/admin/test-series/${slug}/${mainid}`)}
                                  >
                                    {bredcumdata}
                                  </li>
                                  {/* {bredcrum?.length>0?bredcrum?.map((item)=>{
                                    // console.log(item)
                                    return(
                                      <li
                                      className="breadcrumb-item "
                                      aria-current="page"
                                      style={{
                                        cursor: "pointer",
                                        // color: "rgb(108, 117, 125)",
                                        // marginLeft: "-.6em",
                                        paddingRight:"0px"
                                      }}
                                      onClick={() => {
                                         handleBreadcrumbClick(item?.id),
                                        navigate(`/admin/test-series-module/${item?.id}/${user}/${mainid}`)}}

                                    >
                                      {item?.name}
                                    </li>
                                    )
                                  }):<></>} */}
                                 
                                  
                                  <li
                                    className="breadcrumb-item active"
                                    aria-current="page"
                                    style={{
                                      cursor: "pointer",
                                      color: "rgb(108, 117, 125)",
                                      // marginLeft: "-.6em",
                                    }}
                                  >
                                    {list?list[0]?.test_sub_module?.name:""}
                                  </li>
                                </ol>
                              </nav>
                            </div>
                            <div className="ticket-btn-grp" style={{marginRight:'.35em'}}>
                              <Link to={`/admin/test-series/show-group-module-testseries-question/${subid}/${user }/${mainid}`}>
                              Show Group Testseries
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="comman-space pb-0">
                          <div
                            // className="settings-tickets-blk course-instruct-blk table-responsive"
                            // id="table-value"
                          >
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div
                                    className="row  gx-2 align-items-center"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                     <div className="col-md-6 col-item">
                                    <div
                                      className=" search-group justify-content-start"
                                      id="search-btn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        style={{
                                          position: "absolute",
                                          left: "7px",
                                          color: "rgb(246, 106, 98)",
                                        }}
                                      >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line
                                          x1="21"
                                          y1="21"
                                          x2="16.65"
                                          y2="16.65"
                                        ></line>
                                      </svg>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Name"
                                        // allowClear
                                        onChange={Filter}
                                        onKeyDown={handleKeyPress}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6 col-lg-6 col-item"
                                  // style={{justifyContent:"end",
                                  // display:"flex"}}
                                  >
                              
                                    <div
                                        className="button-container-admin"
                                      style={{ justifyContent: "flex-end" }}
                                      >
                                        <button
                                        onClick={() =>
                                          // moduledata(id,user)
                                          navigate(
                                            `/admin/test-series/sub-module/${user}/${
                                                subid
                                            }`
                                          )
                                        
                                        
                                        }
                                        className="btn btn-info me-md-1 me-sm-0 mb-1 text-white"
                                        id="custom-button"
                                      >
                                         <i className="fa-solid fa-plus"></i>{" "}
                                     Add Tests
                                      </button>
                                        <button
                                        onClick={() =>
                                          navigate(
                                            `/admin/add/test-series/${
                                                subid
                                            }/${user}/${2}/${mainid}`
                                          )
                                        }
                                        className="btn btn-primary me-md-1 me-sm-0 mb-1"
                                        id="custom-button"
                                      >
                                        <i className="fa-solid fa-plus"></i>{" "}
                                        Create New
                                      </button>
                                      </div>
                                  
                                  </div>
                                    
  
                                 
                                  </div>
                                </form>
                              </div>
                            </div>
                            {/* Referred Users */}
                            <div className="settings-tickets-blk course-instruct-blk table-responsive">
                           
                            <table className="table table-nowrap mb-0">
                              <thead>
                                <tr className="text-center">
                                  <th>SR. NO.</th>
                                  <th>NAME</th>
                                  <th>STATUS</th>
                                  <th>ACTION</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                               
                                  <tbody 
                                  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}>
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">Loading...</span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                
                              ) : list?.length > 0 ? (
                                list?.map((item, i) => {
                                  const serialNumber =
                                    (currentPage - 1) * itemsPerPage + i + 1;
                                  return (
                                    <tbody key={item.id}>
                                      <tr
                                        className="text-center"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        <td>{serialNumber}</td>
                                        <td className="">
                                            <div className="d-flex"
                                            style={{justifyContent:'center'}}
                                            >
                                                <div className="mr-10">
                                            {item?.fk_test_structure_id==1?
                                        <img src={Folder} style={{width:"24px",height:"24px"}}/>   
                                        :<img src={Document} style={{width:"24px",height:"24px"}}/> 
                                        
                                        }
                                        </div>
                                        <div style={{alignSelf:"center",marginLeft:"10px",marginTop:"2px"}}>
                                          <a
                                            role="button"
                                            onClick={() => {
                                              handleRoute(
                                                item.slug,
                                                item?.fk_test_structure_id,
                                                item?.id,
                                                item?.test_sub_module
  
                                               
                                                );
                                            }}
                                            className="refer-avatar-blk  align-items-center"
                                          >
                                            <p>{item.name}</p>
                                          </a>
                                          </div>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <Switch
                                            onChange={() => {
                                              handleChange(item.id, item.status);
                                            }}
                                            checked={
                                              item.status === "1" ? true : false
                                            }
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={38}
                                            className="react-switch"
                                          />
                                        </td>
                                        <td>
                                          <Tooltip title={item?.fk_test_structure_id==1?"Open Folder":"Open Questions"}>
                                            <Button
                                              onClick={() =>
                                              {  handleRoute(item.slug,item?.
                                                  fk_test_structure_id,
                                                  item?.id,
                                                  item?.test_sub_module
  
                                                  );
                                                  handelTestPage(currentPage)
                                                
                                                  }
                                              }
                                              className="btn btn-secondary"
                                            >
                                              {" "}
                                              <i
                                                className="fa fa-arrow-circle-o-right"
                                                style={{ fontSize: "19px" }}
                                              ></i>
                                            </Button>{" "}
                                          </Tooltip>
                                          <Tooltip title="Edit">
                                            <button
                                              onClick={() =>
                                                handleEdit(
                                                  item.id,
                                                  item.module_id
                                                )
                                              }
                                              type="button"
                                              className="btn btn-info text-white"
                                            >
                                              <i className="fa-regular fa-pen-to-square"></i>
                                            </button>{" "}
                                          </Tooltip>
                                          <Tooltip title="Delete">
                                            <Button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() => showConfirm(item.id)}
                                            >
                                              <i className="fa-regular fa-trash-can"></i>
                                            </Button>
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              ) : (
                                <>
                                </>
                              )}
                            </table>
                            </div>
                            {list?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Referred Users */}
                          </div>
                          <div className="fixtable">
                            {list?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationNModule
                                nPages={last_page}
                                text={text}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={SHOWSUDMODULE}
                                module={subModule}
                                setData={setList}
                                linkSearch={SEARCHSUBMODULEAPI}
                                slug={subid}
                                subid={subid}
                                user={user}
                                mainid={mainid}
                                pagenumber={pagenumber}
                                pageValue1={pageValue1}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Instructor Dashboard */}
              <ToastContainer />
              <Footer />
            </div>
          </div>
        </div>
      </div>
      )
};
