import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../footer";
import "./index.css";
import {
  PencilIcon,
  logo,
  Video,
  Video1,
  VideoBg,
  Video2,
  Play,
  PlayVideo,
} from "../../imagepath";
import moment from "moment";
import { notification, Space } from "antd";
import { Search } from "react-feather";
import Select from "react-select";
import {
  Course10,
  Course11,
  Course12,
  Course13,
  Course14,
  Icon1,
  InstructorProfileBg,
  TimerStart,
  User15,
  VideoNew,
  
} from "../../imagepath";

import StudentHeader from "../studentHeader";
import axios from "../../../api/axios";
import FooterStudent from "../../fotterstudent";
import axiosInstance from "../../../api/axios1";
import StudentSideBar from "../sidebar";
import { Tooltip } from "@mui/material";
const VIDEO = "/api/student/coursevideolist";
export default function  StudentsVideo() {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState(null);
  const navigate = useNavigate();
  const tokenStudent = localStorage.getItem("tokenStudent");
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const getVideo = async () => {
    try {
      setLoading(true);
      const responce = await axiosInstance.get(VIDEO, config);
      // console.log(responce,"responce")

      // const course=responce?.data?.flatMap(item=>{
      //   if(item?.course_relation_liveclass.length===0){
      //     return null
      //   }
      //   else{
      //     const data=[]
      //     const name=item?.name;
      //     const image=item?.image;
      //     const slug=item?.slug;
      //     const id=item?.id;
      //     const videobatch=item?.course_relation_liveclass

      //   }
      // })
      const course = responce?.data;
      // console.log(course,'course')
      // const transformedData = course.map((course) => {

      // });
      const transformedData = course
        .filter((course) => course?.course_relation_liveclass?.length > 0)
        .map((course) => {
          const { name, image, slug, id, course_relation_liveclass } = course;

          const filteredLiveClass = course_relation_liveclass.filter(
            (relation) =>
              relation.courserelationsubmodule &&
              relation.courserelationsubmodule.length > 0
          );

          const transformedCourse = {
            name,
            image,
            slug,
            id,
            time: 0, // Initialize time property in minutes
            totalVideos: 0, // Initialize totalVideos property
          };

          if (filteredLiveClass.length > 0) {
            filteredLiveClass.forEach((relation) => {
              relation?.courserelationsubmodule?.forEach((subModule) => {
                const videos = subModule?.videorelation
                  ?.map((videoRelation) => videoRelation?.videos)
                  .flat();

                if (videos && Array.isArray(videos)) {
                  transformedCourse.totalVideos += videos.length;

                  videos.forEach((video) => {
                    const length = parseVideoLength(video?.video_length);
                    transformedCourse.time += length;
                  });
                }
              });
            });

            // Convert time from minutes to hours and format
            const hours = Math.floor(transformedCourse.time / 3660);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            transformedCourse.timeFormatted = `${hours}hr${" "}${minutes}mins`;

            transformedCourse.course_relation_liveclass = filteredLiveClass.map(
              (relation) => relation.courserelationsubmodule
            );
          }

          return transformedCourse;
        });

      // console.log(transformedData);

      function parseVideoLength(value) {
        if (value === undefined || value === null) {
          return 0;
        }

        const numericValue = parseFloat(value.replace(/[^\d.]/g, ""));
        return isNaN(numericValue) ? 0 : numericValue;
      }

      // console.log(transformedData);

      // console.log(transformedData);

      // console.log(course,"course")

      // const transformedResponse = responce?.data?.flatMap(item => {
      //   console.log(item,"item")

      //   // const course_relation_liveclass = item?.sub || [];

      //   if (item?.course_relation_liveclass.length === 0) {
      //     return null; // Skip the item if course_relation_liveclass is empty
      //   }

      //   const courserelationsubmoduleObjects = item?.course_relation_liveclass.flatMap(classItem => {
      //     const courserelationsub_module_id = classItem?.sub_module_id || null; // Get sub_module_id from classItem
      // const videorelation=classItem?.videorelation

      //     return classItem.courserelationsubmodule.map(submodule => {
      //       return {
      //         id: item?.id,
      //         slug: item?.slug,
      //         name: item?.name,
      //         module_id: submodule.module_id,
      //         module1: submodule.module1,
      //         courserelationsub_module_id,
      //         videorelation,
      //          // Assign the sub_module_id to courserelationsub_module_id
      //       };
      //     });
      //   });

      //   if (courserelationsubmoduleObjects.length === 0) {
      //     return null; // Skip the item if courserelationsubmoduleObjects is empty
      //   }

      //   return {
      //     name: item.name,
      //     slug: item.slug,
      //     courseid: item?.course_subscription[0]?.course_id,
      //     course_relation_liveclass: courserelationsubmoduleObjects,
      //     courserelationsub_module_id: courserelationsubmoduleObjects[0]?.courserelationsub_module_id || null,
      //     courserelationmodule_id: courserelationsubmoduleObjects[0]?.module_id || null,
      //   };
      // }).filter(Boolean); // Filter out null items

      // console.log(transformedResponse);

      // const courseSub=course?.filter(e=>e?.courserelationsubmodule?.length>0)

      // const videomodule = course.filter(e => e?.courserelationsubmodule?.length > 0)
      // const unique = [];
      // courseSub?.map(x =>
      //   unique.filter(a => a?.module_id === x?.module_id && a?.course_id === x?.course_id).length > 0 ? null : unique.push(x)
      // );
      // videomodule?.map(x => unique.filter(a => a?.module_id == x?.module_id && a?.course_id == x?.course_id).length > 0 ? null : unique?.push(x));

      // console.log(videomodule,"videomodule")
      // const arrayHashmap = unique.reduce((obj, item) => {
      //   obj[item?.course_id] ? obj[item?.course_id]?.videomodule?.push(...item.videomodule) : (obj[item.course_id] = { ...item });
      //   return obj;
      // }, {});
      // console.log(arrayHashmap,"data")

      // const mergedArray = Object.values(arrayHashmap);

      setLoading(false);
      setList(course);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIcon3("error", error?.response?.data?.message,);
        setTimeout(() => {
          navigate("/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
      openNotificationWithIcon3("error",error?.response?.data?.message);
      }
      setLoading(false);
    }
  };
  // console.log(list,"list value")
  useEffect(() => {
    getVideo();
  }, []);
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const handleNotification = (courseSlug, slug) => {
    // let date = new Date();
    // let current = moment(date).format("YYYY-MM-DD HH:mm:ss").valueOf()
    // let expiry_dates = moment(expiry_date).format("YYYY-MM-DD HH:mm:ss").valueOf()
    // // console.log(`${current}     ${expiry_dates}`);
    // if (current > expiry_dates) {
    //   toast.info("Course is Expired ");
    // } else {

    // }
    navigate(`/student/videos/${courseSlug}/${slug}`);
  };
  useEffect(() => {
    const lastVisiteStudentRoute = localStorage.getItem(
      "lastVisiteStudentRoute"
    );
    if (lastVisiteStudentRoute) {
      localStorage.removeItem("lastVisiteStudentRoute");
    } else {
      localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);
    }
  }, []);
  useEffect(() => {
    const lastVisiteStudentTest = localStorage.getItem("lastVisiteStudentTest");
    if (lastVisiteStudentTest !== window.location.pathname) {
      localStorage.removeItem("startTime");
      localStorage.removeItem("expirationTime");
    }
  }, []);
  // console.log(list, "list");
  const options = [
    { label: "Choose", value: "choose" },
    { label: "Angular", value: "Angular" },
    { label: "React", value: "React" },
    { label: "Node", value: "Node" },
  ];
  const [value, setValue] = useState(null);
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  const handelVideoWatch = (id) => {
    navigate(`/student/videos/${id}`);
  };
  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"videos"} />
      {/* BreadCrumb */}
      <div className="breadcrumb-bar">
        <div className="container" style={{ minWidth: "98%", margin: "auto" }}>
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="/student/videos">Videos</Link>
                    </li>
                    {/* <li className="breadcrumb-item">Students Videos</li> */}
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* BreadCrumb */}
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container" style={{ minWidth: "98%", margin: "auto" }}>
          <div className="row">
            {/* <h3 className="mb-3 mb-md-4 mb-lg-5">Videos</h3> */}
            {/* animate__animated animate__fadeIn live-classes-heading */}
            {loading === true ? (
              <div style={{ display: "block" }} id="cover-spin"></div>
            ) : (
              <div className="row">

                <StudentSideBar activeMenu={"videos"} />
                <div className="col-xl-9 col-lg-9 col-md-12">
                  <div className="col-lg-12">
                    <h3 className="mb-3 mb-md-4 mb-lg-5 animate__animated animate__fadeIn live-classes-heading">
                      Videos
                    </h3>
                  </div>
                  <div className="settings-tickets-blk course-instruct-blk ">
                    {list?.length > 0 ? (
                      list?.map((item,i) => {
                        const hours = Math.floor(item?.count?.durantion / 3660);
                        const minutes = Math.floor(
                          (item?.count?.durantion % 3600) / 60
                        );
                        // console.log(item?.id)
                        const time = `${hours}hr${" "}${minutes}mins`;
                        return (
                          <div
                            className="row d-flex"
                            style={{
                              marginTop: "20px",
                              borderBottom:
                                "2px solid rgba(199, 199, 199, 0.25)",
                              paddingBottom: "10px",
                            }}
                            key={i}
                          >
                            <div className="col-lg-3 col-sm-12"
                            style={{alignSelf:"center"}}
                            >
                              <div
                                className="videoimg-div"
                                style={{
                                  justifyContent: "center",
                                  padding: "5px",
                                  width: "100%",
                                  height: "100%",
                                  margin:"auto",
                                  // border:"1px solid red"
                                }}
                              >
                                  <img
                                    src={item?.image}
                                    className="videoimg"
                                    alt=""
                                    
                                  />
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12"
                            style={{alignSelf:"center",justifyContent:"center",}}
                            >
                              <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12"
                                style={{padding:"5px",margin:"auto"}}
                                >
                                  <div
                                    className="sell-tabel-info"
                                    style={{ marginLeft: "0",maxWidth:"100%", padding:"5px"}}
                                  >
                                    <p style={{ textTransform: "capitalize" }}>
                                      <Link to="">
                                        {item?.courseName} course
                                      </Link>
                                    </p>
                                    {/* <div className="row"></div> */}
                                    <div className=" row d-flex align-items-center border-bottom-0 pb-0 "
                                    style={{justifyContent:'space-between'}}
                                    >
                                      <div className="d-flex align-items-center justify-content-between">

                                        
                                      <p>Total Videos</p>
                                      <div className="rating-img d-flex align-items-center">
                                        
                                        <img src={Icon1} alt="" />
                                        <div  
                                        style={{marginBottom:"-14px",marginLeft:"5px"}}
>
                                        <p className=""
                                        >
                                          {item?.count?.totalVideos} {""}  Videos
                                        </p>
                                        </div>
                                      </div>
                                      </div>
                                      
                                    </div>
                                    <div className=" row d-flex align-items-center border-bottom-0 pb-0 "
                                    style={{justifyContent:'space-between'}}
                                    >
                                      <div className=" d-flex align-items-center justify-content-between"
                                      // style={{marginLeft:"25px"}}
                                      >
                                        
                                      <p>Total Duration</p>
                                      <div className="rating-img d-flex align-items-center">
                                        <img src={TimerStart} alt="" />
                                        <div  
                                        style={{marginBottom:"-14px", marginLeft:"5px"}}>
                                           <p>{time}</p>

                                        </div>
                                      </div>
                                      </div>
                                      
                                    </div>
                                    <div className=" row d-flex align-items-center border-bottom-0 pb-0 "
                                    style={{justifyContent:'space-between'}}
                                    >
                                          <div className=" d-flex align-items-center justify-content-between"
                                      // style={{marginLeft:"25px"}}
                                      >
                                        
                                      <p>Total Videos Watched</p>
                                      <div className="rating-img d-flex align-items-center">
                                        <img src={VideoNew} alt="" style={{width:"24px",height:"24px"}} />
                                        <div  
                                        style={{marginBottom:"-16px" ,marginLeft:"5px"}}>
                                           <p>{item?.count?.watchCount} Videos</p>

                                        </div>
                                      </div>
                                      </div>
                                      
                                    </div>
                                    {/* <div className=" align-items-center justify-content-between"
                                      style={{marginLeft:"25px"}}
                                      >
                                        
                                      <p>Total Duration</p>
                                      <div className="course-view d-flex align-items-center">
                                        <img src={TimerStart} alt="" />
                                        <div  
                                        style={{marginBottom:"-14px"}}>
                                           <p>{time}</p>

                                        </div>
                                      </div>
                                      </div>
                                      <div className="align-items-center"
                                      // style={{marginLeft:"25px"}}
                                      >
                                        
                                      <p>Total Videos Watched</p>
                                      <div className="course-view d-flex align-items-center">
                                        <img src={VideoNew} alt="" style={{width:"24px",height:"24px"}} />
                                        <div  
                                        style={{marginBottom:"-16px" ,marginLeft:"5px"}}>
                                           <p>{item?.count?.watchCount} Video</p>

                                        </div>
                                      </div>
                                      </div> */}
                                    {/* <div className=" d-flex align-items-center border-bottom-0 pb-0 justify-content-between">
                                      
                                      <div className="rating-img d-flex align-items-center">
                                        
                                        <img src={Icon1} alt="" />
                                        <div  
                                        style={{marginBottom:"-12px"}}
>
                                        <p className=""
                                        >
                                          {item?.count?.totalVideos} {""}   Total Videos
                                        </p>
                                        </div>
                                      </div>
                                      <div className="course-view d-flex align-items-center">
                                        <img src={TimerStart} alt="" />
                                        <div  
                                        style={{marginBottom:"-12px"}}>
                                           <p>{time} Total Duration</p>

                                        </div>
                                      </div>
                                      
                                    </div> */}
                                    <div className="course-stip progress-stip">
                                      <Tooltip title={ `${
                                            ((item?.count?.watchCount || 0) /
                                              (item?.count?.totalVideos || 1)) *
                                            100
                                          }%`}>
                                      <div
                                        className="progress-bar bg-success progress-bar-striped active-stip"
                                        style={{
                                          width: `${
                                            ((item?.count?.watchCount || 0) /
                                              (item?.count?.totalVideos || 1)) *
                                            100
                                          }%`,
                                        }}
                                      ></div>
                                      </Tooltip>
                                    </div>
                                    {/* <div className="row">
                                      <p>{`${
                                            ((item?.count?.watchCount || 0) /
                                              (item?.count?.totalVideos || 1)) *
                                            100
                                          }%`} completed</p>
                                    </div> */}
                                  </div>
                                </div>
                                <div
                                  className="col-lg-4 col-sm-12"
                                  style={{
                                    justifyContent: "center",
                                    margin: "auto",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignSelf: "center",
                                    }}
                                  >
                                    <button
                                      className="btn btn-primary"
                                      style={{ marginTop: "20px" }}
                                      onClick={() => {
                                        handelVideoWatch(item?.id);
                                      }}
                                    >
                                      Watch Videos
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>
                        <h4 style={{ textAlign: "center" }}>No Videos</h4>
                      </div>
                    )}
                  </div>
                  {/* {list?.length>0?list?.map((item)=>{
                          return(
                          //   <tbody>

                              //   <tr style={{marginTop:"20px"}}>
                              //   <td>
                              //     <div className="sell-table-group d-flex align-items-center">
                              //       <div className="sell-group-img"
                              //       style={{width:"none",height:"none"}}
                              //       >
                              //         <Link to="course-details">
                              //           <img
                              //             src={item?.image}
                              //             className=""
                              //             alt=""
                              //             style={{width:"80%",height:"90%"}}
                              //           />
                              //         </Link>
                              //       </div>
                              //       <div className="sell-tabel-info">
                              //         <p style={{textTransform:"capitalize"}}>
                              //           <Link to="">
                              //             {item?.courseName} course
                              //           </Link>
                              //         </p>
                              //         <div className="course-info d-flex align-items-center border-bottom-0 pb-0">
                              //           <div className="rating-img d-flex align-items-center">
                              //             <img src={Icon1} alt="" />
                              //             <p>{item?.count?.totalVideos} {""}Videos</p>
                              //           </div>
                              //           <div className="course-view d-flex align-items-center">
                              //             <img src={TimerStart} alt="" />
                              //             <p>{time}</p>
                              //           </div>
                              //         </div>
                              //         <div className="course-stip progress-stip">
                              //           <div className="progress-bar bg-success progress-bar-striped active-stip"
                              //            style={{ width: `${((item?.count?.watchCount || 0) / (item?.count?.totalVideos || 1)) * 100}%` }}
                              //           ></div>
                              //         </div>
                              //       </div>
                              //     </div>
                              //   </td>
                               
                              //   <td>
                              //     <div style={{display:"flex",alignSelf:"center"}}>
                              //     <button className="btn btn-primary"
                              //     style={{marginTop:"20px"}}
                              //     onClick={()=>{
                              //       handelVideoWatch(item?.id)
                              //     }}
                              //     >
                              //       Watch Videos
                              //     </button>
                              //     </div>
                                 
                              //   </td>
                              // </tr>
                              // </tbody>
                          )
                        }):""} */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Page Wrapper */}
      <ToastContainer />
      <FooterStudent />
    </div>
  );
}
