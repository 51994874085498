import React, { useContext, useEffect, useState } from "react";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "../../../api/axios";
import AdminInstructorSidebar from "../adminSidebar";
import { useFormik } from "formik";
import CryptoJS from "crypto-js";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import AdminHeader from "../adminHeader";
import { notification, Space } from "antd";
import Select from "react-select";
import Form from "react-bootstrap/Form";

const ADDSUBSCRPTIONS = "/api/add-student-subscription";
const AMOUNT="/api/course-amount"
const COURSEBTACH="/api/course-list-batch"
const initalValue = {
    // name: "",
    // email: "",
    // phone: "",
    // admission_date: "",
    expiry_date: "",
    total_amount: "",
    installment_amount: "",
    courseId:"",
    // remark: "",
    // gender:"",
    // dob:""

};
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const studentSchema = Yup.object({
    expiry_date: Yup.date().required("Please enter your expiry date"),
    // total_amount: Yup.string().required("Please enter your total amount"),
    installment_amount: Yup.string().required("Please enter your instalment amount"),
    pending_fees: Yup.string().required("Please enter your pending fees"),
    courseId:Yup.string().required("Please Select your Course")

});
export default function AddSubscrptions() {
    const {  id,batch_id,coursedata } = useParams()
    // console.log("slug ",coursedata)
    const navigate = useNavigate();
    const [submit, setSubmit] = useState(false)
    const style = {
        control: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: "white",
          width: "100%",
          height: "auto",
          color: "black",
          minHeight: "40px",
          border: "1px solid #e9ecef",
          paddingLeft: "5px",
          // This line disable the blue border
          boxShadow: state.isFocused ? 0 : 0,
          borderRadius: state.isSelected ? "0" : "10px",
          fontSize: "14px",
          "&:hover": {
            cursor: "pointer",
          },
          outline: "none",
        }),
        menu: (base) => ({ ...base, marginTop: "0px" }),
        menuList: (base) => ({ ...base, padding: "0" }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "white" : "white",
          color: "black",
          fontSize: "14px",
          "&:hover": {
            backgroundColor: "#FFDEDA",
            // #dddddd
          },
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: "none",
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          color: "black",
          transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
          transition: "250ms",
        }),
      };
    // let bytes = CryptoJS.AES.decrypt(
    //     decodeURIComponent(id),
    //     "my-secret-key@admin8623009959"
    // );
    // let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // var ciphertext = CryptoJS.AES.encrypt(
    //     JSON.stringify(slug),
    //     "my-secret-key@admin8623009959"
    // ).toString();
    // let ciphertexts = encodeURIComponent(ciphertext);
    const token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const [api, contextHolder] = notification.useNotification();
    const [courseslist,setcourselist]=useState()
    const[courseid,setCourseid]=useState("")
    const[amount,setAmount]=useState("")
    const [formState, setFormState] = useState({
   expiry_date: "",
   installment_amount: "",
   pending_fees: "",
     });
    const coursebatchlsit=async()=>{
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        try {
            // setLoading(true);
            const resonse = await axios.get(`${COURSEBTACH}/${batch_id}`, config);
            // console.log(resonse,"amount")
            setcourselist(resonse?.data)
          } catch (error) {
            console.log(error);
            if (error?.response?.status === 401) {
                openNotificationWithIconSesion("error")
              localStorage.removeItem("token");
              navigate("/admin/login");
            }
            else{
                openNotificationWithIcon2("error");

            }
          }
    }
    const courseamount=async(id)=>{
        // console.log(id,"courseamount")
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        try {
            //  setLoading(true);
            const resonse = await axios.get(`${AMOUNT}/${id}`, config);
 const data=resonse?.data[0]?.amount?resonse?.data[0]?.amount:"";
        // formState.total_amount=data
            setAmount(data)
            //  console.log(resonse?.data[0]?.amount,"amount")
          } catch (error) {
            console.log(error);
            if (error?.response?.status === 401) {
                
              localStorage.removeItem("token");
              navigate("/admin/login");
            }
            else{
            openNotificationWithIcon2("error");
            }
          }
    }
  
useEffect(()=>{

coursebatchlsit()
},[])
//  console.log(courseslist,"initalvalue")
    const openNotificationWithIcon = (type, text,col) => {
        notification[type]
            (
                {
                    message:
                        (
                            <div style={{ color: col }}>
                                <strong>{text}</strong>
                            </div>
                        ),
                    duartion: 1
                });
    };
    const openNotificationWithIconSesion = (type) => {
        notification[type]({
          message: (
            <div style={{ color: "red" }}>
              <strong>Session Expired Please Login </strong>
            </div>
          ),
          duration: 1,
        });
      };
   
    useEffect(() => {
        const lastVisitedRoute = localStorage.getItem("lastVisitedRoute")
        if (lastVisitedRoute) {
            localStorage.removeItem("lastVisitedRoute")
        }
        else {
            localStorage.setItem("lastVisitedRoute", window.location.pathname);

        }
    }, []);
    const openNotificationWithIcon2 = (type) => {

        notification[type]
            (

                {
                    message:
                        (
                            <div style={{ color: "red" }}>
                                <strong>Internal Server Error </strong>
                            </div>
                        ),

                    // description:
                    // (<div style={{color:"green"}}>
                    // Your Batch has Sucessfully Deleted

                    // </div>)
                    duration: 1
                });
    };
    const today = new Date();
    today.setFullYear(today.getFullYear() - 12);
  
    const maxDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    const handleInputChange = (field, value) => {
        setFormState((prevFormState) => ({
          ...prevFormState,
          [field]: value,
        }));
      };
    //   console.log(formState,"formState")
      const handleSubmit=async()=>{
        // event.preventDefalut()
        const updatedFormState = {
            ...formState,

            "batch_id": batch_id,
            "user_id":id,   // Add users to formState
            courseId:courseid,
            total_amount:amount
            
          };
          // console.log(updatedFormState,"upadte formstate")
          updatedFormState.pending_fees = "1"
          try {
            const response = await axios.post(
                ADDSUBSCRPTIONS,
              updatedFormState,
              config
            );
            if (response) {
                openNotificationWithIcon("success", response.data.success,'#28a745')
                setTimeout(() => {
                    navigate(-1)
                }, 800);
              // fetchBatchData()
      
            }
          } catch (error) {
            console.log(error.response.data.message);
            if (error.response.status == 403) {
              openNotificationWithIcon(
                "error",
                error.response.data.error,
                "red"
              );
            } else {
              openNotificationWithIcon(
                "error",
                error.response.data.message,
                "red"
              );
              
            }
            if (error?.response?.status === 401) {
              localStorage.removeItem("token");
              navigate("/admin/login");
            }
          }
      }
    return (
        <div className="main-wrapper">
            <AdminHeader />
            {/* Student Dashboard */}
            <div className="page-content">
                <div className="container"
                    style={{ minWidth: "100%", marginTop: "-15px" }}
                >
                    <div className="row">
                        {/* Sidebar */}
                        <AdminInstructorSidebar activeMenu={"Batches"} />
                        {/* Sidebar */}

                        {/* Profile Details */}
                        <div className="col-xl-9 col-md-8 main-page">
                            <div className="settings-widget profile-details">
                                <div className="settings-menu p-0">
                                    <div className="profile-heading">
                                        <h3>Add Subscription</h3>
                                        <nav aria-label="breadcrumb">
                                            <ol class="breadcrumb" style={{ marginBottom: 0.5 }}>
                                                <li
                                                    class="breadcrumb-item "
                                                    onClick={() => navigate("/admin/batchList")}
                                                    // aria-current="page"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Batches
                                                </li>
                                                <li
                                                    class="breadcrumb-item"
                                                    aria-current="page"
                                                    style={{
                                                        cursor: "pointer",
                                                        // marginLeft: "-.6em",
                                                        fontWeight: 'normal'
                                                    }}
                                                    onClick={() => navigate(-1)}
                                                >
                                                     Students
                                                </li>
                                                <li
                                                    class="breadcrumb-item active"
                                                    aria-current="page"
                                                    style={{
                                                        cursor: "pointer",
                                                        marginLeft: "-.7em",
                                                    }}
                                                >
                                                    Add Subscription
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                    <div className="checkout-form personal-address add-course-info">
                                      
                                            <div className="row">
                                            <div className="col-lg-6">
                                            <Form>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
    <Form.Label>Select Course</Form.Label>
    <Form.Select
      value={courseid} // Set the selected value here
      onChange={(e) => {setCourseid(e.target.value),courseamount(e?.target.value)}} // Handle the change event
      placeholder="Select a course"
    >
      <option value="">Select...</option>
      {courseslist?.map((option) => (
        <option key={option?.id} value={option?.id}>
          {option?.name}
        </option>
      ))}
    </Form.Select>
  </Form.Group>
  </Form>
                                            </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Expiry Date
                                                        </label>
                                                        <div className="password-field">
                                                            <input
                                                                type='date'
                                                                className="form-control"
                                                                name="expiry_date"
                                                                id="expiry_date"
                                                                placeholder="Expiry Date"
                                                                value={formState?.expiry_date}
                                                             min={new Date().toISOString().split('T')[0]}     
                                                             onChange={(e) => handleInputChange('expiry_date', e.target.value)}
                                                            />
                                                            
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div
                                                        className="form-group"
                                                    >
                                                        <label className="form-control-label">
                                                            Total Amount
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="total_amount"
                                                            value={amount}
                                                            // onChange={handleChange}
                                                            name="total_amount"
                                                            placeholder="Total Amount"
                                                        />
                                                     
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div
                                                        className="form-group"
                                                    >
                                                        <label className="form-control-label">
                                                            Instalment Amount
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="installment_amount"
                                                            value={formState?.installment_amount}
                                                            onChange={(e) => handleInputChange('installment_amount', e.target.value)}
                                                            name="installment_amount"
                                                            placeholder="Instalment Amount"
                                                        />
                                                     
                                                    </div>
                                                </div>
                                               
                                                {/* <div className="col-lg-6">
                                                    <div
                                                        className="form-group"
                                                    >
                                                        <label className="form-control-label">
                                                            remark
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="remark"
                                                            value={values.remark}
                                                            onChange={handleChange}
                                                            name="remark"
                                                            placeholder="Remark"
                                                        />
                                                        {errors.remark && touched.remark ? (
                                                            <span style={{ color: "red" }}>{errors.remark}</span>
                                                        ) : null}
                                                    </div>
                                                </div> */}

                                                <div className="update-profile"
                                                    id="submit-button"

                                                >
                                                    <div
                                                        className="btn-group mr-2"
                                                        role="group"
                                                        aria-label="First group"
                                                        id="custom-submit"

                                                    >

                                                        <button type="submit" className="btn btn-primary"
                                                         disabled={submit}
                                                         onClick={()=>{
                                                            handleSubmit()
                                                        }}
                                                        >
                                                            Submit
                                                        </button>


                                                    </div>{" "}
                                                    <div
                                                        className="btn-group mr-2"
                                                        role="group"
                                                        aria-label="First group"
                                                        id="custom-submit"

                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                navigate(-1);
                                                            }}
                                                            className="btn btn-primary cancel-btn"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>{" "}
                                                </div>
                                            </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Profile Details */}
                    </div>
                </div>
            </div>
            {/* Student Dashboard */}
            {/* <ToastContainer autoClose={3000} hideProgressBar={true} /> */}
            <Footer />
        </div>
    );
}
