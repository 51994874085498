import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import axios from '../../../../api/axios';
// import "./React.css";
const STARTTIME="/api/student/videos/user_watch_time"
import { useNavigate } from 'react-router-dom';
import {   notification, Space  } from "antd";
import WaterMark from './WaterMark';

const VimeoPlayer = ({ id,viewvid,moduleid }) => {
  const [isActive, setIsActive] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [timer, setTimer] = useState(0);
  const[primaryTimer,setPrimaryTimer]=useState(0)
  let interval;

  const token = localStorage.getItem("tokenStudent");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePlay = () => {
    setIsActive(true);
    // console.log('Video is playing');
    endvideo()
  };

  const handlePause = () => {
    setIsActive(false);
    // console.log('Video is paused');
    clearInterval(interval)
  };

  const handleEnded = () => {
    setIsActive(false);
    // console.log('Video has ended');
    // endvideo(); // Assuming you want to call endvideo when the video ends
  };
  const handleVideoClick = () => {
    if (isActive) {
      setIsActive(false);
      handlePause(); // Pause the video
    } else {
      setIsActive(true);
      handlePlay(); // Play the video
    }
  };
  const sendTime = async () => {
    try {
      const response = await axios.get(`${STARTTIME}/${viewvid}`, config);
      setPrimaryTimer(+(response?.data?.watchTime));
    } catch (error) {
      if (error?.response?.status === 403) {
        openNotificationWithIcon4("error",error?.response?.data?.message);
        navigate("/student/videos");
      }
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else{
        openNotificationWithIcon4("error",error?.response?.data?.message)
      }
    }
  }

  const UpdateTime = async (time) => {
    try {
      const response = await axios.put(`${STARTTIME}/${viewvid}`,
        { "time": +time },
        config);
        setPrimaryTimer(+(response?.data?.watchTime));
        if(response){
            setTimeout(()=>{
                setTimer(0)
            },100)
        }
    } catch (error) {
      console.error(error?.response?.status);
      if (error?.response?.status == 403) {

        openNotificationWithIcon4("error", error?.response?.data?.message);
        navigate("/student/videos");
      }
      if (error.response.status == 401) {
        openNotificationWithIcon4("error")
        localStorage.removeItem('tokenStudent');
        navigate('/login');
      }
    }
  }

  const endvideo = async () => {
    try {
      const response = await axios.post("/api/student/create/video_watched", {
        "fk_sub_module_id": moduleid,
        "fk_video_id": viewvid
      }, config);
      setPrimaryTimer(+(response?.data?.watchTime));
    } catch (error) {
        if (error?.response?.status == 403) {
            openNotificationWithIcon4("error", error?.response?.data?.message);
            navigate("/student/videos");
          }
          if (error.response.status == 401) {
            localStorage.removeItem('token');
            navigate('/login');
          }
    }
  }

  useEffect(() => {
    if (isActive) {
      interval = setInterval(() => {
        setTimer(prevTime => prevTime + 1); 
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => 
      clearInterval(interval);
    
  }, [isActive]);
  useEffect(() => {
    sendTime();
  }, []);

  useEffect(() => {
    if (isActive) {
    const  interval = setInterval(() => {
        UpdateTime(timer);
        // setTimer(0)
      }, 1000);

      return () => {clearInterval(interval)};
    }
  }, [isActive,timer]);

  const openNotificationWithIcon4 = (type, text) => {
    notification[type](
      {
        message:
          (
            <div style={{ color: "red" }}>
              <strong>{text}</strong>
            </div>
          ),
        duration: 4
      });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const handleToggle = () => {
    // setIsActive(!isActive);
    // if (isActive) {
    //   UpdateTime(timer);
    // }
  };

  return (
    <div className='player-wrapper'>
      {/* <div>Elapsed Time: {timer} seconds</div>
      <div>{primaryTimer} upadted time in sec</div> */}
      <ReactPlayer
        url={`https://vimeo.com/${id}`}
        className='react-player'
        controls={true}
        width='100%'
        height='650px'
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        playsinline
        onClick={handleVideoClick}
      />
          {/* <WaterMark/> */}

            <button  style={{display:'none'}}onClick={handleToggle}>{isActive ? '' : ''}</button>

    </div>
  );
};
export default VimeoPlayer;