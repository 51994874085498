import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import * as Yup from "yup";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../instructor/adminHeader";
import {   notification, Space  } from "antd";

const UPDATETESTSERIES = "/api/test-series/update-testseries-module";
const EDITTESTSERIES = "/api/test-series/edit-testseries-module";

export default function AdminEditTestSeries() {
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();
  const[submit,setSubmit]=useState(false)

  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const fetchTestEdit = async () => {
    setLoading(true);
    const response = await axios.get(
      `${EDITTESTSERIES}/${decryptedData}`,
      config
    );
    setData(response.data.res);
    setLoading(false);
  };
  useEffect(() => {
    fetchTestEdit();
  }, []);
  const initalValue = {
    name: data?.name?data?.name:"",
    total_question: data?.total_question?data?.total_question:"",
    total_marks: data?.total_marks?data?.total_marks:"",
    negative_question: data?.negative_marks?data?.negative_marks:"",
    duration: data?.duration?data?.duration:"",
    status: data?.status,
    attempts:data?.attempts?data?.attempts:""
  };
  const studentSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter your name"),
    total_question: Yup.string()
      .min(1)
      .max(100)
      .required("Please enter your total question"),
    total_marks: Yup.string()
      .min(1)
      .max(100)
      .required("Please enter your total marks"),
    negative_question: Yup.string()
      .min(1)
      .max(25)
      .required("Please enter your negative marks"),
    duration: Yup.string()
      .min(1)
      .max(25)
      .required("Please enter your duration"),
    status: Yup.string().required("Please enter your status"),
    attempts: Yup.string().min(1).max(25).required("Please enter your attempts"),

  });
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  
  const[toast,setToast]=useState(false)
 
    const openNotificationWithIcon1 = (type) => {

      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"#52c41a"}}>
           <strong>Test series updated </strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        duration:1
      });
      };
      const openNotificationWithIconSesion = (type) => {
        notification[type]({
          message: (
            <div style={{ color: "red" }}>
              <strong>Session Expired Please Login </strong>
            </div>
          ),
          duration: 1,
        });
      };

      const openNotificationWithIcon2 = (text,type) => {
  
        notification[type]
        (
          
          {
          message: 
          (
            <div style={{color:"red"}}>
             <strong>{text}</strong> 
            </div>
          ),
        
          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted
      
          // </div>)
          duration:1
        });
        };
  const { errors, values, touched, handleChange, handleSubmit, getFieldProps } =
    useFormik({
      initialValues: initalValue,
      onSubmit: async (value) => {
        setSubmit(true)
        try {
          const response = await axios.post(
            UPDATETESTSERIES,
            {
              "testseries-name": value.name,
              status: value.status,
              total_question: value.total_question,
              total_marks: value.total_marks,
              negative_question: value.negative_question,
              duration: value.duration,
              "testseries-id": decryptedData,
              attempts:value?.attempts

            },
            config
          );
          if (response) {
            openNotificationWithIcon1("success")
            setTimeout(() => {
              // navigate("/admin/test-series");
              
              navigate(-1);
            }, 800);
            // toast.info("Test Series Updated");
          }
        } catch (error) {
          setSubmit(false)
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem('token')
            navigate('/admin/login')
          }
        else if (error?.response?.status === 422) {
            openNotificationWithIcon2(error?.response?.data?.message,"error")
  
          }
          else if (error?.response?.status === 403) {
            localStorage.removeItem("token");
            openNotificationWithIcon2(error?.response?.data?.message,"error");
            setTimeout(() => {
              navigate("/admin/login");
            }, 1000); // Delay the navigation to show the notification
          }
          else{
            openNotificationWithIcon2(error?.response?.data?.message,"error")

          }
          console.log(error);
        }
      },
      enableReinitialize: true,
      validationSchema: studentSchema,
    });
    useEffect(() => {
      const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
      if(lastVisitedRoute){
        localStorage.removeItem("lastVisitedRoute")
      }
      else{
        localStorage.setItem("lastVisitedRoute", window.location.pathname);
  
      }
    }, []);
    // const windowTop = (arg1, arg2) => {
    //   window.scrollTo(arg1, arg2);
    // };
    
    //   useEffect(() => {
    //     windowTop(0, 0);
    //   }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader/>
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
                style={{minWidth:"100%",marginTop:"-15px"}}

        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Test Series</h3>
                    {/* <p>Edit Test Series information.</p> */}
                    <nav aria-label="breadcrumb" style={{marginTop:'0.5em'}} >
                      <ol className="breadcrumb" style={{marginBottom:0.5}}>
                        <li className="breadcrumb-item" onClick={()=>navigate('/admin/test-series')} aria-current="page">
                          Test Series
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                        Edit Test Series
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      {loading === true ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Enter Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={values.name}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Your Name"
                              />
                              {errors.name && touched.name ? (
                                <span style={{ color: "red" }}>
                                  {errors.name}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Total Question
                              </label>
                              <input
                                type="number"
                                name="total_question"
                                id="total_question"
                                value={values.total_question}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Total Question"
                              />
                              {errors.total_question &&
                              touched.total_question ? (
                                <span style={{ color: "red" }}>
                                  {errors.total_question}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Total Marks
                              </label>
                              <input
                                type="number"
                                name="total_marks"
                                id="total_marks"
                                value={values.total_marks}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Total Marks"
                              />
                              {errors.total_marks && touched.total_marks ? (
                                <span style={{ color: "red" }}>
                                  {errors.total_marks}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Negative Marks(Every Question)
                              </label>
                              <input
                                type="number"
                                name="negative_question"
                                id="negative_question"
                                step="0.01"
                                value={values.negative_question}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Negative Marks"
                              />
                              {errors.negative_question &&
                              touched.negative_question ? (
                                <span style={{ color: "red" }}>
                                  {errors.negative_question}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                 Duration (In minutes) 
                              </label>
                              <input
                                type="number"
                                name="duration"
                                id="duration"
                                value={values.duration}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Duration"
                              />
                              {errors.duration && touched.duration ? (
                                <span style={{ color: "red" }}>
                                  {errors.duration}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Attempts
                            </label>
                            <input
                              type="number"
                              name="attempts"
                              id="attempts"
                              value={values.attempts}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Duration"
                            />
                            {errors.attempts && touched.attempts ? (
                              <span style={{ color: "red" }}>
                                {errors.attempts}
                              </span>
                            ) : null}
                          </div>
                        </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label
                                className="radio control-label"
                                style={
                                  {
                                    // marginRight: "10px",
                                    // marginTop: "-30px",
                                  }
                                }
                              >
                                Status :
                              </label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("status").onChange}
                                  value={"0" || values?.status}
                                  checked={values?.status === "0"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio2"
                                  onChange={getFieldProps("status").onChange}
                                  value={"1" || values?.status}
                                  checked={values?.status === "1"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Active
                                </label>
                              </div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="update-profile"
                          id="submit-button"
                          >
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"

                            >
                              <button type="submit" className="btn btn-primary"
                              disabled={submit}
                              >
                                Update
                              </button>
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"

                            >
                              <button
                                type="button"
                                onClick={() => {
                                  navigate("/admin/test-series");
                                  // windowTop(0,0)
                                }}
                                className="btn btn-primary cancel-btn "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
