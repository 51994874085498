import React, { useContext, useEffect, useRef, useState } from "react";
import AdminHeader from "../adminHeader";
import Footer from "../../footer";

import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import { Input, Tooltip } from "antd";
import Button from "react-bootstrap/Button";

import axios from "../../../api/axios";
import CryptoJS from "crypto-js";
const CONTACTLISTDETAILS = "/api/Web-Contact-Details/view";
const DELETECONTACTDETAILS = "api/Web-Contact-Details/delete";
const ADDCATEGORY = "/api/course-category/create";
const STATUSCHANGE = "/api/Web-Contact-Details/status";
const CONTACTSEARCH = "/api/course-category/category-search";
const COURSECATEGORYDELETE = "api/course-category/destroy/all";
import "react-toastify/dist/ReactToastify.css";
import { notification, Modal } from "antd";
import Form from "react-bootstrap/Form";
import "../addAdminRecordedVideos/insex.css";
import moment from "moment";
const { confirm } = Modal;
import Switch from "react-switch";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";

export default function AdminContactsDetails() {
  const { Search } = Input;
  const [install, setinstall] = useState(null);
  const [status, setSatus] = useState("");
  const [category, setCategory] = useState("");
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [last_page, setLast_page] = useState("");
  const [text, settext] = useState("");
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();
  const { id } = useParams();

  // const [itemsPerPage, setitemsPerPage] = useState('')
  const [userid, setuserid] = useState("");
  const [batchid, setbatchid] = useState("");
  const [courseid, setcourseid] = useState("");
  const [paydata, setpaydata] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [isModalVisibleForm1, setIsModalVisibleForm1] = useState(false);
  const [emaildelete, setEmailDelete] = useState("");
  const [passworddelete, setPasswordDelete] = useState("");
  const [deleteid, setDeleteId] = useState("");
  const [message, setmessage] = useState("No Data");
  const [total_amount, settotal_amount] = useState("");
  const [remaing, setRemaing] = useState("");
  const [sum, setsum] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(new Date());
  const [imagevalue, setimagevalue] = useState(false);
  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const fetchContactList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(CONTACTLISTDETAILS, config);
        // console.log(resonse,"resonse")

      // setitemsPerPage(batch_detail_relation.length)
        setList(resonse?.data?.data);
      //   setData(resonse?.data?.data);
      setLast_page(resonse?.data?.last_page);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");

      }
      else{
        openNotificationWithIcon2("error","Internal Server Error","red")
      }
    }
  };
  // console.log(last_page)

  useEffect(() => {
    fetchContactList();
  }, []);
  const handleEdit = (detail) => {
    navigate(`/admin/edit/contact-details/${id}/${detail}`);
  };
  const handleChange = async (id1, status) => {
    // console.log(deleteid,"id")
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      STATUSCHANGE,
      { id: id1, status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1("success", "Status Updated", "#52c41a");
      fetchContactList();
    }
    if (!response) {
      openNotificationWithIcon2("error", "Internal Server", "red");
    }
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
  };


  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };
  const handleShowForm1 = () => {
    setIsModalVisibleForm1(true);
  };
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon1 = (type, text, colors) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type, text, colors) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
//   const visiblerecordsData = list?.slice(indexOfFirstCourse, indexOfLastCourse);
  const recordsDataLength = list?.length ? list?.length : 0;
  const numOFTotalPage = Math.ceil(recordsDataLength / 10);
//   const pages = [...Array(numOFTotalPage + 1).keys()].slice(1);
  const statusRef = useRef(null);
  const imageRef = useRef(null);
 
  // console.log(status,"status",
  // file,"file")

  const handleCreate = () => {
    navigate(`/admin/craete/contacts-details/${id}`)
  };
const handleViewConatct=(id)=>{
  navigate(`/admin/contact-details/${id}`)
}
  const handleForceDelete = () => {
    handleShowForm1();
  };
  const handleModalClose = () => {
    setIsModalVisibleForm(false);
    setCategory("");
    setSatus(""); // Resetting status
    setFile(null); // Resetting file
    if (imageRef.current) imageRef.current.value = "";

    setFilePre(""); // Resetting filePre
  };
  const handleModalDeleteClose = () => {
    setIsModalVisibleForm1(false);
  };
  const Filter = async (e) => {
    const searchValue = e.target.value;
    settext(searchValue);
    const res = await axios.post(CONTACTSEARCH, { name: searchValue }, config);
    if (res.data.data.length > 0) {
      setList(res.data.data);
      setCurrentPage(res.data.current_page);
      setLast_page(res.data.last_page);
      setmessage("No Data");
    }
    if (res.data.data.length === 0) {
      setList(res?.data?.data);
      setmessage("No Data Found");
    }
    if (searchValue.length === 0) {
      const res = await axios.get(
        `${CONTACTLISTDETAILS}?page=${currentPage}`,
        config
      );
      setList(data);
      setCurrentPage(1);
      setLast_page(res.data.last_page);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  let itemsPerPage = 10;

  const handleClose = () => setShow(false);

  function showConfirm(id) {
    setDeleteId(id);
    confirm({
      title: "Do you want to delete this Contact Detail?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(
            `${DELETECONTACTDETAILS}/${id}`,
            config
          );
          //  console.log("resonse", resonse?.status);
         
            openNotificationWithIcon1("success", "Contact Details Deleted", "#52c41a");

            setLoading(false);
            fetchContactList();

          setShow(false);
        } catch (error) {
          console.log(error?.response?.data?.message);
          if (error?.response?.status == 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          } else if (error.response.status == 422) {
            openNotificationWithIcon2(
              "error",
              error?.response?.data?.message,
              "red"
            );
            fetchContactList();
          } else {
            openNotificationWithIcon2(
              "error",
              error?.response?.data?.message,
              "red"
            );
          }
        }
      },
      onCancel() {},
    });
  }
  const handeleDeleteCategory = async () => {
    try {
      // console.log("krishna",deleteid)
      // setLoading(true);
      const resonse = await axios.post(
        `${COURSECATEGORYDELETE}/${deleteid}`,
        { email: emaildelete, password: passworddelete },
        config
      );
      //  console.log(resonse,"resonse delete")
      openNotificationWithIcon1("error", "Category Deleted", "red");
      handleModalDeleteClose();
      fetchContactList();

      // setitemsPerPage(batch_detail_relation.length)
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      } else {
        openNotificationWithIcon2("error", error?.response?.data, "red");
      }
    }
  };

  // console.log(imagevalue,"list")
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"contact"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget ttt">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <div>
                            <h3 style={{ textTransform: "capitalize" }}>
                              Contacts Details
                            </h3>
                            <nav
                              aria-label="breadcrumb"
                              style={{ marginBottom: "-1.6em" }}
                            >
                              <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate("/admin/contacts")
                                }
                              >
                                Contacts
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                // onClick={() =>
                                //   navigate("/admin/recorded-videos")
                                // }
                              >
                                {" "}
                                Contacts Details
                              </li>
                            </ol>
                            </nav>
                          </div>
                        </div>
                        <div className="comman-space pb-0">
                          <div
                          // className="settings-referral-blk table-responsive"
                          // id="table-value"
                          >
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row gx-2 align-items-center">
                                    <div className="col-md-6 col-lg-6">
                                      <div className=" search-group">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Name"
                                          // allowClear
                                          onChange={Filter}
                                          onKeyPress={handleKeyPress}
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-item">
                                      <div
                                        className="button-conatiner  justify-content-end"
                                        // style={{  marginBottom:"-5px"}}
                                      >
                                        
                                        <Button
                                            onClick={() =>
                                              handleCreate()
                                            }
                                          style={
                                            {
                                              // marginLeft: "5.2em",
                                              // width:"100%"
                                            }
                                          }
                                          className="btn btn-primary"
                                          id="custom-button"
                                        >
                                          {" "}
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Create New
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                            
                              <Modal
                                title="Delete Course Category"
                                okButtonProps={{
                                  style: { backgroundColor: "#FF875A" },
                                  children: "Import",
                                }}
                                open={isModalVisibleForm1}
                                okText="Delete"
                                onOk={() => {
                                  handeleDeleteCategory();
                                }}
                                // onCancel={() => {
                                //   setIsModalVisibleForm1(false);
                                // }}
                                onCancel={handleModalDeleteClose}
                              >
                                <Form>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput2"
                                  >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                      type="email"
                                      placeholder="Enter email"
                                      value={emaildelete} // Set the value if you want to control it
                                      onChange={(e) =>
                                        setEmailDelete(e.target.value)
                                      } // Handle the change event
                                    />
                                  </Form.Group>

                                  {/* Password Input */}
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput3"
                                  >
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                      type="password"
                                      placeholder="Enter password"
                                      value={passworddelete} // Set the value if you want to control it
                                      onChange={(e) =>
                                        setPasswordDelete(e.target.value)
                                      } // Handle the change event
                                    />
                                  </Form.Group>
                                </Form>
                              </Modal>

                              <table className="table table-nowrap mt-2">
                                <thead>
                                  <tr className="text-center">
                                    <th>Sr.No</th>
                                    <th>Contact Details</th>
                                    <th>Contact Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                {loading === true ? (
                                  <tbody
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                      position: "fixed",
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.6)",
                                    }}
                                  >
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : list?.length > 0 ? (
                                  list?.map((item, i) => {
                                    const serialNumber =
                                      (currentPage - 1) * itemsPerPage + i + 1;
                                      // console.log(item?.id,"id in map")
                                    return (
                                      <tbody
                                        key={i}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        <tr className="text-center">
                                          <td>{serialNumber}</td>
                                          <td>
                                            
                                            {item?.detail}
                                            
                                          </td>
                                          <td>
                                           {item?.webcontact?.name}
                                          </td>
                                          <td>
                                            <Switch
                                              onChange={(e) => {
                                                handleChange(
                                                  item?.id,
                                                  item?.status
                                                );
                                              }}
                                              checked={
                                                item?.status === "1"
                                                  ? true
                                                  : false
                                              }
                                              // id={item?.id}
                                              onColor="#86d3ff"
                                              onHandleColor="#2693e6"
                                              handleDiameter={20}
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                              height={15}
                                              width={38}
                                              className="react-switch"
                                            />
                                          </td>

                                          <td>
                                            <Tooltip title="Edit">
                                              <Button
                                                onClick={() =>
                                                  handleEdit(item?.id)
                                                }
                                                type="button"
                                                className="btn btn-secondary"
                                              >
                                                <i className="fa-regular fa-pen-to-square"></i>
                                              </Button>{" "}
                                            </Tooltip>
                                           
                                            <Tooltip title="Delete">
                                              {" "}
                                              <Button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                  showConfirm(item?.id)
                                                }
                                              >
                                                <i className="fa-regular fa-trash-can"></i>
                                              </Button>
                                            </Tooltip>
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </table>
                            </div>
                            {list?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {list?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationVideo
                                nPages={last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={CONTACTLISTDETAILS}
                                setData={setList}
                                text={text}
                                setLast_page={setLast_page}
                                linkSearch={CONTACTSEARCH}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
}
