import React, { useState, useContext, useEffect } from "react";
import Footer from "../../footer";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import axios from "../../../api/axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import AdminHeader from "../../instructor/adminHeader";
import { notification } from "antd";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import TextEditor from "../../pages/course/addCourse/textEditor";
import { Input } from "antd";
import "./index.css";
const ADDVIDEO = "/api/recorded-video/create";

const initalValue = {
  title: "",
  link_type: "",
  video_link: "",
  desc: "",
  thumbnail: "",
  status: "",
  time: "",
};
function checkUrl(test_url) {
  var testLoc = document.createElement('a');
      testLoc.href = test_url.toLowerCase();
  let url = testLoc.hostname;
  var what;
  if (url.indexOf('youtube.com') !== -1) {
      what='Youtube';
  }else if (url.indexOf('vimeo.com') !== -1) {
      what='Vimeo';
  }else{
      what='None';
  }
  return what;
}

async function getFileFromUrl(url, name, defaultType = 'image/jpeg'){
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

const studentSchema = Yup.object({
  title: Yup.string().min(2).required("Please enter your title"),
  link_type: Yup.string().required("Please enter your link type"),
  desc: Yup.string().required("Please enter your video description"),
  video_link: Yup.string()
    .test("isLarger", "link type and video link does not match", (value, testContext) => {
      let a = checkUrl(value);
      if (testContext.parent.link_type == 0 && a == "Youtube") {
       return true
      } else if (testContext.parent.link_type == 1 && a == "Vimeo") {
        return true
      }
      else{
        return false
      }
    })
    .required("Please enter a video link"),
  status: Yup.string().required("Please enter your status"),
  time: Yup.string().required("Please enter your Video Time"),
});

export default function AddVideo() {
  const [errorI, setErrorI] = useState("");
  const [file, setFile] = useState();
  const [filePre, setFilePre] = useState();
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(false);

  const YouTubeGetID = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  const openNotificationWithIcon1 = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    validationSchema: studentSchema,
    onSubmit: async () => {
      // console.log(values, file, "test25");
      setSubmit(true);
      const formData = new FormData();
      setErrorI("");
      let type= checkUrl(values.video_link)
      
      let files =null
      let img=null
      let vimeo_Reg = /(videos|video|channels|\.com)\/([\d]+)/;
      if(type=='Youtube'){
        const ytcode = YouTubeGetID(values.video_link);
        img=`https://img.youtube.com/vi/${ytcode}/mqdefault.jpg`
        //  files = await getFileFromUrl(`https://img.youtube.com/vi/${ytcode}/mqdefault.jpg`, 'youtube.jpg');
      }
      else if(type=='Vimeo'){
        img=`https://vumbnail.com/${values.video_link.match(vimeo_Reg)[2]}.jpg`
        // files = await getFileFromUrl(`https://vumbnail.com/${values.video_link.match(vimeo_Reg)[2]}.jpg`, 'vimeo.jpg');
      }
      
      if(file==undefined){
        formData.append("thumbnail", img);
        formData.append("thumbnail_type", 'url');
      }else{
        const image = new Image();
      image.src = URL.createObjectURL(file);
      await new Promise(resolve => { image.onload = resolve });
      if (image.width > 630 || image.height > 420) {
        openNotificationWithIcon1("error", "Image dimensions should be 630px width and 430px height.", "red");
        setSubmit(false);
        return;
      }
        formData.append("thumbnail", file);
        formData.append("thumbnail_type", 'file');
      }
      
      formData.append("desc", values.desc);
      formData.append("title", values.title);
      formData.append("link_type", values.link_type);
      formData.append("status", values.status);
      formData.append("video_link", values.video_link);
      formData.append("time", values?.time);
      // console.log(formData);
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const response = await axios.post(ADDVIDEO, formData, config);
        // console.log(response);
        if (response) {
          openNotificationWithIcon1("success", "Video Created", "#52c41a");
          setTimeout(() => {
            navigate("/admin/recorded-videos-list");
          }, 800);
          // toast.success("Video Created");
        }
      } catch (error) {
        console.log(error);
        setSubmit(false);
        if (resonse?.status == 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login")
  
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon1(
            "error",
            error.response.data.message,
            "red"
          );
        } 
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIcon1("error", error?.response?.data?.message,"red");
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
        else {
          openNotificationWithIcon1("error", "Internal Server Error", "red");
        }
        if (error.response.data.errors.thumbnail) {
          openNotificationWithIcon1(
            "error",
            error.response.data.message,
            "red"
          );
          setErrorI(error.response.data.errors.thumbnail.toString());
        }
      }
    },
  });
  // console.log(initalValue,"instila value")


  const options = [
    { label: "YouTube", value: "0" },
    { label: "Vimeo", value: "1" },
    { label: "Vdocipher", value: "2" },
  ];
  const options1 = [
    { label: "Select Status", value: "Status" },
    { label: "Draft", value: "Active" },
    { label: "Published", value: "Inactive" },
  ];
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "50px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  return (
    <>
      {/* <pre>{JSON.stringify(initalValue)}</pre> */}
      <div className="main-wrapper">
        <AdminHeader />
        <div className="page-content">
          <div
            className="container"
            style={{ maxWidth: "100%", marginTop: "-15px" }}
          >
            <div className="row">
              <AdminInstructorSidebar activeMenu={"Videos"} />
              <div className="col-xl-9 col-md-8 col-lg-8 main-page">
                <div className="settings-widget profile-details">
                  <div className="settings-menu p-0">
                    <div className="profile-heading">
                      <h3>Add Video</h3>
                      <nav aria-label="breadcrumb ">
                        <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                          <li
                            className="breadcrumb-item"
                            aria-current="page"
                            style={{ cursor: "pointer", marginBottom: 0.5 }}
                            onClick={() =>
                              navigate("/admin/recorded-videos-list")
                            }
                          >
                            Recorded Videos
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                            style={{ cursor: "pointer" }}
                          >
                            Add Video
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div className="checkout-form personal-address add-course-info ">
                      {/* <div className="personal-info-head">
                        <h4>Add Video</h4>
                      </div> */}
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Title
                              </label>
                              <Input
                                type="text"
                                name="title"
                                id="title"
                                style={{ minHeight: "50px" }}
                                value={values.title}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Title"
                              />
                              {errors.title && touched.title ? (
                                <span style={{ color: "red" }}>
                                  {errors.title}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Select Link Type
                              </label>{" "}
                              <Select
                                className=" select country-select"
                                name="link_type"
                                options={options}
                                // defaultValue={options[0]}
                                placeholder=" Select Link Type"
                                onChange={(option) =>
                                  setFieldValue("link_type", option.value)
                                }
                                // onChange={(e) => setCountry(e.value)}
                                styles={style}
                              ></Select>
                              {errors.link_type && touched.link_type ? (
                                <span style={{ color: "red" }}>
                                  {errors.link_type}
                                </span>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Video Link (For Vdocipher: paste video id only)
                              </label>
                              <input
                                type="text"
                                name="video_link"
                                id="videolink"
                                value={values.video_link}
                                onChange={handleChange}
                                className="form-control"
                                style={{ minHeight: "50px" }}
                                placeholder="Enter Video Link"
                              />
                              {errors.video_link && touched.video_link ? (
                                <span style={{ color: "red" }}>
                                  {errors.video_link}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Video Time
                              </label>
                              <input
                                type="number"
                                name="time"
                                id="time"
                                value={values.time}
                                onChange={handleChange}
                                style={{ minHeight: "50px" }}
                                className="form-control"
                                placeholder="Enter Video Time"
                              />
                              {errors.time && touched.time ? (
                                <span style={{ color: "red" }}>
                                  {errors.time}
                                </span>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group mb-0">
                            <label className="form-control-label">
                              Video Description
                            </label>
                            <div id="editor">
                              <TextEditor
                                type="description"
                                id="desc"
                                name="desc"
                                value={values.desc}
                                setFieldValue={setFieldValue}
                              />
                              {errors.desc && touched.desc ? (
                                <span style={{ color: "red" }}>
                                  {errors.desc}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div
                            className="col-lg-12"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="form-group">
                              <label className="form-control-label">
                                Thumbnail
                              </label>
                              <input
                                type="file"
                                name="Thumbnail"
                                id="Thumbnail"
                                onChange={(e) => {
                                  setFile(e.target.files[0]),
                                    setFilePre(
                                      URL.createObjectURL(e.target.files[0])
                                    );
                                }}
                                className="form-control"
                                placeholder="Cover Image"
                              />
                              {errorI ? (
                                <span style={{ color: "red" }}>{errorI}</span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <div
                                className="add-image-box"
                                style={{ minHeight: "0", height: "200px" }}
                              >
                                {!filePre && (
                                  <Link to="#" style={{ fontSize: "200px" }}>
                                    <i className="far fa-image" />
                                  </Link>
                                )}
                                {filePre && (
                                  <img
                                    src={filePre}
                                    style={{
                                      height: "200px",
                                      width: "100%",
                                      borderRadius: "10px",
                                    }}
                                  ></img>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-12"
                            style={{ marginTop: "-8px" }}
                          >
                            <div className="form-group">
                              <label className="form-label">Status</label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio2"
                                  onChange={getFieldProps("gender").onChange}
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("gender").onChange}
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  Active
                                </label>
                              </div>
                              <div>
                                {errors.status && touched.status ? (
                                  <span style={{ color: "red" }}>
                                    {errors.status}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="update-profile" id="submit-button">
                            <div
                              className="btn-group mr-2"
                              role="group"
                              id="custom-submit"
                              aria-label="First group"
                            >
                              <button
                                type="submit"
                                className="btn btn-primary responsive-buttons"
                                id="custom-submit"
                                disabled={submit}
                              >
                                Submit
                              </button>
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              id="custom-submit"
                              aria-label="First group"
                            >
                              <button
                                type="button"
                                onClick={() => {
                                  navigate(-1);
                                }}
                                id="custom-submit"
                                className="btn btn-primary cancel-btn responsive-buttons"
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* Profile Details */}
            </div>
          </div>
        </div>
        {/* Student Dashboard */}
        <ToastContainer autoClose={1000} hideProgressBar={true} />
        <Footer />
      </div>
    </>
  );
}
