import React from "react";
import axios from "../../../../api/axios";

const PaginationLink = ({ nPages, currentPage, setCurrentPage, link, setData,user,subuser }) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const handleSetData = async (pgNumber) => {
    const res = await axios.get(`${link}/${user}/${subuser}?page=${pgNumber}`, config);
    setCurrentPage(pgNumber);
    setData(res.data.video.data);
  };

  const nextPage = async () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
      const res = await axios.get(`${link}/${user}/${subuser}?page=${currentPage + 1}`, config);
      setData(res.data.video.data);
    }
  };
  const prevPage =async () => {
    if (currentPage !== 1) {
        setCurrentPage(currentPage - 1)
        const res = await axios.get(`${link}/${user}/${subuser}?page=${currentPage - 1}`, config);
        setData(res.data.video.data);
    };
  };
  return (
    <nav>
      <ul className="pagination lms-page d-flex align-items-center justify-content-center">
        <li className="page-item prev">
          <a className="page-link" onClick={prevPage} href="#">
          <i className="fas fa-angle-left" />
          </a>
        </li>
        {pageNumbers.map((pgNumber) => (
          <li
            key={pgNumber}
            className={`${currentPage == pgNumber ? "page-item first-page active" : "page-item first-page"} `}
          >
            <a
              onClick={() => handleSetData(pgNumber)}
              className="page-link"
              href="#"
            >
              {pgNumber}
            </a>
          </li>
        ))}
        <li className="page-item next">
          <a className="page-link" onClick={nextPage} href="#">
          <i className="fas fa-angle-right" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default PaginationLink;
