import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../footer";
import AdminInstructorSidebar from "../adminSidebar";
import AuthContext from "../../../context/AuthProvider";
import { Button } from "react-bootstrap";
import * as FileSaver from "file-saver";
import Switch from "react-switch";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import AdminHeader from "../adminHeader";
import "./index.css";
import Form from "react-bootstrap/Form";
import axios from "../../../api/axios";
import { Tooltip, Input } from "antd";
import PaginationQuestion from "../../pages/course/addCourse/PaginationQuestion";

const QUESTIONAPI = "/api/test-series/testseries-question-search";
const SHOWQUESTION = "/api/test-series/show-testseries-question";
const DELETEQUESTION = "/api/test-series/delete-question";
const QUESSTIONSTATUS = "/api/test-series/status-question";
const TESTEXCEL = "/api/test-series/download/question/excel/sample";
const IMPORT = "/api/test-series/word/import-questions_chapters";
const DownloadWord = "/api/export/wordfile/download";
import { Modal } from "antd";
import { notification, Space } from "antd";

const { confirm } = Modal;

export const AdminTestListUsersGroup = () => {
  const { auth } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [id, setId] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [showTest, setShowTest] = useState(false);
  const [error, seterror] = useState("");
  const [submoduleid, setUubmoduleid] = useState(null);
  const [list, setList] = useState([]);
  const [last_page, setLast_page] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(null);
  const [sub,setsub]=useState(null)
  const [loading, setLoading] = useState(false);
  const { user, subuser, uid } = useParams();
  const [message, setmessage] = useState("No Data");
  const [subModule, setSubModule] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [word, setWord] = useState(false);
  const inputFile = useRef(null);
  const[page,setpage]=useState(1)

  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const config1 = {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "blob" },
    responseType: "arraybuffer",
  };
  const showQuestion = async (page) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${SHOWQUESTION}/${user}/${subuser}?page=${page}`,
        config
      );
      setModuleName(response?.data?.module?.name);
      setLast_page(response?.data?.allQuestion?.last_page);
      setData(response?.data?.allQuestion?.data);
      setList(response?.data?.allQuestion?.data);
      setUubmoduleid(response?.data?.submoduleid);
      // setCurrentPage(page);
      const subModuleData = response?.data?.allQuestion?.data;
      const singleValue = subModuleData?.find((item) => item.id === item.id);
      setSubModule(singleValue?.sub_module_id);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      openNotificationWithIcon2("error");
    }
  };
  useEffect(() => {
    showQuestion();
  }, []);
  const subjectOption=async()=>{
    try {
        const result =await axios.get(`/api/test-series/list-submodule-question/${user}`,config)
        setsub(result.data.subModule.data);
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          openNotificationWithIcon1("error",'Internal Server Error','red')
    }
   
  }
  useEffect(() => {
    subjectOption()
  }, []);
  const navigate = useNavigate();
  const handleEdit = (id) => {
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(
      `/admin/testseries/user/edit-question/group/${ciphertexts}/${user}/${subuser}/${encodeURIComponent (uid)}`
    );
  };

  const handleClose = () => setShow(false);
  const handleCloseImoprt = () => setShowImport(false);
  const handleCloseTest = () => setShowTest(false);
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      QUESSTIONSTATUS,
      { id: id, status: result },
      config
    );
    if (response) {
      openNotificationWithIcon1("success");
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    // toast.success("Status Change");
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
  };

  const [file, setFile] = useState(null);
  const openNotificationWithIconImport = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Question Imported</strong>
        </div>
      ),
      duration: 3,
    });
  };
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };

  const handleDelteImoprt = async () => {
    const formData = new FormData();
    formData.append("question_import", file);
    formData.append("sub-module", submoduleid);
    formData.append("type", 1);
    seterror("");

    const customConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.post(IMPORT, formData, customConfig);
      openNotificationWithIconImport("success");
      showQuestion();
      if (response) {
        setShowImport(false);
        setIsModalVisible(false);
        if (inputFile.current) {
          inputFile.current.value = "";
          inputFile.current.type = "text";
          inputFile.current.type = "file";
        }
      }
    } catch (error) {
      console.log(error);
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }
      seterror(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      if (error.response.status === 500){
        openNotificationWithIcon2("error");
      }{
        openNotificationWithIcon2("error");

      }
    }
  };

  const downloadFileWord = async () => {
    try {
      const response = await axios.get(DownloadWord, config1);
      const blob = new Blob([response?.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "sample.docx";
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      openNotificationWithIcon2("error");
    }
    setWord(false);
  };
  const exportCsv = (excel) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const data = new Blob([excel], { type: "application/vnd.ms-excel" });
    FileSaver.saveAs(data, "Question" + fileExtension);
  };
  const handleShow = async (id) => {
    setShow(true);
    setId(id);
  };
  const handleShowImport = () => {
    setShowImport(true);
    setIsModalVisible(true);
  };
  const handleShowTest = async (id) => {
    setShowTest(true);
    // setId(id);
  };
  const DeleteTestSeries = async () => {
    if (id) {
      try {
        setLoading(true);
        const resonse = await axios.get(`${DELETEQUESTION}/${id}`, config);
        // console.log("resonse", resonse);
        toast.warning("Question Deleted");
        setLoading(false);
        showQuestion();
        setShow(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      }
    }
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Question Deleted</strong>
        </div>
      ),
      duration: 1,

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this question?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETEQUESTION}/${id}`, config);
          openNotificationWithIcon("success");
          setLoading(false);
          showQuestion();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          openNotificationWithIcon2("error");
        }
      },
      onCancel() {},
    });
  }
  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      const res = await axios.post(
        QUESTIONAPI,
        { name: searchValue, submodule: submoduleid },
        config
      );
      if (res.data.question.length > 0) {
        setList(res.data.question);
      }
      if (res.data.question.length == 0) {
        setList(res.data.question);
      }
      if (searchValue.length === 0) {
        const res = await axios.get(
          `${SHOWQUESTION}/${user}/${subuser}?page=${currentPage}`,
          config
        );
        setList(res.data.subModule.data);
      }
    } catch (error) {
      // console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  const itemsPerPage = 10;

  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  // useEffect(() => {
  //   windowTop(0, 0);
  // }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader activeMenu={"Test"} />
      <div className="page-content instructor-page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3 style={{ textTransform: "capitalize" }}>
                          Questions ({moduleName})
                        </h3>
                        <nav
                          aria-label="breadcrumb"
                          style={{ marginTop: "0.5em", marginBottom: "-1.6em" }}
                        >
                          <ol class="breadcrumb">
                            <li
                              class="breadcrumb-item "
                              onClick={() => navigate("/admin/test-series")}
                              aria-current="page"
                              style={{
                                cursor: "pointer",
                                marginRight: "0.5em",
                              }}
                            >
                              Test Series{" "}
                            </li>
                            <li
                              className="breadcrumb-item p-0 "
                              style={{
                                fontWeight: "normal",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let ciphertext = CryptoJS.AES.encrypt(
                                  JSON.stringify(submoduleid),
                                  "my-secret-key@admin8623009959"
                                ).toString();
                                let ciphertexts =
                                  encodeURIComponent(ciphertext);
                                navigate(
                                  `/admin/test-series/${user}/${ciphertexts}`
                                );
                              }}
                              aria-current="page"
                            >
                              Test
                            </li>
                            <li
                              class="breadcrumb-item"
                              aria-current="page"
                              onClick={()=>navigate(-1)}
                              style={{ cursor: "pointer",fontWeight:'normal' }}
                            >
                              <a>Show Group Testseries</a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                              style={{ cursor: "pointer" ,marginLeft:'-.75em'}}
                            >
                              <a>Questions</a>
                            </li>
                          </ol>
                        </nav>
                      </div>
                      <div className="comman-space pb-0">
                        <div
                          className="settings-tickets-blk course-instruct-blk table-responsive"
                          id="table-value"
                        >
                          <div className="instruct-search-blk">
                            <div className="show-filter choose-search-blk">
                              <form action="#">
                                <div
                                  className="row  gx-2 align-items-center"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <div className="col-md-3 col-item">
                                    <div
                                      className=" search-group justify-content-start"
                                      id="search-btn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        style={{
                                          position: "absolute",
                                          left: "7px",
                                          color: "rgb(246, 106, 98)", // Change the color to yellow
                                        }}
                                      >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line
                                          x1="21"
                                          y1="21"
                                          x2="16.65"
                                          y2="16.65"
                                        ></line>
                                      </svg>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Input Search Text"
                                        allowClear
                                        onChange={Filter}
                                        onKeyDown={handleKeyPress}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-8 col-lg-8 col-item ">
                                    <div
                                      className="button-container"
                                      style={{ justifyContent: "flex-end" }}
                                    >
                                      {/* <Button onClick={() => exportCsv(excel)} className="custom-button"
                                        style={{ border: " 1px solid rgb(0, 128, 0)", color: "rgb(0, 128, 0)", backgroundColor: "white" }}
                                      >
                                        Export Sample
                                      </Button>
                                      <Button onClick={() => handleShowImport()} className="custom-button"
                                        style={{ border: " 1px solid rgb(242, 9, 9)", color: "rgb(242, 9, 9)", backgroundColor: "white" }}

                                      >
                                        Import Excel
                                      </Button> */}
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (!word) {
                                            downloadFileWord();
                                            setWord(true);
                                          }
                                        }}
                                        className="custom-button-total"
                                      >
                                        Export Word Sample
                                      </button>
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleShowImport();
                                        }}
                                        className="custom-button-free"
                                      >
                                        Import Word Sample
                                      </button>
                                      <Button
                                        onClick={() =>
                                          navigate(
                                            `/admmin/testseries/add-question/group/${user}/${subuser}/${encodeURIComponent(uid)}`
                                          )
                                        }
                                        className="custom-button"
                                        style={{}}
                                      >
                                        <i className="fa-solid fa-plus"></i>{" "}
                                        Create New
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>

                          {/* Referred Users */}
                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>Delete Question</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Are you sure want to delete this question?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                              <Button
                                variant="primary"
                                onClick={DeleteTestSeries}
                              >
                                Delete
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          <Modal
                            title="Import Question"
                            okButtonProps={{
                              style: { backgroundColor: "#FF875A" },
                              children: "Import",
                            }}
                            visible={isModalVisible}
                            okText="Import"
                            onOk={() => {
                              handleDelteImoprt();
                            }}
                            onCancel={() => {
                              setIsModalVisible(false);
                              seterror("");
                            }}
                          >
                            <div class="mb-3">
                              <label for="formFile" class="form-label">
                                only support word file{" "}
                              </label>
                              <input
                                class="form-control"
                                autoFocus
                                onChange={(e) => setFile(e.target.files[0])}
                                type="file"
                                id="formFile"
                                ref={inputFile}
                              />
                              {error && (
                                <span style={{ color: "red" }}>{error}</span>
                              )}
                            </div>
                          </Modal>
                          <Modal show={showImport} onHide={handleCloseImoprt}>
                            <Modal.Header closeButton>
                              <Modal.Title> Import Question </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Upload File</Form.Label>
                                  <Form.Control
                                    type="file"
                                    autoFocus
                                    onChange={(e) => setFile(e.target.files[0])}
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  {/* <Form.Label>Example textarea</Form.Label>
                                  <Form.Control as="textarea" rows={3} /> */}
                                </Form.Group>
                              </Form>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={handleCloseImoprt}
                              >
                                Close
                              </Button>
                              <Button
                                variant="primary"
                                onClick={handleDelteImoprt}
                              >
                                Import
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          <table className="table table-nowrap ">
                            <thead style={{ textTransform: "capitalize" }}>
                              <tr className="text-center">
                                <th>SR. NO.</th>
                                <th>QUESTION</th>
                                <th>TYPE</th>
                                <th>CHAPTER</th>
                                <th>SUBJECT</th>
                                <th>STATUS</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            {loading === true ? (
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                  position: "fixed",
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  left: 0,
                                  backgroundColor: "rgba(255, 255, 255, 0.6)",
                                }}
                              >
                                <div
                                  class="spinner-border text-warning"
                                  role="status"
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              list?.map((item, i) => {
                                const serialNumber =
                                  (currentPage - 1) * itemsPerPage + i + 1;
                                return (
                                  <tbody
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <tr className="text-center">
                                      <td>{serialNumber}</td>
                                      <td
                                        style={{
                                          whiteSpace: " normal !important",
                                          wordWrap: "break-word",
                                          maxWidth: "450px",
                                        }}
                                      >
                                        <Tooltip title={item.name}>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: item?.name.substr(0, 40),
                                            }}
                                          />
                                        </Tooltip>
                                      </td>
                                      <td>
                                        {item?.question_type_id == "1"
                                          ? "MCQ"
                                          : "Subjective"}
                                      </td>
                                      <td>
                                        {item?.chapter === ""
                                          ? "None"
                                          : item?.chapter}
                                      </td>
                                      <td> {item?.subject==undefined?'None':sub?.filter(e=>e?.id==item?.subject)[0].name }</td>
                                      <td>
                                        {" "}
                                        <Switch
                                          onChange={() => {
                                            handleChange(
                                              item?.id,
                                              item?.status
                                            );
                                          }}
                                          checked={
                                            item?.status === "1" ? true : false
                                          }
                                          onColor="#86d3ff"
                                          onHandleColor="#2693e6"
                                          handleDiameter={20}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                          height={15}
                                          width={38}
                                          className="react-switch"
                                        />
                                      </td>
                                      <td>
                                        <Tooltip title="Edit">
                                          <button
                                            onClick={() => handleEdit(item.id)}
                                            type="button"
                                            class="btn btn-secondary"
                                          >
                                            <i className="fa-regular fa-pen-to-square"></i>
                                          </button>{" "}
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                          <Button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => showConfirm(item.id)}
                                          >
                                            <i className="fa-regular fa-trash-can"></i>
                                          </Button>
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })
                            )}
                          </table>
                          {list?.length <= 0 ? (
                            <h5 class="text-center">{message}</h5>
                          ) : (
                            ""
                          )}
                          {/* Referred Users */}
                        </div>
                        <div className="fixtable">
                          {list?.length <= 0 ? (
                            ""
                          ) : (
                            <PaginationQuestion
                              nPages={last_page}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              link={SHOWQUESTION}
                              setData={setList}
                              user={user}
                              subuser={subuser}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
            <ToastContainer />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
