import CryptoJS from "crypto-js";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../context/AuthProvider";
import axios from "../../../../api/axios";
const VIEWVIDEO = "/api/student/view-video";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Import } from "../../../imagepath";
import * as FileSaver from "file-saver";
import "./index.css";
import { Modal, Tooltip } from "antd";

import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import axiosInstance from "../../../../api/axios1";
const DetailsContent = () => {
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  const { id } = useParams();
  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [playbackInfo, setPlaybackInfo] = useState("");
  const [videoContent, setvideoContent] = useState(null);
  const [url, seturl] = useState("");
  const [res, setRes] = useState(null);
  const token = localStorage.getItem("tokenStudent");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const viewVidos = async () => {
    setLoading(true);
    const response = await axiosInstance.get(`${VIEWVIDEO}/${decryptedData}`, config);
    setRes(response.data.res);
    setvideoContent(response.data.videoContent);
    setOtp(response.data.otp);
    setPlaybackInfo(response.data.playbackInfo);
    setLoading(false);
  };
  useEffect(() => {
    viewVidos();
  }, []);
  // console.log(res);
  const YouTubeGetID = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };
  const ytcode = YouTubeGetID(res?.link);
  let vimeo_Reg = /(videos|video|channels|\.com)\/([\d]+)/;

  // const [open, setOpen] = useState(false);
  // const [open2, setOpen2] = useState(false);
  // const [open3, setOpen3] = useState(false);
  // const [open4, setOpen4] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();
  const handleId = (id) => {
    // console.log(id);
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/student/view-video/${ciphertexts}`);
  };
  const style = {
    videoWrapper: {
      position: "relative",
      padding: "56.25% 0 0 0 ",
    },
    iframe: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  };


const getFileFromUrl = async (url, name) => {
  try {
    const response = await axiosInstance.get(url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf', // Specify the Content-Type header
      },
    });

    const fileURL = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');
    link.href = fileURL;
    link.download = name;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error fetching the file:', error);
  }
};

  
  

  const handleDownload = (urls) => {
    // window.open(urls)
    FileSaver.saveAs(urls);
  };
  return (
    <>
      <section className="page-content course-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {/* Overview */}
              <div className="card overview-sec">
                <div className="card-body">
                  <h5 className="subs-title">Overview</h5>
                  <div
                    className=""
                    style={{
                      borderBottom: "2.3px solid #FF8D00",
                      width: "95px",
                      marginTop: "-8px",
                      marginRight: "3px",
                    }}
                  ></div>
                  <h6 style={{ marginTop: "10px" }}>Video Description</h6>
                  <div
                    style={{ textTransform: "capitalize" }}
                    dangerouslySetInnerHTML={{
                      __html: res?.description,
                    }}
                  />
                  {/* <h6>What you'll learn</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Become a UX designer.</li>
                        <li>You will be able to add UX designer to your CV</li>
                        <li>Become a UI designer.</li>
                        <li>Build &amp; test a full website design.</li>
                        <li>Build &amp; test a full mobile app.</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>
                          Learn to design websites &amp; mobile phone apps.
                        </li>
                        <li>You'll learn how to choose colors.</li>
                        <li>Prototype your designs with interactions.</li>
                        <li>Export production ready assets.</li>
                        <li>All the techniques used by UX professionals</li>
                      </ul>
                    </div>
                  </div>
                  <h6>Requirements</h6>
                  <ul className="mb-0">
                    <li>
                      You will need a copy of Adobe XD 2019 or above. A free
                      trial can be downloaded from Adobe.
                    </li>
                    <li>No previous design experience is needed.</li>
                    <li className="mb-0">
                      No previous Adobe XD skills are needed.
                    </li>
                  </ul> */}
                </div>
              </div>
              {/* /Overview */}
              {/* Course Content */}
              {/* <div className="card content-sec">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5 className="subs-title">Course Content</h5>
                    </div>
                    <div className="col-sm-6 text-sm-end">
                      <h6>92 Lectures 10:56:11</h6>
                    </div>
                  </div>
                  <div className="course-card">
                    <h6 className="cou-title">
                      <Link className="collapsed" data-bs-toggle="collapse" to="#collapseOne" aria-expanded={open} onClick={()=> setOpen(!open)} aria-controls="example-collapse-text">
                        In which areas do you operate?
                      </Link>
                    </h6>
                    
                    <div id="collapseOne" className="card-collapse collapse"  >
                      <ul>
                        <li>
                          <p>
                            <img src={Play} alt="" className="me-2"/>
                            Lecture1.1 Introduction to the User ExperienceCourse
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img src={Play} alt="" className="me-2"/>
                            Lecture1.2 Exercise: Your first design challenge
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img src={Play} alt="" className="me-2"/>
                            Lecture1.3 How to solve the previous exercise
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img src={Play} alt="" className="me-2"/>
                            Lecture1.3 How to solve the previous exercise
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img src={Play} alt="" className="me-2"/>
                            Lecture1.5 How to use text layers effectively
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                       </ul>
                    </div>
                    
                  </div>
                  <div className="course-card">
                    <h6 className="cou-title">
                      <Link className="collapsed" data-bs-toggle="collapse" to="#course2" aria-expanded={open2} onClick={()=> setOpen2(!open2)} aria-controls="example-collapse-text">
                        The Brief
                      </Link>
                    </h6>
                    
                    <div id="course2" className="card-collapse collapse">
                      <ul>
                        <li>
                          <p>
                            <img src={Play} alt="" className="me-2"/>
                            Lecture1.1 Introduction to the User Experience Course
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img src={Play} alt="" className="me-2"/>
                            Lecture1.2 Exercise: Your first design challenge
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture1.3 How to solve the previous exercise
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture1.3 How to solve the previous exercise
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture1.5 How to use text layers effectively
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                  <div className="course-card">
                    <h6 className="cou-title">
                      <Link
                        className="collapsed"
                        data-bs-toggle="collapse"
                        to="#course3"
                        aria-expanded={open3} aria-controls="example-collapse-text"
                      >
                        Wireframing Low Fidelity
                      </Link>
                    </h6>
                    
                    <div
                      id="course3"
                      className="card-collapse collapse"
                      
                    >
                      <ul>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture1.1 Introduction to the User Experience
                            Course
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture1.2 Exercise: Your first design challenge
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture1.3 How to solve the previous exercise
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture1.3 How to solve the previous exercise
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture1.5 How to use text layers effectively
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                  <div className="course-card">
                    <h6 className="cou-title mb-0">
                      <Link
                        className="collapsed"
                        data-bs-toggle="collapse"
                        to="#coursefour"
                        aria-expanded={open4}  aria-controls="example-collapse-text"
                      >
                        Type, Color &amp; Icon Introduction
                      </Link>
                    </h6>
                    
                    <div
                      id="coursefour"
                      className="card-collapse collapse"
                      style={{}}
                    >
                      <ul>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture4.1 Introduction to the User Experience
                            Course
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture4.2 Exercise: Your first design challenge
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture4.3 How to solve the previous exercise
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture4.4 How to solve the previous exercise
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                        <li>
                          <p>
                            <img
                              src={Play}
                              alt=""
                              className="me-2"
                            />
                            Lecture4.5 How to use text layers effectively
                          </p>
                          <div>
                            <Link to="#">Preview</Link>
                            <span>02:53</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                </div>
              </div> */}
              {/* /Course Content */}
              <Modal
                title="View Pdf"
                okButtonProps={{
                  style: { backgroundColor: "#FF875A" },
                  children: "ok",
                }}
                open={isModalVisible}
                okText="ok"
                width={1000}
                onOk={() => {
                  setIsModalVisible(false);
                }}
                onCancel={() => {
                  setIsModalVisible(false);
                }}
              >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                  <div id="pdfviewer">
                    <Viewer fileUrl={`${url}`} />
                  </div>
                </Worker>
                {/* <Document file={`"https://cors-anywhere.herokuapp.com/${url}`} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} />
                </Document>
                <div>
                  <div className="pagec">
                    Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                    {numPages || "--"}
                  </div>
                  <div className="buttonc">
                    <button
                      type="button"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                      className="Pre"
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Next
                    </button>
                  </div>
                </div> */}
              </Modal>
              {/* Instructor */}
              {/* <div className="card instructor-sec">
                <div className="card-body">
                  <h5 className="subs-title">About the instructor</h5>
                  <div className="instructor-wrap">
                    <div className="about-instructor">
                      <div className="abt-instructor-img">
                        <Link to="/instructor-profile">
                          <img
                            src={User1}
                            alt="img"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="instructor-detail">
                        <h5>
                          <Link to="/instructor-profile">Nicole Brown</Link>
                        </h5>
                        <p>UX/UI Designer</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star me-1" />
                      <span className="d-inline-block average-rating">
                        4.5 Instructor Rating
                      </span>
                    </div>
                  </div>
                  <div className="course-info d-flex align-items-center">
                    <div className="cou-info">
                      <img src={Play} alt="" />
                      <p>5 Courses</p>
                    </div>
                    <div className="cou-info">
                      <img src={Icon1} alt="" />
                      <p>12+ Lesson</p>
                    </div>
                    <div className="cou-info">
                      <img src={Icon2} alt="" />
                      <p>9hr 30min</p>
                    </div>
                    <div className="cou-info">
                      <img src={People} alt="" />
                      <p>270,866 students enrolled</p>
                    </div>
                  </div>
                  <p>
                    UI/UX Designer, with 7+ Years Experience. Guarantee of High
                    Quality Work.
                  </p>
                  <p>
                    Skills: Web Design, UI Design, UX/UI Design, Mobile Design,
                    User Interface Design, Sketch, Photoshop, GUI, Html, Css,
                    Grid Systems, Typography, Minimal, Template, English,
                    Bootstrap, Responsive Web Design, Pixel Perfect, Graphic
                    Design, Corporate, Creative, Flat, Luxury and much more.
                  </p>
                  <p>Available for:</p>
                  <ul>
                    <li>1. Full Time Office Work</li>
                    <li>2. Remote Work</li>
                    <li>3. Freelance</li>
                    <li>4. Contract</li>
                    <li>5. Worldwide</li>
                  </ul>
                </div>
              </div> */}
              {/* /Instructor */}
              {/* Reviews */}
              {/* <div className="card review-sec">
                <div className="card-body">
                  <h5 className="subs-title">Reviews</h5>
                  <div className="instructor-wrap">
                    <div className="about-instructor">
                      <div className="abt-instructor-img">
                        <Link to="instructor-profile">
                          <img
                            src={User1}
                            alt="img"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                      <div className="instructor-detail">
                        <h5>
                          <Link to="/instructor-profile">Nicole Brown</Link>
                        </h5>
                        <p>UX/UI Designer</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star me-1" />
                      <span className="d-inline-block average-rating">
                        4.5 Instructor Rating
                      </span>
                    </div>
                  </div>
                  <p className="rev-info">
                    “ This is the second Photoshop course I have completed with
                    Cristian. Worth every penny and recommend it highly. To get
                    the most out of this course, its best to to take the
                    Beginner to Advanced course first. The sound and video
                    quality is of a good standard. Thank you Cristian. “
                  </p>
                  <Link to="#" className=" btn-reply">
                    <FeatherIcon icon="corner-up-left"/>
                     Reply
                  </Link>
                </div>
              </div> */}
              {/* /Reviews */}
              {/* Comment */}
              {/* <div className="card comment-sec">
                <div className="card-body">
                  <h5 className="subs-title">Post A comment</h5>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Subject"
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        rows={4}
                        className="form-control"
                        placeholder="Your Comments"
                        defaultValue={""}
                      />
                    </div>
                    <div className="submit-section">
                      <button className=" submit-btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div> */}
              {/* /Comment */}
            </div>
            <div className="col-lg-4">
              <div className="sidebar-sec">
                {/* Video */}
                <div
                  className="video-sec vid-bg "
                  style={{
                    marginTop: "4rem",
                    marginLeft: "0.5rem",
                  }}
                >
                  <div className="card">
                    <div className="card-body">
                      {/* <Link
                        // to="https://www.youtube.com/embed/1trvO6dqQUI"
                        className="video-thumbnail"
                        data-fancybox=""
                      >
                        <div className="play-icon">
                          <i className="fa-solid fa-play" />
                        </div>
                        <img
                          className=""
                          style={{height:'150px'}}
                          src={`https://lmscode.stellarflux.in/${res?.thumbnail_path}/${res?.thumbnail}`}
                          alt=""
                        />
                      </Link> */}

                      {loading === true ? (
                        <div style={{ display: "block" }} id="cover-spin"></div>
                      ) : res?.vid_source == "0" ? (
                        <div className=" pb-0">
                          <div className="settings-referral-blk table-responsive overflow-hidden">
                            <div style={style.videoWrapper}>
                              <Link
                                style={style.iframe}
                                data-fancybox=""
                                // to={`https://www.youtube.com/embed/${ytcode}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                              >
                                <img
                                  className="img-fluid"
                                  src={`${res?.thumbnail_path}/${res?.thumbnail}`}
                                  alt="Video Thumbnail"
                                  style={{ height: "200px", width: "358px" }}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : res?.vid_source == "1" ? (
                        <div className="pb-0">
                          <div className="settings-referral-blk table-responsive overflow-hidden">
                            <div style={style.videoWrapper}>
                              <Link
                                style={style.iframe}
                                data-fancybox=""
                                title="Vimeo video player"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                              >
                                <img
                                  className="img-fluid"
                                  src={`${res?.thumbnail_path}/${res?.thumbnail}`}
                                  alt="Video Thumbnail"
                                  style={{ height: "200px", width: "358px" }}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="pb-0">
                          <div className="settings-referral-blk table-responsive">
                            <div style={style.videoWrapper}>
                              <Link
                                // to={`https://player.vdocipher.com/v2/?otp=${otp}&playbackInfo=${playbackInfo}`}
                                data-fancybox=""
                                title="YouTube video player"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture; encrypted-media"
                                allowFullScreen
                              >
                                <img
                                  className="img-fluid"
                                  src={`${res?.thumbnail_path}/${res?.thumbnail}`}
                                  alt="Video Thumbnail"
                                  style={{ height: "200px", width: "358px" }}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* <div
                        className="video-details"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="course-fee">
                          <h2>FREE</h2>
                          <p><span>$99.00</span> 50% off</p>
                        </div>
                        <div className="row gx-2">
                          <div className="col-md-6 addHeart">
                            <Link
                              to="/course-wishlist"
                              className=" btn btn-wish w-100"
                            >
      
                              <FeatherIcon icon="heart"/>
                              Add to Wishlist
                            </Link>
                          </div>
                          <div className="col-md-6 addHeart">
                            <Link
                              to="#"
                              className="btn btn-wish w-100"
                            >
                       
                              <FeatherIcon icon="share-2"/>
                              
                              Share
                            </Link>
                          </div>
                        </div>
                        <a
                          onClick={() => handleId(res?.id)}
                          //   to="checkout"
                          className="btn btn-enroll w-100"
                        >
                          <h5 className="text-white"> Play Now</h5>
                        </a>
                      </div> */}
                      <div className="video-details">
                        {/* <div className="course-fee">
                          <h2>FREE</h2>
                          <p>
                            <span>$99.00</span> 50% off
                          </p>
                        </div> */}
                        {/* <div className="row gx-2">
                          <div className="col-md-6 addHeart">
                            <Link
                              to="/course-wishlist"
                              className=" btn btn-wish w-100"
                            >
                              <i className="feather-heart" />
                              <FeatherIcon icon="heart" />
                              Add to Wishlist
                            </Link>
                          </div>
                          <div className="col-md-6 addHeart">
                            <Link to="#" className="btn btn-wish w-100">
                              <i className="feather-share-2" />
                              <FeatherIcon icon="share-2" />
                              Share
                            </Link>
                          </div>
                        </div> */}
                        {ytcode ? (
                          <a
                            className="btn btn-enroll w-100"
                            onClick={() => handleId(res?.id)}
                            style={{ fontSize: "18px" }}
                          >
                            Play Now
                          </a>
                        ) : (
                          <a
                            className="btn btn-enroll w-100"
                            onClick={() => handleId(res?.id)}
                            style={{ fontSize: "18px" }}
                          >
                            Play Now
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Video */}
                {/* Include */}
                <div className="card include-sec">
                  <div className="card-body">
                    <div className="cat-title">
                      <h4>Pdf</h4>
                    </div>
                    <ul>
                      {videoContent?.length > 0
                        ? videoContent?.map((item) => {
                            return (
                              <li
                                key={item?.id}
                                style={
                                  {
                                    // display: "flex",
                                    // justifyContent: "space-evenly",
                                    // alignItems:'center'
                                  }
                                }
                              >
                                <Tooltip title='Download'>
                                  <img src={Import} className="me-2" alt="" />{" "}
                                  {/* <span
                                    onClick={() =>
                                      getFileFromUrl(item?.url, "SamplePDF.pdf")
                                    }
                                    role="button"
                                  >{`pdf user download ${item?.id}`}</span>{" "} */}
                                  <a
  href={item?.url}
  target="_blank"
  download="SamplePDF.pdf"
  onClick={(e) => {
    e.stopPropagation(); // Prevent any other click handlers from being triggered
    // getFileFromUrl(item?.url, "SamplePDF.pdf");
  }}
  role="button"
>
  {`pdf user download ${item?.id}`}
</a>

                                </Tooltip>
                                {/* <span
                              className="text-dark"
                              onClick={() => {
                                setIsModalVisible(true);
                                seturl(item?.url);
                              }}
                            >
                              <i
                                style={{ display: "block" }}
                                class="fa fa-eye"
                                aria-hidden="true"
                              ></i>
                            </span>{" "} */}
                              </li>
                            );
                          })
                        : "No Pdf For this Video"}
                    </ul>
                  </div>
                </div>
                {/* /Include */}
                {/* Features */}
                {/* <div className="card feature-sec">
                  <div className="card-body">
                    <div className="cat-title">
                      <h4>Includes</h4>
                    </div>
                    <ul>
                      <li>
                        <img
                          src={Users}
                          className="me-2"
                          alt=""
                        />{" "}
                        Enrolled: <span>32 students</span>
                      </li>
                      <li>
                        <img
                          src={Timer2}
                          className="me-2"
                          alt=""
                        />{" "}
                        Duration: <span>20 hours</span>
                      </li>
                      <li>
                        <img
                          src={Chapter}
                          className="me-2"
                          alt=""
                        />{" "}
                        Chapters: <span>15</span>
                      </li>
                      <li>
                        <img
                          src={Video2}
                          className="me-2"
                          alt=""
                        />{" "}
                        Video:<span> 12 hours</span>
                      </li>
                      <li>
                        <img
                          src={Chart}
                          className="me-2"
                          alt=""
                        />{" "}
                        Level: <span>Beginner</span>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* /Features */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailsContent;
