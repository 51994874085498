import React, { useState, useContext, useEffect, useRef } from "react";


import Footer from "../../footer";
import { AudioOutlined } from "@ant-design/icons";
import { Input, Select, Tooltip } from "antd";
import { Video2 } from "../../imagepath";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";
import CryptoJS from "crypto-js";
import axios from "../../../api/axios";
// import "./style.css";
import { notification, Space } from "antd";
import AdminHeader from "../adminHeader";
const RECORDEDVIDEO = "api/admin/vimeo/subdirs";
const DELETEVIDEO = "/api/recorded-video/delete";
const RECORDEDSTATUS = "/api/recorded-video/status";
const VIMEOLISTSEARCH = "/api/admin/vimeo/videos/search";
const VIMEOIMPORTLISTFLODER="/api/admin/vimeo/import/category"

const IMPORT = "/api/recorded-video/pdf-file-upload";

import { Link, useNavigate, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "react-switch";
import { Modal } from "antd";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import def from "ajv/dist/vocabularies/applicator/dependencies";
import AdminInstructorSidebar from "../adminSidebar";
import PaginationVimeo from "../../pages/course/addCourse/PaginationVimeo";
import PaginationVimeoList from "../../pages/course/addCourse/PaginationVimeoList";
import Form from "react-bootstrap/Form";
import VimeoStudent from "../../pages/course/addCourse/Vimeo";
const VIMEOSUBMODUULE = "/api/recorded-video/submodule-list";
const COURSEVIDEO = "/api/admin/course/active";
const VIDEOMODULE = "/api/recorded-video/module-list";
const VIMEOIMPORTLIST="/api/admin/vimeo/import/category/videos"
const { confirm } = Modal;
import Folder from "../../../assets/img/user/folder.png"
import Video from "../../../assets/img/user/youtube.png"


const  AddImportVimeoSubModule=() =>{
    const { uri } = useParams();
    // console.log(uri,"uri")
  const { Search } = Input;
  const [list, setList] = useState(null);
  const inputFile = useRef(null);
  const [last_page, setLast_page] = useState("");
  const [file, setFile] = useState(null);
  const [records1, setRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);

  const [text, settext] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [error, seterror] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [id, setId] = useState("");
  const [messages, setmessage] = useState("No Data");
  const [uid, setuid] = useState("");
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [isModalVisibleForm1, setIsModalVisibleForm1] = useState(false);

  const [vimeovideoid,setVimeoid]=useState([])
  const [checked, setChecked] = useState([
    
  ]);
  var itemType =""    // this itemType is using for to know the type of folder or video for checking purpose

  // console.log(checked,"checked kkk")
  const [subid, setsubid] = useState("");
  const [course, setCourses] = useState([]);
  const [submodule, setsubmodule] = useState(null);
  const [video, setVideo] = useState([]);
  const [value, setValue] = useState("");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const playerRef = useRef(null);



  const handleClose = () => setShow(false);

  const navigate = useNavigate();
  

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const openNotificationWithIcon2 = (type,message) => {

    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const fecthRecordedList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(`${RECORDEDVIDEO}/${uri}?page=${currentPage}`, config);
      setLast_page(resonse?.data?.pagging?.last);
      setList(resonse.data?.dir);
      setRecords(resonse.data?.dir);
      setLoading(false);
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon2("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error",error?.resonse?.data?.message);
      }
    }
  };
  useEffect(() => {
    fecthRecordedList();
  }, []);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type) => {

    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Vimeo video Imported</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type, text, col) => {
  
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text} </strong>
        </div>
      ),
      duartion: 1,
    });
  };

  

  
  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      settext(searchValue);
     
      const res = await axios.get(`${VIMEOLISTSEARCH}/${uri}?userid=88380469&search=${searchValue}`, config);

      if (res.data.dir.length > 0) {
        setList(res.data.dir);
        setLast_page(res.data.pagging.last);
        setmessage("No Data");
      } else {
        // setRecords(data)
      }
      if (res.data.dir.length == 0) {
        setList(res.data.dir);
        setmessage("No Data Found");
      }
      if (searchValue.length === 0) {
        const res = await axios.get(`${RECORDEDVIDEO}/${uri}?page=${currentPage}`, config);
        setList(records1);
        setCurrentPage(1);
        setLast_page(res.data.pagging.last);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon2("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };

  let data = [];

  
 
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const itemsPerPage = 10;
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  // useEffect(() => {
  //   windowTop(0, 0);
  // }, []);
  const fetchApisCourse = async () => {
    try {
      setLoading(true);

      const response = await axios.get(COURSEVIDEO, config);
      setCourses(response?.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
        
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon2("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
    }
  };
  const submoduledata=async()=>{
    try{
      setLoading(true);

    const response= await axios .get(VIMEOSUBMODUULE,config)
    setsubmodule(response?.data);
    setLoading(false);

    } catch(error){
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon2("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      // setLoading(false);

    }

  }
  const fetchApisVideo = async () => {
    try {
      setLoading(true);

      const response = await axios.get(VIDEOMODULE, config);
      setVideo(response?.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon2("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
    }
  };
  useEffect(() => {
    fetchApisCourse();
    fetchApisVideo();
    submoduledata()
  }, []);
  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };
  const handleShowForm1=()=>{
    setIsModalVisibleForm1(true);

  }
  let vimeo_Reg = /(videos|video|channels|\.com)\/([\d]+)/;
  const handelvimeovideo=(link)=>{
    const vimeoLink = link; // Replace with your Vimeo link

// Extract the video ID from the Vimeo link
const videoId = vimeoLink.split('/').pop();

// Construct the embed URL
const embedUrl = `https://player.vimeo.com/video/${videoId}`;
    setVimeoid(embedUrl)
    handleShowForm();

  }

  const handleCancel = () => {
    // Stop the video when the modal is canceled
    const iframe = playerRef.current;

    // If the iframe exists, pause the video
    if (iframe) {
      iframe.contentWindow.postMessage(
        '{"method":"pause"}',
        'https://player.vimeo.com'
      );
    }

    // Close the modal
    setIsModalVisibleForm(false);

  };
  const handleAddNotification= () => {
    // Stop the video when the modal is canceled
    const iframe = playerRef.current;

    // If the iframe exists, pause the video
    if (iframe) {
      iframe.contentWindow.postMessage(
        '{"method":"pause"}',
        'https://player.vimeo.com'
      );
    }

    // Close the modal
    setIsModalVisibleForm(false);
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    if (!isCheckAll) {
      const newChecked = records1?.map((li) => ({ 
        title: li?.name, 
        link: li?.link, 
        thumbnail: li?.thumbnail, 
        thumbnail_path: li?.thumbnail_path, 
        video_length: li?.duration 
      }));
  
      newChecked.forEach(item => {
        const parts = item?.thumbnail?.split('/');
        const path = parts[parts.length - 1];
        const linkurl = item?.thumbnail.replace(path, '');
  
        item.thumbnail = linkurl;
        item.thumbnail_path = path;
      });
  
      setChecked(newChecked);
    } else {
      setChecked([]);
    }
  };
  
  const handleChange = (name,duration,link,thumbnail, thumbnail_path,check) => {
    
    
    if (check === true) {
        handleShowForm1();
      }
      const newItem = {
        title: name,
        link: link,
        thumbnail: thumbnail,
        thumbnail_path: thumbnail_path, 
        video_length: duration,
      };
      const arr=[...checked];
    


      if (!arr.some((e) => e?.link === link)) {
        setChecked([...arr, newItem]);
      } else {
        const res = arr.filter((e) => e.link !== link);
        setChecked(res);
      }
  };
  const handleChangeVideo = (uri,check,type) => {

    if (type=="video") {
      openNotificationWithIcon1('error', 'You cannot select both folder and video types at the same time', 'red');
      return;
    }
  
    
    if (check === true) {
        handleShowForm1();
      }
      const newItem = uri
       
      const arr=[...checked];


      if (!arr.some((e) => e == uri)) {
        setChecked([...arr, newItem]);
      } else {
        const res = arr.filter((e) => e !== uri);
        setChecked(res);
      }
  };
 
    // console.log(checked,"checked")
  const handleVimeo = (e) => {     
     e.preventDefault();


    if(checked?.length==0){
      return openNotificationWithIcon2("error","Please Select Vimeo Video")
      
    }
    else{
      // console.log("krishna");
      // event.preventDefault()
      setIsModalVisible1(true);
  
    }
  };

  const handleImoprt = async () => {
    try {
      setLoading(true);
      if(checked[0]?.title){

      
      const responce = await axios.post(
        VIMEOIMPORTLIST,
        {
          category_id: value,
          dirId: checked,
          category_name: subid,
          // videos:checked
        },
        config
      );
      setLoading(false);
      if (responce.status == 200) {
        openNotificationWithIcon("success", responce.data.message);
        setIsModalVisible1(false);
        setChecked([]);
      }
    }
    else{
      const responce = await axios.post(
        VIMEOIMPORTLISTFLODER,
        {
          category_id: value,
          dir_id: checked,
          category_name: subid,
        },
        config
      );
      setLoading(false);
      if (responce.status == 200) {
        openNotificationWithIcon("success", responce.data.message);
        setIsModalVisible1(false);
        setChecked([]);
      }
    }
    } catch (error) {
      console.log(error );
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon2("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error",error?.response?.data?.message      )
        navigate(-1)

      }

    }
  };
  const hadlevimeodrl=(uri)=>{
   
    navigate(`/admin/vimeolist/${uri}`);
  }
  // console.log(list,"lastpage")

  return (
    <div className="main-wrapper ">
      <AdminHeader />
      <div className="page-content ">
        <div
          className="container"
          style={{ maxWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                          <h3>Import Vimeo Videos List</h3>
                          <nav
                            aria-label="breadcrumb"
                            style={{ marginBottom: "-1.6em" }}
                          >
                            <ol class="breadcrumb">
                            <li
                                class="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() => navigate("/admin/recorded-videos-list")}
                              >
                                Recorded videos
                              </li>
                              <li
                                class="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() => navigate("/admin/vimeo-video")}
                              >
                                Import vimeo Video
                              </li>
                              <li
                                class="breadcrumb-item active"
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                              >
                                Import Vimeo Videos List 
                              </li>
                            </ol>
                          </nav>
                        </div>
                  
                        <div className="comman-space pb-0">
                          <div
                            // className="settings-referral-blk table-responsive"
                            // id="table-value"
                          >
                           
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row   align-items-center">
                                    <div className="col-md-6 col-item"                                    >
                                      <div className=" search-group justify-content-start">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search"
                                          allowClear
                                          onChange={Filter}
                                          style={{ width: "100%" }}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-6 col-lg-6 col-item">
                                      <div
                                        className="d-flex justify-content-end"
                                        style={{ gap: "8px",}}
                                      >
                                        <button
                                          onClick={(e) => {
                                            handleVimeo(e);
                                          }}
                                          className="btn btn-primary"
                                          id="custom-button"
                                          style={{ padding: "5px 20px" }}
                                          // disabled={checked?.length<=0}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk">
                            <Modal
                              title="Vimeo Video"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              visible={isModalVisibleForm}
                            okText="Ok"
                              onOk={() => {
                                handleAddNotification();
                              }}
                              onCancel={() => {
                                handleCancel()
                              }}
                            >
             
<div>
<iframe
  title="Vimeo Video"
  src={vimeovideoid}
  style={{width:"470px",height:"300px"}}
  width="140px"
  height="160px"
  frameBorder="0"
  ref={playerRef}
  //  allowFullScreen
></iframe>
</div>
                            </Modal>
                            <Modal
                                title="Import Vimeo"
                                okButtonProps={{
                                  style: { backgroundColor: "#FF875A" },
                                  children: "Import",
                                }}
                                open={isModalVisible1}
                                okText="Import"
                                onOk={() => {
                                  handleImoprt();
                                }}
                                onCancel={() => {
                                  setIsModalVisible1(false);
                                }}
                              >
                                <div class="mb-3">
                                  <label for="formFile" class="form-label">
                                    Select Category
                                  </label>
                                  <Select
                                    showSearch
                                    style={{ width: 480 }}
                                    placeholder="Select a video"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                      setsubid(e);
                                    }}
                                  >
                                    <Option value={"course"}>
                                      {"Courses"}
                                    </Option>
                                    <Option value={"module"}>{"Module"}</Option>
                                    <Option value={"sub_module"}>
                                      {"sub_module"}
                                    </Option>
                                  </Select>
                                </div>
                                {subid === "course" ? (
                                  <div class="mb-3">
                                    <label for="formFile" class="form-label">
                                      Select Course
                                    </label>
                                    <Select
                                      showSearch
                                      style={{ width: 480 }}
                                      placeholder="Select a video"
                                      optionFilterProp="children"
                                      onChange={(e) => {
                                        setValue(e);
                                      }}
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {course?.map((item) => {
                                        return (
                                          <Option value={item?.id}>
                                            {item?.name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {subid === "module" ? (
                                  <div class="mb-3">
                                    <label for="formFile" class="form-label">
                                      Select Module
                                    </label>
                                    <Select
                                      showSearch
                                      style={{ width: 480 }}
                                      placeholder="Select a video"
                                      optionFilterProp="children"
                                      onChange={(e) => {
                                        setValue(e);
                                      }}
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {video?.map((item) => {
                                        return (
                                          <Option value={item?.id}>
                                            {item?.name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {subid === "sub_module" ? (
                                  <div class="mb-3">
                                    <label for="formFile" class="form-label">
                                      Select Submodule
                                    </label>
                                    <Select
                                      showSearch
                                      style={{ width: 480 }}
                                      placeholder="Select a video"
                                      optionFilterProp="children"
                                      onChange={(e) => {
                                        setValue(e);
                                      }}
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {submodule?.map((item) => {
                                        return (
                                          <Option value={item?.id}>
                                            {item?.name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Modal>

                            
                              {loading === true ? (
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div
                                          class="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span class="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </div>
                              ) : (
                               

                                <div>
                                <div className="row d-flex">
      {list?.map((folder) => {
        // console.log(folder,"folder")
        const res = checked?.filter(
                      (e) => e == folder?.uri)
                      // console.log(res,"res")
                      const res1 = checked?.filter(
                        (e) => e == folder?.uri)
                        itemType =  checked?.length>0 ? checked[0]?.title!=null ? 'video' : 'folder':null;

                      return(
                        <>
                        {folder?.type=="video"?  <div key={folder.id} className="col-lg-4 col-md-6"
        
        >
          <div className="card" style={{borderRadius:"10px"}}
         
          >
            <div className="card-body">
              <div className="d-flex"
              style={{justifyContent:"space-between"}}
              >
    
              <h5 className="card-title">   
              <Tooltip title={folder?.name}>
                                            
                                            <h5 className="card-title">{`${folder?.name.slice(0, 15)} ${
                                                                    folder?.name.length > 15
                                                                      ? "..."
                                                                      : ""
                                                                  }`}</h5>
                      
                                                                </Tooltip></h5>
              <div class="form-check form-check-inline">
                                                  {res?.map((e) => {
                                                    if (e) {
                                                      // console.log(e,"eee")
                                                      return (
                                                        <input
                                                        // checked
                                                          class="form-check-input"
                                                          style={{
                                                            marginLeft: "0px",
                                                          }}
                                                          type="checkbox"
                                                        checked={checked?.some((e) => e?.uri === folder?.uri)}                                                               
                                                           onChange={(e) => {
                                                            handleChange(
                                                              folder?.name,
                                                              folder?.video_length,
                                                              folder?.link,
                                                              folder?.thumbnail,
                                                              folder?.thumbnail_path,
                                                              e.target.checked
                                                            );
                                                          }}
                                                          id={`inlineCheckbox${folder?.link}`}                                                        />
                                                      );
                                                    } else {
                                                      return (
                                                        <input
                                                          class="form-check-input"
                                                          type="checkbox"
                                                          style={{
                                                            marginLeft: "0px",
                                                          }}
                                                          checked={checked.some((e) => e?.link === folder?.link)}       
                                                            onChange={(e) => {
                                                            handleChange(
                                                                folder?.name,
                                                                folder?.video_length,
                                                                folder?.link,
                                                                folder?.thumbnail,
                                                                folder?.thumbnail_path,
                                                                e.target.checked
                                                              );
                                                          }}
                                                          id={`inlineCheckbox${folder.link}`}                                                        />
                                                      );
                                                    }
                                                  })}
                                                  {res.length == 0 ? (
                                                    <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      style={{
                                                        marginLeft: "0px",
                                                      }}
                                                    checked={checked.some((e) => e?.link === folder?.link)}      
                                                      onChange={(e) => {
                                                        handleChange(
                                                            folder?.name,
                                                            folder?.video_length,
                                                            folder?.link,
                                                            folder?.thumbnail,
                                                            folder?.thumbnail_path,
                                                            e.target.checked
                                                          );
                                                      }}
                                                      id={`inlineCheckbox${folder.link}`}                                                    />
                                                  ) : null}
                                                </div>
              </div>
              <div className="row">
                {folder?.type=="folder"? 
                <div className="row">
                  <div className="row">
                <div className="col-lg-6 "
                style={{margin:"auto",display:"flex",justifyContent:"center"}}
               
                 onClick={()=>{
            hadlevimeodrl(folder?.uri)
          }}
                >
                  {folder?.type=="folder"?            
                  <img src={Folder} style={{ width: "140px", height: "140px",justifyContent:"center"}} alt="Folder Icon" className="" />
    :      <img src={Video} style={{ width: "140px", height: "140px",justifyContent:"center"}} alt="Folder Icon" className="" />
    }
    
                </div>
                </div>
                <div className="row" style={{marginTop:"10px"}}>
           
           <div className="col-lg-6"
           style={{margin:"auto"}}
          
           >
         <h6 style={{color:"#958e8d",textAlign:"center"}}> Videos :{" "}{folder?.videos}</h6>

           </div>
         </div>
                </div>
                :
                
                <div className="col-lg-12"
                style={{margin:"auto"}}
                onClick={()=>{
                  //  handelvimeovideo("https://vimeo.com/252443587")
                     handelvimeovideo(folder?.link)

                }}
                >
                  <img src={`${folder?.thumbnail_path}/${folder?.thumbnail}`} style={{ width: "90%", height: "90%",justifyContent:"center", objectFit:"cover"}} alt="Folder Icon" className="" />
    
                </div>
                }
               
    
              </div>
              
            </div>
          </div>
            </div>:<div key={folder?.uri} className="col-lg-4 col-md-6"
        
        >
          <div className="card" style={{borderRadius:"10px"}}
         
          >
            <div className="card-body">
              <div className="d-flex"
              style={{justifyContent:"space-between"}}
              >
    
              <h5 className="card-title">   
              <Tooltip title={folder?.name}>
                                            
                                            <h5 className="card-title">{`${folder?.name.slice(0, 15)} ${
                                                                    folder?.name.length > 15
                                                                      ? "..."
                                                                      : ""
                                                                  }`}</h5>
                      
                                                                </Tooltip></h5>
              <div className="form-check form-check-inline">
                                                  {res1?.map((e) => {
                                                    // console.log(e,"ee")
                                                    if (e) {
                                                      return (
                                                        <input
                                                        // checked
                                                          className="form-check-input"
                                                          style={{
                                                            marginLeft: "0px",
                                                          }}
                                                          type="checkbox"
                                                        checked={checked?.some((e) => e == folder?.uri)}       
                                                        // checked1={checked.some((e) => {console.log(e?.uri == folder?.uri)})}                                                         
                                                           onChange={(e) => {
                                                            handleChangeVideo(
                                                              folder?.uri,
                                                              e.target.checked,
                                                              itemType
                                                            );
                                                          }}
                                                          id={`inlineCheckbox${folder?.uri}`} 
                                                          // disabled={itemType === 'video'}  
                                                                                                      />
                                                          
                                                      );
                                                    } else {
                                                      return (
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          style={{
                                                            marginLeft: "0px",
                                                          }}
                                                          checked={checked.some((e) => e == folder?.uri)}      
                                                            onChange={(e) => {
                                                            handleChangeVideo(
                                                              folder?.uri,
                                                              e.target.checked,
                                                              itemType

                                                              );
                                                          }}
                                                          id={`inlineCheckbox${folder?.uri}`} 
                                                          // disabled={itemType == 'video'}
                                                           />
                                                      );
                                                    }
                                                  })}
                                                  {res1.length == 0 ? (
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      style={{
                                                        marginLeft: "0px",
                                                      }}
                                                    checked={checked.some((e) => e == folder?.uri)}      
                                                      onChange={(e) => {
                                                        handleChangeVideo(
                                                          folder?.uri,
                                                          e.target.checked,
                                                          itemType

                                                          );
                                                      }}
                                                      id={`inlineCheckbox${folder.uri}`}     
                                                      // disabled={itemType == 'video'}          
                                                                                      />
                                                  ) : null}
                                                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 "
                style={{margin:"auto",display:"flex",justifyContent:"center"}}
               
                 onClick={()=>{
            hadlevimeodrl(folder?.uri)
          }}
                >
                  {folder?.type=="folder"?            
                  <img src={Folder} style={{ width: "140px", height: "140px",justifyContent:"center"}} alt="Folder Icon" className="" />
    :      <img src={Video} style={{ width: "140px", height: "140px",justifyContent:"center"}} alt="Folder Icon" className="" />
    }
    
                </div>
    
              </div>
              <div className="row" style={{marginTop:"10px"}}>
           
            <div className="col-lg-12 d-flex"
            style={{margin:"auto",justifyContent:"space-between"}}
           
            >
                        <h6 style={{color:"#958e8d",textAlign:"center"}}> Folders :{" "}{folder?.folders}</h6>

          <h6 style={{color:"#958e8d",textAlign:"center"}}> Videos :{" "}{folder?.videos}</h6>

            </div>
          </div>
              
            </div>
          </div>
        </div>}
                      
            
                 
                        </>
       

                      )
})}
    </div>
    
                                </div>
                                
                              )}
                          
                            </div>
                           
                            {records1?.length <= 0 ? (
                              <h5 class="text-center">{messages}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {records1?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationVimeoList
                                nPages={+last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={RECORDEDVIDEO}
                                uri={uri}
                                setData={setList}
                                text={text}
                                setLast_page={setLast_page}
                                linkSearch={"/api/admin/vimeo/dirs/search"}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
}
export default AddImportVimeoSubModule
