import React from "react";
import TextEditor from "../../pages/course/addCourse/editor";
import Select from "react-select";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import * as Yup from "yup";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ADDTESTSERIES = "/api/test-series/create-testseries-module";

const Basic = ({ nextTab }) => {

  const [input, setInput] = useState(null);
  
const initalValue = {
    name: "",
    total_question: "",
    total_marks: "",
    negative_question: "",
    duration: "",
  };
  const studentSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter your name"),
    total_question: Yup.string()
      .min(1)
      .max(25)
      .required("Please enter your total question"),
    total_marks: Yup.string()
      .min(1)
      .max(25)
      .required("Please enter your total marks"),
    negative_question: Yup.string()
      .min(1)
      .max(25)
      .required("Please enter your negative marks"),
    duration: Yup.string().min(1).max(25).required("Please enter your duration"),
  });
  const navigate = useNavigate();
  const { errors, values, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initalValue,
    onSubmit: async (value) => {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const response = await axios.post(
          ADDTESTSERIES,
          {
            "testseries-name": value.name,
            total_question: value.total_question,
            total_marks: value.total_marks,
            negative_question: value.negative_question,
            duration: value.duration,
          },
          config
        );
        // console.log(response);
        if (response) {
          setTimeout(() => {
            // navigate("/admin/test-series");
            navigate(-1);
          }, 1000);
          toast.success("Test Series Created");
        }
      } catch (error) {
        console.log(error);
      }
    },
    validationSchema: studentSchema,
  });
  const option = [
    { label: "Category 01", value: "" },
    { label: "Category 02", value: "Category 02" },
    { label: "Category 03", value: "Category 03" },
    { label: "Category 04", value: "Category 04" },
  ];

  const options = [
    { label: "Level 01", value: "" },
    { label: "Level 02", value: "Level 02" },
    { label: "Level 03", value: "Level 03" },
    { label: "Level 04", value: "Level 04" },
  ];

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#FFDEDA" : "white",
      };
    },
    // dropdownIndicator: (base, state) => ({
    //   ...base,
    //   transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
    //   transition: '300ms',
    // }),
  };

  return (
    <>
     <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head">
                      {/* <h4>Add Test Series</h4>
                      <p>Add Test Series information.</p> */}
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Enter Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Your Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Total Question
                            </label>
                            <input
                              type="number"
                              name="total_question"
                              id="total_question"
                              value={values.total_question}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Total Question"
                            />
                            {errors.total_question && touched.total_question ? (
                              <span style={{ color: "red" }}>
                                {errors.total_question}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Total Marks
                            </label>
                            <input
                              type="number"
                              name="total_marks"
                              id="total_marks"
                              value={values.total_marks}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Total Marks"
                            />
                            {errors.total_marks && touched.total_marks ? (
                              <span style={{ color: "red" }}>
                                {errors.total_marks}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Negative Marks(Every Question)
                            </label>
                            <input
                              type="number"
                              name="negative_question"
                              id="negative_question"
                              step="0.01"
                              value={values.negative_question}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Negative Marks"
                            />
                            {errors.negative_question &&
                            touched.negative_question ? (
                              <span style={{ color: "red" }}>
                                {errors.negative_question}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Duration (In Minutes)
                            </label>
                            <input
                              type="number"
                              name="duration"
                              id="duration"
                              value={values.duration}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Duration"
                            />
                            {errors.duration && touched.duration ? (
                              <span style={{ color: "red" }}>
                                {errors.duration}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="update-profile">
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
    </>
  );
};

export default Basic;
