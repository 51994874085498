import Carousel from 'react-bootstrap/Carousel';
import {
    CategoryIcon,
    CategoryIcon1,
    CategoryIcon2,
    CategoryIcon3,
    CategoryIcon4,
    CategoryIcon5,
    Image,
  } from "../../imagepath";
import axios from '../../../api/axios';
import  "./scroll.css";
import { useEffect, useState } from 'react';
const IMAGES="/api/home/slider/image"
function DarkVariantExample() {
    const [list, setList] = useState(null);

    const ScrolImages = async () => {
        try {
            const response = await axios.get(IMAGES,);
             console.log(response,"res")
             setList(response?.data)
    
        } catch (error) {
            console.log(error);
            if (error.response && error?.response?.status === 401) {
                localStorage.removeItem("token");
                navigate("/login");
            }
            openNotificationWithIcon2("error");
        } finally {
            // setLoading(false);
        }
    };
    useEffect(() => {
        ScrolImages()
      }, []);
  return (
 
    <section className="section how-it-works">
    <div className="container">
      <div className="section-header aos" data-aos="fade-up">
        <div className="section-sub-head">
          {/* <span>Images</span> */}
          <h2>Images</h2>
        </div>
       
      </div>
     
         <Carousel data-bs-theme="dark">
            {list?.length>0? list?.map((item)=>{
return(
    <Carousel.Item>
    <img
      className="d-block w-100 scroll"
      src={item?.image}
      alt="First slide"
    //   style={{width:"100%",height:"100%"}}
    />
   
  </Carousel.Item>
)
            }):""}
   
     
    </Carousel>
     
    </div>
  </section>
  );
}

export default DarkVariantExample;