import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./ChatApp.css"; // Import your custom styles
import "bootstrap/dist/css/bootstrap.min.css";
import { Tooltip } from "antd";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import axios from "axios";
import axiosInstance from "../../../api/axios1";
import axiosTeacher from "../../../api/axiosTeacher";
const GETMESSAGES = "api/teacher/get/chat";
const SENDMESSAGE = "/api/teacher/send/chat";
const LASTSTUDENTS = "/api/teacher/get/students";
import { useInView } from "react-intersection-observer";
import { userprofile } from "../../imagepath";
import { notification, Space } from "antd";
import chahalpic from "../../../assets/img/chahal/chahalpic.jpg";
import chahalpicblur from "../../../assets/img/chahal/chahalblur.jpeg";
import textterd from "../../../assets/img/chahal/Pic1.jpg"

const ChatApp = () => {
  const token = localStorage.getItem("tokenTeacher");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentname, setStudentsName] = useState("");
  const [teacherid, setTeacherid] = useState("");
  const [id, setid] = useState([]);
  const chatContainerRef = useRef(null);
  const [value, setValue] = useState([]);
  const Teacheruserid = localStorage.getItem("Teacheruserid");
  const [currentPage, setCurrentPage] = useState(1);
  const lastMessageRef = useRef(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
    root: chatContainerRef.current, // Use the chat container as the root
    rootMargin: "0px 0px 100% 0px", // Adjust the root margin based on your needs
  });
  const [isAtTop, setIsAtTop] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [scrollAtBottom, setScrollAtBottom] = useState(true);
  const [pagevalue, setPageValue] = useState(1);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const handleStudentlist = async () => {
    try {
      // setLoading(true);
      const responce = await axiosTeacher.get(LASTSTUDENTS, config);
      // console.log(responce?.data,"responce")
      setStudents(responce?.data);
      // setSelectedStudent(responce?.data[0])
      // setStudentsName(responce?.data[0]?.name)
      // setTeacherid(+responce?.data[0]?.id)    //we are seting reciverid
      // setid(responce?.data[0]?.id)
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenTeacher");
        navigate("/login");
      }
      openNotificationWithIcon2("error");
      setLoading(false);
    }
  };
  const handleList = async () => {
    try {
      // setLoading(true);
      const responce = await axiosTeacher.get(LASTSTUDENTS, config);
      // console.log(responce?.data,"responce")
      // setStudents(responce?.data)
      setSelectedStudent(responce?.data[0]);
      setStudentsName(responce?.data[0]?.name);
      setTeacherid(+responce?.data[0]?.id); //we are seting reciverid
      setid(responce?.data[0]?.id);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenTeacher");
        navigate("/teacher/login");
      }
      // openNotificationWithIcon2("error");
      setLoading(false);
    }
  };
  useEffect(() => {
    handleStudentlist();
    // handleList()
  }, []);

  const handleSelectStudent = (student) => {
    // console.log(student,"in student function")
    setSelectedStudent(student);
    setStudentsName(student?.name);
    setid(student?.id);
    setScrollAtBottom(true);
  };
  // console.log(id,"id")
  useEffect(() => {
    let render = false;
    let isMounted = true;
    axiosTeacher
      .get(`${GETMESSAGES}/${id ? id : ""}?page=${currentPage}`, config)
      .then((response) => {
        // console.log(response?.data?.last_page,"lastpage")
        const reversermessage = response?.data?.data.reverse();
        setMessages(reversermessage);
        setLastPage(response?.data ? response?.data?.last_page : "");
        setPageValue(response?.data?.current_page);

        if (isMounted) {
          scrollToBottom();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    Pusher.logToConsole = true;

    const echo = new Echo({
      broadcaster: "pusher",
      key: "a54af538516d5957021d",
      cluster: "ap2",
      encrypted: true,
    });
    echo.channel("user-send").listen("UserMessageSent", (event) => {
      // console.log(event, "event");
      if (event) {
        // let data=messages
        // data.push(event?.data)
        if (
          event?.data?.sender_id == Teacheruserid ||
          event?.data?.receiver_id == Teacheruserid
        ) {
          setMessages((prevMessages) => [...prevMessages, event?.data]);
          //  getStudentMessages(currentPage);

          handleStudentlist();
          if (isMounted) {
            scrollToBottom();
          }
        }
      }
    });
    return () => {
      isMounted = false;
      echo.disconnect();
    };
  }, [id, currentPage]);
  const getStudentMessages = async (page) => {
    try {
      const response = await axiosTeacher.get(
        `${GETMESSAGES}/${id ? id : ""}?page=${page}`,
        config
      );
      // console.log(response?.last_page)
      const reversedMessages = response?.data?.data.reverse();
      setMessages((prevMessages) => [...reversedMessages, ...prevMessages]);
      setLastPage(response?.data ? response?.data?.last_page : "");
      setPageValue(response?.data?.current_page);
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };

  // useEffect(() => {
  //   getStudentMessages(currentPage);
  // }, [currentPage]);

  useEffect(() => {
    if (inView && currentPage > 1) {
      // console.log('Reached top of the scroll. Fetching the previous page.');
      getStudentMessages(currentPage - 1);
    }
  }, [inView, currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const scrollTop = chatContainerRef.current.scrollTop;
        const threshold = 50;

        setIsAtTop(scrollTop < threshold);
      }
    };

    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      const chatContainer = chatContainerRef.current;
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isAtTop && currentPage < lastPage) {
      // console.log('Reached the top of the scroll. Fetching the previous page.');
      getStudentMessages(currentPage + 1);
    }
  }, [isAtTop, currentPage, lastPage]);

  //  console.log(messages,"messages outside")

  const handleSendMessage = async () => {
    try {
      if (newMessage.trim() !== "" && selectedStudent) {
        // Send the message to the server
        const response = await axiosTeacher.post(
          SENDMESSAGE,
          {
            receiver_id: id,
            message: newMessage,
          },
          config
        );

        // Assuming the server returns the sent message data, update the UI
        const sentMessage = response?.data;
        // console.log(sentMessage,"messages from the sender")
        if (sentMessage?.sent_time && sentMessage?.sent_date) {
          sentMessage.sent_time = formatSentTime(sentMessage.sent_time);
          setMessages((prevMessages) => [...prevMessages, sentMessage]);
        } else {
          console.error("Invalid date or time in sent message:", sentMessage);
        }
        //  getStudentMessages(currentPage);
        setScrollAtBottom(true);
        // handleStudentlist()

        // Clear the message input
        setNewMessage("");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      openNotificationWithIcon2("error", error?.response?.data?.message);

      // Handle error if needed
    }
  };

  const openNotificationWithIcon2 = (type, text) => {
    console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };

  function groupedMessages() {
    // Group messages by date
    const grouped = messages.reduce((acc, message) => {
      const date = message.sent_date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});

    // Convert the grouped object into an array, sorted by date
    const sortedGroups = Object.keys(grouped)
      .sort()
      .map((date) => ({
        date,
        messages: grouped[date],
      }));

    return sortedGroups;
  }

  function formatSentTime(sentTime) {
    const [hours, minutes] = sentTime ? sentTime?.split(":") : "";
    const dummyDate = new Date(2000, 0, 1, hours, minutes);

    if (isNaN(dummyDate.getTime())) {
      // console.error("Invalid time string:", sentTime);
      return "Invalid Time";
    }

    const formattedTime = dummyDate?.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    // console.log(formattedTime);
    return formattedTime;
  }

  // Function to format date
  function formatDate(sentDate) {
    const messageDate = new Date(sentDate);
    const today = new Date();

    if (isSameDay(messageDate, today)) {
      return "Today";
    } else if (isSameDay(messageDate, new Date(today.getTime() - 86400000))) {
      return "Yesterday";
    } else {
      return messageDate?.toLocaleDateString();
    }
  }

  // Function to check if two dates are the same day
  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
  useEffect(() => {
    if (scrollAtBottom) {
      scrollToBottom();
      setScrollAtBottom(false);
    }
  }, [messages]);
  useEffect(() => {
    // console.log(pagevalue,"current page in useEffect")
    if (pagevalue == 1) {
      scrollToBottom();
    }
  }, [messages]);
  // useEffect(() => {
  //   // Scroll to the bottom when the component mounts or when selectedStudent changes
  //   console.log(selectedStudent,"in useeffect")
  //   scrollToBottom();
  // }, [selectedStudent?selectedStudent:"",]);
  // useLayoutEffect(() => {
  //   // Scroll to the bottom when the component mounts or when selectedStudent changes
  //   console.log(selectedStudent,"in useLayoutEffect")

  //   scrollToBottom();
  // }, [selectedStudent?selectedStudent:""]);
console.log(selectedStudent,"selected")
  return (
    <div className="container-fluid"
    style={{padding:"10px"}}

    >
      <div className="row"
      style={{padding:"0px"}}
      >
        <div className="col-lg-3"
   style={{border:"3px solid #d5cdcd",minHeight:'500px' }}

        >
          <div className="student-sidebar" style={{ padding: "15px",backgroundColor:"white" }}>
          <div style={{ padding: "5px",display:"flex" }}>
                <i class='far fa-comment' style={{fontSize:"22px",marginTop:"2px"}}></i>
                  <h5 className="ms-2">Messages</h5>
                </div>
            <div className="" style={{ maxHeight: "500px", overflow: "auto" }}>
              {students.map((student, index) => (
                <div
                  key={student ? student?.id : ""}
                  className="row"
                  onClick={() => handleSelectStudent(student)}
                  style={{
                     marginTop: "10px",
                    boxShadow:
                      student?.id === id
                        ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                        : "",
                    margin: "auto",
                    padding: "8px",
                    borderRadius: "12px",
                    // border:"1px solid red",
                    backgroundColor: student?.id === id ? "#f1f1f1" : "",
                  }}
                >
                  <div className="student-image col-3">
                    <div
                      style={{
                        // display: "flex",
                        alignSelf: "center",
                        justifyContent: "center",
                        marginTop: "5px",
                        width: "60px",
                        height: "60px",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "50%",
                          width: "78%",
                          height: "78%",
                          objectFit: "cover",
                        }}
                        src={
                          student?.profile?.image
                            ? student?.profile?.image
                            : userprofile
                        }
                        alt={student?.name}
                      />
                    </div>
                  </div>
                  <div
                    className="col-9"
                    style={{ alignSelf: "center", justifyContent: "" }}
                  >
                    <div className="row">
                      <div className="col-7">
                        <h4 style={{ fontSize: "14px", fontWeight: "600" }}>
                          <Tooltip title={student ? student?.name : ""}>
                            {`${student?.name.slice(0, 10)} ${
                              student?.name.length > 10 ? "..." : ""
                            }`}
                          </Tooltip>
                        </h4>

                        <h6 style={{ fontSize: "12px" }}>
                          <Tooltip title={student?.chat?.message}>
                            {student?.chat?.message
                              ? `${student?.chat?.message.slice(0, 10)} ${
                                  student?.chat?.message.length > 10
                                    ? "..."
                                    : ""
                                }`
                              : ""}
                          </Tooltip>
                        </h6>
                      </div>
                      <div
                        className="col-5"
                        style={{
                          alignSelf: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <h6 style={{ alignSelf: "center", fontSize: "12px" }}>
                          {student?.chat?.sent_time
                            ? formatSentTime(student?.chat?.sent_time)
                            : ""}
                        </h6>
                      </div>
                    </div>
                    {/* {student.isActive && <span className="active-symbol">&#8226;</span>} */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-lg-9"
        style={{backgroundColor:"white",
        borderRight:"3px solid #ddd6d6",
        borderTop:"3px solid #ddd6d6",
        borderBottom:"3px solid #ddd6d6",
       //  borderTopLeftRadius:"10px",


   }}
        >
          <div className="row">
            {/* <h2 style={{ color: "green" }}>
              {selectedStudent
                ? `Chat with ${studentname}`
                : "Select a student to chat"}
            </h2> */}
               <div className="col-lg-12"
                style={{position:"relative"}}
                // style={{ borderBottom: '1px solid transparent', backgroundImage: 'linear-gradient(to right, #000 50%, transparent 50%)', backgroundSize: '10px 1px', backgroundRepeat: 'repeat-x', paddingBottom: '10px' }}
                >
               <div className="d-flex">
               <div
                          style={{
                             display: "flex",
                            alignSelf: "center",
                            justifyContent: "center",
                            //  marginTop: "10px",
                             width: "70px",
                             height: "70px",
                            marginLeft:"10px"

                          }}
                          
                        >
                          <img
                            style={{
                              borderRadius: "50%",
                              width: "75%",
                              height: "75%",
                              objectFit: "cover",
                              alignSelf:"cenetr",
                              marginTop:"10px"
                              
                            }}
                            src={
                              selectedStudent?.profile?.image
                                ? selectedStudent?.profile?.image
                                : userprofile
                            }
                            alt={selectedStudent?.name}
                          />
                         
                        </div>
                        <div className="align-self-center"
                        style={{marginTop:"5px"}}
                        >
                        <h4 style={{ color: "green" }}>
                  {selectedStudent
                    ? `${studentname}`
                    : "Select a Teacher"}
                </h4>
                        </div>
        <div style={{ position: 'absolute', bottom: '-1px', left: 0, width: '100%',
                         borderBottom: '3px dotted rgb(197 192 192)' }}></div>
               </div>
                         
                        
                </div>
                <div
                  style={{  
                    // minHeight: selectedStudent?"420px":"500px",
                    // minHeight:"500px",
                  overflow: "auto",
                   backgroundImage: selectedStudent?`url(${textterd})`
                   :`url(${chahalpic})`,
                  //  backgroundSize: "cover", // Optional: Adjust to your needs
                  backgroundPosition: "center", // Optional: Adjust to your needs
                  objectFit:"contain",
                 backgroundRepeat: selectedStudent?"":"no-repeat",
              //  background: selectedStudent?"":"linear-gradient(90deg, rgba(111,202,199,1) 0%, rgba(255,255,255,1) 100%)"// background: linear-gradient(90deg, rgba(111,202,199,1) 0%, rgba(255,255,255,1) 100%).
  
                  // backgroundColor:"white",
       
                  // marginTop:"5px"
  
                  // opacity: selectedStudent ? "1" : "1", // Adjust opacity conditionally
                  // opacity: selectedStudent ? "0.5" : "1", // Adjust opacity conditionally
                }}
                >
            <div ref={lastMessageRef}>
              <div
                className="message-container col-12"
                ref={chatContainerRef}
                id="message-container"
                style={{
                  minHeight: selectedStudent?"420px":"500px",
                  overflow: "auto",
                  backgroundImage: selectedStudent?`url(${textterd})`:`url(${chahalpic})`,
                  // backgroundSize: "cover", // Optional: Adjust to your needs
                  backgroundPosition: "center", // Optional: Adjust to your needs
                  objectFit:"contain",
                  backgroundRepeat: selectedStudent?"":"no-repeat",
                  backgroundColor:"white",
                  marginTop:"5px"
                }}
              >
                {groupedMessages()?.map((group, groupIndex) => (
                  <>
                    {selectedStudent ? (
                      <div key={groupIndex}>
                        <div
                          className="date-header"
                          style={{
                            // color: "white",
                            textAlign: "center",
                            marginTop: "5px",
                            fontSize: "14px",
                          }}
                        >
                          <span 
                            style={{ backgroundColor: "white",
                             padding: "5px 10px",
                            borderRadius:"5px", 
                             color: "black", }}>
                 {formatDate(group.date)}
  </span>
                        </div>
                        {group?.messages?.map((message, index) => (
                          <div
                            key={index}
                            className={
                              message?.sender_id == Teacheruserid
                                ? "user-message"
                                : "student-message"
                            }
                            ref={
                              index === group.messages.length - 1
                                ? lastMessageRef
                                : null
                            }
                          >
                            {message.sender_id == Teacheruserid ? (
                              <>
                                <div className="sender-message message-bubble">
                                  {message?.message}
                                </div>
                                <div
                                  style={{
                                    alignSelf: "center",
                                    marginLeft: "5px",
                                    color: "black",
                                    fontSize: "12px",
                                  }}
                                >
                                  {formatSentTime(message?.sent_time)}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="receiver-message message-bubble">
                                  {message?.message}
                                </div>
                                <div
                                  style={{
                                    alignSelf: "center",
                                    marginLeft: "5px",
                                    fontSize: "12px",
                                    color: "black",
                                  }}
                                >
                                  {formatSentTime(message?.sent_time)}
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ))}
                <div ref={ref}></div>
              </div>
            </div>

            {selectedStudent && (
              <div className="message-input"
              style={{paddingBottom:"5px"}}

              >
                <textarea
                  placeholder="Type your message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
                <button className="btn btn-primary" onClick={handleSendMessage}>
                  Send
                </button>
              </div>
            )}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatApp;
