import React, { useEffect, useRef, useState } from "react";
import { LogOut, Moon, Star, User,Delete } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
// import "./style.css";
import {
  Cart,
  Course14,
  Course15,
  Course4,
  logo,
  Messages,
  // Notification,
  User1,
  User11,
  User2,
  User3,
  Wish,
  userprofile,
  ChahalLogo,
} from "../../imagepath";
import {   notification, Space  } from "antd";

// import axios from "../../../api/axios";
import axiosInstance from "../../../api/axios1";
import axiosTeacher from "../../../api/axiosTeacher";
const LOGOUT = "/api/teacher/logout";

const EXPIRED="api/student/get/expired_courses"
const COURSECATEGORY = "/api/admin/course/menu";
const UPLOADIMAGE="/api/student/editImageUserProfile";


export default function TeacherNavbar({ activeMenu }) {
  const name = localStorage.getItem("Teachername");
  const navigate = useNavigate();
  const tokenTeacher = localStorage.getItem("tokenTeacher");
 
  const config = {
    headers: { Authorization: `Bearer ${tokenTeacher}` },
  };
  const [api, contextHolder] = notification.useNotification();
  
  const[toast,setToast]=useState(false)
  const [courses, setCourses] = useState([]);
  const[image,setImage]=useState("")

 
    const openNotificationWithIcon1 = (type) => {
      console.log("toast after click")
      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"#52c41a"}}>
           <strong>Logout Successful</strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        duration:1
      });
      };

  const [navbar, setNavbar] = useState(false);

  const [showCart, setShowCart] = useState(false);
  const [showWish, setShowWish] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  // Mobile Menu toggle
  const [mobileMenu, setMobileMenu] = useState(false);
  const logOut = async () => {
    try {
     
      const responce = await axiosTeacher.post(LOGOUT, {}, config);
      if (responce) {
        openNotificationWithIcon1("success")

        setTimeout(() => {
          navigate("/teacher/login");
        }, 1000);
    //     const keysToRemove = Object.keys(localStorage).filter(key => key.startsWith('notificationClosed_'));
    //    keysToRemove.forEach(key => localStorage.removeItem(key));
    //     // toast.success("Logout Successful");
    //     // console.log(tokenStudent,"token")
        localStorage.removeItem("tokenTeacher");      
      }
    } catch (error) {
      console.error(error);
      if (error.response.status == 401) {
        localStorage.removeItem('tokenTeacher')
        localStorage.removeItem("showNotification")

        // console.log(tokenStudent,"token")

        navigate('/teacher/login')
        const keysToRemove = Object.keys(localStorage).filter(key => key.startsWith('notificationClosed_'));
  keysToRemove.forEach(key => localStorage.removeItem(key));
      } 
      else{
        localStorage.removeItem('tokenTeacher')
        localStorage.removeItem("showNotification")
        const keysToRemove = Object.keys(localStorage).filter(key => key.startsWith('notificationClosed_'));
  keysToRemove.forEach(key => localStorage.removeItem(key));

        // console.log(tokenStudent,"token")

        navigate('/teacher/login')
  
      }


      }
  };


  const openMobileMenu = (event) => {
    document.body.classList.add("menu-opened");
    setMobileMenu(true);
  };
  const hideMobileMenu = (event) => {
    document.body.classList.remove("menu-opened");
    setMobileMenu(false);
  };


  // To close the modal, when clicked outside anywhere
  const cart = useRef();
  useOnClickOutside(cart, () => setShowCart(false));

  const wish = useRef();
  useOnClickOutside(wish, () => setShowWish(false));

  const notificationvalue = useRef();
  useOnClickOutside(notificationvalue, () => setShowNotification(false));

  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));

 
  const profileClick = (e) => {
    e.preventDefault();
    setShowProfile(!showProfile);
  };

  const changeHeaderBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeHeaderBackground);

  const isActive = (path) => {
    const location = useLocation();
    return location.pathname === path;
  };
  const [showNotification1, setShowNotification1] = useState(true);
  const [loading, setLoading] = useState(false);
  const [expireddata,setExpiredData]=useState([])


 
  

 
  // console.log(expireddata,"data")
  const [closedNotifications, setClosedNotifications] = useState([]);

  const handleCloseNotification = (index) => {
    if (expireddata?.length <= 1) {
      localStorage.setItem('showNotification', 'false');
      setShowNotification1(false);
    }
  
    setExpiredData(prevData => prevData.filter((_, i) => i !== index));
  
    // Update localStorage to indicate that this notification has been closed
    localStorage.setItem(`notificationClosed_${index}`, 'true');
  };
  
  useEffect(() => {
    const shouldShowNotification = localStorage.getItem('showNotification') !== 'false';
    setShowNotification1(shouldShowNotification);
  }, []);
  return (
    <>
   
    <header className="header header-page">
    
      <div className="header-fixed">
     
        <nav
          className={
            navbar
              ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
              : "navbar navbar-expand-lg header-nav scroll-sticky"
          }
        >
          
         
          <div className="container"
          style={{minWidth:"100%"}}
          >
            
       
            <div className="navbar-header">
              <Link id="mobile_btn" to="#;" onClick={openMobileMenu}>
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </Link>
              <Link to="/" className="navbar-brand logo">
                <img src={ChahalLogo} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/" className="menu-logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                </Link>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#;"
                  onClick={hideMobileMenu}
                >
                  <i className="fas fa-times"></i>
                </Link>
              </div>
              <ul className="main-nav">
              
                <li className="has-submenu">
                  <Link to=""
                   className={
                    isActive("") ? "actives" : ""
                  }
                  >
                    Home{" "}
                    {/* <i
                      className="fas fa-chevron-down "
                      onClick={openMobileSubMenu2}
                    ></i> */}
                  </Link>
                  <ul
                  // className={
                  //   mobileSubMenu2 ? "submenu submenuShow" : "submenu"
                  // }
                  >
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                  </ul>
                </li>

               
                  <li className="has-submenu">
                  <Link
                    to="/teacher/dashboard"
                    className={
                      isActive("/teacher/dashboard") ? "actives" : ""
                    }
                  >
                   Dashboard{" "}
                  </Link>
                  <ul>
                    <li>
                      <Link to="/teacher/dashboard">Dashboard</Link>
                    </li>
                  </ul>
                </li>
            

               
               
 

               
               
              </ul>
            </div>
            <ul className="nav header-navbar-rht">
             
              <li className="nav-item user-nav">
                <Link
                  to="#"
                  className={
                    showProfile ? "dropdown-toggle show" : "dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                  onClick={profileClick}
                >
                  <span className="user-img">
                    <img src={image?image:userprofile} alt="" />
                    <span className="status online"></span>
                  </span>
                </Link>
                <div
                  ref={profile}
                  className={
                    showProfile
                      ? "users dropdown-menu dropdown-menu-right show modalPosition"
                      : "users dropdown-menu dropdown-menu-right"
                  }
                  data-popper-placement="bottom-end"
                >
                  <div className="user-header">
                    <div className="avatar avatar-sm">
                      <img
                        src={image?image:userprofile}
                        alt="User Image"
                        className="avatar-img rounded-circle"
                      />
                    </div>
                    <div className="user-text">
                      <h6 className="single-line">{name}</h6>
                      <p className="text-muted text mb-0">Teacher</p>
                    </div>
                  </div>
                
                    <Link
                    className="dropdown-item text"
                    to=''
                  >
                    <User size={14} color={"#FF875A"} className="headerIcon" />{" "}
                    Edit Profile
                  </Link>
                  {/* <Link
                    className="dropdown-item text"
                    to="/student/setting-student-security"
                  >
                    <Star size={14} color={"#FF875A"} className="headerIcon" />{" "}
                    Security
                  </Link> */}
               
                  <a
                    className="dropdown-item text "
                    style={{ cursor: "pointer" }}
                    onClick={logOut}
                  >
                    <LogOut
                      size={14}
                      color={"#FF875A"}
                      className="headerIcon "
                    />{" "}
                    Log Out
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
       
        <div
          className={mobileMenu ? "sidebar-overlay opened" : "sidebar-overlay"}
        ></div>
        <ToastContainer />
      </div>
     
    </header>

   
   
    </>
  );
}
