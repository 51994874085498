import React, { useState, useContext, useEffect, useRef } from "react";

import AdminHeader from "../../instructor/adminHeader";
import Footer from "../../footer";
import { AudioOutlined } from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import { Video2 } from "../../imagepath";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";
import CryptoJS from "crypto-js";
import axios from "../../../api/axios";
// import "./style.css";
import { notification, Space } from "antd";

const RECORDEDVIDEO = "/api/video-list";
const DELETEVIDEO = "/api/delete-submodule-video-list";
const RECORDEDSTATUS = "/api/recorded-video/status";
const RECORDEDSEARCH = "/api/search-Submodule-Video-List";
const IMPORT = "/api/recorded-video/pdf-file-upload";

import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import FeatherIcon from "feather-icons-react";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "react-switch";
import { Modal } from "antd";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { confirm } = Modal;

export default function AddVideosList() {
    const {id}=useParams()
  const { Search } = Input;
  const [list, setList] = useState(null);
  const inputFile = useRef(null);
  const [last_page, setLast_page] = useState("");
  const [file, setFile] = useState(null);
  const [records1, setRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [text, settext] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [error, seterror] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
//   const [id, setId] = useState("");
  const [messages, setmessage] = useState("No Data");
  const [uid, setuid] = useState("");

  const handleClose = () => setShow(false);

  const navigate = useNavigate();
  const handleEdit = (id) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/editvideo/${ciphertexts}`);
  };

  const handleView = (id) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/view-video/${ciphertexts}`);
  };
  const handleLink = (id) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/link-video/${ciphertexts}`);
  };
  function showAlert() {
    navigate("/admin/recorded-videos");
  }

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const openNotificationWithIcon2 = (type) => {

    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const fecthRecordedList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(`${RECORDEDVIDEO}/${id}`, config);
      // console.log(resonse?.data,"response")
      setLast_page(resonse.data.last_page);
      setList(resonse?.data);
      setRecords(resonse?.data);
      setLoading(false);
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
      else{
      openNotificationWithIcon2("error")
      }
    }
  };
  useEffect(() => {
    fecthRecordedList();
  }, []);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type) => {

    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Video Deleted</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type, text, col) => {
  
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text} </strong>
        </div>
      ),
      duartion: 1,
    });
  };

  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this video?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        setLoading(true);
        try {
          const response = await axios.delete(`${DELETEVIDEO}/${id}`, config);
          setLoading(false);
          openNotificationWithIcon("success");
          fecthRecordedList();
          setShow(false);
          response;
          // toast.success("Item deleted");
          // Show success toast notification
        } catch (error) {
          console.error("Error deleting student:", error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else{

          
          openNotificationWithIcon2("error");
          }
        }
      },
      onCancel() {},
    });
  }
  const handleDeleteStudent = async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await axios.delete(`${DELETEVIDEO}/${id}`, config);
        setLoading(false);
        fecthRecordedList();
        setShow(false);
        response;
        // toast.warning("Video Deleted");
        // toast.success("Item deleted");
        // Show success toast notification
      } catch (error) {
        console.error("Error deleting student:", error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      }
    }
  };

  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      RECORDEDSTATUS,
      { id: id, status: result },
      config
    );
  
    if (response) {
      openNotificationWithIcon1("success", "Status updated", "#52c41a");
      const updatedList = list?.map(item =>
        item.id === id ? { ...item, status: result } : item
      );
      setRecords(updatedList);
    } else {
      openNotificationWithIcon2("error");
    }
  };
  
  const handlePdf = (id) => {
    navigate(`/admin/recorded-video/uploadPdf/${id}`)
  };
  const handleDelteImoprt = async () => {
    seterror("");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("video_id", uid);

    try {
      const response = await axios.post(IMPORT, formData, config);
      fecthRecordedList();
      openNotificationWithIcon1("success", response.data.message, "#52c41a");
      if (response) {
        setIsModalVisible(false);
        if (inputFile.current) {
          inputFile.current.value = "";
          inputFile.current.type = "text";
          inputFile.current.type = "file";
        }
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        openNotificationWithIcon1("error", error.response.data.message, "red");
      } else {
        openNotificationWithIcon1("error", "Internal Server Error", "red");
      }
      seterror(error.response.data.message);
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      settext(searchValue);
      const res = await axios.post(
        RECORDEDSEARCH,
        { title: searchValue ,submodule:id},
        config
      );
      if (res.data.length > 0) {
        const filteredData = res.data.filter(item => item?.videos[0]);
        // console.log(filteredData,"filterdata")
    setRecords(filteredData);
    setLast_page(res.data.last_page);
       
        
        setmessage("No Data");
      }
      if (res.data.length === 0) {
        setRecords(res.data);
        setmessage("No Data Found");
      }
      if (searchValue.length === 0) {
        // const res = await axios.get(
        //   `${RECORDEDVIDEO}/?page=${currentPage}`,
        //   config
        // );
        // setRecords(list);
        // setCurrentPage(1);
        // setLast_page(res.data.last_page);
        fecthRecordedList()
      }
    } catch (error) {
      console.error("Error deleting student:", error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  // console.log(records1,"records on in serach")
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };

  let data = [];

  // if (Array.isArray(records1)) {
  //   // Only sort if records1 is an array
  //   data = records1.sort((a, b) => {
  //     if (sortColumn !== "") {
  //       if (sortDirection === "asc") {
  //         if (a[sortColumn] < b[sortColumn]) return -1;
  //         if (a[sortColumn] > b[sortColumn]) return 1;
  //         return 0;
  //       } else {
  //         if (a[sortColumn] < b[sortColumn]) return 1;
  //         if (a[sortColumn] > b[sortColumn]) return -1;
  //         return 0;
  //       }
  //     } else {
  //       return a.id - b.id;
  //     }
  //   });
  // }
  const props = {
    accept: ".pdf",
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} is not a pdf file`);
      }
      return file.type === "application/pdf" ? true : Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      let files = info.file.originFileObj;
      delete files.uid;
      if(file){
        info.fileList.splice(0)
      }else{

        setFile(files);
      }

    },
    // showUploadList: file ? false : true,
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const itemsPerPage = 10;
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  // useEffect(() => {
  //   windowTop(0, 0);
  // }, []);
  return (
    <div className="main-wrapper ">
      <AdminHeader />
      <div className="page-content ">
        <div
          className="container"
          style={{ maxWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                        <div className="filter-grp ticket-grp d-flex align-items-center justify-content-between">
                            <div>
                        <h3>Videos List</h3>
                        <nav
                            aria-label="breadcrumb"
                            style={{ marginBottom: "-1.6em" }}
                          >
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate("/admin/recorded-videos")
                                }
                              >
                                Recorded Modules
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate("/admin/recorded-videos-list")
                                }
                              >
                                {" "}
                                Recorded videos
                              </li>
                            </ol>
                          </nav>
                          </div>
                        </div>
                        
                        </div>
                  
                        <div className="comman-space pb-0">
                          <div
                            // className="settings-referral-blk table-responsive"
                            // id="table-value"
                          >
                            {/* Instructor Users */}
                            
                            {/* <form>
                              <div className="student-search-form d-flex m-0">
                                {/* <div className="form-group mb-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Twitter Profile Name"
                              />
                            </div> *
                                <div className="export-cvv-btn">
                                  <Link
                                    to="/admin/add-video"
                                    className="btn btn-primary"
                                    style={{color:'white'}}
                                  >
                                   Create New Video
                                  </Link>
                                  {/* Export CSV *
                                </div>
                                <div className="profile-share d-flex align-items-center justify-content-center">
                                  <Search
                                    className="custom-search-box"
                                    placeholder="input search text"
                                    allowClear
                                    size="large"
                                    onChange={Filter}
                                  />
                                </div>
                              </div>
                            </form> */}
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row  gx-2 align-items-center">
                                    <div className="col-md-4 col-item"                                    >
                                      <div className=" search-group justify-content-start">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search"
                                          
                                          onChange={Filter}
                                          style={{ width: "100%" }}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </div>

                                    
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                           
                            <Modal
                              title="Upload Pdf"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              open={isModalVisible}
                              okText="ok"
                              onOk={() => {
                                handleDelteImoprt()
                                // setIsModalVisible(false);
                              }}
                              onCancel={() => {
                                setIsModalVisible(false);
                                seterror("");
                              }}
                            >
                              <Upload
                                onChange={(e) => setFile(e.target.files[0])}
                                accept=".doc,.docx,application/pdf"
                                {...props}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Upload pdf only
                                </Button>
                              </Upload>
                              {/* <div className="mb-3">
                                <label for="formFile" className="form-label">
                                  only support Pdf file
                                </label>
                                <input
                                  className="form-control"
                                  autoFocus
                                  onChange={(e) => setFile(e.target.files[0])}
                                  ref={inputFile}
                                  type="file"
                                  id="formFile"
                                />
                                {error && (
                                  <span style={{ color: "red" }}>{error}</span>
                                )}
                              </div> */}
                            </Modal>
                            <table className="table table-nowrap mb-2">
                              <thead>
                                <tr className="text-center">
                                  <th>SR NO</th>
                                  <th>VIDEOS</th>
                                  <th>ACTION</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                                
                                  <tbody                                   
                                  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}
>
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                              ) : (
                                <tbody style={{ textTransform: "capitalize" }}>
                                  {records1 
                                    // .sort((a, b) => b.id - a.id)
                                    ?.map((item, i,index) => {
                                      const sNo =
                                        (currentPage - 1) * itemsPerPage +
                                        i +
                                        1;
                                      return (
                                        <tr key={item?.recorded_video_id}>
                                          <td className="text-center">{sNo}</td>
                                          <td>
                                            <div
                                              style={{
                                                marginLeft: "12em",
                                                marginBottom: "-1.4em",
                                              }}
                                              className="sell-table-group d-flex align-items-center"
                                            >
                                                <div
                                                  className="sell-group-img"
                                                  style={{ height: "none" }}
                                                >
                                                  <Link>
                                                    <img
                                                      src={`${item?.videos[0]?.thumbnail_path}/${item?.videos[0]?.thumbnail}`}
                                                      style={{
                                                        borderRadius: "15px",
                                                        width: "90%",
                                                        height: "90%",
                                                        objectFit: "cover",
                                                      }}
                                                      className="img-fluid "
                                                      alt=""
                                                    />
                                                  </Link>
                                                </div>
                                              <div
                                                className="sell-tabel-info"
                                                style={{ height: "none" }}
                                              >
                                                <p>
                                                  <Link>
                                                    <Tooltip title={item?.videos[0]?.title}>
                                                      {`${item?.videos[0]?.title.slice(
                                                        0,
                                                        33
                                                      )} ${
                                                        item?.videos[0]?.title.length > 33
                                                          ? "..."
                                                          : ""
                                                      }`}
                                                    </Tooltip>
                                                  </Link>
                                                </p>
                                                <div
                                                  className="course-info d-flex align-items-center border-bottom-0 pb-0"
                                                  style={{ width: "0" }}
                                                >
                                                  <div className="rating-img d-flex align-items-center">
                                                    <img src={Video2} alt="" />
                                                    <p>
                                                      {" "}
                                                      {item?.videos[0]?.vid_source === "0"
                                                        ? "YouTube"
                                                        : item?.videos[0]?.vid_source ===
                                                          "2"
                                                        ? "Vdocipher"
                                                        : "Vimeo"}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        
                                          <td className="text-center">
                                            
                                            <Tooltip title="View">
                                              <a
                                                onClick={() =>
                                                  handleView(item?.videos[0]?.id)
                                                }
                                                className="btn btn-info text-white"
                                              >
                                                <i className="fa-regular fa-eye"></i>
                                              </a>
                                            </Tooltip>{" "}
                                          
                                            <Tooltip title="Delete">
                                              <button
                                                onClick={() =>
                                                  showConfirm(item?.videos[0]?.id)
                                                }
                                                type="button"
                                                className="btn btn-danger"
                                              >
                                                <i
                                                  className="fa fa-trash-o"
                                                  aria-hidden="true"
                                                ></i>
                                              </button>
                                            </Tooltip>{" "}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              )}
                            </table>
                            </div>
                           
                            {records1?.length <= 0 ? (
                              <h5 className="text-center">{messages}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          {/* <div className="fixtable">
                            {records1?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationVideo
                                nPages={last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={RECORDEDVIDEO}
                                setData={setRecords}
                                text={text}
                                setLast_page={setLast_page}
                                linkSearch={RECORDEDSEARCH}
                              />
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
}
