import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import * as Yup from "yup";
import Select from "react-select";
import axios from "../../../api/axios";

const EDITTESTSERIES = "/api/recorded-video/edit-submodule";
const UPDATETESTSERIES="/api/recorded-video/update-submodule"

export default function AdAdminRecoededSubEdit() {
  const { auth } = useContext(AuthContext);
  const [loading,setLoading] =useState(false)
  const [data,setData] =useState(null)
  const {id} =useParams()
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchEdit=async()=>{
    try {
      setLoading(true)
      const respone = await axios.get(`${EDITTESTSERIES}/${id}`,config)
      // console.log(respone);
      setData(respone.data.res)
      setLoading(false)
    } catch (error) {
      console.log(error);
      
    }
   
  }
  useEffect(()=>{
    fetchEdit()
  },[])

  const openNotificationWithIcon2 = (type) => {
    console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"red"}}>
         <strong>Internal Server Error </strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };
  const navigate = useNavigate();
  const initalValue = {
    name: data?.name,
    status: data?.status,
  };
  const studentSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter your name"),
    status: Yup.string().required("Please enter your status"),
  });
  const { errors, values, touched, handleChange, handleSubmit,getFieldProps } = useFormik({
    initialValues: initalValue,
    onSubmit: async (value) => {
   
      try {
        const response = await axios.post(
          UPDATETESTSERIES,
          {
            "recordedvideos_submodule_name": value.name,
            "status": value.status,
            "moduleid":data?.module_id,
            "recordedvideos-submodule-id": data?.id
          },
          config
        );
        // console.log(response);
        if (response) navigate(-1);
      } catch (error) {
        console.log(error);
        openNotificationWithIcon2("error")
      }
      // console.log(value);
    },
    validationSchema: studentSchema,
    enableReinitialize:true,
  });
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  const [country, setCountry] = useState(null);

  const options = [
    { label: "Select Status", value: "Status" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];
  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);

  return (
    <div className="main-wrapper">
      {/* <StudentHeader activeMenu={"Profile"} /> */}
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Profile Details</h3>
                    <p>
                      You have full control to manage your own account setting.
                    </p>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head">
                      <h4>Edit Test Series</h4>
                      <p>Edit Test Series information.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-control-label">
                              Enter Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter your Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Select Exam</label>

                            <Select
                              className=" select country-select"
                              name="sellist1"
                              options={options}
                              defaultValue={options[0]}
                              placeholder="Select "
                              onChange={setCountry}
                              styles={style}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Select Sub-Exam</label>

                            <Select
                              className=" select country-select"
                              name="sellist1"
                              options={options}
                              defaultValue={options[0]}
                              placeholder="Select "
                              onChange={setCountry}
                              styles={style}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Select Batch</label>

                            <Select
                              className=" select country-select"
                              name="sellist1"
                              options={options}
                              defaultValue={options[0]}
                              placeholder="Select "
                              onChange={setCountry}
                              styles={style}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Select Syllabus</label>

                            <Select
                              className=" select country-select"
                              name="sellist1"
                              options={options}
                              defaultValue={options[0]}
                              placeholder="Select "
                              onChange={setCountry}
                              styles={style}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Select Subject</label>

                            <Select
                              className=" select country-select"
                              name="sellist1"
                              options={options}
                              defaultValue={options[0]}
                              placeholder="Select "
                              onChange={setCountry}
                              styles={style}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Select Faculty</label>

                            <Select
                              className=" select country-select"
                              name="sellist1"
                              options={options}
                              defaultValue={options[0]}
                              placeholder="Select "
                              onChange={setCountry}
                              styles={style}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label
                              className="form-control-label"
                              style={{ marginRight: "10px", marginTop: "30px" }}
                            >
                              Status :
                            </label>
                            <br />
                            <div class="form-check form-check-inline">
                            <input
                                  class="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("status").onChange}
                                  value={"0" || values?.status}
                                  checked={values?.status === "0"}
                                />
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >
                                Draft
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                            <input
                                  class="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio2"
                                  onChange={getFieldProps("status").onChange}
                                  value={"1" || values?.status}
                                  checked={values?.status === "1"}
                                />
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >
                                Published
                              </label>
                            </div>
                            {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                          </div>
                        </div>
                        <div className="update-profile">
                          <button className="btn btn-primary">
                            Edit 
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
