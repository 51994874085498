import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import AdminHeader from "../../instructor/adminHeader";
import Footer from "../../footer";
import { Share, User11 } from "../../imagepath";
import { Link, useNavigate, useParams } from "react-router-dom";
import StudentSideBar from "../sidebar";
import CryptoJS from "crypto-js";
import Select from "react-select";
import * as Yup from "yup";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import TextEditor from "../../pages/course/addCourse/textEditor";
import AuthContext from "../../../context/AuthProvider";
import axios from "../../../api/axios";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import { Toast, Col, Row } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
const CONTACTEDIT = "/api/Web-Contact-Details/edit";
const CONTACTUPDATE = "/api/Web-Contact-Details/update";
const CONTACLISTID="/api/webcontacts/WebContactsid"


const COURSETIME="/api/course-type/show-active"

import { notification, Space } from "antd";
import { event } from "jquery";

export default function EditContactDetails() {
  const [showToast, setShowToast] = useState(false);
  const [option, setOption] = useState(null);
  const [relation, setRelation] = useState(null);
  const [errorI, setErrorI] = useState("");
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();

  const [radio, setRadio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id,detailid } = useParams();
  const [selectedcontactid, setSelectedcontactId] = useState([]);
  const [selectedContact, setSelectedContact] = useState("");


  // console.log(id,detailid)
  const [submit, setSubmit] = useState(false);
  const [selectedCoursetime, setSelectedCoursetime] = useState([]);


  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchContactList = async () => {
    try {
        const res = await axios.get(CONTACLISTID, config)
//  console.log(res,"res")
        setSelectedcontactId(res?.data)
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
        }

    }
}
useEffect(()=>{
fetchContactList()
},[])
const openNotificationWithIconSesion = (type) => {
  notification[type]({
    message: (
      <div style={{ color: "red" }}>
        <strong>Session Expired Please Login </strong>
      </div>
    ),
    duration: 1,
  });
};
 
  const fetcheditfeeds = async () => {
    try {
      setLoading(true);
      const responce = await axios.get(
        `${CONTACTEDIT}/${detailid}`,
        config
      );
    //  console.log(responce);
    //   setOption(responce?.data?.batches);
      setData(responce?.data);
    //   setSelectedCoursetimevalue(responce?.data?.courseType)


      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon2("Internal Server Error","error")
      }
    }
  };


  useEffect(() => {
    fetcheditfeeds();
  }, []);

  //  console.log(data,"relation")
  const initalValue = {
    name: data?.detail?data?.detail:"",
    status: data?.status?data?.status:"",
    webcontactsid:data?.web_contacts_id?data?.web_contacts_id:""
    // file: data?.file,

  };
  //  console.log(initalValue,"krishna")
  const courseSchema = Yup.object({
    name: Yup.string().min(2).required("Please enter your name"),
    status: Yup.string().required("Please select your status"),
  });
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Contact Details Updated</strong>
        </div>
      ),
      duration: 1,

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon2 = (text, type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  
// console.log(file,"file")
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmit(true);
      
      try {
        // console.log(value);
        setErrorI("");
        const formData = new FormData();
    
      
        formData.append("detail", values?.name);
        formData.append("status", values?.status);
        formData.append("webcontacts-id", values?.webcontactsid);

        // console.log(formData,"formdata")
        const response = await axios.post(
          `${CONTACTUPDATE}/${detailid}`,
         formData,
          config
        );
      

        if (response) {
          openNotificationWithIcon("success");
          setTimeout(() => {
            navigate(`/admin/contact-details/${id}`);
          }, 600);
        }
      } catch (error) {
        console.log(error?.response?.data?.error);
        setSubmit(false);

        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon2(error?.response?.data?.error, "error");
        }
        if (error.response.data.errors.image) {
          toast.warning(error.response.data.errors.image.toString());
          setErrorI(error.response.data.errors.image.toString());
        }
        else{
          openNotificationWithIcon2(error?.response?.data?.error, "error");

        }
      }
    },
    validationSchema: courseSchema,
  });
  
  const handleRadio = (e) => {
    if (e === "option2") {
      setFieldValue("amount", 0);
    }
    setRadio(e);
  };

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  return (
    <div className="main-wrapper">
      {/* <StudentHeader activeMenu={"Profile"} /> */}
      {/* Student Dashboard */}
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ maxWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"contact"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Contacts Details</h3>{" "}
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li className="breadcrumb-item" role="button">
                          <a onClick={() => navigate(`/admin/contact-details/${id}`)}>
                            Contacts Details
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Edit Contacts
                        </li>
                      </ol>
                    </nav>
                  </div>
                  {/* <div className="course-group mb-0 d-flex">
                    <div className="course-group-img d-flex align-items-center">
                      <Link to="student-profile">
                        <img src={User11} alt="" className="img-fluid" />
                      </Link>
                      <div className="course-name">
                        <h4>
                          <Link to="student-profile">Your avatar</Link>
                        </h4>
                        <p>PNG or JPG no bigger than 800px wide and tall.</p>
                      </div>
                    </div>
                    <div className="profile-share d-flex align-items-center justify-content-center">
                      <Link to="#;" className="btn btn-success">
                        Update
                      </Link>
                      <Link to="#;" className="btn btn-danger">
                        Delete
                      </Link>
                    </div>
                  </div> */}
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form>
                      {loading === true ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Contact Details
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Contact Details"
                                id="name"
                                name="name"
                                value={values?.name}
                                onChange={handleChange}
                              />
                              {/* {errors?.name && touched?.name ? (
                                <span style={{ color: "red" }}>
                                  {errors?.name}
                                </span>
                              ) : null} */}
                            </div>
                          </div>
                        
                         
                          <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">
                              Contact Type
                            </label>
                            
                              <select
                              className="form-control"
                              id="webcontactsid"
                              name="webcontactsid"
                              value={values.webcontactsid}
                              onChange={(e) => setFieldValue("webcontactsid", e.target.value)}
                            >
                              <option value="">Select Contact Type</option>
                              {selectedcontactid?.map((course) => (
                                <option key={course?.id} value={course?.id}>
                                  {course?.name}
                                </option>
                              ))}
                            </select>
                            {!selectedcontactid && (
                              <div className="text-danger">
                                Please select a course Type.
                              </div>
                            )}
                          </div>


                        </div>
                          

                         
                        
                          
                       
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-label">Status</label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios1"
                                  onChange={getFieldProps("status").onChange}
                                  value={"0" || values?.status}
                                  checked={values?.status === "0"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios2"
                                  checked={values?.status === "1"}
                                  onChange={getFieldProps("status").onChange}
                                  value={"1" || values?.status}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios2"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="update-profile" id="submit-button">
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
                            >
                              <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={submit}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setShowToast(true);
                                  handleSubmit();
                                }}
                              >
                                Update
                              </button>
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
                            >
                              <button
                                type="button"
                                onClick={(event) => {event.preventDefault();
                                  navigate(-1)}}
                                className="btn btn-primary cancel-btn "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>

      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
