import React, { useEffect, useState } from "react";
import Footer from "../../footer";
import { useFormik } from "formik";

import * as Yup from "yup";
import { notification, Space } from "antd";
import axios from "../../../api/axios";
import StudentHeader from "../../student/studentHeader";
import AdminHeader from "../adminHeader";
import AdminInstructorSidebar from "../adminSidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useNavigate } from "react-router-dom";

const PROFILEDEATILS = "/api/admin/profile";
const CHANGEPASSWORD = "/api/admin/reset_password";

export default function adminProfile() {
  const token = localStorage.getItem("token");
  const userid = localStorage.getItem("userid");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const navigate = useNavigate();

  const [userdata, setuserdata] = useState(null);
  const [resetpassword, setResetPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const userDetails = async () => {
    try {
      const responce = await axios.get(PROFILEDEATILS, config);
      setuserdata(responce.data);
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  console.log(userdata, "data");
  useEffect(() => {
    userDetails();
  }, []);

  const initalValue = {
    password: "",
    new_password: "",
    confirm_password: "",
  };
  const loginSchema = Yup.object({
    password: Yup.string().required("Please enter your current password"),
    new_password: Yup.string()
      .required("Please enter a password")
      .min(8, "Password must have at least  characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character, and must be at least 8 characters long"
      ),
    confirm_password: Yup.string()
      .required()
      .oneOf([Yup.ref("new_password"), null], "Password Must Match"),
  });

  const openNotificationWithIcon2 = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),

      duration: 2,
    });
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initalValue,
    onSubmit: async (val) => {
      try {
        const response = await axios.put(CHANGEPASSWORD, val, config);
        console.log(response);
        if (response) {
          openNotificationWithIcon2("success", "Password Changed", "#52c41a");
        }
        setResetPassword(false);
      } catch (err) {
        console.log(err);
        if (err.response?.status == 403) {
          openNotificationWithIcon2(
            "error",
            err.response?.data?.message,
            "red"
          );
        }
        if (err.response.status === 401) {
          localStorage.removeItem("token");
          navigate("admin/login");
        }
        else{
            openNotificationWithIcon2(
                "error",
                err.response?.data?.message,
                "red"
              );
        }
      }
    },
    validationSchema: loginSchema,
  });
  const today = new Date();
  today.setFullYear(today.getFullYear() - 12);

  const maxDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };
  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            {/* <StudentSideBar activeMenu="EditProfile" /> */}
            {/* Sidebar */}

            {/* Profile Details */}
            {/* <AdminInstructorSidebar activeMenu={"expiry"} /> */}
            <AdminInstructorSidebar activeMenu={""} />

            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Profile</h3>
                    <p>
                      You have full control to manage your own account setting.
                    </p>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    {/* <div className="personal-info-head">
                      <h4>Personal Details</h4>
                      <p>Edit your personal information and address.</p>
                    </div> */}

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-control-label">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Name"
                            id="name"
                            name="name"
                            disabled={true}
                            value={userdata?.name}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-control-label">Email</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Email Address"
                            id="email"
                            name="email"
                            disabled={true}
                            //   onChange={handleChange}
                            value={userdata?.email}
                          />
                          {/* {errors.email && touched.email ? (
                              <span style={{ color: "red" }}>
                                {errors.email}
                              </span>
                            ) : null} */}
                        </div>
                      </div>

                      <div className="update-profile">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setResetPassword(true);
                          }}
                          style={{ display: resetpassword ? "none" : "" }}
                        >
                          Reset Password
                        </button>
                      </div>
                      {resetpassword ? (
                        <div>
                          <form onSubmit={handleSubmit}>
                            <div className="form-group">
                              <label className="form-control-label">
                                Current password
                              </label>
                              <div className="pass-group">
                                <div className="password-field-wrapper">
                                  <input
                                    type={passwordType}
                                    onChange={handleChange}
                                    value={values.password}
                                    required
                                    autoComplete="off"
                                    name=""
                                    id="password"
                                    placeholder="Current Password"
                                    className="form-control"
                                  />
                                  <span
                                    className="toggle-password feather-eye"
                                    onClick={togglePassword}
                                  >
                                    {passwordType === "password" ? (
                                      <FeatherIcon icon="eye" />
                                    ) : (
                                      <FeatherIcon icon="eye-off" />
                                    )}
                                  </span>
                                </div>
                                {errors.current_password &&
                                touched.current_password ? (
                                  <span style={{ color: "red" }}>
                                    {errors.current_password}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="form-control-label">
                                Password
                              </label>
                              <div className="pass-group" id="passwordInput">
                                <div className="password-field-wrapper">
                                  <input
                                    type={passwordType1}
                                    className="form-control password-field"
                                    placeholder="Enter your password"
                                    onChange={handleChange}
                                    value={values.new_password}
                                    required
                                    autoComplete="off"
                                    name="new_password"
                                    id="new_password"
                                  />
                                  <span
                                    className="toggle-password feather-eye"
                                    onClick={togglePassword1}
                                  >
                                    {passwordType1 === "password" ? (
                                      <FeatherIcon icon="eye" />
                                    ) : (
                                      <FeatherIcon icon="eye-off" />
                                    )}
                                  </span>
                                </div>
                                {errors.new_password && touched.new_password ? (
                                  <span style={{ color: "red" }}>
                                    {errors.new_password}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="form-control-label">
                                Confirm New Password
                              </label>
                              <div className="pass-group">
                                <div className="password-field-wrapper">
                                  <input
                                    type={passwordType2}
                                    onChange={handleChange}
                                    value={values.confirm_password}
                                    required
                                    autoComplete="off"
                                    name="confirm_password"
                                    id="confirm_password"
                                    className="form-control password-field"
                                    placeholder="New Password"
                                  />
                                  <span
                                    className="toggle-password feather-eye"
                                    onClick={togglePassword2}
                                  >
                                    {passwordType2 === "password" ? (
                                      <FeatherIcon icon="eye" />
                                    ) : (
                                      <FeatherIcon icon="eye-off" />
                                    )}
                                  </span>
                                </div>
                                {errors.confirm_password &&
                                touched.confirm_password ? (
                                  <span style={{ color: "red" }}>
                                    {errors.confirm_password}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="update-profile" id="submit-button">
                              <div
                                className="btn-group mr-2"
                                role="group"
                                aria-label="First group"
                                id="custom-submit"
                              >
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={""}
                                >
                                  Submit
                                </button>
                              </div>{" "}
                              <div
                                className="btn-group mr-2"
                                role="group"
                                aria-label="First group"
                                id="custom-submit"
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    setResetPassword(false);
                                  }}
                                  className="btn btn-primary cancel-btn"
                                >
                                  Cancel
                                </button>
                              </div>{" "}
                            </div>
                          </form>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
