// import React from "react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import Footer from "../../footer";
import StudentHeader from "../studentHeader";
export default function testSeriesSummary() {
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleBack = () => {
    // Add logic for back button action
    console.log("Back button clicked");
    setShow(false); // Close the modal after handling the action
  };

  const handleSubmit = () => {
    // Add logic for submit button action
    console.log("Submit button clicked");
    setShow(false); // Close the modal after handling the action
  };
  useEffect(()=>{
    const lastVisiteStudentTest=  localStorage.getItem("lastVisiteStudentTest")
  if(lastVisiteStudentTest!==window.location.pathname){
    localStorage.removeItem("startTime");
    localStorage.removeItem("expirationTime");
   
  }
},[]);
  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"Grid2"} />
      {/* BreadCrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="/student/test-series-summary">
                        Test Series Summary
                      </Link>
                    </li>
                    {/* <li className="breadcrumb-item">Students Videos</li> */}
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* BreadCrumb */}
      {/* Page Wrapper */}
      <div className="page-content">
        {/* <div className="container">
          <Button
            style={{
              backgroundColor: "#0080ff",
              color: "white",
            }}
            variant="primary"
            onClick={handleShow}
          >
            Show Test Summary
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Test Series Summary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    <div className="card">
                      <div className="card-body">
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>Section Name</td>
                              <td
                                className="text-center"
                                style={{ marginLeft: "80px" }}
                              >
                                Science Test
                              </td>
                            </tr>
                            <tr>
                              <td>No of Question</td>
                              <td
                                className="text-center"
                                style={{ marginLeft: "80px" }}
                              >
                                3
                              </td>
                            </tr>
                            <tr>
                              <td>Answered</td>
                              <td
                                className="text-center"
                                style={{ marginLeft: "80px" }}
                              >
                                3
                              </td>
                            </tr>
                            <tr>
                              <td>Not Answered</td>
                              <td
                                className="text-center"
                                style={{ marginLeft: "80px" }}
                              >
                                0
                              </td>
                            </tr>
                            <tr>
                              <td>Mark Review</td>
                              <td
                                className="text-center"
                                style={{ marginLeft: "80px" }}
                              >
                                0
                              </td>
                            </tr>
                            <tr>
                              <td>Not Visited</td>
                              <td
                                className="text-center"
                                style={{ marginLeft: "80px" }}
                              >
                                0
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">
                                <div className="text-center d-flex justify-content-between">
                                  <button
                                    className="btn btn-primary"
                                    style={{
                                      backgroundColor: "#0080ff",
                                      color: "white",
                                    }}
                                  >
                                    Back
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    style={{
                                      backgroundColor: "#0080ff",
                                      color: "white",
                                      marginRight: "20px",
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div> */}
        <div className="container">
          <Button
            style={{
              backgroundColor: "#0080ff",
              color: "white",
            }}
            variant="primary"
            onClick={handleShow}
          >
            Show Test Summary
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title style={{ textAlign: "center", width: "100%" }}>
                Test Series Summary
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    <div className="card">
                      <div className="card-body">
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>Section Name</td>
                              <td
                                className="text-center"
                                style={{ marginLeft: "80px" }}
                              >
                                Science Test
                              </td>
                            </tr>
                            <tr>
                              <td>No of Question</td>
                              <td
                                className="text-center"
                                style={{ marginLeft: "80px" }}
                              >
                                3
                              </td>
                            </tr>
                            <tr>
                              <td>Answered</td>
                              <td
                                className="text-center"
                                style={{ marginLeft: "80px" }}
                              >
                                3
                              </td>
                            </tr>
                            <tr>
                              <td>Not Answered</td>
                              <td
                                className="text-center"
                                style={{ marginLeft: "80px" }}
                              >
                                0
                              </td>
                            </tr>
                            <tr>
                              <td>Mark Review</td>
                              <td
                                className="text-center"
                                style={{ marginLeft: "80px" }}
                              >
                                0
                              </td>
                            </tr>
                            <tr>
                              <td>Not Visited</td>
                              <td
                                className="text-center"
                                style={{ marginLeft: "80px" }}
                              >
                                0
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="text-center d-flex justify-content-between">
              <Button
                className="btn btn-primary"
                style={{
                  backgroundColor: "#0080ff",
                  color: "white",
                  marginLeft: "50px",
                }}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                className="btn btn-primary"
                style={{
                  backgroundColor: "#0080ff",
                  color: "white",
                  marginRight: "50px",
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      {/* Page Wrapper */}
      <Footer />
    </div>
  );
}
