import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../footer";
import AdminInstructorSidebar from "../../../instructor/adminSidebar";
import axios from "../../../../api/axios";
import JoinMeeting from "./JoinMeeting";
import CryptoJS from "crypto-js";
const JOIN ='/api/student-liveclass-join'
import { notification, Space } from "antd";

export const StartMeeting = () => {

 
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
    // const navigate = useNavigate();
    const token = localStorage.getItem("tokenStudent");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { id } = useParams();
    let bytes = CryptoJS.AES.decrypt(
      decodeURIComponent(id),
      "my-secret-key@admin8623009959"
    );
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
   
    const fetchJoin=async()=>{
        try {
            setLoading(true)
            const res =await axios.get(`${JOIN}/${decryptedData}`,config)
            setDetails(res?.data)
            localStorage.setItem('details', JSON.stringify(res?.data));
            setLoading(false)
        } catch (error) {
           console.log(error); 
           if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem('tokenStudent')
            navigate('/login')
          }
          else{
            openNotificationWithIcon3("error",error?.response?.data?.message)
          }
          setLoading(false)

        }
    }
    useEffect(()=>{
      fetchJoin()
    },[])
    const openNotificationWithIcon2 = (type) => {
      // console.log("toast after click")
      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"red"}}>
           <strong>Internal Server Error </strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        duration:1
      });
      };
      const openNotificationWithIcon3 = (type,message) => {
        // console.log("toast after click")
        notification[type]
        (
          
          {
          message: 
          (
            <div style={{color:"red"}}>
             <strong>{message} </strong> 
            </div>
          ),
        
          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted
      
          // </div>)
          duration:1
        });
        };
      const openNotificationWithIconSesion = (type) => {
        notification[type]({
          message: (
            <div style={{ color: "red" }}>
              <strong>Session Expired Please Login </strong>
            </div>
          ),
          duration: 1,
        });
      };
      useEffect(() => {
        const lastVisiteStudentRoute=localStorage.getItem("lastVisiteStudentRoute")
        if(lastVisiteStudentRoute){
          localStorage.removeItem("lastVisiteStudentRoute")
        }
        else{
          localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);
    
        }
      }, []);
  return (
    // <div className="main-wrapper">
    //   <div className="page-content instructor-page-content">
    //     <div className="container">
    //       <div className="row">
    //         <AdminInstructorSidebar activeMenu={"Zoom"} />
      
            <JoinMeeting />
       

    //         <Footer />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};
