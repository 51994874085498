import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as FileSaver from "file-saver";
import Footer from "../../footer";
import AdminInstructorSidebar from "../adminSidebar";
import Modal from "react-bootstrap/Modal";
import AuthContext from "../../../context/AuthProvider";
import { Button } from "react-bootstrap";
import Switch from "react-switch";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import CryptoJS from "crypto-js";

const SHOWTSET = "/api/test-series/show-testseries-question";
const QUESSTIONSTATUS = "/api/test-series/status-question";
const DELETEQUESTION = "/api/test-series/delete-question";
const TESTEXCEL = "/api/test-series/download/question/excel/sample";
const IMPORT = "/api/test-series/import-questions";

export const AdminTestListUser = () => {
  const { user } = useParams();
  const { auth } = useContext(AuthContext);
  const [excel, setExcel] = useState(null);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [show, setShow] = useState(false);
  const [list, setList] = useState(null);
  const [showTest, setShowTest] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [submoduleid, setUubmoduleid] = useState(null);
  const [file, setFile] = useState(null);
  const [ids, setIds] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleCloseImoprt = () => setShowImport(false);
  const handleShowImport = () => {
    setShowImport(true);
  };
  const config1 = {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "blob" },
    responseType: "arraybuffer",
  };
  const fetchExcel = async () => {
    try {
      const response = await axios.get(TESTEXCEL, config1);
      // console.log(response.data);
      setExcel(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchExcel();
  }, []);
  const handleEdit = (id) => {
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/test/edit-question/${ciphertexts}/${user}`);
  };

  const [checked, setChecked] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseTest = () => setShowTest(false);
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      QUESSTIONSTATUS,
      { id: id, status: result },
      config
    );
    toast.success("Status Change");
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
  };
  const handleShow = async (id) => {
    setShow(true);
    setIds(id);
  };
  const handleShowTest = async (id) => {
    setShowTest(true);
    // setId(id);
  };
  const fetchShow = async () => {
    setLoading(true);
    const response = await axios.get(`${SHOWTSET}/0/${user}`, config);
    // console.log(response);
    setUubmoduleid(response.data.submoduleid);
    setList(response.data.allQuestion.data);
    setLoading(false);
  };
  const handleDelteImoprt = async () => {
    const formData = new FormData();
    formData.append("question_import", file);
    formData.append("sub-module", submoduleid);
    try {
      const response = await axios.post(IMPORT, formData, config);
      // console.log(response);
      toast.success("Course Created");
      fetchShow();
      if (response) setShowImport(false);
    } catch (error) {
      console.log(error);
    }
  };
  const exportCsv = (excel) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const data = new Blob([excel], { type: "application/vnd.ms-excel" });
    FileSaver.saveAs(data, "Question" + fileExtension);
  };
  const DeleteTestSeries = async () => {
    if (ids) {
      try {
        setLoading(true);
        const resonse = await axios.get(`${DELETEQUESTION}/${ids}`, config);
        toast.success("Course Deleted");
        setLoading(false);
        fetchShow();
        setShow(false);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    fetchShow();
  }, []);
  return (
    <div className="main-wrapper">
      {/* <InstructorHeader activeMenu={"Dashboard"} /> */}
      <div className="page-content instructor-page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              {/* <div className="row">
                <div className="col-md-12">
                  <div className="card instructor-card">
                    <div className="card-header">
                      <h4>Order</h4>
                    </div>
                    <div className="card-body">
                      <div id="order_chart">
                        <ReactApexChart
                          options={orderChart.options}
                          series={orderChart.series}
                          type="bar"
                          height={300}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3 style={{ marginBottom: "1em" }}>Questions</h3>
                        <div className="row">
                          <div className="col-sm-6 col-lg-5">
                            <div className="profile-share d-flex align-items-center justify-content-md-start">
                              <a
                                onClick={() => exportCsv(excel)}
                                className="btn btn-success"
                              >
                                Export Excel
                              </a>
                              <a
                                onClick={() => handleShowImport()}
                                className="btn btn-danger"
                              >
                                Import Excel
                              </a>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-7">
                            <div className="text-end">
                              <Button
                                onClick={() =>
                                  navigate(`/admmin/test/add-question/${user}`)
                                }
                                className="btn btn-primary"
                              >
                                {" "}
                                <i className="fa-solid fa-plus"></i> Create New
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="comman-space pb-0">
                        <div className="settings-tickets-blk course-instruct-blk table-responsive">
                          {/* Referred Users */}
                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>Delete Question</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Are you sure want to delete this Question?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                              <Button
                                variant="primary"
                                onClick={DeleteTestSeries}
                              >
                                Delete
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          <Modal show={showImport} onHide={handleCloseImoprt}>
                            <Modal.Header closeButton>
                              <Modal.Title> Import Question </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Upload File</Form.Label>
                                  <Form.Control
                                    type="file"
                                    autoFocus
                                    onChange={(e) => setFile(e.target.files[0])}
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  {/* <Form.Label>Example textarea</Form.Label>
                                  <Form.Control as="textarea" rows={3} /> */}
                                </Form.Group>
                              </Form>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={handleCloseImoprt}
                              >
                                Close
                              </Button>
                              <Button
                                variant="primary"
                                onClick={handleDelteImoprt}
                              >
                                Import
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          <table
                            className="table table-nowrap mb-0"
                            style={{ tableLayout: "fixed" }}
                          >
                            <thead>
                              <tr>
                                <th>SR. NO.</th>
                                <th>Question</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            {loading === true ? (
                              <div
                                class="d-flex justify-content-center"
                                style={{ position: "absolute" }}
                              >
                                <div
                                  class="spinner-border text-warning"
                                  style={{
                                    left: "50%",
                                    marginLeft: "24em",
                                    marginTop: "10em",
                                  }}
                                  role="status"
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              list?.map((item, i) => {
                                return (
                                  <tbody key={i}>
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td
                                        style={{
                                          whiteSpace: " normal !important",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        {" "}
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item.name,
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {item.question_type_id == "1"
                                          ? "Subjective"
                                          : "Objective"}
                                      </td>
                                      <td>
                                        {" "}
                                        <Switch
                                          onChange={() => {
                                            handleChange(item.id, item.status);
                                          }}
                                          checked={
                                            item.status === "1" ? true : false
                                          }
                                          onColor="#86d3ff"
                                          onHandleColor="#2693e6"
                                          handleDiameter={20}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                          height={15}
                                          width={38}
                                          className="react-switch"
                                        />
                                      </td>
                                      <td>
                                        <button
                                          onClick={() => handleEdit(item.id)}
                                          type="button"
                                          class="btn btn-secondary"
                                        >
                                          <i className="fa-regular fa-pen-to-square"></i>
                                        </button>{" "}
                                        <Button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() => handleShow(item.id)}
                                        >
                                          <i className="fa-regular fa-trash-can"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })
                            )}
                          </table>
                          {/* Referred Users */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
            <ToastContainer />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
