import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useState } from "react";
import CryptoJS from "crypto-js";
import axios from "../../../api/axios";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Button, Modal } from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';
import { isArray } from "jquery";
const QUESTION = "/api/student/test-series/question";
const NEXTQUESTION = "/api/student/next-question";
const PREVIOUSQUESTION = "/api/student/previous-question";
const SELECT = "/api/student/question-number-select";
const MARK = "/api/student/markreview";
const SUBMIT = "/api/student/submit-result";
const QUESTIONSTATUS = "/api/student/test-attempt"
import "./Styles.css";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { notification, Space } from "antd";
import axiosInstance from "../../../api/axios1";

const Basic = () => {
  const { qid } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [answerResult, setAnswerResult] = useState(null);
  const [questionNumber, setQuestionNumber] = useState(null);
  const [answerQuestion, setAnswerQuestion] = useState(null);
  const [notVisitquestion, setNotVisitquestion] = useState(null);
  const [notAnswer, setNotAnswer] = useState(null);
  const [markReview, setMarkReview] = useState(null);
  const [totalminute, setTotalminute] = useState(null);
  const [show, setShow] = useState(false);
  const[response,setResponse]=useState(false)
  const [submit, setSubmit] = useState(false)

  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [valuenavigate, setValueNavigate] = useState("")
  const seconds = totalminute * 60;
  const[image ,setImage]=useState("")

  const handleBack = () => {
    setShow(false); // Close the modal after handling the action
  };
  const [test, setTest] = useState(null);
  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Too late...</div>;
    }
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
      <div className="timer">
        <div className="value">{`${minutes}:${seconds}`}</div>
      </div>
    );
  };
  // console.log(markReview,"review")

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#FFDEDA" : "white",
      };
    },
  };
  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(qid),
    "my-secret-key@admin8623009959"
  );
  const token = localStorage.getItem("tokenStudent");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const fecthquestionStatus = async () => {
    try {
      setLoading(true);
      const responce = await axiosInstance.get(`${QUESTIONSTATUS}/${decryptedData}`, config);
      if(responce?.status==200){
setResponse(true)

      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/login')
      }
      openNotificationWithIcon2("error")
    }
  }
  useEffect(() => {
      // fecthquestionStatus()
      fetchQuestion();
  }, [])
  const fetchQuestion = async () => {
    try {
      setLoading(true);
      const responce = await axiosInstance.get(`${QUESTION}/${decryptedData}`, config);
      if (responce?.data?.subTotalMarks) {
        var ciphertext = CryptoJS.AES.encrypt(
          JSON.stringify(responce?.data?.subModuleName?.id),
          "my-secret-key@admin8623009959"
        ).toString();
        let ciphertexts = encodeURIComponent(ciphertext);
        setValueNavigate(ciphertexts)
        navigate(`/student/test-result/${ciphertexts}`, {
          state: {
            notAttemptedQuestion: responce.data.notAttemptedQuestion,
            subTotalMarks: responce.data.subTotalMarks,
            subtotalWrongQuestion: responce.data.subtotalWrongQuestion,
            totalCorrectQuestion: responce.data.totalCorrectQuestion,
            totalNumberQuestion: responce.data.totalNumberQuestion
          }
        });
      }

      history.pushState(null, document.title, location.href);
      window.addEventListener("popstate", function (event) {
        history.pushState(null, document.title, location.href);
      });
      setQuestionNumber(responce?.data?.data?.length);
      setAnswerQuestion(responce?.data?.answerQuestion);
      setNotAnswer(responce?.data?.notAnswer);
      setMarkReview(responce?.data?.markReview);
      setTotalminute(responce?.data?.totalminute);
      const result = responce?.data?.data;
      let qid = 1;
      let newResult = result?.map((v) => ({
        ...v,
        qstId: qid++,
        ansStatus: "0",
      }));
      let ids = 0;
      const array = Array.from(Array(result?.length), (it) => ({
        id: ids++,
        ans: "",
      }));
      setNotVisitquestion(newResult?.filter((e) => e.ansStatus == '0').length);
      setAnswerResult(array);
      setTest(newResult);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/login')
      }
      openNotificationWithIcon2("error")
    }
  };


  const openNotificationWithIcon2 = (type) => {
    // console.log("toast after click")
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "red" }}>
                <strong>Internal Server Error </strong>
              </div>
            ),
          duration: 1
        });
  };
  const openNotificationWithIcon3 = (type) => {
    // console.log("toast after click")
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "green" }}>
                <strong>Your Exam has been submited </strong>
              </div>
            ),
          duration: 4
        });
  };
  const openNotificationWithIcon4 = (type) => {
    // console.log("toast after click")
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "red" }}>
                <strong>No Data</strong>
              </div>
            ),
          duration: 4
        });
  };
  useEffect(() => {
  }, []);
  const changeQuestion = () => {
    // updateScore();
    handleNext();
    if (currentQuestion < test.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      // setClickedOption(0);
    } else {
      handleShow()
    }
  };
  const bankQuestion = () => {
    handlePre();
    setCurrentQuestion(currentQuestion - 1);
  };
  const handleTest = (e, id) => {
    const edit = [...answerResult];
    const res = edit.find((it) => it.id + 1 === id);
    res.ans = e;
    setAnswerResult(edit);
  };
  const handleTestSubmit = async (id) => {
    // console.log(id,"id")
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    try {
      const res = await axiosInstance.post(
        SUBMIT,
        { subModuleId: +decryptedData, answer: answerResult.map((e) => e.ans), answerStatus: test.map((e) => e.ansStatus), questionId: test.map((e) => e.id) },
        config
      );

      setValueNavigate(ciphertexts)
      navigate(`/student/test-result/${ciphertexts}`, {
        state: {
          notAttemptedQuestion: res.data.notAttemptedQuestion,
          subTotalMarks: res.data.subTotalMarks,
          subtotalWrongQuestion: res.data.subtotalWrongQuestion,
          totalCorrectQuestion: res.data.totalCorrectQuestion,
          totalNumberQuestion: res.data.totalNumberQuestion
        },
      });
    
    }
    catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/login')
      }
      openNotificationWithIcon2("error")
    }
  };
  const handleNext = async () => {
    const edit = [...test];
    const res = edit.find((e) => e.id == test[currentQuestion].id);
  
    if (answerResult[currentQuestion].ans == '') {
      if (res.ansStatus == 3) {
        // If the question is marked for review and no answer is selected, preserve the review status
      } else {
        res.ansStatus = '1'; // Set as Not Answered
        setTest(edit);
      }
    }
  
    if (answerResult[currentQuestion].ans) {
      if (res.ansStatus !== '3') { // Check if the question is not already marked for review
        res.ansStatus = '2'; // Set as Answered
        setTest(edit);
      }
    }
  
    setAnswerQuestion(test.filter((e) => e.ansStatus == '2').length);
    setNotVisitquestion(test.filter((e) => e.ansStatus == '0').length);
    setNotAnswer(test.filter((e) => e.ansStatus == '1').length);
    setMarkReview(test.filter((e) => e.ansStatus == '3').length);
  };
  
  
  const handlePre = async () => {
    const edit = [...test]
    const res = edit.find((e) => e.id == test[currentQuestion].id)
    if (answerResult[currentQuestion].ans == '') {
      if (res.ansStatus == 3) {

      } else {
        res.ansStatus = '1';
        setTest(edit);
      }

    }
    if (answerResult[currentQuestion].ans) {
      res.ansStatus = '2';
      setTest(edit);
    }

    setAnswerQuestion(test.filter((e) => e.ansStatus == '2').length);
    setNotVisitquestion(test.filter((e) => e.ansStatus == '0').length);
    setNotAnswer(test.filter((e) => e.ansStatus == '1').length);
    setMarkReview(test.filter((e) => e.ansStatus == '3').length);


  };
  const handleSelect = async (e, id, qid) => {
    e.preventDefault();
    const edit = [...test]
    const res = edit.find((e) => e.id == test[currentQuestion].id)
    if (answerResult[currentQuestion].ans == '') {
      if (res.ansStatus == 3) {

      } else {
        res.ansStatus = '1';
        setTest(edit);
      }

    }
    if (answerResult[currentQuestion].ans) {
      res.ansStatus = '2';
      setTest(edit);
    }
    setAnswerQuestion(test.filter((e) => e.ansStatus == '2').length);
    setNotVisitquestion(test.filter((e) => e.ansStatus == '0').length);
    setNotAnswer(test.filter((e) => e.ansStatus == '1').length);
    setMarkReview(test.filter((e) => e.ansStatus == '3').length);
    setCurrentQuestion(qid - 1);
  };
  // };



  const handleMarkReview = async () => {
    const edit = [...test];
    const res = edit.find((e) => e.id === test[currentQuestion].id);
  
    // Update ansStatus to Marked for Review regardless of whether an answer is selected or not
    res.ansStatus = '3'; // Marked for Review
  
    // Update the count of questions marked for review
    setMarkReview(test.filter((e) => e.ansStatus === '3').length);
  
    setTest(edit);
  };
  

  function getSize() {
    if (window.innerWidth >= 992) {
      // Desktop view
      return 88;
    } else if (window.innerWidth >= 768) {
      // Tablet view
      return 70;
    } else {
      // Mobile view
      return 44;
    }
  }
  const handeldesc = (one, two) => {

    return test[two].description?.split(':')[0]
  }
  //  handeldesc();

  const handelimage = (one, two) => {
    const imageUrl = test[two].description?.split('<br>')[1];
    return imageUrl;
  }
  const handleSubmit = async (id) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    setSubmit(true)

    try {
      const res = await axiosInstance.post(
        SUBMIT,
        { submoduleid: +decryptedData, answer: answerResult.map((e) => e.ans), answerStatus: test.map((e) => e.ansStatus), questionId: test.map((e) => e.id) },
        config
      );
// console.log(res,"res")
      setValueNavigate(ciphertexts)
      openNotificationWithIcon3("success")

      navigate("/student/test-series")

      // navigate(`/student/test-result/${ciphertexts}`, {
      //   state: {
      //     notAttemptedQuestion: res.data.notAttemptedQuestion,
      //     subTotalMarks: res.data.subTotalMarks,
      //     subtotalWrongQuestion: res.data.subtotalWrongQuestion,
      //     totalCorrectQuestion: res.data.totalCorrectQuestion,
      //     totalNumberQuestion: res.data.totalNumberQuestion
      //   },
      // });
         localStorage.removeItem("startTime");
       localStorage.removeItem("expirationTime");
       setStartTime("");
       setExpirationTime("");
       setSubmit(false)

      setShow(false); // Close the modal after handling the action
     
    }
    catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/login')
      }
      setSubmit(false)

      openNotificationWithIcon2("error")
    }
  };
  const lastVisiteStudentRoute = localStorage.getItem("lastVisiteStudentRoute")

  useEffect(() => {
    if (lastVisiteStudentRoute) {
      localStorage.removeItem("lastVisiteStudentRoute")
    }
    else {
      localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);

    }
  }, []);

  // local storage
  // const startTime = new Date().getTime(); // Get the current time in milliseconds
  // const testDuration = totalminute; // The duration of the test in minutes
  // const expirationTime = startTime + testDuration *60*1000; // Calculate expiration time in milliseconds

  // // Store the values in localStorage
  // localStorage.setItem('testStartTime', startTime.toString());
  // localStorage.setItem('testExpirationTime', expirationTime.toString());
  // window.onload = function () {
  //   const startTime = parseInt(localStorage.getItem('testStartTime'), 10);
  //   const expirationTime = parseInt(localStorage.getItem('testExpirationTime'), 10);

  //   if (!isNaN(startTime) && !isNaN(expirationTime)) {
  //     const currentTime = new Date().getTime();
  //     if (currentTime >= expirationTime) {
  //       // Test time has expired, take appropriate action.
  //       alert('Time is up! Your test has expired.');
  //     } else {
  //       // Calculate remaining time in seconds
  //       const remainingTimeInSeconds = Math.floor((expirationTime - currentTime) / 1000);
  //       console.log(`Remaining time: ${remainingTimeInSeconds} seconds`);

  //       // Start a timer (if needed) to handle the remaining time
  //     }
  //   } else {
  //     // Timer data doesn't exist, set the timer as usual
  //     const testDuration = 200; // The duration of the test in minutes
  //     const expirationTime = new Date().getTime() + testDuration * 60 * 1000;
  //     localStorage.setItem('testStartTime', new Date().getTime().toString());
  //     localStorage.setItem('testExpirationTime', expirationTime.toString());

  //     // Start a timer (if needed) for the full test duration
  //   }
  // };
  // // const interval = setInterval(checkRemainingTime, 1000);
  const totalDuration = totalminute * 60; // Convert minutes to seconds

  const storedStartTime = localStorage.getItem("startTime");
  const storedExpirationTime = localStorage.getItem("expirationTime");

  const [startTime, setStartTime] = useState(storedStartTime || "");
  const [expirationTime, setExpirationTime] = useState(
    storedExpirationTime ? parseInt(storedExpirationTime, 10) : ""
  );

  const setTimerValues = (duration) => {
    const currentTime = new Date().getTime();
    const expiration = new Date(currentTime + duration * 1000).getTime();

    setStartTime(currentTime);
    setExpirationTime(expiration);

    localStorage.setItem("startTime", currentTime.toString());
    localStorage.setItem("expirationTime", expiration.toString());
  };

  useEffect(() => {
    if (!startTime && totalDuration) {
      setTimerValues(totalDuration);
    }
  }, [totalDuration]);

  const resetTimer = () => {

    handleTestSubmit(test[currentQuestion]?.sub_module_id);
  };

  const remainingTime = expirationTime
  ? Math.max(0, Math.floor((expirationTime - new Date().getTime()) / 1000))
  : totalDuration;

const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};



  const [key, setKey] = useState(0);
  const currentTime = new Date().getTime();
  const elapsedTime = startTime ? Math.floor((currentTime - startTime) / 1000) : 0;

  // Calculate the remaining time by subtracting the elapsed time from totalDuration
  const remainingTimeAfterRefresh = Math.max(0, remainingTime - elapsedTime);
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault(); // Prevent right-click context menu
    };

    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'i') {
        event.preventDefault(); // Prevent Ctrl+Shift+I (developer tools)
      }
    };

    // Add event listeners when the component mounts
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listeners when the component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  // console.log(window.location.pathname,"krihna",`student/test-series/question/${qid}`)
  localStorage.setItem("lastVisiteStudentTest", window.location.pathname);
  useEffect(() => {
    const lastVisiteStudentTest = localStorage.getItem("lastVisiteStudentTest")
    if (lastVisiteStudentTest !== window.location.pathname) {
      localStorage.removeItem("startTime");
      localStorage.removeItem("expirationTime");
      setStartTime("");
      setExpirationTime("");
    }
  })

  // useEffect(() => {

  //   const checkLocalStorageValues = () => {
  //     const startTimevalue = localStorage.getItem('startTime');
  //     const expirationTimevalue = localStorage.getItem('expirationTime');

  //     if (startTimevalue =="" || expirationTimevalue == "") {

  //       if (test && test[currentQuestion] && test[currentQuestion].sub_module_id) {

  //         handleTestSubmit(test[currentQuestion].sub_module_id);
  //       } else {
  //         console.error('Unable to call handleTestSubmit. Missing or invalid data.');
  //       }
  //     }
  //   };

  //   // Initial check when the component mounts
  //   checkLocalStorageValues();

  //   // Set up an interval to check local storage values every 30 seconds
  //   const intervalId = setInterval(checkLocalStorageValues, 3000);

  //   // Clean up the interval when the component unmounts
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };
  
  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
    const handleCollapseClick = () => {
      setIsCollapsed(!isCollapsed);
  }
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [timerActive, setTimerActive] = useState(true);

  useEffect(() => {
    let interval;

    if (timerActive && totalminute > 0) {
      interval = setInterval(() => {
        setTotalminute(prevTime => prevTime - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timerActive, totalminute]);

  const formatTimeSegment = (time) => {
    return String(time).padStart(2, '0');
  };

  const formatTime1 = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${formatTimeSegment(hours)}:${formatTimeSegment(minutes)}:${formatTimeSegment(seconds)}`;
  };
  const novalue=()=>{
   setTimeout(()=>{
    navigate("/student/test-series")

   },2000)
  }

// console.log(test,"test")
  return (
    <>
   {loading === true ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        >
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          { test?.length>0 && test ? (
            <div>
              <div className="">
                <div
                  className="row main-grid-1"
                  style={{ 
                  boxShadow: "0 1px 2px rgba(0,0,0,.2)",
                  background:"linear-gradient(to right, #bfcde2, rgb(200 212 202))" ,
paddingTop:"10px",
paddingBottom:"10px"
                
                }}
                >
                  <div
                    className={`col-md-${isCollapsed ? "3" : "5"}`}
                    style={{ justifyContent: "start" ,display:"flex"}}
                  >
                    <div style={{ alignSelf: "center" ,marginLeft:"10px" }}>
                      <h4>Questions <span className="ml-2">{currentQuestion + 1}</span></h4>
                    </div>
                  </div>
                  <div  className={`col-md-${isCollapsed ? "5" : "7"}`}>
                    <div
                      className="d-flex justify-content-start justify-content-md-end"
                     
                    >
                      <div className="">
                  <div>
                    <CountdownCircleTimer
                      key={key}
                      isPlaying
                      duration={remainingTime} // Use the total duration of the exam
                      initialRemainingTime={remainingTimeAfterRefresh}
                      colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                      colorsTime={[
                        remainingTime / 4,
                        remainingTime / 3,
                        remainingTime / 2,
                        0,
                      ]}
                      size={70}
                      strokeWidth={6}
                      // onComplete={resetTimer}
                      onComplete={() => {
                        // do your stuff here
                        handleSubmit(test[currentQuestion].sub_module_id);
                      }}
                    >
                      {({ remainingTime }) => formatTime(remainingTime)}
                    </CountdownCircleTimer>

                  </div>
                </div>
                    </div>
                  </div>
                </div>
              </div>
              
                {test ? (
                  <div className="">
                    {/* image div */}
                    <div className="row" style={{ padding: "20px", backgroundColor:"#a3c4e61f"}}>
 
                    
   {handelimage(test,currentQuestion)?
   <div
                        className={`col-md-${isCollapsed ? "9" : "12"}`}
                        style={{
                          height: "500px",
                          overflowY: "auto",
                          marginTop: "20px",
                        }}
                      >
                      
                        <div 
                      //   style={{ height:"300px",
                      // overflow:"auto"}}
                      >
                        {/* <h5 style={{color:"rgb(134, 134, 134)"}}>Questions </h5> */}

                        <div className="add"
                        style={{marginTop:"10px"}}
                        >
                          <h5 style={{marginLeft:"5px"}}>
                            <div
                              className="text-increase"
                              dangerouslySetInnerHTML={{
                                __html: test[currentQuestion]?.name,
                              }}
                            />
                            
                          </h5>
                          
                        </div>
                      {handelimage(test, currentQuestion)?
                      <div className="add"
                      style={{textAlign:"center",marginTop:"20px",
                      // height:"300px",
                      // overflow:"auto"
                    }}
                      >
<h5
style={{color:"rgb(134, 134, 134)"}}
 >
   <div
     className="text-increase"
     dangerouslySetInnerHTML={{
       __html: handeldesc(test, currentQuestion),
     }}
     style={{ paddingLeft: "10px",fontSize:"22px" }}
   />
 </h5>
 <div style={{marginTop:"10px"}}>
      {ReactHtmlParser(handelimage(test, currentQuestion))}
    </div>

                      </div>
                      :<></>
}  
</div>
                        <div className="add-course-form d-flex "
                          style={{padding:"0px 0px 30px",marginLeft:"5px",marginTop:"10px"}}

                        >
                          <form>
                            <div className="form-group">
                              {test[currentQuestion].option_1 == "" ? (
                                ""
                              ) : (
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    checked={
                                      answerResult[currentQuestion].ans === "1"
                                    }
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="1"
                                    // key={i}
                                    onClick={(e) =>
                                      handleTest(
                                        e.target.value,
                                        test[currentQuestion].qstId
                                      )
                                    }
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios1"
                                    value={test[currentQuestion].option_1}
                                    style={{}}
                                  >
                                    {test[currentQuestion].option_1}
                                  </label>
                                </div>
                              )}
                              {test[currentQuestion].option_2 == "" ? (
                                ""
                              ) : (
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios2"
                                    checked={
                                      answerResult[currentQuestion].ans === "2"
                                    }
                                    value="2"
                                    // key={i}
                                    onClick={(e) =>
                                      handleTest(
                                        e.target.value,
                                        test[currentQuestion].qstId
                                      )
                                    }
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios2"
                                    value={test[currentQuestion].option_2}
                                  >
                                    {test[currentQuestion].option_2}
                                  </label>
                                </div>
                              )}

                              {test[currentQuestion].option_3 == "" ? (
                                ""
                              ) : (
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios3"
                                    value="3"
                                    checked={
                                      answerResult[currentQuestion].ans === "3"
                                    }
                                    // key={i}
                                    onClick={(e) =>
                                      handleTest(
                                        e.target.value,
                                        test[currentQuestion].qstId
                                      )
                                    }
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios3"
                                    value={test[currentQuestion].option_3}
                                  >
                                    {test[currentQuestion].option_3}
                                  </label>
                                </div>
                              )}

                              {test[currentQuestion].option_4 == "" ? (
                                ""
                              ) : (
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios4"
                                    value="4"
                                    checked={
                                      answerResult[currentQuestion].ans === "4"
                                    }
                                    // key={i}
                                    onClick={(e) =>
                                      handleTest(
                                        e.target.value,
                                        test[currentQuestion].qstId
                                      )
                                    }
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios4"
                                    value={test[currentQuestion].option_4}
                                  >
                                    {test[currentQuestion].option_4}
                                  </label>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>:<div
                        className={`col-md-${isCollapsed ? "9" : "12"}`}
                        style={{
                          height: "480px",
                          overflowY: "auto",
                          marginTop: "20px",
                        }}
                      >
                      
                        <div>
                                                {/* <h5 style={{color:"rgb(134, 134, 134)"}}>Questions </h5> */}

                        <div className="add"
                        style={{marginTop:"10px"}}
                        >
                          <h5 style={{marginLeft:"5px",fontSize:"18px"}}>
                            <div
                              className="text-increase"
                              dangerouslySetInnerHTML={{
                                __html: test[currentQuestion]?.name,
                              }}
                            />
                          </h5>
                          
                        </div>
                      {handelimage(test, currentQuestion)?
                      <div className="add"
                      style={{textAlign:"center",marginTop:"20px"}}
                      >
<h5
style={{color:"rgb(134, 134, 134)"}}
 >
   <div
     className="text-increase"
     dangerouslySetInnerHTML={{
       __html: handeldesc(test, currentQuestion),
     }}
     style={{ paddingLeft: "10px",fontSize:"22px" }}
   />
 </h5>
 <div style={{marginTop:"10px"}}>
      {ReactHtmlParser(handelimage(test, currentQuestion))}
    </div>

                      </div>
                      :<></>
}  
</div>
                        <div className="add-course-form d-flex "
                          style={{padding:"0px 0px 30px",marginLeft:"5px",marginTop:"10px"}}

                        >
                          <form>
                            <div className="form-group">
                              {test[currentQuestion].option_1 == "" ? (
                                ""
                              ) : (
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    checked={
                                      answerResult[currentQuestion].ans === "1"
                                    }
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="1"
                                    // key={i}
                                    onClick={(e) =>
                                      handleTest(
                                        e.target.value,
                                        test[currentQuestion].qstId
                                      )
                                    }
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios1"
                                    value={test[currentQuestion].option_1}
                                    style={{}}
                                  >
                                    {test[currentQuestion].option_1}
                                  </label>
                                </div>
                              )}
                              {test[currentQuestion].option_2 == "" ? (
                                ""
                              ) : (
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios2"
                                    checked={
                                      answerResult[currentQuestion].ans === "2"
                                    }
                                    value="2"
                                    // key={i}
                                    onClick={(e) =>
                                      handleTest(
                                        e.target.value,
                                        test[currentQuestion].qstId
                                      )
                                    }
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios2"
                                    value={test[currentQuestion].option_2}
                                  >
                                    {test[currentQuestion].option_2}
                                  </label>
                                </div>
                              )}

                              {test[currentQuestion].option_3 == "" ? (
                                ""
                              ) : (
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios3"
                                    value="3"
                                    checked={
                                      answerResult[currentQuestion].ans === "3"
                                    }
                                    // key={i}
                                    onClick={(e) =>
                                      handleTest(
                                        e.target.value,
                                        test[currentQuestion].qstId
                                      )
                                    }
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios3"
                                    value={test[currentQuestion].option_3}
                                  >
                                    {test[currentQuestion].option_3}
                                  </label>
                                </div>
                              )}

                              {test[currentQuestion].option_4 == "" ? (
                                ""
                              ) : (
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios4"
                                    value="4"
                                    checked={
                                      answerResult[currentQuestion].ans === "4"
                                    }
                                    // key={i}
                                    onClick={(e) =>
                                      handleTest(
                                        e.target.value,
                                        test[currentQuestion].qstId
                                      )
                                    }
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios4"
                                    value={test[currentQuestion].option_4}
                                  >
                                    {test[currentQuestion].option_4}
                                  </label>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>}                  

                     
                      

                      <div className={`col-md-3`}>
                        {/* Collapsible Toggle */}
                        {(window.innerWidth < 992 ||
                          window.innerWidth > 992) && (
                          <button
                            className="btn  offcanvas-toggle"
                            type="button"
                            style={{ textAlign:"center" ,height:"40px",width:"40px",backgroundColor:"grey",color:"white",borderRadius:"50%"}}
                            onClick={handleCollapseClick}
                          >
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16"
        style={{marginLeft:"-8px",marginTop:"-2px"}}
        >
  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
</svg>
                          </button>
                        )}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "linear-gradient(to right, #bfcde2, rgb(200 212 202))",
                        boxShadow: "0 -1px 2px rgba(0,0,0,.2)",
                        paddingBottom:"20px",
                        paddingTop:"20px"
                      }}
                    >
                      <div className={`col-md-${isCollapsed ? "5" : "8"}`}
                      style={{display:"flex"}}
                      >
                      
                      <div className="row d-flex add-course-btns">
                          <div
                            className="col-md-6"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <button type="button" class="btn btn-black"
                              onClick={bankQuestion}
                              id="custom-button1"

                              disabled={test[currentQuestion].qstId === 1}
                            >
                              Back
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button type="button" class="btn btn-warning"
                                                    id="custom-button1"
                                                    style={{color:"white",justifyContent:"center"}}

                            
                             onClick={() => handleMarkReview()}
                            >
                              Mark As Review
                            </button>
                          </div>
                        </div>
                        
                        
                      </div>
                      <div className={`col-md-${isCollapsed ? "3" : "4"}`}>
                        <div className="row add-course-btns">
                          <div className="col-md-6">
                          <button id="custom-button1" type="button" className="btn btn-success-dark"
                         onClick={changeQuestion}
                        >
                          Next
                        </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className={`col-md-${isCollapsed ? "8" : "12"}`}
                      style={{display:"flex"}}
                      >
                         <div className="button-container add-course-btns"
                      style={{ justifyContent: "space-between" }}>
                      
                        <button id="custom-button1"
                          type="button"
                          className="btn btn-black"
                          onClick={bankQuestion}
                          disabled={test[currentQuestion].qstId === 1}
                        >
                          Back 
                        </button>
                     
                        <button id="custom-button1"  type="button" class="btn btn-warning"
                        style={{justifyContent:"center",color:"white"}}
                        onClick={() => handleMarkReview()}
                        >
                          Mark as Review
                        </button>
                        <button id="custom-button1" type="button" className="btn btn-success-dark"
                         onClick={changeQuestion}
                        >
                          Next
                        </button>
                      </div>
                      </div> */}
                    </div>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header>
                        <Modal.Title
                          style={{ textAlign: "center", width: "100%" }}
                        >
                          Test Series Summary
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="container">
                          <div className="row justify-content-center">
                            <div className="col-md-10">
                              <div className="card">
                                <div className="card-body">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <td>Section Name</td>
                                        <td
                                          className="text-center"
                                          style={{ marginLeft: "80px" }}
                                        >
                                          Test
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>No of Question</td>
                                        <td
                                          className="text-center"
                                          style={{ marginLeft: "80px" }}
                                        >
                                          {/* {sessionStorage.getItem(
                                        "totalNumberQuestion"
                                      )} */}
                                          {questionNumber}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Answered</td>
                                        <td
                                          className="text-center"
                                          style={{ marginLeft: "80px" }}
                                        >
                                          {answerQuestion}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Not Answered</td>
                                        <td
                                          className="text-center"
                                          style={{ marginLeft: "80px" }}
                                        >
                                          {notAnswer}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Mark Review</td>
                                        <td
                                          className="text-center"
                                          style={{ marginLeft: "80px" }}
                                        >
                                          {markReview}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Not Visited</td>
                                        <td
                                          className="text-center"
                                          style={{ marginLeft: "80px" }}
                                        >
                                          {notVisitquestion}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="text-center d-flex justify-content-between">
                        <Button
                          className="btn btn-primary"
                          style={{
                            backgroundColor: "#0080ff",
                            color: "white",
                            marginLeft: "50px",
                          }}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <Button
                          className="btn btn-primary"
                          style={{
                            backgroundColor: "#0080ff",
                            color: "white",
                            marginRight: "50px",
                          }}
                          onClick={() =>
                            handleSubmit(test[currentQuestion].sub_module_id)
                          }
                          disabled={submit}
                        >
                          Submit
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    {isCollapsed && (
                      <div
                        className="offcanvas offcanvas-end show"
                        tabIndex="-1"
                        id="offcanvasWithBothOptions"
                      >
                        <div className="offcanvas-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="offcanvas"
                            onClick={handleCollapseClick}
                            
                          />
                        </div>
                        <div
                          className="row d-flex"
                          style={{
                          
                            
                            paddingBottom: "7px",
                          }}
                        >
                          <div
                            className="col-lg-12"
                            // style={{border:"1px solid blue",justifyContent:"space-between"}}
                          >
                            <div className="widget-setcount"
                            style={{marginBottom:"0px",padding: "10px 30px 10px",  borderBottom: "1px solid rgb(224, 224, 224) ",}}
                            >
                              <div
                                className="row"
                                style={{ justifyContent: "space-evenly" }}
                              >
                                <div
                                  className="col-lg-6 col-md-6 col-sm-6"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <label
                                      htmlFor=""
                                      className="label-without-margin"
                                      style={{color:"white",fontSize:"16px"}}

                                    >
                                      Answered
                                    </label>
                                    <button
                                      className="btn btn-success"
                                      id="ans-question"
                                      style={{ width: "50px" }}
                                    >
                                      {answerQuestion}
                                    </button>
                                  </div>
                                </div>
                                <div
                                  className="col-lg-6 col-md-6 col-sm-6"
                                  style={{ marginTop: "10px",}}
                                >
                                  <div className="d-flex align-items-center justify-content-between"
                                  style={{marginRight:"5px"}}
                                  >
                                    <label
                                      htmlFor=""
                                      className="label-without-margin"
                                      style={{color:"white",fontSize:"16px"}}

                                    >
                                      Not Answered
                                    </label>
                                    <button
                                      className="btn btn-danger"
                                      id="notans-question"
                                      style={{ width: "50px" ,marginBottom:"15px"}}
                                    >
                                      {notAnswer}
                                    </button>
                                  </div>
                                </div>
                                <div
                                  className="col-lg-6 col-md-6 col-sm-6"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <label htmlFor=""
                                    className="label-without-margin"
                                    style={{color:"white",fontSize:"16px"}}
                  

                                    >Not Attempted</label>
                                    <button
                                      className="btn btn-light"
                                      style={{ width: "50px",fontWeight:600 }}
                                    >
                                      {notVisitquestion}
                                    </button>
                                  </div>
                                </div>
                                <div
                                  className="col-lg-6 col-md-6 col-sm-6"
                                  style={{ marginTop: "19px" }}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <label htmlFor=""
                                    className="label-without-margin"
                                    style={{color:"white",fontSize:"16px"}}

                                    
                                    >For Review</label>
                                    <button
                                      className="btn btn-warning text-white"
                                      id="mark-question"
                                      style={{ width: "50px" }}
                                    >
                                      {markReview}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="offcanvas-body">
                        <h5 style={{ textAlign: "start" ,color:"white"}}>
                                Question Panel
                              </h5>
                          <div
                            className="row"
                            style={{ height: "370px", overflow: "auto", }}
                          >
                             
                              
                         
                            <div className="col-md-12">
                              <div className="category-tab tickets-tab-blk customHeading"
                              style={{marginLeft:"0px"}}
                              >
                                <ul class="nav nav-justified row"
                                >
                                  {test.map((it, index) => (
                                    <li
                                      class="nav-item col-md-3 col-lg-2"
                                      key={it.id}
                                      style={{ margin: "5px", flexGrow: "0", }}
                                    >
                                      <Link
                                        to="#all"
                                        class={
                                          it.ansStatus === "0"
                                            ? "badge badge-light"
                                            : it.ansStatus === "1"
                                            ? "badge badge-danger text-white"
                                            : it.ansStatus === "2"
                                            ? "badge badge-success text-white"
                                            : "badge badge-warning text-white"
                                        }
                                        style={{
                                          // backgroundColor:"grey",color:"white",
                                          width: "60px",
                                          height: "60px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: "10px",
                                          textDecoration: "none",
                                          borderRadius:"8px"
                                          
                                        }}
                                        onClick={(e) => {
                                          handleSelect(e, it.id, it.qstId);
                                        }}
                                      >
                                        <p
                                          style={{
                                            textAlign: "center",
                                            marginBottom: "-5px",
                                          }}
                                        >
                                          
                                          {it.qstId}
                                        </p>
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{ marginTop: "0px",
                            }}
                            >
                              <div class="category-tab tickets-tab-blk customHeading"
                              style={{marginLeft:"0px"}}
                              >
                                <button
                                  type="button"
                                  class="btn btn-success-dark "
                                  style={{
                                    width: "100%",
                                   
                                    textAlign: "center",
                                    fontSize: "20px",
                                  }}
                                  onClick={handleShow}
                                >
                                  Submit Test
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
             
            </div>
          ) : (
            <>
            ""
            </>
          )}
          
        </>
      )}
    </>
  );
};

export default Basic;