import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import Footer from "../../footer";
import StudentHeader from "../../student/studentHeader";
import axios from "../../../api/axios";
import male from "../../../assets/img/slider/home-slider-2.png";
import { Course26, StudentIcon, User1 } from "../../imagepath";
const TEST = "/api/student/test-series/course/submodule";
import { notification, Space, Tooltip } from "antd";
import "./style.css";
import FooterStudent from "../../fotterstudent";
import axiosInstance from "../../../api/axios1";
import StudentSideBar from "../../student/sidebar";
const QUESTIONSTATUS = "/api/student/test-attempt";
import {
  Course10,
  Course11,
  Course12,
  Course13,
  Course14,
  Icon1,
  InstructorProfileBg,
  TimerStart,
  User15,
} from "../../imagepath";
import { get } from "react-hook-form";
export default function AddStudentTestCourseModule() {
  const { testid } = useParams();
  // console.log(testid)
  localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState(null);
  const navigate = useNavigate();
  const tokenStudent = localStorage.getItem("tokenStudent");
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const [data, setData] = useState(null);
  const[analytics,setAnalytics]=useState([])
  const [responsettempt, setResponseattempt] = useState(false);
  const handelShowTest=(testid,id)=>{
    navigate(`/student/test-series-module/${testid}`)
    getVideo(id)
      }
  const getVideo = async (id) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`${TEST}/${id?id:testid}`, config);
      //  console.log(response,"response")

      const groupedData = {};
       const analyticsdata=response?.data?.analytics
      // console.log(analyticsdata,"data")
      // setAnalytics(analyticsdata)

      response.data?.message?.forEach((parentItem) => {
        // console.log(parentItem,"parenitem")
        
       let coursename=parentItem?.name
      //  console.log(coursename)
      
      });
      // console.log(groupedData,"data")

      // Convert the object into an array of arrays with each object having 'name' as coursesubmodule
      const transformedData = Object.values(groupedData).map((group) => {
        //  console.log(group,"group")
        const groupName = group[0]?.coursesubmodule || "";
        const moduleid=group?.map((item)=>{
      
            return (item?.data?.currentId)
        
        }) // Take the 'name' from the first object in the group
        // console.log(moduleid,"moduledid")
         const analyticsvalue=analyticsdata?.filter((item)=>{
           // console.log("Item ID:", item?.module_id);
           // console.log("Module IDs:", moduleid);
          return moduleid?.includes(item?.module_id);

        })
        // console.log(analyticsvalue,"value for anaytics")

        return { name: groupName, data: group?.map((item) =>item?.data),moduleid:moduleid,          analyticsvalue:analyticsvalue
        };
      });
      setAnalytics(response?.data?.analytics)

      setList(response?.data?.message);
    
      setLoading(false);

    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIconSesion("error")
        navigate("/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIcon3("error", error?.response?.data?.message,);
        setTimeout(() => {
          navigate("/login");
        }, 1000); // Delay the navigation to show the notification
      }

      else{
        openNotificationWithIcon3("error",error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVideo();
  }, [testid]);
        //  console.log(list,"length")

  const [attemptid, setAttemptId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const visibleCourse = list?.slice(indexOfFirstCourse, indexOfLastCourse);
  const courseLength = list?.length ? list?.length : 0;
  const numOFTotalPage = Math?.ceil(courseLength / 10);
  // console.log(numOFTotalPage)
  const pages = [...Array(numOFTotalPage + 1).keys()].slice(1);
  //  console.log(pages,"paages")

  const handleTest = async (id) => {
    // console.log(id,"id")
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    // let date = new Date();
    // let current = moment(date).format("YYYY-MM-DD HH:mm:ss").valueOf()
    // let expiry_dates = moment(expiry_date).format("YYYY-MM-DD HH:mm:ss").valueOf()
    // console.log(`${current}     ${expiry_dates}`);
    // if (current > expiry_dates) {
    //   toast.success("Course is expired ");
    // } else {
    try {
      setLoading(true);
      const responce = await axiosInstance.get(
        `${QUESTIONSTATUS}/${id}`,
        config
      );
      if (responce) {
        setResponseattempt(true);
        navigate(`/student/test-series/question/${ciphertexts}`);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      }
      openNotificationWithIcon2("error");
    }

    // }
  };
  const handleTestResult = (id, type) => {
    if (type == 1) {
      navigate(`/student/test-series-result/${id}`);
    } else {
       navigate(`/student/test-series-result-single/${id}`);
          // navigate(`/student/test-series-result-single/downlaod/${id}`);

    }
  };
  useEffect(() => {
    const lastVisiteStudentRoute = localStorage.getItem(
      "lastVisiteStudentRoute"
    );
    if (lastVisiteStudentRoute) {
      localStorage.removeItem("lastVisiteStudentRoute");
    } else {
      localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);
    }
  }, []);
  
const maxAttemptValue = (submodule, module,attempt) => {
    // console.log('for Max attempt value', submodule, module);
    if (submodule == null && module > 0) {
      // console.log('maxAttemptValue', module);
      return module;
    } else {
      // console.log('maxAttemptValue', submodule);
      return submodule;
    }
  };
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  useEffect(() => {
    const lastVisiteStudentTest = localStorage.getItem("lastVisiteStudentTest");
    if (lastVisiteStudentTest !== window.location.pathname) {
      localStorage.removeItem("startTime");
      localStorage.removeItem("expirationTime");
    } else {
      localStorage.removeItem("startTime");
      localStorage.removeItem("expirationTime");
    }
  });
  const itemsPerPage = 10;
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);

  const renderPagination = () => {
    const pagesToShow = 6;
    const totalPageCount = Math.ceil(courseLength / itemsPerPage);

    if (totalPageCount <= pagesToShow) {
      return [...Array(totalPageCount).keys()].map((pageNum) => (
        <li
          key={pageNum}
          className={
            currentPage === pageNum + 1 ? "page-item active" : "page-item"
          }
        >
          <Link
            className="page-link"
            to="#"
            onClick={() => setCurrentPage(pageNum + 1)}
          >
            {pageNum + 1}
          </Link>
        </li>
      ));
    } else {
      if (currentPage <= 3) {
        for (let i = 0; i < 5; i++) {
          pages.push(
            <li
              key={i}
              className={
                currentPage === i + 1 ? "page-item active" : "page-item"
              }
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li key={totalPageCount - 1} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      } else if (currentPage >= totalPageCount - 2) {
        pages.push(
          <li key={0} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        for (let i = totalPageCount - 5; i < totalPageCount; i++) {
          pages.push(
            <li
              key={i}
              className={
                currentPage === i + 1 ? "page-item active" : "page-item"
              }
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
      } else {
        pages.push(
          <li key={0} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i)}
              >
                {i}
              </Link>
            </li>
          );
        }
        pages.push(
          <li key="ellipsis2" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li key={totalPageCount - 1} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      }
      return pages;
    }
  };
  const prevPageHandler = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const nextPageHandler = () => {
    if (currentPage !== numOFTotalPage) setCurrentPage(currentPage + 1);
  };
  const fecthquestionStatus = async () => {
    try {
      setLoading(true);
      const responce = await axiosInstance.get(
        `${QUESTIONSTATUS}/${attemptid}`,
        config
      );
      if (responce) {
        setResponseattempt(true);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else{
      openNotificationWithIcon2("error",error?.response?.data?.message);
      }
    }
  };
  useEffect(() => {}, []);
  // console.log(visibleCourse[0].coursesubmodule,"course")
  return (
    <div className="main-wrapper">
      <StudentHeader activeMenu={"Test"} />
      {/* BreadCrumb */}
      <div className="breadcrumb-bar">
        <div className="container" style={{ minWidth: "98%", margin: "auto" }}>
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="">Test Series</Link>
                    </li>
                    {/* <li className="breadcrumb-item">Students Videos</li> */}
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* BreadCrumb */}
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container" style={{ minWidth: "98%", margin: "auto" }}>
          <div className="row">
            <div className="all-course ">
              {/* animate__animated animate__fadeIn live-classes-heading */}
              {loading === true ? (
                <div style={{ display: "block" }} id="cover-spin"></div>
              ) : (
                <div className="row">
                    <div className="row">
                    <h3
                            className="mb-3 mb-md-3 mb-lg-3 "
                            style={{ textTransform: "capitalize",color:"#7B1FFE" }}
                          >
                            {list?list[0]?.test_sub_module?.name : ""}
                          </h3>
                    </div>
                    <div className="col-lg-12">
                       <h3
                            className="mb-3 mb-md-3 mb-lg-3"
                            style={{
                              textTransform: "capitalize",
                              color: "#7B1FFE",
                            }}
                          >
                            {analytics ? analytics[0]?.name : ""}
                          </h3>
                          <h4>Analytics</h4>
                          <div className="row mb-3">
                            <div className="col-lg-6">
                            <h5                             
                            style={{ textTransform: "capitalize",color:"#A5ACB8" }}
                            >Average :
                            <span 
                            style={{color:"black"}}
                            >{analytics?analytics[0]?.avg.toFixed(2):""}</span> </h5>
                            </div>
                            <div className="col-lg-6 justify-content-end d-flex">
                            <h5                             
                            style={{ textTransform: "capitalize",color:"#A5ACB8" }}
                            >Message :
                            <span 
                            style={{color:"black"}}
                            >{analytics?analytics[0]?.message:""}</span> </h5>
                              </div>
                            </div>
                       </div>
                  {visibleCourse?.length > 0 ? (
                    visibleCourse?.map((it, index) => {
                      //  console.log(item?.analyticsvalue,"item")

                      return (
                        <>
                       {it?.fk_test_structure_id==1?
                       <>
                      
                       <div className="col-lg-3 d-flex"
                                key={index}
                                >
                                  <div className="course-box-three">
                                    <div className="course-three-item"
                                    style={{minHeight:"180px"}}
                                    >
                                      <div className="course-three-img"
                                        style={{minHeight:"180px"}}

                                      >
                                        <Link>
                                          <img
                                            style={{
                                              maxHeight: "180px",
                                              objectFit: "cover",
                                            }}
                                            className="img-fluid"
                                            alt=""
                                            src={
                                              it?.image ? it?.image : male
                                            }
                                          />
                                        </Link>
                                      </div>
                                      <div className="course-three-content">
                                        <div className="course-three-text">
                                          <Link to="">
                                            <Tooltip
                                              title={it?.name}
                                            >
                                              <p> {it?.name}</p>
                                            </Tooltip>
                                            <div
                                              className="d-flex  row"
                                              style={{
                                                justifyContent:
                                                  "space-between",
                                              }}
                                            >
                                              <div
                                                className="col-6 "
                                                style={{
                                                  overflowWrap:
                                                    "break-word",
                                                }}
                                              >
                                                <h3 className="title instructor-text">
                                                  <Tooltip title={it?.module1?.name}>
                                                    {`${it?.module1?.name?.slice(
                                                      0,
                                                      10
                                                    )} ${
                                                      it?.module1?.name?.length > 10
                                                        ? "..."
                                                        : ""
                                                    }`}
                                                  </Tooltip>
                                                </h3>
                                              </div>

                                              <div
                                                className=" col-6 price-three-time d-inline-flex align-items-center"
                                                style={{
                                                  justifyContent: "end",
                                                }}
                                              >
                                                <i className="fa-regular fa-clock me-2"></i>
                                                <span>
                                                  {it?.module1?.duration
                                                    ? it?.module1?.duration
                                                    : 0}{" "}
                                                  Minutes
                                                </span>
                                              </div>
                                            </div>
                                          </Link>
                                        </div>
                                        <div
                                          className="student-counts-info d-flex align-items-center "
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div className="students-three-counts d-flex align-items-center ">
                                            <p
                                              className="ptag"
                                              style={{ fontSize: "13px" }}
                                            >
                                              {it?.qst_count
                                                ? it?.qst_count
                                                : 0}{" "}
                                              Questions
                                            </p>
                                            <p
                                              className="ptag"
                                              style={{ fontSize: "13px" }}
                                            >
                                              {" "}
                                              {it?.module1?.total_marks
                                                ? it?.module1?.total_marks
                                                : 0}{" "}
                                              Marks
                                            </p>
                                            <p
                                              className="ptag"
                                              style={{ fontSize: "13px" }}
                                            >
                                              {it?.module1?.negative_marks
                                                ? it?.module1?.negative_marks
                                                : 0}
                                              {"  "} Negative marks
                                            </p>
                                          </div>
                                        </div>
                                        <div className="price-three-group d-flex align-items-center justify-content-between button-container-student">
                                          <div className="price-three-view d-flex align-items-center custom-button-start"
                                          style={{width:"100%"}}
                                          >
                                            <div className="course-price-three custom-button-start"
                                                      style={{width:"100%"}}

                                            >
                                           
                                              <button
                                              onClick={() => {
                                               //  setAttemptId(
                                               //    it?.sub_module_id
                                               //  );

                                               //  handleTest(
                                               //    it?.sub_module_id
                                               //  );
                                               handelShowTest(it?.id,it?.id)

                                              }}
                                              className="test1 custom-button-start"
                                              style={{
                                                padding: "6px 30px",
                                                width:'100%'
                                              }}
                                            >
                                              Show Test
                                            </button>
                    
                                                      
                                            </div>
                                          </div>
                                         
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </>
                                :
                                <div className="col-lg-3 d-flex"
                                key={index}
                                >
                                  <div className="course-box-three">
                                    <div className="course-three-item"
                                    style={{minHeight:"180px"}}
                                    >
                                      <div className="course-three-img"
                                      
                                      >
                                        <Link>
                                          <img
                                            style={{
                                              maxHeight: "180px",
                                              objectFit: "cover",
                                            }}
                                            className="img-fluid"
                                            alt=""
                                            src={
                                              it?.image ? it?.image : male
                                            }
                                          />
                                        </Link>
                                      </div>
                                      <div className="course-three-content">
                                        <div className="course-three-text">
                                          <Link to="">
                                            <Tooltip
                                              title={it?.name}
                                            >
                                              <p> {it?.name}</p>
                                            </Tooltip>
                                            <div
                                              className="d-flex  row"
                                              style={{
                                                justifyContent:
                                                  "space-between",
                                              }}
                                            >
                                              <div
                                                className="col-6 "
                                                style={{
                                                  overflowWrap:
                                                    "break-word",
                                                }}
                                              >
                                                <h3 className="title instructor-text">
                                                  <Tooltip title={it?.module1?.name}>
                                                    {`${it?.module1?.name?.slice(
                                                      0,
                                                      10
                                                    )} ${
                                                      it?.module1?.name?.length > 10
                                                        ? "..."
                                                        : ""
                                                    }`}
                                                  </Tooltip>
                                                </h3>
                                              </div>

                                              <div
                                                className=" col-6 price-three-time d-inline-flex align-items-center"
                                                style={{
                                                  justifyContent: "end",
                                                }}
                                              >
                                                <i className="fa-regular fa-clock me-2"></i>
                                                <span>
                                                  {it?.module1?.duration
                                                    ? it?.module1?.duration
                                                    : 0}{" "}
                                                  Minutes
                                                </span>
                                              </div>
                                            </div>
                                          </Link>
                                        </div>
                                        <div
                                          className="student-counts-info d-flex align-items-center "
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div className="students-three-counts d-flex align-items-center ">
                                            <p
                                              className="ptag"
                                              style={{ fontSize: "13px" }}
                                            >
                                              {it?.qst_count
                                                ? it?.qst_count
                                                : 0}{" "}
                                              Questions
                                            </p>
                                            <p
                                              className="ptag"
                                              style={{ fontSize: "13px" }}
                                            >
                                              {" "}
                                              {it?.module1?.total_marks
                                                ? it?.module1?.total_marks
                                                : 0}{" "}
                                              Marks
                                            </p>
                                            <p
                                              className="ptag"
                                              style={{ fontSize: "13px" }}
                                            >
                                              {it?.module1?.negative_marks
                                                ? it?.module1?.negative_marks
                                                : 0}
                                              {"  "} Negative marks
                                            </p>
                                          </div>
                                        </div>
                                        <div className="price-three-group d-flex align-items-center justify-content-between button-container-student">
                                          
                                          {/* {(it?.fk_test_structure_id!==1 && it?.test_result_count>0)?
                                                     <div className="price-three-view d-flex align-items-center custom-button-start">
                                                     <div
                                                       className="course-price-three custom-button-start"
                                                       style={{
                                                         textAlign: "center",
                                                       }}
                                                     >
                                                           <button
                                                             onClick={() =>
                                                               handleTestResult(
                                                                 it?.id,
                                                                 it?.type
                                                               )
                                                             }
                                                             className="test1 test1-1 custom-button-start"
                                                             style={{
                                                               backgroundColor:
                                                                 "#4CAF50",
                                                               padding: "6px 30px",
                                                             }}
                                                           >
                                                             Result
                                                           </button>
                                                     </div>
                                                   </div>:
                                                  ""
                                                    } */}
                                                     <div className="price-three-view d-flex align-items-center custom-button-start">
                                                <div className="course-price-three custom-button-start">
                                                   {
(it?.test_result===null||
  maxAttemptValue(
    it?.attempts,
    it?.module1?.attempt,
    ) > it?.test_result?.attempt
  // it?.submoduleattempts!==null?it?.test_result[0]?.attempt<it?.submoduleattempts:it?.test_result[0].attempts<it.submoduleattempts
  // ||it?.coursemoduleattempts!==null?it?.test_result[0]?.attempt<it?.coursemoduleattempts:it?.test_result[0].attempts<it.coursemoduleattempts
  // || it?.test_result[0]?.attempts< it?.submoduleattempts

                                                    // it?.submoduleattempts <5 ||
                                                    // it?.coursemoduleattempts < 5
                                                    // it?.test_result[0]?.attempt<5
                                                    ) &&(
                                                    
                                                    <button
                                                      onClick={() => {
                                                        setAttemptId(
                                                          it?.id
                                                        );

                                                        handleTest(
                                                          it?.id
                                                        );
                                                      }}
                                                      className="test1 custom-button-start"
                                                      style={{
                                                        padding: "6px 30px",
                                                      }}
                                                    >
                                                      Start Test
                                                    </button>
                                                  )} 
                                                         
                                                </div>
                                              </div>
                                              <div className="price-three-view d-flex align-items-center custom-button-start">
                                                <div
                                                  className="course-price-three custom-button-start"
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {it?.test_result?.attempt >
                                                    0  && (
                                                      <button
                                                        onClick={() =>
                                                          handleTestResult(
                                                            it.id,
                                                            it?.type
                                                          )
                                                        }
                                                        className="test1 test1-1 custom-button-start"
                                                        style={{
                                                          backgroundColor:
                                                            "#4CAF50",
                                                          padding: "6px 30px",
                                                        }}
                                                      >
                                                        Result
                                                      </button>
                                                    )}
                                                </div>
                                              </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                }
                        
                                </>
                       
                      );
                    })
                  ) : (
                    <h2 className="text-center">No Test</h2>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {visibleCourse?.length > 0 ? (
          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-12">
              <div className="fixtable">
                <ul
                  className="pagination lms-page d-flex align-items-center justify-content-center"
                  style={{ marginLeft: "1.5em" }}
                >
                  <li
                    className="page-item prev"
                    onClick={() => prevPageHandler()}
                  >
                    <Link className="page-link" to="#" tabIndex={-1}>
                      <i className="fas fa-angle-left" />
                    </Link>
                  </li>
                  {renderPagination()}

                  <li
                    className="page-item next"
                    onClick={() => nextPageHandler()}
                  >
                    <Link className="page-link" to="#">
                      <i className="fas fa-angle-right" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <ToastContainer />
      {/* Page Wrapper */}
      <FooterStudent />
    </div>
  );
}
