import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import { Link, useNavigate } from "react-router-dom";
import StudentSideBar from "../sidebar";
import Select from "react-select";
import axios from "../../../api/axios";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import FeatherIcon from "feather-icons-react";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "antd";
import moment from "moment";
import AdminHeader from "../../instructor/adminHeader";
import "./index.css";
import { notification, Space } from "antd";

const ADDSTUDENT = "/api/student-register";
const CENTERSLIST = "/api/public/academy_centers";

const initalValue = {
  name: "",
  email: "",
  password: "",
  phone: "",
  dob: "",
  gender: "",
  status: "",
  course: "",
  refrence:"",
  remark:"",
  // fk_center_id: "", // Add center field for selecting center
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const studentSchema = Yup.object({
  name: Yup.string().min(2).max(100).required("Please enter your name"),
  email: Yup.string().email().required("Please enter your email"),
  phone: Yup.string()
    .max(10)
    .required("Please enter your phone number")
    .matches(phoneRegExp, "Phone number is not valid"),
  dob: Yup.date()
    .min("1969-11-13", "Date is too early")
    .max("2023-11-13", "Date is too further")
    .required("Please Enter date of birth"),
  status: Yup.string().required("Please select your status"),
  gender: Yup.string().required("Please select your gender"),
  // fk_center_id: Yup.object().nullable().required("Please select a center"), // Add center validation
});

export default function AddUser() {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(false);
  const [centers, setCenters] = useState([]);
  const [centersId, setCentersId] = useState("");


  const openNotificationWithIcon = (type, text) => {
    notification[type]({
      message: (
        <div style={{ color: type === "success" ? "#52c41a" : "red" }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  useEffect(() => {
    const CentersList = async () => {
      try {
        const response = await axios.get(CENTERSLIST);
        setCenters(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    };

    CentersList();
  }, []);
console.log(centersId)
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldError,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    onSubmit: async (value, { setFieldError }) => {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      setSubmit(true);
      const dataToSend = { ...value, fk_center_id: centersId };
      console.log(dataToSend,"data") 
      try {
        const response = await axios.post(ADDSTUDENT, dataToSend, config);
        if (response) openNotificationWithIcon("success", "Student Created");

        setTimeout(() => {
          navigate("/admin/student-list");
        }, 800);
      } catch (error) {
        setSubmit(false);
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIcon("error", error?.response?.data?.message);
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
        else{
          console.log(error.response.data?.message)
          openNotificationWithIcon("error", error.response.data?.message);

        }
       
      }
    },
    validationSchema: studentSchema,
  });
  const today = new Date();
  today.setFullYear(today.getFullYear() - 12);

  const maxDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  const [passwordType, setPasswordType] = useState("password");
const handelcenterchange=(value)=>{
  console.log(value)
setCentersId(value?.value)
}

  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div className="container" style={{ minWidth: "100%", marginTop: "-15px" }}>
          <div className="row">
            <AdminInstructorSidebar activeMenu={"StudentList"} />
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Student</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li onClick={() => navigate("/admin/student-list")} className="breadcrumb-item" role="button">
                          <a>All Students</a>
                        </li>
                        <li className="breadcrumb-item active" role="button" aria-current="page">
                          Add Student
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="checkout-form personal-address add-course-info">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Enter Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Your Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Email Address
                            </label>
                            <input
                              type="text"
                              name="email"
                              id="email"
                              value={values.email}
                              onChange={handleChange}
                              className="form-control"
                              placeholder="Enter Your Email Address"
                            />
                            {errors.email && touched.email ? (
                              <span style={{ color: "red" }}>
                                {errors.email}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Phone</label>
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              onChange={handleChange}
                              value={values.phone}
                              className="form-control"
                              placeholder="Enter Your Phone"
                            />
                            {errors.phone && touched.phone ? (
                              <span style={{ color: "red" }}>
                                {errors.phone}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Select Center</label>
                            <Select
  options={centers?.map(center => ({ value: center.id, label: center.name }))}
  placeholder="Select Center"
  onChange={handelcenterchange}
/>


                            {errors.fk_center_id && touched.fk_center_id ? <span style={{ color: "red" }}>{errors.fk_center_id}</span> : null}
                          </div>
                        </div>
                       
                        <div className="col-lg-6">
                          <div
                            className="form-group"
                            // style={{ marginTop: "-8px" }}
                          >
                            <label className="form-control-label">
                              Date of Birth
                            </label>
                            {/* <input
                              type="date"
                              className="form-control"
                              id="dob"
                              value={values.dob}
                              onChange={handleChange}
                              name="dob"
                              placeholder="Birth of Date"
                            /> */}
                            <input
                              type="date"
                              name="dob"
                              id="dob"
                              autoCorrect="off"
                              autoComplete="off"
                              value={values.dob}
                              onChange={handleChange}
                              max={maxDate}
                              className="form-control"
                              placeholder="Enter your date of birth"
                              
                            />

                            {errors.dob && touched.dob ? (
                              <span style={{ color: "red" }}>{errors.dob}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Intrested Course</label>
                            <input
                        type="course"
                        name="course"
                        id="course"
                        autoCorrect="off"
                        autoComplete="off"
                        value={values.course}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="intrested course"
                        required
                      />
                      {errors.course && touched.course ? (
                        <span style={{ color: "red" }}>{errors.course}</span>
                      ) : null}
                           </div>
                          </div>
                          <div className="col-lg-6">
                              <div className="form-group">
                      <label className="form-control-label">Refrence</label>
                      <input
                        type="text"
                        name="refrence"
                        id="refrence"
                        autoComplete="off"
                        autoCorrect="off"
                        value={values.refrence}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="refrence"
                        required
                      />
                      {errors.refrence && touched.refrence ? (
                        <span style={{ color: "red" }}>{errors.refrence}</span>
                      ) : null}
                    </div>
                              </div>
                              <div className="col-lg-6">
                                
                    <div className="form-group">
                      <label className="form-control-label">Remark</label>
                      <input
                        type="text"
                        name="remark"
                        id="remark"
                        autoComplete="off"
                        autoCorrect="off"
                        value={values.remark}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="remark"
                        required
                      />
                      {errors.remark && touched.remark ? (
                        <span style={{ color: "red" }}>{errors.remark}</span>
                      ) : null}
                    </div>
                              </div>


                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <label className="text-center">Gender</label>
                              </div>
                            </div>
                            <div style={{ marginTop: "0.5em" }}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("gender").onChange}
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="inlineRadio2"
                                  onChange={getFieldProps("gender").onChange}
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  onChange={getFieldProps("gender").onChange}
                                  id="inlineRadio3"
                                  value="2"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio3"
                                >
                                  Other
                                </label>
                              </div>
                            </div>
                            <div>
                              {errors.gender && touched.gender ? (
                                <span style={{ color: "red" }}>
                                  {errors.gender}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Password
                            </label>
                            <div className="password-field">
                              <input
                                type={passwordType}
                                className="form-control"
                                name="password"
                                id="password"
                                placeholder="Password"
                                onChange={handleChange}
                                value={values.password}
                                readOnly={isPasswordGenerated}
                              />

                              <span
                                className=""
                                style={{
                                  position: "relative",
                                  top: "-2em",
                                  left: "26em",
                                }}
                                onClick={togglePassword}
                              >
                                {passwordType === "password" ? (
                                  <FeatherIcon icon="eye" />
                                ) : (
                                  <FeatherIcon icon="eye-off" />
                                )}
                              </span>
                              <div style={{ marginTop: "-1.5em" }}>
                                {errors.password && touched.password ? (
                                  <span style={{ color: "red" }}>
                                    {errors.password}
                                  </span>
                                ) : null}
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div
                            className="form-check"
                            style={{ marginTop: "40px" }}
                          >
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                                onChange={(e) => handleGen(e)}
                              />
                              Generate password
                            </label>
                            <span>
                              {" "}
                              <Tooltip title="Auto Generated password will be combination of first four letters of your name, date and month of DOB. Example">
                                {" "}
                                <i
                                  className="fa fa-question-circle"
                                  aria-hidden="true"
                                ></i>
                              </Tooltip>
                            </span>
                          </div>
                        </div> */}
                        <div className="col-lg-6">
                          <div
                            className="form-group"
                            // style={{ marginTop: "10px" }}
                          >
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <label className="text-center">Status</label>
                              </div>
                            </div>
                            <div style={{ marginTop: "0.5em" }}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios1"
                                  onChange={getFieldProps("status").onChange}
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios2"
                                  onChange={getFieldProps("status").onChange}
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios2"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                            <div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <div
                            className="form-group"
                          >
                            <label className="form-label">Status</label>

                            <Select
                              className="select country-select"
                              name="status"
                              options={options}
                              defaultValue={options[0]}
                              onChange={(option) =>
                                setFieldValue("status", option.value)
                              }
                              placeholder="Select Status"
                              styles={style}
                            ></Select>
                            {errors.status && touched.status ? (
                              <span style={{ color: "red" }}>
                                {errors.status}
                              </span>
                            ) : null}
                          </div>
                        </div> */}
                        {/* 
                        <div className="col-lg-6">
                          <div
                            className="form-check"
                            style={{ marginTop: "30px" }}
                          >
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                              />
                              Mail credentials to user
                            </label>
                          </div>
                        </div> */}

                        <div className="update-profile" id="submit-button">
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={submit}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate("/admin/student-list");
                                // windowTop(0,0)
                              }}
                              className="btn btn-primary cancel-btn"
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
