import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Course10,
  Course11,
  Course12,
  Course13,
  Course14,
  Course15,
  Course16,
  Course17,
  Icon1,
  Icon2,
  User,
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
  Share,
} from "../../../imagepath";
import axios from "../../../../api/axios";
import { useState } from "react";
const GETCOURSE = "/api/free-course";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { event } from "jquery";
import "./gridinner.css";
import { notification, Space } from "antd";
import PaginationStudent from "../addCourse/PaginationStudentSub";
import axiosInstance from "../../../../api/axios1";

const GridInnerPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const [data, setData] = useState([]);
  const tokenStudent = localStorage.getItem("tokenStudent");
  const [list, setList] = useState(null);
  const [listdata, setlistdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [all, setAll] = useState(false);
  const [free, setFree] = useState(false);
  const [searchvalue, setSearch] = useState("");
  const [paid, setPaid] = useState(false);
  const [freeCourses, setFreeCourses] = useState([]);
  const [PaidCourses, setPaidCourses] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [last_page, setLast_page] = useState("");

  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const getCourse = async () => {
    try {
      setLoading(true);
      const reponse = await axiosInstance.get(GETCOURSE, config);
      setList(reponse?.data?.data);
      setData(reponse?.data?.data);
      setlistdata(reponse?.data);
      setLoading(false);
      setLast_page(reponse?.data?.last_page);
      setCurrentPage(reponse?.data?.current_page)
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      }
      openNotificationWithIcon2("error");
    }
  };
  useEffect(() => {
    getCourse();
  }, []);
  // console.log(currentPage,"pages",last_page)
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const visibleCourse = list?.slice(indexOfFirstCourse, indexOfLastCourse);

  const courseLength = data?.length ? data?.length : 0;
  const numOFTotalPage = Math.ceil(courseLength / 10);
  const pages = [...Array(numOFTotalPage + 1).keys()].slice(1);

  const navigate = useNavigate();


  useEffect(() => {
    if (data) {
      const filteredFreeCourses = list?.filter((item) => item?.amount == 0);
      setFreeCourses(filteredFreeCourses);
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      const filteredPaidCourses = list?.filter((item) => item?.amount != 0);
      setPaidCourses(filteredPaidCourses);
    }
  }, [data]);


  const Filter = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    console.log(searchTerm, "searchterm"); 
    setSearch(searchTerm);

    if (!searchTerm) {
      getCourse();
      return;
    }
  
    const searchlist = data?.filter((t) =>
      t.name.toLowerCase().includes(searchTerm)
    );
    // console.log(searchlist,"serachlist")
    setData(searchlist);
  };

  const RadioCourses = (event) => {
    if (event?.name == "paid") {
      var paidcourse = list?.filter((item) => Number(item?.amount) > 0);
      
      if (event.checked) {
        setPaid(!paid);
        setFree(false);
        setAll(false);
      }

      setData(paidcourse);
    }
    if (event?.name == "free") {
      var freeCourses = list?.filter((item) => item.amount == 0);
      console.log(freeCourses)
      if (event?.checked) {
        setFree(!free);
        setPaid(false);
        setAll(false);
      }
      setData(freeCourses);
    }
    if (event?.name == "all") {
      // var allCourse = data?.slice(index
      if (event?.checked) {
        setAll(!all);
        setPaid(false);
        setFree(false);
      }
      getCourse();
      return;
    }
  };
  // console.log(searchvalue,"serach")
  const ClearOptions = () => {
    // console.log(searchvalue,"serach in clear ")
    setSearch(" ");
    // console.log(searchvalue,"serach in after ")

    setAll(false);
    setPaid(false);
    setFree(false);
    // setData(data)
    getCourse();
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1023);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const itemsPerPage = 10;

  return (
    <>
      <div className="showing-list">
        <div className="row">
          <div className="col-lg-6">
            <div className="d-flex align-items-center">
              <div
                className="show-result"
                style={{
                  justifyContent: "space-between",
                  // marginBottom:"0px"
                }}
              >
                <h3 class="mb-3 mb-md-4">Free Courses</h3>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6">
                      <div className="show-filter add-course-info">
                        <form action="#">
                          <div className="row gx-2 align-items-center">
                            <div className="col-md-4 col-item">
                              <div className=" search-group">
                                <i className="feather-search">
                                  <FeatherIcon icon="search" />
                                </i>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search our Courses"
                                  onChange={Filter}
                                />
                              </div>
                            </div>
                            
                          </div>
                        </form>
                      </div>
                    </div> */}
        </div>
      </div>
      <div className="row">
        {isSmallScreen && (
          // <div className="col-md-12 col-12">
          //   <div
          //     className="col-lg-3 col-item "
          //     id="filter"
          //     // style={{marginBottom:"30px"}}
          //     // style={{marginTop:"-38px"}}
          //   >
          //     <div
          //       className="col-lg-12"
          //       style={{ justifyContent: "space-between" }}
          //     >
          //       <div className="clear-filter d-flex align-items-center">
          //         <h4>
          //           {/* <i className="feather-filter" /> */}
          //           <FeatherIcon icon="filter" />
          //           Filters
          //         </h4>
          //         <div className="clear-text">
          //           <button onClick={ClearOptions} style={{ border: "none" }}>
          //             CLEAR
          //           </button>
          //         </div>
          //       </div>
          //     </div>
          //     <div
          //       className="col-lg-12"
          //       // style={{border:"1px solid red"}}
          //     >
          //       <div className="show-filter add-course-info">
          //         <form action="#">
          //           <div className="">
          //             <div className="">
          //               <div
          //                 className=" search-group"
          //                 style={{ marginTop: "-15px" }}
          //               >
          //                 <i className="feather-search">
          //                   <FeatherIcon icon="search" />
          //                 </i>
          //                 <input
          //                   type="text"
          //                   className="form-control"
          //                   placeholder="Search our Courses"
          //                   onChange={Filter}
          //                   value={searchvalue}

          //                   // onKeyDown={handleKeyPress}
          //                 />
          //               </div>
          //             </div>
          //           </div>
          //         </form>
          //       </div>
          //     </div>
          //     <div className="col-lg-12" style={{ marginTop: "20px" }}>
          //       <div className="card search-filter ">
          //         <div className="card-body">
          //           <div className="filter-widget mb-0">
          //             <div className="categories-head d-flex align-items-center">
          //               <h4>Price</h4>
          //               <i className="fas fa-angle-down" />
          //             </div>
          //             <div>
          //               <label className="custom_check custom_one">
          //                 <input
          //                   type="checkbox"
          //                   name="all"
          //                   checked={all}
          //                   onClick={(event) => {
          //                     RadioCourses(event.target), setAll(true);
          //                   }}
          //                 />
          //                 <span className="checkmark" /> All ({list?.length})
          //               </label>
          //             </div>
          //             <div>
          //               <label className="custom_check custom_one">
          //                 <input
          //                   type="checkbox"
          //                   name="free"
          //                   checked={free}
          //                   onClick={(event) => {
          //                     RadioCourses(event.target), setFree(true);
          //                   }}
          //                 />
          //                 <span className="checkmark" /> Free (
          //                 {freeCourses?.length})
          //               </label>
          //             </div>
          //             <div>
          //               <label className="custom_check custom_one">
          //                 <input
          //                   type="checkbox"
          //                   name="paid"
          //                   checked={paid}
          //                   onClick={(event) => {
          //                     RadioCourses(event.target), setPaid(true);
          //                   }}
          //                 />
          //                 <span className="checkmark" /> Paid Courses (
          //                 {PaidCourses?.length})
          //               </label>
          //             </div>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div>
          ""
        )}
        <div
          className="col-lg-9 datavalue"
          // style={{ marginTop: "-35px" }}
        >
          <div className="row">
            {loading === true ? (
              <div style={{ display: "block" }} id="cover-spin"></div>
            ) : data?.length > 0 ? (
              data?.map((item) => {
                return (
                  <div className="col-lg-4 col-md-6">
                    <div
                      className="course-box course-design  "
                      style={{
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div className="product ">
                        <div className="product-img">
                          <Link>
                            {item?.image === null ? (
                              <img
                                // style={{ width: "389px", height: "271px" }}
                                id="img-grid"
                                className="img-fluid"
                                alt=""
                                src={Share}
                              />
                            ) : (
                              <img
                                // style={{ width: "389px", height: "271px" }}
                                className="img-fluid"
                                id="img-grid"
                                alt=""
                                src={`${item?.image}`}
                              />
                            )}
                          </Link>
                          <div className="price" style={{ cursor: "pointer" }}>
                            <h3>
                              {+item.amount === 0 ? (
                                <h3 className="free-color">FREE</h3>
                              ) : (
                                <>
                                  ₹{" "}
                                  {parseInt(item.amount).toLocaleString(
                                    "en-IN",
                                    {
                                      maximumFractionDigits: 0,
                                    }
                                  )}
                                </>
                              )}
                            </h3>
                          </div>
                        </div>
                        <div className="product-content">
                          <div className="course-group d-flex">
                            <div className="course-group-img d-flex">
                              {/* <Link>
                            <img
                              src=""
                              alt=""
                              className="img-fluid"
                            />
                          </Link> */}
                              <div className="course-name">
                                <h6
                                  className="mt-3"
                                  style={{
                                    marginLeft: "5px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <Link>
                                    {item?.name.slice(0, 35)}
                                    {item?.name?.length > 35 ? "..." : ""}
                                  </Link>
                                  {/* <Link>{item?.name}</Link> */}
                                </h6>
                                {/* <p>Instructor</p> */}
                              </div>
                            </div>
                            {/* <div className="course-share d-flex align-items-center justify-content-center">
                          <Link>
                            <i className="fa-regular fa-heart" />
                          </Link>
                        </div> */}
                          </div>
                          <h3 className="title">
                            <a>
                              {/* <DescriptionWithReadMore
                            description={item.description}
                            wordLimit={25}
                            onNavigate={() => navigateToCourse(item.slug)}
                          /> */}

                              {/* <DescriptionWithReadMore
                            description={item.description}
                            wordLimit={25}
                          /> */}
                              {/* <div 
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          /> */}
                            </a>
                          </h3>
                          <div className="course-info d-flex align-items-center">
                            <div className="rating-img d-flex align-items-center">
                              <img src={Icon2} alt="" />
                              <p>{item.duration} Days </p>
                            </div>
                            {/* <div className="course-view d-flex align-items-center">
                        <img src={Icon1} alt="" />
                          <p>12+ Lesson</p>
                          
                        </div> */}
                          </div>
                          {/* <div className="rating">
                        <i className="fas fa-star filled me-1" />
                        <i className="fas fa-star filled me-1" />
                        <i className="fas fa-star filled me-1" />
                        <i className="fas fa-star filled me-1" />
                        <i className="fas fa-star me-1" />
                        <span className="d-inline-block average-rating">
                          <span>4.0</span> (15)
                        </span>
                      </div> */}
                          <div className="all-btn all-category d-flex align-items-center  justify-content-between ml-md-3">
                            {/* <div className="d-flex justify-content-between  ">
                              <a
                                onClick={() =>
                                  navigate(`/student/buy-course/${item.slug}`)
                                }
                                className="btn btn-primary"
                                 id="buy"

                              >
                                BUY NOW
                              </a>
                            </div> */}
                            <div className="d-flex justify-content-between ">
                              <a
                                onClick={() =>
                                  navigate(`/student/courses/${item.slug}`)
                                }
                                className="btn btn-primary"
                                id="know"
                              >
                                KNOW MORE
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h2 className="text-center">No Course</h2>
            )}
          </div>
        </div>
        {!isSmallScreen && (
          // <div
          //   className="col-lg-3 col-item "
          //   id="filter"
          //   // style={{marginBottom:"30px"}}
          //   // style={{marginTop:"-38px"}}
          // >
          //   <div
          //     className="col-lg-12"
          //     style={{ justifyContent: "space-between" }}
          //   >
          //     <div className="clear-filter d-flex align-items-center">
          //       <h4>
          //         {/* <i className="feather-filter" /> */}
          //         <FeatherIcon icon="filter" />
          //         Filters
          //       </h4>
          //       <div className="clear-text">
          //         <button onClick={ClearOptions} style={{ border: "none" }}>
          //           CLEAR
          //         </button>
          //       </div>
          //     </div>
          //   </div>
          //   <div
          //     className="col-lg-12"
          //     // style={{border:"1px solid red"}}
          //   >
          //     <div className="show-filter add-course-info">
          //       <form action="#">
          //         <div className="">
          //           <div className="">
          //             <div
          //               className=" search-group"
          //               style={{ marginTop: "-15px" }}
          //             >
          //               <i className="feather-search">
          //                 <FeatherIcon icon="search" />
          //               </i>
          //               <input
          //                 type="text"
          //                 className="form-control"
          //                 placeholder="Search our Courses"
          //                 onChange={Filter}
          //                 value={searchvalue}

          //                 // onKeyDown={handleKeyPress}
          //               />
          //             </div>
          //           </div>
          //         </div>
          //       </form>
          //     </div>
          //   </div>
          //   <div className="col-lg-12" style={{ marginTop: "20px" }}>
          //     <div className="card search-filter ">
          //       <div className="card-body">
          //         <div className="filter-widget mb-0">
          //           <div className="categories-head d-flex align-items-center">
          //             <h4>Price</h4>
          //             <i className="fas fa-angle-down" />
          //           </div>
          //           <div>
          //             <label className="custom_check custom_one">
          //               <input
          //                 type="checkbox"
          //                 name="all"
          //                 checked={all}
          //                 onClick={(event) => {
          //                   RadioCourses(event.target), setAll(true);
          //                 }}
          //               />
          //               <span className="checkmark" /> All ({list?.length})
          //             </label>
          //           </div>
          //           <div>
          //             <label className="custom_check custom_one">
          //               <input
          //                 type="checkbox"
          //                 name="free"
          //                 checked={free}
          //                 onClick={(event) => {
          //                   RadioCourses(event.target), setFree(true);
          //                 }}
          //               />
          //               <span className="checkmark" /> Free (
          //               {freeCourses?.length})
          //             </label>
          //           </div>
          //           <div>
          //             <label className="custom_check custom_one">
          //               <input
          //                 type="checkbox"
          //                 name="paid"
          //                 checked={paid}
          //                 onClick={(event) => {
          //                   RadioCourses(event.target), setPaid(true);
          //                 }}
          //               />
          //               <span className="checkmark" /> Paid Courses (
          //               {PaidCourses?.length})
          //             </label>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div>
          ""
        )}
      </div>
      {data?.length > 0 ? (
        <div className="fixtable">
          <PaginationStudent
            nPages={last_page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            link={GETCOURSE}
            setData={setData}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default GridInnerPage;
