import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "../../../../api/axios";
import AdminInstructorSidebar from "../../../instructor/adminSidebar";
import { useFormik } from "formik";
import CryptoJS from "crypto-js";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import AdminHeader from "../../../instructor/adminHeader";
import { notification, Space } from "antd";
import Select from "react-select";

const BATCHUSERADD = "api/add/batchUser/single";
const CENTERSLIST = "/api/public/academy_centers";

const initalValue = {
    name: "",
    email: "",
    phone: "",
    admission_date: "2024-01-01T00:00:00",
    // expiry_date: "",
    // total_amount: "",
    // inst_amount: "",
    // pending_fees: "",
    // remark: "",
    gender:"",
    status: "",
    dob:"",
    refrence:"",
    remark:"",
    course:""

};
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const studentSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter your name"),
    email: Yup.string().email().required("Please enter your email"),
    phone: Yup.string().max(10).required("Please enter your phone number").matches(phoneRegExp, 'Phone number is not valid'),
    admission_date: Yup.date().min("1969-11-13", "Date is too early").max(new Date(), "Date is too further").required("Please enter admission date"),
    gender: Yup.string().required("Please select your gender"),
    dob: Yup.date()
    .min("1969-11-13", "Date is too early")
    .max(new Date(), "Date is too further")
    .required("Please Enter date of birth"),
    // expiry_date: Yup.date().required("Please enter your expiry date"),
    // total_amount: Yup.string().required("Please enter your total amount"),
    // inst_amount: Yup.string().required("Please enter your instalment amount"),
    // pending_fees: Yup.string().required("Please enter your pending fees"),
    // remark: Yup.string().required("Please enter your remark"),

});
export default function CreateBatch() {
    const { slug, id } = useParams()
    const navigate = useNavigate();
    const [submit, setSubmit] = useState(false)
    const [centers, setCenters] = useState([]);
    const [centersId, setCentersId] = useState("");
  

    let bytes = CryptoJS.AES.decrypt(
        decodeURIComponent(id),
        "my-secret-key@admin8623009959"
    );
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    var ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(slug),
        "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, text,col) => {
        notification[type]
            (
                {
                    message:
                        (
                            <div style={{ color: col }}>
                                <strong>{text}</strong>
                            </div>
                        ),
                    duartion: 1
                });
    };
    useEffect(() => {
        const CentersList = async () => {
          try {
            const response = await axios.get(CENTERSLIST);
            setCenters(response?.data?.message);
          } catch (error) {
            console.log(error);
          }
        };
    
        CentersList();
      }, []);
      const handelcenterchange=(value)=>{
        console.log(value)
      setCentersId(value?.value)
      }
    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        getFieldProps,
    } = useFormik({
        initialValues: initalValue,
        onSubmit: async (value, { setFieldError }) => {
            const token = localStorage.getItem("token");
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            setSubmit(true)
            const dataToSend = { ...value, fk_center_id: centersId };

            try {
                const response = await axios.post(BATCHUSERADD, {
                    "batch_id": decryptedData,
                    "name": value.name,
                    "email": value.email,
                    "phone": value.phone,
                    "admission_date": "2024-01-01T00:00:00",
                    "gender":value?.gender,
                    "dob":value?.dob,
                    "fk_center_id": centersId,
                    "course":value?.course,
                    "remark":value?.remark,
                    "refrence":value?.refrence,
                    "status":value?.status
                    // "exp_date": value.expiry_date,
                    // "pending_fees": value.pending_fees,
                    // "total_amount": value.total_amount,
                    // "inst_amount": value.inst_amount,
                    // "remark": value.remark
                }, config);
                if (response)
                    openNotificationWithIcon("success", response.data.success,'#52c41a')
                setTimeout(() => {
                    navigate(`/admin/batchListStudents/${slug}/${ciphertexts}`)
                }, 800);
            } catch (error) {
                console.log(error);
                if (error.response.status == 400) {
                    openNotificationWithIcon('error', error.response.data.message,'red')
                }
                // openNotificationWithIcon('error', error.response.data.errors.remark.toString())
                setSubmit(false)
                if (error.response.status == 403) {
                    openNotificationWithIcon('error', error.response.data.error,'red')
                }
                if (error?.response?.status === 401) {
                    localStorage.removeItem('token')
                    navigate('/admin/login')
                }
                setSubmit(false)
                if (error.response.status == 422) {
                    openNotificationWithIcon('error', error.response.data.message,'red')
                }
                else if (error?.response?.status === 403) {
                    localStorage.removeItem("token");
                    openNotificationWithIcon("error", error?.response?.data?.message,"red");
                    setTimeout(() => {
                      navigate("/admin/login");
                    }, 1000); // Delay the navigation to show the notification
                  }
                else{
                    openNotificationWithIcon('error', error.response.data.message,'red')
                    // openNotificationWithIcon('error', "Please Refresh the Page",'red')

                }
            }
        },
        validationSchema: studentSchema,
    });
    useEffect(() => {
        const lastVisitedRoute = localStorage.getItem("lastVisitedRoute")
        if (lastVisitedRoute) {
            localStorage.removeItem("lastVisitedRoute")
        }
        else {
            localStorage.setItem("lastVisitedRoute", window.location.pathname);

        }
    }, []);
    const openNotificationWithIcon2 = (type) => {

        notification[type]
            (

                {
                    message:
                        (
                            <div style={{ color: "red" }}>
                                <strong>Internal Server Error </strong>
                            </div>
                        ),

                    // description:
                    // (<div style={{color:"green"}}>
                    // Your Batch has Sucessfully Deleted

                    // </div>)
                    duration: 1
                });
    };
    const today = new Date();
    today.setFullYear(today.getFullYear() - 12);
  
    const maxDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  
    return (
        <div className="main-wrapper">
            <AdminHeader />
            {/* Student Dashboard */}
            <div className="page-content">
                <div className="container"
                    style={{ minWidth: "100%", marginTop: "-15px" }}
                >
                    <div className="row">
                        {/* Sidebar */}
                        <AdminInstructorSidebar activeMenu={"Batches"} />
                        {/* Sidebar */}

                        {/* Profile Details */}
                        <div className="col-xl-9 col-md-9 main-page">
                            <div className="settings-widget profile-details">
                                <div className="settings-menu p-0">
                                    <div className="profile-heading">
                                        <h3>Create Student</h3>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                                                <li
                                                    className="breadcrumb-item "
                                                    onClick={() => navigate("/admin/batchList")}
                                                    // aria-current="page"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Batches
                                                </li>
                                                <li
                                                    className="breadcrumb-item"
                                                    aria-current="page"
                                                    style={{
                                                        cursor: "pointer",
                                                        // marginLeft: "-.6em",
                                                        fontWeight: 'normal'
                                                    }}
                                                    onClick={() => navigate(-1)}
                                                >
                                                    Add Students
                                                </li>
                                                <li
                                                    className="breadcrumb-item active"
                                                    aria-current="page"
                                                    style={{
                                                        cursor: "pointer",
                                                        marginLeft: "-.7em",
                                                    }}
                                                >
                                                    Create Student
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                    <div className="checkout-form personal-address add-course-info">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Enter Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            value={values.name}
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            placeholder="Enter Your Name"
                                                        />
                                                        {errors.name && touched.name ? (
                                                            <span style={{ color: "red" }}>
                                                                {errors.name}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Email Address
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="email"
                                                            id="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            placeholder="Enter Your Email Address"
                                                        />
                                                        {errors.email && touched.email ? (
                                                            <span style={{ color: "red" }}>
                                                                {errors.email}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">Phone</label>
                                                        <input
                                                            type="text"
                                                            name="phone"
                                                            id="phone"
                                                            onChange={handleChange}
                                                            value={values.phone}
                                                            className="form-control"
                                                            placeholder="Enter Your Phone"
                                                        />
                                                        {errors.phone && touched.phone ? (
                                                            <span style={{ color: "red" }}>
                                                                {errors.phone}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Select Center</label>
                            <Select
  options={centers?.map(center => ({ value: center.id, label: center.name }))}
  placeholder="Select Center"
  onChange={handelcenterchange}
/>


                            {errors.fk_center_id && touched.fk_center_id ? <span style={{ color: "red" }}>{errors.fk_center_id}</span> : null}
                          </div>
                        </div>
                                               
                                                <div className="col-lg-6">
                          <div
                            className="form-group"
                            // style={{ marginTop: "-8px" }}
                          >
                            <label className="form-control-label">
                              Date of Birth
                            </label>
                            {/* <input
                              type="date"
                              className="form-control"
                              id="dob"
                              value={values.dob}
                              onChange={handleChange}
                              name="dob"
                              placeholder="Birth of Date"
                            /> */}
                            <input
                              type="date"
                              name="dob"
                              id="dob"
                              autoCorrect="off"
                              autoComplete="off"
                              value={values.dob}
                              onChange={handleChange}
                              max={maxDate}
                              className="form-control"
                              placeholder="Enter your date of birth"
                              
                            />

                            {errors.dob && touched.dob ? (
                              <span style={{ color: "red" }}>{errors.dob}</span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Intrested Course</label>
                            <input
                        type="course"
                        name="course"
                        id="course"
                        autoCorrect="off"
                        autoComplete="off"
                        value={values.course}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="intrested course"
                        required
                      />
                      {errors.course && touched.course ? (
                        <span style={{ color: "red" }}>{errors.course}</span>
                      ) : null}
                           </div>
                          </div>
                          <div className="col-lg-6">
                              <div className="form-group">
                      <label className="form-control-label">Refrence</label>
                      <input
                        type="text"
                        name="refrence"
                        id="refrence"
                        autoComplete="off"
                        autoCorrect="off"
                        value={values.refrence}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="refrence"
                        required
                      />
                      {errors.refrence && touched.refrence ? (
                        <span style={{ color: "red" }}>{errors.refrence}</span>
                      ) : null}
                    </div>
                              </div>
                              <div className="col-lg-6">
                                
                    <div className="form-group">
                      <label className="form-control-label">Remark</label>
                      <input
                        type="text"
                        name="remark"
                        id="remark"
                        autoComplete="off"
                        autoCorrect="off"
                        value={values.remark}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="remark"
                        required
                      />
                      {errors.remark && touched.remark ? (
                        <span style={{ color: "red" }}>{errors.remark}</span>
                      ) : null}
                    </div>
                              </div>
                                                <div className="col-lg-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <label className="text-center">Gender</label>
                              </div>
                            </div>
                            <div style={{ marginTop: "0.5em" }}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("gender").onChange}
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="inlineRadio2"
                                  onChange={getFieldProps("gender").onChange}
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  onChange={getFieldProps("gender").onChange}
                                  id="inlineRadio3"
                                  value="2"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio3"
                                >
                                  Other
                                </label>
                              </div>
                            </div>
                            <div>
                              {errors.gender && touched.gender ? (
                                <span style={{ color: "red" }}>
                                  {errors.gender}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        
                                                {/* <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Expiry Date
                                                        </label>
                                                        <div className="password-field">
                                                            <input
                                                                type='date'
                                                                className="form-control"
                                                                name="expiry_date"
                                                                id="expiry_date"
                                                                placeholder="Expiry Date"
                                                                onChange={handleChange}
                                                                value={values.expiry_date}
                                                            />
                                                            {errors.expiry_date && touched.expiry_date ? (
                                                                <span style={{ color: "red" }}>
                                                                    {errors.expiry_date}
                                                                </span>
                                                            ) : null}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div
                                                        className="form-group"
                                                    >
                                                        <label className="form-control-label">
                                                            Total Amount
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="total_amount"
                                                            value={values.total_amount}
                                                            onChange={handleChange}
                                                            name="total_amount"
                                                            placeholder="Total Amount"
                                                        />
                                                        {errors.total_amount && touched.total_amount ? (
                                                            <span style={{ color: "red" }}>{errors.total_amount}</span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div
                                                        className="form-group"
                                                    >
                                                        <label className="form-control-label">
                                                            Instalment Amount
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="inst_amount"
                                                            value={values.inst_amount}
                                                            onChange={handleChange}
                                                            name="inst_amount"
                                                            placeholder="Instalment Amount"
                                                        />
                                                        {errors.inst_amount && touched.inst_amount ? (
                                                            <span style={{ color: "red" }}>{errors.inst_amount}</span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div
                                                        className="form-group"
                                                    >
                                                        <label className="form-control-label">
                                                            Pending Fees
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="pending_fees"
                                                            value={values.pending_fees}
                                                            onChange={handleChange}
                                                            name="pending_fees"
                                                            placeholder="Pending Fees"
                                                        />
                                                        {errors.pending_fees && touched.pending_fees ? (
                                                            <span style={{ color: "red" }}>{errors.pending_fees}</span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div
                                                        className="form-group"
                                                    >
                                                        <label className="form-control-label">
                                                            remark
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="remark"
                                                            value={values.remark}
                                                            onChange={handleChange}
                                                            name="remark"
                                                            placeholder="Remark"
                                                        />
                                                        {errors.remark && touched.remark ? (
                                                            <span style={{ color: "red" }}>{errors.remark}</span>
                                                        ) : null}
                                                    </div>
                                                </div> */}
<div className="col-lg-6">
                          <div
                            className="form-group"
                            // style={{ marginTop: "10px" }}
                          >
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <label className="text-center">Status</label>
                              </div>
                            </div>
                            <div style={{ marginTop: "0.5em" }}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios1"
                                  onChange={getFieldProps("status").onChange}
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios2"
                                  onChange={getFieldProps("status").onChange}
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios2"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                            <div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                                                <div className="update-profile"
                                                    id="submit-button"

                                                >
                                                    <div
                                                        className="btn-group mr-2"
                                                        role="group"
                                                        aria-label="First group"
                                                        id="custom-submit"

                                                    >

                                                        <button type="submit" className="btn btn-primary"
                                                        // disabled={submit}

                                                        >
                                                            Submit
                                                        </button>


                                                    </div>{" "}
                                                    <div
                                                        className="btn-group mr-2"
                                                        role="group"
                                                        aria-label="First group"
                                                        id="custom-submit"

                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                navigate(-1);
                                                            }}
                                                            className="btn btn-primary cancel-btn"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>{" "}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Profile Details */}
                    </div>
                </div>
            </div>
            {/* Student Dashboard */}
            {/* <ToastContainer autoClose={3000} hideProgressBar={true} /> */}
            <Footer />
        </div>
    );
}
