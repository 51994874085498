import { Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "../../../api/axios";
import Wave from "../../../assets/img/TestResult/wavy.png";
const QUESTIONSRESULT = "api/student/test-result-analytics";
import ApexCharts from "react-apexcharts";
import Chart from "react-apexcharts";
import { schemeCategory10 } from "d3-scale-chromatic";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import MyChart from "./chart.jsx";
import BarChart from "./Barchrat";
import StackedBarChart from "./StackedBar";
import SubjectWiseGraph from "./SubjectWiseGraph";
import scorecard from "../../../assets/img/Dashboard/Scorecard.png"
import Accuracyimg from "../../../assets/img/Dashboard/Accuracy.png"
import Weightage from "../../../assets/img/Dashboard/weightage.png"

const Subjectwise = ({ result, data, data1 }) => {
  //   const [wrongAccordionOpen, setWrongAccordionOpen] = useState(false);
  //   const [leaveAccordionOpen, setLeaveAccordionOpen] = useState(false);
  const [openAccordions, setOpenAccordions] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [videoid,setvideoid]=useState([])
  const handelvimeovideo=(link)=>{
    setvideoid(link)
    handleShowForm();
  }
  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };
  const chartOptions = {
    series: [],
    chart: {
      width: "100%",
      type: "donut",
      // height:"100%"
    },
    labels: ["Attempted", "Unattempted"],
    colors:[   "#6fdc6f","#66b0e5"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    // fill: {
    //     type: 'gradient',
    //     gradient: {
    //       shade: 'dark',
    //       type: 'vertical',
    //       shadeIntensity: 0.5,
    //       inverseColors: false,
    //       opacityFrom: 1,
    //       opacityTo: 0.8,
    //       stops: [0, 100]
    //     }
    //   }
  };
  const chartOptions1 = {
    series: [],
    chart: {
      width: "100%",
      type: "pie",
      //  height:"100%"
    },
    labels: ["Correct Answers", "Wrong Answers"],
    colors:["#6fdc6f", "#f15b5b"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    // fill: {
    //     type: 'gradient',
    //     gradient: {
    //       shade: 'dark',
    //       type: 'vertical',
    //       shadeIntensity: 0.5,
    //       inverseColors: false,
    //       opacityFrom: 1,
    //       opacityTo: 0.8,
    //       stops: [0, 100]
    //     }
    //   }
  };
  // console.log(result,"result")
  return (
    <div className="" style={{ marginTop: "20px" }}>
      <div className="row" style={{ justifyContent: "center" }}>
        <div className="col-lg-10">
          {data?.length > 0
            ? data?.map((item, index) => {
              // console.log(item,"item")
                
                const seriesData = [
                  item?.subjectResult?.attemptedquestions?item?.subjectResult?.attemptedquestions:0,
                  item?.subjectResult?.unattemptedQuestios1?item?.subjectResult?.unattemptedQuestios1:0,
                ];
                const seriesData1 = [
                  item?.subjectResult?.correct?item?.subjectResult?.correct:0,
                  item?.subjectResult?.wrong?item?.subjectResult?.wrong:0 ,
                ];

                const parser = new DOMParser();
                const doc = parser.parseFromString(
                  item?.description,
                  "text/html"
                );

                // Get the text content
                const textContent = doc.body.textContent;

                // Find the image element and get its source
                const imageElement = doc.body.querySelector("img");
                const imageUrl = imageElement ? imageElement.src : "No Image";
                const wrongOpenAccordion =
                  openAccordions[`wrongAccordion${index}`] || null;
                const unattemptedOpenAccordion =
                  openAccordions[`unattemptedAccordion${index}`] || null;
const neg=item?.wrong*item?.negativeMark;
// console.log(neg,"nge")
                return (
                  <>
                    <div
                      className=""
                      style={{
                        background: " #FFFFFF",
                        borderRadius: "10px",
                        padding: "20px",
                        marginBottom: "25px",
                        border: "1px solid #e9ecef",
                      }}
                    >
                      <h4
                        style={{
                          color: "rgb(57, 44, 125)",
                          textTransform: "capitalize",
                        }}
                      >
                      Subject : {" "}  {item?.subjectResult?.name} 
                      </h4>
                      <div className="row d-flex"
                      style={{marginTop:"20px"}}
                      >
                         <div className="col-md-4 ">
                          <div>
                            <div
                              className="card instructor-card w-100"
                              style={{border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}

                            >
                              <div className="card-body d-flex"
                              style={{justifyContent:"space-between"}}
                              >
                                <div className="instructor-inner">
                                  <h6
                                    style={{ color: "black", fontWeight: 700 }}
                                  >
                                    Score
                                  </h6>
                                  <h4
                                    className="instructor-text-info"
                                    style={{ color: "red", fontWeight: 700 }}
                                  >
                                    {(item?.subjectResult?.correct *
                                      result?.eachQuestionMark).toFixed(2)-
                                      (item?.subjectResult?.wrong*item?.subjectResult
                                        ?.negativeMark)}
                                  </h4>
                                </div>
                                <div className="instructor-inner">
                                <img src={scorecard} alt="image"/>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 ">
                          <div>
                            <div
                              className="card instructor-card w-100"
                              style={{border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}

                            >
                              <div className="card-body d-flex"
                              style={{justifyContent:"space-between"}}
                              >
                                <div className="instructor-inner">
                                  <h6
                                    style={{ color: "black", fontWeight: 700 }}
                                  >
                                    Accuracy
                                  </h6>
                                  <h4
                                    className="instructor-text-success"
                                    style={{ color: "red", fontWeight: 700 }}
                                  >
                                    {(
                                      (item?.subjectResult?.correct /
                                        item?.subjectResult
                                          ?.attemptedquestions) *
                                      100
                                    ).toFixed(2)>=0?(
                                      (item?.subjectResult?.correct /
                                        item?.subjectResult
                                          ?.attemptedquestions) *
                                      100
                                    ).toFixed(2):0}
                                    %
                                  </h4>
                                </div>
                                <div className="instructor-inner">
                                <img src={Accuracyimg} alt="image"/>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                        <div className="col-md-4 ">
                          <div>
                            <div
                              className="card instructor-card w-100"
                              style={{border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}

                            >
                              <div className="card-body d-flex"
                              style={{justifyContent:"space-between"}}
                              >
                                <div className="instructor-inner">
                                  <h6
                                    style={{ color: "black", fontWeight: 700 }}
                                  >
                                    Weightage
                                  </h6>
                                  <h4
                                    className="instructor-text-warning"
                                    style={{
                                      color: "red",
                                      fontWeight: 700,
                                    }}
                                  >
                                    {(
                                      (item?.subjectResult?.count /
                                        result?.totalQuestion) *
                                      100
                                    ).toFixed(2)>=0?(
                                      (item?.subjectResult?.count /
                                        result?.totalQuestion) *
                                      100
                                    ).toFixed(2):0}
                                    %
                                  </h4>
                                </div>
                                <div className="instructor-inner">
                                <img src={Weightage} alt="image"/>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="clo-lg-10">
                          <div className="" style={{ paddingBottom: "20px" }}>
                            <div className="settings-inner-blk ">
                              <div
                                className="row"
                                style={{
                                  justifyContent: "center",
                                  padding: "10px",
                                  //   border:"1px solid red"
                                }}
                              >
                                <div className="col-lg-6">
                                  <div
                                    className="settings-widget "
                                    style={{border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                                    >
                                    <div
                                      className="settings-inner-blk p-0"
                                      // style={{
                                      //   borderBottom: "none",
                                      //   backgroundColor: "#cbd9e6",
                                      // }}
                                    >
                                      <div
                                        className="d-flex"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            marginLeft: "20px",
                                            marginTop: "15px",
                                            color: "#392C7D",
                                            fontSize:"19px"
                                          }}
                                        >
                                          Total Questions :{" "}
                                          {item?.subjectResult?.count}
                                        </h4>
                                        <h4
                                          style={{
                                            marginLeft: "20px",
                                            marginTop: "15px",
                                            color: "#392C7D",
                                            marginRight: "30px",
                                            fontSize:"19px"
                                          }}
                                        >
                                          Total Marks :{" "}
                                          {(result?.eachQuestionMark *
                                            item?.subjectResult?.count).toFixed(2)}
                                        </h4>
                                      </div>

                                      <div
                                        className="sell-course-head comman-space graph1"
                                        //   style={{ height: "265px" }}
                                      >
                                        <div className="chart">
                                          <Chart
                                            options={chartOptions}
                                            series={seriesData}
                                            type={chartOptions?.chart?.type}
                                            width={chartOptions.chart.width}
                                            
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div
                                    className="settings-widget"
                                    style={{border:"none",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}

                                  >
                                    <div
                                      className="settings-inner-blk p-0"
                                      style={{
                                        borderBottom: "none",
                                        // backgroundColor: "rgb(240 222 229)",
                                      }}
                                    >
                                      <div
                                        className="d-flex"
                                        style={{
                                        justifyContent: "space-between",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            marginLeft: "20px",
                                            marginTop: "15px",
                                            color: "#392C7D",
                                            paddingLeft:"5px",
                                            fontSize:"19px"
                                          }}
                                        >
                                          Positive Marks :{" "}
                                          {(result?.eachQuestionMark *
                                            item?.subjectResult?.correct).toFixed(2)}
                                        </h4>
                                        <h4
                                          style={{
                                            // marginLeft: "20px",
                                            marginTop: "15px",
                                            color: "#392C7D",
                                            paddingRight:"5px",
                                            fontSize:"19px",

                                            marginRight: "20px",
                                          }}
                                        >
                                          Negative Marks :{" "}
                                          {(result?.negativeMark *
                                            item?.subjectResult?.wrong).toFixed(2)}
                                        </h4>
                                      </div>

                                      <div
                                        className="sell-course-head comman-space graph1 "
                                        // style={{ height: "265px" }}
                                      >
                                        <div className="chart">
                                          <Chart
                                            options={chartOptions1}
                                            series={seriesData1}
                                            type={chartOptions1?.chart?.type}
                                            width={chartOptions1?.chart?.width}
                                            
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                        {item?.subjectResult?.wrongansquestions?.length>0?   
                                             <div
                          className="clo-lg-12"
                          style={{ marginTop: "-10px" }}
                        >
                          <h4 style={{ padding: "10px", color: "red" }}>
                            Wrong Question Analysis
                          </h4>
                          {item?.subjectResult?.wrongansquestions.map(
                            (value, index1) => {
                              const parser = new DOMParser();
                              const doc = parser.parseFromString(
                                value.description,
                                "text/html"
                              );
                              const doc1 = parser.parseFromString(
                                value?.question,
                                "text/html"
                              );
                              const questionContent = doc1.body.textContent;

                              // Get the text content
                              const textContent = doc.body.textContent;

                              // Find the image element and get its source
                              const imageElement =
                                doc.body.querySelector("img");
                              const imageUrl = imageElement
                                ? imageElement.src
                                : "No Image";
                              const isOpen = wrongOpenAccordion === index1;
                              return (
                                <>
                                  <div style={{ marginTop: "20px" }} 
                                    key={index1}

                                  >
                                    <div
                                      className="accordion"
                                      id={`wrongAccordionExample${index}-${index1}`}
                                      // key={index1}
                                      style={{ padding: "0px 20px" }}
                                    >
                                      <div className="accordion-item">
                                        <h2 className="accordion-header d-flex">
                                          <button
                                            className={`accordion-button collapsed ${
                                              isOpen ? "show" : ""
                                            }`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#wrongCollapseOne${index}-${index1}`}
                                            aria-expanded={isOpen}
                                            aria-controls={`wrongCollapseOne${index}-${index1}`}
                                            style={{
                                              backgroundColor: "#e6e6e6",
                                              color: "black",
                                            }}
                                            onClick={() => {
                                              setOpenAccordions({
                                                ...openAccordions,
                                                [`wrongAccordion${index}`]:
                                                  index1,
                                                [`unattemptedAccordion${index}`]:
                                                  null, // Close unattempted accordion
                                              });
                                            }}
                                          >
                                          {/* Q: <b>{index+1}</b>{")"}  */}
                                          Q{index + 1}
                                    
                                    {")"}{" "}
                                           {questionContent}
                                            {/* <span style={{ textAlign: "center" }}>Physiscs</span> */}
                                          </button>
                                        </h2>
                                        <div
                                          id={`wrongCollapseOne${index}-${index1}`}
                                          className={`accordion-collapse collapse ${
                                            isOpen ? "show" : ""
                                          }`}
                                          data-bs-parent={`#wrongAccordionExample${index}-${index1}`}
                                        >
                                          <div className="accordion-body">
                                            <div className="settings-widget profile-details"
                                            style={{border:"none"}}
                                            >
                                              <div className="settings-menu p-0">
                                                <div className="profile-heading">
                                                  <p
                                                    style={{
                                                      color: "black",
                                                      fontSize: "24px",
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    Subject : {value?.subject}
                                                  </p>
                                                </div>
                                                <div className="address-check-widget comman-space"
                                                style={{padding:"10px 25px"}}
                                                >
                                                  <div className="check-bill-address">
                                                    <div
                                                      className="edit-new-address wallet-method wallet-radio-blk d-flex align-items-center"
                                                      style={{
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      <label className="">
                                                        <h6>Wrong Answer :</h6>
                                                        <CloseCircleOutlined
                                                          twoToneColor="#FF0000"
                                                          style={{
                                                            fontSize: "20px",
                                                            color: "red",
                                                          }}
                                                        />

                                                        <span
                                                          className="checkmark"
                                                          style={{
                                                            fontSize: "18px",
                                                            marginLeft: "7px",
                                                            marginTop: "2px",
                                                          }}
                                                        >
                                                          {value?.your_answer}
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="edit-new-address wallet-method wallet-radio-blk d-flex align-items-center mb-0"
                                                      style={{
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      <label className="">
                                                        <h6>Correct Answer :</h6>
                                                        <CheckCircleTwoTone
                                                          twoToneColor="#52c41a"
                                                          style={{
                                                            fontSize: "20px",
                                                          }}
                                                        />

                                                        <span
                                                          className="checkmark"
                                                          style={{
                                                            fontSize: "18px",
                                                            marginLeft: "7px",
                                                            marginTop: "2px",
                                                          }}
                                                        >
                                                          {value?.answer}
                                                        </span>
                                                      </label>
                                                    </div>
                                                    {value?.description ? (
                                                      <div
                                                        className="add-new-address grad-border hvr-sweep-to-right"
                                                        style={{
                                                          marginTop: "5px",
                                                        }}
                                                      >
                                                        <h4
                                                          style={{
                                                            color: "black",
                                                            textTransform:
                                                              "capitalize",
                                                            fontSize: "20px",
                                                          }}
                                                        >
                                                          Descrption {" : "}
                                                        </h4>
                                                     
                                                        <p
                                                          style={{
                                                            color:
                                                              "rgb(134, 134, 134)",
                                                            fontSize: "24px",
                                                          }}
                                                        >
                                                          {textContent}
                                                        </p>
                                                        {imageUrl && (
                                                          <img
                                                            src={imageUrl}
                                                            alt="image"
                                                          />
                                                        )}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {value?.video ? (
                                                      <div
                                                        className="add-new-address grad-border hvr-sweep-to-right"
                                                        style={{
                                                          marginTop: "5px",
                                                        }}
                                                      >
                                                        <button className="btn btn-secondary"
                                                         onClick={()=>{
                                                          let embedUrl;
                                
                                    // Check if it's a YouTube link
                                    if (value?.video?.includes('youtube.com')) {
                                      const videoId = value.video.split('v=')[1];
                                      embedUrl = `https://www.youtube.com/embed/${videoId}`;
                                    }
                                    // Check if it's a Vimeo link
                                    else if (value?.video?.includes('vimeo.com')) {
                                      const videoId = value.video.split('/').pop();
                                      embedUrl = `https://player.vimeo.com/video/${videoId}`;
                                    }
                                    // For any other link
                                    else {
                                      embedUrl = value?.video; // Assuming it's a valid video URL
                                    }
                                    handelvimeovideo(embedUrl)
                                                        }}
                                                        
                                                        >
                                                          Watch Video
                                                        </button>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {value?.explaination ? (
                                                      <div
                                                        className="add-new-address grad-border hvr-sweep-to-right"
                                                        style={{
                                                          marginTop: "5px",
                                                        }}
                                                      >
                                                        <h6>Solution : </h6>
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              value?.explaination,
                                                          }}
                                                        />
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>:""}
                        <div>
                        <Modal
                            title="Video"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              open={isModalVisibleForm}
                            okText="Ok"
                              onOk={() => {
                                // handleAddNotification();
                                setIsModalVisibleForm(false);

                              }}
                              onCancel={() => {
                                setIsModalVisibleForm(false);
                              }}
                            >
             
<div>
 <div style={{height:"450px",width:"100%"}}>
 
      <iframe
        title="YouTube Video"
        src={videoid}
        width="80%"
        height="100%"
        frameBorder="0"
        allowFullScreen
        style={{marginTop:"-80px"}}

      ></iframe>
       {/* <VideoJs
                                      src={`https://www.youtube.com/embed/${ytcode}`}
                                      type="video/youtube"
                                    /> */}
    
    
  </div>
</div>
                            </Modal>
                        </div>
{item?.subjectResult?.unattemptedQuestios?.length>0?  <div className="col-lg-12">
                          <h4
                            style={{
                              padding: "10px",
                              color: "#8c8892",
                              marginTop: "10px",
                            }}
                          >
                            Unattempted Question Analysis
                          </h4>
                          {item?.subjectResult?.unattemptedQuestios.map(
                            (value, index1) => {
                              const parser = new DOMParser();
                              const doc = parser.parseFromString(
                                value.description,
                                "text/html"
                              );
                              const doc1 = parser.parseFromString(
                                value?.question,
                                "text/html"
                              );
                              const questionContent = doc1.body.textContent;
                              // Get the text content
                              const textContent = doc.body.textContent;

                              // Find the image element and get its source
                              const imageElement =
                                doc.body.querySelector("img");
                              const imageUrl = imageElement
                                ? imageElement.src
                                : "No Image";
                              const isOpen =
                                unattemptedOpenAccordion === index1;
                              return (
                                <>
                                  <div style={{ marginTop: "20px" }}>
                                    <div
                                      className="accordion"
                                      id={`unattemptedAccordionExample${index}-${index1}`}
                                      key={index1}
                                      style={{ padding: "0px 20px" }}
                                    >
                                      <div className="accordion-item">
                                        <h2 className="accordion-header d-flex">
                                          <button
                                            className={`accordion-button collapsed ${
                                              isOpen ? "show" : ""
                                            }`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#unattemptedCollapseOne${index}-${index1}`}
                                            aria-expanded={isOpen}
                                            aria-controls={`unattemptedCollapseOne${index}-${index1}`}
                                            style={{
                                              backgroundColor: "#e6e6e6",
                                              color: "black",
                                            }}
                                            onClick={() => {
                                              setOpenAccordions({
                                                ...openAccordions,
                                                [`unattemptedAccordion${index}`]:
                                                  index1,
                                                [`wrongAccordion${index}`]:
                                                  null, // Close wrong accordion
                                              });
                                            }}
                                          >   Q{index + 1}
                                    
                                          {")"}{" "}
                                        {questionContent}
                                            {/* <span style={{ textAlign: "center" }}>Physiscs</span> */}
                                          </button>
                                        </h2>
                                        <div
                                          id={`unattemptedCollapseOne${index}-${index1}`}
                                          className={`accordion-collapse collapse ${
                                            isOpen ? "show" : ""
                                          }`}
                                          data-bs-parent={`#unattemptedAccordionExample${index}-${index1}`}
                                        >
                                          <div className="accordion-body">
                                            <div className="settings-widget profile-details">
                                              <div className="settings-menu p-0">
                                                <div className="profile-heading">
                                                  <p
                                                    style={{
                                                      color: "black",
                                                      fontSize: "24px",
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    Subject : {" "}{value?.subject}
                                                  </p>
                                                </div>
                                                <div className="address-check-widget comman-space"
                                                style={{padding:"10px 25px"}}
                                                >
                                                  <div className="check-bill-address">
                                                    <div
                                                      className="edit-new-address wallet-method wallet-radio-blk d-flex align-items-center mb-0"
                                                      style={{
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      <label className="">
                                                        <h6>Correct Answer </h6>
                                                        <CheckCircleTwoTone
                                                          twoToneColor="#52c41a"
                                                          style={{
                                                            fontSize: "20px",
                                                          }}
                                                        />

                                                        <span
                                                          className="checkmark"
                                                          style={{
                                                            fontSize: "18px",
                                                            marginLeft: "7px",
                                                            marginTop: "2px",
                                                          }}
                                                        >
                                                          {value?.answer}
                                                        </span>
                                                      </label>
                                                    </div>
                                                    {value?.description ? (
                                                      <div
                                                        className="add-new-address grad-border hvr-sweep-to-right"
                                                        style={{
                                                          marginTop: "5px",
                                                        }}
                                                      >
                                                        <h4
                                                          style={{
                                                            color: "black",
                                                            textTransform:
                                                              "capitalize",
                                                            fontSize: "20px",
                                                          }}
                                                        >
                                                          Descrption {" : "}
                                                        </h4>
                                                        <p
                                                          style={{
                                                            color:
                                                              "rgb(134, 134, 134)",
                                                            fontSize: "24px",
                                                          }}
                                                        >
                                                          {textContent}
                                                        </p>
                                                        {imageUrl && (
                                                          <img
                                                            src={imageUrl}
                                                            alt="image"
                                                          />
                                                        )}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {value?.video ? (
                                                      <div
                                                        className="add-new-address grad-border hvr-sweep-to-right"
                                                        style={{
                                                          marginTop: "5px",
                                                        }}
                                                      >
                                                        <button className="btn btn-secondary"
                                                          onClick={()=>{
                                                            let embedUrl;
                                  
                                      // Check if it's a YouTube link
                                      if (value?.video?.includes('youtube.com')) {
                                        const videoId = value.video.split('v=')[1];
                                        embedUrl = `https://www.youtube.com/embed/${videoId}`;
                                      }
                                      // Check if it's a Vimeo link
                                      else if (value?.video?.includes('vimeo.com')) {
                                        const videoId = value.video.split('/').pop();
                                        embedUrl = `https://player.vimeo.com/video/${videoId}`;
                                      }
                                      // For any other link
                                      else {
                                        embedUrl = value?.video; 
                                      }
                                      handelvimeovideo(embedUrl)
                                                          }}
                                                        >
                                                          Watch Video
                                                        </button>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {value?.explaination ? (
                                                      <div
                                                        className="add-new-address grad-border hvr-sweep-to-right"
                                                        style={{
                                                          marginTop: "5px",
                                                        }}
                                                      >
                                                        <h6>Solution : </h6>
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              value?.explaination,
                                                          }}
                                                        />
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>:""}
                      
                      </div>
                    </div>
                  </>
                );
              })
            : ""}
        </div>
       
      </div>
    </div>
  );
};

export default Subjectwise;
