import React, { useContext, useEffect, useState } from "react";
import { InstructorHeader } from "../../instructor/header";
import Footer from "../../footer";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import FeatherIcon from "feather-icons-react";
import AdminHeader from "../adminHeader";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import CryptoJS from "crypto-js";
import Switch from "react-switch";
import AuthContext from "../../../context/AuthProvider";
import { Search } from "react-feather";
import "../adminTestSeries/index.css"
import "./index.css"
import axios from "../../../api/axios";
import { AddAdminRecordedVideos } from './../addAdminRecordedVideos/index';
const SHOWCOURSE = "/api/course-payment/show-course-payment";
const SHOWCOURSENAME = "/api/course-payment/add-course-payment";
const PAYMENTSTATUS = "/api/course-payment/status-course-payment";
const PAYMENTVIEW = "/api/course-payment/view-course-payment";
const PAYMENTDELETE = "/api/course-payment/delete-course-payment";
import { notification, Modal } from "antd";

const { confirm } = Modal;

export default function AdminPaymentMethodList() {
  const [show, setShow] = useState(false);
  const [showView, setShowView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [name, setName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      PAYMENTSTATUS,
      { cpt_id: id, status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1("success");
      handleShowView();
    }
    if(!response){
      openNotificationWithIcon2("error")
    }
    // toast.success("Status Change");
    const editCheck = [...list];
    const res = editCheck.find((it) => it.cpt_id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
  };
  const handlePayment = () => {
    navigate("/admin/show-course-payment");
  };
  const [id, setId] = useState("");
  const [list, setList] = useState(null);
  const [data, setData] = useState(null);
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { payid } = useParams();
  var bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(payid),
    "my-secret-key@admin8623009959"
  );
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const handleClose = () => setShow(false);
  const handleCloseView = () => setShowView(false);
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };
  const handleShowView = async (id) => {
    setLoadingTable(true);
    const response = await axios.post(PAYMENTVIEW, { cpt_id: id }, config);
    setData(response.data.coursepaymentinstallment);
    setLoadingTable(false);
    setShowView(true);
    setIsModalVisible(true);
  };
  const navigate = useNavigate();
  const handleView = () => { };
  const fetchName = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${SHOWCOURSENAME}/${decryptedData}`,
        config
      );
      setName(response.data.name);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
    }
  };
  useEffect(() => {
    fetchName();
  }, []);
  const handleEdit = (payid, cptid) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(payid),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    var ciphertextCpt = CryptoJS.AES.encrypt(
      JSON.stringify(cptid),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertextsCpt = encodeURIComponent(ciphertextCpt);
    navigate(
      `/admin/edit-payment-term/${ciphertexts}/${ciphertextsCpt}/${name}`
    );
  };
  const fetchPayment = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${SHOWCOURSE}/${decryptedData}`,
        config
      );
      setLoading(false);
      const course = [response.data.course];
      const CoursePaymentTerm = response.data.CoursePaymentTerm.data;
      const result = CoursePaymentTerm.map((v) => ({
        ...v,
        ...course.find((sp) => sp.id == v.course_id),
      }));
      setList(result);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
    }
  };
  useEffect(() => {
    fetchPayment();
  }, []);
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false)
  const openNotificationWithIcon = (type) => {
    console.log("toast after click")
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "#52c41a" }}>
                <strong>PaymentTerms Deleted </strong>
              </div>
            ),

          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted

          // </div>)

        });






  };
  const openNotificationWithIcon1 = (type) => {
    console.log("toast after click")
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "#52c41a" }}>
                <strong>Status updated </strong>
              </div>
            ),

          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted

          // </div>)

        });
  };
  const openNotificationWithIcon2 = (type) => {
    console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"red"}}>
         <strong>Internal Server Error </strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };
  const handleDeleteStudent = async () => {
    if (id) {
      setLoading(true);
      const resonse = await axios.get(`${PAYMENTDELETE}/${id}`, config);
      setLoading(false);
      fetchPayment();
      setShow(false);
      openNotificationWithIcon("info")
      // toast.warning("Term Deleted");
      return resonse;
    }
  };
  function showConfirm(id) {
    confirm({
      title: 'Do you want to delete this installment?',
      content:
        'When clicked the OK button, this dialog will be closed after 1 second',
      okButtonProps: { style: { backgroundColor: '#ff0100' } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${PAYMENTDELETE}/${id}`, config);
          setLoading(false);
          fetchPayment();
          setShow(false);
          openNotificationWithIcon("success")
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
          }
          openNotificationWithIcon2("error")
        }
      },
      onCancel() { },
    });
  }
  const Filter = (e) => {
    const searchValue = e.target.value;
    if (Array.isArray(listModule) && listModule) {
      const filteredRecords = listModule.filter((it) =>
        it.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      console.log(filteredRecords);
      if (filteredRecords.length > 0) {
        setListModule(filteredRecords);
      } else {
      }
      if (searchValue.length === 0) {
        setListModule(data);
      }
    }
  };
  useEffect(() => {
    const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
    if(lastVisitedRoute){
      localStorage.removeItem("lastVisitedRoute")
    }
    else{
      localStorage.setItem("lastVisitedRoute", window.location.pathname);

    }
  }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div className="container"
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Courses"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3>Payment Terms</h3>
                          <nav
                            aria-label="breadcrumb"
                            style={{ marginTop: "0.5em", marginBottom: '-1.6em' }}
                          >
                            <ol class="breadcrumb">
                              <li
                                class="breadcrumb-item"
                                style={{ cursor: "pointer" }}
                              >
                                <a
                                  onClick={() => navigate("/admin/course")}
                                >
                                  Courses
                                </a>
                              </li>
                              <li
                                class="breadcrumb-item active"
                                aria-current="page"
                                style={{ cursor: "pointer" }}
                              >
                                Payment Terms
                              </li>
                            </ol>
                          </nav>
                        </div>
                        <div className="comman-space pb-0">
                          <div className="instruct-search-blk">
                            <div className="show-filter choose-search-blk">
                              {/* <h4 className="text text-secondary">{name.slice(0,60)}</h4> */}
                              <form action="#">
                                {/* <p>Zoom App Details</p> */}
                                <div className="row gx-2 align-items-center"
                                  style={{ justifyContent: "space-between" }}

                                >
                                  <div className="col-md-6 col-item">
                                    <div className=" search-group"
                                    >
                                      {/* <Search
                                        size={16}
                                        style={{
                                          position: "absolute",
                                          left: "7px",
                                          color: "#F66962",
                                        }}
                                      />
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="search name"
                                        onChange={Filter}
                                      /> */}
                                      <h4 className="text text-secondary">
                                        {name.slice(0, 60)}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-6 col-item">
                                    <div className=" form-group select-form mb-0 d-flex justify-content-end">
                                      <Button
                                        onClick={() =>
                                          navigate(
                                            `/admin/add-payment-term/${encodeURIComponent(
                                              payid
                                            )}/${name}`
                                          )
                                        }
                                        style={{
                                          // marginLeft: "5.2em",
                                          // width:"100%"
                                        }}
                                        className="btn btn-primary"
                                        id="custom-button-1"
                                      >
                                        {" "}
                                        <i className="fa-solid fa-plus"></i>{" "}
                                        Create New
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="settings-referral-blk table-responsive">
                            {/* Instructor Users */}
                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Delete Installment</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Are you sure want to delete this installment?
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={handleDeleteStudent}
                                >
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <Modal title="View Payment"
                              okButtonProps={{ style: { backgroundColor: '#FF875A' }, children: "Import" }}
                              visible={isModalVisible}
                              okText="ok"
                              onOk={() => {
                                setIsModalVisible(false);

                              }}
                              onCancel={() => {
                                setIsModalVisible(false);
                              }}>

                              <table className="table table-nowrap mb-0">
                                <thead>
                                  <tr>
                                    <th>Inst.No</th>
                                    <th>Amount</th>
                                    <th>Duration</th>
                                  </tr>
                                </thead>
                                {loadingTable === true ? (
                                  <div
                                    class="d-flex justify-content-center"
                                    style={{ position: "absolute" }}
                                  >
                                    <div
                                      class="spinner-border text-warning"
                                      style={{
                                        left: "50%",
                                        marginLeft: "24em",
                                        marginTop: "10em",
                                      }}
                                      role="status"
                                    >
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                  </div>
                                ) : (
                                  data?.map((item, i) => {
                                    return (
                                      <tbody key={item.id}>
                                        <tr >
                                          <td>{i + 1}</td>
                                          {/* <td>
                                            ₹ {Math.round(item.inst_amount).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                            </td> */}
                                          <td>
                                            ₹{" "}
                                            {parseInt(
                                              item.inst_amount
                                            ).toLocaleString("en-IN", {
                                              maximumFractionDigits: 0,
                                            })}
                                          </td>
                                          <td>{item.inst_duration} Days</td>
                                        </tr>
                                      </tbody>
                                    );
                                  })
                                )}
                              </table>

                            </Modal>
                            {/* View Payment */}
                            <Modal show={showView} onHide={handleCloseView}>
                              <Modal.Header closeButton>
                                <Modal.Title>View Payment</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <table className="table table-nowrap mb-0">
                                  <thead>
                                    <tr>
                                      <th>Inst.No</th>
                                      <th>Amount</th>
                                      <th>Duration</th>
                                    </tr>
                                  </thead>
                                  {loadingTable === true ? (
                                    <div
                                      class="d-flex justify-content-center"
                                      style={{ position: "absolute" }}
                                    >
                                      <div
                                        class="spinner-border text-warning"
                                        style={{
                                          left: "50%",
                                          marginLeft: "24em",
                                          marginTop: "10em",
                                        }}
                                        role="status"
                                      >
                                        <span class="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    data?.map((item, i) => {
                                      return (
                                        <tbody key={item.id}>
                                          <tr >
                                            <td>{i + 1}</td>
                                            {/* <td>
                                            ₹ {Math.round(item.inst_amount).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                            </td> */}
                                            <td>
                                              ₹{" "}
                                              {parseInt(
                                                item.inst_amount
                                              ).toLocaleString("en-IN", {
                                                maximumFractionDigits: 0,
                                              })}
                                            </td>
                                            <td>{item.inst_duration} Days</td>
                                          </tr>
                                        </tbody>
                                      );
                                    })
                                  )}
                                </table>
                              </Modal.Body>
                              {/* <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                  Delete
                                </Button>
                              </Modal.Footer> */}
                            </Modal>
                            <table className="table table-nowrap mt-3">
                              <thead>
                                <tr className="text-center">
                                  {/* <th>
                                    <div className="form-check instruct-check-list">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="option1"
                                      />
                                    </div>
                                  </th> */}
                                  <th>SR. NO.</th>
                                  <th>TYPE</th>
                                  <th>PRICE</th>
                                  <th>TOTAL INSTALLMENTS</th>
                                  <th>STATUS</th>
                                  <th>ACTIONS</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                                <div
                                  class="d-flex justify-content-center"
                                  style={{ position: "absolute" }}
                                >
                                  <div
                                    class="spinner-border text-warning"
                                    style={{
                                      left: "50%",
                                      marginLeft: "24em",
                                      marginTop: "10em",
                                    }}
                                    role="status"
                                  >
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                list?.map((item, i) => {
                                  return (
                                    <tbody key={item.id}>
                                      <tr className="text-center">
                                        <td>{i + 1}</td>
                                        <td>
                                          <Link
                                            // to="student-profile"
                                            className="refer-avatar-blk  align-items-center"
                                          >
                                            <p>
                                              {" "}
                                              {item.payment_type === "0"
                                                ? "Full Payment"
                                                : "Installments"}{" "}
                                            </p>
                                          </Link>
                                        </td>
                                        {/* <td>₹ {Math.round(item.amount).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} </td> */}
                                        <td>
                                          ₹{" "}
                                          {parseInt(item.amount).toLocaleString(
                                            "en-IN",
                                            {
                                              maximumFractionDigits: 0,
                                            }
                                          )}
                                        </td>
                                        <td class="text-center">
                                          {item.total_inst_count || 0}
                                        </td>
                                        {item.payment_type === "0" ? null : (
                                          <td>
                                            <Switch
                                              onChange={(e) => {
                                                handleChange(
                                                  item.cpt_id,
                                                  item.status
                                                );
                                              }}
                                              checked={
                                                item.status === "1"
                                                  ? true
                                                  : false
                                              }
                                              id={item.cpt_id}
                                              onColor="#86d3ff"
                                              onHandleColor="#2693e6"
                                              handleDiameter={20}
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                              height={15}
                                              width={38}
                                              className="react-switch"
                                            />
                                          </td>
                                        )}
                                        {item.payment_type === "0" ? null : (
                                          <td>
                                            <button
                                              onClick={() =>
                                                handleEdit(item.id, item.cpt_id)
                                              }
                                              type="button"
                                              class="btn btn-secondary"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title="Edit"
                                            >
                                              <i className="fa-regular fa-pen-to-square"></i>
                                            </button>{" "}
                                            <Button
                                              type="button"
                                              className="btn btn-danger"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title="Remove"
                                              onClick={() =>
                                                showConfirm(item.cpt_id)
                                              }
                                            >
                                              <i className="fa-regular fa-trash-can"></i>
                                            </Button>{" "}
                                            <Button
                                              type="button"
                                              className="btn btn-info"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title="view"
                                              style={{ color: "white" }}
                                              onClick={() =>
                                                handleShowView(item.cpt_id)
                                              }
                                            >
                                              <i className="fa-regular fa-eye"></i>
                                            </Button>
                                          </td>
                                        )}
                                      </tr>
                                    </tbody>
                                  );
                                })
                              )}
                            </table>
                            {/* Instructor List */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </div>
  );
}
