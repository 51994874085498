import React, { useEffect, useState } from "react";
import StudentHeader from "../../student/studentHeader";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

// import "./index.css";
import { Checkbox, ConfigProvider, Tabs, Tooltip } from "antd";
// import Result from "./Result";
import { useParams } from "react-router";
import axios from "../../../api/axios";
import { LogOut, Moon, Star, User, Delete, Download } from "react-feather";
import { notification, Modal } from "antd";
const RESULTPDF = "/api/student/test-result-analytics/byuser";
import scorecard from "../../../assets/img/Dashboard/Scorecard.png";
import Accuracyimg from "../../../assets/img/Dashboard/Accuracy.png";
import Chart from "react-apexcharts";
import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import Weightage from "../../../assets/img/Dashboard/weightage.png";

const StudentTestMultiResultDownloadAnalysis = () => {
  // console.log(userid,"userid",attempt)
  const navigate = useNavigate();

  const { id, userid, attempt } = useParams();
  // const [attempt, setattempt] = useState("");
  const token = localStorage.getItem("tokenStudent");
  // const userid = localStorage.getItem("userid");
  const [result, setResult] = useState([]);
  const [subjectdata, setSubjectData] = useState([]);
  const [attemptedques, setAttemptedques] = useState();
  const [statusresult, setStatusresult] = useState();
  const [openAccordions, setOpenAccordions] = useState({});
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [videoid, setvideoid] = useState([]);
  const handelvimeovideo = (link) => {
    setvideoid(link);
    handleShowForm();
  };

  const [isModalVisibleForm1, setIsModalVisibleForm1] = useState(false);
  const [cheked, setChecked] = useState([]);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [selectedButton, setSelectedButton] = useState(0);
  const handleClick = (index) => {
    // console.log(index, "index");
    setSelectedButton(index);
  };
  const handleModalDeleteClose = () => {
    setIsModalVisibleForm1(false);
  };
  const handleShowForm1 = () => {
    setIsModalVisibleForm1(true);
  };
  const handleCreate = () => {
    handleShowForm1();
  };
  const onChange = (e) => {
    setChecked(e.target.checked);
    // console.log(`checked = ${e.target.checked}`);
  };
  const ResultDownlaod = async () => {
    try {
      //   setLoading(true);
      const responce = await axios.get(
        `${RESULTPDF}/${userid}/${id}/${attempt}`
      );
      //   console.log(responce?.data, "response");
      //   setInvoiceData(resonse?.data);
      setResult(responce?.data);
      setStatusresult(responce?.status);
      // setAttemptedques(responce?.data?.totalQuestion
      //   -(responce?.data?.correct+responce?.data?.wrong))
      setAttemptedques(
        responce?.data?.totalQuestion - responce?.data?.leaveQuestion
      );
      const subjectWiseData = responce?.data?.subject?.map((subject) => {
        const subjectResult = responce?.data?.subjectResult?.find(
          (result) => result.name === subject
        );
        const wrongQuestions = responce?.data?.wrongQuestions?.filter(
          (question) => question.subject === subject
        );
        const unattemptedQuestios = responce?.data?.leaveQuestions?.filter(
          (question) => question?.subject === subject
        );
        const uniqueID = Math.random().toString(36).substr(2, 9);

        return {
          subjectResult: {
            ...subjectResult,
            attemptedquestions: subjectResult?.correct + subjectResult?.wrong,
            unattemptedQuestios1:
              subjectResult?.count -
              (subjectResult?.correct + subjectResult?.wrong),
            id: uniqueID,
            wrongansquestions: wrongQuestions,
            unattemptedQuestios: unattemptedQuestios,
            negativeMark: responce?.data?.negativeMark,
          },
        };
      });

      const finalData = {
        subjectWiseData,
      };
      setSubjectData(finalData);
      //   setLoading(false);
    } catch (error) {
      console.log(error);
      //   if (error?.response?.status === 401) {
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("showNotification")

      //     navigate("/login");
      //   }
      // openNotificationWithIcon2("error");
    }
  };
  useEffect(() => {
    ResultDownlaod();
  }, []);
  const chartOptions = {
    series: [result?.totalAttempt, result?.leaveQuestion],

    chart: {
      width: "100%",
      type: "donut",
      // height:"100%"
    },
    labels: ["Attempted", "Unattempted"],
    colors: ["#6fdc6f", "#66b0e5"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'dark',
    //     type: 'vertical',
    //     shadeIntensity: 0.5,
    //     inverseColors: false,
    //     opacityFrom: 1,
    //     opacityTo: 0.8,
    //     stops: [0, 100]
    //   }
    // }
  };
  const chartOptions1 = {
    series: [result?.correctAnswer, result?.wrongAnswer],
    chart: {
      width: "100%",
      type: "pie",
      //  height:"100%"
    },
    labels: ["Correct Answers", "Wrong Answers"],
    colors: ["#6fdc6f", "#f15b5b"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'dark',
    //     type: 'vertical',
    //     shadeIntensity: 0.5,
    //     inverseColors: false,
    //     opacityFrom: 1,
    //     opacityTo: 0.8,
    //     stops: [0, 100]
    //   }
    // }
  };
  const [accordionOpen, setAccordionOpen] = useState(true);

  useEffect(() => {
    // This effect runs once when the component mounts
    // Set accordionOpen to true to ensure all accordion items are open by default
    setAccordionOpen(true);
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <>
      <div className="main-wrapper">
        {/* BreadCrumb */}
        <div className="breadcrumb-bar p-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="container" style={{ marginTop: "20px" }}>
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-md-5 d-flex">
              <div
                className="card instructor-card w-100"
                style={{
                  border: "none",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className="card-body d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="instructor-inner">
                    <h6 style={{ color: "black", fontWeight: 700 }}>
                      Scored Marks
                    </h6>
                    <h4
                      className="instructor-text-success"
                      style={{ fontWeight: 800 }}
                    >
                      {result?.totalMarks >= 0
                        ? (result?.totalMarks).toFixed(2)
                        : result?.totalMarks}
                    </h4>
                  </div>
                  <div className="instructo-inner">
                    <img src={scorecard} alt="image" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 d-flex">
              <div
                className="card instructor-card w-100"
                style={{
                  border: "none",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className="card-body d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="instructor-inner">
                    <h6 style={{ color: "black", fontWeight: 700 }}>
                      Accuracy
                    </h6>
                    <h4
                      className="instructor-text-info"
                      style={{ color: "#f00e21", fontWeight: 800 }}
                    >
                      {(
                        (result?.correctAnswer / result?.totalAttempt) *
                        100
                      ).toFixed(2) >= 0
                        ? (
                            (result?.correctAnswer / result?.totalAttempt) *
                            100
                          ).toFixed(2)
                        : 0}
                      %
                    </h4>
                  </div>
                  <div className="instructo-inner">
                    <img src={Accuracyimg} alt="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-md-5">
              <div
                className="settings-widget krishna1"
                style={{
                  border: "none",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className="settings-inner-blk"
                  //  style={{borderBottom:"none",backgroundColor:"#cbd9e6"}}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h4
                      style={{
                        marginLeft: "20px",
                        marginTop: "15px",
                        color: " #392C7D",
                        fontSize: "19px",
                      }}
                    >
                      Total quesions : {result?.totalQuestion}
                    </h4>
                    <h4
                      style={{
                        marginLeft: "20px",
                        marginTop: "15px",
                        color: " #392C7D",
                        marginRight: "30px",
                        fontSize: "19px",
                      }}
                    >
                      Total Marks :
                      {result?.totalQuestion * result?.eachQuestionMark}
                    </h4>
                  </div>

                  <div className="sell-course-head comman-space graph2">
                    <div className="chart">
                      <Chart
                        options={chartOptions}
                        series={chartOptions.series}
                        type={chartOptions?.chart?.type}
                        width={chartOptions.chart.width}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div
                className="settings-widget krishna2"
                style={{
                  border: "none",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div
                  className="settings-inner-blk p-0"
                  //  style={{borderBottom:"none",backgroundColor:"rgb(197 236 190 / 39%)"}}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h4
                      style={{
                        marginLeft: "20px",
                        marginTop: "15px",
                        color: "#392C7D",
                        fontSize: "19px",
                      }}
                    >
                      Positive Marks :
                      {(
                        result?.eachQuestionMark * result?.correctAnswer
                      ).toFixed(2)}
                    </h4>
                    <h4
                      style={{
                        marginLeft: "20px",
                        marginTop: "15px",
                        color: "#392C7D",
                        marginRight: "30px",
                        fontSize: "19px",
                      }}
                    >
                      Negative Marks :{" "}
                      {(result?.negativeMark * result?.wrongAnswer).toFixed(2)}
                    </h4>
                  </div>

                  <div className="sell-course-head comman-space graph2">
                    <div className="chart">
                      <Chart
                        options={chartOptions1}
                        series={chartOptions1.series}
                        type={chartOptions1?.chart?.type}
                        width={chartOptions1.chart.width}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentTestMultiResultDownloadAnalysis;
