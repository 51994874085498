import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../adminHeader";
import Footer from "../../footer";

import { Link, useNavigate } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import { Input, Tooltip } from "antd";
import Button from "react-bootstrap/Button";

import axios from "../../../api/axios";
import CryptoJS from "crypto-js";

const STUDENTLIST = "/api/student/expired-students";
const DELETESTUDENT = "/api/student/delete";
const PAYEDINSTALMENTS = "/api/course-installment-paid";

import "react-toastify/dist/ReactToastify.css";
import { notification, Modal } from "antd";
import Form from "react-bootstrap/Form";
import "../addAdminRecordedVideos/insex.css";
import moment from "moment";
const { confirm } = Modal;
const CENTERSLIST = "/api/public/academy_centers";

export default function AddAdminExpiry() {
  const { Search } = Input;
  const [install, setinstall] = useState(null);
  const [paid, setpaid] = useState("");
  const [list, setList] = useState([]);
  // const [itemsPerPage, setitemsPerPage] = useState('')
  const [userid, setuserid] = useState("");
  const [batchid, setbatchid] = useState("");
  const [courseid, setcourseid] = useState("");
  const [paydata, setpaydata] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [message, setmessage] = useState("No Data");
  const [total_amount, settotal_amount] = useState("");
  const [remaing, setRemaing] = useState("");
  const [sum, setsum] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(new Date());
  const [centers, setCenters] = useState([]);
  const [centersId, setCentersId] = useState("");

  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const fecthSudentList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(STUDENTLIST, config);
      const ids = resonse.data.map((e) => e.id);
      const usersubs = resonse.data
        .map((e) => {
          let name = e.name;
          let phone = e.phone;
          let admission_date = e.admission_date;
          let center=e.center
          let res = [];
          e.usersubs.map((r) => {
            res.push(Object.assign(r, { name, phone, admission_date,center }));
          });
          return res;
        })
        .flat();
          // console.log(usersubs,"usersubs")
      const course = usersubs
        .map((e) => {
          let course_id = e.course_id;
          let student = e.name;
          let phone = e.phone;
          let user_id = e.user_id;
          let expiry_date = e.expiry_date;
          let admission_date = e.admission_date;
          let center=e.center


          let course = [];
          e.course.map((r) => {
            course.push(
              Object.assign(r, {
                student: student,
                user_id: user_id,
                expiry_date: expiry_date,
                course_id: course_id,
                phone: phone,
                admission_date: admission_date,
                center:center
              })
            );
          });
          return course;
        })
        .flat();
      const coursebatchrelation = course
        .map((e) => {
          let student = e.student;
          let user_id = e.user_id;
          let expiry_date = e.expiry_date;
          let name = e.name;
          let phone = e.phone;
          let course_id = e.course_id;
          let admission_date = e.admission_date;
          let center=e.center

          const coursebatchrelation = [];
          e.batch_subject_relation.map((r) => {
            coursebatchrelation.push(
              Object.assign(r, {
                student: student,
                user_id: user_id,
                expiry_date: expiry_date,
                name: name,
                course_id: course_id,
                phone: phone,
                admission_date: admission_date,
                 center:center

              })
            );
          });
          return coursebatchrelation;
        })
        .flat();
        //  console.log(coursebatchrelation,"coursebatchrelation")
      const batch_detail_relation = coursebatchrelation
        ?.map((e) => {
            // console.log(e?.center,"eee")
          let student = e.student;
          let user_id = e.user_id;
          let expiry_date = e.expiry_date;
          let name = e.name;
          let batch_id = e.batch_id;
          let course_id = e.course_id;
          let center=e.center
          let phone = e.phone;
          let admission_date = e.admission_date;
          let batch_detail_relation = [];
          e.batchstudent_relation.map((r) => {
            // console.log(r?.fk_student_id,"rr")
            if (r.fk_student_id== +e.user_id) {
              batch_detail_relation.push(
                Object.assign(r, {
                  phone: phone,
                  student: student,
                  user_id: user_id,
                  expiry_date: expiry_date,
                  name: name,
                  batch_id: batch_id,
                  course_id,
                  admission_date: admission_date,
                  center:center,
                })
              );
            }
          });
          return batch_detail_relation;
        })
        .flat();
        // console.log(batch_detail_relation,"batch_detail_relation")

      // setitemsPerPage(batch_detail_relation.length)
      setList(batch_detail_relation);
      setData(batch_detail_relation);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon1("error", error?.response?.data?.message,"red");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon1("error","Internal Server Error","red")
      }
    }
  };

  useEffect(() => {
    fecthSudentList();
  }, []);

  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    const CentersList = async () => {
      try {
        const response = await axios.get(CENTERSLIST);
        setCenters(response?.data?.message);
      } catch (error) {
        console.log(error);
      }
    };

    CentersList();
  }, []);
  const openNotificationWithIcon1 = (type, text, colors) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: colors }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const visiblerecordsData = list?.slice(indexOfFirstCourse, indexOfLastCourse);
  const recordsDataLength = list?.length ? list?.length : 0;
  const numOFTotalPage = Math.ceil(recordsDataLength / 10);
  const pages = [...Array(numOFTotalPage + 1).keys()].slice(1);
  const prevPageHandler = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const nextPageHandler = () => {
    if (currentPage !== numOFTotalPage) setCurrentPage(currentPage + 1);
  };
  const handleAddUser = async () => {
    try {
      const response = await axios.post(
        PAYEDINSTALMENTS,
        {
          courseId: courseid,
          amount: paid,
          userId: userid,
          payment_date: paydata,
          date: fromDate,
        },
        config
      );
      if (response) {
        setIsModalVisibleForm(false);
        openNotificationWithIcon1("success", "Installment Payed", "#52c41a");
        setpaydata(new Date());
        setpaid("");
        setFromDate(new Date());
        fecthSudentList();
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("erorr")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      if (error.response.status == 403) {
        openNotificationWithIcon1("error", "403", "red");
      } 
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon1("error", error?.response?.data?.message,"red");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else {
        openNotificationWithIcon1("error", error.response.data.message, "red");
      }
     
      // else{
      //   openNotificationWithIcon1("error","Internal Server Error","red")
      // }
    }
  };

  const handleEdit = (batch_id, expiry_date, user_id, course_id) => {
    setFromDate(new Date(expiry_date));
    setcourseid(course_id);
    setbatchid(batch_id);
    setuserid(user_id);
    handleShowForm();
  };

  const handleView = async (user_id, course_id) => {
    try {
      setLoadingTable(true);
      const res = await axios.get(
        `api/viewInstallment/${user_id}/${course_id}`,
        config
      );
      setinstall(res.data.viewInstallment);
      let total_amount = [
        ...new Set(res.data.viewInstallment.map((e) => e.total_amount)),
      ];
      settotal_amount(Number(total_amount[0]));
      let inst_amount = res.data.viewInstallment.map((e) =>
        Number(e.inst_amount)
      );
      let sum = inst_amount.reduce((partialSum, a) => partialSum + a, 0);
      let amount = Number(total_amount[0]);
      setsum(sum);
      let rem = amount - sum;
      setRemaing(rem);
      setLoadingTable(false);
      setIsModalVisible(true);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon1("error", error?.response?.data?.message,"red");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{      
      openNotificationWithIcon1("error", "Internal Server Error", "red");
      }
    }
  };

  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      const res = list.filter((e) =>
        e.student.toLowerCase().includes(searchValue.toLowerCase())
      );
      if (res.length > 0) {
        setList(res);
      }
      if (res.length == 0) {
        setList(res);
        setmessage("No Data Found");
      }
      if (searchValue.length === 0) {
        setList(data);
      }
    } catch (error) {
      console.error("Error deleting student:", error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIcon1("error", error?.response?.data?.message,"red");
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);

  let itemsPerPage = 10;
  const renderPagination = () => {
    const pagesToShow = 6;
    const totalPageCount = Math.ceil(recordsDataLength / itemsPerPage);

    if (totalPageCount <= pagesToShow) {
      return [...Array(totalPageCount).keys()].map((pageNum) => (
        <li
          key={pageNum}
          className={
            currentPage === pageNum + 1 ? "page-item active" : "page-item"
          }
        >
          <Link
            className="page-link"
            to="#"
            onClick={() => setCurrentPage(pageNum + 1)}
          >
            {pageNum + 1}
          </Link>
        </li>
      ));
    } else {
      if (currentPage <= 3) {
        for (let i = 0; i < 5; i++) {
          pages.push(
            <li
              key={i}
              className={
                currentPage === i + 1 ? "page-item active" : "page-item"
              }
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li key={totalPageCount - 1} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      } else if (currentPage >= totalPageCount - 2) {
        pages.push(
          <li key={0} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        for (let i = totalPageCount - 5; i < totalPageCount; i++) {
          pages.push(
            <li
              key={i}
              className={
                currentPage === i + 1 ? "page-item active" : "page-item"
              }
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
      } else {
        pages.push(
          <li key={0} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i)}
              >
                {i}
              </Link>
            </li>
          );
        }
        pages.push(
          <li key="ellipsis2" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li key={totalPageCount - 1} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      }
      return pages;
    }
  };
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"expiry"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget ttt">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3 style={{ textTransform: "capitalize" }}>
                            Installment
                          </h3>
                        </div>
                        <div className="comman-space pb-0">
                          <div
                            // className="settings-referral-blk table-responsive"
                            // id="table-value"
                          >
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row gx-2 align-items-center">
                                    <div className="col-md-6 col-item">
                                      <div className=" search-group">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Name"
                                          // allowClear
                                          onChange={Filter}
                                          onKeyPress={handleKeyPress}
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="settings-referral-blk table-responsive">
                            <Modal
                              title="Add Student"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              open={isModalVisibleForm}
                              okText="Create"
                              onOk={() => {
                                handleAddUser();
                              }}
                              onCancel={() => {
                                setIsModalVisibleForm(false);
                              }}
                            >
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Paid Amount</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={paid}
                                    onChange={(e) => setpaid(e.target.value)}
                                    placeholder="enter paid amount"
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Expiry Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    min={
                                      fromDate.getFullYear().toString() +
                                      "-" +
                                      (fromDate.getMonth() + 1)
                                        .toString()
                                        .padStart(2, 0) +
                                      "-" +
                                      fromDate
                                        .getDate()
                                        .toString()
                                        .padStart(2, 0)
                                    }
                                    value={
                                      fromDate.getFullYear().toString() +
                                      "-" +
                                      (fromDate.getMonth() + 1)
                                        .toString()
                                        .padStart(2, 0) +
                                      "-" +
                                      fromDate
                                        .getDate()
                                        .toString()
                                        .padStart(2, 0)
                                    }
                                    onChange={(e) => {
                                      setFromDate(new Date(e.target.value));
                                    }}
                                    placeholder="enter expiry date"
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Payment Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    max={
                                      paydata.getFullYear().toString() +
                                      "-" +
                                      (paydata.getMonth() + 1)
                                        .toString()
                                        .padStart(2, 0) +
                                      "-" +
                                      paydata
                                        .getDate()
                                        .toString()
                                        .padStart(2, 0)
                                    }
                                    value={
                                      paydata.getFullYear().toString() +
                                      "-" +
                                      (paydata.getMonth() + 1)
                                        .toString()
                                        .padStart(2, 0) +
                                      "-" +
                                      paydata
                                        .getDate()
                                        .toString()
                                        .padStart(2, 0)
                                    }
                                    onChange={(e) => {
                                      setpaydata(new Date(e.target.value));
                                    }}
                                    placeholder="enter payment date"
                                  />
                                </Form.Group>
                              </Form>
                            </Modal>
                            <Modal
                              title="View Installment"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              open={isModalVisible}
                              okText="ok"
                              onOk={() => {
                                setIsModalVisible(false);
                              }}
                              onCancel={() => {
                                setIsModalVisible(false);
                              }}
                            >
                              <Form>
                                <Form.Group className="mb-3">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th scope="col">Total Amount</th>
                                        <th scope="col">Paid Amount</th>
                                        <th scope="col">Remaing Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{total_amount}</td>
                                        <td>{sum}</td>
                                        <td>{remaing < 0 ? 0 : remaing}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                  {" "}
                                  <table className="table table-borderless mb-0">
                                    <thead>
                                      <tr className="text-center">
                                        <th>Inst.No</th>
                                        <th>Installment Amount</th>
                                        <th>Payment Date</th>
                                      </tr>
                                    </thead>
                                    {loadingTable === true ? (
                                       <tbody  className="d-flex justify-content-center align-items-center"
                                       style={{
                                         position: "fixed",
                                         top: 0,
                                         right: 0,
                                         bottom: 0,
                                         left: 0,
                                         backgroundColor: "rgba(255, 255, 255, 0.6)",
                                       }}>
                                         <tr>
                                           <td className="spinner-border text-warning"
                                               role="status">
                                            
                                             <span className="sr-only">
                                                 Loading...
                                               </span>
                                           </td>
                                         </tr>
                                       </tbody>
                                    ) : (
                                      install?.map((item, i) => {
                                        return (
                                          <tbody key={i}>
                                            <tr className="text-center">
                                              <td>{i + 1}</td>
                                              <td>
                                                ₹{" "}
                                                {parseInt(
                                                  item.inst_amount
                                                ).toLocaleString("en-IN", {
                                                  maximumFractionDigits: 0,
                                                })}
                                              </td>
                                              <td>
                                                {item.payment_date == null
                                                  ? "-"
                                                  : moment(
                                                      item.payment_date
                                                    ).format("DD-MM-YYYY")}
                                              </td>
                                            </tr>
                                          </tbody>
                                        );
                                      })
                                    )}
                                  </table>
                                </Form.Group>
                              </Form>
                            </Modal>
                            <table className="table table-nowrap mt-2">
                              <thead>
                                <tr className="text-center">
                                  <th>SR.NO</th>
                                  <th>STUDENT</th>
                                  <th>COURSE</th>
                                  <th>PHONE</th>
                                  <th>BATCH</th>
                                  <th className="text-center">CENTER NAME</th>
                                  <th>ADMISSION DATE</th>
                                  <th>EXPIRY DATE</th>
                                  <th>ACTION</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                                
                                  <tbody  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}>
                                    <tr>
                                      <td className="spinner-border text-warning sr-only"
                                          role="status">
                                       <>
                                       Loading...
                                      
                                       </>
                                        
                                      </td>
                                    </tr>
                                  </tbody>
                              ) : visiblerecordsData?.length > 0 ? (
                                visiblerecordsData?.map((item, i) => {
                                  // console.log(visiblerecordsData,"visiblerecordsdata")
                                  const serialNumber =
                                    (currentPage - 1) * itemsPerPage + i + 1;
                                    const centerObj = centers.find((center) => center?.id == item?.fk_academy_center_id);  
// console.log(item,"center")
                                  return (
                                    <tbody
                                      key={i}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <tr className="text-center">
                                        <td>{serialNumber}</td>
                                        <td>
                                        <Tooltip title={item?.student}>
                                                {`${item?.student?.slice(0, 12)} ${
                                                  item?.student?.length > 12
                                                    ? "..."
                                                    : ""
                                                }`}
                                              </Tooltip>

                                          
                                        </td>
                                        <td>
                                        <Tooltip title={item?.name}>
                                                {`${item?.name?.slice(0, 12)} ${
                                                  item?.name?.length > 12
                                                    ? "..."
                                                    : ""
                                                }`}
                                              </Tooltip>

                                        </td>
                                        <td>{item.phone}</td>

                                        <td>
                                          {item.batch_relation.map(
                                            (e) => e.name
                                          )}
                                        </td>
                                        <td>{item?.center?.name}</td>

                                        <td>
                                          {moment(item.admission_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>
                                          {" "}
                                          {moment(item.expiry_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>
                                          <Tooltip title="Edit">
                                            <Button
                                              onClick={() =>
                                                handleEdit(
                                                  item.batch_id,
                                                  item.expiry_date,
                                                  item.user_id,
                                                  item.course_id
                                                )
                                              }
                                              type="button"
                                              className="btn btn-secondary"
                                            >
                                              <i className="fa fa-credit-card"></i>
                                            </Button>{" "}
                                          </Tooltip>
                                          <Tooltip title="View">
                                            <button
                                              onClick={() =>
                                                handleView(
                                                  item.user_id,
                                                  item.course_id
                                                )
                                              }
                                              // type="button"
                                              className="btn btn-info"
                                            >
                                              <i
                                                style={{ color: "white" }}
                                                className="fa fa-eye"
                                                aria-hidden="true"
                                              ></i>
                                            </button>{" "}
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              ) : (
                                ""
                              )}
                            </table>
                            </div>
                            {visiblerecordsData?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {visiblerecordsData?.length <= 0 ? (
                              ""
                            ) : (
                              <ul
                                className="pagination lms-page d-flex align-items-center justify-content-center"
                                style={{ marginLeft: "1.5em" }}
                              >
                                <li
                                  className="page-item prev"
                                  onClick={() => prevPageHandler()}
                                >
                                  <Link
                                    className="page-link"
                                    to="#"
                                    tabIndex={-1}
                                  >
                                    <i className="fas fa-angle-left" />
                                  </Link>
                                </li>
                                {renderPagination()}

                                <li
                                  className="page-item next"
                                  onClick={() => nextPageHandler()}
                                >
                                  <Link className="page-link" to="#">
                                    <i className="fas fa-angle-right" />
                                  </Link>
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
}
