import React, { useState, useContext, useEffect } from "react";

import AdminHeader from "../../instructor/adminHeader";
import Footer from "../../footer";
import { AudioOutlined } from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import { Video2 } from "../../imagepath";
import PaginationLink from "../../pages/course/addCourse/PaginationLink";
import CryptoJS from "crypto-js";
import axios from "../../../api/axios";
import "./index.css";
import { notification, Space } from "antd";

const RECORDEDVIDEO = "/api/recorded-video/linksubmodule-video";
const RECORDEDSEARCH = "/api/recorded-video/recordedvideo-search";
const RECORDEDSUBMIT = "/api/recorded-video/linksubmodule-video-save";

import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "antd";

const { confirm } = Modal;

export default function AddRecordedLink() {
  const { Search } = Input;
  const { mid, sid } = useParams();
  const [list, setList] = useState(null);
  const [last_page, setLast_page] = useState("");
  const [records1, setRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const navigate = useNavigate();
  var bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(mid),
    "my-secret-key@admin8623009959"
  );
  let midcode = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  let decrptSid = CryptoJS.AES.decrypt(
    decodeURIComponent(sid),
    "my-secret-key@admin8623009959"
  );
  let sidcode = JSON.parse(decrptSid.toString(CryptoJS.enc.Utf8));

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const openNotificationWithIcon2 = (type) => {

    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  
  const fecthRecordedList = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(
        `${RECORDEDVIDEO}/${midcode}/${sidcode}`,
        config
      );
      const result = resonse?.data?.video.filter(
        (e) =>
          !resonse?.data?.videorelation.some(
            (s) => e?.id == s?.recorded_video_id
          )
      );
      setLast_page(resonse.data.video.last_page);
      setList(result);
      setRecords(result);
      setLoading(false);
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    } catch (error) {
      console.log(error);
      if (error) {
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIcon1("error", error?.response?.data?.message);
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
        else{
          openNotificationWithIcon2("error");
        }
      }
    }
  };
  useEffect(() => {
    fecthRecordedList();
  }, []);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, text) => {

    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type, text) => {

    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };

  const handleChange = (id, check) => {
    const arr = [...checked];
    if (!arr.includes(id)) {
      setChecked([...arr, id]);
    } else {
      const res = arr.filter((e) => e !== id);
      setChecked(res);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(    checked?.length == 0){
      openNotificationWithIcon1("error", "Select Video")
      return

    }
    try {
      const response = await axios.post(
        RECORDEDSUBMIT,
        {
          subModuleId: sidcode,
          moduleId: midcode,
          videoId: checked,
        },
        config
      );
      if (response) {
        setTimeout(() => {
          navigate(-1);
        }, 1000);

        openNotificationWithIcon("success", "Link Created");
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon2("error");
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      const res = records1.filter((e) =>
        e.title.toLowerCase().includes(searchValue.toLowerCase())
      );
      if (res.length > 0) {
        setRecords(res);
      }
      if (searchValue.length === 0) {
        setRecords(list);
      }
    } catch (error) {
      console.error("Error deleting student:", error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const visiblerecordsData = records1?.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );
  const recordsDataLength = records1?.length ? records1?.length : 0;
  const numOFTotalPage = Math.ceil(recordsDataLength / 10);
  const page = [...Array(numOFTotalPage + 1).keys()].slice(1);

  const prevPageHandler = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const nextPageHandler = () => {
    if (currentPage !== numOFTotalPage) setCurrentPage(currentPage + 1);
    // windowTop(0,0);
  };
  const handleSetData = async (pgNumber) => {
      setCurrentPage(pgNumber);
  };
  let data = [];

  const renderPagination = () => {
    let pages = [];
    const nPages = Math.ceil(recordsDataLength / itemsPerPage);
    if (nPages <= 1) {
      pages = [1, ];
    } else if (nPages <= 6) {
      pages = [...Array(nPages).keys()].map(i => i + 1);
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, 4, 5, null, nPages];
      } else if (currentPage >= nPages - 2) {
        pages = [1, null, nPages - 4, nPages - 3, nPages - 2, nPages - 1, nPages];
      } else {
        pages = [1, null, currentPage - 1, currentPage, currentPage + 1, null, nPages];
      }
    }

    return pages.map((pgNumber, index) => (
      <li
        key={index}
        className={`${
          currentPage === pgNumber ? "page-item first-page active" : "page-item first-page "
        } `}
      >
        {pgNumber !== null ? (
          <a onClick={() => handleSetData(pgNumber)} className="page-link" href="#">
            {pgNumber}
          </a>
        ) : (
          <span> <a  className="page-link" href="#">
          ....
        </a></span>
        )}
      </li>
    ));
  };
  
  if (Array.isArray(visiblerecordsData)) {
    // Only sort if records1 is an array
    data = visiblerecordsData.sort((a, b) => {
      if (sortColumn !== "") {
        if (sortDirection === "asc") {
          if (a[sortColumn] < b[sortColumn]) return -1;
          if (a[sortColumn] > b[sortColumn]) return 1;
          return 0;
        } else {
          if (a[sortColumn] < b[sortColumn]) return 1;
          if (a[sortColumn] > b[sortColumn]) return -1;
          return 0;
        }
      } else {
        return a.id - b.id;
      }
    });
  }

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const itemsPerPage = 10;
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };
  
  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);

  return (
    <div className="main-wrapper ">
      <AdminHeader />
      <div className="page-content ">
        <div
          className="container"
          style={{ maxWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3>Link Videos</h3>
                          {midcode == 0 ? (
                            <nav
                              aria-label="breadcrumb"
                              style={{ marginBottom: "-1.6em" }}
                            >
                              <ol className="breadcrumb">
                                <li
                                  className="breadcrumb-item "
                                  aria-current="page"
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "0.5em",
                                  }}
                                  onClick={() =>
                                    navigate("/admin/recorded-videos-list")
                                  }
                                >
                                  Recorded Videos
                                </li>
                                <li
                                  className="breadcrumb-item "
                                  aria-current="page"
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "0.5em",
                                    fontWeight: "normal",
                                  }}
                                  onClick={() =>
                                    navigate("/admin/recorded-videos")
                                  }
                                >
                                  {" "}
                                  Recorded SubModules
                                </li>
                                <li
                                  className="breadcrumb-item active"
                                  aria-current="page"
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "0.5em",
                                    marginLeft: "-.8em",
                                  }}
                                >
                                  {" "}
                                  Link Videos
                                </li>
                              </ol>
                            </nav>
                          ) : (
                            <nav
                              aria-label="breadcrumb"
                              style={{
                                marginTop: "0.5em",
                                marginBottom: "-1.6em",
                              }}
                            >
                              <ol className="breadcrumb">
                                <li
                                  className="breadcrumb-item "
                                  aria-current="page"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate("/admin/recorded-videos-list")
                                  }
                                >
                                  {" "}
                                  Recorded Videos
                                </li>
                                <li
                                  className="breadcrumb-item "
                                  aria-current="page"
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "normal",
                                  }}
                                  onClick={() =>
                                    navigate("/admin/recorded-videos")
                                  }
                                >
                                  {" "}
                                  Recorded Modules
                                </li>
                                <li
                                  className="breadcrumb-item  "
                                  aria-current="page"
                                  style={{
                                    marginLeft: "-1em",
                                    cursor: "pointer",
                                    fontWeight: "normal",
                                  }}
                                  onClick={() => navigate(-1)}
                                >
                                  SubModule
                                </li>
                                <li
                                  className="breadcrumb-item  active"
                                  aria-current="page"
                                  style={{
                                    marginLeft: "-1em",
                                    cursor: "pointer",
                                  }}
                                >
                                  Link Videos
                                </li>
                              </ol>
                            </nav>
                          )}
                        </div>
                        <div className="comman-space pb-0">
                          <div
                            className="settings-referral-blk table-responsive"
                            id="table-value"
                          >
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row  gx-2 align-items-center">
                                    <div className="col-md-6 col-item">
                                      <div className=" search-group justify-content-start">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search"
                                          // allowClear
                                          onChange={Filter}
                                          style={{ width: "100%" }}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-6 col-lg-6 col-item">
                                      <div
                                        className="d-flex justify-content-end"
                                        style={{ gap: "8px" }}
                                      >
                                        <button
                                          style={{
                                            color: "white",
                                            textAlign: "center",
                                            backgroundColor: "rgb(57, 44, 125)",
                                          }}
                                          id="custom-button"
                                          className="btn "
                                          onClick={() =>
                                            navigate("/admin/add-video")
                                          }
                                        >
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Create New
                                        </button>

                                        <button
                                          onClick={(e) => handleSubmit(e)}
                                          className="btn btn-primary"
                                          id="custom-button"
                                          style={{ padding: "5px 20px" }}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <table className="table table-nowrap mb-2 mt-2">
                              <thead>
                                <tr className="text-center">
                                  <th>SR NO</th>
                                  <th>VIDEOS</th>
                                  <th className="text-center">ACTION</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                               
                                  <tbody className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}>
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                              ) : (
                              
                                data?.map((item, i) => {
                                  const sNo =
                                    (currentPage - 1) * itemsPerPage +
                                    i +
                                    1;
                                  const res = checked?.filter(
                                    (e) => e == item.id
                                  );
                                  return (
                                    <tbody style={{ textTransform: "capitalize" }} key={item.id}>
                                  <tr key={item.id}>
                                      <td className="text-center">
                                        {sNo}
                                      </td>
                                      <td>
                                        <div
                                          style={{
                                            marginLeft: "9em",
                                            marginBottom: "-1.4em",
                                          }}
                                          className="sell-table-group d-flex align-items-center"
                                        >
                                          <div
                                            className="sell-group-img"
                                            style={{ height: "none" }}
                                          >
                                            <Link>
                                              <img
                                                src={`${item.thumbnail_path}/${item.thumbnail}`}
                                                className="img-fluid "
                                                style={{
                                                  borderRadius: "15px",
                                                  width: "90%",
                                                  height: "90%",
                                                  objectFit: "cover",
                                                }}
                                                alt=""
                                              />
                                            </Link>
                                          </div>
                                          <div
                                            className="sell-tabel-info"
                                            style={{ height: "none" }}
                                          >
                                            <p>
                                              <Link>
                                                {`${item.title.slice(
                                                  0,
                                                  25
                                                )} ${
                                                  item.title.length > 25
                                                    ? "..."
                                                    : ""
                                                }`}
                                              </Link>
                                            </p>
                                            <div
                                              className="course-info d-flex align-items-center border-bottom-0 pb-0"
                                              style={{ width: "0" }}
                                            >
                                              <div className="rating-img d-flex align-items-center">
                                                <img
                                                  src={Video2}
                                                  alt=""
                                                />
                                                <p> Study</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-check form-check-inline">
                                          {res.map((e) => {
                                            if (e) {
                                              return (
                                                <input
                                                  checked
                                                  className="form-check-input"
                                                  style={{
                                                    marginLeft: "2em",
                                                  }}
                                                  type="checkbox"
                                                  onChange={(e) => {
                                                    handleChange(
                                                      item.id,
                                                      e.target.checked
                                                    );
                                                  }}
                                                  key={item?.id}
                                                  id="inlineCheckbox1"
                                                />
                                              );
                                            } else {
                                              return (
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  style={{
                                                    marginLeft: "2em",
                                                  }}
                                                  onChange={(e) => {
                                                    handleChange(
                                                      item.id,
                                                      e.target.checked
                                                    );
                                                  }}
                                                  key={item?.id}
                                                  id="inlineCheckbox1"
                                                />
                                              );
                                            }
                                          })}
                                          {res.length == 0 ? (
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              style={{
                                                marginLeft: "2em",
                                              }}
                                              onChange={(e) => {
                                                handleChange(
                                                  item.id,
                                                  e.target.checked
                                                );
                                              }}
                                              key={item?.id}
                                              id="inlineCheckbox1"
                                            />
                                          ) : null}
                                        </div>
                                      </td>
                                    </tr>
                                    </tbody>
                                   
                                  );
                                })
                              )}
                            </table>
                            {/* Instructor List */}
                            {data?.length <= 0 ? (
                            <h4 className="text-center">{"No Data"}</h4>
                          ) : (
                            ""
                          )}
                          {data === null ? (
                            <h4 className="text-center">{"No Data"}</h4>
                          ) : (
                            ""
                          )}
                          </div>
                          {data?.length>0?<div className="fixtable">
                            <div className="fixtable">
                              <ul
                                className="pagination lms-page d-flex align-items-center justify-content-center"
                                style={{ marginLeft: "1.5em" }}
                              >
                                <li
                                  className="page-item prev"
                                  onClick={() => prevPageHandler()}
                                >
                                  <Link
                                    className="page-link"
                                    to="#"
                                    tabIndex={-1}
                                  >
                                    <i className="fas fa-angle-left" />
                                  </Link>
                                </li>
                                {renderPagination()}
                                <li
                                  className="page-item next"
                                  onClick={() => nextPageHandler()}
                                >
                                  <Link className="page-link" to="#">
                                    <i className="fas fa-angle-right" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>:""}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
}
