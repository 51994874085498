import React, { useContext, useEffect, useState } from "react";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import { Collapse } from "react-bootstrap";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../api/axios";
import AdminHeader from "../../instructor/adminHeader";
import { event, type } from "jquery";
import { notification, Space } from "antd";

const GETCOURSE = '/api/user-subscription'
const SUBCRIPTION = "/api/std-courses-initiate";
const GETSUBSCRIPTION = '/api/get-Subscription-installment'

export default function AdminBuyCourse() {
    const { id } = useParams();
    const [list, setList] = useState(null)
    const [loading, setLoading] = useState(false)
    const [courseid, setCourseid] = useState(null)
    const [pay1, setpay1] = useState(null)
    const [pay2, setpay2] = useState(null)
    const [emiPayment, setemiPayment] = useState(null)
    const [emiPayments, setemiPayments] = useState(null)
    const [error, setError] = useState('')
    const [method, setMethod] = useState("")
    const [submit, setSubmit] = useState(false)
    const [transaction, setTransaction] = useState("")
    const [file, setFile] = useState("")
    const [fileInput, setFileInput] = useState(null);
    const [paymentvalue, setPaymentValue] = useState("")
    console.log(paymentvalue, "value")
    let bytes = CryptoJS.AES.decrypt(
        decodeURIComponent(id),
        "my-secret-key@admin8623009959"
    );
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const [openIndex, setOpenIndex] = useState(null);
    const [referalId, setReferalId] = useState("")

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const navigate = useNavigate();
    const fetchCourse = async () => {
        try {
            setLoading(true)
            const res = await axios.get(`${GETCOURSE}/${decryptedData}`, config)

            const usersubscription = res?.data?.usersubscription.map((it) => {
                return it.course_id
            })
            const course = res?.data?.course
            const ids = usersubscription.map((it) => Number(it))
            let data = course.filter(item => !ids.includes(item.id));
            // console.log(data);
            const option = data?.map(({ name, id }) => ({ label: name, value: id }))
            setList(option)
            setLoading(false)
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 401) {
                localStorage.removeItem('token')
                navigate('/admin/login')
            }

        }
    }
    useEffect(() => {
        fetchCourse()
    }, [])
    useEffect(() => {
        const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
        if(lastVisitedRoute){
          localStorage.removeItem("lastVisitedRoute")
        }
        else{
          localStorage.setItem("lastVisitedRoute", window.location.pathname);
    
        }
      }, []);
      const openNotificationWithIcon2 = (type) => {
        console.log("toast after click")
        notification[type]
        (
          
          {
          message: 
          (
            <div style={{color:"red"}}>
             <strong>Internal Server Error </strong> 
            </div>
          ),
        
          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted
      
          // </div>)
          duration:1
        });
        };
    const [getId, setGetId] = useState(null);
    const handleCourse = async (value) => {
        setLoading(true)
        const res = await axios.post(GETSUBSCRIPTION, { courseId: value }, config)
        // console.log(res.data);
        setpay1(res?.data?.amount)
        setpay2(res?.data?.fullPayment)
        setGetId(res?.data?.fullPayment?.cpt_id)
        setemiPayment(res.data.emiPayment)
        setemiPayments(res.data.emiPayments)
        setLoading(false)
    }
    const [selectedPlan, setSelectedPlan] = useState(pay2?.cpt_id);
    const [userChoice, setUserChoice] = useState("");
    const handleFileInputChange = (event) => {
        setFileInput(event.target.files[0]);
    };
    console.log(fileInput, "fileinput")
    const [api, contextHolder] = notification.useNotification();

    const [toast, setToast] = useState(false)
    const openNotificationWithIcon = (type) => {
        console.log("toast after click")
        notification[type]
            (

                {
                    message:
                        (
                            <div style={{ color: "#52c41a" }}>
                                <strong>Course Is Buyed</strong>
                            </div>
                        ),
                        duration:1

                    // description:
                    // (<div style={{color:"green"}}>
                    // Your Batch has Sucessfully Created

                    // </div>)

                });






    };
    const handleSubmmit = async (event) => {
        event.preventDefault();
        setSubmit(true)
        try {
            if (getId == null) {
                setError('Select Course')

            }
            let pay_mode = "";
            if (paymentvalue === "Cash") {
                pay_mode = "0";
            } else if (paymentvalue === "Online") {
                pay_mode = "1";
            }
            if (paymentvalue == "") {
                return setMethod("Select Payment Option")
            }

            if (referalId == "" && paymentvalue == "Online") {
                return setTransaction("Select Transaction Id")

            }

            else {
                setError('')

                const formData = new FormData();
                if (fileInput === null) {
                    formData.append("pay_proof", '');
                } else if (fileInput !== null) {
                    formData.append("pay_proof", fileInput);
                } else { formData.append("pay_proof", fileInput); }
                formData.append("paymentOption", getId);
                formData.append("pay_mode", pay_mode);
                formData.append("ref_no", referalId);
                formData.append("userId", decryptedData);


                const responce = await axios.post(
                    SUBCRIPTION,
                    // { paymentOption: getId,
                    //     pay_mode:pay_mode,
                    //     ref_no:referalId,
                    //     pay_proof:fileInput, 
                    //     userId: decryptedData },
                    formData,
                    config,
                    console.log(fileInput)
                );
                if (responce) {
                    openNotificationWithIcon("success")
                    setTimeout(() => {
                        navigate(`/admin/paid-student`);
                    }, 1000);
                    // toast.success("Course Is Buyed");
                }
            }

        }
        catch (error) {
            console.log(error)
            setSubmit(false)
            openNotificationWithIcon2("error")
        }

    };
    const style = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "white",
            width: "100%",
            height: "40px",
            color: "black",
            minHeight: "40px",
            border: "1px solid #e9ecef",
            paddingLeft: "5px",
            // This line disable the blue border
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: state.isSelected ? "0" : "10px",
            fontSize: "14px",
            "&:hover": {
                cursor: "pointer",
            },
            outline: "none",
        }),
        menu: (base) => ({ ...base, marginTop: "0px" }),
        menuList: (base) => ({ ...base, padding: "0" }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#FFDEDA" : "white",
            color: "black",
            fontSize: "14px",
            "&:hover": {
                backgroundColor: "#FFDEDA",
                // #dddddd
            },
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            color: "black",
            transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
            transition: "250ms",
        }),
    };
    const PaymentSelect = () => {
        const paymentoptions = [{ name: "Cash" }, { name: "Online" }];
        const optionsvalue = paymentoptions.map((item) => ({
            value: item.name,
            label: item.name,
        }));

        return optionsvalue;
    };
    //   const optionsData=(event)=>{
    //     setPaymentValue()

    //   }
    const optionsvalue = PaymentSelect();
    const referaiIdvalue = (event) => {
        setReferalId(event.target.value)
    }
    return (
        <div className="main-wrapper">
            <AdminHeader />
            {/* Student Dashboard */}
            <div className="page-content">
                <div className="container"
             style={{minWidth:"100%",marginTop:"-15px"}}
                >
                    <div className="row">
                        {/* Sidebar */}
                        <AdminInstructorSidebar activeMenu={"StudentList"} />
                        {/* Sidebar */}

                        {/* Profile Details */}
                        <div className="col-xl-9 col-md-8 main-page">
                            <div className="settings-widget profile-details">
                                <div className="settings-menu p-0">
                                    <div className="profile-heading">
                                        <h3>Course List</h3>
                                        <nav aria-label="breadcrumb" >
                                            <ol class="breadcrumb" style={{ marginBottom: 0.5 }}>
                                                <li
                                                    class="breadcrumb-item "
                                                    onClick={() => navigate(-1)}
                                                    // aria-current="page"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    All Students
                                                </li>
                                                <li
                                                    class="breadcrumb-item active"
                                                    aria-current="page"
                                                    style={{
                                                        cursor: "pointer",
                                                        // marginLeft: "-.6em",
                                                        fontWeight: 'normal'
                                                    }}
                                                >
                                                    Course List
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>

                                    <div className="checkout-form personal-address add-course-info ">
                                        {loading === true ? 
                                         <div
                                         className="d-flex justify-content-center align-items-center"
                                         style={{
                                           position: "fixed",
                                           top: 0,
                                           right: 0,
                                           bottom: 0,
                                           left: 0,
                                           backgroundColor: "rgba(255, 255, 255, 0.6)",
                                         }}
                                       >
                                         <tbody
                                         >
                                           <tr>
                                             <td>
                                               <div
                                                 class="spinner-border text-warning"
                                                 role="status"
                                               >
                                                 <span class="sr-only">Loading...</span>
                                               </div>
                                             </td>
                                           </tr>
                                         </tbody>
                                       </div>: <form>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Select Course List
                                                        </label>
                                                        <Select
                                                            className=" select country-select"
                                                            name="questionType"
                                                            id="questionType"
                                                            options={list}
                                                            placeholder="Select"
                                                            value={userChoice}
                                                            onChange={(option) => {
                                                                setCourseid(option.value)
                                                                handleCourse(option.value)
                                                                setUserChoice(option)
                                                                setError('')
                                                            }
                                                            }
                                                            styles={style}
                                                        ></Select>
                                                        {error && <span style={{ color: 'red' }}>{error}</span>}
                                                    </div>
                                                    <h3>{userChoice?.label}</h3>
                                                    {courseid === null ? "" :
                                                        <div className="form-group">
                                                            <div
                                                                className="plan-box shadow-sm rounded p-4"
                                                                style={{ transition: "box-shadow 0.3s ease" }}
                                                            >
                                                                <div>
                                                                    <h4>Full Payment</h4>
                                                                    <p>
                                                                        Pay ₹
                                                                        {pay1?.amount == 0
                                                                            ? "Free "
                                                                            : parseInt(pay1?.amount).toLocaleString(
                                                                                "en-IN",
                                                                                {
                                                                                    maximumFractionDigits: 0,
                                                                                }
                                                                            )}
                                                                        at once and avail complete course benefits.
                                                                    </p>
                                                                </div>
                                                                <h3>
                                                                    <input
                                                                        name="plan"
                                                                        className="form-check-input"
                                                                        value={pay2?.cpt_id}
                                                                        type="radio"
                                                                        onChange={(e) => {
                                                                            setSelectedPlan(pay2?.cpt_id);
                                                                            setGetId(pay2?.cpt_id);
                                                                            //   setSelectedPayment(null); 
                                                                            // Set selected payment as null for full payment
                                                                        }}
                                                                        checked={
                                                                            selectedPlan === pay2?.cpt_id || !selectedPlan
                                                                        }
                                                                    />
                                                                </h3>
                                                            </div>
                                                            {emiPayment?.map((item, i) => {
                                                                let installmentNumber = 1;
                                                                return (
                                                                    <>
                                                                        <div
                                                                            key={i}
                                                                            className="plan-box shadow-sm rounded p-4"
                                                                            style={{
                                                                                transition: "box-shadow 0.3s ease",
                                                                            }}
                                                                        >
                                                                            <div className="container">
                                                                                <h4>Installment {i + 1}</h4>
                                                                                <div>
                                                                                    <h6 className="cou-title">
                                                                                        <Link
                                                                                            className="collapsed"
                                                                                            onClick={() => handleToggle(i)}
                                                                                            aria-controls="example-collapse-text"
                                                                                        >
                                                                                            View Price Breakup
                                                                                        </Link>
                                                                                    </h6>

                                                                                    <Collapse
                                                                                        in={openIndex === i}
                                                                                        id="collapseOne"
                                                                                        className="card-collapse collapse"
                                                                                    >
                                                                                        <table className="table text-center table-borderless table-nowrap mb-0">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Inst.No</th>
                                                                                                    <th>Amount</th>
                                                                                                    <th>Duration</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {emiPayments?.map(
                                                                                                    (setEmiOptionPrice, index) => {
                                                                                                        if (
                                                                                                            item.cpt_id ===
                                                                                                            setEmiOptionPrice.cpt_id
                                                                                                        ) {
                                                                                                            const currentInstallmentNumber =
                                                                                                                installmentNumber;
                                                                                                            installmentNumber++;
                                                                                                            return (
                                                                                                                <tr data-index={index}>
                                                                                                                    <td>
                                                                                                                        {
                                                                                                                            currentInstallmentNumber
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        ₹{" "}
                                                                                                                        {parseInt(
                                                                                                                            setEmiOptionPrice.inst_amount
                                                                                                                        ).toLocaleString(
                                                                                                                            "en-IN",
                                                                                                                            {
                                                                                                                                maximumFractionDigits: 0,
                                                                                                                            }
                                                                                                                        )}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {
                                                                                                                            setEmiOptionPrice.inst_duration
                                                                                                                        }{" "}
                                                                                                                        Days
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            );
                                                                                                        } else {
                                                                                                            return null;
                                                                                                        }
                                                                                                    }
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </Collapse>
                                                                                </div>
                                                                            </div>
                                                                            <h3>
                                                                                <input
                                                                                    name="plan"
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    value={item.cpt_id}
                                                                                    onChange={() => {
                                                                                        setSelectedPlan(item.cpt_id);
                                                                                        setGetId(item.cpt_id);
                                                                                        // setSelectedPayment(item.inst_amount);
                                                                                    }}
                                                                                />
                                                                            </h3>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                            <div className="row  gx-2 align-items-center">
                                                                <div className="col-md-4 col-item">
                                                                    <label className="form-label">
                                                                        Select the Payment Optionsss
                                                                    </label>

                                                                    <Select
                                                                        className=" select country-select"
                                                                        name="questionType"
                                                                        id="questionType"
                                                                        options={optionsvalue}
                                                                        placeholder="Select your Payment Options"
                                                                        onChange={(option) => {
                                                                            setPaymentValue(option?.label)
                                                                        }
                                                                        }
                                                                        styles={style}
                                                                    ></Select>
                                                                    {paymentvalue === "" ? <span style={{ color: 'red' }}>{method}</span> : ""}
                                                                </div>
                                                                <div className="col-md-4 col-item">
                                                                    <label className="form-label"
                                                                        style={{ marginBottom: "5px" }}
                                                                    >Enter your Transaction No </label>

                                                                    <input
                                                                        type="text"
                                                                        name="Referal"
                                                                        id="referalNo"
                                                                        value={referalId}
                                                                        onChange={referaiIdvalue}
                                                                        disabled={paymentvalue == "Cash"}
                                                                        className="form-control"
                                                                        placeholder="Enter Your Transaction No"
                                                                    />
                                                                    {referalId == "" && paymentvalue == "Online" ? <span style={{ color: 'red' }}>{transaction}</span> :
                                                                        ""}
                                                                </div>
                                                                <div className="col-md-4 col-item">
                                                                    <label className="form-label">Upload Image</label>

                                                                    <input type="file" name="fileInput" accept=".pdf, .jpg, .jpeg, .png"
                                                                        disabled={paymentvalue == "Cash"}
                                                                        //    value={fileInput}

                                                                        onChange={handleFileInputChange}
                                                                    />
                                                                    {fileInput == null && paymentvalue == "Online" ? <span style={{ color: 'red' }}>{file}</span> : ""}
                                                                </div>
                                                            </div>

                                                        </div>}

                                                </div>


                                                <div className="update-profile"
                                       id="submit-button"

                                                >
                                                    <div
                                                        class="btn-group mr-2"
                                                        role="group"
                                                        aria-label="First group"
                                                        id="custom-submit"

                                                    >

                                                            <button onClick={(e) => handleSubmmit(e)} type="submit" className="btn btn-primary"
                                                            >
                                                                Submit
                                                            </button>


                                                    </div>{" "}
                                                    <div
                                                        class="btn-group mr-2"
                                                        role="group"
                                                        aria-label="First group"
                                                        id="custom-submit"

                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                navigate(-1);
                                                            }}
                                                            className="btn btn-primary cancel-btn "
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>{" "}
                                                </div>
                                            </div>
                                        </form>}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Profile Details */}
                    </div>
                </div>
            </div>
            {/* Student Dashboard */}
            {/* <ToastContainer /> */}
            <Footer />
        </div>
    );
}
