import React, { useContext, useEffect, useState } from "react";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import CryptoJS from "crypto-js";
import TextEditor from "../../pages/course/addCourse/textEditor";
import { useFormik } from "formik";
import axios from "../../../api/axios";
import * as Yup from "yup";
import AuthContext from "../../../context/AuthProvider";
import AdminHeader from "../../instructor/adminHeader";
const CREATEQUESTION = "/api/test-series/create-group-question";
import { notification, Space } from "antd";
import { async } from "q";

export default function AddAdminTestQuestionGroup() {
  const [data, setData] = useState(null);
  const [sub,setsub]=useState(null)
  const [submoduleid, SetSubmoduleid] = useState("");
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const initialValue = {
    questionType: "",
    question: "",
    answerOption1: "",
    answerOption2: "",
    answerOption3: "",
    answerOption4: "",
    correctAnswer: "",
    subject: "",
    description: "",
    videoLink: "",
    status: "",
    chapter:'',
    explanation:''

  };
  const courseSchema = Yup.object({
    questionType: Yup.string().required("Please enter your question type"),
    subject: Yup.string().required("Please enter your subject type"),
    question: Yup.string().required("Please enter your question"),
    answerOption1: Yup.string().required("Enter your answer option 1"),
    answerOption2: Yup.string().required("Enter your answer option 2"),
    answerOption3: Yup.string().required("Enter your answer option 3"),
    answerOption4: Yup.string().required("Enter your answer option 4"),
    correctAnswer: Yup.string().required("Enter your correct answer"),
    chapter: Yup.string().required("Please enter your chapter"),
    description: Yup.string().required("Select your question description"),
    videoLink: Yup.string().required("Select your video link"),
    explanation: Yup.string().required("Please enter your explanation"),
    status: Yup.string().required("Select your status"),
  });
  const options = [
    { label: "Select Option", value: "Option" },
    { label: "Option 1", value: 1 },
    { label: "Option 2", value: 2 },
    { label: "Option 3", value: 3 },
    { label: "Option 4", value: 4 },
  ];
  const question = [
    { id: 0, label: "Select Question", value: "Question" },
    { id: 1, label: "Subjective", value: 1 },
    { id: 2, label: "Objective", value: 2 },
  ];
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { user, subuser,uid } = useParams();
  const fetchAdd = async () => {
    try {
        setLoading(true);
        const response = await axios.get(
          `/api/test-series/question/${user}/${subuser}/add-question`,
          config
        );
        // console.log(response.data.questionType);
        setData(response?.data?.questionType);
        SetSubmoduleid(response.data.submoduleid);
        setLoading(false);
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          openNotificationWithIcon1("error",'Internal Server Error','red')
    }

  };
  const subjectOption=async()=>{
    try {
        const result =await axios.get(`/api/test-series/list-submodule-question/${user}`,config)
        setsub(result.data.subModule.data);
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          openNotificationWithIcon1("error",'Internal Server Error','red')
    }
   
  }
  useEffect(() => {
    fetchAdd();
    subjectOption()
  }, []);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon1 = (type,text,col) => {
 
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text} </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    field,
  } = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: async (value) => {
      setSubmit(true);
      try {
        // console.log(value);
        const response = await axios.post(
          CREATEQUESTION,
          {
            question_type: values.questionType,
            "sub-module": submoduleid,
            question: values.question,
            ans_opt1: values.answerOption1,
            ans_opt2: values.answerOption2,
            ans_opt3: values.answerOption3,
            ans_opt4: values.answerOption4,
            correct_ans: values.correctAnswer,
            desc: values.description,
            video_link: values.videoLink,
            status: values.status,
            chapter: values.chapter,
            subject:values.subject,
            explanation :values.explanation
          },
          config
        );
        // console.log(response);
        // if (response) {   navigate(`/admin/testseries/${user}/${subuser}`);}
        if (response) openNotificationWithIcon1("success",'Question Created',"#52c41a");
        setTimeout(() => {
          navigate(-1);
        }, 800);
        // toast.success("Question Created");
      } catch (error) {
        console.log(error);
        setSubmit(false);
        if (error?.response?.status === 422) {
          openNotificationWithIcon2("error", error.response.data.message, "red");
        } else {
          openNotificationWithIcon2("error", "Internal Server Error", "red");
        }
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      }
    },
    validationSchema: courseSchema,
  });
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const openNotificationWithIcon2 = (type,text,col) => {
 
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong> {text}</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Question</h3>
                    <nav
                      aria-label="breadcrumb "
                      style={{ marginTop: "0.5em" }}
                    >
                      <ol class="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li
                          class="breadcrumb-item "
                          onClick={() => navigate("/admin/test-series")}
                          aria-current="page"
                          style={{ cursor: "pointer", marginRight: ".4em" }}
                        >
                          Test Series{" "}
                        </li>
                        <li
                          className="breadcrumb-item p-0 "
                          style={{
                            fontWeight: "normal",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            let ciphertext = CryptoJS.AES.encrypt(
                              JSON.stringify(submoduleid),
                              "my-secret-key@admin8623009959"
                            ).toString();
                            let ciphertexts = encodeURIComponent(ciphertext);
                            navigate(
                              `/admin/test-series/${user}/${ciphertexts}`
                            );
                          }}
                          aria-current="page"
                        >
                          Test
                        </li>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          onClick={() => navigate(`/admin/test-series/show-group-testseries-question/${user}/${uid}`)}
                          style={{ cursor: "pointer", fontWeight: "normal" }}
                        >
                          <a>Show Group Testseries</a>
                        </li>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          onClick={() => navigate(-1)}
                          style={{ cursor: "pointer", fontWeight: "normal",marginLeft:'-.7em' }}
                        >
                          <a>Question</a>
                        </li>
                        <li
                          class="breadcrumb-item active"
                          aria-current="page"
                          style={{ cursor: "pointer", marginLeft: "-1em" }}
                        >
                          <a>Add Question</a>
                        </li>
                      </ol>
                    </nav>
                  </div>
                  {/* <div className="course-group mb-0 d-flex">
                    <div className="course-group-img d-flex align-items-center">
                      <Link to="student-profile">
                        <img src={User11} alt="" className="img-fluid" />
                      </Link>
                      <div className="course-name">
                        <h4>
                          <Link to="student-profile">Your avatar</Link>
                        </h4>
                        <p>PNG or JPG no bigger than 800px wide and tall.</p>
                      </div>
                    </div>
                    <div className="profile-share d-flex align-items-center justify-content-center">
                      <Link to="#;" className="btn btn-success">
                        Update
                      </Link>
                      <Link to="#;" className="btn btn-danger">
                        Delete
                      </Link>
                    </div>
                  </div> */}
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      {loading === true ? (
                        <div
                          class="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            class="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-label">
                                Select Question Type
                              </label>
                              <Select
                                className=" select country-select"
                                name="questionType"
                                id="questionType"
                                options={data?.map(({ id, name }) => ({
                                  label: name,
                                  value: id,
                                }))}
                                placeholder="Select Question Type"
                                onChange={(option) =>
                                  setFieldValue("questionType", option.value)
                                }
                                styles={style}
                              ></Select>
                              {errors.questionType && touched.questionType ? (
                                <span style={{ color: "red" }}>
                                  {errors.questionType}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-label">
                                Select Subject
                              </label>
                              <Select
                                className=" select country-select"
                                name="subject"
                                id="subject"
                                options={sub?.map(({ id, name }) => ({
                                  label: name,
                                  value: id,
                                }))}
                                placeholder="Select subject"
                                onChange={(option) =>
                                  setFieldValue("subject", option.value)
                                }
                                styles={style}
                              ></Select>
                              {errors.subject && touched.subject ? (
                                <span style={{ color: "red" }}>
                                  {errors.subject}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                              Chapter
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Chapter"
                                id="chapter"
                                name="chapter"
                                value={values.chapter}
                                onChange={handleChange}
                              />
                              {errors.chapter && touched.chapter ? (
                                <span style={{ color: "red" }}>
                                  {errors.chapter}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Question
                              </label>
                              <div id="editor">
                                <TextEditor
                                  id="question"
                                  name="question"
                                  // onBlur={handleBlur}
                                  handleChange={handleChange}
                                  setFieldValue={setFieldValue}
                                  value={values.question}
                                />
                                {/* <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              name="description"
                              // onBlur={handleBlur}
                              value={values.description}
                              onChange={handleChange}
                              rows="3"
                            ></textarea> */}
                                {errors.question && touched.question ? (
                                  <span style={{ color: "red" }}>
                                    {errors.question}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Answer Option 1
                              </label>
                              <input
                                type="text"
                                name="answerOption1"
                                id="answerOption1"
                                value={values.answerOption1}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Answer Option 1"
                              />
                              {errors.answerOption1 && touched.answerOption1 ? (
                                <span style={{ color: "red" }}>
                                  {errors.answerOption1}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Answer Option 2
                              </label>
                              <input
                                type="text"
                                name="answerOption2"
                                id="answerOption2"
                                value={values.answerOption2}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Answer Option 2"
                              />
                              {errors.answerOption2 && touched.answerOption2 ? (
                                <span style={{ color: "red" }}>
                                  {errors.answerOption2}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Answer Option 3
                              </label>
                              <input
                                //   type="text"
                                name="answerOption3"
                                id="answerOption3"
                                value={values.answerOption3}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Answer Option 3"
                              />
                              {errors.answerOption3 && touched.answerOption3 ? (
                                <span style={{ color: "red" }}>
                                  {errors.answerOption3}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Answer Option 4
                              </label>
                              <input
                                type="text"
                                name="answerOption4"
                                id="answerOption4"
                                value={values.answerOption4}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Answer Option 4"
                              />
                              {errors.answerOption4 && touched.answerOption4 ? (
                                <span style={{ color: "red" }}>
                                  {errors.answerOption4}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-label">
                                Select Correct Answer
                              </label>

                              <Select
                                className=" select country-select"
                                name="correctAnswer"
                                options={options}
                                defaultValue={options[0]}
                                placeholder="Select Correct Answer"
                                onChange={(option) =>
                                  setFieldValue("correctAnswer", option.value)
                                }
                                styles={style}
                                value={
                                  options
                                    ? options.find(
                                        (option) =>
                                          option?.value === field?.value
                                      )
                                    : ""
                                }
                              ></Select>
                              {errors.correctAnswer && touched.correctAnswer ? (
                                <span style={{ color: "red" }}>
                                  {errors.correctAnswer}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          {/* <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Marks
                              </label>
                              <input
                                type="text"
                                name="marks"
                                id="marks"
                                value={values.marks}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Marks"
                              />
                              {errors.marks && touched.marks ? (
                                <span style={{ color: "red" }}>
                                  {errors.marks}
                                </span>
                              ) : null}
                            </div>
                          </div> */}
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Video Link
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Video Link"
                                id="videoLink"
                                name="videoLink"
                                value={values.videoLink}
                                onChange={handleChange}
                              />
                              {errors.videoLink && touched.videoLink ? (
                                <span style={{ color: "red" }}>
                                  {errors.videoLink}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Question Explanation
                              </label>
                              <div id="editor">
                                <TextEditor
                                  id="explanation"
                                  name="explanation"
                                  // onBlur={handleBlur}
                                  setFieldValue={setFieldValue}
                                  value={values.explanation}
                                />
                                {errors.explanation && touched.explanation ? (
                                  <span style={{ color: "red" }}>
                                    {errors.explanation}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Question Description
                              </label>
                              <div id="editor">
                                <TextEditor
                                  id="description"
                                  name="description"
                                  // onBlur={handleBlur}
                                  setFieldValue={setFieldValue}
                                  value={values.description}
                                />
                                {/* <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              name="description"
                              // onBlur={handleBlur}
                              value={values.description}
                              onChange={handleChange}
                              rows="3"
                            ></textarea> */}
                                {errors.description && touched.description ? (
                                  <span style={{ color: "red" }}>
                                    {errors.description}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label
                                className="form-control-label"
                                style={{
                                  marginRight: "10px",
                                  marginTop: "30px",
                                }}
                              >
                                Status :
                              </label>
                              <br />
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio1"
                                  checked={values?.status === "0"}
                                  onChange={getFieldProps("status").onChange}
                                  value={"0" || values?.status}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineRadio1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadio2"
                                  checked={values?.status === "1"}
                                  onChange={getFieldProps("status").onChange}
                                  value={"1" || values?.status}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineRadio2"
                                >
                                  Active
                                </label>
                              </div>
                              <div>
                                {errors.status && touched.status ? (
                                  <span style={{ color: "red" }}>
                                    {errors.status}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="update-profile" id="submit-button">
                            <div
                              class="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
                            >
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={submit}
                              >
                                Submit
                              </button>
                            </div>{" "}
                            <div
                              class="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
                            >
                              <button
                                type="button"
                                onClick={() => {
                                  navigate(-1);
                                }}
                                className="btn btn-primary cancel-btn "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
