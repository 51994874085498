import React, { useContext, useEffect, useState } from "react";
import Footer from "../../footer";

import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../adminSidebar";
import AdminHeader from "../adminHeader";
import Button from "react-bootstrap/Button";
import CryptoJS from "crypto-js";
import Switch from "react-switch";
import AuthContext from "../../../context/AuthProvider";
import { Search } from "react-feather";
import "../adminTestSeries/index.css";
// import "./index.css";
import axios from "../../../api/axios";
import { Tooltip } from "antd";
import fileDownload from "react-file-download";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { saveAs } from 'file-saver';

import { notification, Space, Modal } from "antd";
import PaginationVideo from "../../pages/course/addCourse/PaginationVideo";
const DELETEEXAMALLERT = "/api/student/examAlerat/delete/pdf";
const EXAMALLERTLISTPDF = "/api/student/exam-alert/pdf";
const STATUSCHANGE = "/api/student/status-update/exam-alert";
const BATCHSEARCH = "/api/search-courses-batch";
const PDFDOWNLAOD="/api/pdflink"
const { confirm } = Modal;

const ExamAlertsPdf = () => {
  const [show, setShow] = useState(false);
  const [showView, setShowView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [iddata, setIdData] = useState("");
  const [message, setmessage] = useState("No Data");
  const [idvalue, setIdValue] = useState("");
  const {id}=useParams()
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.put(
      STATUSCHANGE,
      { courseId: id, status: result },
      config
    );
    response;
    if (response) {
      openNotificationWithIcon1("success");
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
  };
  const[name,setName]=useState([])


  const [last_page, setLast_page] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const [text, settext] = useState("");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleClose = () => setShow(false);
  const handleCloseView = () => setShowView(false);
  const handleShow = (id) => {
    setShow(true);
    // setIdValue(id)
  };

  const navigate = useNavigate();
  const handleView = () => {};
  const handleEdit = (id1) => {
    // var ciphertext = CryptoJS.AES.encrypt(
    //   JSON.stringify(id1),
    //   "my-secret-key@admin8623009959"
    // ).toString();
    // let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/exam-allerts/edit-pdf/${id1}`);
  };

  const fetchExamAlerts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${EXAMALLERTLISTPDF}/${id}`, config);
      setList(response?.data[1]);
      setData(response?.data[1]);
      setName(response?.data[0])

      setIdData(response?.data?.CourseId); // using this setIdData i am sending courseId to create page
    //   setLast_page(response.data?.last_page);
      setCurrentPage(response?.data?.current_page);

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon2("error")
      }
    }
  };

  useEffect(() => {
    fetchExamAlerts();
  }, [token]);
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Exam Alert Pdf Deleted</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const handleDeleteStudent = async () => {
    setLoading(true);
    const resonse = await axios.delete(
      `${DELETEEXAMALLERT}/${idvalue}`,
      config
    );
    setLoading(false);
    if (resonse) {
      openNotificationWithIcon("success");

      fetchExamAlerts();
    }

    setShow(false);
    // toast.warning("Batch Deleted");
    return resonse;
  };
  function showConfirm(id1) {
    confirm({
      title: "Do you want to delete this Exam Allert Pdf?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(
            `${DELETEEXAMALLERT}/${id1}`,
            config
          );
          setLoading(false);
          if (resonse) {
            openNotificationWithIcon("success");
            fetchExamAlerts();
          }
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else{
          openNotificationWithIcon2("error");
          }
        }
      },
      onCancel() {},
    });
  }
  const Filter = async (e) => {
    const searchValue = e.target.value;
    settext(searchValue);
    const res = await axios.post(BATCHSEARCH, { name: searchValue }, config);
    if (res?.data?.data?.length > 0) {
      setList(res?.data?.data);
      setCurrentPage(res.data.current_page);
      setLast_page(res.data.last_page);
      setmessage("No Data");
    }
    if (res.data.data.length === 0) {
      setmessage("No Data Found");
      setList(res?.data?.data);
    }
    if (searchValue.length == 0) {
      const response = await axios.get(
        `${EXAMALLERTLISTPDF}?page=${currentPage}`,
        config
      );
      // setList(response?.data?.data);
      setList(data);
      setCurrentPage(1);
      setLast_page(response.data.last_page);
    }
  };

  const itemsPerPage = 10;
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };

  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  // useEffect(() => {
  //   windowTop(0, 0);
  // }, []);

  const handleDownload = async (id) => {
    try {
      const response = await axios.get(`${PDFDOWNLAOD}/${id}`, config);
  
      window.open(response?.data?.message, '_blank');
  
    } catch (error) {
      console.log(error);
      if (error.response && error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  
  
  
  // const handleDownload = async (id) => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(`${PDFDOWNLAOD}/${id}`, config);
  //     console.log(response?.data)
  //      fileDownload(response?.data, "Exam Allert Pdf");

  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     if (error?.response?.status === 401) {
  //       localStorage.removeItem("token");
  //       navigate("/admin/login");
  //     }
  //   }
  // };
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"exams"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3> {name?.name} Exam Alert Pdf</h3>
                          <nav
                              aria-label="breadcrumb"
                              style={{ marginBottom: "-1.6em" }}
                            >
                              <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate("/admin/exam-allerts")
                                }
                              >
                                Exam Alerts
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate("")
                                }
                              >
                                {" "}
                                {name?.name} Exam Alert Pdf
                              </li>
                            </ol>
                            </nav>
                        </div>
                        <div className="comman-space pb-0">
                          <div className="instruct-search-blk">
                            <div className="show-filter choose-search-blk">
                              {/* <h4 className="text text-secondary">{name.slice(0,60)}</h4> */}
                              <form action="#">
                                {/* <p>Zoom App Details</p> */}
                                <div className="row gx-2 align-items-center">
                                  {/* <div className="col-md-6 col-item">
                                    <div className=" search-group">
                                      <Search
                                        size={16}
                                        style={{
                                          position: "absolute",
                                          left: "7px",
                                          color: "#F66962",
                                        }}
                                      />
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Name"
                                        onChange={Filter}
                                      />
                                      <h4 className="text text-secondary">
                                      </h4>
                                    </div>
                                  </div> */}
                                  <div className="col-md-12 col-lg-12 col-item">
                                    <div
                                      className="form-group select-form mb-0 d-flex justify-content-end"
                                      style={{ marginBottom: "-5px" }}
                                    >
                                      <Button
                                        onClick={() =>
                                            navigate(`/admin/exam-allerts/create-pdf/${id}`)

                                        }
                                        style={
                                          {
                                            //  marginLeft: "5.2em",
                                            // width:"100%"
                                          }
                                        }
                                        className="btn btn-primary"
                                        id="custom-button"
                                      >
                                        {" "}
                                        <i className="fa-solid fa-plus"></i>{" "}
                                        Create New
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="settings-referral-blk table-responsive">
                            {/* Instructor Users */}

                            {/* View Payment */}

                            <table className="table table-nowrap mt-2">
                              <thead>
                                <tr className="text-center">
                                  {/* <th>
                                      <div className="form-check instruct-check-list">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="option1"
                                        />
                                      </div>
                                    </th> */}
                                  <th>SR. NO.</th>
                                  <th>Pdf Name</th>
                                  <th>ACTIONS</th>

                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                                <tbody
                                  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}
                                >
                                  <tr>
                                    <td>
                                      <div
                                        className="spinner-border text-warning"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                list?.map((item, i) => {
                                  const sNo =
                                    (currentPage - 1) * itemsPerPage + i + 1;
                               
                                  const url = item?.url;
                                  return (
                                    <tbody
                                      key={item.id}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <tr className="text-center">
                                        <td>{i+1}</td>
                                        <td>{item?.name}</td>
                                        {/* <td>₹ {Math.round(item.amount).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} </td> */}

                                      
                                       
                                        <td
                                          className="text-center "
                                          // style={{ width: "100px" }}
                                        >
                                          <Tooltip title="Edit">
                                            <button
                                              onClick={() =>
                                                handleEdit(item.id)
                                              }
                                              type="button"
                                              className="btn btn-secondary"
                                            >
                                              <i className="fa-regular fa-pen-to-square"></i>
                                            </button>
                                          </Tooltip>
                                          <Tooltip title="Downlaod pdf">
                                            <button
                                              type="button"
                                              className="btn btn-info"
                                            //   onClick={() => {
                                            //     handleDownload(item?.id);
                                                
                                            //   }}
                                              onClick={() => {
                                                // Open the URL in a new window to trigger the download
                                                window.open(item?.file, '_blank');
                                              }}

                                              style={{ marginLeft: ".5em" }}
                                            >
                                              <i
                                                className="fa fa-download"
                                                style={{ color: "white" }}
                                              ></i>
                                            </button>
                                          </Tooltip>

                                          <Tooltip title="Delete">
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() => {
                                                setIdValue(item?.id),
                                                  showConfirm(item?.id);
                                              }}
                                              style={{ marginLeft: ".5em" }}
                                            >
                                              <i className="fa-regular fa-trash-can"></i>
                                            </button>
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              )}
                            </table>
                            {list?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          {/* <div style={{ position: "relative", top: "-.7em" }}>
                            {list?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationVideo
                                nPages={last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                setLast_page={setLast_page}
                                link={EXAMALLERTLISTPDF}
                                text={text}
                                setData={setList}
                                linkSearch={BATCHSEARCH}
                              />
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
        {/* <ToastContainer /> */}
      </div>
      <Footer />
    </div>
  );
};

export default ExamAlertsPdf;
