import React, { useContext, useEffect } from 'react'
import AuthContext from '../../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';

const ProtectedAdmin = ({Component}) => {
    const navigate =useNavigate()
  const { auth} = useContext(AuthContext);
    useEffect(()=>{
      const token = localStorage.getItem("token");
        if(!token) navigate('/admin/login')
    },[])
  return (
    <div>
        <Component/>
    </div>
  )
}

export default ProtectedAdmin