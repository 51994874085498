import React, { useEffect, useState } from "react";
import StudentHeader from "../../../student/studentHeader";
import CryptoJS from "crypto-js";
import { notification, Space } from "antd";

import DetailsContent from "./detailsContent";
import { Icon1, People, Timer, User1, bannerimg } from "../../../imagepath";
import Footer from "../../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../../api/axios";
import FooterStudent from "../../../fotterstudent";
import axiosInstance from "../../../../api/axios1";
import { Tooltip } from "antd";
const VIEWVIDEO = "/api/student/view-video";

const VideoDetails = () => {
  const { id,name } = useParams();
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState(null);
  const navigate = useNavigate();
  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const token = localStorage.getItem("tokenStudent");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const viewVidos = async () => {
    try{
      setLoading(true);
      const response = await axiosInstance.get(`${VIEWVIDEO}/${decryptedData}`, config);
      setRes(response.data.res);
      // console.log(response.data.res);
      setLoading(false);
    }
    catch(error){
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('tokenStudent')
        navigate('/login')
      }
      else{
        openNotificationWithIcon2("error")

      }
    }
   
  };
  useEffect(() => {
    viewVidos();
  }, []);
  const openNotificationWithIcon2 = (type) => {
    console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"red"}}>
         <strong>Internal Server Error </strong> 
        </div>
      ),
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted
  
      // </div>)
      duration:1
    });
    };
    const openNotificationWithIconSesion = (type) => {
      notification[type]({
        message: (
          <div style={{ color: "red" }}>
            <strong>Session Expired Please Login </strong>
          </div>
        ),
        duration: 1,
      });
    };
  return (
    <>
      <div className="main-wrapper">
        <StudentHeader  activeMenu={"videos"}/>

        <div className="breadcrumb-bar">
          <div className="container"
          style={{minWidth:"98%",margin:"auto"}}

          >
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/student/videos">Videos</Link>
                      </li>
                      <li
                        className="breadcrumb-item"
                        onClick={() => navigate(-1)}
                      >
                        <Link>{name[0].toUpperCase()+name.slice(1)}</Link>{" "}
                      </li>
                      <li
                        className="breadcrumb-item"
                        onClick={() => navigate(-1)}
                      >
                        <Link>
                        <Tooltip title={res?.title}>
                                            {`${res?.title.slice(0, 15)} ${
                                              res?.title.length > 15
                                                ? "..."
                                                : ""
                                            }`}
                                          </Tooltip>
                        
                        </Link>{" "}
                      </li>
                      <li className="breadcrumb-item">
                        <Link> Video Details</Link>{" "}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading === true ? (
          ""
        ) : (
          <div>
            <div
              className="inner-banner"
              style={{
                backgroundImage: "url(" + bannerimg + ")",
                height: "250px",
                minWidth:"98%",
                margin:"auto"
              }}
            >
              <div className="container"
                        // style={{minWidth:"98%",margin:"auto"}}

              >
                <div className="row">
                  <div className="col-lg-8">
                    <div className="instructor-wrap border-bottom-0 m-0">
                      <div className="about-instructor align-items-center"></div>
                    </div>
                  
                    <h2 style={{textTransform:"capitalize"}}>
                  

                    </h2>

                    <div className="course-info d-flex align-items-center border-bottom-0 m-0 p-0">
                      <div className="cou-info">
                        {/* <img src={Timer} alt="" />
                        <p>9hr 30min</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DetailsContent res={res} />
          </div>
        )}

        <FooterStudent />
      </div>
    </>
  );
};

export default VideoDetails;
