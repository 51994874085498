import React from "react";
import axios from "../../../../api/axios";

const PaginationMod = ({
  nPages,
  currentPage,
  setCurrentPage,
  link,
  setData,
  linkSearch,
  text,
}) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleSetData = async (pgNumber) => {
    if (text.length > 0) {
      try {
        const res = await axios.post(
          `${linkSearch}?page=${pgNumber}`,
          { name: text },
          config
        );
        if (res.data.module.data.length > 0) {
          setCurrentPage(pgNumber);
          setData(res.data.module.data);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const res = await axios.get(`${link}?page=${pgNumber}`, config);
      setCurrentPage(pgNumber);
      setData(res.data.module.data);
    }
  };

  const nextPage = async () => {
    if (currentPage !== nPages) {
      if (text.length > 0) {
        try {
          const res = await axios.post(
            `${linkSearch}?page=${currentPage + 1}`,
            { name: text },
            config
          );
          if (res.data.module.data.length > 0) {
            setCurrentPage(currentPage + 1);
            setData(res.data.module.data);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setCurrentPage(currentPage + 1);
        const res = await axios.get(`${link}?page=${currentPage + 1}`, config);
        setData(res.data.module.data);
      }
    }
  };

  const prevPage = async () => {
    if (currentPage !== 1) {
      if (text.length > 0) {
        try {
          const res = await axios.post(
            `${linkSearch}?page=${currentPage - 1}`,
            { name: text },
            config
          );
          if (res.data.module.data.length > 0) {
            setCurrentPage(currentPage - 1);
            setData(res.data.module.data);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setCurrentPage(currentPage - 1);
        const res = await axios.get(`${link}?page=${currentPage - 1}`, config);
        setData(res.data.module.data);
      }
    }
  };

  const renderPageNumbers = () => {
    let pages = [];

    if (nPages <= 1) {
      pages = [1];
    } else if (nPages <= 6) {
      pages = [...Array(nPages).keys()].map((i) => i + 1);
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, 4, 5, null, nPages];
      } else if (currentPage >= nPages - 2) {
        pages = [
          1,
          null,
          nPages - 4,
          nPages - 3,
          nPages - 2,
          nPages - 1,
          nPages,
        ];
      } else {
        pages = [
          1,
          null,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          null,
          nPages,
        ];
      }
    }

    return pages.map((pgNumber, index) => (
      <li
        key={index}
        className={`${
          currentPage === pgNumber
            ? "page-item first-page active"
            : "page-item first-page "
        } `}
      >
        {pgNumber !== null ? (
          <a
            onClick={() => handleSetData(pgNumber)}
            className="page-link"
            href="#"
          >
            {pgNumber}
          </a>
        ) : (
          <span>
            {" "}
            <a className="page-link" href="#">
              ....
            </a>
          </span>
        )}
      </li>
    ));
  };

  return (
    <>
      <nav>
        <ul className="pagination lms-page d-flex align-items-center justify-content-center">
          <li className="page-item prev">
            <a className="page-link" onClick={prevPage} href="#">
              <i className="fas fa-angle-left" />
            </a>
          </li>
          {renderPageNumbers()} {/* Call renderPageNumbers here */}
          <li className="page-item next">
            <a className="page-link" onClick={nextPage} href="#">
              <i className="fas fa-angle-right" />
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default PaginationMod;
