import axios from "axios";
import { notification, Space } from "antd";
// import { useNavigate } from "react-router-dom";

const BASEURL ='https://aspirantcode.chahalacademy.com'

const axiosInstance = axios.create({
  baseURL: BASEURL,
  headers: {
    // Default content type
   'Db': sessionStorage.getItem("db") ? sessionStorage.getItem("db") : 0, // Default accept type
}
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("tokenStudent");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("tokenStudent");
      localStorage.removeItem("showNotification")
      window.location.href = "/login"; // Redirect to login page
      const keysToRemove = Object.keys(localStorage).filter(key => key.startsWith('notificationClosed_'));
      keysToRemove.forEach(key => localStorage.removeItem(key));
    }
    else if (error?.response?.status === 403) {
      localStorage.removeItem("tokenStudent");
    sessionStorage.removeItem("db")
    sessionStorage.removeItem("centername")
setTimeout(()=>{
  window.location.href = "/login"; // Redirect to login page

},1000)

      // setTimeout(() => {
      //   navigate("/login");
      // }, 1000); // Delay the navigation to show the notification
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
