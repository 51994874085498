import React, { useState, useContext, useEffect } from "react";

import AdminHeader from "../../adminHeader";
import Footer from "../../../footer";
import { AudioOutlined } from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import { Video2 } from "../../../imagepath";
import PaginationLink from "../../../pages/course/addCourse/PaginationLink";
import CryptoJS from "crypto-js";
import axios from "../../../../api/axios";
import { notification, Space } from "antd";


import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../adminSidebar";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "antd";
import Select from "react-select";

const TESTSERIESSUBMODULE = "/api/admin/submodule/testseries";
const TESTSERIESMODULESUBMIT = "/api/test-series/submodule-listlink-save";
const TESTSERIESMODULESELECT="/api/admin/module/get";

const { confirm } = Modal;

const TestseriesSubModule1 = () => {
  const { id, user } = useParams();
  // console.log(id,"id",user)
  const [centers,setCenters]=useState([])

  const { Search } = Input;
  const [list, setList] = useState([]);
  const [listdata, setListData] = useState([]);
  const [last_page, setLast_page] = useState("");
  const [records1, setRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [ids, setIds] = useState(id);
  const [modulename,setModuleName]=useState([])
  const [modulenameId,setModuleNameId]=useState("")
  const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const handleShowForm = () => {
    setIsModalVisibleForm(true);
  };
  const handleModalClose = () => {
    setIsModalVisibleForm(false);
  };

  const navigate = useNavigate();
  // var bytes = CryptoJS.AES.decrypt(
  //   decodeURIComponent(id),
  //   "my-secret-key@admin8623009959"
  // );
  // let midcode = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // let decrptSid = CryptoJS.AES.decrypt(
  //   decodeURIComponent(id),
  //   "my-secret-key@admin8623009959"
  // );

  // let sidcode = JSON.parse(decrptSid.toString(CryptoJS.enc.Utf8));

  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const TestseriesModule=async()=>{
    try{
const response=await axios.get(`${TESTSERIESMODULESELECT}/${1}`,config)
// console.log(response,"res")
const filteredData = response.data.filter(item => item?.id !== id);
// console.log(filteredData,"found")
 setModuleName(filteredData)
    }
    catch(error){
      console.log(error)
      
    }
  }
 
  const handelcenterchange=(value)=>{
    // console.log(value)
    // setSelectedModule(value)
    // if(!value)return
    // if(checked?.length>0){
    //   return handleShowForm()

    // }
    // else{
      setModuleNameId(value?.value)
      fecthRecordedList(value?.value)
  
    // }
  }
  // console.log(selectedModule,"selected")
  useEffect(()=>{
    TestseriesModule()
  },[])
  // console.log(modulenameId,"name")
  const fecthRecordedList = async (value) => {
    try {
      setLoading(true);
      const resonse = await axios.get(
        `${TESTSERIESSUBMODULE}/${value}`,
        config
      );
      // console.log(resonse,"res")
      setList(resonse?.data);
      setListData(resonse?.data);

      setLoading(false);

      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    } catch (error) {
      console.log(error);
      if (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
          openNotificationWithIconSesion("error")}
          else{
          openNotificationWithIcon2("error");
        }
      }
    }
  };
  useEffect(() => {
    // fecthRecordedList();
  }, [modulenameId]);
  // console.log(list,"list")
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, text) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type, text) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const handleChange = (name, check) => {
    const arr = [...checked];
    if (!arr.includes(name)) {
      setChecked([...arr, name]);
    } else {
      const res = arr.filter((e) => e !== name);
      setChecked(res);
    }
  };
  // console.log(checked,"checked")

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(checked?.length==0){
      return openNotificationWithIcon1("error", "Select Test")

    }
    
   
    try {
      const response = await axios.post(
        TESTSERIESMODULESUBMIT,
        {
            submoduleId: id,
          subModulename: checked,
        },
        config
      );
      if (response) {
        setTimeout(() => {
          navigate(-1);
        }, 1000);

        openNotificationWithIcon("success", "Test Created");
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if(error?.response?.status==403){
        openNotificationWithIcon1("error",error?.response?.data?.error)

      }
      else{
        openNotificationWithIcon2("error");

      }
    }
  };
  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      const res = list.filter((e) =>
        e.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      if (res.length > 0) {
        setList(res);
      }
      if (searchValue.length === 0) {
        setList(listdata);
      }
    } catch (error) {
      console.error("Error deleting student:", error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const visiblerecordsData =list?.slice(indexOfFirstCourse, indexOfLastCourse);
  // console.log(visiblerecordsData,"course")
  const recordsDataLength = list?.length ? list?.length : 0;

  const numOFTotalPage = Math.ceil(recordsDataLength / 10);
  const page = [...Array(numOFTotalPage + 1).keys()].slice(1);

  const prevPageHandler = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const nextPageHandler = () => {
    if (currentPage !== numOFTotalPage) setCurrentPage(currentPage + 1);
    // windowTop(0,0);
  };
  const itemsPerPage = 10;

  let data = [];
  const renderPagination = () => {
    let pages = [];
    const pagesToShow = 6;
    const totalPageCount = Math.ceil(recordsDataLength / itemsPerPage);
    if (totalPageCount <= pagesToShow) {
      return [...Array(totalPageCount).keys()].map((pageNum) => (
        <li
          key={pageNum}
          className={
            currentPage === pageNum + 1 ? "page-item active" : "page-item"
          }
        >
          <Link
            className="page-link"
            to="#"
            onClick={() => setCurrentPage(pageNum + 1)}
          >
            {pageNum + 1}
          </Link>
        </li>
      ));
    } else {
      if (currentPage <= 3) {
        for (let i = 0; i < 5; i++) {
          pages.push(
            <li
              key={i}
              className={
                currentPage === i + 1 ? "page-item active" : "page-item"
              }
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li key={totalPageCount - 1} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      } else if (currentPage >= totalPageCount - 2) {
        pages.push(
          <li key={0} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        for (let i = totalPageCount - 5; i < totalPageCount; i++) {
          pages.push(
            <li
              key={i}
              className={
                currentPage === i + 1 ? "page-item active" : "page-item"
              }
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
      } else {
        pages.push(
          <li key={0} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i)}
              >
                {i}
              </Link>
            </li>
          );
        }
        pages.push(
          <li key="ellipsis2" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
        pages.push(
          <li key={totalPageCount - 1} className="page-item">
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      }
      return pages;
    }
  };
  if (Array.isArray(visiblerecordsData)) {
    // Only sort if records1 is an array
    data = visiblerecordsData.sort((a, b) => {
      if (sortColumn !== "") {
        if (sortDirection === "asc") {
          if (a[sortColumn] < b[sortColumn]) return -1;
          if (a[sortColumn] > b[sortColumn]) return 1;
          return 0;
        } else {
          if (a[sortColumn] < b[sortColumn]) return 1;
          if (a[sortColumn] > b[sortColumn]) return -1;
          return 0;
        }
      } else {
        return a.id - b.id;
      }
    });
  }

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  const moduledata = (user, id) => {
    navigate(`/admin/test-series/${user}/${encodeURIComponent(id)}}`);
  };

  return (
    <div className="main-wrapper ">
      <AdminHeader />
      <div className="page-content ">
        <div
          className="container"
          style={{ maxWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <div className="d-flex"
                          style={{justifyContent:"space-between"}}
                          >
                      <div>
                   <h3> All Tests </h3>
                          <nav
                            aria-label="breadcrumb"
                            style={{ marginBottom: "-1.6em" }}
                          >
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() => navigate("/admin/test-series")}
                              >
                                TestSeries
                              </li>
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                  fontWeight: "normal",
                                }}
                                onClick={() =>
                                  // navigate(`/admin/test-series/${user}/${id}`)
                                  moduledata(user, id)
                                }
                              >
                                {" "}
                                Test
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                  marginLeft: "-.8em",
                                }}
                              >
                                {" "}
                                Add Tests
                              </li>
                            </ol>
                          </nav>
</div>
<div className="">
                          <div className="form-group" 
                          style={{marginTop:"20px"}}
                          >
                            {/* <label className="form-control-label">Select Center</label> */}
                            <Select
  options={modulename?.map(center => ({ value: center.id, label: center.name }))}
  placeholder="Select Test series "
   onChange={handelcenterchange}
  style={{marginTop:"10px"}}
/>


                            {/* {errors.fk_center_id && touched.fk_center_id ? <span style={{ color: "red" }}>{errors.fk_center_id}</span> : null} */}
                          </div>
                        </div>
                          </div>
                          
                        </div>
                        <div className="comman-space pb-0">
                          <div
                            className="settings-referral-blk table-responsive"
                            id="table-value"
                          >
                            <div className="instruct-search-blk">
                              <div className="show-filter choose-search-blk">
                                <form action="#">
                                  <div className="row  gx-2 align-items-center">
                                    <div className="col-lg-6 col-md-12 col-item d-flex">
                                      <div className=" search-group justify-content-start">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          style={{
                                            position: "absolute",
                                            left: "7px",
                                            color: "rgb(246, 106, 98)", // Change the color to yellow
                                          }}
                                        >
                                          <circle
                                            cx="11"
                                            cy="11"
                                            r="8"
                                          ></circle>
                                          <line
                                            x1="21"
                                            y1="21"
                                            x2="16.65"
                                            y2="16.65"
                                          ></line>
                                        </svg>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search"
                                          // allowClear
                                          onChange={Filter}
                                          style={{ width: "100%" }}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                   
                                    </div>

                                    <div className="col-md-6 col-lg-6 col-item">
                                      <div
                                        className="d-flex justify-content-end"
                                        style={{ gap: "8px" }}
                                      >
                                        <button
                                          onClick={(e) => handleSubmit(e)}
                                          className="btn btn-primary"
                                          id="custom-button"
                                          style={{ padding: "5px 20px" }}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <Modal
                              title="Test series Module"
                              okButtonProps={{
                                style: { backgroundColor: "#FF875A" },
                                children: "Import",
                              }}
                              open={isModalVisibleForm}
                              okText="Create"
                              onOk={() => {
                                handleAddUser();
                              }}
                              onCancel={handleModalClose}
                            >
                             <div>
                              <h5>
                                You had selected a Test-series .Do you want to revert those changes?
                              </h5>
                             </div>
                            </Modal>

                            <table className="table table-nowrap mb-2">
                              <thead>
                                <tr className="text-center">
                                  <th>SR NO</th>
                                  <th style={{ textAlign: "center" }}>Name</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                                  <tbody className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}>
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                              ) : (
                               <>
                                  {visiblerecordsData?.length > 0
                                    ? visiblerecordsData?.map((item, i) => {
                                        const sNo =
                                          (currentPage - 1) * itemsPerPage +
                                          i +
                                          1;
                                        const res = checked?.filter(
                                          (e) => e == item.id
                                        );
                                        return (
                                          <tbody style={{ textTransform: "capitalize",textAlign:"center" }} key={item.id}>
                                          <tr >
                                            <td className="text-center">
                                              {sNo}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.name}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              <div className="form-check form-check-inline">
                                                {res.map((e) => {
                                                  if (e) {
                                                    return (
                                                      <input
                                                        checked
                                                        className="form-check-input"
                                                        // style={{
                                                        //   marginLeft: "2em",
                                                        // }}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                          handleChange(
                                                            item?.name,
                                                            e.target.checked
                                                          );
                                                        }}
                                                        id="inlineCheckbox1"
                                                      />
                                                    );
                                                  } else {
                                                    return (
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        // style={{
                                                        //   marginLeft: "2em",
                                                        // }}
                                                        onChange={(e) => {
                                                          handleChange(
                                                            item?.name,
                                                            e.target.checked
                                                          );
                                                        }}
                                                        id="inlineCheckbox1"
                                                      />
                                                    );
                                                  }
                                                })}
                                                {res.length == 0 ? (
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    // style={{
                                                    //   marginLeft: "2em",
                                                    // }}
                                                    onChange={(e) => {
                                                      handleChange(
                                                        item?.name,
                                                        e.target.checked
                                                      );
                                                    }}
                                                    id="inlineCheckbox1"
                                                  />
                                                ) : null}
                                              </div>
                                            </td>
                                          </tr>
                                          </tbody>
                                        );
                                      })
                                    : 
                                    ""
                                    }
                               </>
                               
                              )}
                              
                            </table>
                            {/* Instructor List */}
                            {modulenameId==""?
                            <h5 className="text-center">{"Please Select Test series Module"}</h5>

                            :""}
                            {modulenameId &&list?.length <= 0 ? (
                             <h5 className="text-center">{"No Data Found"}</h5>
                            ) : (
                              ""
                            )}
                            { modulenameId &&list === null ? (
                              <h5 className="text-center">{"No Data Found"}</h5>
                            ) : (
                              ""
                            )}
                          </div>
                          {visiblerecordsData?.length>0?<div className="fixtable">
                            <div className="fixtable">
                              <ul
                                className="pagination lms-page d-flex align-items-center justify-content-center"
                                // style={{ marginLeft: "1.5em" }}
                              >
                                <li
                                  className="page-item prev"
                                  onClick={() => prevPageHandler()}
                                >
                                  <Link
                                    className="page-link"
                                    to="#"
                                    tabIndex={-1}
                                  >
                                    <i className="fas fa-angle-left" />
                                  </Link>
                                </li>
                                {renderPagination()}
                                <li
                                  className="page-item next"
                                  onClick={() => nextPageHandler()}
                                >
                                  <Link className="page-link" to="#">
                                    <i className="fas fa-angle-right" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>:""}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
      {/* <ToastContainer /> */}
    </div>
  );
};
export default TestseriesSubModule1;
