import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { QuizData } from "./QuizData";
import Footer from "../../footer";
// import CourseHeader from "../header";
import CourseHeader  from "../../pages/course/header";
import StudentHeader from "../studentHeader";
// import Basic from "../../pages/course/addCourse/basic";
import Basic from "./Basic";
import CourseMedia from "../../pages/course/addCourse/courseMedia";

import Curriculum from "../../pages/course/addCourse/curriculum";

import Settings from "../../pages/course/addCourse/settings";
import Success from "../../pages/course/addCourse/success";
import {
  BookOpen,
  Calendar,
  CreditCard,
} from "feather-icons-react/build/IconComponents";
import  Result  from "./Result";
const AddStudentTestSeires = () => {
  const [TabChange, setTabChange] = useState(false);
  const [TabChange1, setTabChange1] = useState(false);
  const [TabChange2, setTabChange2] = useState(false);
  const [TabChange3, setTabChange3] = useState(false);
  const [PageChange, setPageChange] = useState("basic");

  const nextTab = () => {
    setTabChange(true);
    setPageChange("courseMedia");
  };

  const prevTab1 = () => {
    setTabChange(false);
    setPageChange("basic");
  };

  const nextTab2 = () => {
    setTabChange1(true);
    setTabChange(true);
    setPageChange("curriculum");
  };

  const prevTab2 = () => {
    setTabChange1(false);
    setPageChange("courseMedia");
  };

  const nextTab3 = () => {
    setTabChange2(true);
    setTabChange(true);
    setPageChange("settings");
  };

  const prevTab3 = () => {
    setTabChange2(false);
    setPageChange("curriculum");
  };

  const nextTab4 = () => {
    setTabChange3(true);
    setTabChange(true);
    setPageChange("success");
  };

  return (
    <>
      <div className="main-wrapper">
        {/* <StudentHeader activeMenu={""} /> */}
       
          <div className="container"  
          style={{minWidth:"100%",margin:"auto"}}
>
<Result />
            
           
          </div>

        {/* <Footer /> */}
      </div>
    </>
  );
};
export default AddStudentTestSeires;
