import React from "react";
import CourseHeader from "../header";
import StudentHeader from "../../../student/studentHeader";
import InnerBanner from "../../../../assets/img/inner-banner.jpg";
import DetailsContent from "./detailsContent";
import { Icon1, People, Play, Timer, User1, bannerimg } from "../../../imagepath";
import Footer from "../../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import axios from "../../../../api/axios";
import { useEffect } from "react";
import FooterStudent from "../../../fotterstudent";
import axiosInstance from "../../../../api/axios1";
const DETAILS = "/api/student/course";
const DETAILSDATA = "/api/student/course/detail";

const StudentCourseDetails = () => {
  const { slug } = useParams();
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [videos,setvideos]=useState(0)
  const [testseries,settestseries]=useState(0)
  const navigate = useNavigate();
  const tokenStudent = localStorage.getItem("tokenStudent");
  useEffect(() => {
    const tokenStudent = localStorage.getItem("tokenStudent");
    if (!tokenStudent) {
      navigate("/login");
    }
  }, []);
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const getDetail = async () => {
    try {
      setLoading(true);
      const responce = await axiosInstance.get(`${DETAILS}/${slug}`, config);
      setDetail(responce.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    const lastVisiteStudentRoute = localStorage.getItem(
      "lastVisiteStudentRoute"
    );
    if (lastVisiteStudentRoute) {
      localStorage.removeItem("lastVisiteStudentRoute");
    } else {
      localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);
    }
  }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };
  
  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  return (
    <>
      <div className="main-wrapper">
        <StudentHeader />

        <div className="breadcrumb-bar">
          <div
            className="container"
            style={{ minWidth: "98%", margin: "auto" }}
          >
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        <Link to="/student/my-subscriptions">My Subscriptions</Link>
                      </li>
                      {/* <li className="breadcrumb-item" aria-current="page">
                        All Courses
                      </li> */}
                      <li className="breadcrumb-item" aria-current="page">
                        {detail?.name}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="inner-banner "
          style={{
            backgroundImage: "url(" + detail?.image + ")",
            height: "250px",
            minWidth: "98%",
            margin: "auto",
          }}
        >
          <div
            className="container"
            // style={{ minWidth: "98%", margin: "auto" }}
          >
            <div className="row">
              <div className="col-lg-8">
                <div className="instructor-wrap border-bottom-0 m-0">
                  <div className="about-instructor align-items-center">
                    {/* <div className="abt-instructor-img">
                      <Link to="/instructor-profile">
                        <img
                          src={User1}
                          alt="img"
                          className="img-fluid"
                        />
                      </Link>
                    </div> */}
                    {/* <div className="instructor-detail me-3">
                      <h5>
                        <Link to="/instructor-profile">{detail?.name}</Link>
                      </h5>
                      <p>UX/UI Designer</p>
                    </div>
                    <div className="rating mb-0">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star me-1" />
                      <span className="d-inline-block average-rating">
                        <span>4.5</span> (15)
                      </span>
                    </div> */}
                  </div>
                  {/* <span className="web-badge mb-3">WEB DEVELPMENT</span> */}
                </div>
                <h2 >{detail?.name}</h2>
                {/* <p>
                  Learn Web Development by building 25 websites and mobile apps
                  using HTML, CSS, Javascript, PHP, Python, MySQL &amp; more!
                </p> */}
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: detail?.description,
                  }}
                /> */}
                <div className="course-info d-flex align-items-center border-bottom-0 m-0 "
                
                >
                  <div className="cou-info">
                     <img src={Icon1} alt="" />
                    <p>{testseries} Test</p> 
                  </div>
                  <div className="cou-info">
                    <img src={Timer} alt="" />
                    <p>{detail?.duration} Days </p>
                  </div>
                  <div className="cou-info">
                    <img src={Play} alt="" />
                    <p>{videos} Videos</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {detail ? (
          <DetailsContent
            amount={detail?.amount}
            description={detail?.description}
            image={detail?.image}
            slug={slug}
            loading={loading}
            name={detail?.name}
            id={detail?.id}
            setvideos={setvideos}
            settestseries={settestseries}
          />
        ) : (
          ""
        )}

        <FooterStudent />
      </div>
    </>
  );
};

export default StudentCourseDetails;

{
  /* <div className="col-lg-4 theiaStickySidebar">
<div className="stickysidebar">
  <div className="student-widget select-plan-group mt-2 shadow-sm rounded p-4" style={{ transition: "box-shadow 0.3s ease" }}>
    <div className="student-widget-group">
      <div className="plan-header">
        <h4>Selected Course</h4>
      </div>

      <div className="basic-plan">
        <h3>{data?.name}</h3>

        <h2>
          <span>₹</span>
          {data?.amount === 0
            ? "Free"
            : parseInt(data?.amount).toLocaleString("en-IN", {
                maximumFractionDigits: 0,
              })}
        </h2>
      </div>
     
      
      
    </div>
  </div>
</div>
</div> */
}
