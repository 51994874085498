import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../footer";
import AdminInstructorSidebar from "../adminSidebar";

import AuthContext from "../../../context/AuthProvider";
import { Button } from "react-bootstrap";
import { Tooltip } from "antd";
import Switch from "react-switch";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import AdminHeader from "../adminHeader";
import CryptoJS from "crypto-js";
import PaginationSub from "../../pages/course/addCourse/PaginationSub";


const SHOWSUDMODULE = "/api/test-series/show-group-testseries-question";
const DELETEMODULE = "/api/test-series/delete-testseries-submodule";
const MODULESTATUS = "/api/test-series/status-testseries-submodule";
const SEARCHSUBMODULEAPI = "/api/test-series/testseries-submodule-search";
import { Modal } from "antd";
import { notification, Space } from "antd";

const { confirm } = Modal;

export const AdminGroupTestseries = () => {
  const [show, setShow] = useState(false);
  const [text, settext] = useState("");
  const [list, setList] = useState(null);
  const [moduleName, setModuleName] = useState("");
  const [message, setmessage] = useState("No Data");

  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [last_page, setLast_page] = useState("");
  const navigate = useNavigate();
  const { user,id } = useParams();

  const handleEdit = (modid, submodid) => {
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(modid),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/edit/test-series/${ciphertexts}/${user}/${encodeURIComponent (id)}/${1}`);
  };

  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const handleClose = () => setShow(false);
  const [ids, setIds] = useState("");
  const [loading, setLoading] = useState(false);
  const [subModule, setSubModule] = useState(false);
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      MODULESTATUS,
      { id: id, status: result },
      config
    );
    if (response) {
      openNotificationWithIcon1("success");
    }
    if (!response) {
      openNotificationWithIcon2("error");
    }
    // toast.success("Status Change");
    const editCheck = [...list];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setList(editCheck);
    // console.log(list);
  };
  const handleRoute = async (slug,fk_test_structure_id,mainid
    ) => {
      if(fk_test_structure_id==1){
    navigate(`/admin/test-series/show-group-module-testseries-question/${mainid}/${slug}/${id}`)
      }
      else{
        navigate(`/admin/testseries/group/${user}/${slug}/${id}`);

      }
  };
  const ShowSudModule = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${SHOWSUDMODULE}/${user}`, config);
      setList(response?.data?.subModule.data);
      setData(response?.data?.subModule.data);
      setLast_page(response?.data?.subModule.last_page);
      setModuleName(response?.data?.module.name);
      // setCurrentPage(page);
      const subModuleData = response.data.subModule.data;
      const singleValue = subModuleData.find((item) => item.id === item.id);
      setSubModule(singleValue?.module_id);

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      openNotificationWithIcon2("error");
    }
  };
  const DeleteTestSeries = async () => {
    if (ids) {
      try {
        setLoading(true);
        const resonse = await axios.get(`${DELETEMODULE}/${ids}`, config);
        // console.log("resonse", resonse);
        toast.warning("Test Deleted");
        setLoading(false);
        ShowSudModule();
        setShow(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      }
    }
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Test Deleted</strong>
        </div>
      ),
      duration: 1,

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon1 = (type) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this test?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETEMODULE}/${id}`, config);
          // console.log("resonse", resonse);
          // toast.warning("Test Deleted");
          openNotificationWithIcon("success");
          setLoading(false);
          ShowSudModule();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          openNotificationWithIcon2("error");
        }
      },
      onCancel() {},
    });
  }
  useEffect(() => {
    ShowSudModule();
  }, []);

  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      settext(searchValue);
      const res = await axios.post(
        SEARCHSUBMODULEAPI,
        { name: searchValue, module: subModule },
        config
      );
      if (res.data.subModule.data.length > 0) {
        setList(res.data.subModule.data);
        setCurrentPage(res.data.subModule.current_page);
        setLast_page(res.data.subModule.last_page);
        setmessage("No Data");
      }
      if (res.data.subModule.data.length == 0) {
        setList(res.data.subModule.data);
        setmessage("No Data Found");
      }
      if (searchValue.length === 0) {
        const res = await axios.get(
          `${SHOWSUDMODULE}/${user}?page=${currentPage}`,
          config
        );
        setList(res.data.subModule.data);
        setCurrentPage(1);
        setLast_page(res.data.subModule.last_page);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    // console.log("krishna start");

    if (lastVisitedRoute) {
      // console.log("krishna middle");

      localStorage.removeItem("lastVisitedRoute");
    } else {
      // console.log("krishna new");
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  // useEffect(() => {
  //   windowTop(0, 0);
  // }, []);

  const itemsPerPage = 10;
  return (
    <div className="main-wrapper">
      <AdminHeader activeMenu={"Test"} />
      <div className="page-content instructor-page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget" style={{ border: "none" }}>
                    <div
                      className="settings-inner-blk p-0"
                      style={{ border: "1px solid #e9ecef" }}
                    >
                      <div className="sell-course-head comman-space">
                        <div className="filter-grp ticket-grp d-flex align-items-center justify-content-between">
                          <div>
                            <h3 style={{ textTransform: "capitalize" }}>
                              Test ({moduleName})
                            </h3>
                            <nav
                              aria-label="breadcrumb"
                              style={{
                                marginTop: "0.5em",
                                marginBottom: "-1.6em",
                              }}
                            >
                              <ol className="breadcrumb">
                                <li
                                  className="breadcrumb-item"
                                  aria-current="page"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navigate("/admin/test-series")}
                                >
                                  Test Series
                                </li>
                                <li
                                  className="breadcrumb-item"
                                  aria-current="page"
                                  style={{
                                    cursor: "pointer",
                                    fontWeight:'normal'
                                    // marginLeft: "-.6em",
                                  }}
                                  onClick={()=>navigate(-1)}
                                >
                                  Test
                                </li>
                                <li
                                  className="breadcrumb-item active"
                                  aria-current="page"
                                  style={{
                                    cursor: "pointer",
                                    color: "rgb(108, 117, 125)",
                                    marginLeft: "-.8em",
                                  }}
                                >
                                  Show Group Testseries
                                </li>
                              </ol>
                            </nav>
                          </div>
                        </div>
                      </div>
                      <div className="comman-space pb-0">
                        <div
                          className="settings-tickets-blk course-instruct-blk table-responsive"
                          id="table-value"
                        >
                          <div className="instruct-search-blk">
                            <div className="show-filter choose-search-blk">
                              <form action="#">
                                <div
                                  className="row  gx-2 align-items-center"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <div className="col-md-3 col-item">
                                    <div
                                      className=" search-group justify-content-start"
                                      id="search-btn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        style={{
                                          position: "absolute",
                                          left: "7px",
                                          color: "rgb(246, 106, 98)",
                                        }}
                                      >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line
                                          x1="21"
                                          y1="21"
                                          x2="16.65"
                                          y2="16.65"
                                        ></line>
                                      </svg>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Name"
                                        // allowClear
                                        onChange={Filter}
                                        onKeyDown={handleKeyPress}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6 col-lg-6 col-item">
                                    <div className="form-group select-form mb-0 d-flex flex-wrap justify-content-lg-end justify-content-sm-start">
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `/admin/add/test-series/${encodeURIComponent(
                                              id
                                            )}/${user}/${1}`
                                          )
                                        }
                                        className="btn btn-primary me-md-1 me-sm-0 mb-1"
                                        id="custom-button"
                                      >
                                        <i className="fa-solid fa-plus"></i>{" "}
                                        Create New
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          {/* Referred Users */}
                          
                          <table className="table table-nowrap mb-0">
                            <thead>
                              <tr className="text-center">
                                <th>SR. NO.</th>
                                <th>NAME</th>
                                <th>STATUS</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            {loading === true ? (
                             
                                <tbody className="d-flex justify-content-center align-items-center"
                                style={{
                                  position: "fixed",
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  left: 0,
                                  backgroundColor: "rgba(255, 255, 255, 0.6)",
                                }}>
                                  <tr>
                                    <td>
                                      <div
                                        className="spinner-border text-warning"
                                        role="status"
                                      >
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                            
                            ) : list?.length > 0 ? (
                              list?.map((item, i) => {
                                const serialNumber =
                                  (currentPage - 1) * itemsPerPage + i + 1;
                                return (
                                  <tbody key={item.id}>
                                    <tr
                                      className="text-center"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <td>{serialNumber}</td>
                                      <td>
                                        <a
                                          role="button"
                                          onClick={() => {
                                            handleRoute(item?.slug,
                                              item?.fk_test_structure_id,
                                              item?.id

                                              );
                                          }}
                                          className="refer-avatar-blk  align-items-center"
                                        >
                                          <p>{item.name}</p>
                                        </a>
                                      </td>
                                      <td>
                                        {" "}
                                        <Switch
                                          onChange={() => {
                                            handleChange(item.id, item.status);
                                          }}
                                          checked={
                                            item.status === "1" ? true : false
                                          }
                                          onColor="#86d3ff"
                                          onHandleColor="#2693e6"
                                          handleDiameter={20}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                          height={15}
                                          width={38}
                                          className="react-switch"
                                        />
                                      </td>
                                      <td>
                                        <Tooltip title="Open Questions">
                                          <Button
                                            onClick={() =>
                                              handleRoute(item?.slug,
                                                item?.fk_test_structure_id,
                                                item?.id

                                                )
                                            }
                                            className="btn btn-secondary"
                                          >
                                            {" "}
                                            <i
                                              className="fa fa-arrow-circle-o-right"
                                              style={{ fontSize: "19px" }}
                                            ></i>
                                          </Button>{" "}
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                          <button
                                            onClick={() =>
                                              handleEdit(
                                                item?.id,
                                                item?.module_id
                                              )
                                            }
                                            type="button"
                                            className="btn btn-info text-white"
                                          >
                                            <i className="fa-regular fa-pen-to-square"></i>
                                          </button>{" "}
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                          <Button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => showConfirm(item.id)}
                                          >
                                            <i className="fa-regular fa-trash-can"></i>
                                          </Button>
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })
                            ) : (
                              <>
                              </>
                            )}
                          </table>
                          {list?.length <= 0 ? (
                            <h5 className="text-center">{message}</h5>
                          ) : (
                            ""
                          )}
                          {/* Referred Users */}
                        </div>
                        <div className="fixtable">
                          {list?.length <= 0 ? (
                            ""
                          ) : (
                            <PaginationSub
                              nPages={last_page}
                              text={text}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              link={SHOWSUDMODULE}
                              module={subModule}
                              setData={setList}
                              linkSearch={SEARCHSUBMODULEAPI}
                              slug={user}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
            <ToastContainer />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
