import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../api/axios";
import AdminHeader from "../../instructor/adminHeader";
import { notification, Space } from "antd";
import { Select } from 'antd';


const ADDTESTSERIES = "/api/test-series/create-testseries-submodule";
const CATEGORY="/api/test-series/category"

const initalValue = {
  name: "",
  image:"",
  attempts:""

};
const studentSchema = Yup.object({
  name: Yup.string().min(2).max(100).required("Please enter your name"),
  attempts: Yup.string().min(1).max(25).required("Please enter your attempts"),

});
export default function AdminAddTestSeriesMainSubModule() {
  const { auth } = useContext(AuthContext);
  const { id, user, testtypeid,mainid } = useParams();
  // console.log(id,"main",mainid)
  const [submit, setSubmit] = useState(false);
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();
  const [selectedCenterId, setSelectedCenterId] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const InstituteList = async () => {
    try {
        const res = await axios.get(CATEGORY, config)
    // console.log(res,"data")
       setSelectedCenter(res?.data?.message)
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
        }
  
    }
  }
  useEffect(()=>{
    InstituteList()
  },[])
  const handleCourseChange = (value) => {
    // console.log(value,"value course")
    setSelectedCenterId(value);
};
  // let bytes = CryptoJS.AES.decrypt(
  //   decodeURIComponent(id),
  //   "my-secret-key@admin8623009959"
  // );
  // let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, text, col) => {

    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  // console.log(selectedCenterId,"selectedCenterId")
  const { errors, values, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initalValue,
    onSubmit: async (value) => {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      setSubmit(true);
      const formData = new FormData();
      if (file === null) {
        formData.append("image", "");
      } else if (file !== null) {
        const image = new Image();
      image.src = URL.createObjectURL(file);
      await new Promise(resolve => { image.onload = resolve });
      if (image.width > 630 || image.height > 420) {
        openNotificationWithIcon("error", "Image dimensions should be 630px width and 430px height.", "red");
        setSubmit(false);
        return;
      }
        formData.append("image", file);
      } else {
        formData.append("image", file);
      }
      formData.append("testseries_submodule_name", values.name);
      formData.append("fk_sub_module_id", id);
      formData.append("testtypeid", testtypeid);
      formData.append("attempts", value?.attempts);
      formData.append("fk_test_structure_id",selectedCenterId)
      formData.append("testseriesmodule-id",mainid)
// console.log(formData,"formdata")
      try {
        const response = await axios.post(
          ADDTESTSERIES,
         formData,
          config
        );
        if (response) {
          openNotificationWithIcon("success", response?.data?.message, "#52c41a");
          setTimeout(() => {
            // navigate("/admin/test-series");
            navigate(-1);
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        setSubmit(false);
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon("error", error.response.data.message, "red");
        } else {
          openNotificationWithIcon("error", "Internal Server Error", "red");
        }
       
      }
      // console.log(value);
    },
    validationSchema: studentSchema,
  });
  const openNotificationWithIcon2 = (type) => {
    // console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div
          className="container"
          style={{ maxWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Test</h3>
                    {testtypeid == 1 ? (
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                          <li
                            className="breadcrumb-item "
                            onClick={() => navigate("/admin/test-series")}
                            // aria-current="page"
                            style={{ cursor: "pointer" }}
                          >
                            Test Series
                          </li>
                          <li
                            className="breadcrumb-item"
                            aria-current="page"
                            style={{
                              cursor: "pointer",
                              // marginLeft: "-.6em",
                              fontWeight: "normal",
                            }}
                            onClick={() => navigate(-1)
                              // (`/admin/test-series/${user}/${encodeURIComponent(id)}`)
                            }
                          >
                            Test
                          </li>
                          <li
                            className="breadcrumb-item"
                            aria-current="page"
                            style={{
                              cursor: "pointer",
                              fontWeight: "normal",
                              marginLeft: "-.6em",
                            }}
                            onClick={() => navigate(-1)}
                          >
                            Show Group Testseries
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                            style={{
                              cursor: "pointer",
                              marginLeft: "-.7em",
                            }}
                          >
                            Add Test
                          </li>
                        </ol>
                      </nav>
                    ) : (
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                          <li
                            className="breadcrumb-item "
                            onClick={() => navigate("/admin/test-series")}
                            // aria-current="page"
                            style={{ cursor: "pointer" }}
                          >
                            Test Series
                          </li>
                          <li
                            className="breadcrumb-item"
                            aria-current="page"
                            style={{
                              cursor: "pointer",
                              // marginLeft: "-.6em",
                              fontWeight: "normal",
                            }}
                            onClick={() => navigate(-1)}
                          >
                            Test
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                            style={{
                              cursor: "pointer",
                              marginLeft: "-.7em",
                            }}
                          >
                            Add Test
                          </li>
                        </ol>
                      </nav>
                    )}
   
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Enter Namel
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Your Name"
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Attempts
                            </label>
                            <input
                              type="number"
                              name="attempts"
                              id="attempts"
                              value={values.attempts}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Attempts"
                            />
                            {errors.attempts && touched.attempts ? (
                              <span style={{ color: "red" }}>
                                {errors.attempts}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
      <div className="form-group d-flex"
      style={{flexDirection:"column"}}
      >
        <label className="form-control-label"> Select Category</label>
        <Select
          showSearch
          style={{ width: "100%",height:"100%" }}
          placeholder="Select an option"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
         onChange={handleCourseChange}
        >
          {selectedCenter?.map((option) => (
            
            <Option key={option?.id} value={option?.id} >
              {option?.name}
            </Option>
          ))}
        </Select>
      </div>
    </div>
                        
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Cover image Size(630px*420px)
                            </label>
                            <input
                              type="file"
                              name="image"
                              id="image"
                              onChange={(e) => {
                                setFile(e.target.files[0]),
                                  setFilePre(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                              }}
                              className="form-control"
                              placeholder="Cover Image"
                            />
                            {errors.image && touched.image ? (
                              <span style={{ color: "red" }}>
                                {errors.image}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <div
                              className="add-image-box"
                              style={{ minHeight: "0", height: "200px" }}
                            >
                              {!filePre && (
                                <Link to="#" style={{ fontSize: "200px" }}>
                                  <i className="far fa-image" />
                                </Link>
                              )}
                              {filePre && (
                                <img
                                  src={filePre}
                                  style={{
                                    height: "200px",
                                    width: "100%",
                                    borderRadius: "10px",
                                  }}
                                ></img>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="update-profile" id="submit-button">
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={submit}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
